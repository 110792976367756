<template>
	<Dialog titulo="Resetar a senha do farmembro na área logada" btn_cancelar="Cancelar"
		:btn_acao_disable="!accepted"
		btn_acao="Resetar" @acao="resetPassword"  @close="closeModal()" :loading="resetting">
		<v-container grid-list-md text-xs-center>
			<v-layout align-center justify-center row wrap>
				<v-flex xs12 md6>
					<v-text-field v-model="farmember.nome" label="Nome" color="purple" readonly box />
				</v-flex>
				<v-flex xs12 md6>
					<v-text-field v-model="farmember.email" label="Email" color="purple" readonly box />
				</v-flex>
				<v-flex xs12 md6>
					<v-text-field v-model="farmember.cpf" label="CPF" color="purple" readonly box
						v-mask="['###.###.###-##']" />
				</v-flex>
				<v-flex xs12 md6>
					<v-text-field v-model="farmember.telefone" label="Telefone" color="purple" readonly box
						v-mask="['(##) #####-####', '(##) ####-####']" />
				</v-flex>
				<v-flex xs12 md12>
					<v-checkbox v-model="accepted" hide-details label="Estou ciente de que, ao redefinir a senha do Farmembro, ele perderá o acesso e a senha atual. Além disso, o usuário receberá uma nova senha por e-mail, que será necessária para fazer login na área do cliente." />
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { PacientePresenter } from '@Presenter/paciente-presenter';
import { onlyNumber } from '@Util/functions';

export default {
	name: 'DialogResetPassword',
	props: {
		farmember: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		resetting: false,
		accepted: false
	}),
	methods: {
		resetPassword() {
			this.resetting = true;
			const data = {
				email: this.farmember.email,
				nome: this.farmember.nome,
				cpf: onlyNumber(this.farmember.cpf),
				telefone: onlyNumber(this.farmember.telefone),
				pacienteId: this.farmember.id
			};

			PacientePresenter.resetPassword(data)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: `Senha do Farmembro - ${this.farmember.nome} resetada.`, color: 'success' });
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: `Erro ao resetar a senha do Farmembro - ${this.farmember.nome}`, color: 'error' });
				})
				.finally(() => {
					this.resetting = false;
					this.closeModal();
				});
		},
		closeModal() {
			this.$emit('close');
		}
	}
};
</script>
