<template>
	<v-card>
		<v-data-table v-model="selected" :headers="headers" :items="boxes" :loading="loading" :search="search"
			ref="DataTable" :no-data-text="loading ? 'Carregando...' : 'Nenhuma box'"
			no-results-text="Nenhum resultado encontrado" :rows-per-page-items="[15, 30, 45, {text: 'Todos', value: -1}]"
			rows-per-page-text="Itens por página" :pagination.sync="pagination" :select-all="selectable" item-key="id">
			<template slot="headers" slot-scope="props">
				<tr>
					<th v-if="selectable" class="pr-0">
						<v-checkbox :input-value="props.all" :indeterminate="props.indeterminate" primary
							hide-details @click.stop="toggleAll" />
					</th>
					<th v-for="(header, index) in props.headers" :key="header.text" :class="['column', { sortable: header.sortable }, index !== 1 ? 'text-xs-center' : 'text-xs-left', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
						@click="changeSort(header.value)">
						{{ header.text }}
						<v-icon small v-if="header.sortable">arrow_upward</v-icon>
					</th>
				</tr>
			</template>
			<template slot="items" slot-scope="props">
				<tr :active="props.selected" @click="props.selected = !props.selected">
					<td v-if="selectable" class="pr-0">
						<v-checkbox :input-value="props.selected" primary hide-details />
					</td>
					<td class="text-xs-center">{{ props.item.assinatura_id }}</td>
					<td class="py-2" style="white-space: nowrap">
						<v-avatar size="24" class="mr-2">
							<span class="white--text">
								{{ props.item.paciente.nome.trim().charAt(0) }}
							</span>
						</v-avatar>
						<GlobalTags
							v-for="(global_tag, index) in props.item.paciente.global_tags"
							:key="index"
							:global_tag="global_tag"/>
						{{ props.item.paciente.nome }}
						<BoxExpress :express="props.item.express"/>
						<FirstBox v-if="!props.item.express && props.item.primeira_box"/>
					</td>
					<td class="text-xs-center">{{ props.item.inicio | formatDate('DD/MM/YYYY [(]dddd[)]') }}</td>
					<td class="text-xs-center">
						{{ (props.item.paciente.casa_repouso || {}).nome || '-' }}
					</td>
					<td v-for="h in extra_headers" :key="h.value" class="text-xs-center">
						{{ h.format ? h.format(props.item[h.value]) : props.item[h.value] || '-' }}
					</td>
					<td class="buttons" @click.stop>
						<v-tooltip v-if="props.item.sem_box" top>
							<v-icon :class="{ 'mr-3': buttons.length > 0 }" slot="activator"
								color="warning">
								fas fa-exclamation-circle
							</v-icon>
							<span>Sem box</span>
						</v-tooltip>
						<template v-for="(btn, i) in buttons">
							<v-tooltip v-if="btn.condition ? btn.condition(props.item) : true" top :key="i">
								<v-icon slot="activator" :class="{
									'mr-3': buttons.length > 1 && i !== buttons.length - 1,
									'cursor-default': !btn.onClick || (btn.isDisabled && btn.isDisabled(props.item))
								}"
									:color="btn.color ? btn.color : btn.setColor ? btn.setColor(props.item) : 'info'"
									@click.stop="btn.onClick ? btn.onClick(props.item) : null">
									{{ btn.icon }}
								</v-icon>
								<span>{{ btn.label || btn.getLabel(props.item) }}</span>
							</v-tooltip>
						</template>
					</td>
				</tr>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
import BoxExpress from '@Componentes/box-express';
import FirstBox from '@Componentes/first-box';
import GlobalTags from '@Componentes/global-tags';

export default {
	name: 'DataTableProcesso',
	components: {
		BoxExpress,
		FirstBox,
		GlobalTags
	},
	props: {
		boxes: {
			type: Array,
			required: true
		},
		selectable: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			required: true
		},
		search: {
			type: String,
			default: ''
		},
		buttons: {
			type: Array,
			default: () => []
		},
		extra_headers: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			selected: [],
			headers: [
				{ text: 'Assinatura', value: 'assinatura_id', sortable: true },
				{ text: 'Paciente', value: 'paciente.nome', sortable: true },
				{ text: 'Data de início', value: 'inicio', sortable: true },
				{ text: 'Casa de repouso', value: 'paciente.casa_repouso.nome', sortable: true },
				...this.extra_headers,
				{ text: 'Ações' }
			],
			pagination: {
				sortBy: 'inicio',
				descending: false
			}
		};
	},
	watch: {
		selected(arr) {
			this.$emit('selected-updated', arr);
		}
	},
	methods: {
		toggleAll() {
			this.selected = this.selected.length ? [] : [...this.$refs.DataTable.filteredItems];
		},
		changeSort(column) {
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		}
	}
};
</script>
