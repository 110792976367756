<template>
	<div class="layout wrap" style="margin: 20px">
		<div class="flex xs3">
			<div class="div-9">
				<div class="div-10">{{ name }}</div>
				<div class="div-11" :style="`color:${labelColor}; background-color: ${labelBackgroundColor}`" >
					<div class="div-12">{{ getFormattedLabel(sache.scheduled_label) }}</div>
					<div class="div-13">{{ getDateFormatted(sache.scheduled_date) }}</div>
				</div>
				<div class="div-14" v-for="medicamento in sache.sache_medicamentos" :key="medicamento.id">
					{{ medicamento.quantidade }} - {{ medicamento.box_medicamento.medicamento.produto }} - {{ medicamento.box_medicamento.medicamento.dosagem }}
				</div>
			</div>
			<h5>{{ sache.id }}</h5>
		</div>
		<div class="flex xs9">
			<div class="layout row wrap"
				v-for="(image, index) in sache.images"  :key="index">
				<div class="layout row wrap">
					<div class="flex xs6">
						<img v-if="image.front_image"
							:loading="loading"
							title="Frente"
							alt="Frente"
							data-decorative="true"
							:src="image.front_image"
							class="img"/>
					</div>
					<div class="flex xs6">
						<img v-if="image.back_image"
							:loading="loading"
							:src="image.back_image"
							class="img"/>
					</div>
				</div>
				<div class="layout row wrap xs12 result_box">
					<img
						v-if="image.ai_response.result === true"
						loading="lazy"
						src="@Assets/icons/approved.svg"
						class="img-6"/>
					<div v-if="image.ai_response.result === true"
						class="div-21">Approvado pela IA - {{ image.ai_response.reason }}</div>
					<img
						v-if="image.ai_response.result === false"
						loading="lazy"
						src="@Assets/icons/rejected.svg"
						class="img-6"/>
					<div v-if="image.ai_response.result === false"
						class="div-21">Reprovado pela IA - {{ image.ai_response.reason }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { formatDate } from '@Util/functions';

export default {
	name: 'SacheCard',
	props: {
		name: {
			type: String,
			required: true
		},
		sache: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		loading: true
	}),
	async created() {
		this.labelBackgroundColor = this.getBackgroundColor(this.sache.scheduled_time);
		this.labelColor = this.getColor(this.sache.scheduled_time);
		this.loading = false;
	},
	methods: {
		getFormattedLabel(scheduledLabel) {
			return scheduledLabel.toUpperCase();
		},
		getDateFormatted(scheduledDate) {
			return formatDate(scheduledDate, 'dddd, D [de] MMMM').replaceAll('-feira', '').toUpperCase();
		},
		getBackgroundColor(scheduled_time) {
			const hora = Number(`${scheduled_time.slice(0, 2)}.${scheduled_time.slice(3, 5)}`);
			if (hora > 7 && hora < 10)
				return '#ffff99';
			if (hora >= 10 && hora < 12)
				return '#9999ff';
			if (hora >= 12 && hora <= 14)
				return '#ff9999';
			if (hora > 14 && hora < 17)
				return '#a2d37a';
			if (hora >= 17 && hora <= 19)
				return '#feafc9';
			if (hora > 19 && hora <= 22)
				return '#000000';
			if (hora > 22 || hora < 2)
				return '#d1d1d1';
			return '#ffffff';
		},
		getColor(scheduled_time) {
			const hora = Number(`${scheduled_time.slice(0, 2)}.${scheduled_time.slice(3, 5)}`);
			if (hora > 7 && hora < 10)
				return '#000000';
			if (hora >= 10 && hora < 12)
				return '#000000';
			if (hora >= 12 && hora <= 14)
				return '#000000';
			if (hora > 14 && hora < 17)
				return '#000000';
			if (hora >= 17 && hora <= 19)
				return '#000000';
			if (hora > 19 && hora <= 22)
				return '#ffffff';
			if (hora > 22 || hora < 2)
				return '#000000';
			return '#000000';
		}
	}
};
</script>

<style scoped>

.img {
	object-fit: auto;
	object-position: center;
	align-self: start;
	inset: 0;
	border-radius: 10px;
	margin: 10px;
	height: 95%;
	width: 95%;
}

.div-9 {
	border-radius: 10px;
	border-color: grey;
	border-style: solid;
	border-width: 2px;
	background-color: #fff;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	font-size: 14px;
	color: var(--primary-darkness, #342b1d);
	font-weight: 700;
	text-align: center;
	width: 100%;
	min-height: 350px;
	padding: 23px 0 20px;
}

@media (max-width: 991px) {
	.div-9 {
		margin-top: 21px;
	}
}

.div-10 {
	font-family: Inter, sans-serif;
	font-size: 12px;
	align-self: center;
	text-wrap: nowrap;
	text-transform: uppercase;
}

.div-11 {
	background-color: #faf4b9;
	align-self: stretch;
	display: flex;
	margin-top: 13px;
	flex-direction: column;
	padding: 9px 33px;
}

.result_box {
	align-self: stretch;
	display: flex;
	margin-top: 13px;
	flex-direction: column;
	padding: 9px 33px;
}

@media (max-width: 991px) {
	.div-11 {
		padding: 0 20px;
	}
}

.div-12 {
	font-family: Inter, sans-serif;
	align-self: center;
	text-wrap: nowrap;
}

.div-13 {
	font-family: Inter, sans-serif;
	align-self: center;
	margin-top: 10px;
	text-wrap: nowrap;
}

.div-14 {
	margin-top: 10px;
	margin-left: 10px;
	text-align: left;
	align-self: start;
	width: 100%;
	font-weight: bold;
	text-wrap: nowrap;
	font: 800 10px Inter, sans-serif;
}

.img-6 {
	aspect-ratio: 1;
	object-fit: auto;
	object-position: center;
	width: 18px;
}

.div-21 {
	font-family: Inter, sans-serif;
	flex-grow: 1;
	flex-basis: auto;
	margin-left: 10px;
}

</style>
