<template>
	<DialogRestHome
		ref="rest-home-dialog"
		title="Adicionar ILPI"
		:rest-home="{}"
		@submited="createRestHome"
		@close="() => $emit('close')" />
</template>

<script>
import DialogRestHome from './rest-home-dialog';

export default {
	name: 'CreateRestHomeDialog',
	components: { DialogRestHome },
	methods: {
		createRestHome(data) {
			this.$emit('create-rest-home', data);
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
