<template>
	<div class="px-md-5">

		<HeaderProcesso
			titulo="Expedição"
			:subtitulo="`Faça a expedição ${tabSelected==='boxesTab' ? 'das boxes' : 'dos pedidos'}`"
			btn_icon="send"
			:btn_label="tabSelected==='boxesTab' ? 'Comprovante de entrega' : ''"
			:btn_disabled="selecionados.length === 0"
			:btn_on_click="() => { dialog_comprovante_entrega = true; }"
			@busca="val => busca = val">
			<v-btn v-if="tabSelected==='boxesTab'" depressed class="primary" :disabled="!somenteSemBox" :loading="gerando_etiquetas"
				@click="gerarEtiquetas(idsSelecionados)">
				<v-icon class="mr-2" style="font-size: 12px">fas fa-file-download</v-icon>
				Etiquetas (sem box)
			</v-btn>
		</HeaderProcesso>

		<TabsDataTable
			:boxesLength="boxes.length"
			:ordersLength="ordersProcessTotal"
			:tabSelected="tabSelected"
			@switchTab="val => switchTab(val)"/>

		<DataTableProcesso
			v-if="tabSelected==='boxesTab'"
			:boxes="boxes"
			selectable
			:loading="carregando"
			:search="busca"
			:buttons="buttons"
			@selected-updated="arr => selecionados = arr" />

		<DataTablePedidos
			v-if="tabSelected==='ordersTab'"
			:orders="orders"
			:loading="loadingOrders"
			:buttons="orderButtons"
			:total-items="ordersProcessTotal"
			@pagination-updated="val => getOrdersProcess(val)"
			@selected-updated="arr => selectedOrders = arr"/>

		<v-dialog persistent v-model="dialog_comprovante_entrega" fullscreen hide-overlay scrollable
			transition="fade">
			<transition>
				<DialogComprovanteEntrega v-if="dialog_comprovante_entrega" :selecionados="selecionados" :close.sync="dialog_comprovante_entrega" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_expedir" transition="fade" max-width="500px">
			<transition>
				<DialogDispatchOrder v-if="dialog_expedir && (tabSelected === 'ordersTab')" :orderId="orderId" :request="requestChangeStatus()" @close="dialog_expedir = false"/>
				<DialogConfirmacao
					v-if="dialog_expedir && (tabSelected === 'boxesTab')"
					@close="dialog_expedir = false"
					:request="requestChangeStatus()"
					:opcoes="[
						'Confirmo que realizei a expedição da box'
					]" />
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { ProcessoMixin } from '@Mixins/processo';
import { BoxPresenter } from '@Presenter/box-presenter';
import { geraExcelProducao } from '@Util/excel-producao';
import TabsDataTable from '@Componentes/tabs-data-table.vue';
import { ProcessTypes } from '@Consts/status-order-process';
import { OrderProcessMixin } from '@Mixins/order-process';
import { validateProcessIsAllowed } from '@Util/order-process';
import DialogComprovanteEntrega from './dialog-comprovante-entrega';
import DialogDispatchOrder from './dialog-dispatch-order.vue';

export default {
	name: 'Expedicao',
	mixins: [ProcessoMixin, OrderProcessMixin],
	components: { DialogComprovanteEntrega, TabsDataTable, DialogDispatchOrder },
	data: () => ({
		statusToShow: ['CHECKED'],
		processName: ProcessTypes.DISPATCH,
		dialog_expedir: false,
		gerando_etiquetas: false,
		dialog_comprovante_entrega: false,
		orderId: null,
		disableBtnByProccess: true
	}),
	computed: {
		idsSelecionados() {
			return this.selecionados.map(s => s.id);
		},
		somenteSemBox() {
			return this.selecionados.length > 0 && this.selecionados.every(s => s.sem_box);
		}
	},
	created() {
		this.buttons = [
			{
				label: 'Imprimir etiquetas',
				icon: 'fas fa-file-download',
				onClick: item => this.gerarEtiquetas([item.box_id]),
				condition: item => item.sem_box
			},
			{
				label: 'Expedir',
				icon: 'rv_hookup',
				onClick: item => this.abrirDialog(item, 'dialog_expedir')
			}
		];

		this.orderButtons = [
			{
				label: 'Expedir',
				icon: 'rv_hookup',
				onClick: item => ([
					this.abrirDialog(item, 'dialog_expedir'),
					this.getProcessByOrderAndProcessType(item.id)
				])
			}
		];
	},
	methods: {
		requestChangeStatus() {
			if (this.tabSelected === 'boxesTab')
				return this.updateStatus('DESPATCHED');

			if (this.tabSelected === 'ordersTab')
				return this.updateOrderProcess(ProcessTypes.DISPATCH, 'SUCCESS', {});

			this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao alterar Status!' });
		},
		gerarEtiquetas(ids) {
			return new Promise(async resolve => {
				this.gerando_etiquetas = true;
				const response = await BoxPresenter.gerarEtiquetas({ ids });
				geraExcelProducao(response, ['etiquetasIndividuais']);
				resolve();
				this.gerando_etiquetas = false;
			});
		},
		getProcessByOrderAndProcessType(orderId) {
			this.orderId = orderId;
			this.disableBtnByProccess = true;
			validateProcessIsAllowed(orderId, this.processName)
				.then(isValid => {
					this.disableBtnByProccess = !isValid;
				});
		}
	}
};
</script>
