export const posologyTypes = {
	allDays: 1,
	dayYesDayNot: 2,
	weekly: 3,
	monthly: 4,
	other: 5,
	dayNotDayYes: 6
};

export const PERIODO_DE_USO = Object.freeze({
	TODOS_OS_DIAS: 1,
	DIA_SIM_DIA_NAO: 2,
	DIA_NAO_DIA_SIM: 6,
	DIAS_DA_SEMANA: 3,
	DIAS_DO_MES: 4,
	OUTRO: 5
});

export const PERIODO_DE_USO_ID = Object.freeze({
	[PERIODO_DE_USO.TODOS_OS_DIAS]: 'TODOS_OS_DIAS',
	[PERIODO_DE_USO.DIA_SIM_DIA_NAO]: 'DIA_SIM_DIA_NAO',
	[PERIODO_DE_USO.DIA_NAO_DIA_SIM]: 'DIA_NAO_DIA_SIM',
	[PERIODO_DE_USO.DIAS_DA_SEMANA]: 'DIAS_DA_SEMANA',
	[PERIODO_DE_USO.DIAS_DO_MES]: 'DIAS_DO_MES',
	[PERIODO_DE_USO.OUTRO]: 'OUTRO'
});

export const PERIODO_DE_USO_LABEL = Object.freeze({
	[PERIODO_DE_USO.TODOS_OS_DIAS]: 'Todos os dias',
	[PERIODO_DE_USO.DIA_SIM_DIA_NAO]: 'Dia SIM, dia NAO',
	[PERIODO_DE_USO.DIA_NAO_DIA_SIM]: 'Dia NAO, Dia SIM',
	[PERIODO_DE_USO.DIAS_DA_SEMANA]: 'Dias da semana',
	[PERIODO_DE_USO.DIAS_DO_MES]: 'Dias do mês',
	[PERIODO_DE_USO.OUTRO]: 'Outro'
});
