<template>
	<div class="">
		<div class="loading" v-if="loading">
			<v-progress-circular indeterminate color="primary" />
		</div>
		<template v-else>
			<Header @assinatura-salva="getAssinatura" />
			<Assinatura :assinatura="assinatura" :carregando="carregando" />
		</template>

		<v-dialog persistent v-model="dialog_confirmacao" scrollable transition="fade" max-width="400px">
			<transition>
				<Dialog v-if="dialog_confirmacao" titulo="Salvar alterações" subtitulo="Tem certeza que deseja sair sem salvar as alterações?"
					btn_acao="Sair sem salvar" btn_acao_class="error" btn_cancelar="Cancelar" @acao="next"
					@close="dialog_confirmacao = false" />
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { mapState } from 'vuex';
import Assinatura from '@Componentes/assinatura';
import Header from './header';

export default {
	name: 'IndexAssinaturaColaborador',
	components: {
		Assinatura, Header
	},
	data: () => ({
		dialog_confirmacao: false,
		tem_alteracao: false,
		carregando: false,
		next: () => ({})
	}),
	computed: {
		...mapState('assinatura', {
			assinatura: state => state.assinatura,
			loading: state => state.loading
		})
	},
	created() {
		this.getAssinatura();
	},
	mounted() {
		this.$root.$on('BOX_APAGADA', this.getAssinatura);
		this.$root.$on('BOX_UPDATED', this.getAssinatura);
		this.$root.$on('ALTEROU', alterou => {
			this.tem_alteracao = alterou;
		});
		window.onbeforeunload = () => {
			if (this.tem_alteracao)
				return 'Existem alterações que não foram salvas. Por favor, salve antes de sair da tela';
			return null;
		};
	},
	beforeDestroy() {
		this.$root.$off('ALTEROU');
		this.$root.$off('BOX_UPDATED');
		this.$root.$off('BOX_APAGADA', this.getAssinatura);
	},
	beforeRouteLeave(to, from, next) {
		if (this.tem_alteracao) {
			this.dialog_confirmacao = true;
			this.next = next;
		} else
			next();
	},
	methods: {
		getAssinatura() {
			this.$store.dispatch('assinatura/FETCH_ASSINATURA', this.$route.params.id);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

@media screen and (min-width: $break-point-md) {
  .flex-end {
    display: flex;
    justify-content: flex-end;
  }
}

.v-card {
  ::v-deep .v-card {
    box-shadow: none !important;
  }
}

.img-wrapper {
  position: relative;

  .miniatura {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.08);
		border: 1px solid red;
  }
}
</style>
