<template>
	<v-layout wrap class="header">
		<v-flex xs12 md8 class="q-row items-center">
			<h1 class="text--text" style="opacity: .8">Clínica</h1>
		</v-flex>

		<v-flex xs12 md4 class="q-row items-center justify-end">
			<v-btn depressed class="primary" @click="$emit('comparar')">
				Comparar receitas/medicamentos
			</v-btn>
			<v-btn :loading="loading" depressed class="primary" @click="updateNoHarm">
				Enviar para NoHarm
			</v-btn>
			<v-btn depressed class="primary" :loading="gerando_termo" @click="imprimirTermoResponsabilidade">
				Termo / Relação
			</v-btn>
		</v-flex>
	</v-layout>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { openBuffer } from '@Util/functions';
import $store from '@Store';

export default {
	name: 'HeaderClinica',
	props: {
		assinatura: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		loading: false,
		gerando_termo: false
	}),
	methods: {
		async updateNoHarm() {
			this.loading = true;
			try {
				await AssinaturaPresenter.updateNoHarm(this.assinatura.id);
				await $store.dispatch('SHOW_SNACKBAR', { message: 'Enviado para NoHarm com sucesso!' });
			} finally {
				this.loading = false;
			}
		},
		async imprimirTermoResponsabilidade() {
			try {
				this.gerando_termo = true;
				const pdf = await AssinaturaPresenter.relacaoDeMedicamentos(
					this.assinatura.id,
					this.assinatura.paciente.nome,
					this.assinatura.paciente.cpf
				);
				openBuffer(pdf);
				return pdf;
			} catch (err) {
				throw new Error(err);
			} finally {
				this.gerando_termo = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.header {
  padding: 0 30px;
}
</style>
