import Vue from 'vue';
import Vuetify from 'vuetify';
import ZoomOnHover from 'vue-zoom-on-hover';
import moment from 'moment';
import VueGtag from 'vue-gtag';
import money from 'vuejs-money';
import mask from 'vuejs-mask';
import ptBR from 'vee-validate/dist/locale/pt_BR';
import VeeValidate from 'vee-validate';
import route from '../router/index';

Vue.use(ZoomOnHover);
Vue.use(Vuetify);
Vue.use(money);
Vue.use(mask);
Vue.use(VeeValidate, {
	inject: false,
	dictionary: {
		en: ptBR
	},
	delay: 10
});
Vue.use(VueGtag, {
	config: {
		id: 'G-QFVPS3G1DM',
		params: {
			send_page_view: false
		}
	}
}, route);
moment.locale('pt-BR');
