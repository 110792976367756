import $http from '@Config/axios';
import $store from '@Store';

import moment from 'moment';
import { buildQueryParams } from 'luiz-fns';
import { DefaultPresenter } from './default-presenter';

const prefix = 'orcamento';
const entity = 'Orçamento';

export const OrcamentoPresenter = {
	...DefaultPresenter({ prefix, entity }),
	index({
		status, page, rowsPerPage, sortBy, order, search
	}) {
		return new Promise((resolve, reject) => {
			$http
				.get(`${prefix}/index`, {
					params: {
						status,
						page,
						rowsPerPage,
						sortBy,
						order,
						search,
						visao_colaborador: $store.getters.visao_colaborador ? 1 : 0
					}
				})
				.then(response => {
					resolve({
						...response,
						items: response.items.map(orcamento => ({
							...orcamento,
							created_at: moment(
								orcamento.created_at,
								'YYYY-MM-DD HH:mm::ss'
							).format('DD/MM/YYYY HH:mm')
						}))
					});
				})
				.catch(response => reject(response));
		});
	},
	count: () => $http.get(`${prefix}/count`),
	relacaoDeMedicamentos: (orcamento_id, nome, cpf) => $http.get(buildQueryParams('relatorios/relacaoDeMedicamentos', { orcamento_id, nome, cpf })),
	relacaoDeMedicamentosB2B: params => $http.post('/relatorios/relacaoDeMedicamentosLoteB2B', params),
	medicamentos: id => $http.get(`${prefix}/${id}/medicamentos`),
	renovacao: ({ id, data_validade }) => $http.put(`${prefix}/${id}/renovacao`, { data_validade }),
	confirmarRevisao: (id, e_vendedor_interno) => $http.patch(`${prefix}/${id}/confirmarRevisao`, { e_vendedor_interno }),
	geraEtiquetas: params => $http.post(`${prefix}/geraEtiquetas`, params),
	enviarOrcamentoB2C: uuid => $http.post(`${prefix}/${uuid}/enviar`, {}),
	pdf: uuid => $http.get(`${prefix}/${uuid}/pdf`),
	sendMailB2B: uuid => $http.post(`${prefix}/casa_repouso/${uuid}/enviar`, {}),
	listaDeCompras: ids => $http.get(buildQueryParams(`${prefix}/listaDeCompras`, {
		ids
	})),
	/**
	 * @param quotationId ID do orçamento
	 */
	getUpdateHistory: quotationId => $http.get(`${prefix}/${quotationId}/alteracoes`),
	getHubspotData: (orcamento_id, dealId) => $http.get(`${prefix}/${orcamento_id}/hubspotDeal/${dealId}`)
};
