<template>
	<div class="lista">
		<div v-for="(medicamento, i) in medicamentos" :key="medicamento.id">
			<v-layout wrap>
				<v-flex xs6>
					<div class="nome-produto">
						<h4>{{medicamento.produto}}</h4>
						<v-tooltip v-if="!medicamento.ean" right>
							<v-icon slot="activator" color='purple'>info_outline</v-icon>
							<span>Medicamento não possui EAN</span>
						</v-tooltip>
					</div>
					<p>{{medicamento.apresentacao}}</p>
					<p>{{medicamento.laboratorio}}</p>
				</v-flex>
				<v-flex xs3 class="text-center">
					<h5>Quant. necessária</h5>
					<p class="numero">{{medicamento.caixas}}</p>
				</v-flex>
				<v-flex xs3 class="text-center">
					<h5>Quant. inserida</h5>
					<div class="quantidade-inserida-container">
						<v-btn class="btn" small icon @click="medicamento.quantidade_inserida -= 1" :disabled="medicamento.quantidade_inserida < 1">
							<v-icon small color="red">remove</v-icon>
						</v-btn>
						<p class="numero" :class="numeroClass(medicamento)">
							{{medicamento.quantidade_inserida}}
						</p>
						<v-btn class="btn" small icon @click="medicamento.quantidade_inserida += 1" :disabled="!!medicamento.ean">
							<v-icon small color="green">add</v-icon>
						</v-btn>
					</div>
				</v-flex>
			</v-layout>
			<v-divider v-if="i < (medicamentos.length - 1)" />
		</div>
	</div>
</template>

<script>

export default {
	name: 'ListaMedicamentos',
	computed: {
		medicamentos() {
			return Object.values(this.$store.state.bipe.medicamentos);
		}
	},
	methods: {
		numeroClass(medicamento) {
			if (medicamento.quantidade_inserida !== medicamento.caixas)
				return 'red--text';
			return 'green--text';
		}
	}
};
</script>

<style lang="scss" scoped>
.quantidade-inserida-container {
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		.btn {
			display: inherit;
		}
	}

	.btn {
		display: none;
	}
}

.lista {
	padding-left: 4px;
	padding-right: 4px;
	overflow-y: auto;
	overflow-x: hidden;
	height: 400px;

	.nome-produto {
		display: flex;
		align-items: center;
	}

	.layout {
		margin-top: 0px !important;
		padding: 4px;

		.text-center {
			text-align: center;

			p {
				font-weight: 500;

				&.numero {
					font-size: 28px;
				}
			}
		}
	}
	p {
		margin: 0;
	}
}
</style>
