<template>
	<div class="">
		<div class="loading" v-if="loading">
			<v-progress-circular indeterminate color="primary" />
		</div>
		<template v-else>
			<v-container grid-list-lg>
				<InvoiceResume :invoice="invoice"  @load-invoice="loadInvoice"/>
				<v-layout wrap>
					<v-flex xs12 md6
						style="width:50%; display: flex; flex-direction:column; gap: 22px;">
						<div class="d-flex justify-space-between">
							<PersonInfo
								v-if="customer"
								title="Cliente"
								showHeader
								class="no-round-right"
								style="width:50%"
								:name="customer.name"
								:mail="customer.email"
								:phone="customer.phone"
								:document="customer.document" />
							<AddressInfo
								class="no-round-left"
								style="width:50%"
								:street="address.street"
								:number="address.address_number"
								:complement="address.complement"
								:neighborhood="address.neighborhood"
								:city="address.city"
								:state="address.state"
								:zipCode="address.zip_code" />
						</div>
						<PersonInfo
							showHeader
							title="Pagador"
							:name="payer.name"
							:mail="payer.email"
							:phone="payer.phone" />
						<Payments :items="payments" />

						<v-card>
							<v-card-title class="pb-0">
								<v-flex xs12 md6 style="display: flex; flex-direction: row; align-items: center">
									<v-avatar size="38" class="mr-3">
										<v-icon color="#342B1D" small>fas fa-history</v-icon>
									</v-avatar>
									<h4>Observações</h4>
								</v-flex>
								<v-spacer />
							</v-card-title>
							<v-card-text>
								<v-flex>
									<p>
										{{ invoice.observation }}
									</p>
									<button class="d-flex" v-if="invoice.invoice_origin_id"
										@click="openDetails(invoice.invoice_origin_id)"
										style="text-align: right; color: gray">Nova via criada a partir da
										<div style="text-decoration: underline; margin-left: 5px;"
											class=" black--text">{{ ` fatura ${invoice.invoice_origin_id}`}}</div>
									</button>
								</v-flex>
							</v-card-text>
						</v-card>
					</v-flex >
					<v-flex xs12 md6
						style="width:50%; display: flex; flex-direction:column; gap: 22px;">
						<Products style="height: 100%;" :items="products"
							:general-discount="invoice.discount"
							:sub-total="subTotal"
							:total="invoice.total_amount"/>
						<History :items="invoice.history_status" />

					</v-flex>
				</v-layout>
			</v-container>
		</template>

	</div>
</template>

<script>
import { InvoicesPresenter } from '@Presenter/invoices-presenter';
import PersonInfo from './PersonInfo';
import InvoiceResume from './InvoiceResume';
import AddressInfo from './AddressInfo';
import History from './History';
import Products from './Products';
import Payments from './Payments';

export default {
	name: 'IndexInvoice',
	components: {
		InvoiceResume, PersonInfo, AddressInfo, History, Products, Payments
	},
	data: () => ({
		invoice: {
			payer: { address: {} }, items: []
		},
		payments: [],
		loading: false
	}),
	async created() {
		await this.loadInvoice();
		await this.loadPayments();
	},
	methods: {
		async loadInvoice() {
			const { id } = this.$route.params;
			try {
				this.invoice = await InvoicesPresenter.show(id);
			} catch (e) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: `Erro ao buscar fatura: ${e.message}`
				});
			} finally {
				this.loading = false;
			}
		},
		async loadPayments() {
			const { id } = this.$route.params;
			try {
				this.payments = await InvoicesPresenter.getPayments(id);
			} catch (e) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: `Erro ao buscar pagamentos: ${e.message}`
				});
			} finally {
				this.loading = false;
			}
		},
		openDetails(invoiceId) {
			this.$router.push({ name: 'invoice', params: { id: invoiceId } });
		}
	},
	computed: {
		customer() {
			return this.invoice.customer;
		},
		payer() {
			return this.invoice.payer;
		},
		address() {
			return this.invoice.payer.address;
		},
		products() { return this.invoice.items;	},
		subTotal() {
			if (!this.invoice.items)
				return 0;
			return this.invoice.items.reduce((acc, item) => acc + item.subTotal, 0);
		}
	}
};
</script>

<style scoped>
.no-round-right{
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-right-width: 3px !important;
	border-color: transparent !important;
	border-right-color: rgba(59, 44, 27, 0.1) !important;
	border-style: dashed !important;
}
.no-round-left{
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}
</style>
