<template>
	<Dialog titulo="Preencha os campos do(s) medicamento(s) que você deseja adicionar ao estoque"
		:subtitulo="paciente.nome"
		@close="$emit('update:close', false)"
		btn_acao="Salvar" @acao="salvar" :loading="salvando" :carregando="carregando">
		<v-container grid-list-md class="pa-0">
			<v-layout wrap>
				<v-flex xs12 v-for="(item, index) in items" :key="index">
					<Medicamento
						:item="item"
						:scope="index"
						:ref="item.medicamento_id"
						@medicamento-selecionado="medicamentoSelecionado" />
					<v-divider class="mb-2" style="margin-top: -14px" />
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog persistent v-model="dialog_justificativa" scrollable transition="fade" max-width="400px">
			<transition>
				<Dialog v-if="dialog_justificativa" titulo="Adicionar ao estoque"
					btn_acao="Confirmar" :btn_acao_disable="salvando" :loading="salvando" @acao="confirmar" @close="dialog_justificativa = false">
					<v-textarea box v-model="justificativa" label="Motivo *" />
				</Dialog>
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { PacientePresenter } from '@Presenter/paciente-presenter';
import { MovimentacaoPresenter } from '@Presenter/movimentacao-presenter';
import { first } from 'lodash';
import Medicamento from './medicamento';

export default {
	name: 'DialogAdicionarEstoque',
	$_veeValidate: { validator: 'new' },
	components: { Medicamento },
	props: {
		paciente: {
			type: Object,
			required: true
		},
		assinatura: {
			type: Object,
			default: null
		}
	},
	data: () => ({
		carregando: true,
		items: [],
		estoque: [],
		salvando: false,
		dialog_justificativa: false,
		justificativa: null,
		assinatura_id: null
	}),
	created() {
		Promise.all([
			AssinaturaPresenter.index({ paciente_id: this.paciente.id, show_medicamentos: true, filtro: 'ativas' }),
			PacientePresenter.stockWithBatchAndExpirationDate(this.paciente.id)
		])
			.then(([assinaturas, infoEstoque]) => {
				this.assinatura_id = first(assinaturas).id;
				this.estoque = infoEstoque.estoque;
				assinaturas
					.reduce((meds, assinatura) => { // Junta os medicamentos das assinaturas
						meds.push(...assinatura.medicamentos);
						return meds;
					}, [])
					.filter((medicamento, index, self) => self.findIndex(t => t.id === medicamento.id) === index) // Remove medicamentos duplicados
					.forEach(this.adicionarMedicamento); // Adiciona os medicamentos na lista
			})
			.finally(() => {
				this.carregando = false;
			});
	},
	methods: {
		buildEstoque(medicamento) {
			const estoque = this.estoque.filter(e => e.id === medicamento?.id).reduce(
				(accumulator, current) => ({
					qtd_comprimidos: accumulator.qtd_comprimidos + current.qtd_comprimidos,
					qtd_caixas: accumulator.qtd_caixas + current.qtd_caixas
				}),
				{ qtd_comprimidos: 0, qtd_caixas: 0 }
			);

			return estoque;
		},
		adicionarMedicamento(medicamento = null) {
			this.items.push({
				medicamento_id: medicamento.id,
				estoque: this.buildEstoque(medicamento),
				qtd_comprimidos: null,
				validade: null,
				lote: null,
				medicamento
			});
		},
		medicamentoSelecionado(item) {
			item.estoque = this.buildEstoque(item.medicamento);
		},
		async salvar() {
			const fieldsAreValid = await this.$validator.validateAll();

			if (fieldsAreValid)
				this.dialog_justificativa = true;
			else {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Dados inválidos',
					color: 'error'
				});
			}
		},
		async confirmar() {
			const items = [];
			this.items
				.filter(item => item.medicamento && item.medicamento.id && item.qtd_comprimidos > 0)
				.forEach(item => {
					items.push({
						quantidade: Number(item.qtd_comprimidos),
						paciente_id: this.paciente.id,
						medicamento_id: item.medicamento.id,
						ean: item.ean,
						produto: item.medicamento.produto,
						observacao: this.justificativa,
						validade: item.validade,
						lote: item.lote
					});
				});

			this.salvando = true;
			MovimentacaoPresenter.storeEntrada({ items, subscriptionId: this.assinatura_id })
				.then(() => {
					const estoque = {};
					items.forEach(item => {
						if (!estoque[item.medicamento_id]) {
							estoque[item.medicamento_id] = {
								produto: item.produto,
								quantidade: Number(item.quantidade)
							};
						} else
							estoque[item.medicamento_id].quantidade += Number(item.quantidade);
					});
					this.$emit('estoque-atualizado', estoque);
					this.$emit('update:close', false);
				})
				.finally(() => {
					this.salvando = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__text {
  overflow-y: scroll !important;
}
</style>
