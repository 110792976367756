var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('LoaderDialog'):_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"540"},scopedSlots:_vm._u([(_vm.visibleActivator)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-flex',_vm._g({staticClass:"button pointer",attrs:{"xs3":""},on:{"click":function($event){return _vm.getBoxResetInfo()}}},on),[_c('div',{staticClass:"q-row justify-center items-center"},[_c('v-icon',{staticClass:"col-12"},[_vm._v(" fas fa-undo-alt ")]),_c('span',{staticClass:"col-12 text-center"},[_vm._v(" Resetar Box ")])],1)])]}}:null],null,true),model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}},[_c('v-card',{staticClass:"farme-dialog"},[_c('section',{staticClass:"farme-dialog-header"},[_vm._v(" Reset da Box ")]),_c('section',{staticClass:"farme-dialog-body"},[(_vm.resetInfo)?[(!_vm.resetInfo.billed)?_c('p',[_vm._v("Você pode resetar a Box sem perdas operacionais!")]):[_c('p',[_vm._v("Ops, essa Box já foi faturada!")]),_c('p',[_vm._v("Para seguir com o reset dessa Box teremos que devolver os medicamentos faturados e depois gerar um novo pedido.")]),_c('p',[_vm._v("Para seguir o processo preencha os campos abaixo:")])],_c('TextDateField',{directives:[{name:"validate",rawName:"v-validate",value:({ required: false }),expression:"{ required: false }"}],attrs:{"box":"","flat":"","background-color":"white","label":"Início","placeholder":"Ex: 01/01/2024","data-vv-name":"Data de início","error-messages":_vm.errors.collect('Data de início')},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}}),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"background-color":"white","box":"","flat":"","label":"Justificativa","data-vv-name":"Justificativa","error-messages":_vm.errors.collect('Justificativa'),"items":[
						'Alteração na box fora do prazo',
						'Falta de medicamento no Picking',
						'Retorno de Pausa',
						'Aproveitamento de receita',
						'Outros problemas de separação'
					]},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}}),_c('section',{staticClass:"mb-4"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Medicamentos presentes nessa Box:")]),_c('MedicineTable',{attrs:{"items":_vm.resetInfo.items,"selectable":_vm.resetInfo.billed},on:{"selected":_vm.setSelectedItems}}),(_vm.resetInfo.billed)?_c('small',[_vm._v("Selecione os medicamentos que devem ser estornados no estoque do paciente")]):_vm._e()],1),_c('v-checkbox',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"label":"Declaro que devolvi todos os itens para a colmeia","hide-details":"","data-vv-name":"Declaro devolvi todos os itens para a colmeia","error-messages":_vm.errors.collect('Declaro devolvi todos os itens para a colmeia')},model:{value:(_vm.form.colmeiaConfirmed),callback:function ($$v) {_vm.$set(_vm.form, "colmeiaConfirmed", $$v)},expression:"form.colmeiaConfirmed"}})]:_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],2),(!_vm.loading)?_c('section',{staticClass:"farme-dialog-footer"},[_c('v-btn',{attrs:{"color":"green darken-1","flat":"flat"},on:{"click":function($event){_vm.opened = false}}},[_vm._v(" Cancelar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.resetbox}},[_vm._v(" Resetar Box ")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }