<template>
	<Dialog titulo="Endereços" :subtitulo="assinatura.paciente.responsaveis[0].nome" @close="$emit('update:close', false)"
		btn_acao="Escolher" @acao="escolherEndereco">
		<v-container fluid grid-list-xl class="pa-0">
			<v-layout wrap>
				<v-flex xs12 v-if="tem_endereco">
					<v-layout wrap fill-height>
						<v-flex xs12 md4 v-for="(item, index) in items" :key="item.id">
							<ItemEndereco :item="item" :is-selecionado="index_selecionado === index"
								@selecionado="index_selecionado = index"
								@delete="removerEndereco(item.id)" @editarEndereco="editarEndereco(index)"/>
						</v-flex>

						<v-flex xs12 md4>
							<v-card :class="novo_endereco ? 'selecionado' : null" @click="novoEndereco">
								<v-card-text class="text-xs-center flex-center">
									<span class="primary--text">
										<v-icon small class="mr-2" color="primary">add</v-icon>
										Cadastrar novo endereço
									</span>
								</v-card-text>
							</v-card>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>

		<v-dialog persistent scrollable v-model="dialog_crud" transition="fade" max-width="980px">
			<transition>
				<DialogEndereco v-if="dialog_crud" :item_pai="item_selecionado"
					:assinatura-id="assinatura.id"
					:close.sync="dialog_crud" @inserir="salvo"/>
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_editar_endereco" transition="fade" max-width="980px">
			<transition>
				<DialogEndereco v-if="dialog_editar_endereco" :item_editavel="endereco_selecionado"
					:assinatura-id="assinatura.id"
					:close.sync="dialog_editar_endereco" @enderecoEditado="end => editar(end)" :editando="loading"/>
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import { ListagemMixin } from '@Mixins/listagem';
import { DefaultPresenter } from '@Presenter/default-presenter';
import { EnderecoPresenter } from '@Presenter/endereco-presenter';
import DialogEndereco from './dialog-endereco';
import ItemEndereco from './item-endereco';

export default {
	name: 'Enderecos',
	props: {
		assinatura: {
			type: Object,
			required: true
		}
	},
	components: { ItemEndereco, DialogEndereco },
	mixins: [ListagemMixin],
	data: () => ({
		type: 'endereco',
		index_selecionado: -1,
		dialog_remover_endereco: false,
		dialog_editar_endereco: false,
		endereco_selecionado: {},
		loading: false
	}),
	methods: {
		removerEndereco(id) {
			DefaultPresenter({ prefix: 'endereco', entity: 'Endereço' }).destroy(id);
			this.escolherEndereco();
		},
		onResponse(response) {
			this.items = response;
			if (response.length > 0)
				this.index_selecionado = this.items.findIndex(end => end.id === this.assinatura.endereco_id);
		},
		getIndexProps() {
			return { assinatura_id: this.assinatura.id };
		},
		escolherEndereco() {
			const endereco = this.items[this.index_selecionado];
			this.$emit('trocar-endereco', endereco);
		},
		novoEndereco() {
			this.item_selecionado = {};
			this.dialog_crud = true;
		},
		salvo(item) {
			this.items.push(item);
			this.index_selecionado = this.items.length - 1;
			this.escolherEndereco();
		},
		editarEndereco(index) {
			this.endereco_selecionado = this.items[index];
			this.dialog_editar_endereco = true;
			this.index_selecionado = index;
		},
		editar(item) {
			const request = {
				assinatura_id: this.assinatura.id,
				bairro: item.bairro,
				cep: this.$functions.onlyNumber(item.cep),
				cidade: item.cidade,
				complemento: item.complemento || null,
				estado: item.estado,
				id: this.endereco_selecionado.id,
				logradouro: item.logradouro,
				numero: item.numero,
				observacao: item.observacao || null,
				latitude: item.latitude || null,
				longitude: item.longitude || null
			};
			this.loading = true;
			EnderecoPresenter.editarEndereco(this.assinatura.id, request)
				.then(response => {
					this.index();
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Endereço atualizado' });
					this.$emit('trocar-endereco', response);
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao atualizar endereço' });
				}).finally(() => {
					this.dialog_editar_endereco = false;
					this.loading = false;
				});
		}
	},
	computed: {
		tem_endereco() {
			return this.items.length > 0;
		},
		novo_endereco() {
			return this.index_selecionado === -1;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.v-card {
  height: 100%;
  cursor: pointer;
  border: 2px solid #fff;

  &.selecionado {
		border: 2px solid $success;
  }

  .v-card__text {
	height: 100%;

		&.flex-center {
			font-weight: 600;
		}
  }
}
</style>
