import { getCurrentCompanyUnit } from '@Config/unidades';

const razao_social = 'Far.me Farmacoterapia Otimizada';

const endereco_bh = {
	logradouro: 'Av. Cristiano Machado',
	numero: 640,
	bairro: 'Sagrada Família',
	cidade: 'Belo Horizonte',
	estado: 'Minas Gerais',
	cep: '31030-514'
};

const endereco_sp = {
	logradouro: 'Al. Joaquim Eugenio de Lima',
	numero: 198,
	bairro: 'Jardim Paulista',
	cidade: 'São Paulo',
	estado: 'São Paulo',
	cep: '01403-000'
};

const infos_bh = [
	'Alvará Sanitário: 2019080718\n',
	'Autorização de Funcionamento da Empresa (AFE): 7.48850.4\n',
	'Farmacêutica Responsável: Bruna Godinho Rocha - CRF MG 40307\n',
	'Horário de Funcionamento: segunda a sexta de 09:00h às 18:00h'
];

const infos_sp = [
	'Autorização de Funcionamento - Processo: 355030801-477-000362-1-8\n',
	'Autorização/MS: 0.12469.2\n',
	'Farmacêutico Responsável: Douglas Ganzarolli Ribeiro da Silva - CRF SP 104527\n',
	'Horário de Funcionamento: segunda a sexta de 09:00h às 18:00h'
];

let enderecoAtual;
let infosAtuais;
let telefones;

export const getInfosEmpresa = () => {
	const infos = {};

	if (getCurrentCompanyUnit().key === 'SP') {
		enderecoAtual = endereco_sp;
		infosAtuais = infos_sp;
		telefones = '(11) 3283-3072 | (11) 99832-4885';
	} else {
		enderecoAtual = endereco_bh;
		infosAtuais = infos_bh;
		telefones = '(31) 2528-4229 | (31) 99642-4336';
	}

	infos.razaoSocial = razao_social;
	infos.endereco = enderecoAtual;
	infos.infos = infosAtuais;
	infos.telefone = telefones;
	infos.email = 'contato@farme.com.br';

	return infos;
};
