<template>
	<div
		class="d-flex justify-space-between items-center"
		style="border-radius: 10px; background-color: #FFF3E2; height: 130px;">
		<div
			class="d-flex items-center"
			style="border: 1px solid #DCDCDC !important; border-radius: 10px 0 0 10px; width: 200px;height:100%;background-color: white; ">
			<v-img
				src="/img/order-item.webp"
				lazy-src="/img/order-item.webp"
				alt="Imagem ilustrativa do produto"
				aspect-ratio="1"
				contain>
			</v-img>
		</div>

		<section class="d-flex items-center pa-4" style="border-radius: 0 10px 10px 0; width: 100%">
			<div style="my-4">
				<h5 class="mb-2">Categoria</h5>
				<h4 class="mb-2">{{ item.name }}</h4>
				<p class="mb-2">{{ item.presentation }} | {{ item.brand }}</p>
				<div class="text-start">
					<v-avatar color="rgba(45, 206, 113, 0.2)" size="25" class="mr-2">
						<v-icon color="success" size="18">fas fa-exclamation-circle</v-icon>
					</v-avatar>

					<v-avatar color="rgba(247, 186, 42, 0.3)" size="25" class="mr-2">
						<v-icon color="#F7BA2A" size="15" >fas fa-exclamation-triangle</v-icon>
					</v-avatar>
				</div>
			</div>
		</section>

		<section style="width: 150px">
			<v-layout column>
				<v-flex class="pa-0 mb-1">
					<h5>ID</h5>
					<span style="opacity: 60%;">{{item.externalId}}</span>
				</v-flex>
				<v-flex class="pa-0 mt-1">
					<h5>Quantidade</h5>
					<span>{{ item.quantity }} caixa(s)</span>
				</v-flex>
			</v-layout>
		</section>
	</div>
</template>

<script>
export default {
	name: 'OrderItemsItem',
	props: {
		item: {
			required: true,
			type: Object
		}
	}
};
</script>

<style lang="scss" scoped>

h4 {
	color: #342B1D;
	// font-family: 'Open Sans';
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

h5 {
	color: rgba(52, 43, 29, 0.70);
	// font-family: Inter;
	font-size: 8px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.48px;
	text-transform: uppercase;
}

p {
	color: #342B1D;
	// font-family: Open Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-transform: uppercase;
}

span {
	font-size: 16px;
	line-height: 17px;
	font-weight: 700;
	color: #342B1D;
}

</style>
