<template>
	<div class="perfil-wrapper">
		<!-- Perfil Header -->
		<Header :salvando="salvando" @salvar-perfil="salvarPerfil" />

		<!-- Conteúdo, após carregado -->
		<transition name="fade">
			<!-- container-wrapper -->
			<v-container fluid grid-list-xl class="container-wrapper mt-md-5">
				<v-card>
					<v-card-text class="pr-md-5 mb-3">
						<Form :campos="campos" :item="user" />
						<v-flex xs12 md6 v-if="crf" class="pl-0 pr-2">
							<v-text-field label="CRF" box v-model="user.crf"></v-text-field>
						</v-flex>
					</v-card-text>
				</v-card>
			</v-container><!-- Fim, container-wrapper -->
		</transition><!-- Fim, conteúdo após carregado -->
	</div>
</template>

<script>
import default_field from '@Fields/user-fields';
import { UserPresenter } from '@Presenter/user-presenter';
import Header from './header';

export default {
	name: 'MeuPerfil',
	$_veeValidate: { validator: 'new' },
	components: { Header },
	data: () => ({
		user: {},
		salvando: false,
		campos: {
			...default_field,
			email: {
				...default_field.email,
				disabled: true
			}
		}
	}),
	created() {
		this.user = this.$lodash.cloneDeep(this.$store.getters.contexto.user);
		delete this.campos.permissao;
		if (!this.$store.getters.eh_rt)
			delete this.campos.crf;
	},
	methods: {
		salvarPerfil() {
			this.$validator.validateAll().then(valido => {
				if (!valido) {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
					return;
				}
				this.salvando = true;
				UserPresenter.update(this.user)
					.then(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Perfil alterado com sucesso' });
					})
					.finally(() => {
						this.salvando = false;
					});
			});
		}
	},
	computed: {
		crf() {
			if ((this.user.permissao === 12 || this.user.permissao.value === 12))
				return true;
			return false;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.perfil-wrapper {
  .container-wrapper {
    .layout-wrapper {
      max-width: $break-point-lg;
      margin: 0 auto;

      ::v-deep .vue-image-crop-upload {
        .vicp-wrap {
          width: 100%;
          height: auto;

          @media screen and (min-width: $break-point-sm) {
            width: 435px;
            height: 360px;
          }
        }
      }

      .align-center {
        display: flex;
        justify-content: center;
        align-items: center;

        .v-avatar {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.06);
          transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

          &:hover {
            background: rgba(0, 0, 0, 0.12);
          }

          &:active {
            background: rgba(0, 0, 0, 0.06);
          }
        }
      }
    }

    .v-card {
      max-width: $break-point-lg;
      margin: 0 auto;
    }
  }
}
</style>
