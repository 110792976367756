import $http from '@Config/axios';
import $store from '@Store';

export const DefaultPresenter = ({ prefix, entity }) => ({
	index: params => {
		if (params) {
			const data = { ...params, visao_colaborador: $store.getters.visao_colaborador ? 1 : 0 };
			return $http.post(`${prefix}/index`, data);
		}
		return $http.get(prefix);
	},
	show: id => $http.get(`${prefix}/${id}`),
	store: params => new Promise((resolve, reject) => {
		$http.post(prefix, params)
			.then(response => {
				$store.dispatch('SHOW_SNACKBAR', { message: `${entity} salvo(a) com sucesso!` });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	}),
	update: (params, show_message = true) => new Promise((resolve, reject) => {
		$http.put(`${prefix}/${params.id}`, params)
			.then(response => {
				if (show_message)
					$store.dispatch('SHOW_SNACKBAR', { message: `${entity} atualizado(a) com sucesso!` });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	}),
	destroy: id => new Promise((resolve, reject) => {
		$http.delete(`${prefix}/${id}`)
			.then(response => {
				$store.dispatch('SHOW_SNACKBAR', { message: `${entity} apagado(a) com sucesso!` });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	}),
	restore: id => new Promise((resolve, reject) => {
		$http.patch(`${prefix}/${id}`)
			.then(response => {
				$store.dispatch('SHOW_SNACKBAR', { message: `${entity} restaurado(a) com sucesso!` });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	})
});
