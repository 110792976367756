import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';
import { DefaultPresenter } from './default-presenter';

const prefix = 'medicamento';
const entity = 'Medicamento';

export const MedicamentoPresenter = {
	...DefaultPresenter({ prefix, entity }),
	index: (campos = []) => $http.post(`${prefix}/index`, { campos }),
	bulkUpdate: medicamentos => $http.put(`${prefix}/bulkUpdate`, { medicamentos }),
	find: (id, company_unit_id) => $http.get(buildQueryParams(`${prefix}/${id}`, { company_unit_id })),
	countAssinaturas: () => $http.get(`${prefix}/etc/countAssinaturas`),
	principiosAtivos: () => $http.get(`${prefix}/etc/principiosAtivos`),
	search(params) {
		return new Promise((resolve, reject) => {
			$http.post(`${prefix}/search`, params)
				.then(response => {
					response.items.forEach(med => {
						med.liquido = !!med.liquido;
					});
					resolve(response);
				})
				.catch(response => reject(response));
		});
	},
	/**
	 *
	 * @param {Number} medicineId
	 * @param {Array<Number>} attachPrescriptionsIds
	 * @param {Array<Number>} detachPrescriptionsIds
	 */
	syncPrescriptions: (medicineId, attachPrescriptionsIds = [], detachPrescriptionsIds = []) => $http.post(`${prefix}/${medicineId}/vincular-receitas`, { attach: attachPrescriptionsIds, detach: detachPrescriptionsIds })
};
