export const url_api = {
	Prod: 'https://farmebox-back.farme.com.br/api',
	Lab: 'https://farmebox-back-dev.infra.farme.com.br/api',
	Dev: 'https://farmebox-back-dev.infra.farme.com.br/api',
	Staging: 'https://farmebox-back-stg.infra.farme.com.br/api',
	Local: 'http://localhost:3333/api'
};
export const FARME_AI_CONFIG = {
	Prod: {
		url: 'https://farme-ai.farme.com.br/api/v1',
		token: '0VX4o7TzjQakAK3xOmHUw2EcefA1H1nJYJ8zMOdqmGxhKvA5OFyOBcKhirFiER9V'
	},
	Staging: {
		url: 'https://farme-ai-dev.infra.farme.com.br/api/v1',
		token: 'eimoh9YeeQuiedeemeec3AhshiekeaJ3'
	},
	Dev: {
		url: 'https://farme-ai-dev.infra.farme.com.br/api/v1',
		token: 'eimoh9YeeQuiedeemeec3AhshiekeaJ3'
	},
	Local: {
		url: 'https://farme-ai-dev.infra.farme.com.br/api/v1',
		token: 'eimoh9YeeQuiedeemeec3AhshiekeaJ3'
	}
};
