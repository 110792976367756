<template>
	<Dialog titulo="Análise de prescrição" @close="$emit('update:close', false)">
		<v-tabs centered color="green" dark icons-and-text>
			<v-tab>
				Análise simplificada
				<v-icon>supervised_user_circle</v-icon>
			</v-tab>
			<v-tab-item>
				<v-card flat>
					<TabelaAnaliseSimplificada :assinatura_id="assinatura_id" />
				</v-card>
			</v-tab-item>
		</v-tabs>
	</Dialog>
</template>

<script>
import TabelaAnaliseSimplificada from './tabela-analise-simplificada';

export default {
	name: 'DialogAnalisePrescricao',
	components: { TabelaAnaliseSimplificada },
	props: {
		assinatura_id: {
			type: Number,
			required: true
		}
	},
	data: () => ({}),
	methods: {}
};
</script>
