import { OrderPresenter } from '@Presenter/order-presenter';
import store from '@Store';
/**
 * @param {Number} orderId
 * @param {String} typeProcess
 * @returns {Promise<boolean>} isValid
 */
export async function validateProcessIsAllowed(orderId, typeProcess) {
	let isValid;
	try {
		const process = await OrderPresenter.getProcessByOrderAndProcessType(orderId, typeProcess);

		if (process.status === 'SUCCESS') {
			store.dispatch('SHOW_SNACKBAR', { message: 'Parece que esse processo já foi concluido pra esse pedido, por favor recarregue a página', color: 'error' });
			isValid = false;
			return isValid;
		}

		isValid = true;
		return isValid;
	} catch (error) {
		store.dispatch('SHOW_SNACKBAR', { message: 'Este processo não existe para o pedido selecionado, por favor recarregue a página', color: 'error' });
		isValid = false;
		return isValid;
	}
}
