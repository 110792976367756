<template>
	<v-dialog persistent scrollable v-model="visible" transition="fade" max-width="800px">
		<transition>
			<DialogMedicamento
				v-if="visible"
				:close.sync="visible"
				:medicamento_selecionado="medicamento_selecionado"
				@medicamento-salvo="medicamentoSalvo"
				@fechar-dialog="fechaModal()"
				:eh_orcamento="eh_orcamento"
				:medicamentosPorHorarios="medicamentosPorHorarios"
				:orcamento_filial_id="orcamento_filial_id"
				:fluxo="fluxo"
				:assinaturaId="assinaturaId"
				:tipoCliente="tipoCliente"
				:discount_percentage="discount_percentage"
				:somente_leitura="somente_leitura"
				:permiteTrocarMedicamento="permiteTrocarMedicamento"
				:restHomeId="restHomeId"
				:externalBilling="externalBilling" />
		</transition>
	</v-dialog>
</template>

<script>
import { isEqual } from 'lodash';
import DialogMedicamento from './dialog-medicamento.vue';

export default {
	name: 'IndexDialogMedicamento',
	components: { DialogMedicamento },
	props: {
		discount_percentage: {
			type: Number,
			required: false,
			default: 0
		},
		medicamentos: {
			type: Array,
			required: true
		},
		visible: {
			type: Boolean,
			required: true
		},
		somente_leitura: {
			type: Boolean,
			default: false,
			required: false
		},
		eh_orcamento: {
			type: Boolean,
			default: false
		},
		orcamento_filial_id: {
			type: Number,
			required: false
		},
		fluxo: {
			type: String,
			required: false
		},
		assinaturaId: {
			type: Number,
			required: false
		},
		tipoCliente: {
			type: String,
			required: false
		},
		medicamento_selecionado: {
			default: () => ({})
		},
		medicamentosPorHorarios: {
			type: Object,
			default: () => ({})
		},
		permiteTrocarMedicamento: {
			type: Boolean,
			default: false
		},
		externalBilling: {
			type: Boolean,
			default: false
		},
		restHomeId: {
			required: false
		}
	},
	methods: {
		medicamentoSalvo(newOrUpdatedMedicine) {
			const i = this.medicamentos.findIndex(med => isEqual(med, this.medicamento_selecionado));
			newOrUpdatedMedicine.alterado = true;

			const newMedicineTimeSlots = this.extrairHorarios(newOrUpdatedMedicine);

			const horariosEsgotados = Object.entries(this.medicamentosPorHorarios).filter(
				([horario, qtd]) => qtd >= 11 && newMedicineTimeSlots.includes(horario)
			);

			if (this.eh_novo) {
				if (horariosEsgotados.length > 0) {
					horariosEsgotados.forEach(([horario]) => {
						this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: `Horário ${horario} excedeu a quantidade máxima de medicamentos (11)` });
					});
					return;
				}
				this.medicamentos.push(newOrUpdatedMedicine);
			} else {
				const oldMedicineTimeSlots = this.extrairHorarios(this.medicamento_selecionado);
				const novosHorariosEsgotados = horariosEsgotados.filter(([hr]) => !oldMedicineTimeSlots.includes(hr));

				if (novosHorariosEsgotados.length > 0) {
					novosHorariosEsgotados.forEach(([horario]) => {
						this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: `Horário ${horario} excedeu a quantidade máxima de medicamentos (11)` });
					});
					return;
				}
				this.medicamentos.splice(i, 1, newOrUpdatedMedicine);
			}

			this.$root.$emit('ALTEROU', true);
			this.$root.$emit('ALTEROU_MEDICAMENTO');
			this.$emit('medicamento-salvo', newOrUpdatedMedicine);
			this.fechaModal();
		},
		extrairHorarios(medicamento) {
			return medicamento.posologia.horarios.map(h => h.horario);
		},
		fechaModal() {
			this.$emit('update:close', false);
		}
	},
	computed: {
		eh_novo() {
			return typeof this.medicamento_selecionado.id !== 'number';
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
.v-card {
  ::v-deep .v-card {
    box-shadow: none !important;
  }
}
</style>
