<template>
	<div class="px-md-5">
		<HeaderProcesso
			titulo="Conferência"
			subtitulo="Faça a conferências das boxes para garantir que foram produzidas corretamente."
			subtitulo2="Aqui estão listadas todas as boxes que acabaram de ser produzidas."
			@busca="val => busca = val" />

		<!-- Conteúdo -->
		<DataTableProcesso
			:boxes="boxes"
			:loading="carregando"
			:search="busca"
			:buttons="buttons"
			@selected-updated="arr => selecionados = arr" />

		<!-- Dialog Informações Cliente -->
		<v-dialog persistent scrollable v-model="dialog_informacoes_cliente" transition="fade" max-width="820px">
			<transition>
				<DialogInformacoesBox v-if="dialog_informacoes_cliente" :assinatura_id="item_selecionado.assinatura_id" :box_id="item_selecionado.box_id" :close.sync="dialog_informacoes_cliente" />
			</transition>
		</v-dialog>

		<!-- Dialog Ocorrências -->
		<v-dialog persistent scrollable v-model="dialog_ocorrencias" transition="fade" max-width="700px">
			<transition>
				<DialogOcorrencias v-if="dialog_ocorrencias" :box_id="item_selecionado.id" :request="updateStatus('CHECKED')" status="CHECKED" :close.sync="dialog_ocorrencias" />
			</transition>
		</v-dialog>

		<!-- Dialog Conferência Digital -->
		<v-dialog
			persistent scrollable
			v-model="dialog_conferencia_digital"
			transition="fade">
			<transition>
				<DialogConferenciaDigital
					v-if="dialog_conferencia_digital"
					:box="item_selecionado"
					:box_id="item_selecionado.id"
					:request="updateStatus('CHECKED')"
					:close.sync="dialog_conferencia_digital"/>
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { ProcessoMixin } from '@Mixins/processo';
import DialogInformacoesBox from '@Componentes/dialog-informacoes-box';
import DialogOcorrencias from '@Componentes/dialog-ocorrencias';
import DialogConferenciaDigital from '@Componentes/dialog-conferencia-digital';
import unleash from '@Util/unleash';
import { featureFlags } from '@Consts/feature-flags';

export default {
	name: 'Conferencia',
	components: { DialogInformacoesBox, DialogOcorrencias, DialogConferenciaDigital },
	mixins: [ProcessoMixin],
	data: () => ({
		statusToShow: ['PRODUCED'],
		fs_conferencia_digital_enabled: unleash.isEnabled(featureFlags.conferencia_digital),
		dialog_ocorrencias: false,
		dialog_informacoes_cliente: false,
		dialog_conferencia_digital: false
	}),
	created() {
		unleash.on(
			'update',
			() => {
				this.fs_conferencia_digital_enabled = unleash.isEnabled(featureFlags.conferencia_digital);
			}
		);
		if (this.fs_conferencia_digital_enabled) {
			this.buttons = [
				{
					label: 'Informações do cliente',
					icon: 'fas fa-prescription-bottle-alt',
					onClick: item => this.abrirDialog(item, 'dialog_informacoes_cliente')
				},
				{
					label: 'Conferir',
					icon: 'fas fa-check',
					onClick: item => this.abrirDialog(item, 'dialog_ocorrencias')
				},
				{
					label: 'Conferência Digital',
					icon: 'fas fa-camera',
					onClick: item => this.abrirDialog(item, 'dialog_conferencia_digital')
				}
			];
		} else {
			this.buttons = [
				{
					label: 'Informações do cliente',
					icon: 'fas fa-prescription-bottle-alt',
					onClick: item => this.abrirDialog(item, 'dialog_informacoes_cliente')
				},
				{
					label: 'Conferir',
					icon: 'fas fa-check',
					onClick: item => this.abrirDialog(item, 'dialog_ocorrencias')
				}
			];
		}
	}
};
</script>
