import { render, staticRenderFns } from "./loader-dialog.vue?vue&type=template&id=04f8c414&scoped=true"
import script from "./loader-dialog.vue?vue&type=script&lang=js"
export * from "./loader-dialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04f8c414",
  null
  
)

export default component.exports