<template>
	<div class="dialog-container">
		<div class="purple-title q-row py-2 px-4 items-center">
			<span class="titulo">
				Criar novo orçamento
			</span>
			<v-spacer />
			<v-btn icon dark flat @click="close">
				<v-icon>close</v-icon>
			</v-btn>
		</div>
		<v-layout wrap>
			<v-flex xs6>
				<Orcamento :id="id" />
			</v-flex>
			<v-flex xs6>
				<NovoOrcamento ref="orcamento" class="pa-3"
					@loading="onLoadingChanged" @saved="onSaved" />
			</v-flex>
		</v-layout>
		<div class="footer text-end pa-2">
			<v-btn depressed class="primary" :loading="loading" @click="submeter">
				Salvar
			</v-btn>
		</div>
	</div>
</template>

<script>
import { FarmeOSPresenter } from '@Presenter/farmeos-presenter';
import NovoOrcamento from '@Componentes/dialog-orcamento/orcamento';
import Orcamento from './orcamento';

export default {
	name: 'dialog-novo-orcamento',
	components: { Orcamento, NovoOrcamento },
	props: {
		id: {
			type: String,
			required: true
		}
	},
	data: () => ({
		loading: false
	}),
	methods: {
		onLoadingChanged(bool) {
			this.loading = bool;
		},
		submeter() {
			return this.$refs.orcamento.submeter();
		},
		onSaved() {
			this.loading = true;
			FarmeOSPresenter.deleteQuotation(this.id)
				.finally(() => {
					this.loading = false;
					this.$emit('saved');
				});
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>

<style lang="scss" scoped>
.dialog-container {
	background: #fafafa;
	width: 100%;

	.titulo {
		font-size: 20px;
	}

	.layout {
		overflow-y: auto;
		height: calc(100vh - 64px - 64px);
	}

	.footer {
		text-align: end;
		background: white;
		bottom: 0;
		position: fixed;
		right: 0;
		left: 0;
	}
}
</style>
