const statusEnum = {
	PENDING: 'PENDING',
	STARTED: 'STARTED',
	FINISHED: 'FINISHED',
	CANCELLED: 'CANCELLED',
	PICKED_UP: 'PICKED_UP',
	DELIVERED: 'DELIVERED',
	FAILED: 'FAILED'
};

const statusLabel = {
	PENDING: 'Pendente',
	STARTED: 'Em rota',
	FINISHED: 'Concluido',
	CANCELLED: 'Cancelado',
	PICKED_UP: 'Coletado',
	DELIVERED: 'Entregue',
	FAILED: 'Falha'
};

const logisticaStatus = {
	IN_PICKING: 'IN_PICKING',
	IN_BILLING: 'IN_BILLING',
	IN_PRODUCTION: 'IN_PRODUCTION',
	IN_CONFERENCE: 'IN_CONFERENCE',
	IN_SHIPMENT: 'IN_SHIPMENT',
	DESPATCHED: 'DESPATCHED',
	DELAYED: 'DELAYED'
};

const logisticaStatusLabels = {
	[logisticaStatus.DESPATCHED]: 'Expedido',
	[logisticaStatus.DELAYED]: 'Atrasado',
	[logisticaStatus.IN_PICKING]: 'Picking',
	[logisticaStatus.IN_BILLING]: 'Faturamento',
	[logisticaStatus.IN_PRODUCTION]: 'Produção',
	[logisticaStatus.IN_CONFERENCE]: 'Conferência',
	[logisticaStatus.IN_SHIPMENT]: 'Expedição'
};

export {
	statusEnum,
	statusLabel,
	logisticaStatus,
	logisticaStatusLabels
};
