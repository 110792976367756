<template>
	<Dialog titulo="Upload Nota Fiscal" :titulo_extra="paciente.nome" subtitulo="Escolha a nota fiscal (XML) para fazer upload"
		btn_acao_extra="Gerar etiqueta" btn_acao="Salvar" btn_cancelar="Cancelar" :btn_acao_extra_disable="medicamentos.length === 0"
		@acao_extra="dialog_pdf = true" @acao="close" @close="close">

		<v-container v-if="existe_medicamentos" fluid grid-list-lg class="pa-0">
			<v-layout wrap align-center>
				<v-flex v-if="upload_feito" xs3 class="mr-2" style="max-width: max-content">
					<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
						<path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
					</svg>
				</v-flex>
				<v-flex v-if="upload_feito" xs9>
					<h3>Upload feito com sucesso.</h3>
					<p class="mb-0">
						<strong>{{ medicamentos.length }}</strong>
						{{ medicamentos.length === 1 ? 'medicamento' : 'medicamentos' }}.
					</p>
				</v-flex>
				<v-flex xs12>
					<v-data-table :headers="headers" :items="medicamentos" no-results-text="Nenhum resultado encontrado">
						<template slot="items" slot-scope="props">
							<tr class="text-xs-left">
								<td>{{ props.item.produto }}</td>
								<td>{{ props.item.lote }}</td>
								<td>{{ props.item.validade }}</td>
								<td class="text-xs-center" style="display: flex">
									<v-tooltip top>
										<v-btn small icon @click.stop="removerMedicamento(props.item)" slot="activator">
											<v-icon color="red">close</v-icon>
										</v-btn>
										<span>Remover medicamento</span>
									</v-tooltip>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</v-container>

		<v-container fluid grid-list-lg class="pa-0">
			<v-progress-linear v-if="is_loading" color="purple" indeterminate />
			<template v-else>
				<input @change="carregarXML" type="file" ref="upload" style="display: none" accept="text/xml" />
				<p v-if="uploading" class="mt-2 text-xs-center">
					<strong>Carregando a nota fiscal, por favor aguarde...</strong>
				</p>
				<v-btn block depressed class="ml-0 green white--text" @click="$refs.upload.click()">
					<v-icon small class="mr-2">cloud_upload</v-icon>
					Escolher nota fiscal (XML)
				</v-btn>
				<v-btn block depressed class="ml-0 green white--text" @click="dialog_adicionar_medicamento = true">
					<v-icon small class="mr-2">add</v-icon>
					Inserir medicamento manualmente
				</v-btn>
			</template>
		</v-container>

		<!-- Etiqueta dialog -->
		<v-dialog persistent v-model="dialog_pdf" fullscreen hide-overlay scrollable
			transition="fade">
			<transition>
				<DialogEtiqueta v-if="dialog_pdf" :medicamentos="medicamentos" :paciente="paciente"
					:close.sync="dialog_pdf" />
			</transition>
		</v-dialog>

		<!-- Adicionar Medicamento dialog -->
		<v-dialog persistent v-model="dialog_adicionar_medicamento" hide-overlay scrollable transition="fade"
			max-width="380px">
			<transition>
				<DialogAdicionarMedicamento v-if="dialog_adicionar_medicamento" :medicamentos="medicamentos"
					:close.sync="dialog_adicionar_medicamento" @medicamento-salvo="onMedicamentoSalvo" />
			</transition>
		</v-dialog>

	</Dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import DialogEtiqueta from './dialog-etiqueta-xml';
import DialogAdicionarMedicamento from './dialog-adicionar-medicamento';

export default {
	name: 'DialogUploadNota',
	components: { DialogEtiqueta, DialogAdicionarMedicamento },
	props: {
		box_id: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		dialog_pdf: false,
		dialog_adicionar_medicamento: false,
		medicamentos: [],
		paciente: {},
		uploading: false,
		is_loading: false,
		upload_feito: false,
		headers: [
			{ text: 'Produto', value: 'produto' },
			{ text: 'Lote', value: 'lote', sortable: false },
			{ text: 'Validade', value: 'validade', sortable: false },
			{ text: '', sortable: false }
		]
	}),
	created() {
		BoxPresenter.show(this.box_id)
			.then(response => {
				this.paciente = response.assinatura.paciente;
				this.medicamentos = response.medicamentos_nfe;
			});
	},
	methods: {
		close() {
			this.$emit('update:close', false);
		},
		update() {
			BoxPresenter.update({ id: this.box_id, medicamentos_nfe: JSON.stringify(this.medicamentos) }, false);
		},
		onMedicamentoSalvo(medicamento) {
			this.medicamentos.push(medicamento);
			this.update();
		},
		carregarXML(input) {
			this.uploading = true;
			const xml = input.target.files[0];
			if (!xml)
				return;
			const reader = new FileReader();
			reader.onload = e => {
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(e.target.result, 'text/xml');
				const json = this.$functions.xmlToJson(xmlDoc);
				const det = json.nfeProc.NFe.infNFe.det.length ? json.nfeProc.NFe.infNFe.det : [json.nfeProc.NFe.infNFe.det];
				this.medicamentos = this.medicamentos.concat(det.filter(d => !!d.prod.rastro)
					.reduce((prev, d) => {
						const rastro = d.prod.rastro.length ? d.prod.rastro : [d.prod.rastro];
						rastro.forEach(ras => {
							const valor = Number(d.prod.vProd) || 0;
							const desconto = Number(d.prod.vDesc) || 0;
							const valorUnitario = (valor - desconto);
							const qtd = Number(d.prod.qCom) || 1;
							prev.push({
								valor,
								desconto,
								valorUnitario,
								qtd,
								valorFinal: valorUnitario * qtd,
								produto: d.prod.xProd,
								lote: ras.nLote,
								validade: this.$functions.FormatDateFromRequest(ras.dVal)
							});
						});
						return prev;
					}, [])
					.sort((a, b) => (a.produto > b.produto ? 1 : -1)));
				this.update();
			};
			reader.readAsText(xml);
			this.upload_feito = true;
			this.uploading = false;
		},
		removerMedicamento(medicamento) {
			const i = this.medicamentos.findIndex(med => med.produto === medicamento.produto);
			if (i !== -1) {
				this.medicamentos.splice(i, 1);
				this.update();
			}
		}
	},
	computed: {
		existe_medicamentos() {
			return this.medicamentos.length > 0;
		}
	}
};
</script>

<style lang="scss" scoped>
$green: #7ac142;
$white: #fff;
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $green;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}
</style>
