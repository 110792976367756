<template>
	<header>
		<v-layout wrap class="mb-4">
			<v-flex xs12 md4>
				<h1 class="text--text" style="opacity: .8">Responsável</h1>
				<p>Utilize a busca aos lado para encontrar o Responsável.</p>
				<v-btn depressed class="primary mx-0 pl-2 pr-3" @click="$emit('novo-responsavel')">
					<v-icon small class="mr-2">add</v-icon>
					Adicionar responsável
				</v-btn>
			</v-flex>
			<v-flex xs12 md8>
				<v-layout wrap row>
					<v-flex md6>
						<v-text-field label="Buscar responsável" placeholder="Nome, e-mail ou CPF do responsável"
							append-icon="search" color="purple" @input="value => $emit('busca', value)" />
					</v-flex>
					<v-flex md6>
						<v-select append-icon="filter_list" color="purple" v-model="filtro" label="Filtros"
							:items="filtros" item-text="nome" item-value="status" @input="value => $emit('filtro', value)" />
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>

export default {
	name: 'ResponsavelHeader',
	props: {
		carregando: {
			type: Boolean,
			required: true
		}
	},
	data: () => ({
		filtro: 'ativo',
		filtros: [
			{ status: null, nome: 'Todos' },
			{ status: 'ativo', nome: 'Responsáveis ativos' },
			{ status: 'inativo', nome: 'Responsáveis inativos' }
		]
	})
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

::v-deep .v-toolbar {
  background: #fff;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.05);

  .v-toolbar__content {
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto !important;
    padding: 16px;

    @media screen and (min-width: $break-point-md) {
      padding: 16px 24px;
    }

    .badge {
      transform: translateY(-2px);
    }

    .navbar-buttons {
      display: flex;
      flex-wrap: wrap;
      min-height: 100%;
      align-items: center;
    }
  }
}
</style>
