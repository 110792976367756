<template>
	<v-card>
		<v-data-table
			ref="dataTableOrders"
			v-model="selected"
			:select-all="selectable"
			:headers="computedHeaders"
			:items="orders"
			:loading="loading"
			:no-data-text="loading ? 'Carregando...' : 'Nenhuma pedido encontrado'"
			no-results-text="Nenhum resultado encontrado"
			:rows-per-page-items="rowsPerPageItems"
			:total-items="totalItems"
			:pagination.sync="pagination"
			rows-per-page-text="Itens por página" item-key="id">
			<template slot="headers" slot-scope="props">
				<tr>
					<th v-if="selectable" class="pr-0">
						<v-checkbox :input-value="props.all" :indeterminate="props.indeterminate" primary
							hide-details @click.stop="toggleAll" />
					</th>
					<th v-for="(header, index) in props.headers" :key="index" :class="['column', { sortable: header.sortable }, header.align ? `text-xs-${header.align}` : 'text-xs-center', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
						@click="changeSort(header.value)">
						{{ header.text }}
						<v-icon small v-if="header.sortable">arrow_upward</v-icon>
					</th>
				</tr>
			</template>
			<template slot="items" slot-scope="props">
				<tr :active="props.selected" @click="!selectable && isRouteFarmePontual ? showOrder(props.item.id) : props.selected = !props.selected" >
					<td v-if="selectable" class="pr-0">
						<v-checkbox :input-value="props.selected" primary hide-details />
					</td>
					<td class="text-center">
						<router-link v-if="!isRouteFarmePontual" :to="{ path: `pedidos/${props.item.id}` }">
							{{ props.item.id }}
						</router-link>
						<span v-else>
							{{ props.item.id }}
						</span>
					</td>
					<td>{{ props.item.customer.subscriptionId || '-' }}</td>
					<td class="py-2" style="white-space: nowrap">
						<v-avatar size="24" class="mr-2">
							<span class="white--text">
								{{ props.item.customer.name.charAt(0) }}
							</span>
						</v-avatar>
						{{ props.item.customer.name }}
					</td>
					<td>
						{{ props.item.dueDate.split('T')[0] | formatDate('DD/MM/YYYY [(]dddd[)]') }}
					</td>
					<td>
						{{ `${props.item.customer.originId} - ${props.item.customer.originName}` || '-' }}
					</td>
					<td v-if="isRouteFarmePontual">
						<v-chip small disabled class="mx-0 white--text info" >
							{{ getDescription(props.item.status) }}
						</v-chip>
					</td>
					<td v-if="!isRouteFarmePontual && !isLoaderRow(props.item.id)" class="buttons" @click.stop>
						<template v-for="(btn, i) in buttons">
							<v-tooltip v-if="btn.condition ? btn.condition(props.item) : true" top :key="i">
								<v-icon class="actionButtons" slot="activator" :class="{
									'mr-3': buttons.length > 1 && i !== buttons.length - 1,
									'cursor-default': !btn.onClick || (btn.isDisabled && btn.isDisabled(props.item))
								}"
									:color="btn.color ? btn.color : btn.setColor ? btn.setColor(props.item) : '#000'"
									@click.stop="btn.onClick ? btn.onClick(props.item) : null">
									{{ btn.icon }}
								</v-icon>
								<span>{{ btn.label || btn.getLabel(props.item) }}</span>
							</v-tooltip>
						</template>
					</td>
					<td v-if="!isRouteFarmePontual && isLoaderRow(props.item.id)">
						<v-icon class="cursor-wait fa-spin" >
							{{'fas fa-spinner'}}
						</v-icon>

					</td>
				</tr>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
import { getDescriptionOrderStatus } from '@Consts/status-order';

export default {
	name: 'OrderDataTable',
	props: {
		orders: {
			type: Array,
			required: true
		},
		selectable: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			required: true
		},
		buttons: {
			type: Array,
			default: () => []
		},
		totalItems: {
			type: Number,
			default: 0
		},
		loaderSpecificRows: {
			type: Array,
			default: () => []
		}
	},
	created() {
		// Remove Ações se estiver na tela de listagem de pedidos
		const statusHeader = 5;
		if (this.isRouteFarmePontual)
			this.headers.pop();
		else
			this.headers.splice(statusHeader, 1);
	},
	data() {
		return {
			pagination: {
				descending: true,
				sortBy: 'order.id'
			},
			selected: [],
			headers: [
				{
					text: 'Pedido', value: 'order.id', sortable: true, show: true
				},
				{
					text: 'Assinatura', value: 'customer.subscriptionId', sortable: false, show: true, align: 'left'
				},
				{
					text: 'Cliente', value: 'customer.name', sortable: false, show: true, align: 'left'
				},
				{
					text: 'Data de entrega', value: 'order.dueDate', sortable: true, show: true, align: 'left'
				},
				{
					text: 'Origem', value: 'originName', sortable: false, show: true, align: 'left'
				},
				{
					text: 'Status', value: 'status', sortable: false, show: true, align: 'left'
				},
				{ text: 'Ações', show: true }
			]
		};
	},
	watch: {
		selected(arr) {
			this.$emit('selected-updated', arr);
		},
		pagination: {
			deep: true,
			handler(val) {
				this.$emit('pagination-updated', val);
			}
		}
	},
	computed: {
		rowsPerPageItems() {
			return [10, 15, 20, 45, 90];
		},
		computedHeaders() {
			return this.headers.filter(header => header.show);
		},
		isRouteFarmePontual() {
			return this.$route.name === 'Orders';
		}
	},
	methods: {
		toggleAll() {
			this.selected = this.selected.length ? [] : [...this.$refs.dataTableOrders.filteredItems];
		},
		getHeaderItemByValue(value) {
			return this.computedHeaders.find(itemHeader => itemHeader.value === value);
		},
		changeSort(column) {
			const itemHeader = this.getHeaderItemByValue(column);

			if (itemHeader && !itemHeader.sortable)
				return;

			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},
		getDescription(status) {
			return getDescriptionOrderStatus(status);
		},
		showOrder(id) {
			this.$router.push({ name: 'Order', params: { id } });
		},
		isLoaderRow(id) {
			return this.loaderSpecificRows.find(rowId => rowId === id);
		}
	}
};
</script>

<style lang="scss" scoped>
	.actionButtons {
		background-color: rgba(255, 200, 117, 0.5);
		padding: 7px;
		border-radius: 100%;
	}
</style>
