<template>
	<header>
		<v-layout row>
			<v-flex grow pa-1>
				<h1
					class="font-weight-bold mb-2"
					style="font-family: Inter; font-size: 30px">
					Produtos
				</h1>
				<p class="mb-0">Utilize os campos abaixo para filtrar os produtos.</p>
			</v-flex>
			<v-flex shrink pa-1>
				<v-btn
					depressed
					class="primary mx-0 pl-2 pr-3"
					@click="openCreateProductDialog">
					<v-icon small class="mr-2">add</v-icon>
					Adicionar produto
				</v-btn>
			</v-flex>
		</v-layout>

		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 lg9>
				<v-layout wrap>
					<v-flex xs12 md6>
						<v-text-field
							v-model="filter.q"
							label="Buscar produto"
							placeholder="Digite alguma coisa"
							background-color="white"
							box
							append-icon="search"
							color="purple"/>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
import { debounce } from 'lodash';

export default {
	name: 'HeaderListProduct',
	data() {
		return {
			filter: {
				q: null,
				layerId: 3
			}
		};
	},
	methods: {
		openCreateProductDialog() {
			this.$emit('openCreateProductDialog');
		},
		getData() {
			this.$emit('onSearch', {
				q: this.filter.q
			});
		}
	},
	watch: {
		filter: {
			handler: debounce(async function () {
				await this.getData();
			}, 750),
			deep: true
		}

	}
};
</script>
