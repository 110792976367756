<template>
	<Dialog titulo="Pendências" @close="close" :loading="loading">
		<ListaPendencias :box_id="box_id" :item="item" :readOnly="readOnly" />
	</Dialog>
</template>

<script>
import { PendenciaPresenter } from '@Presenter/pendencia-presenter';
import $store from '@Store';
import moment from 'moment';
import ListaPendencias from './lista-pendencia.vue';

export default {
	name: 'DialogPendencias',
	components: {
		ListaPendencias
	},
	props: {
		box_id: {
			type: Number,
			required: true
		},
		item: {
			type: Object,
			required: true
		},
		readOnly: {
			type: Boolean,
			require: false,
			default: false
		}
	},
	data: () => ({
		loading: false,
		enviando: false,
		concluindoPendencia: false,
		semPendencias: false,
		emAberto: false,
		concluidas: false,
		dialog_nova_pendencia: false,
		dialog_pendencia_concluida: false,
		dialog_historico_pendencia: false,
		enviaResposta: false,
		editandoResposta: false,
		isEditing: false,
		panel: [false],
		pendencias: [],
		historicoPendencia: {},
		response: '',
		rt: 'RT',
		atendimento: 'ATENDIMENTO'
	}),
	created() {
		this.getPendencias(this.box_id);
	},
	computed: {
		btnConcluirDisabled() {
			return this.pendencias.some(item => item.status === 'OPENED');
		},
		pendenciasEmAberto() {
			return this.pendencias.filter(item => item.status === 'OPENED');
		},
		pendenciasConcluidas() {
			return this.pendencias.filter(item => item.status === 'CONCLUDED');
		},
		ehAtendimento() {
			return this.$route.name === 'Atendimento';
		},
		ehFarmaceutico() {
			return this.$route.name === 'Farmaceutico';
		}
	},
	methods: {
		getPendencias(box_id) {
			this.loading = true;
			PendenciaPresenter.buscarPendencias(box_id)
				.then(pendencias => {
					this.pendencias = pendencias;
				})
				.catch(() => {
				}).finally(() => {
					this.loading = false;
				});
		},
		enviarPendencia(bodyPendencia) {
			this.enviando = true;
			PendenciaPresenter.enviarPendencia({ box_id: this.box_id, ...bodyPendencia })
				.then(response => {
					this.$root.$emit('alterou_pendencia', true);
					this.pendencias.push(response);
					this.item.e_pendencias_resolvidas = 0;
				})
				.catch(() => {
				}).finally(() => {
					this.enviando = false;
				});
		},
		concluirSemPendencias() {
			this.semPendencias = true;
			PendenciaPresenter.semPendencias(this.box_id)
				.then(() => {
					this.$root.$emit('alterou_pendencia', true);
					this.close();
				})
				.catch(() => {
				}).finally(() => {
					this.semPendencias = false;
				});
		},
		pendenciaConcluida(pendencia) {
			this.concluindoPendencia = true;
			PendenciaPresenter.concluirPendencia(pendencia.id)
				.then(() => {
					this.$root.$emit('alterou_pendencia', true);
					pendencia.status = 'CONCLUDED';
					$store.dispatch('SHOW_SNACKBAR', { message: 'Pendência concluída.' });
					this.getPendencias(this.box_id);
				})
				.catch(() => {
				}).finally(() => {
					this.concluindoPendencia = false;
				});
		},
		enviarResposta(pendencia) {
			this.enviaResposta = true;
			PendenciaPresenter.respostaPendencia(pendencia.id, { texto: this.response })
				.then(() => {
					this.$root.$emit('alterou_pendencia', true);
					$store.dispatch('SHOW_SNACKBAR', { message: 'Resposta pendência enviada.' });
					this.getPendencias(this.box_id);
				}).catch(() => {
				}).finally(() => {
					this.enviaResposta = false;
					this.response = '';
				});
		},
		enviarRespostaEditada(resposta) {
			this.editandoResposta = true;
			PendenciaPresenter.editarResposta(resposta.id, { texto: resposta.texto })
				.then(() => {
					this.$root.$emit('alterou_pendencia', true);
					resposta.readonly = true;
					this.getPendencias(this.box_id);
				})
				.catch(() => {
				}).finally(() => {
					this.editandoResposta = false;
					this.isEditing = false;
				});
		},
		formatDate(dateIso) {
			return moment(dateIso).format('DD/MM/YYYY HH:mm');
		},
		concluirTodasPendencias(item) {
			this.concluidas = true;
			PendenciaPresenter.concluirTodasPendencias(item.box_id)
				.then(() => {
					this.$root.$emit('alterou_pendencia', true);
					item.e_pendencias_resolvida = 1;
					this.close();
				}).catch(() => {
				}).finally(() => {
					this.concluidas = false;
				});
		},
		abrirHistorico(pendencia) {
			this.abrirHistoricoPendencia = true;
			PendenciaPresenter.buscaPendencia(pendencia.id)
				.then(pendenciaEscolhida => {
					this.dialog_historico_pendencia = true;
					this.historicoPendencia = pendenciaEscolhida;
				}).catch(() => {
				}).finally(() => {
					this.abrirHistoricoPendencia = false;
				});
		},
		close() {
			this.$emit('close');
		},
		editarResposta() {
			this.isEditing = true;
		},
		podeEditar(pendencia, resposta, indice_resposta) {
			return (resposta.user.id === this.$store.getters.contexto.user.id) && (pendencia.respostas.length - 1 === indice_resposta);
		},
		podeResponder(pendencia) {
			return (pendencia.respostas.length % 2 === 0 && this.ehAtendimento) || (pendencia.respostas.length % 2 !== 0 && this.ehFarmaceutico);
		},
		botoesConcluir(item) {
			if (item.e_pendencias_resolvidas)
				return false;
			return true;
		},
		descricaoPendencia(item) {
			return item.descricao.split('\n');
		}
	}
};
</script>

	<style lang="scss" scoped>

.id-pendencia {
	font-family: 'Poppins';
	font-weight: 700;
}

.categoria-pendencia {
	font-family: 'Roboto';
	font-weight: 700;
}

.font-style {
	font-family: 'Poppins';
	font-weight: 700;
	font-size: 10px;
}

.title-panel{
	text-align: left;
	font-size: 15px;
	font-weight: 700;
}

</style>
