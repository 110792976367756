import { debounce, isEqual } from 'lodash';
import { OrderPresenter } from '@Presenter/order-presenter';
import DataTablePedidos from '@Views/colaborador/orders/data-table.vue';

export const OrderProcessMixin = {
	components: { DataTablePedidos },
	data: () => ({
		busca: '',
		selectedOrders: [],
		processName: null,
		processesStatus: [],
		loadingOrders: false,
		orderButtons: [],
		ordersProcessTotal: 0,
		ordersProcesses: [],
		orders: []
	}),
	computed: {
		getOrdersIdByProcess() {
			return this.ordersProcesses.map(orderProcess => orderProcess.orderId);
		}
	},
	created() {
		this.orderButtons = [
			{
				label: 'Adicionar medicamento',
				icon: 'fas fa-plus-square'
			}
		];

		this.getOrdersProcess();
	},
	methods: {
		getOrdersProcess(pagination = {}) {
			if (!this.processName)
				return;

			const { page, rowsPerPage } = pagination;

			const params = {
				statuses: this.processesStatus ? this.processesStatus.join(',') : undefined,
				page,
				perPage: rowsPerPage
			};

			OrderPresenter.getOrdersByProcess(this.processName, params)
				.then(response => {
					this.ordersProcessTotal = response.total;
					this.ordersProcesses = response.items;
					return this.getOrders(pagination);
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: `Erro ao buscar pedidos no processo ${this.processName}`, color: 'error' });
				});
		},
		getOrders(pagination) {
			if (!this.ordersProcesses.length) {
				this.orders = [];
				return;
			}

			this.loadingOrders = true;
			const { sortBy, descending } = pagination;

			const params = {
				q: this.busca,
				ids: this.getOrdersIdByProcess,
				sortBy,
				orderBy: descending ? 'DESC' : 'ASC'
			};

			OrderPresenter.get(params)
				.then(ordersResponse => {
					this.orders = ordersResponse.items;
				}).catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: `Erro ao buscar pedidos ${this.params.ids.join(', ')}`, color: 'error' });
				}).finally(() => {
					this.loadingOrders = false;
				});
		},
		updateOrderProcess(typeProcess, status, metadata) {
			return () => new Promise((resolve, reject) => {
				OrderPresenter.updateOrderProcess(
					this.item_selecionado.id, typeProcess, { status, metadata }
				)
					.then(() => {
						this.afterSaveOrder(resolve);
					})
					.catch(() => {
						reject();
					});
			});
		},
		afterSaveOrder(resolve) {
			this.getOrdersProcess();
			this.$store.dispatch('SHOW_SNACKBAR', { message: 'Status do pedido atualizado com sucesso!' });
			if (resolve)
				resolve();
		}
	},
	watch: {
		busca: {
			handler: debounce(function (val, oldVal) {
				if (isEqual(val, oldVal))
					return;

				this.getOrders({});
			}, 500)
		},
		pagination: {
			deep: true,
			handler(val, oldVal) {
				if (isEqual(val, oldVal))
					return;

				this.loading = true;

				this.getOrdersProcess();
			}
		}
	}
};
