<template>
	<section>
		<v-data-table
			:items="items"
			:headers="headers"
			v-model="selected"
			:loading="loading"
			:total-items="total"
			class="custom-data-table"
			:disable-initial-sort="true"
			:pagination.sync="pagination"
			rows-per-page-text="Items por página"
			:rows-per-page-items="itemsPerPageOptions"
			no-results-text="Nenhum resultado encontrado"
			:no-data-text="loading ? 'Carregando...' : 'Nenhum intem encontrado'">
			<template v-slot:headers="props">
				<tr>
					<th width="10px">
						<v-checkbox
							color="primary"
							:input-value="props.all"
							:indeterminate="props.indeterminate"
							hide-details
							@click.stop="toggleAll"></v-checkbox>
					</th>
					<th
						v-for="header in props.headers"
						:key="header.text"
						:width="header.width"
						class="column"
						:class="[
							header.sortable ? 'sortable' :  '',
							pagination.descending ? 'desc' : 'asc',
							header.value === pagination.sortBy ? 'active' : ''
						]"
						@click="changeSort(header)">
						<v-icon v-if="header.sortable " small>arrow_upward</v-icon>
						{{ header.text }}
					</th>
				</tr>
			</template>

			<template v-slot:items="props">
				<tr :active="props.selected">
					<td @click="props.selected = !props.selected">
						<v-checkbox
							:input-value="props.selected"
							primary
							hide-details></v-checkbox>
					</td>
					<td class="text-left">
						<router-link :to="{name: 'Assinatura', params: {id: props.item.subscriptionId}}" class="black--text">
							{{ props.item.subscriptionId }}
						</router-link> |
						{{ props.item.id }}
					</td>
					<td class="text-center">
						<div class="items-center justify-center">
							<template v-if="props.item.patient.globalTags && props.item.patient.globalTags.length > 0">

								<GlobalTags
									v-for="(globalTag, index) in props.item.patient.globalTags"
									:key="index"
									:global_tag="globalTag"/>
							</template>
							<span class="ml-2">{{ props.item.patient.name }}</span>
						</div>
					</td>
					<td class="text-center">
						<template v-if="props.item.restHome">
							{{ props.item.restHome.name }}
						</template>
						<template v-else>
							----
						</template>
					</td>
					<!--<td>####</td>-->
					<td class="text-center">
						<span
							class="mr-2"
							:class="{'font-weight-bold' : props.item.isProductionDateCalculated}">{{ props.item.productionDate | formatDate('DD/MM/YYYY') }}</span>
						<v-tooltip v-if="props.item.isProductionDateCalculated" bottom>
							<template v-slot:activator="{ on }">
								<v-avatar size="30">
									<v-icon v-on="on">error_outline</v-icon>
								</v-avatar>
							</template>
							<span>Data calculada pelo sistema</span>
						</v-tooltip>
					</td>
					<td class="text-center">{{ props.item.startDate | formatDate('DD/MM/YYYY') }}</td>

					<td>
						<v-select
							@change="value => markAsPrintedLabel(props.item.id, value)"
							background-color="white"
							:value="props.item.printedLabel"
							hide-selected
							:items="[
								{ text: 'Sim', value: true },
								{ text: 'Não', value: false }
							]"
							hide-details
							item-value="value"
							item-text="text"
							solo/>
					</td>

					<td class="text-center">
						<span class="column-overflow">
							<template v-if="props.item.occurrence">
								{{ props.item.occurrence }}
							</template>
							<template v-else>
								<PreProducitonInsertCommentDialog @save-comment="comment => saveComment(props.item.id, comment)" />
							</template>
						</span>
					</td>
					<td>
						<div class="items-center">
							<GenerateTagsDialog :boxId="props.item.id" />

							<FinishPreProductionDialog :boxId="props.item.id" @confirm="boxId => finishPreProduction(boxId)" />
						</div>
					</td>
				</tr>
			</template>
		</v-data-table>
	</section>
</template>

<script>
import GlobalTags from '@Componentes/global-tags';
import GenerateTagsDialog from './dialogs/dialog-generate-tags';
import PreProducitonInsertCommentDialog from './dialogs/comment.vue';
import FinishPreProductionDialog from './dialogs/dialog-confirm-finish.vue';

export default {
	name: 'ListProductionTable',
	components: {
		GlobalTags,
		GenerateTagsDialog,
		PreProducitonInsertCommentDialog,
		FinishPreProductionDialog
	},
	props: {
		loading: {
			required: false,
			type: Boolean,
			default: false
		},
		items: {
			required: false,
			type: Array,
			default: () => ([])
		},
		total: {
			required: false,
			type: Number,
			default: 0
		},
		itemsPerPageOptions: {
			required: false,
			type: Array,
			default: () => ([5, 10, 25, 100])
		},
		itemsPerPage: {
			required: false,
			type: Number,
			default: 5
		}
	},
	data() {
		return {
			generatingTags: false,
			boxId: null,
			dialogChoiceMedicinesOpened: false,
			pagination: {
				descending: false,
				page: 1,
				rowsPerPage: this.itemsPerPage,
				sortBy: 'id',
				totalItems: this.total
			},
			selected: []
		};
	},
	computed: {
		headers() {
			return [

				{
					text: 'AssinaturaId | BoxId',
					value: 'header-id',
					sortable: false,
					width: '10px'
				},
				{
					text: 'Nome',
					value: 'header-customer',
					sortable: false
				},
				{
					text: 'Origem',
					value: 'header-origin',
					sortable: false
				},
				{
					text: 'Previsão de produção',
					value: 'productionDate',
					sortable: true,
					width: '10px'
				},
				{
					text: 'Início da Box',
					value: 'header-box-start-date',
					// this property has to be on sortable from pagination
					sortable: false
				},
				{
					text: 'Etiquetas impressa?',
					value: 'header-box-start-date',
					width: '10px',

					// this property has to be on sortable from pagination
					sortable: false
				},
				{
					text: 'Comentário produção',
					value: 'header-comment',
					// this property has to be on sortable from pagination
					sortable: false,
					width: '200px'
				},
				{
					sortable: false,
					width: '10px'
				}
			];
		}
	},
	methods: {
		updateFilters() {
			this.$emit('filter', {
				pagination: {
					page: Number(this.pagination.page),
					perPage: Number(this.pagination.rowsPerPage)
				},
				sorting: {
					field: this.pagination.sortBy,
					direction: this.pagination.descending ? 'DESC' : 'ASC'
				}
			});
		},
		toggleAll() {
			if (this.selected.length)
				this.selected = [];
			else
				this.selected = this.items.slice();
		},
		changeSort(header) {
			if (!header.sortable)
				return;
			const column = header.value;
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},
		async markAsPrintedLabel(boxId, value) {
			this.$emit('markAsPrintedLabel', { boxId, printed: value });
		},
		async saveComment(boxId, comment) {
			this.$emit('saveComment', { boxId, comment });
		},
		async finishPreProduction(boxId) {
			this.$emit('finishPreProduction', boxId);
		}
	},
	watch: {
		pagination: {
			deep: true,
			handler() {
				this.updateFilters();
			}
		},
		selected: {
			handler() {
				this.$emit('updatedSelected', this.selected);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
header {
	border-bottom-width: 5px;
	border-bottom-color: #ffd9a0 !important;
	border-bottom-style: solid;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.column-overflow {
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

::v-deep .custom-data-table  {
	.v-table {
		background-color: $primary_light;
		border-collapse: separate;
		border-spacing: 0 5px;

		thead th {
			padding-top: 10px;
			padding-bottom: 10px;
			height: 100%;
		}
		thead {
			tr {
				background-color: transparent;
			}

			th {
				padding: 5px 10px;
				height: 100%;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					border: 2px solid transparent;
				}

				&:last-child {
					border: 4px solid transparent;
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}
		tbody {

			tr {
				background-color: $secondary-light;
			}

			td {
				padding: 5px 10px;
				height: 100%;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					border: 2px solid $secondary-light;
				}

				&:last-child {
					border: 4px solid $secondary-light;
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}

	}

	.v-datatable {
		.v-datatable__actions {
			background-color: $primary_light;
		}
	}
}
</style>
