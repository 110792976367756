<template>
	<v-dialog
		persistent
		v-model="opened"
		width="390">
		<template v-slot:activator="{ on }">
			<div v-on="on" class="action" >
				<button class="action" >
					<v-icon color="black" large>play_arrow</v-icon>
				</button>
				Iniciar
			</div>
		</template>

		<section class="farme-dialog">
			<section class="farme-dialog-header">
				Iniciar produção da Box
			</section>
			<section class="farme-dialog-body">
				<p>
					Clique no botão 'Iniciar produção' para dar início a produção da Box.
				</p>
			</section>
			<section class="farme-dialog-footer">
				<v-btn
					color="green darken-1"
					flat="flat"
					@click="opened = false">
					Cancelar
				</v-btn>
				<v-spacer></v-spacer>

				<v-btn
					color="primary"
					@click="confirm">
					Inciar produção
				</v-btn>
			</section>
		</section>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			opened: false
			// iconStart
		};
	},
	methods: {
		confirm() {
			this.opened = false;
			this.$emit('confirm');
		}
	}
};
</script>

<style lang="scss" scoped>
.action {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 50px;

	button {
		background-color: #FFF;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		box-shadow: 0px 4.62px 4.62px 0px rgba(217, 172, 106, 0.2);

		svg {
			width: 45px;
			height: 45px;
		}
	}

	@media (max-width: 600px) {
		gap: 20px;
		button {
			background-color: #FFF;
			width: 75px;
			height: 75px;
		}
	}
}
</style>
