<template>
	<div>
		<v-data-table
			v-model="selected"
			ref="DataTable"
			:headers="headers"
			:items="items"
			:loading="loading"
			:no-data-text="loading ? 'Carregando...' : error ? 'Erro ao carregar, tente novamente' : 'Nenhum orçamento'"
			no-results-text="Nenhum resultado encontrado"
			:rows-per-page-items="rows_per_page"
			:pagination.sync="pagination"
			:search="search"
			rows-per-page-text="Itens por página">
			<template slot="headers" slot-scope="props">
				<tr>
					<th class="pr-0">
						<v-checkbox
							:input-value="props.all"
							:indeterminate="props.indeterminate"
							primary
							hide-details @click.stop="toggleAll" />
					</th>
					<th v-for="(header, index) in props.headers"
						:key="header.text"
						:class="['column',
							{ sortable: header.sortable },
							index !== 1 ? 'text-xs-center' : 'text-xs-left',
							pagination.descending ? 'desc' : 'asc',
							header.value === pagination.sortBy ? 'active' : '']"
						@click="changeSort(header.value)">
						{{ header.text }}
						<v-icon small v-if="header.sortable">arrow_upward</v-icon>
					</th>
				</tr>
			</template>
			<template v-slot:items="props">
				<tr :active="props.selected"
					@click="abrirDialog(props.item, 'dialog_detalhes')"
					class="pointer">
					<td class="pr-0">
						<v-checkbox
							:input-value="props.selected"
							@click.stop="props.selected = !props.selected"
							primary
							hide-details
							:disabled="disableCheckBox(props.item.status)"/>
					</td>
					<td class="text-xs-center">{{ props.item.id }}</td>
					<td><strong>{{ props.item.nome }}</strong></td>
					<td class="text-xs-center"><strong>{{ props.item.valor_final | dinheiro }}</strong></td>
					<td class="text-xs-center">
						<v-chip small disabled class="mx-0 white--text" :class="$functions.getStatusOrcamento(props.item.status).class">
							{{ $functions.getStatusOrcamento(props.item.status).label.toUpperCase() }}
						</v-chip>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { allQuotationStatus } from '@Consts/quotation-v2';
import mixin from './mixin-data-table';

export default {
	name: 'DataTableILPIQuotationBatch',
	mixins: [mixin],
	props: {
		rows_per_page: {
			type: Array,
			default: () => [15, 30, 45, 90, { text: 'Todos', value: -1 }]
		}
	},
	watch: {
		pagination: {
			async handler(newVal, oldVal) {
				if (!newVal.sortBy) { // skipping 'null state' of sortBy (3rd click in same column)
					oldVal.descending = !oldVal.descending;
					this.pagination = oldVal;
					return;
				}
				this.$emit('pagination-update', this.pagination);
			},
			deep: true
		},
		selected(arr) {
			this.$emit('selected-updated', arr);
		}
	},

	data: () => ({
		headers: [
			{ text: 'ID', value: 'id' },
			{ text: 'Residente', value: 'nome' },
			{ text: 'Preço', value: 'preco' },
			{ text: 'Status', value: 'status', align: 'center' },
			{ text: '', sortable: false }
		],
		pagination: {
			page: 1,
			rowsPerPage: 15
		},
		selected: []
	}),
	methods: {
		toggleAll() {
			this.selected = this.selected.length ? [] : [...this.$refs.DataTable.filteredItems.filter(
				quotationItem => quotationItem.status === allQuotationStatus.UNDER_ANALYSIS.value
					|| quotationItem.status === allQuotationStatus.APPROVED.value
			)];
		},
		changeSort(column) {
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},
		refresh() {
			this.$emit('refresh');
		},
		disableCheckBox(status) {
			return status !== allQuotationStatus.UNDER_ANALYSIS.value
				&& status !== allQuotationStatus.APPROVED.value;
		}
	}
};
</script>

<style lang="scss" scoped>
$icon-size: 16px;

.botao-acao {
	color: #4E2F6F !important;
	border: 1px solid #4E2F6F !important;
  border-radius: 16px;

	&:hover {
		color: white !important;
		background-color: #4E2F6F !important;
	}
}

.preco {
	.space {
		width: $icon-size;
	}

	.v-icon {
		font-size: $icon-size;
	}
}

.revisao {
	font-weight: bold;
	text-align: center;
}
</style>
