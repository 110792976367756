<template>
	<v-card>
		<!-- Título e descrição -->
		<v-card-title class="purple-title">
			<v-layout fill-height align-center>
				<v-flex>
					<h2 style="font-weight: 600">Imprimir orçamento</h2>
				</v-flex>
				<!-- Botão fechar -->
				<v-flex class="align-end">
					<v-btn small icon @click="$emit('update:close', false)" class="mr-0">
						<v-icon small color="white">close</v-icon>
					</v-btn>
				</v-flex><!-- Fim botão fechar -->
			</v-layout>
		</v-card-title><!-- Fim, título e descrição -->
		<object v-if="!gerando && pdf" width="100%" height="100%" :data="pdf">
			<embed :src="pdf" type="application/pdf" style="width:100%; height:100%">
		</object>
		<div v-else class="wrapper">
			<div class="carregando">
				<v-progress-circular indeterminate color="grey" />
				<p class="mt-3 grey--text">Gerando PDF, por favor aguarde.</p>
			</div>
		</div>
	</v-card>
</template>

<script>
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';

export default {
	name: 'DialogImpressao',
	props: {
		orcamento_uuid: {
			type: String,
			required: true
		}
	},
	data: () => ({
		gerando: true,
		pdf: null
	}),
	created() {
		OrcamentoPresenter.pdf(this.orcamento_uuid)
			.then(bufferArray => {
				const arr = new Uint8Array(bufferArray);
				const blob = new Blob([arr], { type: 'application/pdf' });
				const reader = new FileReader();

				reader.onloadend = () => {
					this.pdf = reader.result;
					this.gerando = false;
				};

				reader.readAsDataURL(blob);
			});
	}
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 104px);

  .carregando {
    text-align: center;
    min-height: calc(100vh - 104px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
