<template>
	<Dialog :titulo="is_new ? 'Cadastrar molécula' : 'Editar molécula'" btn_cancelar="Cancelar"
		:btn_acao="is_new ? 'Cadastrar' : 'Editar'" :btn_acao_disable="false" @acao="submeter()"  @close="fechaModal()" :loading="salvando">

		<v-container fluid grid-list-lg>
			<Form :item="item" :campos="campos" />
		</v-container>

	</Dialog>
</template>

<script>
import { CRUDMixin } from '@Mixins/crud';

export default {
	name: 'DialogMolecula',
	mixins: [CRUDMixin],
	data: () => ({
		type: 'molecula',
		fields_type: 'molecula'
	}),
	methods: {
		createData() {
			return {
				...this.item
			};
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
::v-deep .v-card__actions {
  background: #f5f5f5;
  padding: 16px 32px;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;

  .v-avatar {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.06);
    transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    &:hover {
      background: rgba(0, 0, 0, 0.12);
    }

    &:active {
      background: rgba(0, 0, 0, 0.06);
    }
  }
}
</style>
