<template>
	<v-tooltip top>
		<template v-slot:activator="{ on }">
			<v-icon :small="small" color="warning" v-if="express" v-on="on" class="pl-1">fas fa-shipping-fast</v-icon>
		</template>
		<span>Box express</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'BoxExpress',
	props: {
		express: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: true
		}
	}
};
</script>
