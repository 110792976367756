<template>
	<v-container fluid grid-list-md class="pa-0">
		<h3 class="mt-3 mb-4">Pagamento</h3>

		<v-layout wrap>
			<v-flex md6 xs12>
				<v-autocomplete
					box
					clearable
					:items="responsabilitiesForPayment"
					v-model="payment.responsabilityForPayment"
					no-data-text="Nenhuma forma de responsabilidade encontrada"
					background-color="white"
					label="Responsável pelo pagamento"
					data-vv-name="Responsável pelo pagamento"
					@input="checkPaymentType"
					v-validate="{ required: false }"
					:error-messages="errors.collect('Responsável pelo pagamento')"/>
			</v-flex>
			<v-flex md6 xs12 >
				<v-autocomplete
					box
					clearable
					:items="paymentTypes"
					v-model="payment.type"
					:readonly="!isRestHomeResponsibleForPayment"
					no-data-text="Nenhum tipo de pagamento encontrado"
					background-color="white"
					label="Tipo de pagamento"
					data-vv-name="Tipo de pagamento"
					v-validate="{ required: isRestHomeResponsibleForPayment }"
					:error-messages="errors.collect('Tipo de pagamento')"/>
			</v-flex>

			<v-flex md4 xs12>
				<v-text-field
					background-color="white"
					box
					v-model="payment.dueDay"
					label="Dia de vencimento"
					data-vv-name="Dia de vencimento"
					v-validate="{ required: isOpenClosePaymentType, gte: 0, lte: 28 }"
					:error-messages="errors.collect('Dia de vencimento')"/>
			</v-flex>

			<v-flex md4 xs12>
				<v-text-field
					background-color="white"
					box
					type="number"
					step="1"
					v-model="payment.closingDay"
					label="Dia de fechamento"
					data-vv-name="Dia de fechamento"
					v-validate="{ required: isOpenClosePaymentType, gte: 0, lte: 28 }"
					:error-messages="errors.collect('Dia de fechamento')"/>
			</v-flex>

			<v-flex md4 xs12>
				<v-text-field
					background-color="white"
					box
					type="number"
					step="1"
					v-model="payment.expireToPayInDays"
					title="Prazo em dias para pagamento da 1ª Box"
					label="Prazo em dias p/ pgto 1ª Box"
					data-vv-name="Prazo pagamento 1ª Box"
					v-validate="{ required: false, gte: 0, lte: 28 }"
					:error-messages="errors.collect('Prazo pagamento 1ª Box')"/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { PAYMENT_TYPES, PAYMENT_TYPE_OPEN_CLOSE } from '@Consts/rest-home/payment-types';
import {
	RESPONSIBLE_PAYMENT_TYPES, RESPONSIBLE_PAYMENT_REST_HOME, RESPONSIBLE_PAYMENT_FARMEMBRO, RESPONSIBLE_PAYMENT_MIXED
} from '@Consts/rest-home/responsible-payment';

export default {
	name: 'RestHomeGeneralDataPayment',
	inject: ['$validator'],
	props: {
		payment: {
			required: true,
			type: Object
		}
	},
	computed: {
		responsabilitiesForPayment() {
			return RESPONSIBLE_PAYMENT_TYPES;
		},
		paymentTypes() {
			return PAYMENT_TYPES;
		},
		isOpenClosePaymentType() {
			return this.payment.type === PAYMENT_TYPE_OPEN_CLOSE.value;
		},
		isRestHomeResponsibleForPayment() {
			return [
				RESPONSIBLE_PAYMENT_REST_HOME.value,
				RESPONSIBLE_PAYMENT_MIXED.value
			].includes(this.payment.responsabilityForPayment);
		}
	},
	methods: {
		checkPaymentType(responsibleForPayment) {
			if (responsibleForPayment === RESPONSIBLE_PAYMENT_FARMEMBRO.value)
				this.payment.type = null;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
