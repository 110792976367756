<template>
	<Dialog titulo="Alterar status" btn_acao="Salvar" :loading="salvando" :btn_acao_disable="!status_box"
		@acao="salvar" @close="close">
		<v-select v-model="status_box" :items="items" label="Novo status" />
	</Dialog>
</template>

<script>
import { getAllStatusBox } from '@Consts/status-box';
import { StatusBoxPresenter } from '@Presenter/status-box-presenter';

export default {
	name: 'DialogAlterarStatus',
	props: {
		box_id: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		items: [],
		salvando: false,
		status_box: null
	}),
	created() {
		const status_box = getAllStatusBox();
		this.items = Object.keys(status_box).map(value => ({ text: status_box[value], value }));
	},
	methods: {
		salvar() {
			StatusBoxPresenter.store({
				box_id: this.box_id,
				status: this.status_box
			})
				.then(box => {
					this.$emit('novo-status', box.status_box);
					this.close();
				});
		},
		close() {
			this.$emit('update:close', false);
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
