<template>
	<v-layout wrap>
		<v-flex class="pb-0" :class="value.size || 'xs12'" v-for="(value, key) in campos" :key="key">
			<field
				class="error-2-lines"
				:is="!value.component ? 'v-text-field' : `v-${value.component}`"
				v-money="value.money || false" color="primary"
				v-model="item[`${key}`]"
				:disabled="disabled || value.disabled === true"
				:label="`${value.label}${(value.validation || {}).required ? ' *' : ''}`"
				v-mask="value.mask || null"
				v-validate="value.validation || {}"
				:type="value.type"
				:data-vv-name="key"
				:error-messages="errors.first(getScope(key))"
				:data-vv-scope="scope"
				:items="value.items || []"
				:item-text="value.item_text || null"
				:multiple="value.multiple || false"
				:item-value="value.item_value || null"
				:clearable="value.clearable || false"
				:return-object="value.return_object || false"
				:chips="value.chips || false"
				:small-chips="value.small_chips || false"
				:hide-details="value.hide_details || false"
				:open-on-clear="value.open_on_clear || false"
				:min="value.min || null"
				:max="value.max || null"
				:autocomplete="value.component ? null : 'new-password'">
				<template v-if="value.no_data_text" slot="no-data">
					<v-list-tile>
						<v-list-tile-content>
							<v-list-tile-sub-title>
								Nenhum resultado
							</v-list-tile-sub-title>
							<v-list-tile-title>
								{{ value.no_data_text }}
							</v-list-tile-title>
						</v-list-tile-content>
					</v-list-tile>
				</template>
			</field>
		</v-flex>
		<slot />
	</v-layout>
</template>

<script>

export default {
	name: 'Form',
	inject: ['$validator'], // inject parent's validator
	props: {
		item: {
			type: Object,
			default: () => ({})
		},
		campos: {
			type: Object,
			default: () => ({})
		},
		scope: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		getScope(key) {
			return this.scope ? `${this.scope}.${key}` : key;
		}
	}
};
</script>
