import ObservacoesAssinatura from '@Componentes/observacoes-assinatura';

export const AssinaturaMixin = {
	components: { ObservacoesAssinatura },
	props: {
		assinatura: {
			type: Object,
			required: true
		},
		scope: {
			type: String,
			required: true
		}
	},
	computed: {
		ehOrcamento() {
			return this.$route.name === 'Orcamentos';
		}
	}
};
