<template>
	<v-flex class="dialogStyle">
		<v-layout column wrap class="d-flex items-center justify-center">
			<v-flex xs12 class="imgStyle d-flex items-center mb-4">
				<img src="/img/icons/exclamation-icon.svg" width="42" height="42" alt="Imagem de exclamação, sugerindo atenção">
			</v-flex>
			<v-flex xs12 class="mb-2 text-center sans text-bold title">
				Você tem certeza que deseja concluir?
			</v-flex>
			<v-flex xs12 class="mb-4 text-center sans subheading">
				Um orçamento submetido não pode ser editado posteriormente. Prossiga somente se o orçamento estiver finalizado.
			</v-flex>
			<v-flex xs12 class="d-flex items-center">
				<v-btn color="primary" @click="$emit('update:close', false)">Cancelar</v-btn>
				<v-btn :loading="loading" @click="$emit('submitQuotation')">Submeter</v-btn>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
export default {
	name: 'DialogConfirmQuotation',
	props: {
		loading: {
			type: Boolean
		}
	}
};
</script>

<style lang="scss" scoped>
.dialogStyle {
	padding: 20px 50px;
	border-radius: 20px;
	background: #FFF3E2;
	border: 2px solid #FFC875
}

.imgStyle {
	background-color: rgba(255, 200, 117, 0.6);
	padding: 15px;
	border-radius: 40px;
}
</style>
