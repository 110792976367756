<template>
	<div>
		<v-container fluid grid-list-md class="pa-0 pb-4">
			<v-layout wrap>
				<!-- Camera 1 and its dropdown -->
				<v-flex xs12 md6>
					<video v-show="showWebcams" ref="video1" width="320" height="240" autoplay></video>
					<img v-show="capturedImage1" :src="capturedImage1" width="320" height="240"/>
					<v-select
						append-icon="filter_list"
						v-model="selectedCamera1"
						label="Selecione a Câmera 1"
						:items="cameraOptions"
						@input="changeCamera(1)"/>
				</v-flex>

				<!-- Camera 2 and its dropdown -->
				<v-flex xs12 md6>
					<video v-show="showWebcams" ref="video2" width="320" height="240" autoplay></video>
					<img v-show="capturedImage2" :src="capturedImage2" width="320" height="240"/>
					<v-select
						append-icon="filter_list"
						v-model="selectedCamera2"
						label="Selecione a Câmera 2"
						:items="cameraOptions"
						@input="changeCamera(2)"/>
				</v-flex>
			</v-layout>

			<!-- Capture and confirmation buttons -->
			<v-btn depressed class="primary mx-0 pl-2 pr-3" @click="captureEnter" v-if="isCaptureAvailable">
				<v-icon small class="mr-2">add</v-icon>
				Capturar Fotos
			</v-btn>
			<v-btn depressed class="primary mx-0 pl-2 pr-3" @click="confirmAndUpload" v-if="isUploadAvailable">
				<v-icon small class="mr-2">send</v-icon>
				Confirmar e Enviar
			</v-btn>
		</v-container>
	</div>
</template>

<script>
import farme_ai_http from '@Config/farme-ai';
import { compressToBase64 } from 'lz-string';

export default {
	data() {
		return {
			videoDevices: [],
			selectedCamera1: '',
			selectedCamera2: '',
			showWebcams: true,
			cameraOptions: [],
			capturedImage1: null,
			capturedImage2: null
		};
	},
	computed: {
		isCaptureAvailable() {
			return this.showWebcams;
		},
		isUploadAvailable() {
			return this.capturedImage1 && this.capturedImage2;
		}
	},
	mounted() {
		this.enumerateDevices();
		window.addEventListener('keyup', this.handleKeyEvents);
	},
	beforeDestroy() {
		window.removeEventListener('keyup', this.handleKeyEvents);

		// Stop all webcam streams
		if (this.$refs.video1 && this.$refs.video1.srcObject)
			this.$refs.video1.srcObject.getTracks().forEach(track => track.stop());

		if (this.$refs.video2 && this.$refs.video2.srcObject)
			this.$refs.video2.srcObject.getTracks().forEach(track => track.stop());
	},
	methods: {
		handleKeyEvents(event) {
			if (event.key === 'F20') {
				this.uploadImages(
					this.takeSnapshot(this.$refs.video1),
					this.takeSnapshot(this.$refs.video2)
				).then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Fotos enviadas com sucesso.', color: 'success' });
				});
			} else if (event.key === 'Enter')
				this.handleAction();
			else if (event.key === 'Escape')
				this.resetImagesAndWebcams();
		},
		handleAction() {
			if (this.isCaptureAvailable)
				this.captureEnter();
			else if (this.isUploadAvailable)
				this.confirmAndUpload();
		},
		resetImagesAndWebcams() {
			if (this.capturedImage1 || this.capturedImage2) {
				this.capturedImage1 = null;
				this.capturedImage2 = null;
				this.showWebcams = true;
				this.setupWebcams();
			}
		},
		async enumerateDevices() {
			try {
				navigator.permissions.query(
					{ name: 'camera' }
				)
					.then(permission => console.log('Camera permission:', permission));
				const devices = await navigator.mediaDevices.enumerateDevices();
				this.videoDevices = devices.filter(device => device.kind === 'videoinput');
				this.cameraOptions = this.videoDevices.map(device => ({
					text: device.label || `Camera ${ device.deviceId }`,
					value: device.deviceId
				}));

				this.selectedCamera1 = this.videoDevices[0]?.deviceId || '';
				this.selectedCamera2 = this.videoDevices[1]?.deviceId || this.selectedCamera1;
				await this.setupWebcams();
			} catch (error) {
				await this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Error enumerating devices',
					color: 'error'
				});
			}
		},
		async setupWebcams() {
			await this.setVideoSource(this.$refs.video1, this.selectedCamera1);
			await this.setVideoSource(this.$refs.video2, this.selectedCamera2);
		},
		async setVideoSource(videoElement, deviceId) {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId } });
				videoElement.srcObject = stream;
			} catch (error) {
				await this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Error setting up video source',
					color: 'error'
				});
			}
		},
		changeCamera(cameraNumber) {
			if (cameraNumber === 1)
				this.setVideoSource(this.$refs.video1, this.selectedCamera1);
			else if (cameraNumber === 2)
				this.setVideoSource(this.$refs.video2, this.selectedCamera2);
		},
		captureEnter() {
			this.$store.dispatch('SHOW_SNACKBAR', {
				message: 'Ficou boa? É só apertar ENTER para enviar!.',
				color: 'primary'
			});
			this.capturedImage1 = this.takeSnapshot(this.$refs.video1);
			this.capturedImage2 = this.takeSnapshot(this.$refs.video2);
			this.showWebcams = false;
		},
		takeSnapshot(videoElement) {
			const canvas = document.createElement('canvas');
			canvas.width = videoElement.videoWidth;
			canvas.height = videoElement.videoHeight;
			canvas.getContext('2d').drawImage(videoElement, 0, 0);
			return canvas.toDataURL('image/jpeg');
		},
		async uploadImages(image1, image2) {
			const payload = {
				image1: compressToBase64(image1),
				image2: compressToBase64(image2)
			};
			return farme_ai_http.post('images', payload);
		},
		async confirmAndUpload() {
			await this.$store.dispatch('SHOW_SNACKBAR', {
				message: 'Não feche esta tela até que a execução esteja completa.',
				color: 'primary'
			});

			await this.uploadImages(
				this.capturedImage1,
				this.capturedImage2
			)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Fotos enviadas com sucesso.', color: 'success' });
					this.capturedImage1 = null;
					this.capturedImage2 = null;
					this.showWebcams = true;
				})
				.finally(() => {
					this.setupWebcams();
				});
		}
	}
};
</script>
