<template>
	<transition>
		<v-card
			style="
				border-radius: 0 !important;
				background-color: rgba(255, 200, 117, 0.5) !important;
			">
			<div class="purple-title toolbarHeight full-width">
				<div class="d-flex items-center q-column">
					<Header
						class="full-width"
						:tabSelected="tabSelected"
						:loadingSave="loadingSaveQuotation"
						:loadingSubmit="loadingSubmitQuotation"
						:isNewQuotation="isNewQuotation"
						:companyUnit="quotation.companyUnit"
						@saveQuotation="openDialogSaveQuotation"
						@saveEditedQuotation="openDialogSaveQuotation"
						@openCardHubspot="() => {}"
						@submitQuotation="openDialogConfirmQuotation"
						@close="$emit('close')"/>
					<div class="container">
						<Tabs
							:tabSelected="tabSelected"
							:isNewQuotation="isNewQuotation"
							@selectTab="(tab) => (tabSelected = tab)"/>
					</div>
				</div>
			</div>
			<v-card-text class="cardBody">
				<div v-if="!loadingQuotation">
					<GeneralData
						ref="generalData"
						v-if="isGeneralData"
						:tabSelected="tabSelected"
						:quotation="quotation"
						:sellers="sellers"
						class="container"
						@changedPricingLayer="resetItems"
						@changedExpiresAt="changedExpiresAt"/>

					<Products
						v-if="isTabProducts"
						:isNewQuotation="isNewQuotation"
						:quotation="quotation"
						class="container"/>

					<ClientData
						ref="clientData"
						v-if="isClientData"
						:tabSelected="tabSelected"
						:quotation="quotation"
						:isNewQuotation="isNewQuotation"
						class="container"/>

					<Footer
						:quotation="quotation"
						:tabSelected="tabSelected"
						class="container py-0"
						@cancel="dialogCancelQuotation = true"
						@back="back"
						@next="next"/>
				</div>
				<div v-if="loadingQuotation" class="loading flex-center justify-center">
					<v-progress-circular indeterminate color="secondary" size="70" />
				</div>
			</v-card-text>

			<v-dialog
				persistent
				v-model="dialogCancelQuotation"
				scrollable
				transition="fade"
				max-width="400px">
				<transition>
					<Dialog
						v-if="dialogCancelQuotation"
						titulo="Cancelar orçamento"
						subtitulo="Tem certeza que deseja cancelar o orçamento? Ele não poderá ser recuperado."
						btn_acao="Cancelar orçamento"
						btn_acao_class="error"
						btn_cancelar="Fechar"
						@close="dialogCancelQuotation = false"
						@acao="cancelQuotation"/>
				</transition>
			</v-dialog>

			<v-dialog
				persistent
				v-if="dialogConfirmQuotation"
				scrollable
				v-model="dialogConfirmQuotation"
				transition="fade"
				max-width="500px">
				<transition>
					<DialogConfirmQuotation
						v-if="dialogConfirmQuotation"
						:loading="loadingSubmitQuotation"
						:close.sync="dialogConfirmQuotation"
						@submitQuotation="submitQuotation"/>
				</transition>
			</v-dialog>

			<v-dialog
				persistent
				v-if="dialogErrorNoPosology"
				scrollable
				v-model="dialogErrorNoPosology"
				transition="fade"
				max-width="500px">
				<transition>
					<DialogErroNoPosology
						v-if="dialogErrorNoPosology"
						:close.sync="dialogErrorNoPosology"/>
				</transition>
			</v-dialog>

			<v-dialog
				persistent
				v-if="dialogSaveQuotation"
				scrollable
				v-model="dialogSaveQuotation"
				transition="fade"
				max-width="500px">
				<transition>
					<DialogSaveQuotation
						v-if="dialogSaveQuotation"
						:loading="loadingSaveQuotation"
						:close.sync="dialogSaveQuotation"
						@saveQuotation="
							isNewQuotation ? saveQuotation() : saveEditedQuotation()
						"/>
				</transition>
			</v-dialog>
		</v-card>
	</transition>
</template>

<script>
import moment from 'moment';
import { QuotationPresenter } from '@Presenter/quotation-presenter';
import { isEmpty } from 'lodash';
import { ProductPresenter } from '@Presenter/product-presenter';
import { TagPresenter } from '@Presenter/tag-presenter';
import { stepsQuotation } from '@Consts/quotation-v2';
import { UserPresenter } from '@Presenter/user-presenter';
import { statesWithSymbol } from '@Consts/estados';
import { calculateValuesOfDiscount, getPropertyValue } from '@Util/ProductService';
import { ConfiguracaoPresenter } from '@Presenter/configuracao-presenter';
import { companyUnitIds } from '@Consts/unidades';
import Tabs from './tabs.vue';
import Header from './header.vue';
import GeneralData from './general-data/index.vue';
import ClientData from './client-data/index.vue';
import Products from './products/index.vue';
import Footer from './footer.vue';
import DialogConfirmQuotation from './dialog-confirm-quotation.vue';
import DialogErroNoPosology from './dialog-erro.vue';
import DialogSaveQuotation from './dialog-confirm-save.vue';

export default {
	name: 'QuotationV2',
	components: {
		Tabs,
		Header,
		Footer,
		GeneralData,
		Products,
		ClientData,
		DialogConfirmQuotation,
		DialogErroNoPosology,
		DialogSaveQuotation
	},
	props: {
		quotationId: {
			type: Number,
			default: null
		}
	},
	data: () => ({
		tabSelected: 'general-data',
		erros: [],
		tags: [],
		sellers: [],
		quotation: {
			address: {},
			items: [],
			customer: {},
			pricingLayerId: null,
			shippingPrice: 990,
			serviceValue: 0,
			coupomCode: null,
			startDate: '',
			expiresAt: '',
			seller: null
		},
		loadingQuotation: false,
		loadingSaveQuotation: false,
		loadingSubmitQuotation: false,
		dialogCancelQuotation: false,
		dialogConfirmQuotation: false,
		dialogErrorNoPosology: false,
		dialogSaveQuotation: false,
		loadingSellers: true,
		loadingConfig: false,
		expirationDateHasChanged: false,
		temporaryExpirationDate: ''
	}),
	async created() {
		this.sellers = await UserPresenter.index();
		this.sellers = this.sellers.filter(s => s.telefone).map(seller => ({
			id: seller.id,
			name: seller.nome,
			phone: seller.telefone
		}));
		this.loadingSellers = false;
		this.getConfigs();

		if (!this.isNewQuotation) {
			this.loadingQuotation = true;
			TagPresenter.index().then(response => {
				this.tags = response;
			});

			const editableQuotation = await QuotationPresenter.show(this.quotationId);
			this.temporaryExpirationDate = editableQuotation.expiresAt;
			await this.editMapper(editableQuotation);

			this.loadingQuotation = false;
		}

		if (this.isNewQuotation) {
			this.quotation.expiresAt = moment().add(7, 'days').format('DD/MM/YYYY');
			this.quotation.startDate = moment().add(5, 'days').format('DD/MM/YYYY');
			const seller = {
				id: this.currentSeller.id,
				name: this.currentSeller.nome,
				phone: this.currentSeller.telefone
			};
			this.quotation.seller = seller;
		}
	},
	computed: {
		currentSeller() {
			return this.$store.getters.user;
		},
		isGeneralData() {
			return this.tabSelected === stepsQuotation.generalData;
		},
		isTabProducts() {
			return this.tabSelected === stepsQuotation.products;
		},
		isClientData() {
			return this.tabSelected === stepsQuotation.clientData;
		},
		isNewQuotation() {
			return typeof this.quotationId !== 'number';
		}
	},
	methods: {
		getConfigs() {
			this.loadingConfig = true;
			ConfiguracaoPresenter.show()
				.then(response => {
					this.quotation.serviceValue = response.valor_servico;
				})
				.finally(() => {
					this.loadingConfig = false;
				});
		},
		cancelQuotation() {
			this.$emit('cancelQuotation');
		},
		resetItems() {
			this.$store.dispatch('SHOW_SNACKBAR', { message: 'Produtos removidos do orçamento', color: 'warning' });
			this.quotation.items = [];
		},
		changedExpiresAt(value) {
			this.expirationDateHasChanged = value;
		},
		validatePersonInfo() {
			if (!this.quotation.customer.name || !this.quotation.customer.phone)
				return false;

			return true;
		},
		setExpiresAt(quotation) {
			const expiresAtSelected = moment(quotation.expiresAt, 'DD/MM/YYYY').format();

			const expiresAt = new Date(expiresAtSelected);
			const hour = moment().hours();
			const minutes = moment().minutes();
			const seconds = moment().seconds();
			expiresAt.setHours(hour, minutes, seconds);

			quotation.expiresAt = moment(expiresAt).format();
		},
		async validateClientData() {
			const clientDataValidate = await this.$refs.clientData.validate();

			if (!clientDataValidate) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Dados obrigatórios incompletos'
				});
			}
		},
		normalizeValuesQuotation() {
			const quotation = this.$lodash.cloneDeep(this.quotation);

			if (quotation.serviceValue) {
				quotation.serviceValue = Number(
					this.$functions.onlyNumber(quotation.serviceValue)
				);
			}
			if (quotation.shippingPrice) {
				quotation.shippingPrice = Number(
					this.$functions.onlyNumber(quotation.shippingPrice)
				);
			}
			if (quotation.percentDiscountFirstBox) {
				quotation.percentDiscountFirstBox = Number(
					this.$functions.onlyNumber(quotation.percentDiscountFirstBox)
				);
			}

			if (this.isNewQuotation)
				this.setExpiresAt(quotation);

			if (!this.isNewQuotation && this.expirationDateHasChanged)
				this.setExpiresAt(quotation);

			if (!this.isNewQuotation && !this.expirationDateHasChanged)
				quotation.expiresAt = this.temporaryExpirationDate;

			if (quotation.customer.birthdate) {
				quotation.customer.birthdate = moment(
					quotation.customer.birthdate,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD');
			}

			quotation.customer.phone = this.$functions.onlyNumber(
				quotation.customer.phone
			);

			if (quotation.items && quotation.items.length) {
				quotation.items = quotation.items.map(prodt => ({
					productId: prodt.id,
					name: prodt.name,
					presentation: prodt.presentation,
					dosage: prodt.drug ? prodt.drug.compositions
						.map(item => item.concentration + item.unitOfMeasurement.symbol)
						.join() : '',
					isControlled: prodt.drug ? !!prodt.drug.prescriptionRule : false,
					isDivisible: prodt.drug ? getPropertyValue(prodt.drug.properties, 'isDivisible') : false,
					gtin: prodt.gtins.shift(),
					manufacturer: prodt.manufacturer.name,
					discount: prodt.values.nominalDiscount,
					priceSku: prodt.values.price,
					price: prodt.values.totalFinalPrice,
					isOutOfBox: prodt.drug ? getPropertyValue(prodt.drug.properties, 'isOutOfBox') : false,
					quantityPills: prodt.drug && getPropertyValue(prodt.drug.properties, 'isDivisible') ? prodt.values.quantityByProductItem : 1,
					quantityPillsPerPackage: prodt.drug && getPropertyValue(prodt.drug.properties, 'isDivisible') ? prodt.quantity : 1,
					posology:
						prodt.posology !== null
							? {
								daysOfMonth: prodt.posology.daysOfMonth,
								daysOfWeek: prodt.posology.daysOfWeek,
								frequency: prodt.posology.frequency,
								schedules: prodt.posology.schedules.map(schedule => ({
									hour: schedule.horario,
									pills: schedule.quantidade_por_horario
								}))
							}
							: null,
					closedPackages: prodt.quantityOfBoxes
						? prodt.quantityOfBoxes
						: Math.ceil(prodt.values.quantityByProductItem / prodt.quantity)
				}));
			}

			quotation.type = 'SUBSCRIPTION';

			if (quotation.tags && quotation.tags.length)
				quotation.tags = quotation.tags.map(tag => tag.nome);

			if (isEmpty(quotation.address))
				delete quotation.address;

			if (quotation.startDate) {
				quotation.startDate = moment(quotation.startDate, 'DD/MM/YYYY').format(
					'YYYY-MM-DD'
				);
			}

			const sellerSelected = quotation.seller;

			quotation.seller = {
				name: sellerSelected.name,
				phone: sellerSelected.phone
			};

			if (quotation.address) {
				const state = Object.keys(statesWithSymbol).find(
					key => statesWithSymbol[key] === quotation.address.state
				);
				quotation.address = { ...quotation.address, addressNumber: quotation.address.addressNumber ? quotation.address.addressNumber : '', state };
			}

			if (quotation.history)
				delete quotation.history;

			return quotation;
		},
		saveQuotation() {
			this.loadingSaveQuotation = true;
			const quotation = this.normalizeValuesQuotation();
			quotation.status = 'DRAFT';
			QuotationPresenter.createOrSaveQuotationB2c(quotation)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'success',
						message: 'Orçamento salvo com sucesso!'
					});
					this.$emit('close');
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'Erro ao salvar orçamento'
					});
				})
				.finally(() => {
					this.$emit('refreshQuotations');
					this.dialogSaveQuotation = false;
					this.loadingSaveQuotation = false;
				});
		},
		async submitQuotation() {
			this.loadingSubmitQuotation = true;
			const quotation = this.normalizeValuesQuotation();

			if (this.isClientData) {
				const clientDataValidate = await this.$refs.clientData.validate();

				if (!clientDataValidate) {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'Dados obrigatórios incompletos'
					});

					this.loadingSubmitQuotation = false;
					this.dialogConfirmQuotation = false;
					return;
				}
			}

			const itemsNotComplete = quotation.items.some(
				prodt => (prodt.isDivisible && prodt.posology === null) || (!prodt.isDivisible && prodt.posology !== null)
			);
			if (itemsNotComplete) {
				this.dialogConfirmQuotation = false;
				this.dialogErrorNoPosology = true;
				this.loadingSubmitQuotation = false;
				return;
			}

			QuotationPresenter.submit(quotation.id, quotation)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'success',
						message: 'Orçamento submetido com sucesso!'
					});
					this.$emit('close');
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'Erro ao submeter orçamento'
					});
				})
				.finally(() => {
					this.dialogConfirmQuotation = false;
					this.loadingSubmitQuotation = false;
				});
		},
		saveEditedQuotation() {
			this.loadingSaveQuotation = true;
			const quotation = this.normalizeValuesQuotation();
			const id = quotation.id;

			this.adjustToSaveEditQuotation(quotation);

			QuotationPresenter.update(id, quotation)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'success',
						message: 'Orçamento editado com sucesso!'
					});
					this.$emit('close');
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'Erro ao editar o orçamento'
					});
				})
				.finally(() => {
					this.dialogSaveQuotation = false;
					this.loadingSaveQuotation = false;
				});
		},
		async next() {
			if (this.isGeneralData) {
				const generalDataValidate = await this.$refs.generalData.validate();

				if (!generalDataValidate) {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'Dados obrigatórios incompletos'
					});
					return;
				}

				this.tabSelected = stepsQuotation.products;
				return;
			}

			if (this.isTabProducts) {
				if (!this.quotation.items.length) {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'É necessário adicionar algum produto para continuar'
					});
					return;
				}

				this.tabSelected = stepsQuotation.clientData;
			}
		},
		back() {
			if (this.isTabProducts) {
				this.tabSelected = stepsQuotation.generalData;
				return;
			}

			if (this.isClientData)
				this.tabSelected = stepsQuotation.products;
		},
		openDialogConfirmQuotation() {
			this.dialogConfirmQuotation = true;
		},
		openDialogSaveQuotation() {
			this.dialogSaveQuotation = true;
		},
		adjustToSaveEditQuotation(quotation) {
			delete quotation.id;
			delete quotation.status;
			delete quotation.updatedAt;
			delete quotation.createdAt;
			delete quotation.publicId;
			delete quotation.total;

			return quotation;
		},
		async editMapper(editableQuotation) {
			this.quotation = editableQuotation;

			const tagsSelected = this.quotation.tags;
			this.quotation.tags =	tagsSelected && tagsSelected.length > 0 ? this.tags.filter(tag => tagsSelected.includes(tag.nome)) : [];

			if (editableQuotation.items) {
				const productIds = editableQuotation.items.map(
					prodt => prodt.productId
				);

				const products = await ProductPresenter.getProducts({
					ids: productIds,
					companyUnitId: companyUnitIds[editableQuotation.companyUnit],
					flow: 'entrada',
					clientType: 'b2c'
				});

				this.quotation.items = products.items.map(prodt => {
					const product = editableQuotation.items.find(
						item => prodt.id === item.productId
					);
					if (product) {
						let posology = product.posology && product.posology.frequency !== undefined ? product.posology : null;

						if (posology !== null) {
							posology = {
								...posology,
								schedules: posology.schedules ? posology.schedules.map(schedule => ({ quantidade_por_horario: schedule.pills, horario: schedule.hour })) : []
							};
						}
						const productSelected = {
							...prodt,
							posology,
							quantityOfBoxes: product.closedPackages && posology === null ? product.closedPackages : undefined,
							values: {
								discount: product.discount,
								nominalDiscount: product.discount,
								price: product.priceSku,
								finalPrice: product.total,
								discountType: 'nominalDiscount'
							}
						};
						const updatedValues = calculateValuesOfDiscount(productSelected);

						productSelected.values = {
							...productSelected.values,
							...updatedValues
						};

						return productSelected;
					}
					return {};
				});
			} else
				this.quotation.items = [];

			if (editableQuotation.expiresAt) {
				this.quotation.expiresAt = moment(editableQuotation.expiresAt)
					.format('DD/MM/YYYY');
			}

			this.quotation.startDate = moment(editableQuotation.startDate).format(
				'DD/MM/YYYY'
			);

			if (editableQuotation.customer.birthdate) {
				this.quotation.customer.birthdate = moment(
					editableQuotation.customer.birthdate
				).format('DD/MM/YYYY');
			}

			const sellerSelected = this.sellers.find(
				seller => seller.name === editableQuotation.seller.name
			);
			if (!sellerSelected) {
				const seller = {
					id: this.currentSeller.id,
					name: this.currentSeller.nome,
					phone: this.currentSeller.telefone
				};

				this.quotation.seller = seller;
			} else
				this.quotation.seller = sellerSelected;

			if (editableQuotation.address)
				this.quotation.address = { ...editableQuotation.address, addressNumber: editableQuotation.address.addressNumber ? editableQuotation.address.addressNumber : '', state: statesWithSymbol[editableQuotation.address.state] };
		}
	}
};
</script>

<style lang="scss" scoped>
	.container {
		max-width: 800px !important;
		min-width: 800px !important;
		padding: 24px 0px;
	}

	.toolbarHeight {
		height: 160px !important;
	}

	.cardBody {
		background-color: rgba(255, 234, 204, 1);
		height: 100vh;
	}
</style>
