<template>
	<header>
		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 md9>
				<h1 class="text--text" style="opacity: .8">Medicamentos</h1>
				<p>Utilize a busca aos lado para encontrar os medicamentos.</p>
				<v-btn v-if="$store.getters.eh_administrador" depressed class="primary ml-md-0 pl-2 pr-3" @click="$emit('novo-medicamento')">
					<v-icon small class="mr-2">add</v-icon>
					Novo medicamento
				</v-btn>
				<v-tooltip top>
					<v-btn slot="activator" depressed class="green white--text"
						@click="$emit('gerar-planilha')">
						<v-icon small class="mr-2">cloud_download</v-icon>
					</v-btn>
					<span>Gerar planilha</span>
				</v-tooltip>
				<v-tooltip top>
					<v-btn slot="activator" depressed class="green white--text"
						@click="$emit('enviar-planilha')">
						<v-icon small class="mr-2">cloud_upload</v-icon>
					</v-btn>
					<span>Enviar planilha</span>
				</v-tooltip>
				<v-tooltip top>
					<v-btn :loading="gerandoRelatorio" slot="activator" depressed class="green white--text"
						@click="$emit('relatorio-medicamento-pacientes')">
						<v-icon small class="mr-2">insert_drive_file</v-icon>
					</v-btn>
					<span>Pacientes / Medicamento</span>
				</v-tooltip>
				<v-switch v-model="apenas_excluidos" @change="val => $emit('update:switch', val)"
					label="Mostrar excluídos" />
			</v-flex>
			<v-flex xs12 md3 align-self-center>
				<v-text-field label="Buscar medicamento"
					placeholder="ID, EAN, Produto, Princípio Ativo, Laboratório ou Dosagem" append-icon="search"
					color="purple" @input="value => $emit('busca', value)" />
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
export default {
	name: 'MedicamentosHeader',
	props: ['gerandoRelatorio'],
	data: () => ({ apenas_excluidos: false })
};
</script>
