import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = 'precos';

export const PricePresenter = {
	list: (layerId, productIds) => $http.get(buildQueryParams(prefix, {
		layerId, productIds
	}))
};
