<template>
	<div class="px-md-5">
		<LogisticsHeader :title="'Acompanhamento'" :tabs="tabs" @tabChanged="filterDataByStatus" :filter="filterField" @filterChanged="filterChanged"/>

		<v-flex class="q-row mt-4">
			<v-card flat width="282" class="mx-2 py-2"
				v-for="order in orders"
				:key="order.id">
				<v-card-title class="pb-0">
					<h2>Rota {{order.id}}</h2>
					<LogisticsStatusChip :status="order.status"/>
				</v-card-title>
				<v-divider class="my-2 divider"></v-divider>
				<v-card-text>
					<span class="text-uppercase span">
						Data de criação
					</span>
					<p style="font-size:17px"><strong>{{ order.created_at | formatDate('DD/MM/YYYY - HH:mm') }}</strong></p>
					<span class="text-uppercase span">
						Fornecedor
					</span>
					<p>
						<strong>{{order.fleet}}
							<template v-if="getModalityFromOrder(order)">
								({{ getModalityFromOrder(order) }})
							</template>
						</strong>
					</p>
					<span class="text-uppercase span">
						Paradas
					</span>
					<p>{{order.cargos.length}}</p>
					<span class="text-uppercase span">
						Entregues / Enviados
					</span>
					<p>{{getVolumesQuantity(order, "DELIVERED")}} / {{getVolumesQuantity(order)}}</p>
				</v-card-text>
				<v-card-actions>
					<v-layout class="py-3 px-2">
						<v-btn large icon @click="() => getOrderDetails(order.id)">
							<v-icon small>add</v-icon>
						</v-btn>
						<v-btn v-if="shouldWarn(order)" style="background-color:red" large icon @click="() => getOrderDetails(order.id)">
							<v-icon color="white" medium>error_outline</v-icon>
						</v-btn>
						<v-btn v-if="canCancel(order)" class="ml-auto" large icon @click="() => setOrderToCancel(order.id)">
							<v-tooltip bottom>
								Cancelar pedido
								<template v-slot:activator="{ on }">
									<v-icon small color="red" v-on="on">close</v-icon>
								</template>
							</v-tooltip>
						</v-btn>
					</v-layout>
				</v-card-actions>
			</v-card>
		</v-flex>

		<v-container class="max-width">
			<v-flex class="q-row justify-center">
				<v-pagination
					v-if="totalOrders > 0"
					circle
					v-model="pagination.page"
					class="my-4  theme--dark"
					:length="pagination.total"
					@input="pageChanged">
				</v-pagination>
				<h3 v-else>Nenhuma rota encontrada</h3>
			</v-flex>
		</v-container>

		<v-dialog scrollable v-model="showModalDetails" transition="fade" max-width="720px">
			<transition>
				<Dialog v-if="showModalDetails" backgroundHeader="white" background="white"
					:loading="loading" @acao="destroy" @close="showModalDetails = false">
					<LogisticsOrderDetails
						:order="detailedOrder"
						:modality="getModalityFromOrder(detailedOrder)"
						:quotationInfo="getQuotationInfo(detailedOrder)"/>
				</Dialog>
			</transition>
		</v-dialog>

		<v-dialog
			persistent
			scrollable
			v-model="showModalCancel"
			transition="fade"
			max-width="500px">
			<transition>
				<Dialog v-if="showModalCancel" backgroundHeader="white" background="white"
					:loading="loading" @acao="destroy" @close="showModalCancel = false">
					<p style="font-size:18px">{{`Confirmo que desejo cancelar a Rota ${detailedOrder.id}`}}</p>
					<v-layout class="justify-end p-0 pt-2">
						<v-btn @click="cancelOrder(detailedOrder.id)" :disabled="loading">Confirmar</v-btn>
					</v-layout>
				</Dialog>
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { LogisticaPresenter } from '@Presenter/logistica-presenter';
import { statusEnum as orderStatusEnum } from '@Consts/status-logistics';
import { getCurrentCompanyUnit } from '@Config/unidades';
import LogisticsHeader from '../header.vue';
import LogisticsOrderDetails from '../order-details.vue';
import LogisticsStatusChip from '../order-status-chip.vue';

export default {
	name: 'ShippingFollowUp',
	components: {
		LogisticsHeader,
		LogisticsOrderDetails,
		LogisticsStatusChip
	},
	data: () => ({
		loading: false,
		showModalDetails: false,
		showModalCancel: false,
		pagination: {
			page: 1,
			size: 15,
			total: 0
		},
		filter: {
			status: 'all',
			search: null,
			days: 1
		},
		totalOrders: 0,
		orders: [],
		detailedOrder: null,
		orderStatusEnum,
		tabs: [
			{
				key: 'all',
				text: 'Todas',
				value: 0,
				style: 'default'
			},
			{
				key: orderStatusEnum.PENDING,
				text: 'Pendente',
				value: 0,
				style: 'default'
			},
			{
				key: orderStatusEnum.STARTED,
				text: 'Em rota',
				value: 0,
				style: 'default'
			},
			{
				key: orderStatusEnum.FINISHED,
				text: 'Concluido',
				value: 0,
				style: 'default'
			},
			{
				key: orderStatusEnum.CANCELLED,
				text: 'Cancelados',
				value: 0,
				style: 'delay'
			}],
		filterField: {
			show: true,
			showDateFilter: true,
			showFleetFilter: true,
			inputSearchFilter: {
				show: true,
				label: 'Volumes ID (separe por vírgula)'
			},
			inputIdsFilter: {
				show: false,
				label: null
			},
			showDemandRegionFilter: false,
			showStatusFilter: false
		}
	}),
	created() {
		this.filterData();
	},

	methods: {

		async filterData() {
			try {
				const {
					orders: ordersResponse,
					total_count,
					total_by_status
				} = await LogisticaPresenter.v2.getOrders({
					branch: getCurrentCompanyUnit().id,
					status: this.filter.status === 'all' ? null : this.filter.status,
					days: this.filter.days,
					fleets: this.filter.fleets,
					page: this.pagination.page,
					perPage: this.pagination.size,
					volumeIds: this.filter.search
				});
				this.orders = ordersResponse.map(t => t.order);
				this.totalOrders = total_count || 0;
				if (this.totalOrders)
					this.pagination.total = Math.ceil(this.totalOrders / this.pagination.size);
				this.tabs[0].value = this.totalOrders;

				this.updateTabsWithTotal(total_by_status);
			} catch (e) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: `Erro ao buscar rotas ${e.message}`
				});
			}
		},

		updateTabsWithTotal(totalByStatus) {
			this.tabs = this.tabs
				.map(tab => {
					if (tab.key !== 'all')
						tab.value = Number(totalByStatus[tab.key]) || 0;
					return tab;
				});
		},

		async filterDataByStatus(statusParam) {
			this.filter.status = statusParam;
			this.pagination.page = 1;
			this.filterData();
		},

		pageChanged(pageClicked) {
			this.pagination.page = pageClicked;
			this.filterData();
		},

		filterChanged(filterParams) {
			this.filter.status = filterParams.status;
			this.filter.days = filterParams.days;
			this.filter.fleets = filterParams.fleets;
			this.filter.search = filterParams.search;
			this.pagination.page = 1;
			this.filterData();
		},

		async getOrderDetails(id) {
			this.loading = true;
			try {
				const orderResponse = await LogisticaPresenter.v2.getOrder(id);
				this.detailedOrder = orderResponse.order;
			} catch (e) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: `Erro ao buscar detalhe da rota ${id}: ${e.message}`
				});
			} finally {
				this.loading = false;
				this.showModalDetails = true;
			}
		},

		async cancelOrder(id) {
			this.loading = true;
			try {
				await LogisticaPresenter.v2.cancelOrder(id);
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'success',
					message: `Rota ${id} cancelada`
				});
				this.filterDataByStatus('all');
			} catch (e) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: `Erro ao cancelar rota ${id}: ${e.message}`
				});
			} finally {
				this.loading = false;
				this.showModalCancel = false;
			}
		},

		getVolumesQuantity(order, status) {
			let sum = 0;
			order.cargos.forEach(c => {
				if (status)
					sum += c.volumes.filter(v => v.status === status).length;
				else
					sum += c.volumes.length;
			});
			return sum;
		},

		setOrderToCancel(orderId) {
			this.detailedOrder = {
				id: orderId
			};
			this.showModalCancel = true;
		},

		canCancel(order) {
			return order.status === orderStatusEnum.PENDING;
		},

		shouldWarn(order) {
			return order.cargos.some(c => c.volumes.some(v => v.status === 'FAILED' || v.status === 'CANCELLED'));
		},
		getQuotationInfo(order) {
			if (order.extras && order.extras.quotation)
				return order.extras.quotation;
			return null;
		},
		getMetadataInfo(quotation) {
			if (quotation && quotation.metadata)
				return quotation.metadata;
			return null;
		},
		getQuotationModality(metadata) {
			if (metadata && metadata.modality)
				return metadata.modality;
			return null;
		},
		getModalityFromOrder(order) {
			const quotation = this.getQuotationInfo(order);
			const metadata = this.getMetadataInfo(quotation);
			return this.getQuotationModality(metadata);
		}
	}
};

</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

::v-deep .v-sheet {
	background-color: $secondary-light!important;
	margin-bottom: 10px;
	border-radius: 30px;
	padding: 30px 10px;
	box-shadow: 0 0 0 0 rgba(0,0,0,.2),0 0 0 0 rgba(0,0,0,.14),0 0 0 0 rgba(0,0,0,.12);
}

.v-btn{
	background-color: #F2D6AC;
}

.span {
	color: rgba(52, 43, 29, 0.70);
}

.divider{
	border: 2px solid #F2D6AC!important;
}

.v-dialog--scrollable > .v-card {
	background-color: white!important;
}

</style>
