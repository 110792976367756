<template>
	<Dialog
		backgroundHeader="white"
		:loading="loading"
		@close="close()">
		<h3 class="text-uppercase ml-2">Itens selecionados</h3>
		<v-divider class="my-3"></v-divider>
		<v-layout
			class="align-center"
			v-for="item in quotedItems"
			:key="item.id">
			<span class="ml-2">
				{{
					`[${getLogisticReferenceTypeDescription(item.type)} ID ${item.id}] ${item.logradouro}, ${item.numero}, ${item.bairro}, ${item.complemento || ''}.${item.cep} - ${item.cidade}. ${item.demand_region || ''}`
				}}
			</span>
		</v-layout>

		<h3 class="text-uppercase ml-2 mt-4">Preços</h3>
		<v-divider class="my-3"></v-divider>
		<v-radio-group v-model="quotationId" class="ma-0 pb-3" hide-details>
			<div
				class="outlined-radio mb-2"
				v-for="quotation in quotations"
				:key="quotation.quotation_id"
				:style="shouldShowQuotationError(quotation) ? 'display: flex;justify-content:space-between;' : ''">

				<v-radio class="py-3 px-3" :value="quotation.quotation_id" :disabled="shouldShowQuotationError(quotation)" :style="shouldShowQuotationError(quotation) ? 'margin-bottom: 0;' : ''">
					<template v-slot:label>
						<h4 class="text-uppercase">{{ quotation.fleet }}</h4>
						<span
							v-if="quotation.metadata && quotation.metadata['modality']">
							&nbsp; ({{ quotation.metadata["modality"] }})</span>
						<span v-if="!shouldShowQuotationError(quotation)" class="ml-auto"><strong>R$ {{ quotation.quotation }}</strong></span>
					</template>
				</v-radio>

				<v-tooltip v-if="shouldShowQuotationError(quotation)" top style="align-self: center;" class="mr-4">
					<v-icon color="red" medium
						slot="activator">error_outline</v-icon>
					<span>{{quotation.error}}</span>
				</v-tooltip>
			</div>
		</v-radio-group>
		<v-divider class="my-3"></v-divider>
		<div v-if="showVolumesField()">
			<v-text-field
				background-color="white"
				type="number"
				v-model="volumesToSend"
				box
				label="Volumes por pedido"
				v-validate="{ required: true }"/>

			<p><b>Itens selecionados</b>: {{selectedIdsSize}}</p>
			<p><b>Volumes por pedido</b>: {{volumesToSend}}</p>
		</div>

		<v-btn class="py-4" block depressed @click="postOrder" :disabled="!quotationId || loading || (showVolumesField() && volumesToSend <= 0)">
			Realizar pedido
		</v-btn>
	</Dialog>
</template>

<script>

import { LogisticaPresenter } from '@Presenter/logistica-presenter';
import { getLogisticReferenceTypeDescription, logisticReferenceTypes } from '@Consts/reference-type-logistic';

export default {
	name: 'DialogLogisticsQuotation',
	props: {
		selectedIdsSize: {
			type: Number,
			required: true
		},
		quotedItems: {
			type: Object,
			required: true
		},
		quotations: {
			type: Array,
			required: true
		},
		reloadData: {
			type: Function,
			required: false
		},
		clearSelected: {
			type: Function,
			required: false
		}
	},
	data: () => ({
		volumesToSend: 0,
		loading: false,
		quotationId: null,
		getLogisticReferenceTypeDescription
	}),
	watch: {
		quotationId: {
			handler() {
				this.volumesToSend = 0;
			}
		}
	},
	methods: {
		close() {
			this.$emit('update:close', false);
		},

		shouldShowQuotationError(quotation) {
			return quotation.error?.length > 0;
		},

		showVolumesField() {
			const selectedQuotation = this.quotations.find(
				q => q.quotation_id === this.quotationId
			);
			return selectedQuotation?.metadata?.inform_volumes_quantity;
		},

		async postOrder() {
			if (!this.quotationId) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Selecione um fornecedor!'
				});
			}

			if (this.selectedIdsSize === 0) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Selecione ao menos 1 item'
				});
			}

			const boxIds = Object.values(this.quotedItems).filter(item => item.type === logisticReferenceTypes.box).map(s => s.id);
			const orderIds = Object.values(this.quotedItems).filter(item => item.type === logisticReferenceTypes.order).map(s => s.id);
			try {
				this.loading = true;

				const fleet = this.quotations.find(
					q => q.quotation_id === this.quotationId
				);

				await LogisticaPresenter.v2.postOrder({
					box_ids: boxIds,
					order_ids: orderIds,
					quotation: fleet,
					volumesQuantity: this.volumesToSend ? parseInt(this.volumesToSend, 10) : null
				});
				this.volumesToSend = 0;
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'success',
					message: 'Pedido realizado com sucesso. Para acompanhá-lo, basta acessar a aba de "Acompanhamento"'
				});
				this.$emit('clearSelected');
				this.close();
			} catch (e) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: `Erro ao realizar pedido: ${e.message}`
				});
			} finally {
				this.loading = false;
				this.$emit('reloadData');
			}
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
