<template>
	<div>
		<Medicamento
			:disabled="disabled"
			:estoque="estoque"
			:medicamento="medicamento"
			:mostrar_excluir="mostrar_excluir"
			:somente_leitura="somente_leitura"
			:assinatura="assinatura"
			:inverter_cores="inverter_cores"
			:data_base_pausa="data_base_pausa"
			:boxId="boxId"
			:quantidadeAproveitamentoReceita="quantidadeAproveitamentoReceita"
			:medicines_checklist="medicines_checklist"
			:receitas="receitas"
			:prescriptionReadonly="prescriptionReadonly"
			:prescriptionIsVisible="prescriptionIsVisible"
			:e_rascunho="e_rascunho"
			@remover-medicamento="dialog_remover_medicamento = true"
			@editar-medicamento="editarMedicamento"
			@pausar-medicamento="pausarMedicamento"
			@check-medicine="$emit('check-medicine')"
			@receita-vinculada="$emit('receita-vinculada', true)"/>

		<DialogMedicamento
			:visible="dialog_medicamento"
			:close.sync="dialog_medicamento"
			:medicamento_selecionado="medicamento_selecionado"
			:medicamentos="medicamentos"
			:medicamentosPorHorarios="medicamentosPorHorarios"
			:discount_percentage="getDiscountPercentage"
			:orcamento_filial_id="assinatura ? assinatura.filial_id : orcamento_filial_id"
			:somente_leitura="somente_leitura"
			:fluxo="fluxo"
			:assinaturaId="assinaturaId || (assinatura ? assinatura.id : null)"
			:tipoCliente="tipoCliente"
			:externalBilling="externalBilling"
			:permiteTrocarMedicamento="permiteTrocarMedicamento"
			:restHomeId="restHomeId"
			@medicamento-salvo="salvarMedicamento" />

		<v-dialog persistent scrollable v-model="dialog_remover_medicamento" transition="fade" max-width="340px">
			<transition>
				<Dialog v-if="dialog_remover_medicamento" titulo="Remover medicamento" subtitulo="Deseja remover o medicamento da lista?"
					btn_acao="Remover" btn_acao_class="error" btn_cancelar="Cancelar" @acao="removerMedicamento"
					:loading="removendo" @close="dialog_remover_medicamento = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_pausar_medicamento" transition="fade" max-width="700px">
			<transition>
				<DialogPausarMedicamento v-if="dialog_pausar_medicamento"
					@close="dialog_pausar_medicamento = false" :e_rascunho="e_rascunho"
					@pausa-adicionada="$emit('pausa-adicionada')"
					@pausa-removida="$emit('pausa-removida')"
					@pausa-editada="$emit('pausa-editada')"
					:medicamento_selecionado="medicamento_selecionado" :data_base_pausa="data_base_pausa"
					:medicamento_pausado="!!medicamento_selecionado.pausa"/>
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import DialogMedicamento from '@Componentes/medicamento/dialog-medicamento';
import DialogPausarMedicamento from '@Componentes/medicamento/dialog-medicamento/dialog-pausar-medicamento';
import { isEqual } from 'lodash';
import Medicamento from './medicamento';

export default {
	name: 'MedicamentoCard',
	components: { Medicamento, DialogMedicamento, DialogPausarMedicamento },
	props: {
		assinatura: {
			type: Object,
			required: false
		},
		discountPercentage: {
			type: Number,
			required: false,
			default: 0
		},
		boxId: {
			required: false,
			default: null
		},
		medicamento: {
			type: Object,
			required: true
		},
		medicamentos: {
			type: Array,
			default: () => []
		},
		medicines_checklist: {
			type: Array,
			default: () => []
		},
		estoque: {
			type: Array,
			default: () => []
		},
		quantidadeAproveitamentoReceita: {
			required: false,
			type: Number,
			default: 1
		},
		mostrar_excluir: {
			type: Boolean,
			default: true
		},
		somente_leitura: {
			type: Boolean,
			default: false
		},
		inverter_cores: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		data_base_pausa: {
			type: Object,
			required: false
		},
		medicamentosPorHorarios: {
			type: Object,
			default: () => ({})
		},
		orcamento_filial_id: {
			type: Number,
			required: false
		},
		fluxo: {
			type: String,
			required: false
		},
		assinaturaId: {
			type: Number,
			required: false
		},
		tipoCliente: {
			type: String,
			required: false
		},
		receitas: {
			required: false,
			type: Array
		},
		prescriptionReadonly: {
			type: Boolean,
			default: true
		},
		prescriptionIsVisible: {
			type: Boolean,
			default: false
		},
		e_rascunho: {
			type: Boolean,
			default: false
		},
		permiteTrocarMedicamento: {
			type: Boolean,
			default: false
		},
		restHomeId: {
			required: false
		},
		externalBilling: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		dialog_medicamento: false,
		dialog_remover_medicamento: false,
		dialog_pausar_medicamento: false,
		medicamento_selecionado: {},
		removendo: false
	}),
	methods: {
		salvarMedicamento(produto) {
			this.$emit('produto-salvo', produto);
		},
		editarMedicamento() {
			this.medicamento_selecionado = this.medicamento;
			this.dialog_medicamento = true;
		},
		removerMedicamento() {
			this.removendo = true;
			setTimeout(() => {
				const i = this.medicamentos.findIndex(med => isEqual(med, this.medicamento));
				const produtoRemovido = { ...this.medicamentos[i] };
				this.medicamentos.splice(i, 1);
				this.dialog_remover_medicamento = false;
				this.removendo = false;
				this.$emit('produto-removido', produtoRemovido);
			}, 500);
		},
		pausarMedicamento() {
			this.medicamento_selecionado = this.medicamento;
			this.dialog_pausar_medicamento = true;
		}
	},
	computed: {
		getDiscountPercentage() {
			return this.discountPercentage || this.getDiscountPercentageBySubscription;
		},
		getDiscountPercentageBySubscription() {
			if (this.assinatura && this.assinatura.paciente && this.assinatura.paciente.casa_repouso)
				return this.assinatura.paciente.casa_repouso.porcentagem_desconto;

			return 0;
		}
	}
};
</script>

<style scoped lang="scss">
@import "@Assets/css/settings.scss";

.medicamento-card {
  overflow: hidden;

  .h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0 0;

    @media screen and (min-width: $break-point-sm) {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: 0;
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (min-width: $break-point-md) {
      transform: translateX(20px);
      opacity: 0;
      transition: all 0.3s;
    }
  }

  &:hover {
    .actions {
      transform: translateX(0px);
      opacity: 1;
    }
  }
}
</style>
