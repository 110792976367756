<template>
	<div class="px-md-5">

		<HeaderProcesso
			titulo="Produção"
			subtitulo="Gere as etiquetas necessárias para produção da box."
			subtitulo2="Aqui estão listadas as boxes que acabaram de ser faturadas."
			btn_icon="fas fa-file-excel"
			btn_label="Gerar etiquetas"
			:btn_loading="gerando"
			:btn_disabled="selecionados.length === 0"
			:btn_on_click="gerarEtiquetasNovas"
			@busca="val => busca = val" />

		<DataTableProcesso
			selectable
			:boxes="boxes"
			:loading="carregando"
			:search="busca"
			:buttons="buttons"
			@selected-updated="arr => selecionados = arr" />

		<v-dialog persistent scrollable v-model="dialog_etiquetas_antigas" transition="fade" max-width="920px">
			<transition>
				<DialogEscolherMedicamentos v-if="dialog_etiquetas_antigas" :box_id="item_selecionado.id" etiqueta
					@close="dialog_etiquetas_antigas = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_iniciar_producao" transition="fade" max-width="500px">
			<transition>
				<DialogConfirmacao v-if="dialog_iniciar_producao" @close="dialog_iniciar_producao = false" :request="updateStatus('IN_PRODUCTION')" :opcoes="[
					`Confirmo que irei iniciar a produção da box de ${name} agora`
				]" />
			</transition>
		</v-dialog>

		<!-- Dialog Ocorrências -->
		<v-dialog persistent scrollable v-model="dialog_enviar_conferencia" transition="fade" max-width="700px">
			<transition>
				<DialogOcorrencias v-if="dialog_enviar_conferencia" :box_id="item_selecionado.id" :request="updateStatus('PRODUCED')" status="PRODUCED" :close.sync="dialog_enviar_conferencia">
					<p class="mt-4">&bull; Confirmo que finalizei a produção da box de {{ name }} agora</p>
				</DialogOcorrencias>
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { ProcessoMixin } from '@Mixins/processo';
import { BoxPresenter } from '@Presenter/box-presenter';
import { geraExcelProducao } from '@Util/excel-producao';
import DialogEscolherMedicamentos from '@Componentes/dialog-escolher-medicamentos';
import DialogOcorrencias from '@Componentes/dialog-ocorrencias';

export default {
	name: 'Envio',
	mixins: [ProcessoMixin],
	components: { DialogEscolherMedicamentos, DialogOcorrencias },
	data: () => ({
		gerando: false,
		name: '',
		statusToShow: ['BILLED', 'IN_PRODUCTION'],
		dialog_iniciar_producao: false,
		dialog_etiquetas_antigas: false,
		dialog_enviar_conferencia: false
	}),
	created() {
		const inProduction = item => item.status_box.some(s => s === 'IN_PRODUCTION');

		this.buttons = [
			{
				label: 'Gerar etiqueta de rastreio',
				icon: 'fas fa-qrcode',
				onClick: item => this.gerarEtiquetaRastreio(item)
			},
			{
				label: 'Gerar etiquetas do saquinho',
				icon: 'fas fa-file-alt',
				onClick: item => this.abrirDialog(item, 'dialog_etiquetas_antigas')
			},
			{
				label: 'Enviar para conferência',
				icon: 'fa-thin fa-check',
				onClick: item => { this.name = item.assinatura.paciente.nome.toUpperCase(); this.abrirDialog(item, 'dialog_enviar_conferencia'); },
				condition: item => inProduction(item)
			},
			{
				label: 'Iniciar produção',
				icon: 'fas fa-play',
				onClick: item => { this.name = item.assinatura.paciente.nome.toUpperCase(); this.abrirDialog(item, 'dialog_iniciar_producao'); },
				condition: item => !inProduction(item)
			}
		];
	},
	methods: {
		async gerarEtiquetasNovas() {
			this.gerando = true;
			const etiquetas = await BoxPresenter.gerarEtiquetas({ ids: this.selecionados.map(o => o.box_id) }).catch(() => { this.gerando = false; });
			geraExcelProducao(etiquetas);
			this.gerando = false;
		}
	}
};
</script>
