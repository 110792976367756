<template>
	<Dialog :titulo="titulo" :subtitulo="subtitulo" btn_acao="Salvar" :btn_excluir="btn_excluir"
		:loading_excluir="excluindo" :loading="salvando"
		@acao="action" @close="close" @excluir="destroy">
		<div v-if="loading" class="loading">
			<v-progress-circular indeterminate color="primary" />
		</div>
		<template v-else>
			<input v-if="!arquivo.id" class="mb-4" type="file" @change="novoArquivoCarregado">
			<v-text-field v-if="arquivo.id" v-model="arquivo.nome" box label="Nome" color="green_strong" />
			<TextDateField v-model="arquivo.data_referencia" label="Data referência" color="green_strong" box />
			<v-select v-model="arquivo.categoria" :items="categorias" box label="Categoria" color="green_strong" />
		</template>

		<v-dialog persistent v-model="dialog_excluir_arquivo" scrollable transition="fade"
			max-width="400px">
			<transition>
				<Dialog v-if="dialog_excluir_arquivo" titulo="Excluir arquivo" subtitulo="Tem certeza que deseja excluir este arquivo?"
					btn_acao="Excluir" btn_acao_class="error" btn_cancelar="Cancelar" @acao="destroy"
					@close="dialog_excluir_arquivo = false" />
			</transition>
		</v-dialog>

	</Dialog>
</template>

<script>
import moment from 'moment';
import { ArquivoPresenter } from '@Presenter/arquivo-presenter';

export default {
	name: 'DialogUploadArquivo',
	props: {
		paciente_id: {
			type: Number,
			required: true
		},
		arquivo_id: {
			type: Number
		}
	},
	data: () => ({
		categorias: [],
		loading: true,
		arquivo: {
			url: null,
			categoria: null,
			data_referencia: moment().format('DD/MM/YYYY')
		},
		salvando: false,
		excluindo: false,
		dialog_excluir_arquivo: false
	}),
	computed: {
		titulo() {
			if (this.arquivo_id)
				return 'Editar arquivo';
			return 'Upload de arquivos';
		},
		subtitulo() {
			if (this.arquivo.id)
				return this.arquivo.nome;
			return 'Selecione o arquivo no seu computador';
		},
		btn_excluir() {
			if (this.arquivo.id)
				return 'Excluir';
			return '';
		}
	},
	created() {
		const promises = [ArquivoPresenter.categorias()];
		if (this.arquivo_id)
			promises.push(ArquivoPresenter.show(this.arquivo_id));
		Promise.all(promises)
			.then(([categorias, arquivo]) => {
				this.categorias = categorias;
				this.arquivo.categoria = categorias[0];
				if (arquivo) {
					arquivo.data_referencia = moment(arquivo.data_referencia).format('DD/MM/YYYY');
					this.arquivo = arquivo;
				}
			})
			.finally(() => {
				this.loading = false;
			});
	},
	methods: {
		novoArquivoCarregado(e) {
			if (e.target) {
				const files = e.target.files || e.dataTransfer.files;
				if (!files.length)
					return;
				this.arquivo.url = files[0];
			}
		},
		action() {
			if (!this.arquivo.url) {
				this.$store.dispatch('SHOW_SNACKBAR', { message: 'É necessário selecionar um arquivo', color: 'error' });
				return;
			}

			if (this.arquivo.id)
				this.update();
			else
				this.store();
		},
		async store() {
			this.salvando = true;
			const formData = new FormData();
			formData.append('arquivo', this.arquivo.url);
			formData.append('categoria', this.arquivo.categoria);
			formData.append('paciente_id', this.paciente_id);
			formData.append('data_referencia', this.arquivo.data_referencia);
			await ArquivoPresenter.store(formData);
			this.afterRequest();
		},
		async update() {
			this.salvando = true;
			await ArquivoPresenter.update({
				id: this.arquivo.id,
				nome: this.arquivo.nome,
				categoria: this.arquivo.categoria,
				data_referencia: this.arquivo.data_referencia
			});
			this.afterRequest();
		},
		async destroy() {
			this.excluindo = true;
			await ArquivoPresenter.destroy(this.arquivo.id);
			this.afterRequest();
		},
		afterRequest() {
			this.excluindo = false;
			this.salvando = false;
			this.$emit('arquivo-atualizado');
			this.close();
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>

<style lang="scss" scoped>
.upload-container {
	border: 2px dashed lightgrey;
	border-radius: 4px;
}
</style>
