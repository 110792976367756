<template>
	<Dialog
		titulo="Escolha os dias"
		subtitulo="Selecione os dias e confirme."
		btn_acao="Concluir"
		btn_cancelar="Cancelar"
		@acao="$emit('dias', novos_dias)"
		@close="$emit('update:close', false)">
		<div>
			<v-btn
				small
				flat
				round
				v-for="(dia, index) in dias_mes"
				:key="index"
				@click="handleDias(dia)"
				:class="dia.ativo ? 'primary' : null"
				class="mx-1"
				:icon="dia.nome !== 'Último dia do mês'">
				{{ dia.nome }}
			</v-btn>
		</div>
	</Dialog>
</template>

<script>
export default {
	name: 'DialogAdicionarDias',
	props: {
		dias: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			novos_dias: [],
			dias_mes: [
				{ nome: '01', ativo: false },
				{ nome: '02', ativo: false },
				{ nome: '03', ativo: false },
				{ nome: '04', ativo: false },
				{ nome: '05', ativo: false },
				{ nome: '06', ativo: false },
				{ nome: '07', ativo: false },
				{ nome: '08', ativo: false },
				{ nome: '09', ativo: false },
				{ nome: '10', ativo: false },
				{ nome: '11', ativo: false },
				{ nome: '12', ativo: false },
				{ nome: '13', ativo: false },
				{ nome: '14', ativo: false },
				{ nome: '15', ativo: false },
				{ nome: '16', ativo: false },
				{ nome: '17', ativo: false },
				{ nome: '18', ativo: false },
				{ nome: '19', ativo: false },
				{ nome: '20', ativo: false },
				{ nome: '21', ativo: false },
				{ nome: '22', ativo: false },
				{ nome: '23', ativo: false },
				{ nome: '24', ativo: false },
				{ nome: '25', ativo: false },
				{ nome: '26', ativo: false },
				{ nome: '27', ativo: false },
				{ nome: '28', ativo: false },
				{ nome: 'Último dia do mês', ativo: false }
			]
		};
	},
	created() {
		this.novos_dias = this.dias.map(dia => dia);
		this.novos_dias.forEach(dia => {
			this.dias_mes.forEach(d => {
				if (d.nome === dia)
					d.ativo = true;
			});
		});
	},
	methods: {
		handleDias(dia) {
			if (dia.ativo) {
				dia.ativo = false;
				const i = this.novos_dias.findIndex(h => h === dia.nome);
				this.novos_dias.splice(i, 1);
			} else {
				dia.ativo = true;
				this.novos_dias.push(dia.nome);
			}

			this.novos_dias.sort();
		}
	}
};
</script>
