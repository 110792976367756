<template>
	<header class="py-4">
		<v-layout row class="mb-4">
			<v-flex xs12 md12>
				<v-layout wrap class="items-center">
					<h1 class="text--text mb-2" style="opacity: 0.8; font-size: 35px">
						{{ title }}
					</h1>
					<v-tabs centered class="ml-5">
						<v-tab
							class="tab"
							v-for="tab in tabs"
							:key="tab.key"
							@click="$emit('tabChanged', tab.key)"
							:class="{ 'delay-text': tab.style === 'delay' }">
							{{ tab.text }}
							<v-badge
								:class="tab.style == 'delay' ? 'badge delay-badge' : 'badge'"
								color="primary">
								<span> {{ tab.value }} </span>
							</v-badge>
						</v-tab>
					</v-tabs>
					<v-flex class="q-row items-center justify-end">
						<v-menu
							class="px-2"
							v-if="filter.show"
							:close-on-content-click="false">
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									depressed
									class="filter-button black--text"
									style="mix-width: 200px">
									<span style="flex-grow: 1">Filtros</span>
									<v-icon small class="ml-3 mr-2">keyboard_arrow_down</v-icon>
								</v-btn>
							</template>
							<v-list class="py-5">
								<v-list-tile>
									<v-list-tile-title>Filtros</v-list-tile-title>
								</v-list-tile>
								<v-divider v-if="filter.showDateFilter"></v-divider>
								<v-list-tile class="py-2" v-if="filter.showDateFilter">
									<v-text-field
										v-model="days"
										append-icon="date_range"
										label="Ultimos dias"
										@input="emitFilterChanged"
										class="my-5"
										type="number"
										min="1"
										hide-details/>
								</v-list-tile>
								<v-divider v-if="filter.inputIdsFilter.show"></v-divider>
								<v-list-tile class="py-2" v-if="filter.inputIdsFilter.show">
									<v-text-field
										v-model="searchIdsInput"
										append-icon="search"
										:label="filter.inputIdsFilter.label"
										@input="emitFilterChanged"
										class="my-5"
										type="text"
										:title="filter.inputIdsFilter.label"
										hide-details/>
								</v-list-tile>
								<v-divider v-if="filter.inputSearchFilter.show"></v-divider>
								<v-list-tile class="py-2" v-if="filter.inputSearchFilter.show">
									<v-text-field
										v-model="search"
										append-icon="search"
										:label="filter.inputSearchFilter.label"
										@input="emitFilterChanged"
										class="my-5"
										type="text"
										:title="filter.inputSearchFilter.label"
										hide-details/>
								</v-list-tile>
								<v-divider
									class="my-2"
									v-if="filter.showFleetFilter"></v-divider>
								<v-list-tile disabled v-if="filter.showFleetFilter">
									<v-list-tile-title>Fornecedor</v-list-tile-title>
								</v-list-tile>
								<v-list-tile class="py-2" v-if="filter.showFleetFilter">
									<v-flex>
										<v-checkbox
											v-model="fleets.LALAMOVE"
											hide-details
											label="LALAMOVE"
											@change="emitFilterChanged"/>
										<v-checkbox
											v-model="fleets.LS_TRANSLOG"
											hide-details
											label="LS_TRANSLOG"
											@change="emitFilterChanged"/>
										<v-checkbox
											v-model="fleets.LINKBOLT"
											hide-details
											label="LINKBOLT"
											@change="emitFilterChanged"/>
									</v-flex>
								</v-list-tile>
								<v-divider
									class="my-2"
									v-if="filter.showDemandRegionFilter"></v-divider>
								<v-list-tile class="py-2" v-if="filter.showDemandRegionFilter">
									<v-select
										@change="emitFilterChanged"
										v-model="selectedDemandRegions"
										:items="Object.keys(demandRegions)"
										return-object
										chips
										label="Regiões de demanda"
										multiple>
									</v-select>
								</v-list-tile>
								<v-divider
									class="my-2"
									v-if="filter.showStatusFilter"></v-divider>
								<v-list-tile class="py-2" v-if="filter.showStatusFilter">
									<v-select
										@change="emitFilterChanged"
										v-model="selectedStatus"
										:items="statuses"
										:item-text="(item) => `${item.name}`"
										item-value="value"
										return-object
										chips
										label="Status"
										multiple>
									</v-select>
								</v-list-tile>
								<template v-if="filter.showTypeFilter">
									<v-divider class="my-2"></v-divider>
									<v-list-tile class="py-2">
										<v-select
											@change="emitFilterChanged"
											v-model="selectedTypes"
											:items="types"
											:item-text="(item) => `${item.label}`"
											item-value="value"
											return-object
											chips
											label="Tipo"
											multiple>
										</v-select>
									</v-list-tile>
								</template>
								<template v-if="filter.showIlpiFilter">
									<v-divider class="my-2"></v-divider>
									<v-list-tile class="mt-4">
										<v-autocomplete
											@change="emitFilterChanged"
											v-model="selectedIlpiIds"
											multiple
											background-color="white"
											:loading="ilpiFilter.loading"
											:disabled="ilpiFilter.loading"
											:items="ilpiFilter.items"
											label="Casas de repouso"
											box
											clearable
											item-value="id"
											item-text="nome"
											small-chips />
									</v-list-tile>
								</template>
							</v-list>
						</v-menu>
						<p v-if="totalSelectedIds" class="mb-1 mx-2">
							{{ totalSelectedIds }} selecionado(s)
						</p>
						<v-btn
							v-if="button.show"
							class="primary"
							@click="$emit('headerButtonAction')"
							:disabled="button.disabled"
							:loading="button.loading">
							{{ button.text }}
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
		<v-layout xs12>
			<v-divider class="divider" />
		</v-layout>
	</header>
</template>

<script>
import { DemandRegionPresenter } from '@Presenter/demand-region-presenter';
import { logisticaStatusLabels as processStatus, logisticaStatus } from '@Consts/status-logistics';
import {
	logisticReferenceTypes,
	getLogisticReferenceTypeDescription
} from '@Consts/reference-type-logistic';
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';

export default {
	name: 'LogisticsHeader',
	components: {},
	props: {
		title: {
			type: String,
			required: true
		},
		totalSelectedIds: {
			type: Number,
			required: false,
			default: 0
		},
		button: {
			type: Object,
			required: false,
			default() {
				return {
					show: false,
					loading: false,
					disabled: false
				};
			}
		},
		headerButtonAction: {
			type: Function,
			required: false
		},
		tabs: {
			type: Array,
			required: false
		},
		tabChanged: {
			type: Function,
			required: false
		},
		filter: {
			type: Object,
			required: false,
			default() {
				return {
					show: false,
					showDateFilter: false,
					showFleetFilter: false,
					inputIdsFilter: {
						show: false,
						label: null
					},
					inputSearchFilter: {
						show: false,
						label: null
					},
					showDemandRegionFilter: false,
					showIlpiFilter: false
				};
			}
		},
		filterChanged: {
			type: Function,
			required: false
		}
	},
	data: () => ({
		days: 1,
		searchIdsInput: '',
		search: '',
		fleets: {
			LALAMOVE: false,
			LS_TRANSLOG: false,
			LINKBOLT: false
		},
		selectedDemandRegions: [],
		selectedStatus: [{ name: processStatus[logisticaStatus.DESPATCHED], value: logisticaStatus.DESPATCHED }, { name: processStatus[logisticaStatus.DELAYED], value: logisticaStatus.DELAYED }],
		demandRegions: [],
		demandRegionsNames: [],
		statuses: Object.keys(processStatus).map(p => ({
			name: processStatus[p],
			value: p
		})),
		selectedTypes: [],
		selectedIlpiIds: [],
		types: [
			{
				id: logisticReferenceTypes.order,
				label: getLogisticReferenceTypeDescription(
					logisticReferenceTypes.order
				)
			},
			{
				id: logisticReferenceTypes.box,
				label: getLogisticReferenceTypeDescription(logisticReferenceTypes.box)
			}
		],
		ilpiFilter: {
			loading: false,
			items: []
		}
	}),
	created() {
		if (this.filter.showDemandRegionFilter)
			this.getDemandRegions();
		if (this.filter.showIlpiFilter)
			this.getIlpis();
	},
	methods: {
		getDemandRegionIds() {
			const selectedRegionsIds = [];

			this.selectedDemandRegions.forEach(
				d => selectedRegionsIds.push(...this.demandRegions[d].map(a => a.id))
			);

			return selectedRegionsIds;
		},

		groupDemandRegionsByName(demandRegions) {
			if (demandRegions) {
				const map = {};

				demandRegions.forEach(region => {
					if (map[region.name])
						map[region.name].push(region);
					else
						map[region.name] = [region];
				});
				this.demandRegions = map;
			}
		},

		emitFilterChanged() {
			return this.$emit('filterChanged', {
				days: this.days,
				searchIdsInput: this.searchIdsInput || null,
				search: this.search || null,
				fleets: Object.keys(this.fleets).filter(key => this.fleets[key]),
				demandRegions: this.getDemandRegionIds(),
				selectedStatuses: this.selectedStatus.map(s => s.value),
				selectedTypes: this.selectedTypes.map(t => t.id),
				selectedIlpiIds: this.selectedIlpiIds
			});
		},

		getDemandRegions() {
			DemandRegionPresenter.getAll().then(demandRegionsResponse => {
				this.groupDemandRegionsByName(demandRegionsResponse);
				this.demandRegionsNames = demandRegionsResponse.map(a => a.name);
			});
		},
		getIlpis() {
			this.ilpiFilter.loading = true;
			CasaRepousoPresenter
				.index()
				.then(ilpiResponse => {
					this.ilpiFilter.items = ilpiResponse;
				}).finally(() => {
					this.ilpiFilter.loading = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.delay-text {
	color: $error !important;
}
.delay-badge {
	background-color: $error !important;
	color: white;
}

.divider {
	border-width: 5px;
	border-radius: 0;
	border-color: #ffd9a0 !important;
}

.tab {
	text-transform: none;
	background-color: $primary_light !important;
	padding: 10px;
}

.badge {
	margin-left: 10px;
	background-color: $primary;
	padding: 4px 10px;
	border-radius: 5px;
}

.filter-button {
	background-color: $primary-light !important;
	border-radius: 12px;
	border: 1.166px solid #3b2c1b;
	min-width: 200px;
}
</style>
