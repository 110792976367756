<template>
	<div class="d-flex justify-space-between">
		<v-card style="width:50%; border-right: 3px dashed #3B2C1B1A; border-radius: 10px 0 0 10px !important;">
			<v-card-title>
				<v-avatar size="38" class="mr-3">
					<v-icon color="#342B1D" small>fas fa-user</v-icon>
				</v-avatar>
				<h3>Cliente</h3>
			</v-card-title>
			<v-card-text>
				<h4 class="mb-2" style="font-size: 16px; line-height: 25px; font-weight: 700">
					<strong>{{customer.name}}</strong>
				</h4>
				<ul style="list-style: none; font-size: 16px; line-height: 25px; font-weight: 400;" class="pa-0">
					<li>{{customer.phone | telefone}}</li>
					<li>{{customer.email}}</li>
					<li>CPF: {{customer.document | cpf}}</li>
					<!-- <li>{{customer.birthdate | idade }} (</li> -->
					<!-- <li>{{customer.gender }} (</li> -->
				</ul>
			</v-card-text>
		</v-card>
		<v-card style="width:50%; border-radius: 0; background: linear-gradient(0deg, #FFF9F1, #FFF9F1),linear-gradient(0deg, #FFFFFF, #FFFFFF); border-radius: 0 10px 10px 0 !important;">
			<v-card-title>
				<v-avatar size="38" class="mr-3">
					<v-icon color="#342B1D" small>fas fa-truck</v-icon>
				</v-avatar>
				<h3>Informações de envio</h3>
			</v-card-title>
			<v-card-text>
				<ul style="list-style: none; font-size: 16px; line-height: 25px; font-weight: 400;" class="pa-0">
					<li>{{address.street}}</li>
					<li>{{address.neighborhood}} {{address.streetNumber}}</li>
					<li v-if="address.complement">{{address.complement}}</li>
					<li>{{address.city}} - {{address.state}}</li>
					<li>CEP: {{address.zipcode}}</li>
				</ul>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
export default {
	name: 'OrderInfo',
	props: {
		customer: {
			type: Object,
			required: true
		},
		address: {
			type: Object,
			required: true
		}
	}
};
</script>

<style scoped>
h3 {
	color: rgba(0, 0, 0, 0.87);
	font-family: Inter;
	font-size: 13.38px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.57px;
}
</style>
