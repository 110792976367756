<template>
	<v-card>
		<!-- Título e descrição -->
		<v-card-title
			:style="`color: #342b1d; background-color: ${backgroundHeader}`"
			class="dialog-header">
			<v-layout fill-height align-center>
				<v-flex v-if="titulo || titulo_extra || subtitulo">
					<h2 v-if="titulo" style="font-weight: 600">{{ titulo }}</h2>
					<p
						v-if="titulo_extra"
						class="mb-0"
						style="font-weight: 600; font-size: 14pt">
						{{ titulo_extra }}
					</p>
					<p v-if="subtitulo" class="mb-0">{{ subtitulo }}</p>
				</v-flex>

				<!-- Botão fechar -->
				<v-flex class="align-end">
					<v-btn
						small
						icon
						@click="$emit('close')"
						class="mr-0"
						:disabled="loading || loading_excluir">
						<v-icon small color="#342b1d">close</v-icon>
					</v-btn> </v-flex><!-- Fim botão fechar -->
			</v-layout> </v-card-title><!-- Fim, título e descrição -->

		<!-- Conteúdo -->
		<v-card-text
			v-if="!!this.$slots.default"
			:class="{ 'pa-0': !padding }"
			:style="`background: ${background};`">
			<div v-if="carregando" class="carregando">
				<v-progress-circular indeterminate color="primary" />
			</div>

			<slot /> </v-card-text><!-- Fim, conteúdo -->

		<v-card-actions
			v-if="btn_acao || btn_excluir || btn_cancelar"
			class="pa-3"
			:style="`background: ${backgroundFooter};`">
			<v-layout align-center>
				<slot name="bottom-left" />
				<v-flex v-if="btn_cancelar || btn_excluir">
					<v-btn
						v-if="btn_excluir"
						flat
						color="error"
						@click="$emit('excluir')"
						:disabled="btn_excluir_disable || loading || carregando"
						:loading="loading_excluir">
						{{ btn_excluir }}
					</v-btn>
					<v-btn
						v-if="btn_cancelar"
						depressed
						@click="$emit('close')"
						:disabled="loading || loading_excluir">
						{{ btn_cancelar }}
					</v-btn>
				</v-flex>
				<v-flex v-if="btn_acao" class="display-flex justify-end">
					<v-btn
						v-if="btn_acao_extra_2"
						depressed
						:class="btn_acao_extra_2_class"
						class="primary my-0"
						@click="$emit('acao_extra_2')"
						:disabled="
							btn_acao_extra_2_disable || loading_excluir || carregando
						"
						:loading="loading_extra_2">
						{{ btn_acao_extra_2 }}
					</v-btn>
					<v-btn
						v-if="btn_acao_extra"
						depressed
						:class="btn_acao_extra_class"
						class="primary my-0"
						@click="$emit('acao_extra')"
						:disabled="btn_acao_extra_disable || loading_excluir || carregando"
						:loading="loading_extra">
						{{ btn_acao_extra }}
					</v-btn>
					<v-btn
						depressed
						:class="btn_acao_class"
						class="primary my-0"
						@click="$emit('acao')"
						:disabled="btn_acao_disable || loading_excluir || carregando"
						:loading="loading">
						{{ btn_acao }}
					</v-btn>
				</v-flex>
			</v-layout>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'Dialog',
	props: {
		titulo: {
			type: String
		},
		subtitulo: {
			type: String
		},
		titulo_extra: {
			type: String
		},
		background: {
			type: String,
			default: 'white'
		},
		backgroundHeader: {
			type: String,
			default: '#ffc875'
		},
		backgroundFooter: {
			type: String,
			default: 'white'
		},
		carregando: {
			type: Boolean,
			default: false
		},
		btn_cancelar: {
			type: String
		},
		btn_acao: {
			type: String
		},
		btn_acao_class: {
			type: String,
			default: null
		},
		btn_acao_disable: {
			type: Boolean
		},
		btn_acao_extra: {
			type: String
		},
		btn_acao_extra_class: {
			type: String,
			default: null
		},
		btn_acao_extra_disable: {
			type: Boolean
		},
		btn_acao_extra_2: {
			type: String
		},
		btn_acao_extra_2_class: {
			type: String,
			default: null
		},
		btn_acao_extra_2_disable: {
			type: Boolean
		},
		btn_excluir: {
			type: String
		},
		btn_excluir_disable: {
			type: Boolean
		},
		loading: {
			type: Boolean,
			default: false
		},
		loading_extra: {
			type: Boolean,
			default: false
		},
		loading_extra_2: {
			type: Boolean,
			default: false
		},
		loading_excluir: {
			type: Boolean,
			default: false
		},
		padding: {
			type: Boolean,
			default: true
		}
	}
};
</script>

<style scoped lang="scss">
	.dialog-header {
		background-size: cover;
		color: #fff;

		&.v-card__title {
			flex: 0 0 auto !important;
		}

		.align-end {
			display: flex;
			justify-content: flex-end;
		}
	}
	.v-card__text {
		position: relative;
		min-height: 80px;

		.carregando {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: #fff;
			z-index: 10;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>
