<template>
	<CardLogin>
		<div v-if="carregando" class="my-5">
			<v-layout row justify-center align-center>
				<v-progress-circular color="purple" size="48" :indeterminate="true" />
			</v-layout>
		</div>
		<div v-else-if="sucesso" class="my-5">
			<h2 class="text--text mb-2" style="line-height: 30px;">E-mail confirmado com sucesso!</h2>
			<p class="titulo mb-5">Clique no botão abaixo para fazer o login.</p>

			<v-layout row justify-center>
				<v-btn depressed @click.stop="irParaLogin" class="ml-0 green_strong white--text" :style="entrando ? null : 'padding: 0 64px'">
					Ir para login
				</v-btn>
			</v-layout>

		</div>
		<div v-else class="my-5">
			<h2 class="error--text mb-2" style="line-height: 30px;">Não conseguimos verificar seu e-mail.</h2>
			<p class="titulo mb-5">Clique no botão abaixo para fazer tentar novamente.</p>

			<v-layout justify-center>
				<v-btn depressed @click.stop="tentarNovamente" :block="!entrando && $vuetify.breakpoint.xsOnly"
					class="ml-0 green_strong white--text" :style="entrando ? null : 'padding: 0 64px'">
					Tentar novamente
				</v-btn>
			</v-layout>
		</div>
		<span />
	</CardLogin>

</template>

<script>
import { UserPresenter } from '@Presenter/user-presenter';

import logo from '@Assets/images/FarmeOS.svg';
import CardLogin from './card-login.vue';

export default {
	name: 'VerificacaoEmail',
	components: {
		CardLogin
	},
	data: () => ({
		carregando: true,
		sucesso: false,
		logo
	}),
	created() {
		this.confirmarEmail();
	},
	methods: {
		confirmarEmail() {
			this.carregando = true;

			const data = {
				email: this.$route.params.email,
				token: this.$route.params.token
			};
			UserPresenter.confirmaEmailVerificado(data)
				.then(() => {
					this.sucesso = true;
				})
				.catch(response => {
					this.mensagemErro = response.data;
					this.sucesso = false;
				})
				.finally(() => {
					this.carregando = false;
				});
		},
		irParaLogin() {
			this.$router.push({ name: 'Entrar', params: { stop: true } });
		},
		tentarNovamente() {
			this.confirmarEmail();
		}
	}
};
</script>
