<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>fas fa-exclamation</v-icon>
			</v-avatar>
			<h3>Observação</h3>
		</v-card-title>
		<v-card-text>
			<v-textarea v-model="assinatura.observacao" :placeholder="placeholder" :disabled="disabled" :readonly="disabled"
				hide-details />
		</v-card-text>

		<v-card-actions v-if="showButton">
			<v-flex class="display-flex justify-end pb-2">
				<v-btn :disabled="!enableButton" color="primary" :loading="loading" @click="atualizaObservacaoAssinatura">Salvar observação</v-btn>
			</v-flex>
		</v-card-actions>
	</v-card>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';

export default {
	name: 'Observacao',
	props: {
		assinatura: {
			type: Object,
			required: true
		},
		disabled: {
			type: Boolean,
			required: false
		}
	},
	data: () => ({
		loading: false,
		observacaoAntiga: ''
	}),
	created() {
		this.observacaoAntiga = this.assinatura.observacao;
	},
	methods: {
		atualizaObservacaoAssinatura() {
			this.loading = true;
			AssinaturaPresenter.updateObservacao(this.assinatura.id, this.assinatura.observacao)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Observação atualizada' });
					this.$emit('observationUpdated');
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao atualizar observação' });
				})
				.finally(() => {
					this.loading = false;
				});
		}
	},
	computed: {
		placeholder() {
			if (this.disabled)
				return 'Nenhuma observação';
			return 'Deseja acrescentar alguma observação?';
		},
		ehFarmaceutico() {
			return this.$store.getters.eh_farmaceutico;
		},
		ehAdministrador() {
			return this.$store.getters.eh_administrador;
		},
		enableButton() {
			return this.observacaoAntiga !== this.assinatura.observacao;
		},
		telaFarmaceutico() {
			return this.$route.name.includes('Farmaceutico');
		},
		showButton() {
			return !this.telaFarmaceutico || (this.telaFarmaceutico && (this.ehFarmaceutico || this.ehAdministrador));
		}
	}
};
</script>
