<template>
	<section
		class="area px-5"
		:class="[getBackgroundColorClass]">
		<DetailHeader v-if="isFinished" :status="status" />
		<div class="content text-center py-5">
			<DetailSubsciptionInfo :subscriptionId="subscriptionId" :status="status"/>
			<DetailPatientInfo :patientName="patientName" :globalTags="globalTags" :hasStock="surplusStock" :status="status" />
			<DetailActions
				:status="status"
				@finished="finished"
				@startProduction="start"
				@finishProduction="finish" />
		</div>
	</section>
</template>

<script>
import { BoxProductionMixin } from '@Mixins/box-production';
import DetailHeader from './header.vue';
import DetailSubsciptionInfo from './subscription.vue';
import DetailPatientInfo from './patiente.vue';
import DetailActions from './actions';

export default {
	components: {
		DetailHeader,
		DetailActions,
		DetailPatientInfo,
		DetailSubsciptionInfo
	},
	mixins: [BoxProductionMixin],
	props: {
		subscriptionId: {
			required: true
		},
		patientName: {
			required: true
		},
		globalTags: {
			required: false
		},
		surplusStock: {
			required: true
		}
	},
	data() {
		return {
			openedFinishCOnfirmedDialog: false
		};
	},
	computed: {
		getBackgroundColorClass() {
			if (this.isFinished)
				return 'bg-finished';
			if (this.isInProgress)
				return 'bg-in-progress';
			return 'bg-default';
		}
	},
	methods: {
		start() {
			this.$emit('startBoxProduction');
		},
		finish(observation) {
			this.$emit('finishBoxProduction', observation);
		},
		finished() {
			this.$emit('finished');
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
.bg-finished { background-color: #FFF3E2;}
.bg-in-progress { background-color: #2DCE71; }
.bg-default {background: rgba(255, 227, 185, 1);}

.area {
	display: flex; flex-direction: column;justify-content: center;align-items: center;
}

.content {
	max-width: 600px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;

}

</style>
