<template>
	<Dialog titulo="Escolher medicamentos" :subtitulo="subtitulo"
		btn_acao="Gerar PDF" btn_cancelar="Cancelar" :loading="loading"
		:btn_acao_disable="selected.length === 0" :btn_acao_extra_disable="selected.length === 0"
		@acao="action" @close="$emit('close')">
		<h3 class="pb-0">Box {{ box.id }}</h3>
		<h4 class="pb-0">Paciente: {{ box.assinatura.paciente.nome }}</h4>

		<v-divider class="my-3" />

		<h4 class="pb-0">Lista de medicamentos:</h4>
		<v-data-table v-model="selected" :headers="headers" :items="box.medicamentos"
			:pagination.sync="pagination" select-all item-key="produto" rows-per-page-text="Itens por página"
			:rows-per-page-items="[15, 30, 45, {text: 'Todos', value: -1}]">
			<template slot="headers" slot-scope="props">
				<tr>
					<th v-show="etiqueta">
						<v-checkbox :input-value="props.all" :indeterminate="props.indeterminate" primary
							hide-details @click.stop="toggleAll"></v-checkbox>
					</th>
					<th v-for="header in props.headers" :key="header.text" :class="['column sortable text-xs-left', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
						@click="changeSort(header.value)">
						{{ header.text }}
						<v-icon small>arrow_upward</v-icon>
					</th>
				</tr>
			</template>
			<template slot="items" slot-scope="props">
				<tr>
					<td v-show="etiqueta">
						<v-checkbox v-model="props.selected" primary hide-details></v-checkbox>
					</td>
					<td>{{ props.item.produto }}</td>
					<td>{{ props.item.dosagem }}</td>
					<td>{{ props.item.principio_ativo }}</td>
					<td>
						<v-tooltip v-if="props.item.liquido || props.item.fora_da_box" top content-class="top">
							<v-icon color="warning" small slot="activator">fas fa-exclamation-triangle</v-icon>
							<span> Este medicamento normalmente é enviado fora da box </span>
						</v-tooltip>
					</td>
				</tr>
			</template>
		</v-data-table>

	</Dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import { openBuffer } from '@Util/functions';
import { geraPdfEtiquetasAntigas } from '@Util/pdf-etiquetas-antigas';

export default {
	name: 'DialogEscolherMedicamentos',
	props: {
		box_id: {
			type: Number,
			required: true
		},
		etiqueta: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		pagination: {
			sortBy: 'produto'
		},
		loading: false,
		etiquetas: {},
		box: { assinatura: { paciente: { nome: '' } } },
		selected: [],
		not_selected: null,
		headers: [
			{
				text: 'Produto',
				align: 'left',
				value: 'produto'
			},
			{ text: 'Dosagem', value: 'dosagem' },
			{ text: 'Princípio ativo', value: 'principio_ativo' },
			{ text: '', sortable: false }
		],
		carregando: true,
		tipo: ''
	}),
	computed: {
		subtitulo() {
			if (this.etiqueta)
				return 'Escolha os medicamentos para gerar as etiquetas';
			return 'Escolhas os medicamentos para gerar a ordem de produção';
		}
	},
	created() {
		BoxPresenter.show(this.box_id)
			.then(box => {
				this.box = box;
				this.toggleAll();
				this.selected = this.selected.filter(sel => !sel.liquido && !sel.fora_da_box);
			})
			.finally(() => {
				this.carregando = false;
			});
	},
	methods: {
		gerarPdfOrdemProducao(box) {
			return new Promise((resolve, reject) => {
				BoxPresenter.gerarPdfOrdemDeProducao(box.id)
					.then(buffer => {
						openBuffer(buffer);
						resolve();
					})
					.catch(() => {
						reject();
					});
			});
		},
		gerarPdfEtiquetasAntigas(meds_to_ignore, box) {
			return new Promise((resolve, reject) => {
				BoxPresenter.gerarEtiquetas({ ids: [box.id], meds_to_ignore })
					.then(({ etiquetasDoSaquinho }) => {
						geraPdfEtiquetasAntigas(etiquetasDoSaquinho);
						resolve();
					})
					.catch(() => {
						reject();
					});
			});
		},
		async action() {
			this.loading = true;
			try {
				const meds_to_ignore = this.box.medicamentos.filter(({ id }) => !this.selected.find(med => med.id === id)).map(({ id }) => id);

				if (this.etiqueta)
					await this.gerarPdfEtiquetasAntigas(meds_to_ignore, this.box);
				else
					await this.gerarPdfOrdemProducao(this.box);
			} catch (err) {
				throw new Error(err);
			} finally {
				this.loading = false;
			}
		},
		toggleAll() {
			if (this.selected.length)
				this.selected = [];
			else
				this.selected = this.box.medicamentos.slice();
		},
		changeSort(column) {
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		}
	}
};
</script>
