<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-layout wrap>
				<v-flex xs12 md6 style="display: flex; flex-direction: row; align-items: center">
					<v-avatar size="38" class="mr-3">
						<v-icon color="white" small>fas fa-user</v-icon>
					</v-avatar>
					<h3>Paciente</h3>
				</v-flex>
			</v-layout>
		</v-card-title>
		<v-card-text style="padding: 32px">
			<v-layout wrap>
				<v-flex xs12 md6>
					<h4 class="titulo">{{ paciente.nome }}</h4>
					<p class="mb-0" v-if="paciente.data_nascimento">
						{{ paciente.data_nascimento | idade }}</p>
					<p class="mb-0" v-if="paciente.telefone">
						{{ paciente.telefone.replace(/[^\d]+/g, '') | telefone }}</p>
					<p class="mb-0" v-if="paciente.email">
						{{ paciente.email }}</p>
					<p class="mb-0" v-if="paciente.cpf">
						{{ paciente.cpf | cpf }}</p>
					<p class="mb-0" v-if="paciente.plano_saude">
						{{ paciente.plano_saude }}</p>
					<div class="mt-2" v-if="responsavel">
						<p class="mb-0"><strong>Responsável Financeiro:</strong></p>
						<p class="mb-0">{{ responsavel.nome }}</p>
						<p class="mb-0" v-if="responsavel.email">
							{{ responsavel.email }}
						</p>
					</div>
				</v-flex>
			</v-layout>
		</v-card-text>

	</v-card>
</template>

<script>

export default {
	name: 'InfoPaciente',
	props: {
		paciente: {
			type: Object,
			required: true
		}
	},
	data: () => ({
	}),
	computed: {
		responsavel() {
			return this.paciente.responsavel || (this.paciente.responsaveis || [])
				.find(x => x.pivot?.tipo === 'financeiro' || x.tipo?.includes('financeiro'));
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
