<template>
	<Dialog titulo="Gerar NFS-e" :subtitulo="subtitulo" :carregando="carregando"
		@close="$emit('update:close', false)">
		<div class="loading" v-if="carregando">
			<v-progress-circular indeterminate color="primary"/>
		</div>
		<v-tabs v-else centered color="green" dark icons-and-text>
			<v-tab>
				Emitir NFS-e
				<v-icon>supervised_user_circle</v-icon>
			</v-tab>
			<v-tab-item>
				<GerarNFS :box="box" :assinatura="assinatura" @carregar-nfs="carregarNfs" />
			</v-tab-item>
			<v-tab>
				Consultar NFS-e emitidas
				<v-icon>shuffle</v-icon>
			</v-tab>
			<v-tab-item>
				<ConsultarNFS :ref="ConsultarNFS" :box="box" />
			</v-tab-item>
		</v-tabs>
	</Dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';

import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import ConsultarNFS from './consultar-nfs';
import GerarNFS from './gerar-nfs';

export default {
	name: 'DialogNfs',
	components: { GerarNFS, ConsultarNFS },
	props: {
		box_id: {
			type: Number,
			required: true
		},
		assinatura_id: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		box: null,
		assinatura: null,
		carregando: true
	}),
	computed: {
		subtitulo() {
			if (this.carregando)
				return 'Carregando...';
			return this.box.assinatura.paciente.nome;
		}
	},
	created() {
		Promise.all([
			BoxPresenter.show(this.box_id),
			AssinaturaPresenter.show(this.assinatura_id)
		])
			.then(([box, assinatura]) => {
				this.box = box;
				this.assinatura = assinatura;
			})
			.finally(() => {
				this.carregando = false;
			});
	},
	methods: {
		carregarNfs() {
			this.$refs.ConsultarNFS.carregarNfs();
		}
	}
};
</script>
