<template>
	<v-layout wrap>
		<v-flex xs12 class="text-xs-right">
			<v-btn outline color="error" @click="openModal">Cancelar pedido</v-btn>
		</v-flex>

		<v-dialog persistent scrollable v-model="cancelOrderModal" transition="fade" max-width="640px">
			<transition>
				<CancelOrderModal v-if="cancelOrderModal" :subtitulo="subtitulo" :loading="loading" @confirm="confirmOrderCancellation" @justification="val => justification = val"
					@close="cancelOrderModal = false" />
			</transition>
		</v-dialog>
	</v-layout>
</template>

<script>
import { OrderPresenter } from '@Presenter/order-presenter';
import CancelOrderModal from './modal.vue';

export default {
	name: 'CancelOrder',
	components: { CancelOrderModal },
	props: {
		order_id: {
			required: true
		},
		customer: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			justification: '',
			loading: false,
			cancelOrderModal: false
		};
	},
	computed: {
		subtitulo() {
			return `Pedido: ${this.order_id} - ${this.customer.name}`;
		}
	},
	methods: {
		openModal() {
			this.cancelOrderModal = true;
		},
		confirmOrderCancellation() {
			this.loading = true;
			OrderPresenter.cancelOrder(this.order_id, this.justification)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Pedido cancelado com sucesso', color: 'success' });
					this.cancelOrderModal = false;
					this.$emit('getOrder');
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao cancelar pedido', color: 'error' });
				})
				.finally(() => {
					this.loading = false;
				});
		}
	}
};
</script>
