<template>
	<Dialog :titulo="titulo" @close="$emit('close')">
		<v-container grid-list-md class="pa-0">
			<v-layout wrap>
				<v-flex xs12>
					<v-btn block depressed class="green white--text" @click="execute('excel')" :loading="excel">
						<v-icon class="mr-2" style="font-size: 14px">fas fa-file-excel</v-icon>
						Excel
					</v-btn>
				</v-flex>
				<v-flex xs12>
					<v-btn block depressed class="green white--text" @click="execute('pdf')" :loading="pdf">
						<v-icon class="mr-2" style="font-size: 14px">fas fa-file-pdf</v-icon>
						PDF
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
export default {
	name: 'dialog-lista-compras',
	props: {
		gerarListaCompra: {
			type: Function,
			require: true
		},
		titulo: {
			type: String,
			require: false,
			default: 'Lista de compra'
		}
	},
	data: () => ({
		pdf: false,
		excel: false
	}),
	methods: {
		async execute(tipo) {
			this[tipo] = true;
			await this.gerarListaCompra(tipo);
			this[tipo] = false;
		}
	}
};
</script>
