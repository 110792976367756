<template>
	<v-card>
		<Dialog titulo="Inserir código de rastreio" subtitulo="O código de rastreio será replicado para todos os itens selecionados." btn_acao="Salvar" :loading="saving" @acao="entregar"
			@close="close" >
			<div class="loading" style="min-height: 84px" v-if="carregando">
				<v-progress-circular indeterminate color="primary"/>
			</div>
			<v-container v-else fluid grid-list-lg class="pa-0">
				<v-layout wrap>
					<v-flex xs12>
						<v-text-field v-model="items.codigo_rastreio" label="Código de rastreio" placeholder="Deixe em branco caso não tenha" box/>
					</v-flex>
				</v-layout>
			</v-container>
		</Dialog>

	</v-card>
</template>

<script>
export default {
	name: 'DialogCodigoRastreioLote',
	data: () => ({
		items: [],
		saving: false,
		carregando: false
	}),

	methods: {
		entregar() {
			this.$emit('entregar', this.items.codigo_rastreio);
			this.close();
		},

		close() {
			this.$emit('update:close', false);
		}
	}

};

</script>
