const RESPONSIBLE_PAYMENT_REST_HOME_VALUE = 'ILPI';
const RESPONSIBLE_PAYMENT_REST_HOME_TEXT = 'ILPI é responsável pelo pagamento';
export const RESPONSIBLE_PAYMENT_REST_HOME = Object.freeze({
	value: RESPONSIBLE_PAYMENT_REST_HOME_VALUE,
	text: RESPONSIBLE_PAYMENT_REST_HOME_TEXT
});

const RESPONSIBLE_PAYMENT_FARMEMBRO_VALUE = 'FARMEMBRO';
const RESPONSIBLE_PAYMENT_FARMEMBRO_TEXT = 'O Farmembro ou Familiar é responsável pelo pagamento';
export const RESPONSIBLE_PAYMENT_FARMEMBRO = Object.freeze({
	value: RESPONSIBLE_PAYMENT_FARMEMBRO_VALUE,
	text: RESPONSIBLE_PAYMENT_FARMEMBRO_TEXT
});

const RESPONSIBLE_PAYMENT_MIXED_VALUE = 'MISTO';
const RESPONSIBLE_PAYMENT_MIXED_TEXT = 'A ILPI é responsável por alguns residentes';
export const RESPONSIBLE_PAYMENT_MIXED = Object.freeze({
	value: RESPONSIBLE_PAYMENT_MIXED_VALUE,
	text: RESPONSIBLE_PAYMENT_MIXED_TEXT
});

export const RESPONSIBLE_PAYMENT_TYPES = Object.freeze([
	RESPONSIBLE_PAYMENT_REST_HOME,
	RESPONSIBLE_PAYMENT_FARMEMBRO,
	RESPONSIBLE_PAYMENT_MIXED
]);
