<template>
	<section>
		<h3 class="my-3">Tipo do item</h3>

		<v-layout wrap>
			<v-flex xs6>
				<FarmeCustomInputRadio
					v-model="form.type"
					:disabled="disabled"
					name="type"
					id="type-medicine"
					value="medicine"
					class="pa-3">
					<template v-slot:icon>
						<img
							src="@Assets/icons/medicine_dark.svg"
							alt="Ícone do medicamento"
							width="25"/>
					</template>
					Medicamento
				</FarmeCustomInputRadio>
			</v-flex>
			<v-flex xs6>
				<FarmeCustomInputRadio
					v-model="form.type"
					name="type"
					:disabled="disabled"
					id="type-product"
					value="product"
					class="pa-3">
					<template v-slot:icon>
						<img
							src="@Assets/icons/product_dark.svg"
							alt="Ícone do produto"
							width="25"/>
					</template>
					Produto
				</FarmeCustomInputRadio>
			</v-flex>
		</v-layout>
	</section>
</template>

<script>
import FarmeCustomInputRadio from '@Componentes/farme-custom-input-radio.vue';

export default {
	name: 'NewProductStepItemType',
	inject: ['$validator'],
	components: {
		FarmeCustomInputRadio
	},
	props: {
		disabled: {
			required: false,
			type: Boolean,
			default: false
		},
		form: {
			required: true,
			type: Object
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
