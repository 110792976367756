<template>
	<v-flex v-if="ehOrcamento" xs12>
		<v-textarea v-model="assinatura.observacao" label="Observações da assinatura" box disabled hide-details />
	</v-flex>
</template>

<script>
export default {
	name: 'ObservacoesAssinatura',
	props: {
		assinatura: {
			type: Object,
			required: true
		}
	},
	computed: {
		ehOrcamento() {
			return this.$route.name === 'Orcamentos' || this.$route.name === 'orcamentosB2C';
		}
	}
};
</script>
