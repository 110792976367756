<template>
	<v-container grid-list-xl class="pa-0">
		<v-layout align-center>
			<v-flex xs1 >
				<template>
					<img src="@Assets/icons/fora_box_backgrounded.svg" alt="Medicamento" width="52" class="pl-1">
				</template>
			</v-flex>
			<v-flex xs11 class="pt-1">
				<p class="mb-0">
					<span v-if="item.medicamento && item.medicamento.produto" >
						<strong>{{ item.medicamento.id }} - {{ item.medicamento.produto }}</strong>
					</span>
				</p>
				<span v-if="item.medicamento && item.medicamento.principio_ativo" class="mb-0">
					{{ `${item.medicamento.principio_ativo} (${(item.medicamento.laboratorio || '').toUpperCase()})`  }}
				</span>
				<span v-if="item.medicamento && item.medicamento.apresentacao" class="mb-0">
					{{ item.medicamento.dosagem }} {{ item.medicamento.apresentacao }}
				</span>

				<v-flex v-if="item.medicamento.produto_referencia || item.medicamento.portaria_344_98">
					<v-tooltip right v-if="item.medicamento.produto_referencia">
						<v-icon slot="activator" color="primary" class="mx-2">info</v-icon>
						<span>O {{ item.medicamento.produto }} é um produto {{
							item.medicamento.descricao_tipo_produto }} ao {{
							item.medicamento.produto_referencia
						}}</span>
					</v-tooltip>

					<v-tooltip right v-if="item.medicamento.portaria_344_98">
						<v-icon slot="activator" color="warning" class="mx-2">warning</v-icon>
						<span>Este medicamento necessita da retenção da prescrição médica.</span>
					</v-tooltip>
				</v-flex>
			</v-flex>
		</v-layout>
		<v-layout wrap>
			<v-flex xs12 md3 :class="$vuetify.breakpoint.smAndDown ? 'order-xs2' : null">
				<p style="font-size: 14px">Nº de cp</p>
				<v-text-field
					v-model.number="item.qtd_comprimidos"
					box
					type="number"
					min="0"
					v-validate="{ required: isRequired(), max_value: item.medicamento.qtd_embalagem }"
					class="pt-2"
					label="Quantidade"
					:data-vv-name="`quantidade_${item.medicamento_id}`"
					:error-messages="errors.collect(`quantidade_${item.medicamento_id}`)"/>
			</v-flex>
			<v-flex xs12 md3 :class="$vuetify.breakpoint.smAndDown ? 'order-xs2' : null">
				<p style="font-size: 14px">Lote</p>
				<v-text-field
					v-model="item.lote"
					:error-messages="errors.collect(`lote_${item.medicamento_id}`)"
					:data-vv-name="`lote_${item.medicamento_id}`"
					label="Lote"
					v-validate="{ required: isRequired() }"
					box
					type="text"
					class="pt-2" />
			</v-flex>
			<v-flex xs12 md3 :class="$vuetify.breakpoint.smAndDown ? 'order-xs2' : null">
				<p style="font-size: 14px">Validade</p>
				<TextDateField
					v-model="item.validade"
					:data-vv-name="`validade_${item.medicamento_id}`"
					:error-messages="errors.collect(`validade_${item.medicamento_id}`)"
					label="Validade"
					v-validate="{ required: isRequired() }"
					box
					placeholder="dd/mm/yyyy"
					class="pt-2"/>
			</v-flex>
			<v-flex xs12 md1 :class="$vuetify.breakpoint.smAndDown ? 'order-xs2' : null">
				<p style="font-size: 14px">Caixas</p>
				<p class="mb-0 bordered">
					<span class="numero-estoque" :class="`${!!item.estoque.qtd_caixas ? '' : 'in'}disponivel`">
						{{ item.estoque.qtd_caixas.toFixed(2) }}
					</span>
				</p>
			</v-flex>
			<v-flex xs12 md2 :class="$vuetify.breakpoint.smAndDown ? 'order-xs2' : null">
				<p style="font-size: 14px">Comprimidos:</p>
				<p class="mb-0 bordered">
					<span class="numero-estoque" :class="`${!!item.estoque.qtd_comprimidos ? '' : 'in'}disponivel`">
						{{ item.estoque.qtd_comprimidos }}
					</span>
				</p>
			</v-flex>
			<v-flex xs12 md2 v-show="!item.medicamento">
				<h3 class="mb-2" style="font-size: 14px">Nome do medicamento ou princípio ativo</h3>
				<v-autocomplete box clearable return-object v-model="item.medicamento" :items="medicamentos"
					:loading="carregando_medicamentos" :search-input.sync="search_medicamento" :filter="() => (true)"
					color="green" label="Escolha o medicamento" placeholder="Nome ou princípio ativo do medicamento"
					:no-data-text="no_data_text" @input="med => $emit('medicamento-selecionado', item)">
					<template slot="item" slot-scope="data">
						<v-list-tile-content>
							<v-list-tile-title v-html="`${data.item.id} - ${data.item.produto} ${data.item.dosagem}`" />
							<v-list-tile-sub-title v-html="`${data.item.principio_ativo} - ${data.item.apresentacao}`" />
						</v-list-tile-content>
					</template>
				</v-autocomplete>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { MedicamentoPresenter } from '@Presenter/medicamento-presenter';
import { debounce } from 'lodash';

export default {
	name: 'Medicamento',
	inject: ['$validator'],
	props: {
		item: {
			type: Object,
			required: true
		},
		scope: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		medicamentos: [],
		carregando_medicamentos: false,
		search_medicamento: ''
	}),
	watch: {
		search_medicamento(search) {
			this.$nextTick(() => {
				if (this.search_medicamento && this.search_medicamento.length > 1) {
					this.carregando_medicamentos = true;
					this.chamaGetMedicamentos(search || '');
				} else
					this.medicamentos = [];
			});
		}
	},
	methods: {
		chamaGetMedicamentos: debounce(function (search) {
			this.getMedicamentos(search);
		}, 500),
		getMedicamentos() {
			MedicamentoPresenter.search({ search: this.search_medicamento, rowsPerPage: 15 })
				.then(response => {
					this.medicamentos = response.items;
				})
				.finally(() => {
					this.carregando_medicamentos = false;
				});
		},
		isRequired() {
			return this.item.validade !== null
				|| this.item.lote !== null
					|| this.item.qtd_comprimidos !== null;
		}
	},
	computed: {
		no_data_text() {
			if (this.carregando_medicamentos)
				return 'Pesquisando...';
			return this.search_medicamento && this.search_medicamento.length > 1 ? 'Nenhum medicamento encontrado' : 'Digite o nome do medicamento para buscar';
		}
	}
};
</script>

<style lang="scss" scoped>
  .numero-estoque {
    font-weight: bold;
    &.disponivel {
      color: green;
    }
    &.indisponivel {
      color: red;
    }
  }
  .bordered{
	width: 90%;
	margin-top: 26px;
	height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
	border: solid 2px #C7C7C7;
	padding: 10px;
  }
</style>
