import Vue from 'vue';
import * as functions from '@Util/functions';
import lodash from 'lodash';
import { permissoes } from '@Consts/permissoes';
import http from '@Config/axios';

Vue.prototype.$http = http;
Vue.prototype.$lodash = lodash;
Vue.prototype.$functions = functions;
Vue.prototype.$permissoes = permissoes;
