<template>
	<section>
		<DialogRestHome
			ref="rest-home-dialog"
			title="Editar ILPI"
			:rest-home="restHome"
			@submited="handle"
			@close="() => $emit('close')" />

		<v-dialog
			persistent
			scrollable
			v-model="confirmUpdateRestHomeDialog.opened"
			transition="fade"
			max-width="600px">
			<transition>
				<Dialog
					titulo="Atenção"
					btn_acao="Confirmar"
					btn_acao_class="primary"
					btn_cancelar="Cancelar"
					:btn_acao_disable="!confirmUpdateRestHomeDialog.confirmed"
					@acao="updateRestHome"
					@close="confirmUpdateRestHomeDialog.opened = false">

					<div class="" style="max-width: 480px; margin: 0 auto;">
						<h2 class="mb-4">Ao prosseguir com a edição dos dados, atente-se para:</h2>
						<p>
							Se o endereço da assinatura estiver ligado ao endereço da casa e for editado, todos os endereços das assinaturas associadas serão modificados simultaneamente.
						</p>
						<v-checkbox
							primary
							hide-details
							:input-value="confirmUpdateRestHomeDialog.confirmed"
							@click.stop="confirmUpdateRestHomeDialog.confirmed = !confirmUpdateRestHomeDialog.confirmed"
							label="Declaro estar ciente dos possíveis efeitos ao prosseguir com a edição dos dados."/>
					</div>
				</Dialog>
			</transition>
		</v-dialog>
	</section>
</template>

<script>
import DialogRestHome from './rest-home-dialog';

export default {
	name: 'UpdateRestHomeDialog',
	components: { DialogRestHome },
	props: {
		restHome: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			data: {},
			confirmUpdateRestHomeDialog: {
				opened: false,
				confirmed: false
			}
		};
	},
	methods: {
		handle(data) {
			this.data = data;
			this.confirmUpdateRestHomeDialog.opened = true;
		},
		updateRestHome() {
			this.$emit('update-rest-home', this.data);
			this.confirmUpdateRestHomeDialog.opened = false;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
