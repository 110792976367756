export const daysOfWeek = {
	SUN: 'Domingo',
	MON: 'Segunda',
	TUE: 'Terça',
	WED: 'Quarta',
	THU: 'Quinta',
	FRI: 'Sexta',
	SAT: 'Sábado'
};

export default Object.values(daysOfWeek);
