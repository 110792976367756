<template>
	<v-flex xs3 class="button pointer" :class="{ 'pointer': !!button.onClick }" @click="execute">
		<div class="q-row justify-center items-center fit">
			<v-progress-circular v-if="loading" indeterminate color="grey" />
			<v-icon v-else class="col-12">{{ button.icon }}</v-icon>
			<span class="col-12 text-center">{{ button.label }}</span>
		</div>
	</v-flex>
</template>

<script>
export default {
	name: 'Button',
	props: {
		button: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		loading: false
	}),
	methods: {
		async execute() {
			if (!this.button.onClick)
				return;
			this.loading = true;
			await this.button.onClick();
			this.loading = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.button {
	min-height: 100px;
	max-height: 100px;
	padding: 0 10px;
	padding: 4px;

	&>div {
		border: 1px solid darkgrey;
		border-radius: 8px;

		transition: box-shadow .3s;

		&:hover {
			box-shadow: 0 0 11px rgba(33,33,33,.2);
		}
	}
}
</style>
