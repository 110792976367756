<template>
	<div class="pt-2">
		<v-layout class="text-center" align-center row wrap>
			<v-flex xs12 md12 class="pa-1">
				<v-card class="card-style pa-md-2" color="#EDEDED">
					<v-card-text v-if="!image">
						<v-icon :loading="loading" x-large>no_photography</v-icon>
						<p class="pt-2 poppins">Sem imagem</p>
					</v-card-text>
					<div v-else>
						<v-img
							:src="image"
							class="grey lighten-2">
							<template v-slot:placeholder>
								<v-layout
									fill-height
									align-center
									justify-center
									ma-0>
									<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
								</v-layout>
							</template>
						</v-img>
					</div>
				</v-card>
			</v-flex>
		</v-layout>

		<v-layout v-if="mostrarMsg" class="report-style pa-2" d-flex row align-center
			justify-center>
			<v-flex class="text-center" xs12 md3>
				<v-icon color="error">report</v-icon>
			</v-flex>
			<v-flex class="poppins" xs12 md9>
				Este {{ isOrder ? 'produto' : 'medicamento' }} não possui foto(s) cadastradas. Para seguir com o processo de picking, as imagens deverão ser incluídas no sistema: <a href="https://images.farme.com.br/" target="_blank">images.farme</a>
			</v-flex>
		</v-layout>

	</div>
</template>

<script>

export default {
	name: 'Images',
	props: {
		image: {
			required: true
		},
		mostrarMsg: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		isOrder: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		goToImagesFarme() {
			window.location.href('https://images.farme.com.br/');
		}
	}
};
</script>

<style lang="scss" scoped>
.card-style {
	border-radius: 10px;
}

.report-style {
	border: 1px solid #EDEDED;
	border-radius: 8px;
}
</style>
