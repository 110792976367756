<template>
	<Dialog
		titulo="Confirmação de estorno"
		:btn_acao="stepper < 2 ? 'Próximo' : 'Confirmar'"
		btn_acao_extra_2="Voltar"
		:loading="loading"
		:btn_acao_disable="ruleNextStep"
		:btn_acao_extra_2_disable="stepper === 1"
		@acao_extra_2="stepper--"
		@acao="action"
		@close="close">
		<v-stepper v-model="stepper">
			<v-stepper-header class="mb-3">
				<v-stepper-step :complete="stepper > 1" step="1">
					Inserção de valores
				</v-stepper-step>
				<v-divider />
				<v-stepper-step step="2">
					Confirmação
				</v-stepper-step>
			</v-stepper-header>
			<v-stepper-items>
				<v-stepper-content step="1">
					<div class="d-flex q-column">
						<h1 class="roboto text-center mb-3" style="font-size: 20px;font-weight: 400;">Estorno para fatura <span style="font-weight: bold;">{{ invoiceId }}</span> de valor 	<span style="font-weight: bold;">{{ invoiceTotalAmount | dinheiro }}</span>
						</h1>
						<div class="d-flex q-column items-start">
							<h2 v-if="refunds.length" class="roboto" style="font-size: 18px;font-weight: 400;">Valores já estornados: <span class="text-bold" v-for="(item, index) in refunds" :key="index">{{ refundsText(item.amount, index) }}</span></h2>
							<h3 class="roboto" style="font-size: 18px;font-weight: 400;">Valor disponível para estorno: <span class="text-bold">{{ this.invoiceTotalAmount - this.totalValueToRefund | dinheiro }}</span></h3>
						</div>
						<div class="d-flex items-center justify-space-between full-width my-3">
							<h4 class="roboto" style="font-size: 18px;">Confirme o valor para estorno: </h4>
							<v-text-field
								box
								v-model="valueRefund"
								:rules="refundRules"
								label="Valor para estorno"
								v-money
								color="green" />
						</div>
						<div v-if="itsStatusProtested">
							<h4 class="red--text">* Faturas em protesto só é liberado o estorno total.</h4>
						</div>
					</div>
				</v-stepper-content>
				<v-stepper-content step="2">
					<div class="d-flex q-column items-center justify-center text-center">
						<h2 class="roboto mb-2" style="font-size: 18px; font-weight: 400;">
							<span style="font-weight: bold;">Atenção!</span> Você está prestes a estornar o valor <span style="font-weight: bold;">{{ this.isTotalRefund ? 'TOTAL' : this.valueRefund }}</span> pago da fatura <span style="font-weight: bold;">{{ invoiceId }}</span>. <br><br>
						</h2>
						<h3 class="roboto" style="font-size: 18px; font-weight: bold;">Certifique-se de que deseja prosseguir com esta ação, pois ela não poderá ser desfeita.</h3>
					</div>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</Dialog>
</template>

<script>
import { InvoicesPresenter } from '@Presenter/invoices-presenter';
import { mascaraDinheiro, onlyNumber } from '@Util/functions';

export default {
	name: 'DialogRefund',
	props: {
		invoiceId: {
			type: Number,
			required: true
		},
		invoiceTotalAmount: {
			type: Number,
			required: true
		},
		refunds: {
			type: Array,
			required: false
		},
		status: {
			type: String,
			required: true
		}
	},
	data: () => ({
		loading: false,
		valueRefund: 0,
		stepper: 1
	}),
	methods: {
		action() {
			if (this.stepper < 2) {
				if (this.itsStatusProtested && !this.isTotalRefund)
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Para o status em protesto, só é liberado o estorno total', color: 'warning' });
				else
					this.stepper++;
			} else {
				this.loading = true;

				const amount = this.isTotalRefund ? this.invoiceTotalAmount : Number(onlyNumber(this.valueRefund));
				InvoicesPresenter.generateRefunds(this.invoiceId, amount)
					.then(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Estorno da fatura efetuado', color: 'success' });
					})
					.catch(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao efetuar estorno da fatura', color: 'error' });
					})
					.finally(() => {
						this.loading = false;
						this.close();
					});
			}
		},
		close() {
			this.$emit('close');
		},
		refundsText(amount, index) {
			return `${mascaraDinheiro(amount)}${index === this.refunds.length - 1 ? '' : ' | '}`;
		}
	},
	computed: {
		isTotalRefund() {
			return Number(onlyNumber(this.valueRefund)) === this.invoiceTotalAmount;
		},
		ruleNextStep() {
			return !(Number(onlyNumber(this.valueRefund)) > 0 && Number(onlyNumber(this.valueRefund)) <= this.invoiceTotalAmount - this.totalValueToRefund);
		},
		refundRules() {
			return [(Number(onlyNumber(this.valueRefund)) >= 0 && Number(onlyNumber(this.valueRefund)) <= (this.invoiceTotalAmount - this.totalValueToRefund)) || 'Valor deve ser maior que zero e menor que o valor total da fatura e menos ou igual ao valor disponível para estorno'];
		},
		totalValueToRefund() {
			const initialValue = 0;
			const totalValueToRefund = this.refunds.reduce(
				(acc, item) => acc + item.amount,
				initialValue
			);

			return totalValueToRefund;
		},
		itsStatusProtested() {
			return this.status === 'PROTESTED';
		}
	}
};

</script>
