<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>
					fas fa-bomb
				</v-icon>
			</v-avatar>
			<h3>Ocorrências</h3>
		</v-card-title>
		<v-card-text>
			<v-layout wrap>
				<v-flex xs12 md6 v-for="(ocorrencia, i) in ocorrencias" :key="i">
					<h4 class="h4 text--text">{{ ocorrencia.status }}</h4>
					<h3 class="teal--text text--darken-2">{{ ocorrencia.user.nome }}</h3>
					<p class="mb-0 grey--text text--darken-2">
						<v-icon small style="margin-right: 10px">far fa-calendar-alt</v-icon>
						<span>
							{{ ocorrencia.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}
						</span>
					</p>
					<p v-if="ocorrencia.ocorrencia" class="mb-0 grey--text text--darken-2">
						<v-icon small style="margin-right: 10px">fas fa-bomb</v-icon>
						<span>
							{{ ocorrencia.ocorrencia }}
						</span>
					</p>
					<p v-if="ocorrencia.justificativa" class="mb-0 grey--text text--darken-2">
						<v-icon small style="margin-right: 10px">fas fa-question</v-icon>
						<span>
							{{ ocorrencia.justificativa }}
						</span>
					</p>
					<p v-if="ocorrencia.solucao" class="mb-0 grey--text text--darken-2">
						<v-icon small style="margin-right: 10px">fas fa-lightbulb</v-icon>
						<span>
							{{ ocorrencia.solucao }}
						</span>
					</p>
				</v-flex>
			</v-layout>
		</v-card-text>
	</v-card>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';

export default {
	name: 'Ocorrencias',
	props: {
		box_id: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		loading: true,
		ocorrencias: []
	}),
	async created() {
		this.ocorrencias = await BoxPresenter.ocorrencias(this.box_id);
		this.loading = false;
	}
};
</script>

<style lang="scss" scoped>
.h4 {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	margin-bottom: 0;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 12px;
}
</style>
