<template>
	<header>
		<v-layout wrap class="mb-4">
			<v-flex xs12 md4>
				<h1 class="text--text" style="opacity: .8">Pessoas</h1>
				<p>Utilize a busca aos lado para encontrar a pessoa.</p>
				<v-btn v-if="!eh_clinica" depressed class="primary mx-0 pl-2 pr-3" @click="$emit('novo-paciente')">
					<v-icon small class="mr-2">add</v-icon>
					Adicionar pessoa
				</v-btn>
			</v-flex>
			<v-flex xs12 md8>
				<v-layout wrap row>
					<v-flex md6 v-if="eh_clinica"></v-flex>
					<v-flex md6>
						<v-text-field label="Buscar pessoa" placeholder="Nome, e-mail ou CPF da pessoa"
							append-icon="search" color="purple" @input="value => $emit('busca', value)" />
					</v-flex>
					<template v-if="!eh_clinica">
						<v-flex md3>
							<v-select append-icon="filter_list" color="purple" v-model="filtro" label="Filtros"
								:items="filtros" item-text="nome" item-value="status" @input="value => $emit('filtro', value)" />
						</v-flex>
						<v-flex md3>
							<v-autocomplete append-icon="filter_list" color="purple" v-model="casa_repouso" label="Casa de repouso"
								no-data-text="Nenhum resultado" :items="casas_repouso" item-text="nome" item-value="id"
								placeholder="Digite..."
								@input="value => $emit('casa-repouso', value)" clearable />
						</v-flex>
					</template>
				</v-layout>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';

export default {
	name: 'PacientesHeader',
	props: {
		carregando: {
			type: Boolean,
			required: true
		}
	},
	data: () => ({
		filtro: 0,
		filtros: [
			{ status: 0, nome: 'Pessoas ativas' },
			{ status: 1, nome: 'Pessoas inativas' }
		],
		casas_repouso: [],
		casa_repouso: {}
	}),
	computed: {
		eh_clinica() {
			return this.$route.name.includes('Clinica');
		}
	},
	created() {
		CasaRepousoPresenter.index({ campos: ['id', 'nome'] })
			.then(response => {
				this.casas_repouso = response;
			});
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

::v-deep .v-toolbar {
  background: #fff;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.05);

  .v-toolbar__content {
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto !important;
    padding: 16px;

    @media screen and (min-width: $break-point-md) {
      padding: 16px 24px;
    }

    .badge {
      transform: translateY(-2px);
    }

    .navbar-buttons {
      display: flex;
      flex-wrap: wrap;
      min-height: 100%;
      align-items: center;
    }
  }
}
</style>
