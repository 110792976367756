import { posologyTypes } from '@Consts/posologia';

const getQuantidade = qnt => {
	if (!qnt)
		return '';
	switch (qnt) {
		case 0.5: return 'Meio comprimido';
		case 1: return '1 comprimido';
		case 1.5: return '1 comprimido e meio';
		case 2.5: return '2 comprimidos e meio';
		case 3.5: return '3 comprimidos e meio';
		case 4.5: return '4 comprimidos e meio';
		case 5.5: return '5 comprimidos e meio';
		case 6.5: return '6 comprimidos e meio';
		case 7.5: return '7 comprimidos e meio';
		case 0.333: return '1/3 de comprimido';
		case 0.666: return '2/3 de comprimido';
		case 0.75: return '3/4 de comprimido';
		case 0.25: return '1/4 de comprimido';
		default: return `${qnt} comprimidos`;
	}
};

const getHora = hora => {
	if (/[0-9]/.test(hora))
		return `às ${hora}`;

	return hora.toLowerCase();
};

export function getPeriodoDeUsoFormatado(medicamento) {
	const posologia = JSON.parse(medicamento.pivot.posologia);

	if (medicamento.liquido) {
		const qtd_caixas = medicamento.pivot.qtd_caixas;
		return `${qtd_caixas} caixa${qtd_caixas > 1 ? 's' : ''}`;
	}

	let descricaoPeriodo;
	if (posologia.periodo_de_uso === posologyTypes.allDays)
		descricaoPeriodo = '"Todos os dias"';
	if (posologia.periodo_de_uso === posologyTypes.dayYesDayNot || posologia.periodo_de_uso === posologyTypes.dayNotDayYes)
		descricaoPeriodo = '"Dias sim, dia não"';
	if (posologia.periodo_de_uso === posologyTypes.weekly)
		descricaoPeriodo = `"Dias da semana: "${posologia.dias_da_semana.join(',')}`;
	if (posologia.periodo_de_uso === posologyTypes.monthly)
		descricaoPeriodo = `"Dias do mês:" ${posologia.dias_do_mes.join(',')}`;
	if (posologia.periodo_de_uso === posologyTypes.other)
		return `${posologia.outro_periodo.tipo} ${posologia.outro_periodo.dia} do mês`;

	return `${descricaoPeriodo}: ${posologia.horarios.map(h => `${getQuantidade(h.quantidade_por_horario)} ${getHora(h.horario)}`).join(',')}`;
}

export function getFormattedPosology(product) {
	const posology = product.posology;

	if (!product.isDivisible) {
		const closedPackages = product.closedPackages;
		return `${closedPackages} caixa${closedPackages > 1 ? 's' : ''}`;
	}

	let posologyDescription;

	switch (posology.frequency) {
		case posologyTypes.allDays:
			posologyDescription = '"Todos os dias"'; break;
		case posologyTypes.dayYesDayNot:
		case posologyTypes.dayNotDayYes:
			posologyDescription = '"Dias sim, dia não"'; break;
		case posologyTypes.weekly:
			posologyDescription = `"Dias da semana: "${posology.daysOfWeek.join(',')}`; break;
		case posologyTypes.monthly:
			posologyDescription = `"Dias do mês:" ${posology.daysOfMonth.join(',')}`; break;
		default: break;
	}

	return `${posologyDescription}: ${posology.schedules.map(h => `${getQuantidade(h.pills)} ${getHora(h.hour)}`).join(',')}`;
}
