<template>
	<section class="options">
		<QrCodeChoice @search="search" class="option" />
		<span v-if="!$vuetify.breakpoint.mdAndUp" class="divisor"></span>
		<template v-else>
			Ou
		</template>
		<InputSearchChoice @search="search" class="option" />
	</section>
</template>

<script>
import QrCodeChoice from './qrcode-reader.vue';
import InputSearchChoice from './input-search.vue';

export default {
	components: {
		QrCodeChoice,
		InputSearchChoice
	},
	methods: {
		search(value) {
			this.$emit('search', value);
		}
	}
};
</script>

<style lang="scss" scoped>
section .options {
	display: flex;
	flex-direction: row;
	height: 100% !important;
	align-items: center;
	gap: 30px;
	min-height: 440px;

	@media (max-width: 960px)  {
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		gap: 0;
	}
}

.option {
	height: 100%;
	min-height: 440px;
	@media (max-width: 960px)  {
		min-height: 0;
	}
}

.divisor {
	width: 100%;
	border: 3px  dotted #342B1D80
}
</style>
