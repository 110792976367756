<template>
	<v-card color="#F4EEE5">
		<v-card-title class="pb-0">
			<v-layout wrap>
				<v-flex style="display: flex; flex-direction: row; align-items: center">
					<v-avatar size="38" class="mr-3">
						<v-icon color="#342B1D" small>fas fa-truck</v-icon>
					</v-avatar>
					<h4>Endereço</h4>
				</v-flex>
			</v-layout>
		</v-card-title>
		<v-card-text style="padding: 32px">
			<v-layout wrap>
				<v-flex class="address">
					<p class="mb-0">
						{{ street }}, {{ number }}
					</p>
					<p class="mb-0">
						<span v-if="complement">
							{{ complement }},
						</span>
						Bairro {{ neighborhood }}
					</p>
					<p class="mb-0">{{ city }}, {{ state }}</p>
					<p class="mb-0"> CEP: {{ zipCode }}</p>
				</v-flex>
			</v-layout>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'AddressInfo',
	props: {
		street: { type: String },
		number: { type: String },
		complement: { type: String },
		neighborhood: { type: String },
		city: { type: String },
		state: { type: String },
		zipCode: { type: String }
	}
};
</script>
