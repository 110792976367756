<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>
					fas fa-user
				</v-icon>
			</v-avatar>
			<h3>Pendencias</h3>
		</v-card-title>
		<v-card-text>
			<v-container fluid grid-list-lg>
				<ListaPendencias :box_id="this.box_id" :item="this.item" :readOnly="readOnly"></ListaPendencias>
			</v-container>
		</v-card-text>
	</v-card>
</template>

<script>
import ListaPendencias from '@Componentes/dialog-pendencias/lista-pendencia';

export default {
	name: 'Pendencias',
	components: { ListaPendencias },
	props: {
		item: {
			type: Object,
			required: true
		},
		box_id: {
			type: Number,
			required: true
		},
		readOnly: {
			type: Boolean,
			required: false
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	padding: 0;
}

.v-card {
  ::v-deep .v-card {
    box-shadow: none !important;
  }
}

.font-style-name {
	font-family: 'Open Sans', sans-serif;
	font-size: 22px;
	font-weight: 600;
}

.font-style-cpf {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: 400;
}
</style>
