<template>
	<div class="flex-box">
		<img :src="logo" width="140px" />
		<div class="my-5">
			<h2 class="text--text">Recuperar senha</h2>
			<p class="titulo mb-5 pb-3">Preencha abaixo a sua nova senha.</p>

			<v-text-field v-model="password" box color="green_strong" label="Nova senha *" placeholder="********"
				@keyup.enter="trocarSenha" v-validate="{ required: true, min: 10, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{10,}$/ }" data-vv-name="nova senha"
				:error-messages="errors.collect('nova senha')" type="password" ref="nova senha" />

			<v-text-field v-model="confirmacao" type="password" box color="green_strong" label="Confirmar nova senha"
				@keyup.enter="trocarSenha" placeholder="********" v-validate="{ min: 10, required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{10,}$/, confirmed: 'nova senha' }"
				data-vv-name="confirmar senha" :error-messages="errors.collect('confirmar senha')" />

			<v-btn depressed :loading="carregando" :icon="carregando" @click.stop="trocarSenha" :block="!carregando && $vuetify.breakpoint.xsOnly"
				class="ml-0 green_strong white--text" :style="carregando ? null : 'padding: 0 64px'">
				Trocar senha
			</v-btn>
		</div>
		<footer class="title">
			<a @click="irParaLogin">Voltar para o login</a>
		</footer>
	</div>
</template>

<script>
import { UserPresenter } from '@Presenter/user-presenter';
import logo from '@Assets/images/logo.png';

export default {
	name: 'RecuperarSenha',
	$_veeValidate: { validator: 'new' },
	data: () => ({
		esconde_nova_senha: true,
		esconde_confirmar_senha: true,
		password: '',
		confirmacao: '',
		carregando: false,
		logo
	}),
	methods: {
		irParaLogin() {
			this.$router.push({ name: 'Entrar', params: { stop: true } });
		},
		trocarSenha() {
			this.$validator.validateAll().then(valido => {
				if (!valido) {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
					return;
				}

				this.carregando = true;
				const data = {
					email: this.$route.params.email,
					token: this.$route.params.token,
					password: this.password
				};
				UserPresenter.redefineSenha(data)
					.then(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Senha redefinida com sucesso!' });
						this.irParaLogin();
					})
					.finally(() => {
						this.carregando = false;
					});
			});
		}
	}
};
</script>
