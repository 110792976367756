<template>
	<div class="px-md-5">
		<Header @nova-configuracao="crud" @filtro="onFiltro" />

		<v-card>
			<v-data-table :headers="headers" :items="filteredItems" :loading="is_loading" :search="busca"
				:no-data-text="(is_loading) ? 'Carregando...' : (request_error) ? 'Erro ao carregar, tente novamente' : 'Nenhuma configuração'"
				no-results-text="Nenhum resultado encontrado" :rows-per-page-items="[15, 30, 45, {text: 'Todos', value: -1}]"
				rows-per-page-text="Itens por página">
				<template slot="items" slot-scope="props">
					<tr class="pointer" @click="crud(props.item)">
						<td>{{ daysOfWeek[props.item.date] ? daysOfWeek[props.item.date] : props.item.date }}</td>
						<td>{{ departmentsMap[props.item.department] }}</td>
						<td>{{ companyUnits[props.item.companyUnit] }}</td>
						<td>{{ props.item.reason }}</td>
						<td>
							<v-chip small class="white--text" :class="`${props.item.workDay ? 'success' : 'error'}`">
								{{ `Dia ${props.item.workDay ? '' : 'não-'}útil` }}
							</v-chip>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog persistent scrollable v-model="dialog_crud" transition="fade" max-width="450px">
			<transition>
				<DialogConfiguracao v-if="dialog_crud" :item_pai="item_selecionado" :close.sync="dialog_crud"
					@inserir="salvo" @excluir="excluido" />
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { ListagemMixin } from '@Mixins/listagem';
import { CalendarioMixin } from '@Mixins/calendario';
import Header from './header';
import DialogConfiguracao from './dialog-configuracao';

export default {
	name: 'ConfiguracoesCalendario',
	components: { Header, DialogConfiguracao },
	mixins: [ListagemMixin, CalendarioMixin],
	data: () => ({
		filtro: null,
		headers: [
			{ text: 'Data', value: 'date' },
			{ text: 'Departamento', value: 'department' },
			{ text: 'Unidade', value: 'companyUnit' },
			{ text: 'Motivo', value: 'reason' },
			{ text: '', value: '' }
		]
	}),
	computed: {
		filteredItems() {
			if (this.filtro === 'ANY')
				return this.items;
			return this.items.filter(item => item.companyUnit === this.filtro);
		}
	},
	methods: {
		onFiltro(filtro) {
			this.filtro = filtro;
		},
		onResponse(response) {
			this.items = response.map(this.formatResponse);
		},
		formatResponse(response) {
			return {
				...response,
				date: response.dayOfWeek || this.convertDate(response)
			};
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
