<template>
	<Dialog titulo="Inserir código de rastreio" :subtitulo="subtitulo"
		btn_acao="Salvar" :loading="saving"
		@acao="salvar" @close="close">
		<div class="loading" style="min-height: 84px" v-if="carregando">
			<v-progress-circular indeterminate color="primary"/>
		</div>
		<v-container v-else fluid grid-list-lg class="pa-0">
			<v-layout wrap>
				<v-flex xs12>
					<v-text-field v-model="box.codigo_rastreio" label="Código de rastreio" placeholder="Deixe em branco caso não tenha" box/>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';

export default {
	name: 'DialogCodigoRastreio',
	props: {
		box_id: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		box: null,
		saving: false,
		carregando: true
	}),
	computed: {
		subtitulo() {
			if (!this.box)
				return 'Carregando...';
			return `Box: ${this.box.id} - ${this.box.assinatura.paciente.nome}`;
		}
	},
	created() {
		BoxPresenter.show(this.box_id)
			.then(box => {
				this.box = box;
			})
			.finally(() => {
				this.carregando = false;
			});
	},
	methods: {
		salvar() {
			this.$emit('salvarCodigoRastreio', this.box.codigo_rastreio);
			this.close();
		},
		close() {
			this.$emit('update:close', false);
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
