import { UnleashClient, LocalStorageProvider } from 'unleash-proxy-client';
import { envs, localStorageEnvKey } from '@Config/environments';
import { getCurrentCompanyUnit } from '@Config/unidades';

const PREFIX = 'unleash';
const env = localStorage.getItem(localStorageEnvKey);

let clientKey = '';
let environment = '';
switch (env) {
	case envs.Prod:
		environment = 'production';
		clientKey = 'default:production.8b78127ff07a80959b53b5230296bfd8a77954470ad74dfad2cecfc2';
		break;
	case envs.Staging:
		environment = 'staging';
		clientKey = 'default:staging.1ff1f44414f0195afd52fad00feb73015e09ad5897884e3076067340';
		break;
	default:
		environment = 'development';
		clientKey = 'default:development.4cc963805fe2e53636f2e5e072ef1e17c16f5adb5c83e1516ecd6558';
		break;
}
export const unleash_config = {
	url: 'https://unleash.farme.com.br/api/frontend',
	clientKey,
	appName: 'farmebox',
	environment,
	storageProvider: new LocalStorageProvider(PREFIX)
};
const unleash = new UnleashClient(unleash_config);
const companyUnit = getCurrentCompanyUnit();
unleash.setContextField('companyUnitName', companyUnit?.key);
// Start the background polling
export const initializeUnleash = async () => {
	await unleash.start();
	console.log('Unleash client started');
};

export default unleash;
