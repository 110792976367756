<template>
	<div class="ma-3">
		<v-card width="100%" class="card-style pa-3">
			<v-card-title>
				<v-container fluid class="pa-0">
					<v-layout justify-center row>
						<img  :src="logo" width="140px" />
					</v-layout>
				</v-container>
			</v-card-title>
			<v-card-actions>
				<slot/>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import logo from '@Assets/images/FarmeOS.svg';

export default {
	name: 'cardLogin',
	data: () => ({
		logo
	})
};
</script>

<style scoped>
	.card-style{
		border-radius: 20px !important;
		-webkit-box-shadow: 5px 6px 10px -7px #000000;
		box-shadow: 5px 6px 10px -7px #000000;
	}

</style>
