import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = '/medicamentos';

export const DrugPresenter = {
	list: params => $http.get(buildQueryParams(prefix, {
		q: params.q,
		page: params.page,
		perPage: params.perPage,
		type: params.type,
		sortBy: params.sortBy,
		sortDirection: params.sortDirection
	}))
};
