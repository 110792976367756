<template>
	<Dialog titulo="Inserir código de rastreio" :subtitulo="subtitulo"
		btn_acao="Salvar" :loading="saving" :btn_acao_disable="disableBtnByProccess"
		@acao="save" @close="close">

		<div class="loading" style="min-height: 84px" v-if="loading">
			<v-progress-circular indeterminate color="primary"/>
		</div>
		<v-container v-else fluid grid-list-lg class="pa-0">
			<v-layout wrap>
				<v-flex xs12>
					<v-text-field label="Código de rastreio" placeholder="Deixe em branco caso não tenha" box
						@input="value =>  setMetadata(value)"/>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { validateProcessIsAllowed } from '@Util/order-process';
import { ProcessTypes } from '@Consts/status-order-process';

export default {
	name: 'DialogCodigoRastreio',
	props: {
		order_id: {
			type: Number,
			required: true
		},
		customer_name: {
			type: String,
			required: true
		},
		request: {
			type: Function,
			required: true
		}
	},
	data: () => ({
		saving: false,
		loading: false,
		disableBtnByProccess: true
	}),
	computed: {
		subtitulo() {
			return `Pedido: ${this.order_id} - ${this.customer_name}`;
		}
	},
	created() {
		this.getProcessByOrderAndProcessType(this.order_id);
	},
	methods: {
		save() {
			this.loading = true;
			validateProcessIsAllowed(this.order_id, ProcessTypes.SHIPPING)
				.then(isValid => {
					if (isValid) {
						this.request()
							.then(() => {})
							.finally(() => {
								this.close();
							});
					} else
						this.disableBtnByProccess = !isValid;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:close', false);
		},
		setMetadata(trackinCode) {
			this.$emit('changeOrderMetadata', { trackinCode });
		},
		getProcessByOrderAndProcessType(orderId) {
			this.disableBtnByProccess = true;
			validateProcessIsAllowed(orderId, ProcessTypes.SHIPPING)
				.then(isValid => {
					this.disableBtnByProccess = !isValid;
				});
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
