<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>fas fa-exclamation</v-icon>
			</v-avatar>
			<h3>Próxima box</h3>
		</v-card-title>
		<v-card-text>
			{{ data | formatDate('DD/MM/YYYY [(]dddd[)]') }}
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'Observacao',
	props: {
		assinatura: {
			type: Object,
			required: true
		}
	},
	computed: {
		data() {
			if (this.assinatura.boxes.length > 0)
				return this.assinatura.boxes[0].inicio;
			return this.assinatura.data_proxima_box;
		}
	}
};
</script>
