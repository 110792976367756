<template>
	<div class="px-md-5">
		<!-- Medicamentos Header -->
		<Header @crud="crud" @busca="b => busca = b" />

		<!-- Conteúdo -->
		<v-card>
			<v-data-table :headers="headers" :items="items" :loading="is_loading" :search="busca"
				:rows-per-page-items="[10, 20, 30]" :no-data-text="error_message" no-results-text="Nenhum resultado encontrado"
				rows-per-page-text="Itens por página">
				<template slot="items" slot-scope="props">
					<tr class="pointer" @click="crud(props.item)">
						<td>{{ props.item.nome }}</td>
						<td>{{ props.item.descricao }}</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog persistent v-model="dialog_crud" scrollable transition="fade" max-width="520px">
			<transition>
				<DialogTag v-if="dialog_crud" :item_pai="item_selecionado" :close.sync="dialog_crud"
					@inserir="salvo" @excluir="excluido" />
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { ListagemMixin } from '@Mixins/listagem';
import DialogTag from './dialog-tag';
import Header from './header';

export default {
	name: 'Tag',
	mixins: [ListagemMixin],
	components: {
		Header, DialogTag
	},
	data: () => ({
		type: 'tag',
		headers: [
			{ text: 'Nome', value: 'nome' },
			{ text: 'Descrição', value: 'descricao' }
		]
	})
};
</script>
