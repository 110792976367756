<template>
	<div class="px-md-5">
		<!-- Conteúdo -->
		<iframe width="100%" height="804" src="https://app.powerbi.com/view?r=eyJrIjoiODkxOGFmODYtNzA0OC00Njk1LTkxZDgtYzdhOTI0MGJkMDMwIiwidCI6IjdmOWQ4YjNiLWEwMGQtNDc1Yy1hOTFjLTYxOWE0NTc3MDFiYSJ9" frameborder="0" allowFullScreen="true"></iframe>
	</div>
</template>

<script>
export default {
	name: 'RelatorioVendaSpot',
	components: {
	},
	data: () => ({
	})
};
</script>
