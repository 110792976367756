<template>
	<v-chip :class="getChipColor(status)">{{statusLabel[status]}}</v-chip>
</template>

<script>

import { statusEnum, statusLabel } from '@Consts/status-logistics';

export default {
	name: 'LogisticsStatusChip',
	mixins: [],
	components: {},
	props: {
		status: {
			type: String,
			required: true
		}
	},
	data: () => ({ statusLabel }),
	created() {},

	computed: {},

	methods: {

		getChipColor(status) {
			const baseStyle = 'ml-auto chip';
			switch (status) {
				case statusEnum.PENDING:
					return `${baseStyle} chip-status-pending`;
				case statusEnum.FINISHED:
					return `${baseStyle} chip-status-delivered`;
				case statusEnum.DELIVERED:
					return `${baseStyle} chip-status-delivered`;
				case statusEnum.STARTED:
					return `${baseStyle} chip-status-started`;
				case statusEnum.PICKED_UP:
					return `${baseStyle} chip-status-started`;
				case statusEnum.CANCELLED:
					return `${baseStyle} chip-status-failed`;
				case statusEnum.FAILED:
					return `${baseStyle} chip-status-failed`;
				default:
					return `${baseStyle} chip-status-pending`;
			}
		}
	}
};

</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.chip{
	color: white!important;
	font-weight: 500!important;
	border-radius: 10px!important;
}

.chip-status-pending{
	background-color: #FFCE4F!important;
}

.chip-status-delivered{
	background-color: #2DCE71!important;
}

.chip-status-started{
	background-color:  #409EFF!important;
}

.chip-status-failed{
	background-color:  #E50000!important;
}

.v-dialog--scrollable > .v-card {
	background-color: white!important;
}

</style>
