<template>
	<v-dialog
		persistent
		v-model="opened"
		width="390">
		<template v-slot:activator="{ on }">
			<div v-on="on" class="action" >
				<button class="action" >
					<v-icon color="black" large>stop</v-icon>
				</button>

				Finalizar
			</div>
		</template>

		<section class="farme-dialog">
			<section class="farme-dialog-header">
				Finalizar produção da Box?
			</section>
			<section class="farme-dialog-body">
				<p>Clique o no botão 'Finalizar produção' para confirmar a finalização da produção da box.</p>

				<v-textarea
					v-model="observation"
					box
					background-color="white"
					name="observation"
					label="Ocorrência"
					placeholder="Aconteceu algo durante a produção? Se sim, registre aqui."></v-textarea>
			</section>

			<section class="farme-dialog-footer">
				<v-btn
					color="green darken-1"
					flat="flat"
					@click="opened = false">
					Cancelar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					@click="confirm">
					Finalizar produção
				</v-btn>
			</section>
		</section>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			opened: false,
			observation: null
		};
	},
	methods: {
		confirm() {
			this.opened = false;
			const observation = this.observation;
			this.$emit('confirm', observation);
		}
	}
};
</script>

<style lang="scss" scoped>
.action {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 50px;
	color: white;

	button {
		background-color: #FFF;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		box-shadow: 0px 4.62px 4.62px 0px rgba(217, 172, 106, 0.2);

		svg {
			width: 45px;
			height: 45px;
		}
	}

	@media (max-width: 600px) {
		gap: 20px;
		button {
			width: 75px;
			height: 75px;
		}
	}
}
</style>
