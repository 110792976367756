<template>
	<v-layout wrap class="d-flex items-center justify-space-between mt-4">
		<v-flex md8 xs12 class="positionTitle ml-5">
			<v-layout column>
				<v-flex>
					<v-toolbar-title style="color: #342b1d" class="titulo display-1">{{textTitle}}</v-toolbar-title>
					<p class="black--text inter mb-0 caption mt-1">{{ budgetCreationCity }}</p>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex md4 xs12 class="d-flex items-center positionButtons mr-5">
			<v-btn v-if="false" style="color: #342b1d; border-radius: 5px" outline flat @click="$emit('openCardHubspot')">
				Card Hubspot <v-icon small right>fab fa-hubspot</v-icon>
			</v-btn>
			<v-btn style="color: #342b1d; border-radius: 5px;" outline flat @click="$emit('close')">
				Fechar <v-icon small right>close</v-icon>
			</v-btn>
			<v-btn :loading="loadingSave" :disabled="isNewQuotation && !isProductTabOrPersonalDataTab" style="color: #342b1d; border-radius: 5px" outline flat
				@click="save">
				{{textSave}} <v-icon small right>fas fa-save</v-icon>
			</v-btn>
			<!--<v-btn :loading="loadingSubmit" :disabled="!isLastTab || isNewQuotation" style="color: #FFC875; border-radius: 5px" @click="submit">
				Submeter <v-icon small right>fas fa-arrow-right</v-icon>
			</v-btn>-->
		</v-flex>
	</v-layout>
</template>

<script>
import { getCurrentCompanyUnit } from '@Config/unidades';
import { stepsQuotation } from '@Consts/quotation-v2';
import Mixin from './mixin';

export default {
	name: 'HeaderQuotationV2',
	mixins: [Mixin],
	props: {
		tabSelected: {
			type: String
		},
		companyUnit: {
			type: String
		},
		loadingSubmit: {
			type: Boolean
		},
		loadingSave: {
			type: Boolean
		}
	},
	computed: {
		textSave() {
			return this.isNewQuotation ? 'Salvar' : 'Salvar edição';
		},
		systemCity() {
			if (this.isNewQuotation)
				return getCurrentCompanyUnit().id;

			let companyId = null;
			switch (this.companyUnit) {
				case 'BH':
					companyId = 1; break;
				case 'SP':
					companyId = 2; break;
				default: companyId = null; break;
			}

			return companyId;
		},
		textCity() {
			switch (this.systemCity) {
				case 1:
					return 'Belo Horizonte';
				case 2:
					return 'São Paulo';
				default:
					return '';
			}
		},
		textTitle() {
			return this.isNewQuotation ? 'Criar orçamento' : 'Editar orçamento';
		},
		budgetCreationCity() {
			if (this.isNewQuotation)
				return `Orçamento sendo criado em: ${this.textCity}`;

			return `Orçamento da cidade: ${this.textCity}`;
		},
		isLastTab() {
			return this.tabSelected === stepsQuotation.clientData;
		},
		isProductTabOrPersonalDataTab() {
			return this.tabSelected !== stepsQuotation.generalData;
		}
	},
	methods: {
		save() {
			return this.isNewQuotation ? this.$emit('saveQuotation') : this.$emit('saveEditedQuotation');
		},
		submit() {
			return this.$emit('submitQuotation');
		}
	}
};
</script>
