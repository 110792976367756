<template>
	<v-text-field
		ref="inputScan"
		solo
		v-model="input"
		hide-details
		@keydown.enter="validateInput">

		<template v-slot:prepend-inner>
			<v-icon class="mr-1">
				qr_code_scanner
			</v-icon>
		</template>
	</v-text-field>
</template>

<script>
export default {
	data() {
		return {
			input: null
		};
	},
	mounted() {
		this.inputFocus();
	},
	methods: {
		inputFocus() {
			if (this.$refs.inputScan)
				this.$refs.inputScan.focus();
		},
		validateInput() {
			const value = this.input;
			if (!value) {
				this.$store.dispatch('SHOW_SNACKBAR', { message: 'Digite ou scaneie o QR CODE ou Código de barras', color: 'error' });
				return;
			}
			this.$emit('scanned', this.input);
			this.input = null;
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
