<template>
	<v-flex class="dialogStyle">
		<v-layout column wrap class="d-flex items-center justify-center">
			<v-flex xs12 class="imgStyle d-flex items-center mb-4">
				<img src="/img/icons/discount.svg" width="50" height="50" alt="Imagem de dinheiro">
			</v-flex>
			<v-flex xs12 class="d-flex q-column items-center mb-4">
				<h1 class="headline sans text-bold">Escolha o modelo de desconto que deseja aplicar:</h1>
				<h2 class="sans subheading text-center">Lembrando que este é um desconto estimado a ser adicionado e que o desconto máximo liberado será de acordo com o sistema. </h2>
			</v-flex>
		</v-layout>
		<v-layout column wrap class="d-flex items-start justify-center">
			<v-flex xs12 style="padding: 12px 0 !important">
				<h3 class="mb-2 mr-3 inline-block inter text-bold body-1" style="font-size: 14px">Tipo do desconto</h3>
				<v-radio-group v-model="discountType" :mandatory="false" hide-details class="ma-0" :column="false">
					<v-radio label="Porcentagem" value="percentage" color="primary" />
					<v-radio label="Desconto nominal" value="nominalDiscount" color="primary" />
					<v-radio label="Preço final" value="finalPrice" color="primary" />
				</v-radio-group>
			</v-flex>
			<v-flex v-if="discountType === 'percentage'" md6 xs12 class="d-flex q-column items-start justify-start">
				<span class="inter text-bold body-1">Desconto</span>
				<v-text-field v-model="values.discount" :rules="percentageRules" box class="inputQuotationStyle mt-3" hide-details
					label="Porcentagem"
					type="number"
					suffix="%"/>
			</v-flex>
			<v-flex v-if="discountType === 'nominalDiscount'" md6 xs12 class="d-flex q-column items-start justify-start">
				<span class="inter text-bold body-1">Desconto Nominal</span>
				<v-text-field v-model="values.nominalDiscount" box class="inputQuotationStyle mt-3" hide-details
					label="Desconto Nominal"
					v-money/>
			</v-flex>
			<v-flex v-if="discountType === 'finalPrice'" md6 xs12 class="d-flex q-column items-start justify-start">
				<span class="inter text-bold body-1">Preço Final</span>
				<v-text-field v-model="values.finalPrice" :rules="finalPriceRules" box class="inputQuotationStyle mt-3" hide-details
					label="Nominal"
					v-money/>
			</v-flex>
			<v-flex xs12 class="items-center justify-center mt-5">
				<v-btn @click="$emit('update:close', false)">Cancelar</v-btn>
				<v-btn color="primary" @click="saveDiscount">Salvar desconto</v-btn>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
import { onlyNumber } from '@Util/functions';

export default {
	name: 'DialogSelectDiscount',
	props: {
		values: {
			type: Object
		},
		isNew: {
			type: Boolean,
			required: false
		}
	},
	data: () => ({
		discountType: 'percentage',
		percentageRules: [v => (v >= 0 && v <= 100) || 'Porcentagem deve ser de 0 a 100'],
		finalPriceRules: [v => (Number(onlyNumber(v)) >= 0) || 'Valor deve ser maior que zero']
	}),
	created() {
		if (!this.isNew)
			this.discountType = this.values.discountType;
	},
	watch: {
		discountType: {
			handler(newVal) {
				if (newVal) {
					this.values.discount = 0;
					this.values.nominalDiscount = 0;
					this.values.finalPrice = 0;
				}
			},
			deep: true
		}
	},
	methods: {
		saveDiscount() {
			const values = { ...this.values, discountType: this.discountType };
			this.$emit('saveDiscount', values);
			this.close();
		},
		close() {
			this.$emit('update:close', false);
		}
	}
};
</script>

<style lang="scss" scoped>
.dialogStyle {
	padding: 20px 50px;
	border-radius: 20px;
	background: #FFF3E2;
	border: 2px solid #FFC875
}

.imgStyle {
	background-color: rgba(255, 200, 117, 0.6);
	padding: 15px;
	border-radius: 40px;
}
</style>
