export const logisticReferenceTypes = Object.freeze({
	order: 'order',
	box: 'box'
});

const logisticReferenceTypeDescription = Object.freeze({
	[logisticReferenceTypes.order]: 'Pontual',
	[logisticReferenceTypes.box]: 'Box'
});

export function getLogisticReferenceTypeDescription(type) {
	if (!logisticReferenceTypeDescription[type])
		throw new Error('Unknow reference type');
	return logisticReferenceTypeDescription[type];
}
