<template>
	<Dialog titulo="Adicionar medicamento" btn_acao="Salvar" btn_cancelar="Cancelar" @acao="salvar()"
		@close="fecharModal()">
		<v-container grid-list-md class="pa-0">
			<v-layout wrap>
				<v-flex xs12>
					<v-text-field v-model="medicamento.produto" label="Nome *" box data-vv-name="nome"
						v-validate="{ required: true }" :error-messages="errors.collect('nome')" />
				</v-flex>
				<v-flex xs6>
					<v-text-field v-model="medicamento.lote" label="Lote" box data-vv-name="lote" v-validate="{ required: true }"
						:error-messages="errors.collect('lote')" />
				</v-flex>
				<v-flex xs6>
					<TextDateField v-model="medicamento.validade" label="Validade" placeholder="dd/mm/yyyy"
						:box="true" color="green_strong" :flat="true" :validate="{ required: true, data: true }" />
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
export default {
	name: 'DialogAdicionarMedicamento',
	$_veeValidate: { validator: 'new' },
	data: () => ({
		medicamento: {}
	}),
	methods: {
		async salvar() {
			const is_valid = await this.$validator.validate();
			if (!is_valid) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
				return;
			}
			this.$emit('medicamento-salvo', this.medicamento);
			this.fecharModal();
		},
		fecharModal() {
			this.$emit('update:close', false);
		}
	}
};
</script>
