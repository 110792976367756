<template>
	<Dialog titulo="Informações gerais da box" :subtitulo="subtitulo" background="#f2f4f6" @close="close">
		<LoaderDialog v-if="loading" />
		<v-container v-else fluid grid-list-lg>
			<v-layout wrap>
				<v-flex xs12 md6>
					<v-layout wrap>
						<v-flex xs12>
							<InformacoesPacienteResponsavelEndereco :paciente="box.assinatura.paciente" :responsaveis="box.assinatura.paciente.responsaveis" :endereco="{...box.endereco, observacao: box.endereco.instrucoes_entrega}" :rastreio="box.codigo_rastreio" />
						</v-flex>
						<v-flex xs12>
							<Receitas :box_id="box.id" :assinatura_id="box.assinatura_id" noUpload noMed noZoom />
						</v-flex>
						<v-flex xs12>
							<Observacao :box="box" :disabled="isInProgress" @observacaoAtualizada="observacaoAtualizada()" />
						</v-flex>
						<v-flex xs12>
							<v-card>
								<v-card-title class="pb-0">
									<v-avatar size="38" class="mr-3">
										<v-icon color="white" small>
											fas fa-history
										</v-icon>
									</v-avatar>
									<h3>Histórico de Status</h3>
									<v-spacer />
									<v-btn v-if="box.status !== 'DELIVERED' && allowChangeStatus" depressed small class="green white--text" @click="dialog_alterar_status = true">
										<v-icon small class="mr-2">add</v-icon>
										Alterar status
									</v-btn>
								</v-card-title>
								<v-card-text class="historico-box">
									<HistoricoStatus :box="box" />
								</v-card-text>
							</v-card>
						</v-flex>
						<v-flex xs12>
							<v-card>
								<v-card-title class="pb-0">
									<v-avatar size="38" class="mr-3">
										<v-icon color="white" >
											running_with_errors
										</v-icon>
									</v-avatar>
									<h3>Histórico de Pendências</h3>
									<v-spacer />
								</v-card-title>
								<v-card-text class="historico-box">
									<HistoricoPendencias :box_id="box.id" />
								</v-card-text>
							</v-card>
						</v-flex>
						<v-flex xs12>
							<Ocorrencias :box_id="box.id" />
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 md6>
					<v-layout wrap>
						<v-flex xs12>
							<v-card>
								<v-card-title class="pb-0">
									<v-avatar size="38" class="mr-3">
										<v-icon color="white" small>
											fas fa-tools
										</v-icon>
									</v-avatar>
									<h3>Ações</h3>
								</v-card-title>
								<v-card-text class="actions">
									<div class="q-row">
										<span v-if="actions.length === 0" class="text-center full-width">Nenhuma ação disponível</span>
										<v-container v-else fluid grid-list-lg>
											<v-layout wrap>
												<Button v-for="(button, i) in actions" :key="i" :button="button"/>
												<ResetBoxDialog v-if="isNewResetBoxFeatureFlagEnabled" :boxId="box.id" @resetBox="resetBox"/>
											</v-layout>
										</v-container>
									</div>
								</v-card-text>
							</v-card>
						</v-flex>
						<v-flex xs12>
							<Medicamentos
								:prescriptionIsVisible="true"
								:boxId="box_id"
								:medicamentos="box.medicamentos"
								mensagem="Os medicamentos aparecerão aqui quando as etapas de Compra e Atendimento forem concluídas."
								:assinatura="box.assinatura"
								:medicamentosComAproveitamentoReceita="medicamentosComAproveitamentoReceita"
								disabled/>
						</v-flex>
						<v-flex xs12 class="text-xs-right" v-if="box.status !== 'DELIVERED' && $store.getters.allow_delete_box">
							<v-btn small flat color="error" @click="dialog_apagar = true">
								Apagar box
							</v-btn>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>

		<v-dialog persistent v-model="dialog_iugu" scrollable transition="fade" max-width="920px">
			<transition>
				<DialogIugu v-if="dialog_iugu" :box_id="box.id" :assinatura_id="box.assinatura_id" @close="dialog_iugu = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialog_nfs" scrollable transition="fade" max-width="920px">
			<transition>
				<DialogNfs v-if="dialog_nfs" :box_id="box.id" :assinatura_id="box.assinatura_id" :close.sync="dialog_nfs" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialog_email" scrollable transition="fade" max-width="920px">
			<transition>
				<DialogEnviarNf v-if="dialog_email" :assinatura_id="box.assinatura_id" :close.sync="dialog_email" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialog_alterar_status" scrollable transition="fade" max-width="500px">
			<transition>
				<DialogAlterarStatus v-if="dialog_alterar_status" :box_id="box.id" :close.sync="dialog_alterar_status"
					@novo-status="getBox" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_ordem_producao" transition="fade" max-width="920px">
			<transition>
				<DialogEscolherMedicamentos v-if="dialog_ordem_producao" :box_id="box.id"
					@close="dialog_ordem_producao = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_etiquetas_antigas" transition="fade" max-width="920px">
			<transition>
				<DialogEscolherMedicamentos v-if="dialog_etiquetas_antigas" :box_id="box.id" etiqueta
					@close="dialog_etiquetas_antigas = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_resetar" transition="fade" max-width="380px">
			<transition>
				<Dialog v-if="dialog_resetar" titulo="Resetar box" btn_acao="Sim, resetar" btn_acao_class="error"
					:loading="resetando" @acao="reset" @close="dialog_resetar = false">
					<p class="text-bold">Tem certeza que deseja resetar esta box? Esta ação é irreversível.</p>
					<p>Resetando a box, seu histórico de alterações e produção será apagado, o estoque do paciente será ajustado (desconsiderando entradas e saídas desta box) e os medicamentos serão atualizados de acordo com a assinatura.</p>
					<TextDateField v-if="box" v-model="inicio" box hideDetails flat
						label="Início" placeholder="dd/mm/yyyy" color="green_strong" />
					<v-textarea class="mt-3" v-model="justificativa" box hide-details label="Justificativa"
						placeholder="Por favor, justique aqui o motivo pelo qual você está resetando esta box" />
				</Dialog>
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_alterar_data_inicio" transition="fade" max-width="380px">
			<transition>
				<Dialog v-if="dialog_alterar_data_inicio"
					titulo="Alterar data de início" subtitulo="Tem certeza que deseja alterar a data de início desta box?"
					btn_acao="Sim, alterar" btn_acao_class="error" :loading="alterando"
					@acao="update('alterar data inicio')" @close="dialog_alterar_data_inicio = false">
					<TextDateField v-model="inicio" box hideDetails flat
						label="Início" placeholder="dd/mm/yyyy" color="green_strong" />
					<v-select
						v-if="isFirstBox"
						v-model="reason"
						:items="reasons"
						placeholder="Motivo"
						v-validate="{ required: true }"
						flat
						box
						hide-details
						class="mt-3" />
					<v-textarea v-else class="mt-3" v-model="justificativa" box hide-details
						label="Justificativa"
						placeholder="Por favor, justique aqui o motivo pelo qual você está alterando a data de início desta box" />
					<v-textarea v-if="showOtherReason" class="mt-3" v-model="justificativa" box hide-details
						label="Motivo"
						placeholder="Escreva o outro motivo aqui..." />
				</Dialog>
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_alterar_data_entrega" transition="fade" max-width="380px">
			<transition>
				<Dialog v-if="dialog_alterar_data_entrega"
					titulo="Alterar data de entrega" subtitulo="Tem certeza que deseja alterar a data de entrega desta box?"
					btn_acao="Sim, alterar" btn_acao_class="error" :loading="alterando"
					@acao="update('alterar data entrega')" @close="dialog_alterar_data_entrega = false">
					<TextDateField v-model="inicio" box hideDetails flat
						label="Entrega" placeholder="dd/mm/yyyy" color="green_strong" />
					<v-textarea class="mt-3" v-model="justificativa" box hide-details label="Justificativa"
						placeholder="Por favor, justique aqui o motivo pelo qual você está alterando a data de entrega desta box" />
				</Dialog>
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_apagar" transition="fade" max-width="380px">
			<transition>
				<Dialog v-if="dialog_apagar" titulo="Apagar box" btn_acao="Sim, apagar" btn_acao_class="error"
					:loading="apagando" @acao="destroy" @close="dialog_apagar = false">
					<p class="text-bold">Tem certeza que deseja apagar esta box? Esta ação é irreversível.</p>
					<p>Apagando a box, você também apagará as receitas anexadas a ela, seu histórico de alterações e produção, ajustará o estoque do paciente, desconsiderando entradas e saídas desta box, e voltará a data de início da próxima box da assinatura.</p>
					<p v-if="tem_pausa" class="text-bold red--text"><v-icon small color="error">pause_circle</v-icon> Atenção! Essa box contém um medicamento pausado!</p>
				</Dialog>
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="show_update_address_dialog" transition="fade" max-width="980px">
			<transition>
				<DialogEndereco v-if="show_update_address_dialog" :item_editavel="{...box.endereco, observacao: box.endereco.instrucoes_entrega}"
					:assinatura-id="box.assinatura.id"
					:box-id="box.id"
					:close.sync="show_update_address_dialog" @enderecoEditado="endereco => editBoxAddress(endereco)" :editando="loading"/>
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import { copyToClipboard, isBoxInProcess } from '@Util/functions';
import { BoxPresenter } from '@Presenter/box-presenter';
import { geraExcelProducao } from '@Util/excel-producao';
import { geraPdfEtiquetaForaDaBox } from '@Util/pdf-etiqueta-fora-da-box';
import { geraPdfEtiquetaRastreabilidade } from '@Util/pdf-etiqueta-rastreabilidade';
import { geraPdfEtiquetasAntigas } from '@Util/pdf-etiquetas-antigas';
import Receitas from '@Componentes/receitas';
import Medicamentos from '@Componentes/assinatura/medicamentos';
import DialogEscolherMedicamentos from '@Componentes/dialog-escolher-medicamentos';
import InformacoesPacienteResponsavelEndereco from '@Componentes/assinatura/informacoes-paciente-responsavel-endereco';
import DialogNfs from '@Componentes/dialog-nfs';
import DialogIugu from '@Componentes/dialog-iugu';
import DialogEndereco from '@Componentes/assinatura/enderecos/dialog-endereco';
import DialogEnviarNf from '@Componentes/dialog-enviar-nf';
import moment from 'moment';
import { url_minha_box } from '@Config/url-minha-box';
import { featureFlags } from '@Consts/feature-flags';
import LoaderDialog from '@Componentes/loader-dialog';
import Button from './button';
import Ocorrencias from './ocorrencias';
import HistoricoStatus from './historico-status';
import HistoricoPendencias from './historico-pendencias.vue';
import DialogAlterarStatus from './dialog-alterar-status';
import Observacao from './observacao.vue';
import ResetBoxDialog from './reset/index.vue';

export default {
	name: 'DialogBox',
	components: {
		Button,
		Receitas,
		Medicamentos,
		HistoricoStatus,
		InformacoesPacienteResponsavelEndereco,
		DialogNfs,
		DialogIugu,
		DialogEnviarNf,
		DialogAlterarStatus,
		DialogEscolherMedicamentos,
		Ocorrencias,
		DialogEndereco,
		HistoricoPendencias,
		Observacao,
		ResetBoxDialog,
		LoaderDialog
	},
	data: () => ({
		box: null,
		inicio: null,
		justificativa: '',
		reason: '',
		cobranca: [],
		loading: true,
		apagando: false,
		resetando: false,
		alterando: false,
		dialog_nfs: false,
		dialog_iugu: false,
		dialog_email: false,
		dialog_apagar: false,
		dialog_resetar: false,
		dialog_ordem_producao: false,
		dialog_alterar_status: false,
		dialog_etiquetas_antigas: false,
		dialog_alterar_data_inicio: false,
		dialog_alterar_data_entrega: false,
		show_update_address_dialog: false,
		endereco_selecionado: {},
		medicamentosComAproveitamentoReceita: []
	}),
	computed: {
		isNewResetBoxFeatureFlagEnabled() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.new_reset_box);
		},
		box_id() {
			return this.$store.state.box_id;
		},
		subtitulo() {
			if (this.box)
				return `Box ${this.box.id} - Assinatura ${this.box.assinatura_id} - Início ${this.box.inicio}`;
			return 'Carregando...';
		},
		isInProgress() {
			return isBoxInProcess(this.box.status);
		},
		tem_pausa() {
			return this.box.medicamentos.some(medicamento => !!medicamento.pausa);
		},
		allowChangeStatus() {
			return this.$store.getters.eh_tech_team;
		},
		canResetBox() {
			if (!this.isInProgress || !this.$store.getters.eh_administrador || !this.$store.getters.eh_rt)
				return false;
			return true;
		},
		actions() {
			const actions = [
				{
					label: 'Etiquetas Novas',
					icon: 'fas fa-file-download',
					onClick: this.gerarEtiquetas
				},
				{
					label: 'Etiquetas Saquinho',
					icon: 'fas fa-file-download',
					onClick: this.gerarEtiquetasSaquinho
				},
				{
					label: 'Etiqueta Fora da Box',
					icon: 'fas fa-file-download',
					onClick: this.gerarEtiquetasForaDaBox
				},
				{
					label: 'Etiqueta Rastreabilidade',
					icon: 'fas fa-file-download',
					onClick: this.gerarEtiquetasRastreio
				},
				{
					label: 'Ordem de produção',
					icon: 'fas fa-file-download',
					onClick: () => { this.dialog_ordem_producao = true; }
				},
				{
					label: 'Iugu',
					icon: 'fas fa-dollar-sign',
					onClick: () => { this.dialog_iugu = true; }
				},
				{
					label: 'NFS-e',
					icon: 'fas fa-file-alt',
					onClick: () => { this.dialog_nfs = true; }
				},
				{
					label: 'Email com NF',
					icon: 'fas fa-envelope',
					onClick: () => { this.dialog_email = true; }
				},
				{
					label: 'Copiar link minha box',
					icon: 'fas fa-copy',
					onClick: () => { this.copyLinkMyBox(this.box.qr_code); }
				},
				{
					label: 'Editar endereço da box',
					icon: 'fas fa-edit',
					onClick: () => { this.openAddressBoxDialog(); }
				}
			];
			if (this.box.status !== 'DELIVERED' || this.$store.getters.eh_administrador) {
				if (!this.isInProgress) {
					actions.push({
						label: 'Alterar data de início',
						icon: 'fas fa-calendar-alt',
						onClick: () => { this.dialog_alterar_data_inicio = true; }
					});
				}
				if (!this.isNewResetBoxFeatureFlagEnabled && (this.isInProgress || this.$store.getters.eh_administrador)) {
					actions.push({
						label: 'Resetar box',
						icon: 'fas fa-undo-alt',
						onClick: () => { this.dialog_resetar = true; }
					});
				}
			}

			return actions;
		},
		reasons() {
			return [
				'Medicamento em falta',
				'Pendência de intercambialidade',
				'Pedido travado por falta de receita',
				'Alteração solicitada pelo cliente',
				'A coleta da receita atrasou',
				'Sem informações sobre aproveitamento de receita',
				'A receita estava diferente do orçamento',
				'A assinatura chegou com informações incompletas ou insuficientes',
				'Abrangência de entrega exige prazo maior',
				'Outro motivo'
			];
		},
		showOtherReason() {
			return this.reason === 'Outro motivo';
		},
		isFirstBox() {
			return this.$store.getters['assinatura/subscription'].primeira_box;
		}
	},
	async created() {
		await this.getBox();
		this.getMedicamentosAproveitamentoReceita();
	},
	methods: {
		async observacaoAtualizada() {
			await this.getBox();
		},
		async getBox() {
			return new Promise(async resolve => {
				this.loading = true;
				this.box = await BoxPresenter.show(this.box_id);
				this.box.inicio = moment(this.box.inicio).format('DD/MM/YYYY');
				this.inicio = this.box.inicio;
				this.loading = false;
				resolve();
			});
		},
		async resetBox(data) {
			this.loading = true;

			try {
				await BoxPresenter.resetBox(
					data.boxId,
					{
						startDate: data.startDate,
						reason: data.reason,
						keepMedicineIds: data.keepMedicineIds
					}
				);
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Reset da Box feito com sucesso',
					color: 'success'
				});
				await this.getBox();
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: error.message,
					color: 'error'
				});
			} finally {
				this.loading = false;
			}
		},
		getMedicamentosAproveitamentoReceita() {
			BoxPresenter.getAproveitamentoReceita(this.box_id)
				.then(aproveitamentoReceitas => {
					this.medicamentosComAproveitamentoReceita = aproveitamentoReceitas;
				});
		},
		destroy() {
			this.apagando = true;
			BoxPresenter.destroy(this.box_id)
				.then(() => {
					this.$root.$emit('BOX_APAGADA', this.box_id);
					this.close();
				})
				.finally(() => {
					this.apagando = false;
				});
		},
		hasError() {
			if (this.isFirstBox && this.dialog_alterar_data_inicio) {
				if (!this.reason)
					return this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Por favor, insira uma justificativa' });
				if (this.showOtherReason && !this.justificativa)
					return this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Por favor, insira uma justificativa' });
			} else if (!this.justificativa)
				return this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Por favor, insira uma justificativa' });

			const { inicio } = this.box;
			const momentInicio = moment(inicio, 'DD/MM/YYYY', true);
			if (!inicio || !momentInicio.isValid())
				return this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Data inválida' });
			return false;
		},
		reset(ocorrencia = 'resetar box') {
			this.doUpdate('RESET', 'resetando', ocorrencia);
		},
		update(ocorrencia = '') {
			this.doUpdate('BEGIN_UPDATED', 'alterando', ocorrencia);
		},
		doUpdate(status, loading_name, ocorrencia) {
			if (this.hasError())
				return;

			const { id } = this.box;
			this[loading_name] = true;
			const doAction = status === 'RESET' ? BoxPresenter.reset : BoxPresenter.update;

			let justification = '';

			if (this.isFirstBox) {
				if (this.showOtherReason)
					justification = `${this.reason} - ${this.justificativa}`;
				else
					justification = this.reason;
			} else
				justification = this.justificativa;

			const data = {
				id,
				status,
				ocorrencia,
				justificativa: justification,
				inicio: moment(this.inicio, 'DD/MM/YYYY').format('YYYY-MM-DD')
			};

			doAction(data)
				.then(() => {
					this.boxUpdated();
				})
				.finally(() => {
					this[loading_name] = false;
				});
		},
		boxUpdated() {
			this.reason = '';
			this.justificativa = '';
			this.dialog_resetar = false;
			this.dialog_alterar_data_inicio = false;
			this.getBox();
			this.$root.$emit('BOX_UPDATED');
		},
		gerarEtiquetas() {
			return new Promise(async resolve => {
				const response = await BoxPresenter.gerarEtiquetas({ ids: [this.box_id] });
				geraExcelProducao(response);
				resolve();
			});
		},
		async gerarEtiquetasForaDaBox() {
			return new Promise(async resolve => {
				const { etiquetasDeRastreio } = await BoxPresenter.gerarEtiquetas({ ids: [this.box_id] });
				geraPdfEtiquetaForaDaBox(etiquetasDeRastreio);
				resolve();
			});
		},
		async gerarEtiquetasRastreio() {
			return new Promise(async (resolve, reject) => {
				BoxPresenter.show(this.box_id)
					.then(response => {
						const paciente = response.assinatura.paciente;
						const medicamentos = response.medicamentos_nfe;
						geraPdfEtiquetaRastreabilidade(medicamentos, paciente);
						resolve();
					}).catch(reject);
			});
		},
		async gerarEtiquetasSaquinho() {
			return new Promise(async resolve => {
				const { etiquetasDoSaquinho } = await BoxPresenter.gerarEtiquetas({ ids: [this.box_id] });
				geraPdfEtiquetasAntigas(etiquetasDoSaquinho);
				resolve();
			});
		},
		copyLinkMyBox(qrcode) {
			return copyToClipboard(`${url_minha_box[localStorage.getItem('env')] || url_minha_box.Prod }/${qrcode}`, `Link minha box - ${this.box.id}`);
		},
		openAddressBoxDialog() {
			this.show_update_address_dialog = true;
		},
		getCoordinate(currentAddress, incomingAddress, coordinateAttribute) {
			const cepHasChanged = currentAddress.cep !== incomingAddress.cep;
			const logradouroHasChanged = currentAddress.logradouro !== incomingAddress.logradouro;
			const currentCoordinate = currentAddress[coordinateAttribute];
			const incomingCoordinate = incomingAddress[coordinateAttribute];

			if (cepHasChanged && logradouroHasChanged) {
				/**
				 *	If the CEP has changed and the coordinates are equal that means that
				 *	there was an error fetching the address through CEP API
				 *	In this case, we have to delete the current coordinate since the address has changed
				 *	otherwise (if the CEP has changed and the coordinates are different) we return the
				 *	incoming coordinate
				 */
				return currentCoordinate === incomingCoordinate ? null : (incomingCoordinate?.toString() || null);
			}

			return currentCoordinate?.toString() || null;
		},
		editBoxAddress(address) {
			BoxPresenter.atualizarEndereco(this.box_id,
				{
					cep: address.cep,
					logradouro: address.logradouro,
					numero: address.numero,
					bairro: address.bairro,
					cidade: address.cidade,
					estado: address.estado,
					complemento: address.complemento,
					longitude: this.getCoordinate(this.box.endereco, address, 'longitude'),
					latitude: this.getCoordinate(this.box.endereco, address, 'latitude'),
					instrucoes_entrega: address.observacao || null
				})
				.then(async response => {
					this.$store.dispatch(
						'SHOW_SNACKBAR',
						{ color: 'success', message: response.message }
					);
					await this.getBox();
					this.show_update_address_dialog = false;
				})
				.catch(response => {
					this.$store.dispatch(
						'SHOW_SNACKBAR',
						{ color: 'error', message: response.message }
					);
					this.show_update_address_dialog = true;
				});
		},
		close() {
			this.$store.commit('SET_BOX_ID', null);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

::v-deep .container {
	padding: 0;

	.historico-box {
		padding: 0;
	}

	.chip {
		display: inline-block;
		background: $purple;
		border-radius: 15px;
		padding: 5px 10px;
		margin: 5px;
		color: white;
	}

	.v-input--switch {
		display: flex;
		justify-content: flex-end;
	}
}
</style>
