<template>
	<div>
		<div class="mb-2" :class="text_style">
			<v-icon small class="mr-2" :color="icon_style">far fa-clock</v-icon>
			<div style="margin-top: -20px;">
				<p class="mb-0" style="margin-left: 28px" v-for="(horario, index) in posologia.horarios" :key="index">
					{{ $functions.getHorario(horario) }}
				</p>
			</div>
		</div>
		<p class="mb-0" :class="text_style">
			<v-icon small style="margin-right: 10px" :color="icon_style">far fa-calendar-alt</v-icon>
			<span v-if="posologia.periodo_de_uso === posologyTypes.allDays">
				Todos os dias
			</span>
			<span v-if="posologia.periodo_de_uso === posologyTypes.dayYesDayNot">
				Dia sim, dia não
			</span>
			<span v-if="posologia.periodo_de_uso === posologyTypes.dayNotDayYes">
				Dia não, dia sim
			</span>
			<span v-if="posologia.periodo_de_uso === posologyTypes.weekly">
				Dias da semana:
				<span v-for="(item, index) in posologia.dias_da_semana" :key="index">
					{{ item.toLowerCase() }}<span v-if="posologia.dias_da_semana.length !== index + 1" v-html="', '" />
				</span>
			</span>
			<span v-if="posologia.periodo_de_uso === posologyTypes.monthly">
				Dias do mês:
				<span v-for="(item, index) in posologia.dias_do_mes" :key="index">
					{{ item.toLowerCase() }}<span v-if="posologia.dias_do_mes.length !== index + 1" v-html="', '" />
				</span>
			</span>
			<span v-if="posologia.periodo_de_uso === posologyTypes.other">
				{{ `${posologia.outro_periodo.tipo}
        ${posologia.outro_periodo.dia.toLowerCase()} do mês` }}
			</span>
		</p>
	</div>
</template>

<script>
import { posologyTypes } from '@Consts/posologia';

export default {
	name: 'Posologia',
	props: {
		posologia: {
			type: Object,
			required: true
		},
		text_style: {
			type: String,
			default: 'grey--text text--darken-2'
		},
		icon_style: {
			type: String,
			default: ''
		}
	},
	data: () => ({
		posologyTypes
	})
};
</script>

<style scoped lang="scss">
</style>
