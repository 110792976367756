<template>
	<Dialog :titulo="!is_new ? 'Editar Responsável' : 'Novo Responsável'"
		:btn_acao="!is_new ? 'Editar' : 'Salvar'" :btn_acao_disable="!habilitarBtnAcao"
		@acao="submeter()" @close="fecharModal()">
		<v-container fluid grid-list-lg class="px-3 pt-1 pb-0">
			<v-layout align-center row wrap>
				<v-layout row wrap>
					<v-flex xs12 md12>
						<v-autocomplete v-model="responsavel" box color="green" label="Buscar responsável" append-icon="search"
							:loading="resp_loading"
							:item-value="'id'"
							:item-text="'nome'"
							:items="responsaveis"
							:return-object="true"
							:search-input.sync="busca"
							hide-no-data
							hide-details></v-autocomplete>
					</v-flex>
				</v-layout>
				<v-flex xs12 md12>
					<h3>Tipo de responsável:
						<v-tooltip right :max-width="350">
							<template v-slot:activator="{ on }">
								<v-icon color="#3C235C" style="font-size: 18px" class="mr-2" v-on="on">far fa-question-circle</v-icon>
							</template>
							<span class="tooltip-responsaveis"><p class="tooltip-font"><strong>Responsável Faturamento:</strong> para quem a nota fiscal será faturada. É permitido apenas uma pessoa responsável por farmembro.</p>

								<p class="tooltip-font"><strong>Responsável Clínico:</strong> é a pessoa que mais acompanha a saúde do farmembro (ex.: familiar, cuidador ou enfermeiro) é permitido apenas um responsável clínico por farmembro.</p>

								<p class="tooltip-font"><strong>Responsável Financeiro:</strong> é quem faz o pagamento da assinatura e para quem o boleto será direcionado. É permitido apenas um por farmembro.</p>

								<p style="font-weight: 400; font-size: 10px">* O boleto aparecerá conforme dados cadastrados no IUGU.</p> </span>
						</v-tooltip>
					</h3>
				</v-flex>
				<v-layout class="alinha-checkbox" row wrap>
					<v-checkbox v-model="tipo.clinico" label="Clínico"></v-checkbox>
					<v-checkbox v-model="tipo.faturamento" label="Faturamento"></v-checkbox>
					<v-checkbox v-model="tipo.financeiro" label="Financeiro"></v-checkbox>
				</v-layout>
			</v-layout>
			<Form :campos="campos" :item="!tem_responsavel ? item : item = this.responsavel" />
		</v-container>
	</Dialog>
</template>

<script>
import { CRUDMixin } from '@Mixins/crud';
import { ResponsavelPresenter } from '@Presenter/responsavel-presenter';

export default {
	name: 'DialogResponsavelComp',
	mixins: [CRUDMixin],
	data: () => ({
		type: 'responsavel',
		fields_type: 'responsavel',
		responsaveis: [],
		responsavel: {},
		tipo: {
			clinico: false,
			faturamento: false,
			financeiro: false
		},
		busca: null,
		resp_selecionado: null,
		resp_loading: false
	}),
	watch: {
		busca(val) {
			return val && val !== this.resp_selecionado && this.buscaResponsaveis(val);
		}
	},
	methods: {
		buscaResponsaveis(value) {
			this.resp_loading = true;
			ResponsavelPresenter.list({ q: value, itens_por_pagina: 10 })
				.then(response => {
					this.responsaveis = response.itens;
				})
				.finally(() => {
					this.resp_loading = false;
				});
		},
		createData() {
			return {
				...this.item,
				tipo: this.tipo
			};
		},
		salvar(responsavel) {
			this.$emit('responsavel-preenchido', responsavel);
		},
		fecharModal() {
			this.$emit('close', false);
		}
	},
	computed: {
		tem_responsavel() {
			return (this.responsavel || {});
		}
	}
};
</script>

<style lang="scss" scoped>
.alinha-checkbox {
	padding-left: 16px;
	padding-right: 17px;
}

.tooltip-responsaveis {
	padding: 15px;
}

.tooltip-font {
	padding-right: 10px;
	padding-left: 10px;
	text-align: center;
	font-size: 12px;
	font-weight: 700;
}
</style>
