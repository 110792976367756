<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>
					fas fa-pills
				</v-icon>
			</v-avatar>
			<h3>Medicamentos{{ !editavel ? ` (${origem})` : '' }}</h3>
			<v-spacer />
			<v-btn v-if="editavel" depressed small class="green white--text" @click="imprimirRascunho">
				<v-icon small class="mr-2">print</v-icon>
				Imprimir orçamento
			</v-btn>
			<v-btn v-if="editavel" depressed small class="green white--text" @click="dialog_pesquisar_medicamento = true">
				<v-icon small class="mr-2">search</v-icon>
				Consultar
			</v-btn>
			<v-btn v-if="editavel" depressed small class="green white--text" @click="dialog_medicamento = true">
				<v-icon small class="mr-2">add</v-icon>
				Adicionar
			</v-btn>
			<v-btn v-if="!editavel && exibe_aprovar_rascunho" depressed small class="green white--text" @click="dialog_aprovar_alteracao = true">
				<v-icon small class="mr-2">check</v-icon>
				Aprovar Rascunho
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-container fluid grid-list-lg>
				<v-layout wrap>
					<v-flex v-if="medicamentos.length === 0">
						Nenhum medicamento
					</v-flex>
					<template v-else>
						<v-flex v-for="(medicamento, index) in medicamentos" :key="index" class="medicamento" md6>
							<MedicamentoCard
								:disabled="!editavel"
								:permite_vincular_receita="!editavel"
								:receitas="receitas"
								:estoque="estoque_medicamentos"
								:boxId="boxId"
								:assinatura="assinatura"
								:discount_percentage="getDiscountPercentage"
								:quantidadeAproveitamentoReceita="getAproveitamentoReceita(medicamento.id)"
								:medicamento="medicamento"
								:medicamentos="medicamentos"
								:mostrar_excluir="editavel"
								:data_base_pausa="data_base_pausa"
								:prescriptionReadonly="prescriptionReadonly"
								:prescriptionIsVisible="prescriptionIsVisible"
								:fluxo="'recorrencia'"
								:assinaturaId="assinatura ? assinatura.id : null"
								:externalBilling="assinatura ? Boolean(assinatura.cobranca_externa) : false"
								:e_rascunho="eRescunho"
								@pausa-adicionada="$emit('pausa-adicionada')"
								@pausa-removida="$emit('pausa-removida')"
								@pausa-editada="$emit('pausa-editada')"
								@receita-vinculada="$emit('receita-vinculada')"
								@produto-removido="(produto) => { $emit('produto-removido', produto) }"
								@produto-salvo="(produto) => { $emit('produto-editado', produto) }" />
							<v-tooltip top v-if="showCheckItem">
								<v-checkbox slot="activator" v-model="medicamento.conferido" />
								<span>Medicamento conferido</span>
							</v-tooltip>
						</v-flex>
					</template>

				</v-layout>
			</v-container>
		</v-card-text>
		<DialogMedicamento
			:visible="dialog_medicamento"
			:close.sync="dialog_medicamento"
			:medicamento_selecionado="{}"
			:medicamentos="medicamentos"
			:orcamento_filial_id="assinatura ? assinatura.filial_id : null"
			:fluxo="'recorrencia'"
			:discount_percentage="getDiscountPercentage"
			:externalBilling="assinatura ? Boolean(assinatura.cobranca_externa) : false"
			:assinaturaId="assinatura ? assinatura.id : null"
			@medicamento-salvo="onProdutoAdicionado"/>

		<v-dialog persistent v-if="dialog_pesquisar_medicamento" scrollable v-model="dialog_pesquisar_medicamento"
			transition="fade">
			<transition>
				<DialogPesquisarMedicamento :close.sync="dialog_pesquisar_medicamento" :fluxo="'recorrencia'" :assinaturaId="assinatura ? assinatura.id : null"/>
			</transition>
		</v-dialog>

		<v-dialog
			persistent
			scrollable
			v-model="dialog_aprovar_alteracao"
			transition="fade"
			max-width="500px">
			<transition>
				<DialogConfirmacao
					v-if="dialog_aprovar_alteracao"
					@close="dialog_aprovar_alteracao = false"
					:request="aprovarRascunho"
					:opcoes="['Confirmo que validei o rascunho feito pelo atendimento',]"/>
			</transition>
		</v-dialog>
	</v-card>
</template>

<script>
import { isBoxBeforePicking, openBuffer } from '@Util/functions';

import MedicamentoCard from '@Componentes/medicamento/medicamento-card';
import DialogMedicamento from '@Componentes/medicamento/dialog-medicamento';
import DialogConfirmacao from '@Componentes/dialog-confirmacao';
import DialogPesquisarMedicamento from '@Componentes/medicamento/dialog-medicamento/dialog-pesquisar-medicamento';
import { StatusBoxPresenter } from '@Presenter/status-box-presenter';
import { PacientePresenter } from '@Presenter/paciente-presenter';
import moment from 'moment';
import { RascunhoPresenter } from '@Presenter/alteracoes-presenter';

export default {
	name: 'Medicamentos',
	components: {
		MedicamentoCard, DialogMedicamento, DialogPesquisarMedicamento, DialogConfirmacao
	},
	props: {
		editavel: {
			type: Boolean,
			required: false,
			default: false
		},
		assinatura: {
			type: Object,
			required: true
		},
		boxId: {
			required: false,
			type: Number
		},
		medicamentos: {
			type: Array,
			required: true
		},
		medicamentosComAproveitamentoReceita: {
			required: false,
			type: Array
		},
		readOnly: {
			type: Boolean,
			required: false
		},
		showCheckItem: {
			type: Boolean,
			default: false
		},
		receitas: {
			type: Array,
			required: false
		},
		prescriptionReadonly: {
			type: Boolean,
			default: true
		},
		prescriptionIsVisible: {
			type: Boolean,
			default: false
		},
		origem: {
			type: String
		},
		showApproveChanges: {
			type: Boolean,
			default: false
		},
		eRescunho: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		dialog_medicamento: false,
		dialog_pesquisar_medicamento: false,
		dialog_aprovar_alteracao: false,
		carrega_estoque: false,
		estoque_medicamentos: [],
		exibe_aprovar_rascunho: false
	}),
	created() {
		this.exibe_aprovar_rascunho = this.showApproveChanges;
		this.medicamentos.forEach(m => { m.conferido = false; });
		if (this.assinatura) {
			this.carrega_estoque = true;
			PacientePresenter.estoque(this.assinatura.paciente_id, { assinatura_id: this.assinatura.id })
				.then(estoque => {
					this.estoque_medicamentos = estoque;
				})
				.catch(() => {
					this.estoque_medicamentos = [];
				})
				.finally(() => {
					this.carrega_estoque = false;
				});
		}
	},
	methods: {
		onProdutoAdicionado(produto) {
			this.$emit('produto-adicionado', produto);
		},
		getAproveitamentoReceita(medicamentoId) {
			if (!this.medicamentosComAproveitamentoReceita.length)
				return 1; // Default cycles

			const medicamentoComAproveitamentoReceitaEncontrado = this.medicamentosComAproveitamentoReceita
				.find(medicamentoComAproveitamentoReceita => medicamentoComAproveitamentoReceita.medicineId === medicamentoId);

			if (!medicamentoComAproveitamentoReceitaEncontrado)
				return 1;

			return medicamentoComAproveitamentoReceitaEncontrado.totalCycles || 1;
		},
		aprovarRascunho() {
			return StatusBoxPresenter.store({
				box_id: this.boxId,
				status: 'SERVICE_ENDED'
			}).then(() => {
				this.dialog_aprovar_alteracao = false;
				this.exibe_aprovar_rascunho = false;
			}).catch(() => {
				this.exibe_aprovar_rascunho = false;
			}).finally(() => {
				this.$emit('rascunho-aprovado');
				this.dialog_aprovar_alteracao = false;
			});
		},
		async imprimirRascunho() {
			const pdf = await RascunhoPresenter.print(this.boxId);
			openBuffer(pdf);
			return pdf;
		}
	},
	computed: {
		getDiscountPercentage() {
			if (this.assinatura && this.assinatura.paciente && this.assinatura.paciente.casa_repouso)
				return this.assinatura.paciente.casa_repouso.porcentagem_desconto;

			return 0;
		},
		data_base_pausa() {
			let date;
			// verifica se box ja passou pelo picking
			const box = this.assinatura.boxes[0];
			const antesPicking = box && isBoxBeforePicking(box.status);

			if (antesPicking)
				date = moment(box.inicio, 'YYYY-MM-DD');
			else
				date = moment(this.assinatura.data_proxima_box, 'DD/MM/YYYY');

			return date;
		}
	},
	watch: {
		medicamentos(value) {
			value.forEach(i => { i.conferido = false; });
			return value;
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	padding: 0;
}

.v-card {
  ::v-deep .v-card {
    box-shadow: none !important;
  }
}

.medicamento {
	position: relative;

	::v-deep .v-input--checkbox {
		position: absolute;
		top: 0;
		right: 16px;
	}
}
</style>
