<template>
	<header>
		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 md8>
				<h1 class="text--text" style="opacity: .8">Orçamentos B2B</h1>
				<p>Crie e controle os orçamentos da Far.me.</p>
				<v-btn depressed class="primary mx-0 pl-2 pr-3" @click="$emit('criar-orcamento')">
					<v-icon small class="mr-2">add</v-icon>
					Criar orçamento
				</v-btn>
				<v-btn depressed class="primary mx-2 pl-2 pr-3" @click="$emit('gerar-lista-de-compra')" :disabled="habilita_lista_compra">
					Gerar lista de compra
				</v-btn>
				<v-btn v-if="eh_vendedor" depressed class="primary mx-2 pl-2 pr-3" @click="goToContrato">
					<v-icon small class="mr-2">fas fa-file-signature</v-icon>
					Preencher Acordo de Fornecimento
				</v-btn>
			</v-flex>

			<v-flex xs12 md4 align-self-center>
				<v-layout wrap>
					<v-flex>
						<v-text-field label="Busca" append-icon="search" v-model="search"
							color="purple" />
					</v-flex>
					<v-flex v-if="!exibir_ilpi" style="max-width: 200px">
						<v-select
							v-if="eh_vendedor"
							append-icon="filter_list"
							v-model=filtro_externo
							label="Filtros"
							:items="filtros"
							@input="onFiltro" />
						<v-select
							v-else
							append-icon="filter_list"
							v-model=filtro_comum
							label="Filtros"
							:items="filtros"
							@input="onFiltro" />
					</v-flex>
					<v-flex v-if="!eh_vendedor" style="max-width: 200px">
						<v-checkbox v-model="exibir_ilpi" label="Exibir ILPI" @change="v => $emit('input', v)" />
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
export default {
	name: 'OrcamentosHeader',
	props: {
		value: {
			type: Boolean,
			required: true
		},
		habilita_lista_compra: {
			type: Boolean,
			required: false
		}
	},
	data: () => ({
		search: '',
		exibir_ilpi: false,
		filtro_comum: 'UNDER_ANALYSIS',
		filtro_externo: null,
		filtros: [
			{ value: null, text: 'Todos' }
		]
	}),
	watch: {
		exibir_ilpi(value) {
			this.search = '';
			this.$emit('input', value);
		},
		search(value) {
			this.$emit('busca', value);
		}
	},
	created() {
		this.exibir_ilpi = this.value;
		this.filtros.push(...Object.entries(this.$functions.statusOrcamento()).map(([key, { label }]) => ({ value: key, text: label })));
		this.onFiltro(this.eh_vendedor ? this.filtro_externo : this.filtro_comum);
	},
	methods: {
		onFiltro(filtro) {
			this.$emit('filtro', filtro);
		},
		goToContrato() {
			window.open('https://farme.kissflow.com/view/process/Vendas_PDV/form', '_blank');
		}
	},
	computed: {
		eh_vendedor() {
			return this.$store.getters.eh_vendedor;
		}
	}
};
</script>
