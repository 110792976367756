<template>
	<Dialog titulo="Tags" subtitulo="Selecione abaixo as tags que deseja utilizar" btn_acao="Confirmar"
		@acao="action" @close="close">
		<div v-if="loading" class="loading">
			<v-progress-circular indeterminate color="primary" />
		</div>
		<v-container v-else fluid class="pa-0">
			<v-layout wrap>
				<v-flex xs6 class="q-row items-center">
					<h3>Tags</h3>
				</v-flex>
				<v-flex xs6>
					<v-text-field v-model="search" label="Busca" placeholder="Nome da tag" hide-details append-icon="search"
						color="purple" />
				</v-flex>
				<v-flex xs12>
					<v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search"
						no-results-text="Nenhum resultado encontrado" item-key="id"
						@input="arr => $emit('selected-updated', arr)">
						<template slot="items" slot-scope="props">
							<tr :active="props.selected" class="pointer" @click="props.selected = !props.selected">
								<td class="pr-0">
									<v-checkbox :input-value="props.selected" primary hide-details />
								</td>
								<td>{{ props.item.nome }}</td>
								<td>{{ props.item.descricao }}</td>
							</tr>
						</template>
					</v-data-table>
				</v-flex>
				<v-flex xs12 class="selection">
					<v-chip v-for="sel in selected" :key="sel.id" class="purple white--text" close
						@input="remove(sel)">
						{{ sel.nome }}
					</v-chip>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { TagPresenter } from '@Presenter/tag-presenter';

export default {
	name: 'DialogTags',
	props: {
		tags: {
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		loading: true,
		items: [],
		selected: [],
		search: '',
		headers: [
			{
				text: '', value: '', sortable: false, width: 50
			},
			{ text: 'Nome', value: 'nome' },
			{ text: 'Descrição', value: 'descricao' }
		]
	}),
	created() {
		TagPresenter.index()
			.then(response => {
				const ids = this.tags.map(a => a.id);
				this.items = response;
				this.selected = this.items.filter(item => ids.includes(item.id));
			})
			.finally(() => {
				this.loading = false;
			});
	},
	methods: {
		action() {
			this.$emit('novas-tags', this.selected);
			this.close();
		},
		remove({ id }) {
			const i = this.selected.findIndex(sel => sel.id === id);
			this.selected.splice(i, 1);
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>

<style lang="scss" scoped>
.selection {
	min-height: 40px;
}
</style>
