<template>
	<CardLogin>
		<div class="flex-box">
			<v-container fluid class="pa-0">
				<div :class="{'text-center': cadastrando}" class="mb-3 mt-3">
					<h1 class="text--text mb-5">Cadastre seu e-mail!</h1>

					<v-layout class="mb-3" wrap>
						<v-flex xs12 class="py-0">
							<v-text-field  v-model="usuario.nome" color="green_strong" label="Nome completo *"
								placeholder="Seu nome" @keyup.enter="cadastrar" data-vv-name="nome completo"
								v-validate="{ required: true }" :error-messages="errors.collect('nome completo')" />
						</v-flex><br>
						<v-flex xs12 class="py-0">
							<v-text-field  v-model="usuario.email" color="green_strong" label="E-mail *"
								placeholder="seu@email.com" @keyup.enter="cadastrar" data-vv-name="e-mail" v-validate="{ required: true, email: true, regex: /^.*@farme.com.br$/ }"
								:error-messages="errors.collect('e-mail')" />
						</v-flex><br>
						<v-flex xs12 md6 class="py-0">
							<v-text-field  v-model="usuario.password" type="password" color="green_strong" label="Senha *"
								placeholder="********" @keyup.enter="cadastrar" data-vv-name="senha" v-validate="{ required: true, min: 10, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{10,}$/ }"
								:error-messages="errors.collect('senha')" />
						</v-flex>
						<v-flex xs12 md6 class="py-0 pl-2">
							<v-text-field v-model="usuario.password_confirmation" type="password" color="green_strong"
								label="Confirmar senha *" placeholder="********" @keyup.enter="cadastrar"
								data-vv-name="confirmar_senha" v-validate="{ required: true, min: 10, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{10,}$/ }"
								:error-messages="errors.collect('confirmar_senha')" />
						</v-flex>

					</v-layout>

					<span class="flex-buttons ">
						<v-btn depressed :block="$vuetify.breakpoint.xsOnly" class="ml-0 green_strong white--text"
							:style="cadastrando ? null : 'padding: 0 67px'" @click="cadastrar" :loading="cadastrando"
							:icon="cadastrando">
							Cadastrar
						</v-btn>
						<v-btn v-if="!cadastrando" outline :block="$vuetify.breakpoint.xsOnly" @click="entrar"
							class="px-4 green green_strong--text"
							style="border: 0px">
							Entrar
						</v-btn>
					</span>

					<br>
					<br>
					<span>* Campos obrigatórios</span>
				</div>
			</v-container>

			<v-dialog persistent scrollable v-model="dialog_termos_uso" transition="fade" max-width="1100px">
				<transition>
					<DialogTermosUso v-if="dialog_termos_uso" :close.sync="dialog_termos_uso"
						@abrir-politica-privacidade="dialog_politica_privacidade = true" />
				</transition>
			</v-dialog>

			<v-dialog persistent scrollable v-model="dialog_politica_privacidade" transition="fade"
				max-width="1100px">
				<transition>
					<DialogPoliticaPrivacidade v-if="dialog_politica_privacidade" :close.sync="dialog_politica_privacidade"
						@abrir-termos-uso="dialog_termos_uso = true" />
				</transition>
			</v-dialog>
		</div>
	</CardLogin>

</template>

<script>
import logo from '@Assets/images/FarmeOS.svg';
import { UserPresenter } from '@Presenter/user-presenter';
import { unidades } from '@Config/unidades';
import CardLogin from './card-login.vue';
import DialogTermosUso from './dialog-termos-uso';
import DialogPoliticaPrivacidade from './dialog-politica-privacidade';

export default {
	name: 'Cadastrar',
	$_veeValidate: { validator: 'new' },
	components: { DialogTermosUso, DialogPoliticaPrivacidade, CardLogin },
	data: () => ({
		logo,
		unidades: [],
		usuario: {},
		cadastrando: false
	}),
	created() {
		this.unidades = unidades;
	},
	methods: {
		entrar() {
			window.location.href = '/entrar';
		},
		cadastrar() {
			this.$validator.validateAll().then(valido => {
				if (!valido) {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
					return;
				}

				if (this.usuario.password !== this.usuario.password_confirmation) {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'As senhas não são conferem' });
					return;
				}
				this.cadastrando = true;

				const data = this.usuario;

				UserPresenter.register(data)
					.then(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Cadastro realizado com sucesso! Por favor, verifique seu email e faça o login.' });
						this.$router.push({ name: 'Entrar' });
					})
					.catch(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao cadastrar usuário.' });
					})
					.finally(() => {
						this.cadastrando = false;
					});
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.flex-box {
  max-width: 300px !important;

}
</style>
