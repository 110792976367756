export const departments = [
	{
		value: 'ANY',
		text: 'Todos'
	},
	{
		value: 'PICKING',
		text: 'Picking',
		step: ['PICKING']
	},
	{
		value: 'BILLING',
		text: 'Faturamento',
		step: ['PICKED']
	},
	{
		value: 'PRODUCTION',
		text: 'Produção',
		step: ['BILLED', 'IN_PRODUCTION']
	},
	{
		value: 'LOGISTICS',
		text: 'Logística',
		step: ['DESPATCHED', 'DELAYED']
	},
	{
		value: 'PRESCRIPTION',
		text: 'Prescrição',
		step: ['WAITING', 'SERVICE_ENDED', 'SERVICE_STARTED', 'PURCHASED']
	}
];

export const departmentsMap = departments.reduce((acc, department) => {
	acc[department.value] = department.text;
	return acc;
}, {});
