<template>
	<v-container fluid grid-list-md class="pa-0">
		<h3 class="mt-3 mb-4">Desconto para todos os residentes</h3>

		<v-layout wrap>
			<v-flex xs12>
				<v-text-field
					:disabled="!allowEditDiscountPercentage"
					aria-disabled="!allowEditDiscountPercentage"
					type="number"
					background-color="white"
					v-model="discount.maxDiscountPercentage"
					box
					label="Percentual máximo de desconto"
					data-vv-name="Percentual máximo de desconto"
					v-validate="{ required: true, gte: 0, lte: 100 }"
					append-icon="percent"
					:error-messages="errors.collect('Percentual máximo de desconto')"/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import store from '@Store/index';

export default {
	name: 'RestHomeGeneralDataDiscount',
	inject: ['$validator'],
	props: {
		discount: {
			required: true,
			type: Object
		}
	},
	computed: {
		allowEditDiscountPercentage() {
			return !!store.getters.allow_edit_discount_ilpi;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
