<template>
	<header>
		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 md8>
				<h1 class="text--text" style="opacity: .8">Orçamentos externos</h1>
				<p>Aqui estão listados os orçamentos criados no sistema externo de orçamento.</p>
			</v-flex>

			<v-flex xs12 md4 align-self-center>
				<v-layout wrap>
					<v-flex>
						<v-text-field label="Buscar orçamento" placeholder="Nome da pessoa" append-icon="search"
							color="purple" @input="value => $emit('busca', value)" />
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
export default {
	name: 'OrcamentosExternosHeader'
};
</script>
