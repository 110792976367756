<template>
	<header>
		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 md8>
				<h1 class="text--text" style="opacity: .8">GTM</h1>
				<p class="mb-0">Utilize a busca aos lado para encontrar os pacientes.</p>
				<p>Você pode buscar pelo id ou nome do paciente.</p>
				<v-btn v-if="$store.getters.eh_administrador || $store.getters.eh_clinica" depressed class="primary ml-0 pl-2 pr-3" @click="$emit('nova-assinatura')">
					<v-icon small class="mr-2">add</v-icon>
					Novo paciente
				</v-btn>
			</v-flex>

			<v-flex xs12 md4 align-self-center>
				<v-layout wrap>
					<v-flex>
						<v-text-field label="Buscar paciente" placeholder="ID ou Paciente"
							append-icon="search" color="purple" @input="value => $emit('busca', value)" />
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
export default {
	name: 'ClinicaGTMHeader'
};
</script>
