<template>
	<v-layout wrap class="estatisticas">
		<v-flex xs12 md3 v-for="(componente, index) in componentes" :key="index">
			<v-card>
				<v-card-text class="text-xs-center">
					<div v-if="componente.loading" class="carregando">
						<v-progress-circular indeterminate color="grey" />
					</div>
					<div v-else>
						<div class="icon-wrapper" :class="componente.color">
							<v-icon> {{ componente.icon }} </v-icon>
						</div>
						<h3>{{ formataNumero(componente.value) }}</h3>
						<h4>{{ componente.title }}</h4>
					</div>
				</v-card-text>
			</v-card>
		</v-flex>
	</v-layout>
</template>

<script>
import { UserPresenter } from '@Presenter/user-presenter';
import { PacientePresenter } from '@Presenter/paciente-presenter';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { BoxPresenter } from '@Presenter/box-presenter';

export default {
	name: 'Estatisticas',
	data: () => ({
		componentes: {
			usuarios: {
				color: 'primary',
				icon: 'fas fa-user',
				title: 'Usuários',
				value: 0,
				loading: true
			},
			pacientes: {
				color: 'primary',
				icon: 'fas fa-user-injured',
				title: 'Pacientes ativos',
				value: 0,
				loading: true
			},
			assinaturas: {
				color: 'primary',
				icon: 'fas fa-credit-card',
				title: 'Assinaturas ativas',
				value: 0,
				loading: true
			},
			boxes: {
				color: 'primary',
				icon: 'fas fa-box-open',
				title: 'Boxes produzidas',
				value: 0,
				loading: true
			}
		}
	}),
	created() {
		UserPresenter.count()
			.then(({ count }) => {
				this.componentes.usuarios.value = count;
				this.componentes.usuarios.loading = false;
			});
		PacientePresenter.count()
			.then(({ count }) => {
				this.componentes.pacientes.value = count;
				this.componentes.pacientes.loading = false;
			});
		AssinaturaPresenter.count({})
			.then(({ count }) => {
				this.componentes.assinaturas.value = count;
				this.componentes.assinaturas.loading = false;
			});
		BoxPresenter.count()
			.then(({ count }) => {
				this.componentes.boxes.value = count;
				this.componentes.boxes.loading = false;
			});
	},
	methods: {
		formataNumero(valor) {
			if (valor >= 1000 && valor < 1000000)
				return `${(valor / 1000).toFixed(1)}k`;
			if (valor >= 1000000)
				return `${(valor / 1000000).toFixed(1)} mi`;
			return valor;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.estatisticas {
  .carregando {
    height: 187px !important;
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin: 10px auto;

    &.primary {
      background: rgba($primary, 0.2) !important;

      .v-icon {
        color: $primary !important;
      }
    }

    .v-icon {
      font-size: 18px;
    }
  }

  h3 {
    color: $text;
    font-size: 64px;
    line-height: 80px;
  }

  h4 {
    color: grey;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
  }
}
</style>
