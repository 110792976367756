<template>
	<header>
		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 md9>
				<h1 class="text--text" style="opacity: .8">Moléculas</h1>
				<p>Utilize a busca ao lado para encontrar os princípios ativos.</p>
				<v-btn depressed class="primary ml-md-0 pl-2 pr-3" @click="$emit('nova-molecula')">
					<v-icon small class="mr-2">add</v-icon>
					Nova molécula
				</v-btn>
			</v-flex>
			<v-flex xs12 md3 align-self-center>
				<v-text-field label="Buscar molécula" placeholder="Nome da molécula"
					append-icon="search" color="purple" @input="value => $emit('busca', value)" />
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
export default {
	name: 'MoleculaHeader'
};
</script>
