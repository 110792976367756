import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

const num_columns = 5; // Número de colunas

const getColorEtiqueta = etiqueta => ({
	color: etiqueta.COR_LETRA_RGB,
	fillColor: etiqueta.COR_FUNDO_RGB
});

const getWidthConfig = () => {
	const arr = [];
	for (let i = 0; i < num_columns; i += 1)
		arr.push('*');
	return arr;
};

const getNome = (_nome, corte) => {
	let nome = _nome;
	if (nome.length > corte)
		nome = `${nome.slice(0, corte)}...`;
	return nome.toUpperCase();
};

const rowMedicamentos = etiqueta => {
	let medicamentos_text = '';
	Object.keys(etiqueta).forEach((key, i) => {
		if (!etiqueta[`NOME_MEDICAMENTO_${i + 1}`])
			return;
		medicamentos_text += `${etiqueta[`NOME_MEDICAMENTO_${i + 1}`]}\n`;
	});
	return [{ text: medicamentos_text, fontSize: 7, margin: [3, 0, 0, 0] }];
};

export const geraPdfEtiquetasAntigas = etiquetasDoSaquinho => {
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	const main_body = [];
	let line = [];

	etiquetasDoSaquinho.filter(e => e.INICIO === 'NÃO').forEach(etiqueta => {
		const data = moment(etiqueta.DATA, 'DD/MM/YYYY');
		const str_horario = etiqueta.HORA;
		const cell = [
			[{ text: `${getNome(etiqueta.NOME_PACIENTE, 16)}`, alignment: 'center', style: 'nome' }],
			[{
				text: str_horario, alignment: 'center', bold: true, fontSize: str_horario.length > 10 ? 10 : 14, ...getColorEtiqueta(etiqueta)
			}],
			[{
				text: `${data.format('dddd, DD [de] MMMM')}`.replace('-feira', ''), alignment: 'center', style: 'data', fontSize: 10, ...getColorEtiqueta(etiqueta)
			}],
			rowMedicamentos(etiqueta)
		];

		line.push({
			table: {
				widths: ['*'],
				heights(row) {
					return row === 1 ? 15 : 1;
				},
				body: cell
			},
			layout: {
				hLineWidth() { return 0; },
				vLineWidth() { return 0; },
				paddingLeft(i) { return i !== 3 ? 0 : 6; },
				paddingRight(i) { return i !== 3 ? 0 : 6; },
				paddingTop(i) { return i !== 3 ? 0 : 6; },
				paddingBottom(i) { return i !== 3 ? 0 : 6; }
			}
		});
		if (line.length === num_columns) {
			main_body.push(line);
			line = [];
		}
	});

	if (line.length > 0) {
		const n_iterations = num_columns - line.length;
		for (let i = 0; i < n_iterations; i += 1)
			line.push([]);
		main_body.push(line);
	}

	const content = [
		{
			style: 'tableExample',
			table: {
				dontBreakRows: true,
				widths: getWidthConfig(),
				heights: 110,
				body: main_body
			},
			layout: {
				paddingLeft: () => 0,
				paddingRight: () => 0,
				paddingTop: () => 0,
				paddingBottom: () => 0
			}
		}
	];

	const config = {
		pageSize: 'LETTER',
		pageMargins: 0,
		content,
		styles: {
			nome: {
				bold: true,
				fontSize: 10
			},
			data: {
				bold: true,
				fontSize: 7
			},
			hora: {
				bold: true
			}
		}
	};

	return pdfMake.createPdf(config).open();
};
