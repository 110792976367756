<template>
	<v-dialog
		persistent
		scrollable
		v-model="opened"
		max-width="540">
		<template v-slot:activator="{ on }">
			<v-btn
				color="secondary"
				outline
				v-on="on"
				style="border-radius: 10px;">
				Inserir comentário
			</v-btn>
		</template>

		<section class="farme-dialog">
			<section class="farme-dialog-header">
				Inserir comentário
			</section>
			<section class="farme-dialog-body">
				<v-textarea
					box
					hide-details
					background-color="white"
					v-model="comment"
					label="Comentário"/>
			</section>

			<section class="farme-dialog-footer">
				<v-btn
					color="green darken-1"
					flat="flat"
					@click="opened = false">
					Cancelar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					@click="submit">
					Salvar comentário
				</v-btn>
			</section>
		</section>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			opened: false,
			comment: null
		};
	},
	methods: {
		submit() {
			this.$emit('save-comment', this.comment);
			this.comment = null;
			this.opened = false;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
