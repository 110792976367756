<template>
	<div class="dashboard-wrapper px-md-5">
		<header class="mb-4">
			<h1 class="text--text" style="opacity: .8">Dashboard</h1>
		</header>

		<v-container fluid grid-list-md class="pa-0 pb-4">
			<Estatisticas />

			<v-layout wrap>
				<v-flex xs12 md6>
					<Orcamentos />
				</v-flex>
				<v-flex xs12 md6>
					<Aniversarios />
				</v-flex>
				<v-flex xs12 md6>
					<NovosCadastros />
				</v-flex>
				<v-flex xs12 md6>
					<NovasAssinaturas />
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
import Estatisticas from './estatisticas';
import Orcamentos from './orcamentos';
import Aniversarios from './aniversarios';
import NovasAssinaturas from './novas-assinaturas';
import NovosCadastros from './novos-cadastros';

export default {
	name: 'Dashboard',
	components: {
		Estatisticas, Orcamentos, Aniversarios, NovasAssinaturas, NovosCadastros
	},
	data: () => ({
	})
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

::v-deep .carregando {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 100%;
}
</style>
