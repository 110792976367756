<template>
	<v-layout wrap>
		<v-flex v-if="isClientData" xs12 class="d-flex items-center justify-start">
			<span class="inter text-bold body-1">Dados pessoais</span>
		</v-flex>

		<v-flex md6 xs12>
			<v-text-field v-model="quotation.customer.name" box label="Nome Completo *" background-color="white"
				v-validate="{ required: true }" data-vv-name="Nome completo" :error-messages="errors.collect('Nome completo')" />
		</v-flex>
		<v-flex md6 xs12 v-if="isClientData">
			<v-autocomplete
				v-model="quotation.customer.gender"
				:items="genders"
				hide-details
				label="Gênero *"
				box
				clearable
				v-validate="{ required: true }"
				:error-messages="errors.collect('Genêro')"
				data-vv-name="Genêro"
				background-color="white"
				item-value="id" item-text="gender" />
		</v-flex>
		<v-flex md6 xs12>
			<v-text-field v-model="quotation.customer.cpf" box :label="`CPF ${isGeneralData ? '' : '*' }`"
				mask="###.###.###-##" data-vv-name="CPF" :error-messages="errors.collect('CPF')" background-color="white"
				v-validate="{ cpf: true, required: isGeneralData ? false : true }" />
		</v-flex>
		<v-flex md6 xs12 v-if="isClientData">
			<TextDateField v-model="quotation.customer.birthdate" box background-color="white" label="Data de nascimento *"
				placeholder="dd/mm/yyyy" data-vv-name="Data de nascimento" :error-messages="errors.collect('Data de nascimento')" v-validate="{ required: true }"/>
		</v-flex>
		<v-flex md6 xs12>
			<v-text-field v-model="quotation.customer.phone" box v-mask="'(##) #####-####' || '(##) ####-####'" data-vv-name="Telefone"
				label="Telefone *" :error-messages="errors.collect('Telefone')"  v-validate="{ required: true, telefone: true }" background-color="white"/>
		</v-flex>
		<v-flex md6 xs12>
			<v-text-field v-model="quotation.customer.email" box :label="`E-mail ${isGeneralData ? '' : '*' }`"
				data-vv-name="E-mail"
				:error-messages="errors.collect('E-mail')"
				background-color="white"
				v-validate="{ required: true, required: isGeneralData ? false : true }"  />
		</v-flex>
	</v-layout>
</template>

<script>
import { stepsQuotation, genders } from '@Consts/quotation-v2';
import Mixin from '../mixin';

export default {
	name: 'PersonInfo',
	mixins: [Mixin],
	$_veeValidate: { validator: 'new' },
	props: {
		tabSelected: {
			type: String
		}
	},
	data: () => ({
		stepsQuotation,
		genders
	}),
	methods: {
		async validate() {
			return Boolean(await this.$validator.validateAll());
		}
	},
	computed: {
		isClientData() {
			return this.tabSelected === stepsQuotation.clientData;
		},
		isGeneralData() {
			return this.tabSelected === stepsQuotation.generalData;
		}
	}
};
</script>
