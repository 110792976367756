<template>
	<v-card style="width: 100%;">
		<v-card-title class="pb-0">
			<v-flex xs12 md6 style="display: flex; flex-direction: row; align-items: center">
				<v-avatar size="38" class="mr-3">
					<v-icon color="#342B1D" small>fa-dollar-sign</v-icon>
				</v-avatar>
				<h4>Cobrança</h4>
			</v-flex>
			<v-spacer />
		</v-card-title>
		<v-card-text>
			<v-data-table disable-initial-sort :headers="headers" :items="itemsFormatted"
				:pagination.sync="pagination" no-data-text="Não há histórico">
				<template v-slot:items="props">
					<tr>
						<td>{{ props.item.method }}</td>
						<td v-if="props.item.installments > 1">{{ props.item.installments }}x de {{ props.item.installmentsAmount | dinheiro }}</td>
						<td v-else>{{ props.item.amount }}</td>
						<td class="text-xs-center">{{ props.item.status }}</td>
					</tr>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { statusChargeLabel } from '@Consts/invoices';
import { getPaymentMethodDescription } from '@Consts/payment-methods';
import { mascaraDinheiro } from '@Util/functions';

export default {
	name: 'History',
	props: {
		items: {
			required: false,
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		headers: [
			{ text: 'Forma de pagamento', value: 'method', class: '' },
			{ text: 'Pagamento', value: 'amount' },
			{ text: 'Status', value: 'status', align: 'center' }
		],
		pagination: { descending: true, sortBy: '' }
	}),
	computed: {
		itemsFormatted() {
			return this.items.map(item => ({
				...item,
				method: getPaymentMethodDescription(item.method || ''),
				status: statusChargeLabel[item.status],
				amount: mascaraDinheiro(item.amount)
			}));
		}
	}
};
</script>
