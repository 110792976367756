<template>
	<Dialog titulo="Gerar planilha" btn_cancelar="Cancelar" @acao="gerarPlanilha" @close="fecharModal"
		:loading="gerando" btn_acao="Gerar" :btn_acao_disable="!aceito">
		<h4>Avisos importantes:</h4>
		<ul class="error--text">
			<li>Não crie ou apague linhas da planilha.</li>
			<li>Não altere o ID do medicamento.</li>
			<li>Não altere o cabeçalho da planilha.</li>
			<li>Não altere o nome da aba da planilha.</li>
			<li>Não altere o nome da aba da planilha.</li>
			<li>Os preços devem estar, obrigatoriamente, em centavos.<br>Ou seja, R$57,90 deverá ser
				5790.</li>
			<li>Medicamentos líquidos devem ter valor 1. 0 (zero) caso contrário.</li>
			<li>Medicamentos que irão fora de box devem ter valor 1. 0 (zero) caso contrário.</li>
		</ul>
		<p class="mt-3">Quaisquer alterações realizadas de forma incorreta irá deixar todos os
			medicamentos
			inconsistentes e incompatíveis com o sistema.</p>
		<p class="mb-0">A MedYes não se responsabiliza por alterações incorretas na planilha.</p>
		<v-checkbox v-model="aceito" hide-details label="Li e concordo com os avisos acima." />
	</Dialog>
</template>

<script>
import { MedicamentoPresenter } from '@Presenter/medicamento-presenter';
import header from './header-planilha';

export default {
	name: 'DialogGerarPlanilha',
	data: () => ({
		gerando: false,
		aceito: false
	}),
	methods: {
		gerarPlanilha() {
			this.gerando = true;
			const table = [];
			MedicamentoPresenter.index(header)
				.then(medicamentos => {
					medicamentos.forEach(med => {
						table.push(med);
					});
					import('./geraExcel').then(geraExcel => {
						geraExcel.default(table, header);
					});
				})
				.finally(() => {
					this.gerando = false;
					this.fecharModal();
				});
		},
		fecharModal() {
			this.$emit('update:close', false);
		}
	}
};
</script>
