<template>
	<Dialog
		titulo="Baixar termos de vários residentes"
		:subtitulo="casa_repouso.nome"
		class="dialog-box"
		@close="$emit('close')">

		<div class="space-from-left">
			<v-flex xs12 md6 align-self-center>
				<v-layout wrap>
					<v-flex>
						<v-text-field
							label="Pesquise o residente"
							append-icon="search"
							v-model="busca"
							class="ml-8"
							color="purple"
							placeholder="Digite o ID ou nome"/>
					</v-flex>
				</v-layout>
			</v-flex>
		</div>

		<DataTableIlpiQuotationBatch
			ref="DataTableIlpiQuotationBatch"
			:items="orcamentos"
			:loading="carregando"
			:search="busca"
			:error="error"
			@refresh="getQuotations"
			@selected-updated="arr => selecionados = arr"/>

		<div class="btn-spaced" v-if="hasQuotations">
			<v-btn
				depressed
				absolute
				right
				@click.stop="printB2BMedicinesRelation()"
				:disabled="gerando_termo"
				:loading="gerando_termo"
				class="primary">
				<v-icon class="mr-2" color="green">fas fa-file-pdf</v-icon>
				Imprimir termos e relação
			</v-btn>
		</div>
	</Dialog>
</template>

<script>
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import { allQuotationStatus } from '@Consts/quotation-v2';
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';
import { openBuffer } from '@Util/functions';
import DataTableIlpiQuotationBatch from './data-table-ilpi-quotation-batch.vue';

export default {
	name: 'DialogILPI',
	components: { DataTableIlpiQuotationBatch },
	props: {
		casa_repouso: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		orcamentos: [],
		selecionados: [],
		busca: '',
		error: false,
		carregando: true,
		gerando_termo: false
	}),
	created() {
		this.getQuotations();
	},
	computed: {
		hasQuotations() {
			return this.orcamentos.length > 0;
		}
	},
	methods: {
		getQuotations() {
			this.carregando = true;
			CasaRepousoPresenter.orcamentos(this.casa_repouso.id)
				.then(orcamentos => {
					this.orcamentos = orcamentos.filter(
						orcamento => orcamento.status !== allQuotationStatus.DONE.value
							&& orcamento.status !== allQuotationStatus.CANCELED.value
					);
				})
				.catch(() => {
					this.error = true;
				})
				.finally(() => {
					this.carregando = false;
				});
		},
		async printB2BMedicinesRelation() {
			try {
				this.gerando_termo = true;
				const pdf = await OrcamentoPresenter.relacaoDeMedicamentosB2B(
					{
						rest_home_id: this.casa_repouso.id,
						quotation_ids: this.selecionados.map(item => item.id)
					}
				);
				openBuffer(pdf);
				return pdf;
			} catch (err) {
				throw new Error(err);
			} finally {
				this.gerando_termo = false;
			}
		}
	}
};
</script>
<style lang="scss" scoped>

.btn-spaced{
	height: 60px;
	padding-top: 20px;
}

.space-from-left{
	margin-left: 24px;
}
</style>
