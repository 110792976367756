<template>
	<Dialog
		titulo="Cadastrar Condição associada"
		@acao="save()"
		btn_acao="Salvar"
		@close="$emit('close')"
		background="rgba(255, 234, 204, 1)"
		backgroundFooter="rgba(255, 234, 204, 1)">
		<v-container>
			<v-layout wrap>
				<v-flex xs12>
					<v-text-field
						v-model="form.condition"
						background-color="white"
						box
						label="Condição"
						v-validate="{ required: true }"
						data-vv-name="condition"
						:error-messages="errors.collect('condition')"/>
				</v-flex>
			</v-layout>
		</v-container>

		<FarmeDivider />
	</Dialog>
</template>

<script>
import { AssociatedConditionPresenter } from '@Presenter/associated-condition-presenter';
import FarmeDivider from '@Componentes/farme-divider.vue';

export default {
	name: 'DialogCreateAssociateCondition',
	$_veeValidate: { validator: 'new' },
	components: {
		FarmeDivider
	},
	data: () => ({
		loading: true,
		form: {}
	}),
	methods: {
		async save() {
			const formIsValidated = await this.$validator.validateAll();

			if (!formIsValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário inválido',
					color: 'error'
				});
				return;
			}

			try {
				await AssociatedConditionPresenter.create({
					condition: this.form.condition
				});

				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Condição associada cadastrado com sucesso',
					color: 'success'
				});

				this.$emit('close');
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao criar Condição associada',
					color: 'error'
				});
				throw new Error(error);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
