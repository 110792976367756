import { render, staticRenderFns } from "./motivo-pausa.vue?vue&type=template&id=93b3d39a&scoped=true"
import script from "./motivo-pausa.vue?vue&type=script&lang=js"
export * from "./motivo-pausa.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93b3d39a",
  null
  
)

export default component.exports