<template>
	<Dialog
		titulo="Remover métodos de pagamento"
		subtitulo="Tem certeza que deseja remover os métodos de pagamento desta assinatura?"
		btn_acao="Descadastrar"
		btn_acao_class="error"
		btn_cancelar="Cancelar"
		@acao="deleteFormaDePagamento()"
		@close="fecharModal()" >
	</Dialog>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';

export default {
	name: 'DialogDescadastrarPagamento',
	props: ['assinatura_iugu_id'],
	data: () => ({
		loading: false,
		error: false
	}),
	methods: {
		deleteFormaDePagamento() {
			if (!this.assinatura_iugu_id) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Paciente sem cadastro no Iugu' });
				this.fecharModal();
				return;
			}

			AssinaturaPresenter.removerFormasDePagamento(this.assinatura_iugu_id)
				.then(() => this.$store.dispatch('SHOW_SNACKBAR', { message: 'Todas as formas de pagamento foram removidas' }))
				.catch(() => {
					this.error = true;
				})
				.finally(() => {
					this.loading = false;
					this.fecharModal();
				});
		},
		fecharModal() {
			this.$emit('update:close', false);
		}

	}
};
</script>

<style lang="scss" scoped>

</style>
