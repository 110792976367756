<template>
	<header>
		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 md8>
				<h1 class="text--text" style="opacity: .8">Configurações de calendário</h1>
				<p>Aqui estão listadas todas as configurações que afetam o calendário da Far.me</p>
				<v-btn v-if="$store.getters.eh_administrador || $store.getters.eh_rt" depressed class="primary ml-0 pl-2 pr-3" @click="$emit('nova-configuracao')">
					<v-icon small class="mr-2">add</v-icon>
					Nova configuração
				</v-btn>
			</v-flex>

			<v-flex xs12 md4 align-self-center>
				<v-layout wrap justify-end>
					<v-flex xs8>
						<v-select append-icon="filter_list" v-model="filtro" label="Unidade"
							:items="filtros" @input="onInputFiltro" />
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
import { companyUnits } from '@Consts/unidades';

export default {
	name: 'ConfiguracaoHeader',
	data: () => ({
		filtro: 'ANY',
		filtros: Object.entries(companyUnits).map(([value, text]) => ({ value, text }))
	}),
	created() {
		this.onInputFiltro(this.filtro);
	},
	methods: {
		onInputFiltro(value) {
			this.$emit('filtro', value);
		}
	}
};
</script>
