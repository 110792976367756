<template>
	<div class="pa-0">
		<v-container v-if="loading" fluid>
			<v-flex class="loading text-center">
				<v-progress-circular :size="70" indeterminate color="primary" />
			</v-flex>
		</v-container>
		<v-container v-else fluid>
			<v-layout justify-center column>
				<template v-if="pendencias <= 0">
					<v-flex xs12 class="text-center pt-5 grey--text" style="min-height: 200px">
						<v-icon small class="mr-2">fas fa-clipboard-list</v-icon>Sem pendências em aberto.
					</v-flex>
				</template>
				<template v-else>
					<v-expansion-panel class="my-2">
						<v-expansion-panel-content focusable>
							<template v-slot:header>
								<div class="title-panel" style="color: #F7BA2A"><v-icon color="#F7BA2A" class="mr-2">fas fa-clipboard-list</v-icon>Em aberto</div>
							</template>
							<v-expansion-panel>
								<v-expansion-panel-content
									v-for="(item, i) in pendenciasEmAberto"
									:key="i">
									<template v-slot:header>
										<div>
											<v-layout row>
												<v-flex xs12 md10 align-self-center>
													<span class="id-pendencia">{{ i+1 }}</span>
													<span class="categoria-pendencia">
														{{ item.categoria }} |
													</span>
													<ul class="ma-0">
														<li v-for="(descricao, index) in descricaoPendencia(item)" :key="index">
															<div v-html="buildDescription(descricao)"></div>
														</li>
													</ul>
												</v-flex>
												<v-flex xs2 md2 align-self-center>
													<v-tooltip top :max-width="300">
														<template v-slot:activator="{ on }">
															<v-btn icon ripple class="ma-0">
																<v-icon color="#929292" v-on="on">info</v-icon>
															</v-btn>
														</template>
														<span>
															Solicitado por: {{ item.openedBy.nome }} <br>
															em: {{ formatDate(item.created_at) }}
														</span>
													</v-tooltip>
													<v-btn class="ma-0" @click="pendenciaConcluida(item)" icon ripple>
														<v-icon color="#929292">check_circle_outline</v-icon>
													</v-btn>
												</v-flex>
											</v-layout>
										</div>
									</template>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panel-content>
					</v-expansion-panel>

					<v-expansion-panel class="my-2">
						<v-expansion-panel-content focusable>
							<template v-slot:header>
								<div class="title-panel" style="color: #13CE66"><v-icon color="#13CE66" class="mr-2">fas fa-clipboard-list</v-icon>Concluídas</div>
							</template>
							<v-expansion-panel>
								<v-expansion-panel-content
									v-for="(item, j) in pendenciasConcluidas"
									:key="j">
									<template v-slot:header>
										<div>
											<v-layout row>
												<v-flex xs12 md10 align-self-center>
													<span class="id-pendencia">{{ j+1 }}</span> <span class="categoria-pendencia">{{ item.categoria }} | </span> {{ item.descricao }}
												</v-flex>
												<v-flex xs3 md2 align-self-center>
													<v-btn @click="abrirHistorico(item)" class="ma-0" icon ripple>
														<v-icon color="#929292">history</v-icon>
													</v-btn>
													<v-tooltip top :max-width="300">
														<template v-slot:activator="{ on }">
															<v-btn icon ripple class="ma-0">
																<v-icon color="#929292" v-on="on">info</v-icon>
															</v-btn>
														</template>
														<span>
															Concluido por: {{ item.concludedBy.nome }} <br>
															em: {{ formatDate(item.concluded_at) }}
														</span>
													</v-tooltip>
												</v-flex>
											</v-layout>
										</div>
									</template>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</template>
				<v-layout align-center justify-center row>
					<v-flex xs4 class="text-center">
						<v-btn @click="dialog_nova_pendencia = true" :disabled="readOnly" color="green">
							<v-icon small class="mr-2">add</v-icon>Nova pendência
						</v-btn>
					</v-flex>
				</v-layout>
			</v-layout>
		</v-container>

		<v-dialog persistent scrollable v-model="dialog_nova_pendencia" transition="fade" max-width="600px">
			<transition>
				<NovaPendencia v-if="dialog_nova_pendencia" @close="dialog_nova_pendencia = false" @acao="enviarPendencia" :loading="enviando" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_pendencia_concluida" transition="fade" max-width="600px">
			<transition>
				<PendenciasConcluidas v-if="dialog_pendencia_concluida" @close="dialog_pendencia_concluida = false" @concluir="concluirTodasPendencias(item)" :loading="concluidas" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_historico_pendencia" transition="fade" max-width="600px">
			<transition>
				<HistoricoPendencia v-if="dialog_historico_pendencia" :pendencia="historicoPendencia" @close="dialog_historico_pendencia = false" />
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { PendenciaPresenter } from '@Presenter/pendencia-presenter';
import $store from '@Store';
import moment from 'moment';
import NovaPendencia from './nova-pendencia.vue';
import PendenciasConcluidas from './pendencias-concluidas.vue';
import HistoricoPendencia from './historico-pendencia.vue';

export default {
	name: 'ListaPendencias',
	components: { NovaPendencia, PendenciasConcluidas, HistoricoPendencia },
	props: {
		box_id: {
			type: Number,
			required: true
		},
		item: {
			type: Object,
			required: true
		},
		readOnly: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		loading: false,
		enviando: false,
		concluindoPendencia: false,
		semPendencias: false,
		emAberto: false,
		concluidas: false,
		dialog_nova_pendencia: false,
		dialog_pendencia_concluida: false,
		dialog_historico_pendencia: false,
		enviaResposta: false,
		editandoResposta: false,
		isEditing: false,
		panel: [false],
		pendencias: [],
		historicoPendencia: {},
		response: '',
		rt: 'RT',
		atendimento: 'ATENDIMENTO'
	}),
	created() {
		this.getPendencias(this.box_id);
	},
	computed: {
		btnConcluirDisabled() {
			return this.pendencias.some(item => item.status === 'OPENED');
		},
		pendenciasEmAberto() {
			return this.pendencias.filter(item => item.status === 'OPENED');
		},
		pendenciasConcluidas() {
			return this.pendencias.filter(item => item.status === 'CONCLUDED');
		},
		ehAtendimento() {
			return this.$route.name === 'Atendimento';
		},
		ehFarmaceutico() {
			return this.$route.name === 'Farmaceutico';
		}
	},
	methods: {
		buildDescription(descricao) {
			const regex = /https?:\/\/[^\s]+/g;
			const textWithLinks = descricao.replace(regex, url => `<a target='_blank' href="${url}">${url}</a>`);
			return `${textWithLinks}`;
		},
		getPendencias(box_id) {
			this.loading = true;
			PendenciaPresenter.buscarPendencias(box_id)
				.then(pendencias => {
					this.pendencias = pendencias;
				})
				.catch(() => {
				}).finally(() => {
					this.loading = false;
				});
		},
		enviarPendencia(bodyPendencia) {
			this.enviando = true;
			PendenciaPresenter.enviarPendencia({ box_id: this.box_id, ...bodyPendencia })
				.then(response => {
					this.$root.$emit('alterou_pendencia', true);
					this.pendencias.push(response);
					this.item.e_pendencias_resolvidas = 0;
				})
				.catch(() => {
				}).finally(() => {
					this.enviando = false;
				});
		},
		concluirSemPendencias() {
			this.semPendencias = true;
			PendenciaPresenter.semPendencias(this.box_id)
				.then(() => {
					this.$root.$emit('alterou_pendencia', true);
					this.close();
				})
				.catch(() => {
				}).finally(() => {
					this.semPendencias = false;
				});
		},
		pendenciaConcluida(pendencia) {
			this.concluindoPendencia = true;
			PendenciaPresenter.concluirPendencia(pendencia.id)
				.then(() => {
					this.$root.$emit('alterou_pendencia', true);
					pendencia.status = 'CONCLUDED';
					$store.dispatch('SHOW_SNACKBAR', { message: 'Pendência concluída.' });
					this.getPendencias(this.box_id);
				})
				.catch(() => {
				}).finally(() => {
					this.concluindoPendencia = false;
				});
		},
		enviarResposta(pendencia) {
			this.enviaResposta = true;
			PendenciaPresenter.respostaPendencia(pendencia.id, { texto: this.response })
				.then(() => {
					this.$root.$emit('alterou_pendencia', true);
					$store.dispatch('SHOW_SNACKBAR', { message: 'Resposta pendência enviada.' });
					this.getPendencias(this.box_id);
				}).catch(() => {
				}).finally(() => {
					this.enviaResposta = false;
					this.response = '';
				});
		},
		enviarRespostaEditada(resposta) {
			this.editandoResposta = true;
			PendenciaPresenter.editarResposta(resposta.id, { texto: resposta.texto })
				.then(() => {
					this.$root.$emit('alterou_pendencia', true);
					resposta.readonly = true;
					this.getPendencias(this.box_id);
				})
				.catch(() => {
				}).finally(() => {
					this.editandoResposta = false;
					this.isEditing = false;
				});
		},
		formatDate(dateIso) {
			return moment(dateIso).format('DD/MM/YYYY HH:mm');
		},
		concluirTodasPendencias(item) {
			this.concluidas = true;
			PendenciaPresenter.concluirTodasPendencias(item.box_id)
				.then(() => {
					this.$root.$emit('alterou_pendencia', true);
					item.e_pendencias_resolvida = 1;
					this.close();
				}).catch(() => {
				}).finally(() => {
					this.concluidas = false;
				});
		},
		abrirHistorico(pendencia) {
			this.abrirHistoricoPendencia = true;
			PendenciaPresenter.buscaPendencia(pendencia.id)
				.then(pendenciaEscolhida => {
					this.dialog_historico_pendencia = true;
					this.historicoPendencia = pendenciaEscolhida;
				}).catch(() => {
				}).finally(() => {
					this.abrirHistoricoPendencia = false;
				});
		},
		close() {
			this.$emit('close');
		},
		editarResposta() {
			this.isEditing = true;
		},
		podeEditar(pendencia, resposta, indice_resposta) {
			return (resposta.user.id === this.$store.getters.contexto.user.id) && (pendencia.respostas.length - 1 === indice_resposta);
		},
		podeResponder(pendencia) {
			return (pendencia.respostas.length % 2 === 0 && this.ehAtendimento) || (pendencia.respostas.length % 2 !== 0 && this.ehFarmaceutico);
		},
		botoesConcluir(item) {
			if (item.e_pendencias_resolvidas)
				return false;
			return true;
		},
		descricaoPendencia(item) {
			return item.descricao.split('\n');
		}
	}
};
</script>

	<style lang="scss" scoped>

		.id-pendencia {
		font-family: 'Poppins';
		font-weight: 700;
		}

		.categoria-pendencia {
		font-family: 'Roboto';
		font-weight: 700;
		}

		.font-style {
		font-family: 'Poppins';
		font-weight: 700;
		font-size: 10px;
		}

		.title-panel{
		text-align: left;
		font-size: 15px;
		font-weight: 700;
		}

	</style>
