<template>
	<v-container fluid grid-list-xl class="pa-0 mt-5">
		<Address ref="address" :quotation="quotation" :isNewQuotation="isNewQuotation"/>
		<hr class="spacerQuotationStyle">
		<PersonInfo ref="personInfo" :quotation="quotation" :tabSelected="tabSelected" />
		<hr class="spacerQuotationStyle">
		<v-layout wrap>
			<v-flex md12 xs12>
				<TextDateField v-model="quotation.startDate" box background-color="white" label="Data de início da box"
					placeholder="dd/mm/yyyy"/>
			</v-flex>
		</v-layout>
		<hr class="spacerQuotationStyle">
	</v-container>
</template>

<script>
import Mixin from '../mixin';
import Address from './address.vue';
import PersonInfo from '../general-data/person-info.vue';

export default {
	name: 'ClientDataQuotationV2',
	components: { Address, PersonInfo },
	mixins: [Mixin],
	$_veeValidate: { validator: 'new' },
	props: {
		tabSelected: {
			type: String
		}
	},
	methods: {
		async validate() {
			const personInfoValidate = Boolean(this.$refs.personInfo.validate());
			const addressValidate = Boolean(this.$refs.address.validate());

			return Boolean(await this.$validator.validateAll() && personInfoValidate && addressValidate);
		}
	}
};
</script>
