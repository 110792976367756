<template>
	<v-container fluid grid-list-md class="pa-0">
		<h3 class="mt-3 mb-4">Endereço</h3>

		<v-layout wrap>
			<v-flex md3 xs12>
				<v-text-field
					background-color="white"
					v-model="address.zipcode"
					mask="#####-###"
					box
					label="CEP"
					data-vv-name="CEP"
					v-validate="{ required: true,numeric: true, min: 8 }"
					:error-messages="errors.collect('CEP')"
					@blur="getDataFromZipcode"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-text-field
					box
					background-color="white"
					v-model="address.street"
					label="Logradouro"
					v-validate="{ required: true }"
					data-vv-name="Logradouro"
					:error-messages="errors.collect('Logradouro')"/>
			</v-flex>

			<v-flex md3 xs12>
				<v-text-field
					background-color="white"
					box
					v-model="address.number"
					label="Número"
					data-vv-name="Número"
					v-validate="{ required: true }"
					:error-messages="errors.collect('Número')"/>
			</v-flex>

			<v-flex md4 xs12>
				<v-text-field
					background-color="white"
					box
					v-model="address.neighborhood"
					label="Bairro"
					data-vv-name="Bairro"
					v-validate="{ required: true }"
					:error-messages="errors.collect('Bairro')"/>
			</v-flex>

			<v-flex md4 xs12>
				<v-text-field
					background-color="white"
					v-model="address.city"
					box
					label="Cidade"
					data-vv-name="Cidade"
					v-validate="{ required: true }"
					:error-messages="errors.collect('Cidade')"/>
			</v-flex>

			<v-flex md4 xs12>
				<v-autocomplete
					box
					clearable
					:items="states"
					item-value="id"
					item-text="text"
					no-data-text="Nenhum estado encontrado"
					v-model="address.state"
					background-color="white"
					label="Estado"
					data-vv-name="Estado"
					v-validate="{ required: true }"
					:error-messages="errors.collect('Estado')"/>
			</v-flex>

			<v-flex xs12>
				<v-text-field
					background-color="white"
					v-model="address.complement"
					box
					label="Complemento"
					data-vv-name="Complemento"
					v-validate="{ required: false }"
					:error-messages="errors.collect('Complemento')"/>
			</v-flex>

			<v-flex xs12>
				<v-textarea
					background-color="white"
					v-model="address.observation"
					rows="3"
					label="Observação"
					box
					v-validate="{ required: false }"
					data-vv-name="Observação"
					:error-messages="errors.collect('Observação')"/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { statesWithSymbol } from '@Consts/estados';
import { EnderecoPresenter } from '@Presenter/endereco-presenter';

export default {
	name: 'RestHomeGeneralDataAddress',
	inject: ['$validator'],
	props: {
		address: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			cities: []
		};
	},
	computed: {
		states() {
			const statesAbbr = Object.keys(statesWithSymbol);
			return statesAbbr.map(abbr => ({ id: abbr, text: statesWithSymbol[abbr] }));
		}
	},
	methods: {
		async getDataFromZipcode() {
			const zipcode = this.$functions.onlyNumber(this.address.zipcode);
			if (zipcode.length !== 8) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'CEP deve ter 8 dígitos.'
				});
				return;
			}
			this.loadingZipcode = true;

			try {
				const dataFound = await EnderecoPresenter.buscaCep(zipcode);
				this.address.neighborhood = dataFound.bairro;
				this.address.street = dataFound.logradouro;
				this.address.city = dataFound.cidade;
				this.address.state = dataFound.uf;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'CEP não encontrado'
				});
			} finally {
				this.loadingZipcode = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
