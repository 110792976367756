import { COLABORADOR, True, TOKEN } from '@Consts/strings';
import Vue from 'vue';
import unleash from '@Util/unleash';
import Router from 'vue-router';
import store from '../store';
import axios from '../config/axios';
import { Logout } from '../util/functions';

import { routes } from './routes';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

function handleNext(to, next, params) {
	const { eh_colaborador, eh_vendedor } = store.getters;
	const eh_colaborador_ou_vendedor = eh_colaborador || eh_vendedor;
	const indo_colaborador = to.matched.some(record => record.meta.colaborador);
	const estou_colaborador = localStorage.getItem(COLABORADOR) === True;

	if (to.meta.skipHandleNext) {
		next();
		return;
	}

	if ((params || {}).stop === true) {
		next();
		return;
	}

	if (eh_colaborador_ou_vendedor === false && estou_colaborador) { // Se não sou colaborador e estou em uma página de colaborador
		store.dispatch('CLEAR_STATE');
		next({ name: 'Perfil', params: { stop: true } });
		return;
	}

	if (eh_colaborador_ou_vendedor === false && indo_colaborador) { // Se não sou colaborador e estou indo para uma página de colaborador
		next(false);
		return;
	}

	if (eh_colaborador_ou_vendedor && indo_colaborador !== estou_colaborador) // Se sou colaborador e estou saindo do contexto de user atual
		store.dispatch('SET_VISAO_COLABORADOR', indo_colaborador);

	if (params)
		next(params);
	else
		next();
}

router.beforeEach((to, from, next) => {
	const entrar_or_esqueleto = to.name === 'Entrar' || to.name === 'Esqueleto';
	if (!to.params.stop && (to.matched.some(record => record.meta.auth) || entrar_or_esqueleto)) {
		if (localStorage.getItem(TOKEN)) {
			if (store.getters.contexto)
				handleNext(to, next, entrar_or_esqueleto ? { name: 'Perfil' } : {});
			else {
				axios.get('me/contexto')
					.then(async response => {
						await store.dispatch('CONTEXTO', response);
						await unleash.setContextField('userId', response.user.id);
						await unleash.setContextField('userEmail', response.user.email);
						store.dispatch('SET_VISAO_COLABORADOR', localStorage.getItem(COLABORADOR) === True);
						handleNext(to, next, entrar_or_esqueleto ? { name: 'Perfil' } : {});
					})
					.catch(async () => {
						Logout();
						handleNext(to, next, { name: 'Entrar', params: { stop: true } });
					});
			}
		} else
			handleNext(to, next, { name: 'Entrar', params: { stop: true } });
	} else
		handleNext(to, next);
});

export default router;
