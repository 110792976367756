<template>
	<div class="pa-3">
		<h1 v-html="temEndereco ? 'Escolha o endereço' : 'Cadastre um endereço'" />
		<p class="mb-5" v-html="temEndereco ? 'Escolha ou cadastre um endereço de entrega da assinatura.' : null" />
		<v-container fluid grid-list-xl class="pa-0">
			<v-layout wrap>
				<v-flex xs12 v-if="temEndereco">
					<v-layout wrap fill-height>
						<v-flex xs12 md4>
							<v-card
								v-for="(endereco, index) in enderecos"
								:key="endereco.id"
								@click="escolherEndereco(index)">
								<CardEndereco
									:isSelected="isSelectedAddress(index)"
									:restHome="casa_repouso"
									:addressItem="endereco"/>
							</v-card>
						</v-flex>
						<v-flex xs12 md4>
							<v-card :class="novo_endereco ? 'selecionado-novo-endereco' : null" @click="novoEndereco">
								<v-card-text class="text-xs-center flex-center">
									<span class="primary--text">
										<v-icon small class="mr-2" color="primary">add</v-icon>
										Cadastrar novo endereço
									</span>
								</v-card-text>
							</v-card>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 v-if="!temEndereco || (temEndereco && novo_endereco)">
					<h3 v-if="temEndereco" class="mt-4 mb-3">Cadastre um novo endereço</h3>
					<Cep :endereco="assinatura.endereco" :scope="scope" :required="!clinica" @endereco-encontrado="end => assinatura.endereco = end" />
					<Form :scope="scope" :item="assinatura.endereco" :campos="campos" />
				</v-flex>
				<ObservacoesAssinatura :assinatura="assinatura" />
			</v-layout>
		</v-container>
	</div>
</template>

<script>
import { featureFlagsMixin } from '@Mixins/feature-flag';
import { EnderecoPresenter } from '@Presenter/endereco-presenter';
import endereco_fields from '@Fields/endereco-fields';
import Cep from '@Componentes/cep';
import { AssinaturaMixin } from '@Mixins/assinatura';
import { isDomicilio } from '@Util/functions';
import CardEndereco from './card-endereco.vue';

export default {
	name: 'EscolherEndereco',
	inject: ['$validator'], // inject parent's validator
	mixins: [AssinaturaMixin, featureFlagsMixin],
	components: { Cep, CardEndereco },
	props: {
		clinica: {
			type: Boolean,
			default: false
		},
		type: {
			type: String // 'b2c' || b2b
		},
		casa_repouso: {
			type: Object,
			default: null
		}
	},
	data: () => ({
		index_selecionado: -1,
		enderecos: [],
		campos: {}
	}),
	created() {
		this.campos = this.$lodash.cloneDeep(endereco_fields);
		if (this.clinica) {
			Object.keys(this.campos).forEach(campo => {
				const validation = this.campos[campo].validation;
				if (validation && validation.required)
					validation.required = false;
			});
		}
	},
	watch: {
		casa_repouso(casa_repouso_info) {
			this.searchRestHomeAddress(casa_repouso_info);
		}
	},
	methods: {
		salvarEndereco() {
			return new Promise(async resolve => {
				if (this.assinatura.endereco.id)
					return resolve(true);
				if (Object.values(this.assinatura.endereco).length === 0)
					return resolve(true);

				if (this.assinatura.endereco) {
					try {
						const response = await EnderecoPresenter.store(this.$lodash.cloneDeep({ ...this.assinatura.endereco, assinatura_id: this.assinatura.id }));
						this.assinatura.endereco = this.$lodash.cloneDeep(response);
						this.enderecos.push(this.$lodash.cloneDeep(response));
						this.index_selecionado = this.enderecos.length - 1;
						return resolve(true);
					} catch (error) {
						return resolve(false);
					}
				}
			});
		},
		escolherEndereco(index) {
			this.assinatura.endereco = this.enderecos[index];
			this.index_selecionado = index;
		},
		novoEndereco() {
			this.assinatura.endereco = {};
			this.index_selecionado = -1;
		},
		searchRestHomeAddress(rest_home) {
			if (rest_home && this.canGetRestAddress(rest_home)) {
				EnderecoPresenter.index({ casa_repouso_id: rest_home.id })
					.then(response => {
						this.enderecos = response;
						this.index_selecionado = null;
					});
			}
		},
		canGetRestAddress(rest_home) {
			return !isDomicilio(rest_home.nome);
		},
		isSelectedAddress(addressIndex) {
			return addressIndex === this.index_selecionado;
		}
	},
	computed: {
		responsavel_id() {
			return this.assinatura.paciente.responsaveis[0].id;
		},
		temEndereco() {
			return this.enderecos.length > 0;
		},
		novo_endereco() {
			return this.index_selecionado === -1;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.v-card {
  height: 100%;
  cursor: pointer;
  border: 2px solid #fff;

  &.selecionado {
    border: 2px solid $success;
  }

  &.selecionado-novo-endereco {
    border: 2px solid $primary;
  }

  .v-card__text {
    height: 100%;

    &.flex-center {
      font-weight: 600;
    }
  }
}
</style>
