<template>
	<Dialog :titulo="is_new ? 'Cadastrar princípios ativos' : 'Editar princípios ativos'" btn_cancelar="Cancelar"
		:btn_acao="is_new ? 'Cadastrar' : 'Editar'" :btn_acao_disable="false" :loading="salvando" :carregando="loading"
		@acao="submeter()"  @close="fechaModal()" >

		<v-container fluid grid-list-lg>
			<v-layout wrap class="layout-wrapper">
				<v-flex class="xs12 md12">
					<Form :campos="campos" :item="item" />
				</v-flex>
			</v-layout>
		</v-container>

	</Dialog>
</template>

<script>
import { CRUDMixin } from '@Mixins/crud';

export default {
	name: 'DialogPrincipioAtivo',
	mixins: [CRUDMixin],
	props: {
		moleculas: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			type: 'principio-ativo',
			fields_type: 'principio-ativo',
			custom_field: {
				moleculas: {
					items: this.moleculas,
					disabled: false
				}
			}
		};
	},
	created() {
		this.custom_field.moleculas.disabled = !this.is_new;
	},
	methods: {
		setItem() {},
		onPresenterLoaded() {
			if (!this.is_new) {
				this.loading = true;
				this.requestShow(this.item_pai.id)
					.then(resp => {
						this.item = resp;
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		createData() {
			return {
				...this.item
			};
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

</style>
