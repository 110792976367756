<template>
	<section>
		<menuMobile v-if="$vuetify.breakpoint.smAndDown" />
		<navbar />
		<v-content>
			<transition name="page" mode="out-in">
				<router-view />
			</transition>
		</v-content>
	</section>
</template>

<script>
import navbar from './navbar';
import menuMobile from './menu-mobile';

export default {
	name: 'Esqueleto',
	components: {
		navbar, menuMobile
	},
	data() {
		return {
			drawer: false
		};
	}
};
</script>
