export const ListagemMixin = {
	data: () => ({
		busca: '',
		presenter: null,
		items: [],
		index_props: {},
		item_selecionado: {},
		dialog_crud: false,
		is_loading: true,
		request_error: false
	}),
	computed: {
		error_message() {
			if (this.is_loading)
				return 'Carregando...';
			return this.request_error ? 'Erro ao carregar. Por favor, tente novamente' : 'Nenhum dado';
		}
	},
	async created() {
		this.presenter = await this.getPresenter();
		this.index();
	},
	methods: {
		getPresenter() {
			return new Promise(async resolve => {
				if (this.type) {
					const Module = await import(`@Presenter/${this.type}-presenter`);
					resolve(Module[`${this.$functions.hyphensToCamel(this.type)}Presenter`]);
				}
				resolve(null);
			});
		},
		index(index_props) {
			this.is_loading = true;
			const index = this.request || this.presenter.index;
			index(index_props || this.getIndexProps())
				.then(this.onResponse)
				.catch(() => { this.request_error = true; })
				.finally(() => { this.is_loading = false; });
		},
		getIndexProps() {
			return null;
		},
		onResponse(response) {
			this.items = response;
		},
		crud(item) {
			this.item_selecionado = this.$lodash.cloneDeep(item || {});
			this.dialog_crud = true;
		},
		salvo(item) {
			const i = this.getIndex(item);
			this.items.splice(i !== -1 ? i : 0, i !== -1 ? 1 : 0, this.formatResponse(item));
			this.afterSave();
		},
		formatResponse(response) {
			return response;
		},
		afterSave() {},
		excluido(item) {
			const i = this.getIndex(item);
			this.items.splice(i, 1);
		},
		getIndex(item) {
			return this.items.findIndex(obj => obj.id === item.id);
		}
	}
};
