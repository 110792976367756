<template>
	<div class="barcode">
		<input
			v-model="input"
			ref="input"
			@keyup.enter="enter">
	</div>
</template>

<script>
import axios from 'axios';
import { getCurrentCompanyUnit } from '@Config/unidades';

export default {
	name: 'InputCodigoBarras',
	props: {
		label: {
			type: String
		},
		withoutLabel: {
			type: Boolean
		},
		focusEnabled: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		input: ''
	}),
	mounted() {
		if (this.focusEnabled)
			this.inputFocus();
	},
	watch: {
		focusEnabled(value) {
			if (value)
				this.inputFocus();
		}
	},
	computed: {
		cidade() {
			return getCurrentCompanyUnit().nome;
		}
	},
	methods: {
		inputFocus() {
			if (this.$refs.input)
				this.$refs.input.focus();
		},
		enter() {
			if (!this.withoutLabel && !this.label) {
				this.$store.dispatch('SHOW_SNACKBAR',
					{ message: 'Preencha a etiqueta ou marque a opção "Este medicamento não tem etiqueta"', color: 'error' });
				return;
			}

			if (this.input) {
				this.$emit('loading', true);
				const url = `https://fotos-medicamentos.s3.amazonaws.com/${this.input}/caixa_min.jpg`;
				axios.get(url, { responseType: 'blob' })
					.then(response => {
						const image = URL.createObjectURL(response.data);
						this.$emit('image', image);
						this.$emit('mostrarMsg', false);
					})
					.then(() => {
						const data = {
							ean: this.input,
							label: this.label
						};
						this.$store.dispatch('bipe/FETCH_MEDICAMENTO', data);
						this.$emit('medicinePicked');
					})
					.catch(() => {
						this.$emit('image', null);
						this.$emit('mostrarMsg', true);
						this.$store.state.bipe.medicamento = null;
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Medicamento sem fotos cadastradas', color: 'error' });
					})
					.finally(() => {
						this.$emit('loading', false);
						this.input = '';
					});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.barcode {
	border-radius: 10px;
	background: url("./barcode.png");

	input {
		text-align: center;
		font-weight: 900;
		font-size: 24px;
		letter-spacing: 1px;
		height: 60px;
		outline: none;
		width: 100%;
	}
}
</style>
