<template>
	<v-card>
		<v-data-table
			v-model="selected"
			:headers="newHeaders"
			:items="items"
			:loading="loading"
			:total-items="totalItems"
			ref="DataTable"
			:no-data-text="loading ? 'Carregando...' : 'Nenhuma box'"
			no-results-text="Nenhum resultado encontrado"
			:rows-per-page-items="rows_per_page"
			rows-per-page-text="Itens por página"
			:pagination.sync="pagination"
			item-key="id">
			<template slot="headers" slot-scope="props">
				<tr>
					<th v-if="selectable" class="pr-0">
						<v-checkbox :input-value="props.all" :indeterminate="props.indeterminate" primary
							hide-details @click.stop="toggleAll" />
					</th>
					<th v-for="(header, index) in props.headers" :key="header.text" :class="['column', { sortable: header.sortable }, index !== 1 ? 'text-xs-center' : 'text-xs-left', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
						@click="changeSort(header.value)">
						{{ header.text }}
						<v-icon small v-if="header.sortable">arrow_upward</v-icon>
					</th>
				</tr>
			</template>
			<template slot="items" slot-scope="props">
				<tr :active="props.selected" @click="props.selected = !props.selected">
					<td v-if="selectable" class="pr-0">
						<v-checkbox :input-value="props.selected" primary hide-details />
					</td>
					<td v-if="showPrioridadeAtendimento" class="text-xs-center">
						<PrioridadeAtendimento :days="props.item.dias_a_iniciar" :status="props.item.status_ultima_box" :hasCalendar="props.item.tem_calendario" />
					</td>
					<td class="text-xs-center">{{ props.item.assinatura_id }}</td>
					<td class="py-2" style="white-space: nowrap">
						<GlobalTags
							v-for="(global_tag, index) in props.item.paciente.global_tags"
							:key="index"
							:global_tag="global_tag"/>
						{{ props.item.paciente.nome }}
						<BoxExpress :express="props.item.express"/>
						<FirstBox v-if="!props.item.express && props.item.primeira_box" />
					</td>
					<td class="text-xs-center">{{ props.item.inicio | formatDate('DD/MM/YYYY [(]dddd[)]') }}</td>
					<td class="text-xs-center">
						{{ (({id, nome}) => nome ? `${id||''}${id ? ' - ' : ''}${nome}` : '-')(props.item.paciente.casa_repouso) }}
					</td>
					<td v-for="h in extra_headers" :key="h.value" class="text-xs-center">
						{{ h.format ? h.format(props.item[h.value]) : props.item[h.value] || '-' }}
					</td>
					<td class="buttons" @click.stop>
						<v-tooltip v-if="props.item.sem_box" top>
							<v-icon :class="{ 'mr-3': buttons.length > 0 }" slot="activator"
								color="warning">
								fas fa-exclamation-circle
							</v-icon>
							<span>Sem box</span>
						</v-tooltip>
						<template v-for="(btn, i) in buttons">
							<v-tooltip v-if="btn.condition ? btn.condition(props.item) : true" top :key="i">
								<v-icon slot="activator" :class="{
									'mr-3': buttons.length > 1 && i !== buttons.length - 1,
									'cursor-default': !btn.onClick || (btn.isDisabled && btn.isDisabled(props.item))
								}"
									:color="btn.color ? btn.color : btn.setColor ? btn.setColor(props.item) : 'info'"
									@click.stop="btn.onClick ? btn.onClick(props.item) : null">
									{{ btn.icon }}
								</v-icon>
								<span>{{ btn.label || btn.getLabel(props.item) }}</span>
							</v-tooltip>
						</template>
					</td>
				</tr>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
import { debounce } from 'lodash';
import BoxExpress from '@Componentes/box-express';
import FirstBox from '@Componentes/first-box';
import PrioridadeAtendimento from '@Componentes/prioridade-atendimento';
import GlobalTags from '@Componentes/global-tags';

export default {
	name: 'DataTableProcessoPagination',
	components: {
		BoxExpress,
		FirstBox,
		PrioridadeAtendimento,
		GlobalTags
	},
	props: {
		showPrioridadeAtendimento: {
			type: Boolean,
			default: false
		},
		selectable: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			required: true
		},
		exibirSuspensos: {
			type: Boolean,
			required: false,
			default: false
		},
		search: {
			type: String,
			default: ''
		},
		days: {
			type: String,
			required: false
		},
		buttons: {
			type: Array,
			default: () => []
		},
		extra_headers: {
			type: Array,
			default: () => []
		},
		rows_per_page: {
			type: Array,
			default: () => [15, 30, 45]
		},
		sort_by: {
			type: String,
			default: 'inicio'
		},
		descending: {
			type: Boolean,
			default: true
		},
		fetch_data: {
			type: Function,
			required: true
		},
		sortable_actions: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		return {
			selected: [],
			headers: [
				{
					text: 'Assinatura', value: 'assinatura', sortable: true, align: 'center'
				},
				{ text: 'Paciente', value: 'paciente', sortable: true },
				{ text: 'Data de início', value: 'inicio', sortable: true },
				{ text: 'Casa de repouso', value: 'casa_repouso', sortable: true },
				...this.extra_headers,
				{ text: 'Ações', value: 'acoes', sortable: this.sortable_actions }
			],
			pagination: {
				sortBy: this.sort_by,
				descending: this.descending
			},
			items: [],
			totalItems: 0
		};
	},
	computed: {
		newHeaders() {
			const headers = this.$data.headers;
			const statusPrioridadeHeader = {
				text: 'Prior.', value: 'dias_a_iniciar', sortable: true, align: 'center'
			};

			if (this.showPrioridadeAtendimento)
				headers.unshift(statusPrioridadeHeader);

			return headers;
		}
	},
	watch: {
		pagination: {
			async handler() {
				await this.fetchData();
			},
			deep: true
		},
		exibirSuspensos: {
			handler() {
				this.pesquisar(this);
			},
			deep: true
		},
		search: {
			handler() {
				this.pesquisar(this);
			},
			deep: true
		},
		days: {
			handler() {
				this.pesquisar(this);
			},
			deep: true
		},
		selected(arr) {
			this.$emit('selected-updated', arr);
		}
	},
	methods: {
		toggleAll() {
			this.selected = this.selected.length ? [] : [...this.$refs.DataTable.filteredItems];
		},
		changeSort(column) {
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},
		pesquisar: debounce(async self => {
			await self.fetchData();
		}, 1000),

		async fetchData() {
			await this.fetch_data(this.pagination);
		},

		updatePage(items, total) {
			this.items = items;
			this.totalItems = total;
		}
	}
};
</script>
