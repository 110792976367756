<template>
	<div>
		<v-data-table
			:headers="headers" :items="estoque" :loading="is_loading" :search="busca"
			:no-data-text="(is_loading) ? 'Carregando...' : (request_error) ? 'Erro ao carregar, tente novamente' : 'Nenhum medicamento no estoque'"
			:rows-per-page-items="[15, 30, 45, {text: 'Todos', value: -1}]" no-results-text="Nenhum resultado encontrado"
			rows-per-page-text="Itens por página">
			<template slot="items" slot-scope="props">
				<tr>
					<td>{{ props.item.id }}</td>
					<td>{{ props.item.produto }}</td>
					<td>{{ props.item.qtd_caixas.toFixed(2) }}</td>
					<td>{{ props.item.qtd_comprimidos }}</td>
					<td v-if="props.item.lote">{{ props.item.lote }}</td><td v-else> - </td>
					<td v-if="props.item.validade">{{ props.item.validade }}</td> <td v-else> - </td>
					<td v-if="showStoreOutputButton" class="text-xs-right" @click.stop>
						<v-tooltip top>
							<v-btn @click.stop="atualizarEstoque(props.item)" small icon slot="activator">
								<v-icon color="purple" style="font-size: 20px">far fa-minus-square</v-icon>
							</v-btn>
							<span>Remover do estoque</span>
						</v-tooltip>
					</td>
				</tr>
			</template>
		</v-data-table>

		<v-dialog persistent v-model="dialog_controle" scrollable transition="fade" max-width="400px">
			<transition>
				<Dialog v-if="dialog_controle"
					titulo="Remover do estoque"
					:subtitulo="`${item_selecionado.produto} - ${item_selecionado.qtd_comprimidos} cp disponível(is).`"
					btn_acao="Confirmar"
					:btn_acao_disable="!habilita_controle || isUpdating"
					:loading="isUpdating"
					@acao="confirmar"
					@close="dialog_controle = false">
					<v-text-field box v-model.number="controle.quantidade" label="Quantidade de comprimidos *" type="number" min="1" />
					<v-textarea box v-model="controle.observacao" label="Motivo *" />
				</Dialog>
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { PacientePresenter } from '@Presenter/paciente-presenter';
import { MovimentacaoPresenter } from '@Presenter/movimentacao-presenter';

export default {
	name: 'ListaEstoque',
	props: {
		paciente_id: {
			type: Number,
			required: true
		},
		box_id: {
			type: Number,
			default: null
		},
		assinatura_id: {
			type: Number,
			default: null
		},
		busca: {
			type: String,
			default: null
		},
		showStoreOutputButton: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		dialog_controle: false,
		estoque: [],
		search: '',
		isUpdating: false,
		is_loading: false,
		request_error: false,
		headers: [
			{ text: 'ID', value: 'id' },
			{ text: 'Medicamento', value: 'produto' },
			{ text: 'Qtd caixas', value: 'qtd_caixas' },
			{ text: 'Qtd comprimidos', value: 'qtd_comprimidos' },
			{ text: 'Lote', value: 'lote' },
			{ text: 'Validade', value: 'validade' }
		],
		controle: {
			quantidade: 1,
			observacao: ''
		}
	}),
	created() {
		this.getEstoque();
		if (this.showStoreOutputButton)
			this.headers.push({ text: '', sortable: false });
	},
	watch: {
		is_loading(newVal) {
			this.$emit('update:carregando', newVal);
		}
	},
	methods: {
		getEstoque() {
			this.is_loading = true;
			PacientePresenter.stockWithBatchAndExpirationDate(this.paciente_id, { assinatura_id: this.assinatura_id })
				.then(response => {
					this.estoque = response.estoque;
				})
				.catch(() => {
					this.request_error = true;
				})
				.finally(() => {
					this.is_loading = false;
				});
		},
		atualizarEstoque(medicamento) {
			this.item_selecionado = medicamento;
			this.dialog_controle = true;
			this.controle = {
				quantidade: 1,
				observacao: ''
			};
		},
		confirmar() {
			const items = [{
				...this.controle,
				box_id: this.box_id,
				paciente_id: this.paciente_id,
				lote: this.item_selecionado.lote,
				validade: this.item_selecionado.validade,
				medicamento_id: this.item_selecionado.id
			}];

			this.isUpdating = true;
			MovimentacaoPresenter.storeSaida({ items })
				.then(() => {
					this.$emit('estoque-atualizado');
					this.dialog_controle = false;
					this.getEstoque();
				}).finally(() => {
					this.isUpdating = false;
				});
		}
	},
	computed: {
		habilita_controle() {
			return this.controle.quantidade <= this.item_selecionado.qtd_comprimidos && this.controle.quantidade > 0 && !!this.controle.observacao;
		}
	}
};
</script>
