<template>
	<div class="px-md-5">
		<LogisticsHeader :title="'Rotas'" :button="{}"/>

		<!-- Conteúdo -->
		<v-container>

			<v-layout xs12 md12 wrap>
				<v-flex xs5 md5 class="pl-0 pt-0 pr-4">
					<v-list>
						<v-list-tile
							class="tile"
							v-for="item in items"
							:key="item.title">

							<v-list-tile-content row>
								<v-list-tile-title class="bold">Rota 1</v-list-tile-title>
							</v-list-tile-content>

							<v-list-tile-content row>
								<v-list-tile-sub-title>Data de envio</v-list-tile-sub-title>
								<v-list-tile-title class="bold">07/01/2024</v-list-tile-title>
							</v-list-tile-content>

							<v-chip class="status-chip waiting-chip">Aguardando</v-chip>
						</v-list-tile>

					</v-list>
				</v-flex>
				<v-flex xs7 md7 justify-center class="items-center map-container">
					mapa aqui
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
import LogisticsHeader from '../header.vue';

export default {
	name: 'ShippingRoutes',
	components: {
		LogisticsHeader
	},
	data: () => ({
		items: [{
			id: 1,
			selected: false,
			assinatura_id: 1,
			dias_a_iniciar: '',
			express: false,
			primeira_box: false,
			paciente: {
				nome: 'teste',
				casa_repouso: ''
			},
			cep: '12344566',
			demand_region: 'MG - Pampulha/Contagem',
			address: {
				street: 'blabla',
				number: 1,
				complement: 'cmaks',
				city: 'minas',
				state: 'MG',
				zip: '12344566'
			},
			observacao: 'aspdaos',
			send_data: '2023-12-26T20:20:07.969Z'
		}, {
			id: 2,
			selected: false,
			assinatura_id: 2,
			dias_a_iniciar: '',
			express: false,
			primeira_box: false,
			paciente: {
				nome: 'teste',
				casa_repouso: ''
			},
			demand_region: 'MG - Pampulha/Contagem',
			address: {
				street: 'blabla',
				number: 1,
				complement: 'cmaks',
				city: 'minas',
				state: 'MG',
				zip: '12344566'
			},
			observacao: 'et',
			send_data: '2023-12-26T20:20:07.969Z'
		}]
	}),
	created() {},
	computed: {},
	methods: {}
};

</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
::v-deep .v-list{
	padding: 0;
	background-color: $primary-light!important;
}

::v-deep .v-list__tile{
	display: "flex";
	flex-direction: row;
}

.tile {
	background-color: $secondary-light;
	margin-bottom: 10px;
	border-radius: 10px;
	padding: 30px 10px;
}

.map-container{
	border-radius: 15px;
	border: 2px dashed rgba(52, 43, 29, 0.40);
	min-height: 700px;
	flex-shrink: 0;
}

.bold {
	font-size: 18px!important;
	font-weight: 700!important;
}

</style>
