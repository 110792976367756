<template>
	<header>
		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 md6>
				<h1 class="text--text" style="opacity: .8">Assinaturas</h1>
				<p class="mb-0">Utilize a busca aos lado para encontrar as assinaturas.</p>
				<p class="mb-0">Você pode buscar pelo ID da assinatura ou pelo nome do paciente ou responsável.</p>
				<p>É possível filtrar pelo status da assinatura ou por Casas de Repouso.</p>
				<v-btn v-if="$store.getters.eh_administrador || $store.getters.eh_rt" depressed class="primary ml-0 pl-2 pr-3" @click="$emit('nova-assinatura')">
					<v-icon small class="mr-2">add</v-icon>
					Nova assinatura
				</v-btn>
				<v-btn depressed class="green white--text pl-3 pr-3" @click="$emit('gerar-excel')">
					<v-icon small class="mr-2">cloud_download</v-icon>
					Gerar lista para calendário
				</v-btn>
			</v-flex>

			<v-flex xs12 md6 align-self-center>
				<v-layout wrap>
					<v-flex  md8>
						<v-text-field label="Buscar assinatura" placeholder="ID ou nome do Paciente ou responsável"
							append-icon="search" color="purple" @input="value => $emit('busca', value)" />
					</v-flex>
					<v-flex  md4 style="max-width: 200px">
						<v-select append-icon="filter_list" v-model="filtro" label="Filtros"
							:items="filtros" @input="onInputFiltro" />
					</v-flex>
				</v-layout>
				<CampoSelecaoMultipla
					:items="casas_repouso"
					:itemText="i => `${i.id} - ${i.nome}`"
					itemValue="id"
					label="Casas de repouso"
					prependInnerIcon="home"
					@items-updated="onCasasRepousoUpdate"/>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import CampoSelecaoMultipla from '@Componentes/campo-selecao-multipla';

export default {
	name: 'AssinaturasHeader',
	components: {
		CampoSelecaoMultipla
	},
	data: () => ({
		filtro: 'ativas',
		filtros: [
			{ value: null, text: 'Todas' },
			{ value: 'ativas', text: 'Ativas' },
			{ value: 'sem_box', text: 'Sem Box' },
			{ value: 'canceladas', text: 'Canceladas' },
			{ value: 'suspensas', text: 'Suspensas' }
		],
		casas_repouso: []
	}),
	created() {
		this.onInputFiltro(this.filtro);
		CasaRepousoPresenter.index({ campos: ['id', 'nome'] })
			.then(response => {
				this.casas_repouso = response;
			});
	},
	methods: {
		onInputFiltro(value) {
			this.$emit('filtro', value);
		},
		onCasasRepousoUpdate(casasRepouso) {
			this.$emit('casas-repouso-updated', casasRepouso);
		}
	}
};
</script>
