<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small> fas fa-pills </v-icon>
			</v-avatar>
			<h3>Receitas</h3>
			<v-spacer />

			<v-btn
				v-if="!readOnly"
				depressed
				class="ml-0 purple secondary--text"
				@click="openUploadPrescriptionsDialog()"
				:loading="enviarReceita">
				<v-icon small class="mr-2">cloud_upload</v-icon>
				Adicionar receita
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-container fluid grid-list-md class="pa-0">
				<v-layout wrap>
					<v-flex v-for="(receita, i) in receitas" :key="i" shrink style="display:flex;flex-direction:column;justify-content:space-between">
						<div class="img-container q-row justify-center text-center">
							<v-btn
								v-if="!readOnly"
								small
								icon
								@click="abrirDialog(receita, 'dialog_remover_receita')"
								class="purple delete-btn">
								<v-icon small color="white">delete</v-icon>
							</v-btn>
							<v-icon
								v-if="receita.url.includes('.pdf')"
								class="pointer pa-3"
								:class="{ selecionada: i === index }"
								style="border: 2px solid #BCBCBC;"
								@click="openPdf(receita, i)"
								:disabled="abrindoPdf"
								size="45"
								color="purple">fas fa-file-pdf</v-icon>
							<img
								v-else
								class="pointer pa-3"
								:class="{ selecionada: i === index }"
								style="border: 2px solid #BCBCBC;"
								:src="receita.url"
								@click="handleClick(i)"
								alt="Receita"
								width="80"
								height="80"/>
						</div>
						<v-tooltip top class="text-center">
							<span slot="activator" >
								ID {{ receita.id }}
							</span>
							<span>
								{{ receita.name.substring(0, 15) }}
							</span>
						</v-tooltip>
					</v-flex>
				</v-layout>
			</v-container>
		</v-card-text>
		<v-dialog
			persistent
			scrollable
			v-model="dialog_remover_receita"
			transition="fade"
			max-width="340px">
			<transition>
				<Dialog
					v-if="dialog_remover_receita"
					titulo="Remover receita"
					subtitulo="Esta ação não pode ser desfeita"
					btn_acao="Remover"
					btn_acao_class="error"
					btn_cancelar="Cancelar"
					:loading="deleting"
					@acao="removerReceita"
					@close="dialog_remover_receita = false"/>
			</transition>
		</v-dialog>

		<v-dialog
			v-model="dialog_visualizar_receitas"
			scrollable
			transition="fade"
			fullscreen>
			<transition>
				<DialogVisualizarReceitas
					v-if="dialog_visualizar_receitas"
					:receitas="receitas"
					:index="index"
					@close="dialog_visualizar_receitas = false"/>
			</transition>
		</v-dialog>

		<v-dialog
			v-model="dialog_enviar_receitas"
			persistent
			scrollable
			transition="fade"
			max-width="1024px">
			<transition>
				<DialogUploadPrescriptions
					v-if="dialog_enviar_receitas"
					:close.sync="dialog_enviar_receitas"
					@prescriptionToBeCreated="salvarReceita"/>
			</transition>
		</v-dialog>
	</v-card>
</template>

<script>
import { compress } from 'lz-string';
import { ReceitaPresenter } from '@Presenter/receita-presenter';
import DialogVisualizarReceitas from '@Componentes/assinatura/dialog-visualizar-receitas.vue';
import axios from 'axios';
import { openBuffer } from '@Util/functions';
import DialogUploadPrescriptions from './dialog-upload-receitas';

export default {
	name: 'Receitas',
	components: { DialogVisualizarReceitas, DialogUploadPrescriptions },
	data: () => ({
		dialog_visualizar_receitas: false,
		dialog_remover_receita: false,
		dialog_enviar_receitas: false,
		deleting: false,
		erro_upload: false,
		item_selecionado: null,
		enviarReceita: false,
		abrindoPdf: false
	}),
	props: {
		index: {
			type: Number,
			required: true
		},
		receitas: {
			type: Array,
			required: true
		},
		readOnly: {
			type: Boolean,
			required: false
		},
		box_id: {
			type: Number,
			required: false
		},
		assinatura_id: {
			type: Number,
			required: false
		}
	},
	methods: {
		handleClick(index) {
			this.$emit('receita-selecionada', index);
			if (!this.noZoom)
				this.abrirDialog(index, 'dialog_visualizar_receitas');
		},
		abrirDialog(item, nome) {
			this.item_selecionado = item;
			this[nome] = true;
		},
		openUploadPrescriptionsDialog() {
			this.dialog_enviar_receitas = true;
		},
		async salvarReceita(data) {
			this.enviarReceita = true;
			try {
				const receita = await ReceitaPresenter.store({
					assinatura_id: this.assinatura_id,
					box_id: this.box_id,
					prescritor_id: data.prescriberId,
					receita_base64: compress(data.receita_base64),
					tipo: data.tipo,
					name: data.name,
					numero_notificacao: data.numero_notificacao,
					data_prescrita: data.data_prescrita ? this.$functions.FormatDateToRequest(data.data_prescrita) : null
				});
				this.receitas.push(receita);
				this.$root.$emit('alterou_receita', true);
				this.dialog_enviar_receitas = false;
			} catch (e) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Erro ao Salvar receita'
				});
			} finally {
				this.enviarReceita = false;
			}
		},
		removerReceita() {
			this.deleting = true;
			ReceitaPresenter.destroy(this.item_selecionado.id)
				.then(() => {
					const i = this.receitas.findIndex(
						receita => receita.id === this.item_selecionado.id
					);
					if (i !== -1)
						this.receitas.splice(i, 1);
					this.dialog_remover_receita = false;
					this.$root.$emit('alterou_receita', true);
				})
				.finally(() => {
					this.deleting = false;
				});
		},
		onClick() {
			this.$refs.upload.click();
			this.erro_upload = false;
		},
		abrirReceita(receita) {
			if (this.rt)
				this.$emit('receita-selecionada', receita);
			else
				window.open(receita.url);
		},
		openPdf(receita, index = null) {
			this.$emit('receita-selecionada', index);
			this.abrindoPdf = true;
			axios.get(receita.url, { responseType: 'arraybuffer' })
				.then(response => openBuffer(response.data))
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao abrir PDF' });
				})
				.finally(() => {
					this.abrindoPdf = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.img-container {
	position: relative;

	.delete-btn {
		opacity: 0;
		transition: opacity 0.3s;
	}

	&:hover {
		.delete-btn {
			opacity: 1;
		}
	}

	button {
		position: absolute;
		right: 0;
	}

	img, i.v-icon {
		&.selecionada {
			border: 2px solid $primary!important;
		}
	}
}
</style>
