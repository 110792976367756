<template>
	<LoaderDialog v-if="loading" />
	<v-dialog
		v-else
		persistent
		scrollable
		v-model="opened"
		max-width="540">
		<template
			v-if="visibleActivator"
			v-slot:activator="{ on }">
			<v-flex v-on="on" xs3 class="button pointer" @click="getBoxResetInfo()">
				<div class="q-row justify-center items-center">
					<v-icon class="col-12">
						fas fa-undo-alt
					</v-icon>
					<span class="col-12 text-center">
						Resetar Box
					</span>
				</div>
			</v-flex>
		</template>

		<v-card class="farme-dialog">
			<section class="farme-dialog-header">
				Reset da Box
			</section>
			<section class="farme-dialog-body">

				<template v-if="resetInfo">
					<p v-if="!resetInfo.billed">Você pode resetar a Box sem perdas operacionais!</p>
					<template v-else>
						<p>Ops, essa Box já foi faturada!</p>
						<p>Para seguir com o reset dessa Box teremos que devolver os medicamentos faturados e depois gerar um novo pedido.</p>
						<p>Para seguir o processo preencha os campos abaixo:</p>
					</template>
					<TextDateField
						v-model="form.startDate"
						box
						flat
						background-color="white"
						label="Início"
						placeholder="Ex: 01/01/2024"
						v-validate="{ required: false }"
						data-vv-name="Data de início"
						:error-messages="errors.collect('Data de início')" />
					<v-select
						background-color="white"
						box
						flat
						v-model="form.reason"
						label="Justificativa"
						v-validate="{ required: true }"
						data-vv-name="Justificativa"
						:error-messages="errors.collect('Justificativa')"
						:items="[
							'Alteração na box fora do prazo',
							'Falta de medicamento no Picking',
							'Retorno de Pausa',
							'Aproveitamento de receita',
							'Outros problemas de separação'
						]"/>
					<section class="mb-4">
						<p class="font-weight-bold">Medicamentos presentes nessa Box:</p>
						<MedicineTable
							:items="resetInfo.items"
							:selectable="resetInfo.billed"
							@selected="setSelectedItems"/>

						<small v-if="resetInfo.billed">Selecione os medicamentos que devem ser estornados no estoque do paciente</small>
					</section>

					<!--
					<v-checkbox
						v-model="form.procfitConfirmed"
						label="Declaro que emiti a nota de devolução na ProcFit"
						hide-details
						v-validate="{ required: true }"
						data-vv-name="Declaro que emiti a nota de devolução na ProcFit"
						:error-messages="errors.collect('Declaro que emiti a nota de devolução na ProcFit')" />
					-->
					<v-checkbox
						v-model="form.colmeiaConfirmed"
						label="Declaro que devolvi todos os itens para a colmeia"
						hide-details
						v-validate="{ required: true }"
						data-vv-name="Declaro devolvi todos os itens para a colmeia"
						:error-messages="errors.collect('Declaro devolvi todos os itens para a colmeia')"/>
				</template>
				<v-progress-circular
					v-else
					indeterminate
					color="primary" />
			</section>

			<section class="farme-dialog-footer" v-if="!loading">
				<v-btn
					color="green darken-1"
					flat="flat"
					@click="opened = false">
					Cancelar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					@click="resetbox">
					Resetar Box
				</v-btn>
			</section>
		</v-card>
	</v-dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import LoaderDialog from '@Componentes/loader-dialog';
import MedicineTable from './table.vue';

export default {
	name: 'ResetBoxDialog',
	components: { MedicineTable, LoaderDialog },
	$_veeValidate: { validator: 'new' },
	props: {
		boxId: {
			required: true,
			type: Number
		},
		visibleActivator: {
			required: false,
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			loading: false,
			opened: false,
			resetInfo: {
				billed: false,
				items: []
			},
			form: {
				startDate: null,
				reason: null,
				colmeiaConfirmed: null,
				// procfitConfirmed: null,
				keepMedicineIds: []
			}
		};
	},
	methods: {
		async getBoxResetInfo() {
			try {
				this.loading = true;
				const getResetInfoResponse = await BoxPresenter.getBoxResetInfo(this.boxId);
				const {
					wasBilled, medicinesToBeMantained, medicinesToBeRemoved
				} = getResetInfoResponse;
				this.resetInfo.billed = wasBilled;
				this.resetInfo.items = [
					...medicinesToBeMantained.map(i => ({ ...i, keep: true })),
					...medicinesToBeRemoved.map(i => ({ ...i, keep: false }))
				];
				this.opened = true;
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'error',
						message: error.message
					}
				);
				this.opened = false;
			} finally {
				this.loading = false;
			}
		},
		async resetbox() {
			const formIsValidated = await this.$validator.validateAll();

			if (!formIsValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário inválido',
					color: 'error'
				});
				return;
			}

			this.$emit(
				'resetBox',
				{
					boxId: this.boxId,
					startDate: this.$functions.FormatDateToRequest(this.form.startDate),
					reason: this.form.reason,
					keepMedicineIds: this.form.keepMedicineIds
				}
			);

			this.opened = false;
		},
		setSelectedItems(items) {
			this.form.keepMedicineIds = items.map(i => i.id);
		}
	}
};
</script>

<style lang="scss" scoped>
.button {
	min-height: 100px;
	max-height: 100px;
	padding: 0 10px;
	padding: 4px;

	&>div {
		border: 1px solid darkgrey;
		border-radius: 8px;
		height: 100%;
		transition: box-shadow .3s;

		&:hover {
			box-shadow: 0 0 11px rgba(33,33,33,.2);
		}
	}
}
</style>
