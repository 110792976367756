<template>
	<label class="label flex pl-3 py-2" :class="{ primary: isChecked, disabled }">
		<input
			type="checkbox"
			:checked="isChecked"
			:value="value"
			:disabled="disabled"
			@change="updateInput"/>

		<span class="checkmark"></span>

		<slot name="icon" />

		<div class="ml-5" style="width: 100%">
			{{ label }}
			<slot />
		</div>
	</label>
</template>

<script>
export default {
	name: 'FarmeCustomInputCheckbox',
	model: {
		prop: 'modelValue',
		event: 'change'
	},
	props: {
		value: { type: String },
		disabled: { type: Boolean, default: false },
		modelValue: { default: '' },
		label: { type: String, required: false, default: '' },
		trueValue: { default: true },
		falseValue: { default: false }
	},
	computed: {
		isChecked() {
			if (this.modelValue instanceof Array)
				return this.modelValue.includes(this.value);
			return this.modelValue === this.trueValue;
		}
	},
	methods: {
		updateInput(event) {
			const isChecked = event.target.checked;
			if (this.modelValue instanceof Array) {
				const newValue = [...this.modelValue];
				if (isChecked)
					newValue.push(this.value);
				else
					newValue.splice(newValue.indexOf(this.value), 1);

				this.$emit('change', newValue);
			} else
				this.$emit('change', isChecked ? this.trueValue : this.falseValue);
		}
	}
};
</script>

<style lang="scss" scoped>
	.label {
		display: flex;
		position: relative;
		cursor: pointer;
		user-select: none;
		width: 100%;
		border: 2px dashed #342b1d;
		border-radius: 5px;
		background: transparent;
		display: flex;
		justify-items: center;
		align-items: center;
		align-content: center;

		&:hover {
			background-color: #ffc875;
			color: #342b1d !important;
			border-color: #ffc875;
			// background-color: #342b1d;
			// color: #ffc875 !important;
		}

		/* Hide the browser's default checkbox */
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
			text-align: center;
		}
	}

	.disabled {
		cursor: not-allowed !important;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		height: 22px;
		width: 22px;
		margin-left: 25px;
		border-radius: 100%;
	}

	/* When the checkbox is checked, add a blue background */
	.label input:checked ~ .checkmark {
		background-color: #12c963;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.label input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.label .checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
</style>
