<template>
	<Dialog titulo="Cancelar assinatura" :subtitulo="assinatura.paciente.nome"
		btn_acao="Cancelar" btn_acao_class="error" :loading="cancelando"
		@acao="cancelar" @close="$emit('close')">
		<p>Por favor, selecione abaixo o motivo do cancelamento desta assinatura</p>
		<v-select v-model="motivo_cancelamento" box label="Motivo" :items="opcoes" />
	</Dialog>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';

export default {
	name: 'DialogCancelarAssinatura',
	props: {
		assinatura: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		cancelando: false,
		motivo_cancelamento: null,
		opcoes: [
			'Suspensão de medicamentos contínuos',
			'Fora da área de abrangência',
			'Falecimento',
			'Adquire medicamento sem custo',
			'Desqualificado (recorrência diferente de 30 dias)',
			'Inadimplência',
			'Preço',
			'Falha de comunicação (ILPI/Familiar)',
			'Não conseguimos contato da ILPI',
			'Insatisfação com atendimento',
			'Insatisfação com o produto',
			'Insatisfação com a forma de pagamento',
			'Alinhamento inadequado na negociação',
			'Adaptação ao nosso serviço (uso da box)',
			'Adaptação ao nosso serviço (alterações frequentes de medicação)',
			'Adaptação ao nosso serviço (processo de coleta de receita)',
			'Sem retorno do cliente (não recebeu a 1º box)',
			'Sem retorno do cliente (após novo orçamento)',
			'Sem retorno do cliente (após pedido da receita)',
			'Internação',
			'Não conseguimos comprar o medicamento',
			'Não aceite comercial da ILPI',
			'Estoque',
			'Insatisfação ao seguir a box sem o controlado',
			'Insatisfação com a data variável de pagamento',
			'Insatisfação campanha reajuste preço 23',
			'Insatisfação campanha preço fixo 12 para 3 meses',
			'Fraude'
		]
	}),
	methods: {
		cancelar() {
			this.cancelando = true;
			AssinaturaPresenter.destroy(this.assinatura.id, this.motivo_cancelamento)
				.then(() => {
					this.$emit('cancelada');
				})
				.finally(() => {
					this.cancelando = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
