import { getState } from './state';

export const CLEAR_STATE = state => {
	Object.assign(state, getState());
};

export const SET_ASSINATURA = (state, assinatura) => {
	state.assinatura = assinatura;
};

export const SET_HISTORICO = (state, historico) => {
	state.historico_boxes = historico;
};

export const SET_DATA_PROXIMA_BOX = (state, data_proxima_box) => {
	state.assinatura.data_proxima_box = data_proxima_box;
};

export const SET_LOADING = (state, bool) => {
	state.loading = bool;
};

export const SET_IUGU_ID = (state, iugu_id) => {
	state.assinatura.iugu_id = iugu_id;
};
