<template>
	<transition>
		<v-card
			style="
				border-radius: 0 !important;
				background-color: rgba(255, 200, 117, 0.5) !important;
			">
			<v-toolbar dark flat class="purple-title toolbarFlex" height="180px">
				<div class="container">
					<v-layout align-center justify-center>
						<v-layout column>
							<v-flex>
								<v-toolbar-title style="color: #342b1d" class="titulo display-1">Consultar/alterar tabela PMC</v-toolbar-title>
							</v-flex>
						</v-layout>
						<v-btn style="color: #342b1d; border-radius: 5px" outline flat @click="$emit('closeTable')">
							Voltar <v-icon small right>close</v-icon>
						</v-btn>
					</v-layout>

				</div>
			</v-toolbar>

			<v-card-text class="cardBody">
				<div class="container">
					<form>
						<section class="px-3">
							<v-layout wrap>
								<v-flex xs12>
									<span style="font-weight: 600">Produto</span>
									<v-text-field
										v-model="form.product.name"
										class="pt-2"
										background-color="white"
										box
										disabled/>
								</v-flex>
								<v-flex xs12>
									<span style="font-weight: 600">Apresentação</span>
									<v-text-field
										v-model="form.product.presentation"
										class="pt-2"
										background-color="white"
										box
										disabled/>
								</v-flex>
								<v-flex xs12>
									<span style="font-weight: 600">Classe terapêutica</span>
									<v-text-field
										v-model="form.medicine.atcName"
										class="pt-2"
										background-color="white"
										box
										disabled/>
								</v-flex>
								<v-flex xs12>
									<span style="font-weight: 600">Regime de preço</span>
									<v-text-field
										value="Regulado"
										class="pt-2"
										background-color="white"
										box
										disabled/>
								</v-flex>
							</v-layout>
						</section>
						<FarmeDivider/>

						<section>
							<v-layout wrap>
								<v-flex md4 xs12 class="px-3" v-for="k in Object.keys(pfsLabel)" :key="`pf${k}`">
									<span style="font-weight: 600">PF {{ pfsLabel[k] }}</span>
									<v-text-field
										v-model="form.medicine.pf[k]"
										class="pt-2"
										background-color="white"
										box
										type="number"
										v-validate="{ required: false }"/>
								</v-flex>
							</v-layout>
						</section>
						<FarmeDivider/>

						<section>
							<v-layout wrap>
								<v-flex md4 xs12 class="px-3" v-for="k in Object.keys(pmcsLabel)" :key="`pmc${k}`">
									<span style="font-weight: 600">PMC {{ pmcsLabel[k] }}</span>
									<v-text-field
										v-model="form.medicine.pmc[k]"
										class="pt-2"
										background-color="white"
										box
										type="number"
										v-validate="{ required: false }"/>
								</v-flex>
							</v-layout>
						</section>

					</form>

					<FarmeDivider />

					<v-layout align-center justify-space-between>
						<v-btn
							flat
							@click="$emit('closeTable')">Cancelar</v-btn>

						<section>
							<v-btn
								color="primary"
								@click="$emit('closeTable')">
								Salvar
							</v-btn>
						</section>
					</v-layout>
				</div>
			</v-card-text>
		</v-card>
	</transition>
</template>

<script>
import FarmeDivider from '@Componentes/farme-divider.vue';
import { pmcsLabel } from '@Consts/product/pmc';
import { pfsLabel } from '@Consts/product/pf';
import { itemType } from '@Consts/product/item-type';

export default {
	name: 'PmcPfTable',
	$_veeValidate: { validator: 'new' },
	components: {
		FarmeDivider
	},
	props: {
		form: {
			type: Object,
			required: true
		},
		isNew: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		pmcsLabel,
		pfsLabel,
		itemType
	})
};
</script>

<style lang="scss" scoped>
.container {
	max-width: 800px !important;
	min-width: 800px !important;
	padding: 24px 0px;
}

.toolbarFlex {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	justify-content: center !important;
}

.cardBody {
	background-color: rgba(255, 234, 204, 1);
	height: 100vh;
}
</style>
