<template>
	<v-card  color="#FFF3E2"
		class="cardStyle sans pb-sm-3 pt-sm-3 px-sm-4 mb-2">
		<v-layout align-center>
			<p class="font-weight-bold mb-2 pr-4" style="font-size: 20px">{{ quantity }}</p>
			<v-flex>
				<span class="smallTitle">{{ nameActiveIngredient(product) }}</span>
				<p class="font-weight-bold mb-0" style="font-size: 16px">{{ product.name }}</p>
				<p class="mb-0 smallTitle"> {{ product.presentation }} </p>
			</v-flex>
			<div class="medicine-container">
				<v-flex class="pl-2 px-sm-4" d-flex>
					<span :style="`color: #857B6B`" class="medicine-value px-sm-2 mt-xs-1 py-sm-2"
						:color="'#FFF3E2'">
						{{ item.priceSku | dinheiro }}
					</span>
					<span :style="`color: #039800 `" class="medicine-value px-sm-2 mt-xs-1 py-sm-2"
						:color="'#FFF3E2'">
						{{ discountValue | dinheiro }}
					</span>
					<span :style="`color: #342B1D `" class="medicine-value px-sm-2 mt-xs-1 py-sm-2"
						:color="'#FFF3E2'">
						{{ totalValue | dinheiro }}
					</span>
				</v-flex>
			</div>
		</v-layout>
	</v-card>
</template>

<script>
import { getQuantityByProductItem } from '@Util/ProductService';
import Mixin from './mixin-data-table';

export default {
	name: 'DetailB2CProduct',
	mixins: [Mixin],
	props: {
		item: {
			type: Object,
			default: () => {}
		},
		product: {
			type: Object,
			default: () => {}
		}
	},
	methods: {
		nameActiveIngredient(product) {
			if (product.drug && product.drug.compositions)
				return product.drug.compositions.map(item => item.activeIngredient.name).join(' + ');
			return '';
		}
	},
	computed: {
		discountValue() {
			return this.item.discount;
		},
		totalValue() {
			return this.item.price;
		},
		quantityByProductItem() {
			const closedPackages = this.getClosedPackages(this.item);

			return getQuantityByProductItem(this.item.posology, closedPackages);
		},
		quantity() {
			const result = this.getNumberPackagesNeeded(this.quantityByProductItem, this.item);

			return result.toFixed(2);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.cardStyle {
	width: 100%;
	border-radius: 0px 10px 10px 0px;
	color: rgba(52, 43, 29, 1);
}

.smallTitle {
	font-size: 10px
}

.medicine-container {
	width: 370px;

	.medicine-value {
		font-weight: 600;
		font-size: 16px;
		text-align: center;
	}
}
</style>
