<template>
	<v-layout wrap>
		<v-flex xs12 class="d-flex items-center justify-start">
			<span class="inter text-bold body-1">Dados residenciais</span>
		</v-flex>
		<v-flex md6 xs12>
			<v-text-field
				v-model="address.zipCode"
				box
				background-color="white"
				label="CEP *"
				append-icon="search"
				mask="#####-###"
				data-vv-name="CEP"
				:error-messages="errors.collect('CEP')"
				v-validate="{ required: true, numeric: true, min: 8 }"
				@click:append="searchCep()"
				@keyup.enter="searchCep()"
				@blur="searchCep()"/>
		</v-flex>
		<v-flex md6 xs12>
			<v-text-field
				v-model="address.neighborhood"
				box
				background-color="white"
				label="Bairro"
				data-vv-name="Bairro"
				:error-messages="errors.collect('Bairro')"
				v-validate="{ required: true }"/>
		</v-flex>
		<v-flex md6 xs12>
			<v-text-field
				v-model="address.street"
				box
				background-color="white"
				label="Rua"
				data-vv-name="Rua"
				:error-messages="errors.collect('Rua')"
				v-validate="{ required: true }"/>
		</v-flex>
		<v-flex md6 xs12>
			<v-text-field
				v-model="address.city"
				box
				background-color="white"
				label="Cidade"
				data-vv-name="Cidade"
				:error-messages="errors.collect('Cidade')"
				v-validate="{ required: true }"/>
		</v-flex>
		<v-flex md4 xs12>
			<v-text-field
				v-model="address.addressNumber"
				box
				background-color="white"
				label="Número *"
				data-vv-name="Número"
				:error-messages="errors.collect('Número')"
				v-validate="{ required: true }"/>
		</v-flex>
		<v-flex md4 xs12>
			<v-text-field
				v-model="address.complement"
				box
				background-color="white"
				label="Complemento"/>
		</v-flex>
		<v-flex md4 xs12>
			<v-text-field
				v-model="address.state"
				box
				background-color="white"
				label="Estado"
				data-vv-name="Estado"
				:error-messages="errors.collect('Estado')"
				v-validate="{ required: true }"/>
		</v-flex>
	</v-layout>
</template>

<script>
import { EnderecoPresenter } from '@Presenter/endereco-presenter';
import Mixin from '../mixin';

export default {
	name: 'Address',
	$_veeValidate: { validator: 'new' },
	mixins: [Mixin],
	data: () => ({
		loadingZipcode: false,
		address: {}
	}),
	created() {
		if (!this.isNewQuotation && this.quotation.address)
			this.address = this.quotation.address;
	},
	watch: {
		address: {
			handler(newVal) {
				if (newVal)
					this.quotation.address = newVal;
			},
			deep: true
		}
	},
	methods: {
		searchCep() {
			const zipcode = this.$functions.onlyNumber(this.address.zipCode);
			if (zipcode.length !== 8) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'CEP deve ter 8 dígitos.'
				});
				return;
			}
			this.loadingZipcode = true;
			EnderecoPresenter.buscaCep(zipcode)
				.then(address => {
					this.address = {
						zipCode: address.cep,
						neighborhood: address.bairro,
						street: address.logradouro,
						city: address.cidade,
						state: address.estado
					};
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'CEP não encontrado'
					});
				})
				.finally(() => {
					this.loadingZipcode = false;
				});
		},
		async validate() {
			return Boolean(await this.$validator.validateAll());
		}
	}
};
</script>
