import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions';
import * as getters from './getters';
import { getState } from './state';
import bipe from './bipe';
import assinatura from './assinatura';

import mutations from './mutations';

Vue.use(Vuex);

export default new Vuex.Store({
	state: getState(),
	actions,
	getters,
	mutations,
	modules: {
		bipe,
		assinatura
	}
});
