<template>
	<v-card>
		<v-card-title>
			<v-avatar size="36" class="mr-3">
				<v-icon color="purple" small>fa-solid fa-money-check</v-icon>
			</v-avatar>
			<h3 class="text--text mr-2">Novos orçamentos em análise</h3>
			<v-spacer />
			<v-btn small depressed to="/colaborador/orcamentos">Ver todos</v-btn>
		</v-card-title>
		<v-card-text class="pt-0">
			<div v-if="carregando" class="carregando">
				<v-progress-circular indeterminate color="grey" />
			</div>
			<div v-else>
				<div v-if="orcamentos.length === 0" class="carregando">
					<v-icon color="grey" class="mb-1" style="font-size: 32px; opacity: .5">check_circle_outline</v-icon>
					<p class="mb-0">Nenhum orçamento em análise.</p>
				</div>
				<v-list v-else two-line>
					<v-list-tile v-for="item in orcamentos" :key="item.id">
						<v-list-tile-content>
							<v-list-tile-title><strong class="grey--text text--darken-1">Orçamento {{ item.id }}</strong>
								- {{ item.nome }}</v-list-tile-title>
							<v-list-tile-sub-title>
								<strong>Data:</strong> {{ item.created_at }}
								<span v-if="(item.vendedor || {}).nome">
									- <strong>Vendedor:</strong> {{ (item.vendedor || {}).nome || '-' }}
								</span>
							</v-list-tile-sub-title>
						</v-list-tile-content>
						<v-list-tile-action v-if="$vuetify.breakpoint.lgAndUp">
							<v-chip small class="info white--text">{{ item.valor_total | dinheiro }}</v-chip>
						</v-list-tile-action>
					</v-list-tile>
				</v-list>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';

export default {
	name: 'Orcamentos',
	data: () => ({
		orcamentos: [],
		carregando: true
	}),
	async created() {
		const { items } = await OrcamentoPresenter.index({ status: 'UNDER_ANALYSIS', rowsPerPage: 10 });
		this.orcamentos = items;
		this.carregando = false;
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.v-card__text {
  overflow-y: auto;
  height: 300px;
}
.v-card__title {
  .v-avatar {
    background: rgba($primary, 0.2) !important;
  }
}
</style>
