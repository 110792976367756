<template>
	<v-card>
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="#342B1D" small>fas fa-history</v-icon>
			</v-avatar>
			<h3>Histórico de Processos</h3>
		</v-card-title>

		<v-card-text>
			<v-data-table
				disable-initial-sort
				:headers="headers"
				:items="items">
				<template v-slot:items="props">
					<tr class="pointer">
						<td class="pl-0">{{ getUserDescription(props.item.metadata) }}</td>
						<td class="pl-0">{{ getProcessDescription(props.item.typeName) }}</td>
						<td class="pl-0">{{ getStatusDescription(props.item.status) }}</td>
						<td width="190">
							{{ props.item.updatedAt | formatDate('DD/MM/YYYY HH:mm') }}
						</td>
					<tr/>
				</template>
			</v-data-table>
		</v-card-text>

	</v-card>
</template>

<script>
import { getDescriptionProcessType, getDescriptionProcessStatus } from '@Consts/status-order-process';

export default {
	name: 'OrderProcessHistory',
	props: {
		items: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	computed: {
		headers() {
			return [
				{
					text: 'Usuário', sortable: false, class: 'pl-0'
				},
				{
					text: 'Processo', value: 'typeName', sortable: false, class: 'pl-0'
				},
				{
					text: 'Status', value: 'status', sortable: false, class: 'pl-0'
				},
				{ text: 'Última atualização', value: 'updatedAt', sortable: true }
			];
		}
	},
	methods: {
		getStatusDescription(status) {
			return getDescriptionProcessStatus(status);
		},
		getProcessDescription(process) {
			return getDescriptionProcessType(process);
		},
		getUserDescription(metadata) {
			if (!metadata || !metadata.user)
				return 'sistema@farme.com.br';

			return metadata.user.email;
		}
	}
};
</script>

<style scoped>
h3 {
	color: rgba(0, 0, 0, 0.87);
	font-family: Inter;
	font-size: 13.38px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.57px;
}
</style>
