import { cloneDeep } from 'lodash';
import {
	COLABORADOR, False, True
} from '@Consts/strings';
import * as types from './mutation-types';
import { getState } from './state';

export default {
	[types.NOVABOX](state, novabox) {
		state.novabox = cloneDeep(novabox);
	},

	[types.SNACKBAR](state, config) {
		state.snackbar = {
			visible: true,
			message: '',
			color: 'success',
			timeout: 3000,
			...config
		};
		state.msg_toast = null;
	},

	[types.CONTEXTO](state, contexto) {
		state.contexto = cloneDeep(contexto);
	},

	[types.SET_VISAO_COLABORADOR](state, bool) {
		state.visao_colaborador = bool;
		localStorage.setItem(COLABORADOR, bool ? True : False);
	},

	[types.MSG_TOAST](state, mensagem) {
		state.msg_toast = mensagem;
	},

	[types.SET_BOX_ID](state, box_id) {
		state.box_id = box_id;
	},

	[types.CLEAR_STATE](state) {
		Object.assign(state, getState());
	},
	[types.SET_FEATURE_FLAGS](state, unleash) {
		const featureFlagNames = Object.keys(state.featureFlags);
		featureFlagNames.forEach(featureFlagName => {
			state.featureFlags[featureFlagName].enabled = unleash.isEnabled(featureFlagName);
			state.featureFlags[featureFlagName].variant = unleash.getVariant(featureFlagName);
		});
	}
};
