export const pmcsLabel = Object.freeze({
	pmcWithoutTax: 'Sem Imposto',
	pmc0: '0%',
	pmc12: '12%',
	pmc12Alc: '12% ALC',
	pmc17: '17%',
	pmc17Alc: '17% ALC',
	pmc17_5: '17,5%',
	pmc17_5Alc: '17,5% ALC',
	pmc18: '18%',
	pmc18Alc: '18% ALC',
	pmc19: '19%',
	pmc19Alc: '19% ALC',
	pmc19_5: '19,5%',
	pmc19_5Alc: '19,5% ALC',
	pmc20: '20%',
	pmc20Alc: '20% ALC',
	pmc20_5: '20,5%',
	pmc21: '21%',
	pmc21Alc: '21% ALC',
	pmc22: '22%',
	pmc22Alc: '22% ALC'
});
