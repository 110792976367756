const { origin, host } = window.location;
import { envs, localStorageEnvKey } from '@Config/environments';

let env = envs.Local;

if (['www.farmebox.com.br', 'farmebox.com.br'].includes(host))
	env = envs.Prod;
else if (origin.includes('lab'))
	env = envs.Lab;
else if (origin.includes('dev'))
	env = envs.Dev;
else if (origin.includes('staging'))
	env = envs.Staging;

localStorage.setItem(localStorageEnvKey, env);
