<template>
	<header
		:style="`border-color: ${getHeaderBorderColor}`">
		<button
			@click="reload"
			:class="[{'light': isInProgress}]">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
				class="size-6">
				<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
			</svg>

			Voltar
		</button>
	</header>
</template>

<script>
import { BoxProductionMixin } from '@Mixins/box-production';

export default {
	mixins: [BoxProductionMixin],
	computed: {
		getHeaderBorderColor() {
			if (this.isFinished)
				return '#342B1D33';
			if (this.isInProgress)
				return '#FFFFFF';
			return '#FFEACC';
		}
	},
	methods: {
		reload() {
			this.$router.go();
		}
	}
};
</script>

<style lang="scss" scoped>
header {
	width: 100%;
	padding: 10px 0;
	border-bottom-width: 5px;
	border-bottom-style: solid;
	display: flex;
	justify-content: end;
	margin-bottom: 50px;
	color: #342B1D;

	button {
		padding: 5px 15px ;
		line-height: 22px;
		display: flex;
		align-items: center;
		gap: 20px;
		border: 2px solid #342B1D;
		border-radius: 8px !important;
		padding: 12px, 21px, 12px, 14px;
		font-weight: 700 !important;

		&.light {
			border: 2px solid white !important;
			color: white !important;
		}

		svg {
			width: 22px;
			height: 22px;
			color: inherit;
		}
	}
}
</style>
