<template>
	<v-flex>
		<v-layout v-for="(item, i) in this.itemsEdited" :key="i" wrap>
			<v-flex d-flex items-center style="gap: 15px">
				<div style="width: 55%;">
					<span style="color: #888888;" v-if="i===0">Item</span>
					<v-combobox
						@update="(value) => changeItem(i, value)"
						v-model="item.description"
						:search-input.sync="item.description"
						background-color="white"
						box hide-details
						:items="searchedProducts"
						:loading="loadingIndex === i">
					</v-combobox>

				</div>
				<div align-center style="flex-direction: column; width: 12%;"  class="items-center">
					<span style="color: #888888;" v-if="i===0">Qtd.</span>
					<v-text-field
						type="number"
						background-color="white"
						box hide-details v-model="item.quantity"/>
				</div>
				<div  style="flex-direction: column;"  class="items-center" >
					<span style="color: #888888;" v-if="i===0">Preço</span>
					<v-text-field
						background-color="white" v-money
						box hide-details v-model="item.amount"/>
				</div>
				<div   class="items-center" style="flex-direction: column;"  >
					<span style="color: #888888;" v-if="i===0">Desconto</span>
					<v-text-field
						background-color="white" v-money
						box hide-details v-model="item.discount"/>
				</div>
				<div   class="items-center"  style="flex-direction: column;"  >
					<span style="color: #888888;" v-if="i===0">Subtotal</span>
					<v-text-field v-money disabled
						background-color="white"
						box hide-details v-model="productsWithSubTotal[i].subTotal"/>
				</div>
				<v-avatar @click="deleteItem({index: i})" color="rgba(229, 0, 0, 0.1)" size="30" class="mr-2" style="display: flex; justify-content: center; cursor: pointer;" >
					<v-icon color="red" size="12">fas fa-trash</v-icon>
				</v-avatar>
			</v-flex>
		</v-layout>
		<v-btn small depressed class="primary mx-0 mt-3" @click="addItem()">
			<v-icon small class="mr-2">add</v-icon>
			Adicionar medicamento
		</v-btn>
	</v-flex>
</template>

<script>
import { getCurrentCompanyUnit } from '@Config/unidades';
import { ProductPresenter } from '@Presenter/product-presenter';
import { onlyNumber } from '@Util/functions';
import { debounce } from 'lodash';

export default {
	name: 'ProductsEdition',
	props: {
		items: {
			required: false,
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		itemsEdited: [],
		oldProducts: [],
		searchedProducts: [],
		loadingIndex: '',
		search: ''
	}),
	created() {
		this.itemsEdited = this.items;
	},
	methods: {
		addItem() {
			this.itemsEdited.push({ description: '' });
		},
		deleteItem({ index }) {
			this.itemsEdited = this.itemsEdited.filter((_, i) => i !== index);
			this.$emit('edit-products', this.itemsEdited);
		},
		subTotalByItem(item) {
			return ((onlyNumber(item.amount) - onlyNumber(item.discount)) * (item.quantity));
		},
		async changeItem(index, newValue) {
			this.itemsEdited[index].description = newValue;
			this.searchedProducts = [];
		},
		async fetchProducts(text) {
			if (text?.toString()?.trim(' ')?.length < 3) {
				this.searchedProducts = [];
				return;
			}
			const productParams = {
				q: text,
				companyUnitId: getCurrentCompanyUnit().id,
				perPage: 5,
				page: 1,
				sortBy: 'name',
				sortDirection: 'ASC'
			};

			const productsResponse = await ProductPresenter.getProducts(productParams);
			this.searchedProducts = productsResponse.data.items.map(p => `${p.name} ${p.presentation}`);
			this.loadingIndex = '';
		}
	},
	computed: {
		productsWithSubTotal() {
			return this.itemsEdited.map(item => ({
				...item,
				subTotal: this.subTotalByItem(item)
			}));
		}
	},
	watch: {
		itemsEdited: {
			async handler(productsEdited) {
				const debouncedFetchProducts = debounce(this.fetchProducts, 750);
				let editedString = '';
				for (let index = 0; index < productsEdited.length; index++) {
					const newProduct = productsEdited[index];
					if (newProduct.description !== this.oldProducts[index]?.description) {
						this.loadingIndex = index;
						editedString = newProduct.description;
						break;
					}
				}
				if (editedString.length < 3)
					return;

				await debouncedFetchProducts(editedString);
				this.loadingIndex = '';
				this.oldProducts = JSON.parse(JSON.stringify(productsEdited));
				this.$emit('edit-products', productsEdited);
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
