export const events = Object.freeze({
	CREATED: 'created',
	UPDATED: 'updated',
	DELETED: 'deleted'
});

const labels = Object.freeze({
	[events.CREATED]: 'Adicionou',
	[events.UPDATED]: 'Alterou',
	[events.DELETED]: 'Excluíu'
});

export function getAuditLabelByEvent(event) {
	return labels[event] || 'Evento desconhecido';
}
