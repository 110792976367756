import $store from '@Store';
import axios from 'axios';
import $http from '@Config/axios';
import { statesWithSymbol } from '@Consts/estados';
import { buildQueryParams } from 'luiz-fns';
import { DefaultPresenter } from './default-presenter';

const prefix = 'endereco';
const entity = 'Endereço';

/**
 * @param {String} zipCpde
 * @returns
 */
function getAddressByZipcode(zipCpde) {
	const queryParams = { cep: zipCpde };
	return $http.get(buildQueryParams(`${prefix}/busca`, queryParams));
}

export const EnderecoPresenter = {
	...DefaultPresenter({ prefix, entity }),
	buscaCep(cep) {
		return new Promise((resolve, reject) => {
			axios.get(`https://viacep.com.br/ws/${cep}/json/`)
				.then(response => {
					let endereco = { ...response.data };
					if (!endereco.erro) {
						endereco = {
							...endereco,
							cep: endereco.cep.replace(/\D/g, ''),
							cidade: endereco.localidade,
							estado: statesWithSymbol[endereco.uf]
						};
						delete endereco.gia;
						delete endereco.unidade;
					} else {
						$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'CEP não encontrado' });
						endereco = { cep };
					}
					resolve(endereco);
				})
				.catch(response => reject(response));
		});
	},
	editarEndereco: (id, params) => $http.put(`${prefix}/${id}`, params),
	getAddressByZipcode
};
