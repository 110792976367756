<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar v-if="icone" size="38" class="mr-3">
				<v-icon color="white" small>
					{{ icone }}
				</v-icon>
			</v-avatar>
			<h3>{{ titulo }}</h3>
			<div class="slot-icon">
				<slot name="icon" />
			</div>
		</v-card-title>
		<v-card-text class="pt-2">
			<slot />
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'Card',
	props: {
		titulo: {
			type: String,
			required: true
		},
		icone: {
			type: String,
			default: ''
		}
	},
	data: () => ({})
};
</script>

<style lang="scss" scoped>
.slot-icon {
	position: absolute;
	right: 10px;
	top: 10px;
}
</style>
