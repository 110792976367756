<template>
	<Dialog titulo="Upload Nota Fiscal" :titulo_extra="customer.name" subtitulo="Escolha a nota fiscal (XML) para fazer upload"
		btn_acao="Enviar para a expedição" btn_cancelar="Cancelar" :btn_acao_disable="disableBtnByProccess"
		@acao="dialog_confirmacao = true" @close="close">

		<v-container v-if="existe_medicamentos" fluid grid-list-lg class="pa-0">
			<v-layout wrap align-center>
				<v-flex v-if="upload_feito" xs3 class="mr-2" style="max-width: max-content">
					<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
						<path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
					</svg>
				</v-flex>
				<v-flex v-if="upload_feito" xs9>
					<h3>Upload feito com sucesso.</h3>
					<p class="mb-0">
						<strong>{{ nfData.medicines.length }}</strong>
						{{ nfData.medicines.length === 1 ? 'medicamento' : 'medicamentos' }}.
					</p>
				</v-flex>
				<v-flex xs12>
					<v-data-table :headers="headers" :items="nfData.medicines" no-results-text="Nenhum resultado encontrado">
						<template slot="items" slot-scope="props">
							<tr class="text-xs-left">
								<td>{{ props.item.gtin }}</td>
								<td>{{ props.item.product }}</td>
								<td>{{ props.item.batch }}</td>
								<td>{{ props.item.expirationDate }}</td>
							</tr>
						</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</v-container>

		<v-container fluid grid-list-lg class="pa-0">
			<v-progress-linear v-if="is_loading" color="purple" indeterminate />
			<template v-else>
				<input @change="carregarXML" type="file" ref="upload" style="display: none" accept="text/xml" />
				<p v-if="uploading" class="mt-2 text-xs-center">
					<strong>Carregando a nota fiscal, por favor aguarde...</strong>
				</p>
				<v-btn block depressed class="ml-0 green white--text" @click="$refs.upload.click()">
					<v-icon small class="mr-2">cloud_upload</v-icon>
					{{ upload_feito?'Alterar': 'Escolher'}} nota fiscal (XML)
				</v-btn>
			</template>
		</v-container>

		<v-dialog persistent scrollable v-model="dialog_confirmacao" transition="fade" max-width="500px">
			<transition>
				<DialogEnviarExpedicao v-if="dialog_confirmacao" @close="dialog_confirmacao = false"
					:changeStatusProcess="changeStatusProcess"
					tituloOpcional="Enviar para a expedição" :orderId="order_id" @disableBtn="val => disableBtnByProccess = val"/>
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import { validateProcessIsAllowed } from '@Util/order-process';
import { ProcessTypes } from '@Consts/status-order-process';
import DialogEnviarExpedicao from './dialog-enviar-expedicao';

export default {
	name: 'DialogUploadNota',
	components: { DialogEnviarExpedicao },
	props: {
		order_id: {
			type: Number,
			required: true
		},
		request: {
			type: Function
		}
	},
	data: () => ({
		dialog_confirmacao: false,
		customer: {},
		nfData: {
			medicines: []
		},
		uploading: false,
		is_loading: false,
		upload_feito: false,
		disableBtnByProccess: true,
		headers: [
			{ text: 'GTIN', value: 'gtin' },
			{ text: 'Produto', value: 'product' },
			{ text: 'Lote', value: 'batch', sortable: false },
			{ text: 'Validade', value: 'expirationDate', sortable: false }
		]
	}),
	created() {
		this.getProcessByOrderAndProcessType(this.order_id);
	},
	methods: {
		close() {
			this.$emit('update:close', false);
		},
		carregarXML(input) {
			this.uploading = true;
			const xml = input.target.files[0];
			if (!xml)
				return;
			const reader = new FileReader();

			reader.onload = e => {
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(e.target.result, 'text/xml');
				const json = this.$functions.xmlToJson(xmlDoc);

				const infNFe = json.nfeProc.NFe.infNFe;
				const items = infNFe.det.length ? infNFe.det : [infNFe.det];

				this.nfData = {
					number: infNFe.ide.nNF,
					serialNumber: infNFe.ide.serie,
					amount: infNFe.pag.detPag.vPag,
					issuingCity: infNFe.emit.enderEmit.xMun,
					accessKey: infNFe['@attributes'].Id,
					fileName: xml.name,
					medicines: items.filter(d => !!d.prod.rastro)
						.reduce((prev, d) => {
							const rastro = d.prod.rastro.length ? d.prod.rastro : [d.prod.rastro];
							rastro.forEach(ras => {
								prev.push({
									product: d.prod.xProd,
									sku: d.prod.cProd,
									gtin: d.prod.cEAN,
									quantity: d.prod.qCom,
									unitPrice: d.prod.vUnCom,
									batch: ras.nLote,
									expirationDate: this.$functions.FormatDateFromRequest(ras.dVal)
								});
							});
							return prev;
						}, [])
						.sort((a, b) => (a.product > b.product ? 1 : -1))
				};
				this.$emit('changeOrderNF', this.nfData);
			};
			reader.readAsText(xml);
			this.upload_feito = true;
			this.uploading = false;
		},
		changeStatusProcess() {
			return this.request()
				.then(() => {
					this.close();
				});
		},
		getProcessByOrderAndProcessType(orderId) {
			this.disableBtnByProccess = true;
			validateProcessIsAllowed(orderId, ProcessTypes.BILLING)
				.then(isValid => {
					this.disableBtnByProccess = !isValid;
				});
		}
	},
	computed: {
		existe_medicamentos() {
			return this.nfData.medicines.length > 0;
		}
	}
};
</script>

<style lang="scss" scoped>
$green: #7ac142;
$white: #fff;
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $green;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}
</style>
