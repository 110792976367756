import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = 'user';
const PREFIX_V2 = 'v2/users';

/**
 * @typedef {Object} UserProducingItem
 * @property {Number} id
 * @property {String} name
 * @property {String} email
 *
 * @typedef {Array<UserProducingItem} UserProducingItems
 *
 * @typedef {Object} UsersProducingResponse
 * @property {Number} total
 * @property {UserProducingItems} items
 * @returns {Promise<UsersProducingResponse>}
 */
async function getUsersProducing() {
	const usersProducingResponse = await $http.get(`${PREFIX_V2}/producing`);
	return usersProducingResponse;
}

export const UserPresenter = {
	index: params => $http.get(buildQueryParams(prefix, params)),
	update: params => $http.put(`${prefix}/${params.id}`, params),
	register: params => $http.post(`${prefix}/register`, params),
	login: params => $http.post(`${prefix}/login`, params),
	showAsPaciente: id => $http.get(`${prefix}/${id}`),
	atualizarSenha: senhas => $http.post(`${prefix}/atualizarSenha`, senhas),
	atualizarEmail: params => $http.post(`${prefix}/atualizarEmail`, params),
	confirmaEmailVerificado: params => $http.post(`${prefix}/confirmaEmailVerificado`, params),
	redefineSenha: params => $http.post(`${prefix}/redefineSenha`, params),
	criarSenha: params => $http.post(`${prefix}/criarSenha`, params),
	enviaLinkResetSenha: params => $http.post(`${prefix}/enviaLinkResetSenha`, params),
	enviaLinkConfirmaEmail: params => $http.post(`${prefix}/enviaLinkConfirmaEmail`, params),
	search: params => $http.post(`${prefix}/search`, params),
	vendedores: () => $http.get(`${prefix}/vendedores`),
	count: () => $http.get(`${prefix}/count`),
	getUsersProducing
};
