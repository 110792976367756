import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';
import { DefaultPresenter } from './default-presenter';

const prefix = 'logistica';
const entity = 'Box';

export const LogisticaPresenter = {
	...DefaultPresenter({ prefix, entity }),
	atrasarBoxes: (params = {}) => $http.post(`${prefix}/atraso`, { ...params }),
	entregarBoxes: (params = {}) => $http.post(`${prefix}/entrega`, { ...params }),
	enviarBoxes: (params = {}) => $http.post(`${prefix}/enviar`, { ...params }),
	v2: {
		/**
		* @param {object} params
		* @param {String} params.searchIds
		* @param {String} params.search
		* @param {Number} params.branch
		* @param {Array<Number>} params.demand_regions_ids
		* @param {Array<String>} params.statuses
		* @param {Array<String>} params.types
		* @param {Array<Number>} params.ilpiIds
		* @param {Number} params.page
		* @param {Number} params.perPage
		*/
		getShippingData: (params = {}) => {
			const queryParams = {
				branch: params.branch,
				searchIds: params.searchIds,
				search: params.search,
				types: params.types,
				demand_regions_ids: params.demand_regions_ids,
				statuses: params.statuses,
				ilpiIds: params.ilpiIds,
				page: params.page,
				perPage: params.perPage
			};
			return $http.get(buildQueryParams(`${prefix}/v2/shipping/itens`, queryParams));
		},
		/**
		 * @param {object} params
		 * @param {Number} params.page - Current page
		 * @param {Number} params.perPage - Itens per page
		 * @param {String} params.status - Filter by order status [PENDING,STARTED,FINISHED,CONFIRMED,REJECTED,CANCELLED,EXPIRED]
		 * @param {Number} params.days - Filter by days since created (last X days)
		 * @param {Number} params.branch - Filter by filialId (BH | SP)
		 * @param {Number} params.volumeIds - Filter by volume Ids
		 * @param {Array<String>} params.fleets - Filter by order fleet
		*/
		getOrders: (params = {}) => $http.get(buildQueryParams(`${prefix}/v2/orders`, params)),
		getOrder: id => $http.get(`${prefix}/v2/orders/${id}`),

		/**
		 *
		 * @param {object} params
		 * @param {Array} params.boxIds
		 * @param {Array} params.orderIds
		 * @returns
		 */
		getQuotations: (params = {}) => $http.post(`${prefix}/v2/orders/quotations`, {
			box_ids: params.box_ids,
			order_ids: params.order_ids
		}),
		/**
		 * @param {object} params
		 * @param {Array<Number>} params.box_ids
		 * @param {Array<Number>} params.order_ids
		 * @param {object} params.quotation - Objeto de cotação contendo ID da cotação e a frota solicitada
		*/
		postOrder: (params = {}) => $http.post(`${prefix}/v2/order`, {
			box_ids: params.box_ids,
			order_ids: params.order_ids,
			quotation: params.quotation,
			volumesQuantity: params.volumesQuantity
		}),
		cancelOrder: id => $http.post(`${prefix}/v2/orders/${id}/cancel`)
	}
};
