<template>
	<v-flex xs12>
		<v-layout class="mt-4">
			<v-flex xs10>
				<v-btn class="px-5 my-0" @click="$emit('cancel')">Cancelar</v-btn>
				<v-btn v-if="!isNew" class="px-5 my-0" color="error" @click="$emit('removeProduct')">Remover Produto</v-btn>
			</v-flex>
			<v-flex class="d-flex items-end">
				<v-btn class="px-5 my-0" color="primary" @click="$emit('addProduct')">{{textButton}}</v-btn>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
export default {
	name: 'DialogProductAndPosologyFooter',
	props: {
		isNew: {
			type: Boolean
		}
	},
	computed: {
		textButton() {
			return this.isNew ? 'Adicionar Produto' : 'Editar Produto';
		}
	}
};
</script>
