<template>

	<v-layout @before-leave="beforeLeave()" align-center justify-center xs12
		md4>
		<transition name="page" mode="out-in" >
			<router-view  />
		</transition>
	</v-layout>
</template>

<script>
import imageBox from '@Assets/images/farme-mensal.png';

export default {
	name: 'Login',
	components: {
	},
	data() {
		return {
			entrando: false,
			trocando_pagina: false,
			imageBox
		};
	},
	mounted() {
		this.$root.$on('entrando', () => this.beforeLeave('entrando'));
	},
	beforeDestroy() {
		this.$root.$off('entrando');
	},
	computed: {
		animacao() {
			if (this.entrando)
				return 'entrando';
			if (this.trocando_pagina)
				return 'trocando_pagina';
			return null;
		}
	},
	methods: {
		beforeLeave(tipo) {
			if (tipo === 'entrando')
				this.entrando = true;
			else {
				this.trocando_pagina = true;
				setTimeout(() => {
					this.trocando_pagina = false;
				}, 1500);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.layout {
  background: $primary;

	::v-deep footer{
		font-size: 13px !important;
	}

}

</style>
