<template>
	<v-tooltip top v-if="isDataLoaded">
		<template v-slot:activator="{ on }">
			<img :src="iconUrl" :alt="tagDescription" v-on="on" width="24" class="pl-1">
		</template>
		<span>
			{{ tagDescription }}
			<br/>
			<br/>Usuário: {{ userDescription }}
			<br/>Data: {{ formattedDate }}
		</span>
	</v-tooltip>
</template>

<script>
import tagPause from '@Assets/icons/tag_pause.svg';
import tagOverdue from '@Assets/icons/tag_overdue.svg';
import tagReprocess from '@Assets/icons/tag_reprocess.svg';
import tagFraud from '@Assets/icons/tag_fraude.svg';
import tagX from '@Assets/icons/tag_x.svg';

export default {
	name: 'GlobalTags',
	props: {
		global_tag: {
			type: Object,
			required: true,
			default: () => ({ tag: {}, user: {} }),
			validator(value) {
				return 'tag' in value && 'user' in value;
			}
		}
	},
	computed: {
		isDataLoaded() {
			return this.global_tag && this.global_tag.tag && this.global_tag.user;
		},
		iconUrl() {
			switch (this.global_tag.tag.nome) {
				case 'GLOBAL_PAUSAR':
					return tagPause; // Pause icon
				case 'GLOBAL_INADIMPLENTE':
					return tagOverdue; // Overdue icon
				case 'GLOBAL_REPROCESSAR_BOX':
					return tagReprocess; // Reprocess icon
				case 'GLOBAL_FRAUDE':
					return tagFraud; // Fraud icon
				default:
					return tagX; // Default icon
			}
		},
		tagDescription() {
			return this.global_tag.tag ? this.global_tag.tag.descricao : 'Descrição padrão';
		},
		userDescription() {
			if (this.global_tag.user) {
				const { nome, email } = this.global_tag.user;
				return `${nome} (${email})`;
			}
			return 'Usuário padrão';
		},
		formattedDate() {
			const options = {
				year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
			};
			return this.global_tag.created_at
				? new Date(`${this.global_tag.created_at}Z`).toLocaleString('pt-BR', options)
				: 'Data não disponível';
		}
	}
};
</script>
