<template>
	<v-layout row wrap class="align-center">
		<v-flex xs2 md2 class="pa-3">
			<v-icon class="pa-1" color="green darken-3" size="44"
				style="border: 2px solid #342b1d; border-radius: 30px; ">monetization_on
			</v-icon>
		</v-flex>
		<v-flex xs10 md10>
			<v-layout align-center class="pa-3">
				<v-flex class="mr-2 " style="max-height: max-content">
					<strong style="font-size: 16px">{{ responsavel.nome }}<strong v-if="responsavel.nascimento">, {{ responsavel.nascimento | idade}}</strong> {{ vinculo && `(${vinculo})` }}</strong>
					<p class="mb-0 grey--text text--darken-2">
						<v-icon small class="mr-1">phone</v-icon>
						{{ responsavel.telefone | telefone }}
					</p>
					<p v-if="responsavel.email" class="mb-0 grey--text text--darken-2">
						<v-icon small class="mr-1">mail</v-icon>
						{{ responsavel.email }}
					</p>
					<p class="mb-0 grey--text text--darken-2">
						<v-icon small class="mr-1">folder_shared</v-icon>
						{{ responsavel.cpfCnpj | cpf }}
					</p>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>
<script>
export default {
	name: 'ResponsavelDetalhes',
	props: {
		responsavel: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		vinculo: ''
	}),

	created() {
		this.vinculo = this.responsavel?.vinculo;
	}
};
</script>

<style lang="scss" scoped>
</style>
