<template>
	<div class="pacientes-wrapper" :class="visao_colaborador ? 'px-md-5' : null">
		<!-- Pacientes Header -->
		<HeaderColaborador ref="headerColaborador" :carregando="is_loading" @novo-paciente="crud"
			@busca="b => busca = b"	@filtro="f => filtro = f" @casa-repouso="id => casa_repouso_id = id" />

		<!-- Estado is_loading -->
		<v-progress-linear v-if="is_loading" indeterminate class="ma-0" color="purple" height="3" />

		<div :class="visao_colaborador ? null : 'pa-xs-3 pa-md-5'">
			<!-- Conteúdo, após carregado -->
			<transition name="fade">
				<!-- container-wrapper -->
				<v-container v-if="!is_loading" fluid grid-list-xl class="container-wrapper pa-0">
					<!-- layout-wrapper -->
					<v-layout wrap class="layout-wrapper">
						<v-flex>
							<!-- Mensagem nenhum paciente cadastrado -->
							<NenhumPaciente v-if="pacientes.length === 0" />

							<!-- Lista de pacientes -->
							<v-layout v-else wrap row :class="pacientes.length < 4 ? 'layout-box-wrapper' : null">
								<v-flex xs12 sm6 md4 v-for="item in pacientes" :key="item.id">
									<PacienteCard v-ripple :paciente="item" @click.native="() => onPacienteSelecionado(item)" />
								</v-flex>

								<v-flex xs12 class="text-xs-center mt-4" v-if="contadorPagina > 1">
									<v-pagination circle v-model="pagina_numero" color="purple" :length="contadorPagina"
										:total-visible="6" />
								</v-flex>
							</v-layout><!-- Fim lista de pacientes -->
						</v-flex>
					</v-layout><!-- Fim, layout-wrapper -->
				</v-container><!-- Fim, container-wrapper -->
			</transition><!-- Fim, conteúdo após carregado -->
		</div>

		<!-- Dialog paciente -->
		<v-dialog persistent scrollable v-model="dialog_crud" transition="fade" max-width="1070px">
			<transition>
				<DialogPaciente v-if="dialog_crud" :item_pai="item_selecionado" :close.sync="dialog_crud"
					@inserir="salvo" @excluir="excluido" />
			</transition>
		</v-dialog><!-- Fim, dialog paciente -->
	</div><!-- Fim, pacientes-wrapper  -->
</template>

<script>
import { debounce } from 'lodash';
import { ListagemMixin } from '@Mixins/listagem';
import DialogPaciente from './dialog-paciente';
import HeaderColaborador from './header-colaborador';
import NenhumPaciente from './nenhum-paciente';
import PacienteCard from './paciente-card';

export default {
	name: 'Pacientes',
	mixins: [ListagemMixin],
	components: {
		HeaderColaborador, NenhumPaciente, PacienteCard, DialogPaciente
	},
	data: () => ({
		filtro: 0,
		type: 'paciente',
		casa_repouso_id: null,
		pagina_numero: 1,
		items: [],
		itens_por_pagina: 12,
		total: 0
	}),
	watch: {
		filtro() {
			this.index();
		},
		busca() {
			this.search();
		},
		casa_repouso_id() {
			this.index();
		},
		pagina_numero() {
			this.index();
		},
		itens_por_pagina() {
			this.index();
		}

	},
	computed: {
		visao_colaborador() {
			return this.$store.getters.visao_colaborador;
		},
		contadorPagina() {
			const l = this.total;
			const s = this.itens_por_pagina;

			return Math.ceil(l / s);
		},
		pacientes() {
			if (this.visao_colaborador)
				return this.items;
			return this.items.filter(pac => pac.email !== this.$store.getters.contexto.user.email);
		},
		eh_clinica() {
			return this.$route.name.includes('Clinica');
		}
	},
	methods: {
		/* override */
		onResponse(response) {
			this.items = response.items;
			this.total = response.total;
		},
		/* override */
		getIndexProps() {
			const props = {
				filtro: this.filtro,
				busca: this.busca,
				casa_repouso_id: this.casa_repouso_id,
				page: this.pagina_numero,
				perPage: this.itens_por_pagina
			};
			return props;
		},

		onPacienteSelecionado(paciente) {
			const { id } = paciente;
			if (this.eh_clinica)
				this.$router.push({ name: 'ClinicaPaciente', params: { id } });
			else if (this.visao_colaborador)
				this.$router.push({ name: 'Paciente', params: { id } });
			else
				this.crud(paciente);
		},
		afterSave() {
			if (this.visao_colaborador)
				this.$refs.headerColaborador.filtro = 0;
			this.filtro = 0;
		},
		search: debounce(function () {
			this.index();
		}, 500)
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.pacientes-wrapper {
  min-height: calc(100vh - 106px);

  .container-wrapper {
    min-height: calc(100vh - 291px);

    .layout-wrapper {
      min-height: calc(100vh - 315px);

      .layout-box-wrapper {
        justify-content: center;
      }
    }
  }
}
</style>
