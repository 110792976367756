import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const createTab = (wb, tabName, etiquetas) => {
	const sheetName = tabName;
	wb.SheetNames.push(sheetName);
	const aoa = [];
	etiquetas.forEach((etiqueta, i) => {
		if (i === 0)
			aoa.push(Object.keys(etiqueta));
		aoa.push(Object.values(etiqueta));
	});
	const ws = XLSX.utils.aoa_to_sheet(aoa);
	wb.Sheets[sheetName] = ws;
};

const geraExcelProducao = (obj, tabs_to_show = [], boxId = null) => {
	const wb = XLSX.utils.book_new();

	Object.keys(obj).forEach(key => {
		if (tabs_to_show.length && !tabs_to_show.includes(key))
			return;
		createTab(wb, key, obj[key]);
	});

	const wbout = XLSX.write(wb, { bookType: 'xls', type: 'binary' });
	function s2ab(s) {
		const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
		const view = new Uint8Array(buf); // create uint8array as viewer
		for (let i = 0; i < s.length; i += 1)
			view[i] = s.charCodeAt(i) & 0xFF; // convert to octet
		return buf;
	}

	let fileName = 'Etiquetas';
	if (boxId)
		fileName = `${fileName}_${boxId}`;

	saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.xls`);
};

export { geraExcelProducao };
