import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { cloneDeep } from 'lodash';
import { getInfos } from './pdf-functions';

const getBodyMedicamentos = etiqueta => {
	const body_medicamentos = [];
	Object.keys(etiqueta).forEach((key, i) => {
		if (!etiqueta[`MEDICAMENTO_${i + 1}`])
			return;

		const medicamento = [
			{
				text: [
					{
						bold: true,
						text: etiqueta[`MEDICAMENTO_${i + 1}`]
					}
				]
			}
		];

		if (etiqueta[`MEDICAMENTO_${i + 1}_QUANTIDADE`])
			medicamento[0].text.push(` Qtd: ${etiqueta[`MEDICAMENTO_${i + 1}_QUANTIDADE`]}`);
		medicamento[0].text.push('\t');

		let posologia = `${etiqueta[`MEDICAMENTO_${i + 1}_QUANTIDADE_DIARIA`]} - ${etiqueta[`MEDICAMENTO_${i + 1}_POSOLOGIA`]}`;
		if (etiqueta[`MEDICAMENTO_${i + 1}_CAIXAS`])
			posologia = etiqueta[`MEDICAMENTO_${i + 1}_CAIXAS`];
		medicamento.push({ text: posologia });

		body_medicamentos.push(medicamento);
	});

	for (let i = body_medicamentos.length - 1; i < 14; i += 1)
		body_medicamentos.push([[' '], [' ']]);

	return body_medicamentos;
};

export const geraPdfEtiquetaForaDaBox = etiquetasDeRastreio => {
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	const [etiqueta] = etiquetasDeRastreio;

	const qr_code = {
		qr: etiqueta.TRACK, fit: 80, alignment: 'center', rowSpan: 2
	};

	const table_qr_rolos = {
		layout: 'noBorders',
		table: {
			widths: ['*', '*', '*'],
			body: [
				[
					qr_code,
					{},
					{}
				]
			]
		}
	};

	const inicio = etiqueta.INICIO_BOX;
	const fim = etiqueta.FIM_BOX;

	const [info_farme, info_gerais] = getInfos();

	const main_row = {
		color: '#444',
		layout: 'noBorders',
		table: {
			widths: ['*', '*'],
			body: [
				[
					{
						fontSize: 25,
						bold: true,
						text: etiqueta.PACIENTE_APELIDO || etiqueta.PACIENTE_NOME
					}
				],
				[
					{},
					{
						fontSize: 20,
						bold: true,
						text: `${inicio} -> ${fim}`
					}
				]
			]
		}
	};

	const medicamentos = {
		layout: 'noBorders',
		table: {
			widths: ['*', '*'],
			body: getBodyMedicamentos(etiqueta)
		}
	};

	const info_paciente = {
		style: 'bottom',
		text: [
			{
				bold: true,
				text: `${etiqueta.PACIENTE_NOME}\n`
			},
			etiqueta.ENDERECO
		]
	};

	const bottom = {
		layout: 'noBorders',
		table: {
			widths: ['*', '*', '*'],
			body: [
				[
					info_gerais,
					info_farme,
					info_paciente

				]
			]
		}
	};

	const page = [
		{
			margin: [0, 10, 0, 10],
			canvas: [{
				type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 2, color: 'grey'
			}]
		},
		medicamentos,
		{
			margin: [0, 10, 0, 10],
			canvas: [{
				type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1, color: 'grey'
			}]
		}
	];

	const content = [];

	// Para incrementar a contagem de rolos e número de etiquetas
	for (let i = 1; i <= 1; i += 1) {
		const _page = cloneDeep(page);
		const _bottom = cloneDeep(bottom);
		const _main_row = cloneDeep(main_row);
		const _table_qr_rolos = cloneDeep(table_qr_rolos);
		_table_qr_rolos.table.body.push([
			{
				text: `Rolo ${i} de ${1}`
			},
			{},
			{}
		]);
		_main_row.table.body[0].unshift(_table_qr_rolos);
		_page.unshift(_main_row);
		if (i !== 1)
			_bottom.pageBreak = 'after';
		_page.push(_bottom);
		content.push(cloneDeep(_page));
	}

	const config = {
		pageSize: 'A4',
		content,
		styles: {
			bottom: {
				fontSize: 8
			}
		}
	};

	return pdfMake.createPdf(config).open();
};
