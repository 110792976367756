<template>
	<v-container>
		<RestHomeInfo
			:info="info"
			:requiredWhenPaymentTypeIsOpenClose="requiredWhenPaymentTypeIsOpenClose"/>
		<FarmeDivider />
		<RestHomeAddress :address="address" />
		<FarmeDivider />

		<RestHomePayment :payment="payment"/>
		<FarmeDivider />

		<RestHomeDiscount :discount="discount"/>
		<FarmeDivider />

		<RestHomeDelivery
			:delivery="delivery"
			:businessType="info.businessType"
			:requiredWhenPaymentTypeIsOpenClose="requiredWhenPaymentTypeIsOpenClose"/>
		<FarmeDivider />
	</v-container>
</template>

<script>
import FarmeDivider from '@Componentes/farme-divider.vue';
import { PAYMENT_TYPE_OPEN_CLOSE } from '@Consts/rest-home/payment-types';
import RestHomeInfo from './info.vue';
import RestHomeAddress from './address.vue';
import RestHomeDiscount from './discount.vue';
import RestHomePayment from './payment.vue';
import RestHomeDelivery from './delivery.vue';

export default {
	name: 'RestHomeGeneralData',
	$_veeValidate: { validator: 'new' },
	props: {
		generalData: {
			required: false,
			type: Object,
			default: () => {}
		}
	},
	components: {
		RestHomeInfo,
		RestHomeAddress,
		RestHomePayment,
		RestHomeDiscount,
		RestHomeDelivery,
		FarmeDivider
	},
	computed: {
		requiredWhenPaymentTypeIsOpenClose() {
			const { payment = {} } = this.generalData;
			return payment.type === PAYMENT_TYPE_OPEN_CLOSE.value;
		},
		info() {
			const { info = {} } = this.generalData;
			return info;
		},
		address() {
			const { address = {} } = this.generalData;
			return address;
		},
		payment() {
			const { payment = {} } = this.generalData;
			return payment;
		},
		discount() {
			const { discount = {} } = this.generalData;
			return discount;
		},
		delivery() {
			const { delivery = {} } = this.generalData;
			return delivery;
		}
	},
	methods: {
		async validate() {
			return Boolean(await this.$validator.validateAll());
		}

	}
};
</script>

<style lang="scss" scoped>

</style>
