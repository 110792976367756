<template>
	<v-card>
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>
					fas fa-receipt
				</v-icon>
			</v-avatar>
			<h3>Receitas</h3>
			<v-spacer />
			<v-btn v-if="visao_colaborador" depressed small class="green white--text" @click="dialog_enviar_receita = true">
				<v-icon small class="mr-2">upload</v-icon>
				Carregar
			</v-btn>
			<v-btn v-if="receitas.length > 4" small depressed class="green white--text" @click="abrirDialog(0, 'dialog_visualizar_receitas')">
				Ver todas
			</v-btn>
		</v-card-title>
		<v-card-text class="pt-0">
			<v-layout wrap>
				<v-flex v-if="receitas.length === 0">
					Nenhuma receita foi enviada.
				</v-flex>
				<v-flex v-else xs12 md3 v-for="(item, index) in receitas.slice(0, 4)" :key="index">
					<div class="img-wrapper">
						<v-btn v-if="$store.getters.user.permissao >= $permissoes.RT" small icon class="purple delete-btn" @click="abrirDialog(index, 'dialog_delete')">
							<v-icon small color="white">delete</v-icon>
						</v-btn>
						<v-icon v-if="item.url.type ? item.url.type === 'application/pdf' : item.url.includes('.pdf')" :disabled="abrindoPdf" @click="openPdf(item)" size="50" class="pointer pa-4"
							color="purple">fas fa-file-pdf</v-icon>
						<img v-else :src="item.url.dataBase64 ? item.url.dataBase64 : item.url" @click="abrirDialog(index, 'dialog_visualizar_receitas')" class="miniatura pointer" />
						<v-tooltip top>
							<span slot="activator">{{ ((item.name) || (item.url.name) || '').substring(0, 15) }}</span>
							<span>
								{{ item.name || item.url.name }}
							</span>
						</v-tooltip>
						<span>{{ item.created_at | formatDate('DD/MM/YYYY') }}</span>
					</div>
				</v-flex>
			</v-layout>
		</v-card-text>

		<v-dialog v-model="dialog_visualizar_receitas" scrollable transition="fade" fullscreen>
			<transition>
				<DialogVisualizarReceitas v-if="dialog_visualizar_receitas" :receitas="receitas"
					:index="index" @close="dialog_visualizar_receitas = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_enviar_receita" transition="fade" max-width="500px">
			<transition>
				<DialogEnviarReceita v-if="dialog_enviar_receita" :close.sync="dialog_enviar_receita" @adicionar-receitas="adicionarReceitas" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_delete" transition="fade" max-width="340px">
			<transition>
				<Dialog v-if="dialog_delete" titulo="Remover receita" subtitulo="Esta ação não pode ser desfeita"
					btn_acao="Remover" btn_acao_class="error" btn_cancelar="Cancelar" :loading="deleting"
					@acao="destroyReceita" @close="dialog_delete = false" />
			</transition>
		</v-dialog>

	</v-card>
</template>

<script>
import { ReceitaPresenter } from '@Presenter/receita-presenter';
import { openBuffer } from '@Util/functions';
import axios from 'axios';
import DialogEnviarReceita from './dialog-enviar-receita';
import DialogVisualizarReceitas from './dialog-visualizar-receitas';

export default {
	name: 'Receitas',
	components: { DialogVisualizarReceitas, DialogEnviarReceita },
	props: {
		receitas: {
			type: Array,
			required: true
		}
	},
	data: () => ({
		index: 0,
		receita: null,
		deleting: false,
		abrindoPdf: false,
		dialog_delete: false,
		dialog_enviar_receita: false,
		dialog_visualizar_receitas: false
	}),
	computed: {
		visao_colaborador() {
			return this.$store.getters.visao_colaborador;
		}
	},
	methods: {
		destroyReceita() {
			this.deleting = true;
			ReceitaPresenter.destroy(this.receitas[this.index].id)
				.then(() => {
					this.dialog_delete = false;
					this.$emit('receita-apagada', this.index);
				})
				.finally(() => {
					this.deleting = false;
				});
		},
		abrirDialog(index, name) {
			this.index = index;
			this[name] = true;
		},
		adicionarReceitas(receitas) {
			receitas.forEach(receita => {
				this.receitas.unshift({ url: receita });
			});
			this.$root.$emit('ALTEROU', true);
			this.dialog_enviar_receita = false;
		},
		openPdf(receita) {
			this.abrindoPdf = true;
			axios.get(receita.url, { responseType: 'arraybuffer' })
				.then(response => openBuffer(response.data))
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao abrir PDF' });
				})
				.finally(() => {
					this.abrindoPdf = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
.v-card {
  ::v-deep .v-card {
    box-shadow: none !important;
  }
}

.img-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

	.delete-btn {
		position: absolute;
		right: 0;
		opacity: 0;
		transition: opacity 0.3s;
	}

	&:hover {
		.delete-btn {
			opacity: 1;
		}
	}

  .miniatura {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.08);
  }
}
</style>
