<template>
	<header>
		<v-layout wrap class="mb-4">
			<v-flex xs12 md4>
				<h1 class="text--text" style="opacity: .8">Usuários</h1>
				<p>Utilize a busca aos lado para encontrar o usuário.</p>
			</v-flex>
			<v-flex xs12 md8>
				<v-layout justify-end wrap row>
					<v-flex md6>
						<v-text-field label="Buscar usuário" placeholder="Nome ou e-mail"
							append-icon="search" color="purple" @input="value => $emit('busca', value)" />
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>

export default {
	name: 'HeaderUsuarios',
	props: {
		carregando: {
			type: Boolean,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

::v-deep .v-toolbar {
  background: #fff;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.05);

  .v-toolbar__content {
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto !important;
    padding: 16px;

    @media screen and (min-width: $break-point-md) {
      padding: 16px 24px;
    }

    .badge {
      transform: translateY(-2px);
    }

    .navbar-buttons {
      display: flex;
      flex-wrap: wrap;
      min-height: 100%;
      align-items: center;
    }
  }
}
</style>
