<template>
	<v-flex xs12>
		<v-layout wrap column>
			<v-flex v-if="isSchedules" class="mt-3">
				<v-flex xs12 class="d-flex items-center justify-start">
					<span class="inter text-bold body-1">Frequência</span>
				</v-flex>
				<v-flex md6 xs12>
					<v-autocomplete
						v-model="posology.frequency"
						:items="frequencies"
						solo
						flat
						hide-details
						placeholder="Digite para filtrar..."
						clearable
						item-value="value" item-text="label" class="inputQuotationStyle" />
				</v-flex>
				<v-flex xs12 v-if="posology.frequency === posologyTypes.weekly">
					<h3 class="mb-0" style="font-size: 14px">Escolha os dias da semana</h3>
					<p class="mb-2">Clique no botão do dia para escolher.</p>
					<v-btn small depressed round v-for="(day, index) in daysOfWeek" :key="index"
						@click="handleDayOfWeekSemana(day)"
						class="ml-0" :class="day.active ? 'primary' : null">
						{{day.label}}
					</v-btn>
				</v-flex>
				<v-flex xs12 v-if="posology.frequency === posologyTypes.monthly">
					<h3 class="mb-2 mr-3" style="font-size: 14px">Escolha os dias do mês</h3>
					<div>
						<v-chip disabled class="black--text mr-3 text-bold" v-for="(item, index) in posology.daysOfMonth"
							:key="index">
							{{ item }}
						</v-chip>
						<v-btn depressed class="green white--text" @click="dialogDaysOfMonth = true">
							<v-icon small class="mr-2">add</v-icon>
							Escolher os dias
						</v-btn>
					</div>
				</v-flex>
			</v-flex>
			<v-flex v-if="isBoxes" xs12 class="noPadding mt-4">
				<v-flex  xs12 class="d-flex items-center justify-start">
					<span class="inter text-bold body-1">Quantidade de caixas</span>
				</v-flex>
				<v-flex md6 xs12>
					<v-autocomplete
						v-model="product.quantityOfBoxes"
						:items="quantityOfBoxes"
						solo
						flat
						hide-details
						placeholder="Digite para filtrar..."
						clearable
						item-value="value" item-text="label" class="inputQuotationStyle" />
				</v-flex>
			</v-flex>
			<v-layout wrap v-if="isSchedules" style="padding: 0px 24px">
				<v-flex xs12 class="d-flex items-center justify-start">
					<span class="inter text-bold body-1">Horários e quantidade</span>
				</v-flex>
				<v-container v-if="posology.schedules.length" fluid grid-list-md class="full-width">
					<v-layout wrap class="full-width d-flex items-center">
						<v-flex v-for="(schedule, index) in posology.schedules" :key="index" xs6 class="d-flex items-center hourStyle mb-2"
							:class="index % 2 === 0 ? 'mx-2' : ''">
							<v-flex xs6 class="hourSelectedStyle roboto text-bold text-center">{{schedule.horario}}
							</v-flex>
							<v-flex xs7>
								<v-autocomplete
									v-model="schedule.quantidade_por_horario"
									:items="quantityPerHour"
									solo
									flat
									hide-details
									placeholder="Digite para filtrar..."
									clearable
									item-value="value" item-text="label" class="inputQuotationStyle" />
							</v-flex>
							<v-flex xs1 class="bgTrashIcon pointer d-flex items-center justify-center" @click="deleteSchedule(schedule)">
								<v-icon color="#E50000" small>fas fa-trash</v-icon>
							</v-flex>
						</v-flex>
					</v-layout>
				</v-container>
				<v-flex md6 xs12>
					<div class="styleDiv flex items-center justify-center pointer" @click="openDialogSchedules">
						<span class="inter text-bold caption">
							<v-icon color="black" small class="mr-2">fas fa-plus-circle</v-icon>
							Adicionar horário
						</span>
					</div>
				</v-flex>
			</v-layout>

			<v-dialog persistent scrollable v-model="dialogSchedules" transition="fade" max-width="320px">
				<transition>
					<DialogAdicionarHorario v-if="dialogSchedules" :horarios="posology.schedules" :horario="scheduleSelected"
						@horarios="addSchedule" @excluir="deleteSchedule" @close="closeDialogSchedules"  />
				</transition>
			</v-dialog>

			<v-dialog persistent scrollable v-model="dialogDaysOfMonth" transition="fade" max-width="320px">
				<transition>
					<DialogAdicionarDias v-if="dialogDaysOfMonth" :dias="posology.daysOfMonth" :close.sync="dialogDaysOfMonth"
						@dias="addDaysMoth" />
				</transition>
			</v-dialog>
		</v-layout>
	</v-flex>
</template>

<script>
import {
	frequencies, quantityOfBoxes, quantityPerHour, daysOfWeek, posologyTypes
} from '@Consts/quotation-v2';
import DialogAdicionarHorario from '../../../medicamento/dialog-medicamento/dialog-adicionar-horario.vue';
import DialogAdicionarDias from '../../../medicamento/dialog-medicamento/dialog-adicionar-dias.vue';

export default {
	name: 'Posology',
	components: { DialogAdicionarHorario, DialogAdicionarDias },
	props: {
		methodSelected: {
			type: String
		},
		salesMethodSelected: {
			type: String
		},
		posology: {
			type: Object || null
		},
		product: {
			type: Object
		}
	},
	data: () => ({
		frequencies,
		quantityOfBoxes,
		quantityPerHour,
		daysOfWeek,
		posologyTypes,
		dialogSchedules: false,
		dialogDaysOfMonth: false,
		scheduleSelected: null
	}),
	created() {
		if (this.posology !== null)
			this.validatePosologyWeekdays();
	},
	watch: {
		'posology.frequency': {
			handler(newValue) {
				this.resetPosology();

				if (newValue === posologyTypes.weekly)
					this.posology.daysOfWeek = this.posology.daysOfWeek.map(day => ({ ...day, active: false }));
			},
			deep: true
		},
		salesMethodSelected: {
			handler(newValue) {
				if (newValue === 'boxes') {
					this.resetPosology();
					this.posology.frequency = undefined;
				}

				if (newValue === 'schedules') {
					this.$emit('schedulesMethod');
					delete this.product.quantityOfBoxes;
				}
			},
			deep: true
		}
	},
	methods: {
		openDialogSchedules() {
			this.dialogSchedules = true;
		},
		closeDialogSchedules() {
			// Ordena os horário por ordem crescente
			this.posology.schedules.sort((a, b) => (a.horario > b.horario ? 1 : -1));

			this.scheduleSelected = null;
			this.dialogSchedules = false;
		},
		addSchedule(schedules) {
			const arr = [];
			schedules.forEach(hor => {
				if (this.posology.schedules.findIndex(h => h.horario === hor) === -1)
					this.posology.schedules.push({ horario: hor, quantidade_por_horario: 1 });
			});

			this.posology.schedules.forEach(hor => {
				if (schedules.findIndex(h => h === hor.horario) !== -1)
					arr.push(hor);
			});
			this.posology.schedules = arr;

			this.closeDialogSchedules();
		},
		addDaysMoth(days) {
			this.posology.daysOfMonth = days;
			this.dialogDaysOfMonth = false;
		},
		deleteSchedule(schedule) {
			const index = this.posology.schedules.findIndex(h => h.hour === schedule.hour);
			if (index !== -1) {
				this.posology.schedules.splice(index, 1);
				this.closeDialogSchedules();
			}
		},
		resetPosology() {
			this.posology.schedules = [];
			this.posology.daysOfWeek = [];
			this.posology.daysOfMonth = [];
		},
		handleDayOfWeekSemana(day) {
			day.active = !day.active;
			const i = this.posology.daysOfWeek.findIndex(d => d === day.label);
			if (i !== -1)
				this.posology.daysOfWeek.splice(i, 1);
			else
				this.posology.daysOfWeek.push(day.label);
		},
		validatePosologyWeekdays() {
			this.daysOfWeek.forEach(day => {
				if ((this.posology.daysOfWeek || []).length)
					day.active = !!this.posology.daysOfWeek.find(d => d === day.label);
			});
		}
	},
	computed: {
		isSchedules() {
			return this.salesMethodSelected === 'schedules';
		},
		isBoxes() {
			return this.salesMethodSelected === 'boxes';
		}

	}
};
</script>

<style lang="scss" scoped>
.styleDiv {
	border: 1.85px dashed rgba(52, 43, 29, 0.4);
	padding: 24px 65px;
	border-radius: 5px;

	&:hover {
		border: 1.85px dashed rgba(52, 43, 29, 1);
		border-radius: 5px;
	}
}

.hourStyle {
	border-radius: 5px;
	background: #FFF3E2;
}

.hourSelectedStyle {
	border-radius: 5px;
	background: rgba(255, 200, 117, 0.40);
}

.bgTrashIcon {
	background-color: rgba(229, 0, 0, 0.1);
	border-radius: 20px;
	padding: 1px;

	&:hover {
		background-color: rgba(229, 0, 0, 0.2);
		transition: .1s ease-in;
	}
}

.noPadding {
	padding: 0px !important
}
</style>
