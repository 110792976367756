import DialogPendencias from '@Componentes/dialog-pendencias';
import { StatusBoxPresenter } from '@Presenter/status-box-presenter';

export const AtendimentoFarmaceuticoMixin = {
	components: { DialogPendencias },
	data: () => ({
		dialog_alteracoes: false,
		dialog_pendencias: false,
		dialog_prescricoes: false
	}),
	computed: {
		isAtendimento() {
			return this.$route.name === 'Atendimento';
		}
	},
	mounted() {
		this.$root.$on('alterou_pendencia', alterou => {
			this.alterouPendencia = alterou;
		});
	},
	beforeAlterouPendencia() {
		this.$root.$off('alterou_pendencia');
	},
	methods: {
		changesColor(item) {
			return this.getIconColor(item.cor_alteracoes);
		},

		prescriptionColor(item) {
			return this.getIconColor(item.cor_prescricao);
		},

		pendenciesColor(item) {
			return this.getIconColor(item.cor_pendencias);
		},

		isServiceStarted(item) {
			return item.servico_iniciado;
		},

		getIconColor(originalColor) {
			switch (originalColor) {
				case 'green':
					return 'success';
				case 'blue':
					return 'primary';
				case 'yellow':
					return 'warning';
				case 'red':
					return 'error';
				case 'gray':
					return 'info';
				default:
					return '';
			}
		},

		abrirDialog(item, dialog_name) {
			this.item_selecionado = item;
			this[dialog_name] = true;
		},

		getButtons() {
			return [
				{
					getLabel: this.getLabelPrescricoes,
					icon: 'fas fa-file-prescription',
					setColor: this.prescriptionColor,
					onClick: item => {
						if (!this.isAtendimento)
							this.abrirDialog(item, 'dialog_prescricoes');
					},
					condition: item => this.isServiceStarted(item)
				},
				{
					getLabel: this.getLabelAlteracoes,
					icon: 'fas fa-check-double',
					isDisabled: () => !this.isAtendimento,
					setColor: this.changesColor,
					onClick: this.abrirDialogAlteracoes,
					condition: item => this.isServiceStarted(item)
				}
			];
		},

		fecharPendencias() {
			if (this.alterouPendencia === false)
				this.dialog_pendencias = false;
			else {
				this.alterouPendencia = false;
				this.dialog_pendencias = false;
				this.getDataPagination(this.$refs.dataTablePagination.pagination);
			}
		},

		getLabelPrescricoes(item) {
			switch (this.prescriptionColor(item)) {
				case 'info':
					return 'Rascunho aberto';
				case 'error':
					return 'Anexar receitas';
				case 'success':
					return 'Receitas ok';
				case 'warning':
					return this.isAtendimento ? 'Vincular receitas' : 'Vincular receitas';
				default:
					return 'Rascunho aberto';
			}
		},

		getLabelAlteracoes(item) {
			switch (this.changesColor(item)) {
				case 'info':
					return 'Atendimento não iniciado';
				case 'error':
					return 'Rascunho em andamento';
				case 'success':
					return 'Rascunho aprovado ou sem alterção';
				case 'warning':
					return this.isAtendimento ? 'Rascunho finalizado' : 'Rascunho concluído';
				default:
					return 'Alterações';
			}
		},

		getLabelAnnotations(item) {
			switch (this.annotationsColor(item)) {
				case 'primary':
					return 'Visualizar anotações';
				default:
					return 'Sem anotações';
			}
		},

		abrirDialogAlteracoes(item) {
			if (!this.isAtendimento)
				return () => ({});

			if (this.changesColor(item) === 'error')
				return this.abrirDialog(item, 'dialog_prescricoes');
			return this.abrirDialog(item, 'dialog_alteracoes');
		},

		updateStatus(status) {
			return () => new Promise((resolve, reject) => {
				StatusBoxPresenter.store({
					box_id: this.item_selecionado.box_id,
					status
				})
					.then(() => {
						this.afterSave(resolve);
					})
					.catch(() => {
						reject();
					});
			});
		},

		afterSave(resolve) {
			this.getDataPagination(this.$refs.dataTablePagination.pagination);
			this.$store.dispatch('SHOW_SNACKBAR', {
				message: 'Status da box atualizado com sucesso!'
			});
			if (resolve)
				resolve();
		}
	}
};
