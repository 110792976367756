<template>
	<header>
		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 md8>
				<h1 class="text--text" style="opacity: .8">Orçamentos B2C</h1>
				<p>Crie e controle os orçamentos da Far.me.</p>
				<v-btn depressed class="primary mx-0" @click="$emit('createQuotationV2')">
					<v-icon small class="mr-2">add</v-icon>
					Criar orçamento
				</v-btn>
				<v-btn depressed class="primary mx-2" @click="$emit('generatePurchaseList')"
					:disabled="enablePurchaseList">
					Gerar lista de compra
				</v-btn>
			</v-flex>

			<v-flex xs12 md4 align-self-center>
				<v-layout wrap>
					<v-flex style="padding: 0 12px">
						<v-text-field label="Busca" append-icon="search" v-model="search" color="purple" />
					</v-flex>
					<v-flex style="padding: 0 12px">
						<v-autocomplete v-model="sellerSelected" append-icon="filter_list" label="Vendedor"
							@input="onFilter" clearable
							item-text="name" item-value="id" :items="quotationSellers"/>
						<v-select append-icon="filter_list" clearable v-model="statusSelected" label="Status" :items="statusOptions"
							@input="onFilter" />
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
import mixin from './mixin-data-table';

export default {
	name: 'OrcamentosHeader',
	mixins: [mixin],
	props: {
		enablePurchaseList: {
			type: Boolean,
			required: false
		},
		quotationSellers: {
			type: Array,
			default: () => []
		}
	},
	created() {
		this.quotationSellers.unshift({ id: null, name: 'Todos' });

		this.statusOptions.push(...Object.entries(this.$functions.statusOrcamentoB2C()).map(([key, { label }]) => ({ value: key, text: label })));

		this.onFilter();
	},
	data: () => ({
		search: '',
		statusSelected: 'DRAFT',
		sellerSelected: '',
		statusOptions: [
			{ value: '', text: 'Todos' }
		]
	}),
	watch: {
		search(value) {
			this.$emit('search', value);
		}
	},
	methods: {
		onFilter() {
			this.$emit('filter', this.statusSelected, this.sellerSelected);
		}
	}
};
</script>
