<template>
	<v-container fluid grid-list-md class="pa-0">
		<h3 class="mt-3 mb-4">Entrega</h3>

		<v-layout wrap>
			<v-flex xs12 md6>
				<v-autocomplete
					box
					clearable
					:items="deliveryTypes"
					v-model="delivery.deliveryType"
					no-data-text="Nenhum local de entrega encontrada"
					background-color="white"
					label="Local de entrega"
					data-vv-name="Local de entrega"
					v-validate="{ required: requiredWhenPaymentTypeIsOpenClose }"
					:error-messages="errors.collect('Local de entrega')"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-text-field
					background-color="white"
					box
					v-model="delivery.daysInAdvanceForDelivery"
					label="Dias de antecedência de entrega"
					data-vv-name="Dias de antecedência de entrega"
					v-validate="{ required: false, numeric: true, min: 0 }"
					:error-messages="errors.collect('Dias de antecedência de entrega')"/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { BUSINESS_TYPE_B2B, BUSINESS_TYPE_B2C } from '@Consts/rest-home/business-types';
import { DELIVERY_TYPE_B2B, DELIVERY_TYPE_B2C, DELIVERY_TYPES } from '@Consts/rest-home/delivery-types';

export default {
	name: 'RestHomeGeneralDataPayment',
	inject: ['$validator'],
	props: {
		delivery: {
			required: true,
			type: Object
		},
		businessType: {
			required: false
		},
		requiredWhenPaymentTypeIsOpenClose: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		deliveryTypes() {
			return DELIVERY_TYPES;
		}
	},
	watch: {
		businessType(businessType) {
			if (businessType === BUSINESS_TYPE_B2B.value)
				this.delivery.deliveryType = DELIVERY_TYPE_B2B.value;
			if (businessType === BUSINESS_TYPE_B2C.value)
				this.delivery.deliveryType = DELIVERY_TYPE_B2C.value;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
