<template>
	<v-card>
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>
					fas fa-receipt
				</v-icon>
			</v-avatar>
			<h3>Arquivos</h3>
			<v-spacer />
			<v-select style="max-width: 150px" class="mr-3" append-icon="filter_list" v-model="categoria_selecionada" label="Filtros"
				:items="categorias" @input="getArquivos" />
			<v-btn small depressed class="green white--text" @click="crud(null)">
				<v-icon class="mr-2">fas fa-upload</v-icon>
				Carregar
			</v-btn>
		</v-card-title>
		<v-card-text class="pa-0">
			<v-data-table :headers="headers" :items="arquivos" dense :loading="loading"
				:no-data-text="loading ? 'Carregando...' : 'Nenhum arquivo'">
				<template v-slot:items="props">
					<tr>
						<td>
							<a @click="abrirArquivo(props.item.url)">{{ props.item.nome }}</a>
						</td>
						<td class="text-xs-center">
							{{ props.item.categoria }}
						</td>
						<td class="text-xs-center">
							{{ props.item.data_referencia | formatDate('DD/MM/YYYY') }}
						</td>
						<td>
							{{ props.item.user ? props.item.user.nome : '-' }}
						</td>
						<td class="buttons" @click.stop>
							<v-tooltip top>
								<v-icon slot="activator" color="info"
									@click.stop="crud(props.item)">
									fas fa-pen
								</v-icon>
								<span>Editar</span>
							</v-tooltip>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card-text>

		<v-dialog v-model="dialog_upload_arquivos" persistent scrollable transition="fade" max-width="440px">
			<transition>
				<DialogUploadArquivo v-if="dialog_upload_arquivos" :paciente_id="paciente_id" :arquivo_id="(item_selecionado || {}).id"
					@arquivo-atualizado="getArquivos" @close="dialog_upload_arquivos = false" />
			</transition>
		</v-dialog>

	</v-card>
</template>

<script>
import { ArquivoPresenter } from '@Presenter/arquivo-presenter';
import { PacientePresenter } from '@Presenter/paciente-presenter';
import DialogUploadArquivo from '@Componentes/dialog-upload-arquivo';

export default {
	name: 'Arquivos',
	components: { DialogUploadArquivo },
	props: {
		paciente_id: {
			type: Number
		}
	},
	data: () => ({
		arquivos: [],
		categorias: [],
		item_selecionado: {},
		categoria_selecionada: '',
		dialog_upload_arquivos: false,
		headers: [
			{ text: 'Arquivo', value: 'url' },
			{ text: 'Categoria', value: 'categoria', align: 'center' },
			{ text: 'Data', value: 'data_referencia', align: 'center' },
			{ text: 'Criado por', value: 'user.nome' },
			{ text: '', value: '' }
		],
		loading: true
	}),
	async created() {
		this.getArquivos();
		this.categorias = await ArquivoPresenter.categorias();
	},
	methods: {
		crud(arquivo) {
			this.item_selecionado = arquivo;
			this.dialog_upload_arquivos = true;
		},
		abrirArquivo(url) {
			window.open(url);
		},
		async getArquivos() {
			this.loading = true;
			this.arquivos = await PacientePresenter.arquivos(this.paciente_id, { categoria: this.categoria_selecionada });
			this.loading = false;
		}
	}
};
</script>

<style lang="scss" scoped>
i {
	&.fas {
		font-size: 16px;
	}
}
</style>
