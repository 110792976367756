<template>
	<v-container style="padding: 0 !important;">
		<v-layout wrap class="d-flex items-start justify-start mb-3">
			<v-flex xs1 style="margin-right: -25px">
				<v-img
					src="/img/icons/recurrence-outline.svg"
					lazy-src="/img/icons/recurrence-outline.svg"
					height="28px"
					width="28px"
					contain />
			</v-flex>
			<v-flex :style="`max-width: ${maxWidthTitle}`">
				<span class="inter text-bold text-uppercase subheading">
					{{ title }}
				</span>
			</v-flex>
			<v-flex class="d-flex items-center full-width" :style="`max-width: ${maxWidthHr}`">
				<hr class="hrStyle">
			</v-flex>
		</v-layout>
		<v-layout column align-center justify-center class="full-width">
			<slot />
		</v-layout>
	</v-container>
</template>

<script>
export default {
	name: 'ListItemQuotationV2',
	props: {
		title: {
			type: String,
			required: true
		},
		maxWidthTitle: {
			type: String,
			required: true
		},
		maxWidthHr: {
			type: String,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>

	.hrStyle {
		border: 2px dashed rgba(52, 43, 29, 0.5);
	}

</style>
