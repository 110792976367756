export const envs = {
	Local: 'Local',
	Prod: 'App',
	Dev: 'Dev',
	Staging: 'Staging',
	Lab: 'Lab'
};

export const localStorageEnvKey = 'env';

export const isProd = () => localStorage.getItem(localStorageEnvKey) === envs.Prod;

export const isLab = () => localStorage.getItem(localStorageEnvKey) === envs.Lab;

export const isStaging = () => localStorage.getItem(localStorageEnvKey) === envs.Staging;

export const isDev = () => localStorage.getItem(localStorageEnvKey) === envs.Dev;

export const isLocal = () => localStorage.getItem(localStorageEnvKey) === envs.Local;
