import { render, staticRenderFns } from "./lista-pendencia.vue?vue&type=template&id=c58cc778&scoped=true"
import script from "./lista-pendencia.vue?vue&type=script&lang=js"
export * from "./lista-pendencia.vue?vue&type=script&lang=js"
import style0 from "./lista-pendencia.vue?vue&type=style&index=0&id=c58cc778&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c58cc778",
  null
  
)

export default component.exports