<template>
	<section class="inter" :class="getPatientNameClass">
		<h1 class="inter mb-3" >{{ patientName }}</h1>
		<div v-if="globalTags && globalTags.length > 0">
			Tags <br>
			<GlobalTags
				v-for="(globalTag, index) in globalTags"
				:key="index"
				:global_tag="globalTag"/>
		</div>
		<!-- Sobra de estoque? <br>
		<div class="stock-info" :style="`background-color: ${getStockStyle.backgroundColor}!important;color:${getStockStyle.textColor}`">
			<strong>{{ getStatusStockDescription }}</strong>
		</div> -->
	</section>
</template>

<script>
import { BoxProductionMixin } from '@Mixins/box-production';
import GlobalTags from '@Componentes/global-tags';

export default {
	mixins: [BoxProductionMixin],
	props: {
		patientName: { required: true, type: String },
		globalTags: { required: false, type: Array },
		hasStock: { required: true, type: Boolean }
	},
	components: {
		GlobalTags
	},
	computed: {
		getStatusStockDescription() {
			return this.hasStock ? 'SIM' : 'NÃO';
		},
		getPatientNameClass() {
			return this.isInProgress ? 'white--text' : 'secondary--text';
		},
		getStockStyle() {
			if (this.isWaiting) {
				return {
					backgroundColor: '#2DCE71',
					textColor: '#FFF'
				};
			}

			if (this.isInProgress) {
				return {
					backgroundColor: '#FFF',
					textColor: '#2DCE71'
				};
			}

			return {
				backgroundColor: '#342B1D',
				textColor: '#FFF'
			};
		}

	}
};
</script>

<style lang="scss" scoped>

section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	text-align: center;
	font-size: 16px;

	h1 {
		font-weight: black;
		font-size: 50px;
		line-height: 57.5px;
	}

	.stock-info {
		padding: 5px 15px;
		border-radius: 10px;
		font-size: 14px;
	}

	@media (max-width: 600px) {
		h1 {
			font-size: 30px;
			line-height: 37.5px;
		}
		.stock-info {
			font-size: 11px;
		}

	}

}
</style>
