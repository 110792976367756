<template>
	<Dialog titulo="Enviar para a expedição" :loading="loading" @close="close">
		<v-flex>
			<p class="mt-3" style="font-weight: 500"> Confirmo ter efetuado o faturamento dos produtos solicitados no sistema.
				Estou ciente de que estou concluindo o processo e encaminhando-o para a etapa de expedição, e que não é possível retroceder ou modificar o pedido a partir deste ponto. </p>
		</v-flex>
		<v-container style="gap: 0.5rem; display: grid; grid-template-columns: repeat(2, minmax(0, 1fr));" >
			<v-btn depressed @click="close" class="full-width">
				Cancelar
			</v-btn>
			<v-btn depressed class="primary full-width" @click="confirmar"
				:loading="loading">
				Confirmar
			</v-btn>
		</v-container>
	</Dialog>
</template>

<script>
import { validateProcessIsAllowed } from '@Util/order-process';
import { ProcessTypes } from '@Consts/status-order-process';

export default {
	name: 'DialogEnviarExpedicao',
	props: {
		tituloOpcional: {
			type: String
		},
		changeStatusProcess: {
			type: Function
		},
		orderId: {
			type: Number
		},
		typeProcess: {
			type: String
		}
	},
	data: () => ({
		loading: false
	}),
	methods: {
		confirmar() {
			this.loading = true;
			validateProcessIsAllowed(this.orderId, ProcessTypes.BILLING)
				.then(isValid => {
					if (isValid)
						this.changeStatusProcess();
					else
						this.$emit('disableBtn', !isValid);
				})
				.finally(() => {
					this.loading = false;
					this.close();
				});
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>
