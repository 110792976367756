<template>
	<Dialog titulo="Editar usuário"
		btn_acao="Editar" btn_cancelar="Cancelar" :btn_acao_disable="!habilitarBtnSalvar"
		@acao="submeter()" @close="fechaModal()" :loading="salvando">
		<div v-if="loading" class="loading">
			<v-progress-circular indeterminate color="primary" />
		</div>
		<v-container v-else fluid grid-list-lg class="px-3 pt-1 pb-0">
			<v-layout align-center row wrap>
				<Form :campos="campos" :item="item" />
				<v-flex xs12 md6 v-if="crf" class="pl-0">
					<v-text-field label="CRF" box v-model="item.crf"></v-text-field>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { CRUDMixin } from '@Mixins/crud';

export default {
	name: 'DialogUsuario',
	mixins: [CRUDMixin],
	data: () => ({
		type: 'user',
		fields_type: 'user',
		loading: false
	}),
	watch: {
		campos: {
			handler() {
				if (!this.eh_administrador)
					delete this.campos.permissao;
			},
			deep: true
		}
	},
	methods: {
		createData() {
			if ((this.item.permissao === 12 || this.item.permissao.value === 12) || (this.item.permissao === 20 || this.item.permissao.value === 20)) {
				return {
					...this.item, permissao: this.item.permissao.value || this.item.permissao, crf: this.item.crf
				};
			}
			return {
				...this.item, permissao: this.item.permissao.value || this.item.permissao, crf: ''
			};
		}
	},
	computed: {
		habilitarBtnSalvar() {
			return this.eh_administrador;
		},
		eh_administrador() {
			return this.$store.getters.eh_administrador;
		},
		crf() {
			if ((this.item.permissao === 12 || this.item.permissao.value === 12) || (this.item.permissao === 20 || this.item.permissao.value === 20))
				return true;
			return false;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
