<template>
	<Dialog titulo="Esqueci minha senha" subtitulo="Preencha o campo abaixo com seu e-mail, para recuperar a senha."
		:btn_acao="passo === 1 ? 'Recuperar senha' : 'Concluir'" :btn_cancelar="passo === 1 ? 'Cancelar' : null"
		@acao="recuperarSenha" @close="$emit('update:close', false)" :loading="carregando">

		<v-window v-model="passo">
			<v-window-item :value="1">
				<v-text-field box v-model="email" @keyup.enter="recuperarSenha" label="E-mail"
					placeholder="E-mail cadastrado no sistema" data-vv-name="email" v-validate="{ required: true, email: true, regex: /^.*@farme.com.br$/ }"
					:error-messages="errors.collect('1.email')" data-vv-scope="1" />
			</v-window-item>

			<v-window-item :value="2">
				<v-layout wrap>
					<v-flex class="mr-3" style="max-width: max-content">
						<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
							<circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
							<path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
						</svg>
					</v-flex>
					<v-flex style="flex: 1">
						<h3>E-mail de recuperação enviado</h3>
						<p>Nós enviamos no seu e-mail um link para você cadastrar uma nova senha.</p>
					</v-flex>
				</v-layout>
			</v-window-item>
		</v-window>

	</Dialog>
</template>

<script>
import { UserPresenter } from '@Presenter/user-presenter';

export default {
	name: 'DialogEsqueceuSenha',
	$_veeValidate: { validator: 'new' },
	data: () => ({
		email: '',
		passo: 1,
		carregando: false
	}),
	methods: {
		recuperarSenha() {
			if (this.passo === 1) {
				this.$validator.validateAll(String(this.passo)).then(valido => {
					if (!valido) {
						this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Você deve preencher o campo e-mail para continuar.' });
						return;
					}
					this.carregando = true;
					UserPresenter.enviaLinkResetSenha({ email: this.email })
						.then(() => {
							this.passo = 2;
						})
						.finally(() => {
							this.carregando = false;
						});
				});
			} else
				this.$emit('update:close', false);
		}
	}
};
</script>

<style lang="scss" scoped>
$green: #7ac142;
$white: #fff;
$curve: cubic-bezier(0.65, 0, 0.45, 1);

::v-deep .v-card__text {
  padding-bottom: 0 !important;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $green;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}
</style>
