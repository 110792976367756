<template>
	<v-card>
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="#342B1D" small>fas fa-exclamation</v-icon>
			</v-avatar>
			<h3>Observações do pedido</h3>
		</v-card-title>
		<v-card-text>{{observation}}</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'OrderObservation',
	props: {
		observation: {
			type: String,
			required: false,
			default: null
		}
	}
};
</script>

<style scoped>
h3 {
	color: rgba(0, 0, 0, 0.87);
	/* font-family: Inter; */
	font-size: 13.38px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.57px;
}
</style>
