<template>
	<transition>
		<v-card
			style="
				border-radius: 0 !important;
				background-color: rgba(255, 200, 117, 0.5) !important;
			">
			<v-toolbar dark flat class="purple-title toolbarFlex" height="180px">
				<div class="container">
					<v-layout align-center justify-center>
						<v-layout column>
							<v-flex>
								<v-toolbar-title style="color: #342b1d" class="titulo display-1">Criar medicamento  <br> ou produto</v-toolbar-title>
							</v-flex>
						</v-layout>
						<v-btn style="color: #342b1d; border-radius: 5px" outline flat @click="$emit('close')">
							Fechar <v-icon small right>close</v-icon>
						</v-btn>
					</v-layout>

					<Tabs :tabSelected="tabSelected" />
				</div>
			</v-toolbar>

			<v-card-text class="cardBody">
				<Loading v-if="loading" class="mt-5"/>
				<div v-else class="container">
					<StepProduct
						v-if="tabSelected === tabs.product"
						:ref="tabs.product"
						:form="formData"
						:defaultMedicineForm="defaultMedicineForm"
						@addIngredient="addIngredient"
						@removeIngredient="(index) => removeIngredient(index)"/>

					<StepPrice
						v-if="tabSelected === tabs.pricing"
						:ref="tabs.pricing"
						:prices="formData.prices"
						:availabilities="formData.availabilities"/>

					<FarmeDivider />

					<v-layout align-center justify-space-between>
						<v-btn
							flat
							color="red"
							@click="cancelCreateMedicineDialog = true">Cancelar</v-btn>

						<section>
							<v-btn class="light" v-if="tabSelected !== tabs.product" @click="back()">Voltar</v-btn>
							<v-btn
								color="primary"
								@click="next()"
								:disabled="submiting"
								:loading="submiting">
								{{tabSelected === tabs.pricing ? "Salvar" : "Próximo"}}
							</v-btn>
						</section>
					</v-layout>
				</div>
			</v-card-text>

			<v-dialog
				persistent
				v-model="cancelCreateMedicineDialog"
				scrollable
				transition="fade"
				max-width="460px">
				<transition>
					<Dialog
						v-if="cancelCreateMedicineDialog"
						titulo="Cancelar cadastro medicamento/produto"
						btn_acao="Cancelar cadastro"
						btn_acao_class="error"
						btn_cancelar="Fechar"
						@close="cancelCreateMedicineDialog = false"
						@acao="$emit('close')"/>
				</transition>
			</v-dialog>
		</v-card>
	</transition>
</template>

<script>
import { ProductPresenter } from '@Presenter/product-presenter';
import { getAllCompanyUnits } from '@Consts/unidades';
import { LayerPresenter } from '@Presenter/pricing/layer-presenter';
import { stepsKeys } from '@Consts/product/product-form-steps';
import { itemType } from '@Consts/product/item-type';
import FarmeDivider from '@Componentes/farme-divider.vue';
import { availabilityKeys } from '@Consts/availabities';
import Tabs from '../form/tabs.vue';
import StepProduct from '../form/steps/product/index.vue';
import StepPrice from '../form/steps/price/index.vue';
import Loading from '../form/loading.vue';

export default {
	name: 'NewProductDialog',
	$_veeValidate: { validator: 'new' },
	components: {
		Tabs,
		StepProduct,
		StepPrice,
		FarmeDivider,
		Loading
	},
	async created() {
		await this.getLayers();
		this.setAvaliabilities();
		this.loading = false;
	},
	data: () => ({
		loading: true,
		submiting: false,
		tabSelected: stepsKeys.product,
		defaultMedicineForm: {
			compositions: [
				{
					concentration: null,
					activeIngredient: null,
					unitOfMeasurementSymbol: null
				}
			],
			stripe: null,
			type: null,
			pbmId: null,
			routeAdministrationIds: [],
			referenceDrug: null,
			prescriptionRule: null,
			dosageFormId: null,
			atcId: null,
			anvisaId: null,
			medicineType: null,
			properties: {
				aggregateItem: null,
				isLiquid: false,
				isDivisible: true,
				isOutOfBox: false
			}
		},
		formData: {
			product: {
				type: itemType.medicine,
				umbrellaItemId: null,
				associatedConditionId: null,
				group: null,
				name: null,
				brand: null,
				category: null,
				presentation: null,
				description: null,
				storageCondition: null,
				ncm: null,
				gtins: [],
				height: null,
				width: null,
				length: null,
				quantity: null,
				quantityForSale: null,
				unitOfMeasurementSymbol: null,
				unitOfMeasurementForSaleSymbol: null,
				grossWeight: null,
				netWeight: null,
				manufacturer: null
			},
			medicine: {
				compositions: [
					{
						concentration: null,
						activeIngredient: null,
						unitOfMeasurementSymbol: null
					}
				],
				stripe: null,
				type: null,
				pbmId: null,
				routeAdministrationIds: [],
				referenceDrug: null,
				prescriptionRule: null,
				dosageFormId: null,
				atcId: null,
				atcName: '',
				anvisaId: null,
				medicineType: null,
				properties: {
					aggregateItem: null,
					isLiquid: false,
					isDivisible: true,
					isOutOfBox: false
				},
				pmc: {
					pmcWithoutTax: '',
					pmc0: '',
					pmc12: '',
					pmc17: '',
					pmc17Alc: '',
					pmc17_5: '',
					pmc17_5Alc: '',
					pmc18: '',
					pmc18Alc: '',
					pmc19: '',
					pmc19_5: '',
					pmc19_5Alc: '',
					pmc20: '',
					pmc20_5: '',
					pmc21: '',
					pmc21Alc: '',
					pmc22: '',
					pmc22Alc: ''
				},
				pf: {
					pfWithoutTax: '',
					pf0: '',
					pf12: '',
					pf17: '',
					pf17Alc: '',
					pf17_5: '',
					pf17_5Alc: '',
					pf18: '',
					pf18Alc: '',
					pf19: '',
					pf19_5: '',
					pf19_5Alc: '',
					pf20: '',
					pf20_5: '',
					pf21: '',
					pf21Alc: '',
					pf22: '',
					pf22Alc: ''
				},
				pnu: null
			},
			prices: [],
			availabilities: []
		},
		cancelCreateMedicineDialog: false
	}),
	computed: {
		companyUnits() {
			return getAllCompanyUnits();
		},
		tabs() {
			return stepsKeys;
		}
	},
	methods: {
		async getLayers() {
			const layersResponse = await LayerPresenter.list({
				isBase: true
			});

			this.formData.prices = [...layersResponse.items.map(layer => ({
				layer: {
					id: layer.id,
					name: layer.name
				},
				value: null,
				originalValue: null
			}))];
		},
		setAvaliabilities() {
			const items = this.companyUnits.map(companyUnit => ({
				status: availabilityKeys.AVAILABLE,
				originalStatus: availabilityKeys.AVAILABLE,
				companyUnit
			}));

			this.formData.availabilities = [...items];
		},
		addIngredient() {
			const allFilled = this.formData.medicine.compositions.every(c => c.activeIngredient && c.concentration && c.unitOfMeasurementSymbol);
			if (allFilled) {
				this.formData.medicine.compositions.push({
					concentration: null,
					activeIngredient: null,
					unitOfMeasurementSymbol: null
				});
			} else {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Preencha todos os dados do principio ativo anterior',
					color: 'error'
				});
			}
		},
		removeIngredient(index) {
			this.formData.medicine.compositions.splice(index, 1);
		},
		async next() {
			const stepValidated = await this.$refs[this.tabSelected].validate();
			if (!stepValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário incompleto',
					color: 'error'
				});
				return;
			}

			if (this.tabSelected === stepsKeys.product) {
				this.tabSelected = stepsKeys.pricing;
				return;
			}

			if (this.tabSelected === stepsKeys.pricing)
				return this.save();

			throw new Error(`Unknown step ${this.tabSelected}`);
		},
		back() {
			if (this.tabSelected === stepsKeys.pricing)
				this.tabSelected = stepsKeys.product;
		},
		async save() {
			this.submiting = true;

			const productParams = {
				...this.formData.product,
				prices: this.formData.prices.map(item => ({
					layerId: item.layer.id,
					value: Number(this.$functions.onlyNumber(item.value)),
					originalValue: Number(this.$functions.onlyNumber(item.originalValue))
				})),
				availabilities: this.formData.availabilities.map(item => ({
					status: item.status,
					originalStatus: item.originalStatus,
					companyUnitId: item.companyUnit.id
				}))
			};

			if (this.formData.product.type === itemType.medicine)
				productParams.medicine = this.formData.medicine;

			try {
				const response = await ProductPresenter.createProduct(productParams);
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: response.result,
					color: response.level,
					timeout: 10000
				});
				this.$emit('close');
			} finally {
				this.submiting = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
	.container {
		max-width: 800px !important;
		min-width: 800px !important;
		padding: 24px 0px;
	}

	.toolbarFlex {
		display: flex !important;
		flex-direction: row !important;
		align-items: center !important;
		justify-content: center !important;
	}

	.cardBody {
		background-color: rgba(255, 234, 204, 1);
		height: 100vh;
	}
</style>
