<template>
	<Dialog :titulo="`${is_new ? 'Cadastrar' : 'Editar'} configuração`" :carregando="loading"
		@excluir="modal_excluir = true" @close="fechaModal">
		<v-container fluid grid-list-lg>
			<v-layout wrap>
				<v-flex xs12>
					<v-select v-model="item.companyUnit" box label="Unidade" :items="companyUnits" hide-details />
				</v-flex>
				<v-flex xs12>
					<v-select v-model="item.department" box label="Departamento" :items="departments" hide-details />
				</v-flex>
				<v-flex xs12>
					<v-text-field v-model="item.reason" label="Motivo" placeholder="Feriado, curso, recesso, etc." box
						hide-details />
				</v-flex>
				<v-flex xs12>
					<v-container fluid grid-list-xs class="card">
						<v-flex xs12>
							<v-radio-group v-model="radio" row class="ma-0 pb-3" hide-details>
								<v-radio label="Data específica" value="date"></v-radio>
								<v-radio label="Dia da semana" value="dayOfWeek"></v-radio>
							</v-radio-group>
						</v-flex>
						<v-layout v-if="radio === 'dayOfWeek'" wrap class="pl-3">
							<v-flex v-for="day in daysOfWeek" :key="day.value">
								<v-btn @click="item.dayOfWeek = day.value" :class="{ 'primary': day.value === item.dayOfWeek }">
									{{ day.text }}
								</v-btn>
							</v-flex>
							<v-flex>
								<v-switch v-model="item.workDay" label="Dia útil" hide-details />
							</v-flex>
						</v-layout>
						<v-layout v-else wrap class="px-3">
							<v-flex xs12>
								<TextDateField v-model="date" box hideDetails flat label="Data"
									placeholder="dd/mm/yyyy" />
							</v-flex>
							<v-flex xs12>
								<v-switch v-model="repeats" label="Esta data repete anualmente" hide-details />
							</v-flex>
							<v-flex xs12>
								<v-switch v-model="item.workDay" label="Dia útil" hide-details />
							</v-flex>
						</v-layout>
					</v-container>
				</v-flex>
				<v-flex xs12 class="bottom">
					<v-btn v-if="!is_new" flat color="error" :disabled="!$store.getters.eh_administrador && !$store.getters.eh_rt" class="ma-0"
						@click="modal_excluir = true">
						Excluir
					</v-btn>
					<v-spacer />
					<v-btn :disabled="!habilitarBtnAcao || (!$store.getters.eh_administrador && !$store.getters.eh_rt)" :loading="salvando" flat class="primary ma-0"
						@click="submeter">
						Salvar
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>

		<v-dialog persistent scrollable v-model="modal_excluir" transition="fade" max-width="340px">
			<transition>
				<Dialog v-if="modal_excluir" :titulo="messagem_dialog_excluir.titulo" :subtitulo="messagem_dialog_excluir.subtitulo"
					:btn_acao="messagem_dialog_excluir.btn_acao" btn_acao_class="error" btn_cancelar="Cancelar"
					@acao="excluir()" :loading="excluindo" @close="modal_excluir = false" />
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import { CRUDMixin } from '@Mixins/crud';
import { departments } from '@Consts/departamentos';
import { companyUnits } from '@Consts/unidades';
import { getCurrentCompanyUnit } from '@Config/unidades';
import { daysOfWeek } from '@Consts/dias_semana';
import { CalendarioMixin } from '@Mixins/calendario';

export default {
	name: 'DialogConfiguracao',
	mixins: [CRUDMixin, CalendarioMixin],
	data: () => ({
		radio: 'date',
		item: {
			companyUnit: 'ANY',
			department: 'ANY',
			workDay: false,
			dayOfWeek: null
		},
		date: null,
		repeats: false,
		daysOfWeek: Object.entries(daysOfWeek).map(([value, text]) => ({ value, text: text.slice(0, 3) })),
		departments,
		companyUnits: Object.entries(companyUnits).map(([value, text]) => ({ value, text }))
	}),
	created() {
		if (this.item_pai.id)
			this.loading = true;
		const companyUnit = getCurrentCompanyUnit();
		if (companyUnit)
			this.item.companyUnit = companyUnit.key;
	},
	methods: {
		setItem() {},
		onPresenterLoaded() {
			if (this.item_pai.id) {
				this.requestShow(this.item_pai.id)
					.then(resp => {
						this.item = resp;
						if (resp.dayOfWeek)
							this.radio = 'dayOfWeek';
						else {
							this.date = this.convertDate(resp);
							if (!resp.year)
								this.repeats = true;
						}
						this.item.department = (this.departments.find(c => c.value === resp.department) || {}).value || this.departments[0].value;
						this.item.companyUnit = (this.companyUnits.find(c => c.value === resp.companyUnit) || {}).value || this.companyUnits[0].value;
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		extraValidation() {
			return new Promise(resolve => {
				if (!this.item.reason)
					return resolve('Por favor, insira um motivo.');
				if (this.radio === 'date' && !this.date)
					return resolve('Por favor, insira uma data.');
				if (this.radio === 'dayOfWeek' && !this.item.dayOfWeek)
					return resolve('Por favor, selecione um dia.');
				resolve(null);
			});
		},
		createData() {
			const data = { ...this.item };
			if (!this.item.dayOfWeek) {
				const date = this.date.split('/');
				data.day = Number(date[0]);
				data.month = Number(date[1]);
				if (date[2])
					data.year = Number(date[2]);
				if (this.repeats)
					delete data.year;
			}
			return data;
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	padding: 0;
}

.card {
	border: 1px solid lightgrey;
	border-radius: 4px;
	padding: 16px;
	min-height: 222px;

	.flex {
		padding: 1px !important;
	}
}

.v-input--radio-group {
	justify-content: center;
}

.bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>
