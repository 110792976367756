import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = 'farmeos';

export const FarmeOSPresenter = {
	quotations: query => $http.get(buildQueryParams(`${prefix}/quotations`, query)),
	showQuotation: id => $http.get(`${prefix}/quotations/${id}`),
	deleteQuotation: id => $http.delete(`${prefix}/quotations/${id}`)
};
