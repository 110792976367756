<template>
	<Dialog :carregando="loading" :titulo="is_new ? 'Cadastrar medicamento' : 'Editar medicamento'" btn_cancelar="Cancelar"
		:btn_acao="is_new ? 'Cadastrar' : 'Editar'" :btn_acao_disable="!habilitarBtnAcao || (!$store.getters.eh_administrador && !$store.getters.eh_clinica )"
		:btn_excluir="messagem_dialog_excluir.btn_excluir" :btn_excluir_disable="!$store.getters.eh_administrador"
		@acao="submeter()" @excluir="modal_excluir = true" @close="fechaModal()" :loading="salvando">

		<v-container fluid grid-list-lg>
			<v-tabs
				grow
				slider-color="green">
				<v-tab ripple>
					Medicamento
				</v-tab>
				<v-tab ripple>
					Preços
				</v-tab>
				<v-tab-item>
					<br/>
					<v-layout wrap>
						<v-flex class="py-0 xs12">
							<v-btn v-if="$store.getters.eh_administrador" depressed class="primary ml-md-0 mt-0" @click="dialog_abc_farma = true">
								<v-icon small class="mr-2">search</v-icon>
								Pesquisar ABC Farma
							</v-btn>
							<v-autocomplete
								box
								color="green"
								v-model="principio_ativo"
								:loading="carregando_principios_ativos"
								:label="'Novo Princípio ativo'"
								:item-value="'id'"
								:item-text="'descricao'"
								:items="principios_ativos"
								:return-object="true"
								:hide-details="true"/>
							<v-layout v-for="molecula in principio_ativo.moleculas" :key="molecula.id" wrap>
								<v-flex class="pb-0 xs8">
									<v-text-field box disabled :hide-details="true" v-model="molecula.descricao" />
								</v-flex>
								<v-flex class="pb-0 xs2">
									<v-text-field box :type="'number'" color="green" :hide-details="true" v-model="molecula.dosagem" />
								</v-flex>
								<v-flex class="pb-0 xs2">
									<v-text-field box color="green" :hide-details="true" v-model="molecula.dosagem_medida"/>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
					<Form style="margin-top:8px" :item="item" :campos="campos">
						<v-flex xs12>
							<v-combobox v-model="item.eans" :items="[]" class="ean" placeholder="EAN"
								color="green"
								multiple chips box flat
								hide-details>
								<template v-slot:selection="data">
									<v-chip
										close
										@click="copiarEAN"
										@input="deleteEAN(data.item)">
										<strong style="cursor: copy" >{{ data.item }}</strong>
									</v-chip>
								</template>
							</v-combobox>
						</v-flex>
					</Form>
				</v-tab-item>
				<v-tab-item>
					<PrecoMedicamento v-if="!loading" ref="preco_medicamento" style="margin-top:8px" :item="item" />
				</v-tab-item>
			</v-tabs>
		</v-container>

		<!-- Dialog medicamento -->
		<v-dialog persistent v-model="dialog_abc_farma" scrollable transition="fade" max-width="920px">
			<transition>
				<DialogAbcFarma v-if="dialog_abc_farma"
					@medicamento-selected="onMedicamentoSelected" @close="dialog_abc_farma = false"  />
			</transition>
		</v-dialog>

		<!-- Dialog desativar medicamento -->
		<v-dialog persistent scrollable v-model="modal_excluir" transition="fade" max-width="340px">
			<transition>
				<Dialog v-if="modal_excluir" :titulo="messagem_dialog_excluir.titulo" :subtitulo="messagem_dialog_excluir.subtitulo"
					:btn_acao="messagem_dialog_excluir.btn_acao" btn_acao_class="error" btn_cancelar="Cancelar"
					@acao="excluir()" :loading="salvando" @close="modal_excluir = false" />
			</transition>
		</v-dialog><!-- Fim, dialog desativar medicamento -->
	</Dialog>
</template>

<script>
import { CRUDMixin } from '@Mixins/crud';
import { MedicamentoPresenter } from '@Presenter/medicamento-presenter';
import medicamentos_fields from '@Fields/medicamento-fields';
import PrecoMedicamento from '@Componentes/medicamento/preco-medicamento';
import DialogAbcFarma from './dialog-abc-farma';

export default {
	name: 'DialogMedicamento',
	mixins: [CRUDMixin],
	components: { DialogAbcFarma, PrecoMedicamento },
	data: () => ({
		item: {
			pbm: false,
			precos: [],
			status: []
		},
		type: 'medicamento',
		dialog_abc_farma: false,
		carregando_principios_ativos: false,
		principios_ativos: [],
		principio_ativo: {
			moleculas: []
		}
	}),
	watch: {
		'item.liquido': function (newValue, oldValue) {
			if (oldValue !== undefined && !!newValue !== !!oldValue) {
				this.item.fora_da_box = newValue;
				this.campos.fora_da_box.disabled = !!newValue;
			}
		}
	},
	created() {
		this.carregando_principios_ativos = true;
		this.buscaPrincipiosAtivos()
			.then(() => {
				let counter = 0;
				const i = setInterval(() => {
					counter++;
					if (this.item.id)
						this.buscaMedicamento(this.item.id);

					if (counter === 10 || this.item.id)
						clearInterval(i);
				}, 200);
			})
			.finally(() => {
				this.carregando_principios_ativos = false;
			});
		this.campos = medicamentos_fields;
		this.campos.descricao_tipo_produto.items = [
			'REFERENCIA',
			'SIMILAR',
			'GENERICO',
			'OUTROS',
			'BIOLÓGICO',
			'MANIPULADO',
			'SUPLEMENTO',
			'SUS',
			'NOVO'
		];
	},
	methods: {
		onMedicamentoSelected(medicamento) {
			this.dialog_abc_farma = false;
			if (!medicamento)
				return;
			Object.assign(this.item, medicamento);
			this.principio_ativo = {
				moleculas: []
			};
		},
		createData() {
			const dados = this.$refs.preco_medicamento.dados;
			const precos = dados.map(dado => {
				const item = this.item.precos.find(preco_item => preco_item.filial_id === dado.filial_id);
				return {
					...item,
					beneficio_pbm: dado.beneficio_pbm,
					filial_id: dado.filial_id,
					preco: Number(this.$functions.onlyNumber(dado.preco)),
					preco_pbm: Number(this.$functions.onlyNumber(dado.preco_pbm))
				};
			});
			const status = dados.map(dado => {
				const item = this.item.status.find(status_item => status_item.filial_id === dado.filial_id);
				return {
					...item,
					filial_id: dado.filial_id,
					status: Number(this.$functions.onlyNumber(dado.status))
				};
			});

			return {
				...this.item,
				novo_principio_ativo: this.principio_ativo,
				precos,
				status
			};
		},
		extraValidation() {
			return new Promise(resolve => {
				// Se o medicamento for pbm, pelo menos um dos precos pbm deve ser maior que 0
				if (this.item.pbm) {
					const checkPrecoPbm = !!this.$refs.preco_medicamento.dados.find(dado => Number(this.$functions.onlyNumber(dado.preco_pbm)) > 0);
					if (!checkPrecoPbm)
						return resolve('Por favor, Insira o preço pbm');
				}
				this.$refs.preco_medicamento.dados.forEach(dado => {
					// status deve ser preenchido
					if (!dado.status && dado.status !== 0)
						return resolve('Por favor, insira a disponibilidade.');
				});
				resolve(null);
			});
		},
		setItem() {},
		onPresenterLoaded() {
			if (!this.is_new) {
				this.loading = true;
				this.requestShow(this.item_pai.id)
					.then(resp => {
						this.item = resp;
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		buscaPrincipiosAtivos() {
			return MedicamentoPresenter.principiosAtivos()
				.then(response => {
					this.principios_ativos = response;
				});
		},
		buscaMedicamento(id) {
			MedicamentoPresenter.show(id)
				.then(response => {
					this.item = response;
					this.principio_ativo = response.novo_principio_ativo || { moleculas: [] };
				});
		},
		deleteEAN(item) {
			this.item.eans.splice(this.item.eans.indexOf(item), 1);
			this.item.eans = [...this.item.eans];
		},
		copiarEAN(el) {
			const tempInput = document.createElement('input');
			tempInput.setAttribute('value', el.target.textContent);
			document.body.appendChild(tempInput);
			tempInput.select();
			document.execCommand('copy');
			document.body.removeChild(tempInput);
		}
	}
};

</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
::v-deep .ean {
	.v-chip {
		margin: 0 2;
	}
	.v-input__append-inner {
		i {
			display: none;
		}
	}
	.v-select__selections {
		min-height: 56px !important;
		padding-top: 2px !important;
	}
}

::v-deep .v-card__actions {
  background: #f5f5f5;
  padding: 16px 32px;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;

  .v-avatar {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.06);
    transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    &:hover {
      background: rgba(0, 0, 0, 0.12);
    }

    &:active {
      background: rgba(0, 0, 0, 0.06);
    }
  }
}
</style>
