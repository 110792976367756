<template>
	<Dialog titulo="Gerar nova via" @close="close"
		btn_cancelar="Cancelar" btn_acao="Gerar nova via" @acao="regenerateInvoice()"
		background="#FFF3E2FF" background-header="#FFDEAF" background-footer="#FFF3E2FF"
		:loading="loading">
		<v-container fluid grid-list-md class="pa-0">
			<v-layout wrap>
				<v-flex style="display: flex;gap: 10px">
					<div class="input-container">
						<span class="small-title">Fatura </span>
						<v-text-field
							v-model="invoice.total_amount"
							box
							background-color="rgba(255, 255, 255, 0.5)"
							hide-details
							label=""
							disabled />
					</div>
					<div class="input-container">
						<span class="small-title">Tipo </span>
						<v-text-field
							v-model="invoice.reference_type"
							box
							background-color="rgba(255, 255, 255, 0.5)"
							hide-details
							label=""
							disabled />
					</div>
				</v-flex>
				<div class="mx-3 mt-3 mb-2 separator" />
				<v-flex  style="display: flex; gap: 10px" class="mt-3">
					<div class="input-container">
						<span class="small-title">Criação </span>
						<v-text-field
							v-model="invoiceCreated"
							box
							background-color="rgba(255, 255, 255, 0.5)"
							hide-details
							label=""
							disabled />
					</div>
					<div class="input-container">
						<span class="small-title">Expiração </span>
						<v-text-field
							v-model="invoiceCreated"
							box
							background-color="rgba(255, 255, 255, 0.5)"
							hide-details
							label=""
							disabled />
					</div>
					<div class="input-container">
						<span class="small-title">Vencimento</span>
						<TextDateField v-model="dueDate" background-color="#fff" box  label=""
							placeholder="dd/mm/yyyy" />

					</div>
				</v-flex>
				<div class="mx-3 mb-2 separator" />
				<span class="small-title mt-3">Pagador</span>
				<PersonInfo
					class="no-round-right mt-2"
					style="width:100%"
					:name="payer.name"
					:mail="payer.email"
					:phone="payer.phone"
					:document="payer.document"
					:birthday="payer.birthday"
					:age="payer.age"
					:gender="payer.gender" />

				<div class="mx-3 mt-3 mb-2 separator" />

				<v-flex md12 xs12 class="mt-2">
					<span class="small-title">Observações da nova via </span>
					<v-textarea
						v-model="observation"
						placeholder="Escreva aqui"
						box
						background-color="white"
						hide-details/>
				</v-flex>

				<div class="mx-3 mt-3 mb-2 separator" />
				<v-flex  class="mt-3">
					<ProductsEdition
						@edit-products="editProducts"
						:items="editedProducts"/>

					<div class="mx-3 mt-3 mb-2 separator" />
					<v-flex  style="display: flex; gap: 10px" class="mt-3">
						<v-flex items-center style="flex-direction: column;">
							<span class="small-title">Subtotal </span>
							<v-text-field
								v-model="subTotal"
								box
								v-money
								background-color="rgba(255, 255, 255, 0.5)"
								hide-details
								label=""
								disabled />
						</v-flex>
						<v-flex items-center style="flex-direction: column;">
							<span class="small-title">Desconto geral </span>
							<v-text-field
								v-model="generalDiscount"
								box
								v-money
								background-color="#ffffff"
								hide-details
								label=""/>
						</v-flex>
						<v-flex items-center style="flex-direction: column;">
							<span class="small-title">Total</span>
							<v-text-field
								v-model="total"
								box
								v-money
								background-color="rgba(255, 255, 255, 0.5)"
								hide-details
								label=""
								disabled/>
						</v-flex>
					</v-flex>
				</v-flex >

			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { formatDate, mascaraDinheiro, onlyNumber } from '@Util/functions';
import TextDateField from '@Views/componentes/text-date-field.vue';
import moment from 'moment';
import { getUFByStateName } from '@Consts/estados';
import ProductsEdition from './invoice/ProductsEdition.vue';
import PersonInfo from './invoice/PersonInfo.vue';

export default {
	name: 'DialogGenerateInvoice',
	components: {
		ProductsEdition, PersonInfo, TextDateField
	},
	props: {
		invoice: {
			type: Object
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dueDate: null,
			observation: '',
			editedProducts: [],
			generalDiscount: ''
		};
	},
	created() {
		this.dueDate = formatDate(this.invoice.due_date, 'DD/MM/YYYY');
		this.editedProducts = this.$lodash.cloneDeep(this.invoice.items);
	},
	computed: {
		payer() { return this.invoice.payer; },
		subTotal() {
			if (!this.editedProducts.length)
				return '';
			return mascaraDinheiro(this.editedProducts.reduce((acc, item) => acc
			+ ((onlyNumber(item.amount || 0) - onlyNumber(item.discount || 0)) * (item.quantity || 0)), 0));
		},
		total() {
			return mascaraDinheiro(onlyNumber(this.subTotal) - onlyNumber(this.generalDiscount || 0));
		},
		invoiceCreated() {
			return this.invoice.created_at ? formatDate(this.invoice.created_at, 'DD/MM/YYYY') : '';
		}
	},
	methods: {
		close() {
			this.loading = false;
			this.$emit('update:close', false);
		},
		editProducts(items) {
			this.editedProducts = items;
		},

		buildParamsToDuplicateInvoice() {
			const expirationDate = moment(this.dueDate, 'DD/MM/YYYY').add(10, 'days');
			const params = {
				...this.invoice,
				observation: this.observation,
				items: this.editedProducts.map(item => ({
					...item,
					discount: onlyNumber(item.discount),
					amount: onlyNumber(item.amount)
				})),
				discount: onlyNumber(this.generalDiscount),
				company_unit: this.invoice.company_unit,
				reference_type: this.invoice.reference_type,
				reference_id: this.invoice.reference_id,
				due_date: moment(this.dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
				expiration_date: expirationDate.format('YYYY-MM-DD'),
				address: {
					...this.invoice.address,
					state: getUFByStateName(this.invoice.address.state) || this.invoice.address.state
				},
				status: undefined
			};
			return params;
		},
		async regenerateInvoice() {
			if (!this.observation) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'O campo observação é obrigatório' });
				return;
			}

			if (!this.dueDate) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'O campo data de vencimento é obrigatório' });
				return;
			}

			this.$emit('duplicate-invoice', this.buildParamsToDuplicateInvoice());
		}
	}
};
</script>

<style lang="scss" scoped>
.input-container{
	width: 200px;
}

.small-title {
	font-weight: 600; font-size: 12px;
}

.separator {
	width: 100%;
	border-top: 3px dotted rgba(255, 200, 117, 0.6);
}
</style>
