<template>
	<v-card class="medicamento-card" :class="{ invert: inverter_cores }" :title="`ID ${medicamento.pivot && medicamento.pivot.id}`">
		<v-card-text>
			<v-layout align-center style="margin-bottom: -12px !important">
				<v-flex>
					<!-- header card medicamento -->
					<h4
						class="h4"
						:class="medicamento_pausado ? 'medicamento-pausado' : 'text--text'">
						{{
							medicamento.descricao_tipo_produto === "GENERICO"
								? "GENERICO"
								: medicamento.principio_ativo
						}}
					</h4>
					<h3
						:class="
							medicamento_pausado
								? 'medicamento-pausado'
								: 'brown--text text--darken-2'
						">
						{{ medicamento.produto }}
						<v-tooltip
							v-if="
								visao_colaborador &&
									medicamento.mpp &&
									medicamento.mpp_observacao
							"
							top>
							<v-icon slot="activator" :color="corIcone('warning')">fas fa-bell</v-icon>
							<span>{{ medicamento.mpp_observacao }}</span>
						</v-tooltip>
						<v-tooltip v-if="medicamento.produto_referencia" top>
							<v-icon slot="activator" :color="corIcone('success')">info</v-icon>
							<span>
								O {{ medicamento.produto }} é um produto
								{{ medicamento.descricao_tipo_produto }} ao
								{{ medicamento.produto_referencia }}
							</span>
						</v-tooltip>
						<v-tooltip v-if="medicamento.portaria_344_98" top>
							<v-icon slot="activator" :color="corIcone('error')">warning</v-icon>
							<span>Este medicamento necessita da retenção da prescrição
								médica.</span>
						</v-tooltip>
						<v-tooltip top v-if="medicamento.fora_da_box">
							<template v-slot:activator="{ on }">
								<img src="@Assets/icons/fora_box_dark.svg" alt="ForaBox" v-on="on" width="18" class="pl-1">
							</template>
							<span>ForaBox</span>
						</v-tooltip>
						<v-tooltip top v-if="medicamento.refrigerado">
							<template v-slot:activator="{ on }">
								<img src="@Assets/icons/refrigerado_dark.svg" alt="Refrigerado" v-on="on" width="18" class="pl-1">
							</template>
							<span>Refrigerado</span>
						</v-tooltip>
						<v-tooltip v-if="medicamento.pbm" top>
							<v-chip
								class="justify-center"
								:color="corIcone('blue')"
								style="max-width: 20px; max-height: 20px; margin-top: -1.5px"
								slot="activator"
								dark
								small><strong>%</strong></v-chip>
							<span>{{ medicamento.beneficio_pbm || "PBM" }}</span>
						</v-tooltip>
					</h3>
					<p :class="corTituloMedicamento" v-if="!eh_clinica">
						{{
							`${medicamento.apresentacao}${
								medicamento.laboratorio ? ` - ${medicamento.laboratorio}` : ""
							}`
						}}
					</p>
					<p :class="corTituloMedicamento" v-else>{{ medicamento.dosagem }}</p>
					<!-- corpo do card medicamento -->
					<div
						class="mb-2"
						:class="corTextoMedicamentoPausado('grey--text text--darken-2')">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon
									small
									style="margin-right: 10px; margin-bottom: 3px"
									:color="corIcone('')"
									class="mr-2"
									v-on="on">ID</v-icon>
							</template>
							<span>Código de identificação do medicamento no sistema.</span>
						</v-tooltip>
						<v-tooltip bottom open-on-hover>
							<template v-slot:activator="{ on }">
								<div
									@click="copyId()"
									style="margin-top: -20px; cursor: pointer"
									v-on="on"
									@mouseover="showCopyIcon = true"
									@mouseleave="showCopyIcon = false">
									<p class="mb-0" style="margin-left: 28px">
										{{ medicamento.id }}
										<Transition name="fade" mode="out-in">
											<v-icon
												size="12px"
												v-show="showCopyIcon"
												:color="corIcone('')"
												class="pl-1 pb-1"
												v-on="on">fas fa-copy</v-icon>
										</Transition>
									</p>
								</div>
							</template>
							<span>Clique para copiar.</span>
						</v-tooltip>
					</div>
					<div
						v-if="medicamento.qtd_caixas"
						class="mb-2"
						:class="corTextoMedicamentoPausado('grey--text text--darken-2')">
						<v-icon small class="mr-2" :color="corIcone('')">fas fa-box</v-icon>
						<div style="margin-top: -20px">
							<p class="mb-0" style="margin-left: 28px">
								{{ medicamento.qtd_caixas }}
								{{ `caixa${medicamento.qtd_caixas > 1 ? "s" : ""}` }}
							</p>
						</div>
					</div>
					<Posologia
						v-else
						:posologia="medicamento.posologia"
						:text_style="corTextoMedicamentoPausado('')"
						:icon_style="corIcone('')"/>
					<Preco
						v-if="!eh_clinica"
						:medicamento="medicamento"
						:text_style="corTextoMedicamentoPausado('')"
						:icon_style="corIcone('')"/>
					<div
						v-if="estoqueMed && estoqueMed.qtd_comprimidos >= 0"
						class="mb-2"
						:class="corTextoMedicamentoPausado('grey--text text--darken-2')">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small class="mr-2" v-on="on" :color="corIcone('')">fas fa-capsules</v-icon>
							</template>
							<span>Quantidade de comprimidos em estoque</span>
						</v-tooltip>
						<div style="margin-top: -20px">
							<p class="mb-0" style="margin-left: 28px">
								{{ estoqueMed.qtd_comprimidos }}
							</p>
						</div>
					</div>
					<Motivo
						v-if="medicamento_pausado && !dialog_box"
						:motivo="motivoPausa"
						:prev_ativacao="data_pausa"/>
					<AproveitamentoReceita v-if="aproveitamentoReceitaEstaDisponivel" :desabilitado="!habilita_aproveitamento" :eRascunho="e_rascunho" :quantidade="quantidadeAproveitamentoReceita" :medicamentoId="medicamento.id"
						:boxId="boxId" />
					<Receita
						class="my-3"
						:isControlled="precisa_retencao_receita_medica"
						:prescriptions="receitas"
						:boxId="boxId"
						:medicineId="medicamento.id"
						:selectedPrescriptionId="getSelectedPrescriptionIds"
						:estoqueMedicine="getStockMedicine"
						:neededPillsQuantity="getNeededQuantity"
						:readonly="prescriptionReadonly"
						:isVisible="prescriptionIsVisible"
						:medicineIsPaused="medicineIsPaused"
						@receita-vinculada="$emit('receita-vinculada', true)"/>
				</v-flex>
			</v-layout>
			<v-checkbox	 v-if="showChecklist" :input-value="medicineIsChecked" @change="$emit('check-medicine')"
				class="medicine-checklist" label="Conferido" />
			<div class="actions" v-if="visao_colaborador && !actionsDisabled">
				<v-tooltip left>
					<v-btn
						small
						icon
						class="green white--text ml-0"
						slot="activator"
						@click="$emit('editar-medicamento')">
						<v-icon small>{{ somente_leitura ? "search" : "edit" }}</v-icon>
					</v-btn>
					<span>
						{{
							somente_leitura ? "Visualizar medicamento" : "Editar medicamento"
						}}
					</span>
				</v-tooltip>
				<v-tooltip left v-if="assinatura">
					<v-btn
						small
						icon
						class="grey darken-2 white--text ml-0"
						slot="activator"
						@click="$emit('pausar-medicamento')">
						<v-icon small>
							{{ medicamento_pausado ? "play_arrow" : "pause" }}
						</v-icon>
					</v-btn>
					<span>
						{{ medicamento_pausado ? "Editar pausa" : "Pausar medicamento" }}
					</span>
				</v-tooltip>
				<v-tooltip left v-if="mostrar_excluir && !somente_leitura">
					<v-btn
						small
						icon
						class="purple white--text ml-0"
						slot="activator"
						@click="$emit('remover-medicamento')">
						<v-icon small>delete</v-icon>
					</v-btn>
					<span> Remover medicamento </span>
				</v-tooltip>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { copyToClipboard } from '@Util/functions';
import { isLab, isProd } from '@Config/environments';
import assinatura_modalidades from '@Consts/modalidade_assinatura';
import Posologia from './posologia';
import Preco from './preco';
import Motivo from './motivo-pausa';
import AproveitamentoReceita from './aproveitamento-receita.vue';
import Receita from '../vincula-receita/index.vue';

export default {
	name: 'MedicamentoCardChild',
	components: {
		Posologia, Preco, Motivo, AproveitamentoReceita, Receita
	},
	props: {
		assinatura: {
			type: Object,
			required: false
		},
		medicamento: {
			type: Object,
			required: true
		},
		boxId: {
			required: false,
			default: null
		},
		quantidadeAproveitamentoReceita: {
			required: false,
			type: Number,
			default: 1
		},
		estoque: {
			type: Array,
			default: () => []
		},
		medicines_checklist: {
			type: Array,
			default: () => []
		},
		mostrar_excluir: {
			type: Boolean,
			default: true
		},
		somente_leitura: {
			type: Boolean,
			default: false
		},
		inverter_cores: {
			type: Boolean,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		data_base_pausa: {
			type: Object,
			required: false
		},
		receitas: {
			type: Array,
			required: false
		},
		prescriptionReadonly: {
			type: Boolean,
			default: true
		},
		prescriptionIsVisible: {
			type: Boolean,
			default: false
		},
		e_rascunho: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		showCopyIcon: false,
		teste: true,
		actionsDisabled: false
	}),
	created() {
		this.actionsDisabled = this.disabled || (this.e_rascunho && !!this.getSelectedPrescriptionIds.length);
		this.corIcone();
		this.corTextoMedicamentoPausado();
	},
	computed: {
		visao_colaborador() {
			return this.$store.getters.visao_colaborador;
		},
		eh_clinica() {
			return this.$route.name.includes('Clinica');
		},
		habilita_aproveitamento() {
			return this.$route.name.includes('Farmaceutico') || (this.e_rascunho && !this.getSelectedPrescriptionIds.length);
		},
		estoqueMed() {
			if (this.estoque.length === 0)
				return [];

			return this.estoque.find(e => e.id === this.medicamento.id);
		},
		isOutOfBox() {
			return !!this.medicamento.fora_da_box;
		},
		getStockMedicine() {
			if (!this.estoqueMed || (Array.isArray(this.estoqueMed) && this.estoqueMed.length === 0))
				return 0;
			if (this.isOutOfBox)
				return this.estoqueMed.qtd_caixas;
			return this.estoqueMed.qtd_comprimidos;
		},
		getNeededQuantity() {
			if (this.isOutOfBox)
				return this.medicamento.qtd_caixas;
			return this.medicamento.qtd_comprimidos_necessarios;
		},
		corTituloMedicamento() {
			if (this.medicamento_pausado)
				return 'medicamento-pausado';
			return 'grey--text text--darken-2';
		},
		data_pausa() {
			return this.data_base_pausa
				.clone()
				.add(this.medicamento.pausa.ciclos * 30, 'days')
				.format('DD/MM/YYYY');
		},
		motivoPausa() {
			return this.medicamento.pausa.motivo;
		},
		medicamento_pausado() {
			return !!this.medicamento.pausa;
		},
		dialog_box() {
			return !!this.$store.state.box_id;
		},
		precisa_retencao_receita_medica() {
			return !!this.medicamento.portaria_344_98;
		},
		ehProdOuEhLab() {
			return isProd() || isLab();
		},
		assinaturaEhIntegral() {
			return this.assinatura && this.assinatura.modalidade === assinatura_modalidades.INTEGRAL;
		},
		aproveitamentoReceitaEstaDisponivel() {
			return this.precisa_retencao_receita_medica
				&& this.assinaturaEhIntegral
				&& !this.medicamento_pausado
				&& this.boxId;
		},
		showChecklist() {
			return this.medicines_checklist?.filter(med => med.id === this.medicamento.id)?.length > 0;
		},
		medicineIsChecked() {
			return this.medicines_checklist?.find(med => med.id === this.medicamento.id)?.checked;
		},
		getSelectedPrescriptionIds() {
			if (this.medicamento.receitas)
				return this.medicamento.receitas.map(r => r.id);

			return [];
		},
		medicineIsPaused() {
			return this.medicamento.pausa !== null;
		}
	},
	methods: {
		corIcone(cor) {
			if (this.medicamento_pausado)
				return '#A8A8A8';
			return cor;
		},
		corTextoMedicamentoPausado(cor) {
			if (this.medicamento_pausado)
				return 'grey--text text--lighten-1';
			return cor;
		},
		copyId() {
			copyToClipboard(
				this.medicamento.id,
				`${this.medicamento.produto}: Código `
			);
		}
	}
};
</script>

<style scoped lang="scss">
	.medicine-checklist{
		height: 38px;
		margin-top:0px;
	}
	.medicamento-pausado {
		text-decoration-line: line-through;
		color: #a8a8a8 !important;
	}

	@import "@Assets/css/settings.scss";
	.test {
		padding-bottom: 10px;
	}

	.medicamento-card {
		overflow: hidden;

		&.invert {
			box-shadow: none;
			background: rgba(0, 0, 0, 0.06);
		}

		h3 {
			i {
				font-size: 22px;
			}
		}

		.h4 {
			font-family: "Poppins", sans-serif;
			font-weight: 500;
			margin-bottom: 0;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 12px;
		}

		.actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 10px 0 0;

			@media screen and (min-width: $break-point-sm) {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 10px;
				margin: 0;
				flex-direction: column;
				justify-content: center;
			}

			@media screen and (min-width: $break-point-md) {
				transform: translateX(20px);
				opacity: 0;
				transition: all 0.3s;
			}
		}

		&:hover {
			.actions {
				transform: translateX(0px);
				opacity: 1;
			}
		}

	}
</style>
