import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = 'layers';

export const LayerPresenter = {
	list: (params = {}) => $http.get(buildQueryParams(prefix, {
		name: params.name,
		type: params.type,
		rule: params.rule,
		page: params.page,
		perPage: params.perPage,
		sortBy: params.sortBy,
		sortDirection: params.sortDirection,
		isBase: params.isBase
	}))
};
