import { getUrlJornadaDigital } from '@Config/url-jornada-digital';

import { copyToClipboard } from '@Util/functions';

export default {
	data: () => ({
		itemSelected: null
	}),
	methods: {
		copyUrl(item) {
			const url = getUrlJornadaDigital();
			const path = item.status === 'DONE' ? 'pedidos' : 'minha-box';
			copyToClipboard(`${url}/${path}/${item.publicId}`);
		},
		openDialog(item, name) {
			this.itemSelected = item;
			this[name] = true;
		},
		getClosedPackages(item) {
			return !item.posology || item.posology == null ? item.closedPackages : undefined;
		},
		getNumberPackagesNeeded(quantityByProductItem, item) {
			let numberPackagesNeeded = 1;

			if (item.posology)
				numberPackagesNeeded = quantityByProductItem / item.quantityPillsPerPackage;
			else
				numberPackagesNeeded = quantityByProductItem;

			return numberPackagesNeeded;
		}
	}
};
