<template>
	<Dialog titulo="ILPI" :subtitulo="casa_repouso.nome" @close="$emit('close')">
		<DataTableOrcamento :items="orcamentos" :loading="carregando" :search="busca" :error="error"
			@refresh="getOrcamentos" />
	</Dialog>
</template>

<script>
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import DataTableOrcamento from './data-table-orcamento.vue';

export default {
	name: 'DialogILPI',
	components: { DataTableOrcamento },
	props: {
		casa_repouso: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		orcamentos: [],
		busca: '',
		error: false,
		carregando: true
	}),
	created() {
		this.getOrcamentos();
	},
	methods: {
		getOrcamentos() {
			this.carregando = true;
			CasaRepousoPresenter.orcamentos(this.casa_repouso.id)
				.then(orcamentos => {
					this.orcamentos = orcamentos;
				})
				.catch(() => {
					this.error = true;
				})
				.finally(() => {
					this.carregando = false;
				});
		}
	}
};
</script>
