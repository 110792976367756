<template>
	<v-container fluid grid-list-md class="pa-0 mb-2">
		<v-layout v-if="quotation.items.length > 0" column class="mt-4">
			<ListItem title="itens da assinatura" maxWidthTitle="25%" maxWidthHr="66%">
				<Item v-for="(item, i) in quotation.items" :isNewQuotation="isNewQuotation" :key="i" :item="item"
					:index="i"
					:quotation="quotation"
					@editProduct="editProduct" @removeProduct="openDialogRemoveProduct" />
			</ListItem>
		</v-layout>
		<v-layout v-if="specificItems.length > 0" column class="mt-5">
			<ListItem title="Itens de envio imediato (pontual)" maxWidthTitle="40%" maxWidthHr="51%">
				<Item v-for="(item, i) in specificItems" :key="i" :isNewQuotation="isNewQuotation" :item="item"
					:index="i"
					:quotation="quotation"
					@editProduct="editProduct" @removeProduct="openDialogRemoveProduct"/>
			</ListItem>
		</v-layout>
		<v-layout class="mt-3 mb-4 styleDiv flex items-center justify-center pointer" @click="openDialogProducts">
			<span class="inter text-bold caption">
				<v-icon color="black" small class="mr-2">fas fa-plus-circle</v-icon>
				Adicionar produto
			</span>
		</v-layout>
		<Services :quotation="quotation"/>
		<hr class="spacerQuotationStyle">

		<v-dialog persistent v-if="dialogProducts" scrollable v-model="dialogProducts"
			transition="fade" :max-width="isDesktop ? '1360px' : '1200px'">
			<transition>
				<DialogProducts :quotation="quotation" @selectedProduct="prodt => selectProduct(prodt)" :close.sync="dialogProducts"/>
			</transition>
		</v-dialog>

		<v-dialog persistent v-if="dialogProductAndPosology" scrollable v-model="dialogProductAndPosology"
			transition="fade" :max-width="isDesktop ? '1360px' : '1200px'">
			<transition>
				<DialogProductAndPosology :quotation="quotation" :item="selectedProduct" :close.sync="dialogProductAndPosology" @addProduct="addProduct" @updatedProducts="updatedProducts"/>
			</transition>
		</v-dialog>

		<v-dialog persistent v-if="dialogRemoveProduct" v-model="dialogRemoveProduct"
			transition="fade" max-width="450px">
			<transition>
				<DialogRemoveProduct @removeProduct="removeProduct" :close.sync="dialogRemoveProduct"/>
			</transition>
		</v-dialog>
	</v-container>
</template>

<script>
import Services from './services.vue';
import ListItem from './list-item.vue';
import Item from './Item/index.vue';
import DialogProducts from './dialog-products.vue';
import DialogProductAndPosology from './dialog-product-and-posology/index.vue';
import DialogRemoveProduct from './dialog-remove-product.vue';
import Mixin from '../mixin';

export default {
	name: 'ProductsQuotationV2',
	components: {
		Services, Item, ListItem, DialogProducts, DialogProductAndPosology, DialogRemoveProduct
	},
	mixins: [Mixin],
	data: () => ({
		dialogProducts: false,
		dialogProductAndPosology: false,
		dialogRemoveProduct: false,
		selectedProduct: {},
		indexToRemove: null
	}),
	methods: {
		openDialogProducts() {
			this.dialogProducts = true;
		},
		selectProduct(prodt) {
			const product = this.$lodash.cloneDeep(prodt);
			this.selectedProduct = product;
			this.dialogProductAndPosology = true;
			this.dialogProducts = false;
		},
		editProduct(prodt) {
			const product = this.$lodash.cloneDeep(prodt);
			this.selectedProduct = product;
			this.dialogProductAndPosology = true;
		},
		openDialogRemoveProduct(index) {
			this.indexToRemove = index;
			this.dialogRemoveProduct = true;
		},
		removeProduct() {
			if (this.indexToRemove !== -1)
				this.quotation.items.splice(this.indexToRemove, 1);
			this.dialogRemoveProduct = false;
		},
		updatedProducts(updatedProducts) {
			this.quotation.items = updatedProducts;
		},
		addProduct(product) {
			this.quotation.items.push(product);
		}
	},
	computed: {
		isDesktop() {
			return this.$vuetify.breakpoint.xlOnly;
		},
		specificItems() {
			// TO DO PONTUAL
			return [];
		}
	}
};
</script>

<style lang="scss" scoped>
.styleDiv {
	border: 1.85px dashed rgba(52, 43, 29, 0.4);
	padding: 24px 65px;
	border-radius: 5px;

	&:hover {
		border: 1.85px dashed rgba(52, 43, 29, 1);
		border-radius: 5px;
	}
}

</style>
