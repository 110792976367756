<template>
	<div class="px-md-5">
		<HeaderProcesso
			filtro_dias
			v-model="dias"
			titulo="Farmacêutico"
			subtitulo="Inicie o processo de produção das boxes indicando se há ou não pendências"
			subtitulo2="Aqui estão listadas as assinaturas que ainda estão em fase de compra ou as boxes que já iniciaram o atendimento."
			@busca="val => busca = val"
			:show_casa_filter="true"
			:exibirFiltroSuspensos="true"
			:filtrarPorSuspensos="exibirSuspensos"
			@filtrarPorSuspensos="val => exibirSuspensos = val"
			@casas-repouso-updated="onCasaRepousoIds"/>

		<!-- Conteúdo -->
		<DataTableProcessoPagination
			:showPrioridadeAtendimento="true"
			:loading="carregando"
			:exibirSuspensos="exibirSuspensos"
			:search="busca"
			:buttons="buttons"
			:days="dias"
			:sort_by="sortBy"
			:descending="descending"
			:fetch_data="getDataPagination"
			ref="dataTablePagination"
			:rows_per_page="[15, 30, 45, { text: 'Todos', value: -1 }]"
			@selected-updated="arr => selecionados = arr"/>

		<v-dialog
			persistent
			scrollable
			v-model="dialog_iniciar_processo"
			transition="fade"
			max-width="500px">
			<transition>
				<DialogConfirmacao
					v-if="dialog_iniciar_processo"
					@close="dialog_iniciar_processo = false"
					:request="iniciarAtendimento()"
					:opcoes="['Confirmo que iniciarei o processo dessa box']"/>
			</transition>
		</v-dialog>

		<v-dialog
			persistent
			scrollable
			v-model="dialog_enviar_picking"
			transition="fade"
			max-width="500px">
			<transition>
				<DialogConfirmacao
					v-if="dialog_enviar_picking"
					@close="dialog_enviar_picking = false"
					:request="updateStatus('PICKING')"
					:opcoes="['Confirmo que o pedido está pronto para ser liberado para Picking',]"/>
			</transition>
		</v-dialog>

		<v-dialog
			persistent
			scrollable
			v-model="dialog_pendencias"
			transition="fade"
			max-width="620px">
			<transition>
				<DialogPendencias
					v-if="dialog_pendencias"
					:box_id="item_selecionado.box_id"
					:item="item_selecionado"
					:readOnly="item_selecionado.cor_alteracoes === 'red'"
					@close="fecharPendencias"/>
			</transition>
		</v-dialog>

		<v-dialog
			persistent
			v-model="dialog_prescricoes"
			fullscreen
			transition="fade">
			<transition>
				<Dialog
					titulo="Receitas"
					v-if="dialog_prescricoes"
					@close="onCloseReceitas">
					<Receitas
						:item="item_selecionado"
						:assinatura_id="item_selecionado.assinatura_id"
						:paciente_id="item_selecionado.paciente.id"
						:box_id="item_selecionado.id"
						:prescriptionIsVisible="true"
						:prescriptionReadonly="!item_selecionado.e_alteracoes_resolvidas"
						rt
						noCheck/>
				</Dialog>
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { AtendimentoFarmaceuticoMixin } from '@Mixins/atendimento-farmaceutico';
import Receitas from '@Componentes/receitas';
import { BoxPresenter } from '@Presenter/box-presenter';
import HeaderProcesso from '@Componentes/header-processo';
import DataTableProcessoPagination from '@Componentes/data-table-processo-pagination';

export default {
	name: 'Farmaceutico',
	components: { HeaderProcesso, DataTableProcessoPagination, Receitas },
	// recursos compartilhados entre Farmaceutico e Atendimento
	mixins: [AtendimentoFarmaceuticoMixin],
	data: () => ({
		dialog_iniciar_processo: false,
		dialog_enviar_picking: false,
		alterouPendencia: false,
		alterouReceita: false,
		carregando: false,
		exibirSuspensos: false,
		dias: '18',
		busca: '',
		sortBy: 'dias_a_iniciar',
		descending: false,
		casaRepousoIds: []
	}),
	created() {
		this.buttons = this.buildButtons();
	},
	mounted() {
		this.$root.$on('alterou_receita', alterou => {
			this.alterouReceita = alterou;
		});
	},
	beforeAlterouReceita() {
		this.$root.$off('alterou_receita');
	},
	methods: {
		async getDataFromAPI(pagination, search, days, casaRepousoIds, exibirSuspensos) {
			this.carregando = true;

			const params = {
				...pagination, search, days, casa_repouso_ids: casaRepousoIds, exibir_suspensos: exibirSuspensos
			};

			const resp = {};
			await BoxPresenter.fetchFarmaceutico({ params })
				.then(response => {
					resp.items = response.items;
					resp.total = response.total;
				})
				.finally(() => {
					this.carregando = false;
				});
			return resp;
		},

		async getDataPagination() {
			const pagination = { ...this.$refs.dataTablePagination.pagination };
			pagination.order = pagination.descending ? 'desc' : 'asc';

			const resp = await this.getDataFromAPI(pagination, this.busca, this.dias, this.casaRepousoIds, this.exibirSuspensos);
			const items = resp.items;
			const total = resp.total;
			this.$refs.dataTablePagination.updatePage(items, total);
		},
		buildButtons() {
			const buttons = this.getButtons();
			const pendenciesButton = this.buildPendenciesButton();
			const startButton = this.buildStartButton();
			const pickingButton = this.buildPickingButton();
			buttons.splice(1, 0, pendenciesButton);
			buttons.push(startButton);
			buttons.push(pickingButton);
			return buttons;
		},
		buildPendenciesButton() {
			return {
				icon: 'fas fa-clipboard-list',
				getLabel: this.pendenciesLabelFarmaceutico,
				setColor: this.pendenciesColor,
				onClick: item => this.abrirDialog(item, 'dialog_pendencias'),
				condition: item => this.isServiceStarted(item)
			};
		},
		buildStartButton() {
			return {
				label: 'Iniciar Processo',
				icon: 'fas fa-play',
				onClick: item => this.abrirDialog(item, 'dialog_iniciar_processo'),
				condition: item => !this.isServiceStarted(item)
			};
		},
		buildPickingButton() {
			return {
				label: 'Enviar para Picking',
				icon: 'all_inbox',
				onClick: item => this.abrirDialog(item, 'dialog_enviar_picking')
			};
		},
		onCloseReceitas() {
			this.dialog_prescricoes = false;
			this.getDataPagination();

			if (this.alterouReceita)
				this.alterouReceita = false;
		},
		iniciarAtendimento() {
			const status = 'SERVICE_STARTED';
			if (!this.item_selecionado.box_id) {
				return () => new Promise((resolve, reject) => {
					BoxPresenter.store({
						assinatura_id: this.item_selecionado.assinatura_id,
						status
					})
						.then(() => {
							this.afterSave(resolve);
						})
						.catch(() => {
							reject();
						});
				});
			}
			return this.updateStatus(status);
		},
		onCasaRepousoIds(casasRepouso) {
			this.casaRepousoIds = casasRepouso;
			this.getDataPagination();
		},
		pendenciesLabelFarmaceutico(item) {
			const color = this.pendenciesColor(item);

			if (color === 'success')
				return 'Pendências concluídas';
			if (color === 'primary')
				return 'Pendências concluídas mas não finalizadas';
			if (color === 'error')
				return 'Pendências não concluídas';
			if (color === 'warning')
				return 'Responder Atendimento';
			if (color === '')
				return 'Sem pendência';

			return 'Aguardando ação.';
		}
	}
};
</script>
