<template>
	<section class="px-md-5">
		<Header
			@set-status="setStatus"
			@set-search="setSearch"
			@set-origin="setOrigin"
			:filters="orderStatus"
			:origins="origins"/>

		<v-card>
			<DataTablePedidos
				:orders="orders"
				:loading="loading"
				:totalItems="totalItems"
				@pagination-updated="val => updatePagination(val)"/>
		</v-card>
	</section>
</template>

<script>
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import { OrderPresenter } from '@Presenter/order-presenter';
import { isEqual, debounce } from 'lodash';
import { getAllStatusOrder, getDescriptionOrderStatus } from '@Consts/status-order';
import DataTablePedidos from './data-table.vue';
import Header from './header.vue';

export default {
	components: { DataTablePedidos, Header },
	name: 'Orders',
	created() {
		this.resetPagination();

		this.getOrders();

		this.getOrigins();
	},
	data() {
		return {
			orders: [],
			origins: [],
			params: {
				q: null,
				status: null,
				originIds: null
			},
			pagination: {
				descending: true,
				page: 1,
				rowsPerPage: 20,
				sortBy: 'order.id',
				totalItems: 0
			},
			totalItems: 0,
			loading: true,
			error: false
		};
	},
	computed: {
		orderStatus() {
			const status = getAllStatusOrder();

			return Object.keys(status)
				.map(item => ({
					value: item,
					text: status[item]
				}));
		},
		getQueryParams() {
			const originIds = this.params.originIds;
			const statusValues = this.params.status;

			return {
				q: this.params.q || undefined,
				statuses: statusValues ? statusValues.join(',') : undefined,
				originIds: originIds ? originIds.join(',') : undefined,
				sortBy: this.pagination.sortBy || undefined,
				orderBy: this.pagination.descending ? 'DESC' : 'ASC',
				page: this.pagination.page || undefined,
				perPage: this.pagination.rowsPerPage || undefined
			};
		}
	},
	methods: {
		setSearch: debounce(function (search) {
			this.params = { ...this.params, q: search };
		}, 100),

		setOrigin: debounce(function (originIds) {
			this.params = { ...this.params, originIds };
		}, 500),

		setStatus: debounce(function (status) {
			this.params = { ...this.params, status };
		}, 100),

		getOrders: debounce(function () {
			OrderPresenter.get(this.getQueryParams)
				.then(response => {
					this.orders = response.items;
					this.totalItems = response.total;
					this.pagination = {
						...this.pagination,
						page: response.currentPage
					};
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao buscar pedidos', color: 'error' });
				})
				.finally(() => {
					this.loading = false;
				});
		}, 200),

		getOrigins: debounce(function () {
			CasaRepousoPresenter.index({ campos: ['id', 'nome'] })
				.then(response => {
					this.origins = response;
				}).catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao buscar casas de repousos', color: 'error' });
				});
		}, 200),

		getDescription(status) {
			return getDescriptionOrderStatus(status);
		},

		updatePagination(newParams) {
			this.pagination = { ...this.pagination, ...newParams };
			this.getOrders();
		},

		resetPagination() {
			this.pagination = {
				descending: false,
				page: 1,
				rowsPerPage: 20,
				sortBy: 'order.id',
				totalItems: 0
			};
		}

	},
	watch: {
		params: {
			deep: true,
			handler(val, oldVal) {
				if (isEqual(val, oldVal))
					return;

				this.resetPagination();

				this.getOrders();
			}
		},
		pagination: {
			deep: true,
			handler(val, oldVal) {
				if (val?.page === oldVal?.page)
					return;

				this.getOrders();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.v-card {
	overflow: hidden;

	::v-deep .v-table {
		thead,
			tbody {
			tr {
				th,
					td {
					&:first-child,
					&:last-child {
					width: 1%;
					white-space: nowrap;
				}
			}
		}
	}

	tbody {
		tr {
			cursor: pointer;
			}
		}
	}
}
</style>
