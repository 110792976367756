<template>
	<v-card style="width: 100%;">
		<v-card-title class="pb-0">
			<v-flex xs12 md6 style="display: flex; flex-direction: row; align-items: center">
				<v-avatar size="38" class="mr-3">
					<v-icon color="#342B1D" small>fas fa-pills</v-icon>
				</v-avatar>
				<h4>Produtos</h4>
			</v-flex>
			<v-spacer />
		</v-card-title>
		<v-card-text>
			<v-data-table :headers="headers" :items="items"
				hide-actions
				:rows-per-page-items="[items.length]"
				no-data-text="Nenhum produto">
				<template v-slot:items="props">
					<tr >
						<td >
							<span  style="white-space: nowrap">{{ props.item.description }}</span>
						</td>
						<td class="text-xs-center">{{ props.item.quantity }}</td>
						<td class="text-xs-center">{{ props.item.amount | dinheiro}} </td>
						<td class="text-xs-center">{{props.item.discount | dinheiro}} </td>
						<td class="text-xs-center">{{props.item.subTotal | dinheiro}} </td>
					</tr>
				</template>
				<template v-slot:footer>
					<tr>
						<td colspan="3"/>
						<td colspan="1">Subtotal</td>
						<td class="text-xs-center">{{subTotal | dinheiro}}</td>
					</tr>
					<tr v-if="generalDiscount">
						<td colspan="3"/>
						<td colspan="1">Desconto geral</td>
						<td class="text-xs-center" style="color: #039800;">{{generalDiscount | dinheiro}}</td>
					</tr>
					<tr >
						<td colspan="3"/>
						<td colspan="1" class="text-bold">Total</td>
						<td class="text-xs-center">{{total | dinheiro}}</td>
					</tr>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>

export default {
	name: 'Products',
	props: {
		items: {
			required: false,
			type: Array,
			default: () => []
		},
		subTotal: {
			required: false
		},
		generalDiscount: {
			required: false
		},
		total: {
			required: false
		}
	},
	data: () => ({
		headers: [
			{ text: 'Item', value: 'description', sortable: false },
			{
				text: 'Qtd.', value: 'quantity', align: 'center', sortable: false
			},
			{
				text: 'Preço.', value: 'amount', align: 'center', sortable: false
			},
			{
				text: 'Desconto.', value: 'discount', align: 'center', sortable: false
			},
			{
				text: 'Subtotal.', value: 'subTotal', align: 'center', sortable: false
			}
		]
	})
};
</script>
