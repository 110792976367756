<template>
	<v-layout wrap>

		<v-flex xs12>
			<h3 class="mb-2" style="font-size: 14px">Horários de uso</h3>
			<div class="chip-wrapper">
				<v-layout wrap>
					<v-flex v-for="(item, index) in posologia.horarios" :key="index" style="max-width: max-content">
						<v-chip disabled class="black--text pointer" @click="editarHorario(item)">
							<v-icon class="icone">far fa-clock</v-icon>
							{{item.horario }}
						</v-chip>
						<v-select hide-details v-model="item.quantidade_por_horario" :items="quantidade_horario" :readonly="somente_leitura"
							item-value="valor" item-text="nome" color="green" label="Quantidade por horário"
							class="qnt_hor" />
					</v-flex>
				</v-layout>
				<v-btn depressed class="green white--text ml-0" :class="posologia.horarios.length ? null : 'mt-3'"
					@click="dialog_horario = true" v-if="!somente_leitura">
					<v-icon small class="mr-2">add</v-icon>
					Escolher horários
				</v-btn>
			</div>
		</v-flex>

		<v-flex xs12>
			<v-divider />
		</v-flex>

		<v-flex xs12 sm4>
			<h3 class="mb-2 mr-3 inline-block" style="font-size: 14px">Período de uso</h3>
			<v-radio-group v-model="posologia.periodo_de_uso" :mandatory="false" :disabled="somente_leitura" hide-details class="ma-0">
				<v-radio label="Todos os dias" :value="posologyTypes.allDays" color="green" />
				<v-radio label="Dia sim, dia não" :value="posologyTypes.dayYesDayNot" color="green" />
				<v-radio label="Dia não, dia sim" :value="posologyTypes.dayNotDayYes" color="green" />
				<v-radio label="Dias da semana" :value="posologyTypes.weekly" color="green" />
				<v-radio label="Dias do mês" :value="posologyTypes.monthly" color="green" />
				<v-radio label="Outro" :value="posologyTypes.other" color="green" />
			</v-radio-group>
		</v-flex>

		<v-flex xs12 sm8>
			<template v-if="posologia.periodo_de_uso === posologyTypes.weekly">
				<h3 class="mb-0 mr-3" style="font-size: 14px">Escolha os dias da semana</h3>
				<p class="mb-2">Clique no botão do dia para escolher.</p>
				<v-btn small depressed round v-for="(dia, index) in dias_da_semana" :key="index"
					@click="handleDiaSemana(dia)"
					class="ml-0" :class="dia.ativo ? 'primary' : null">
					{{dia.nome}}
				</v-btn>
			</template>

			<template v-if="posologia.periodo_de_uso === posologyTypes.monthly">
				<h3 class="mb-2 mr-3" style="font-size: 14px">Escolha os dias do mês</h3>
				<div class="chip-wrapper">
					<v-chip disabled class="black--text mr-3" v-for="(item, index) in posologia.dias_do_mes"
						:key="index">
						{{ item }}
					</v-chip>
					<v-btn depressed class="green white--text" @click="dialog_dia_mes = true">
						<v-icon small class="mr-2">add</v-icon>
						Escolher os dias
					</v-btn>
				</div>
			</template>

			<template v-if="posologia.periodo_de_uso === posologyTypes.other">
				<h3 class="mb-0 mr-3" style="font-size: 14px">Escolha as opções abaixo</h3>
				<p class="mb-2">Exemplo: Último domingo do mês.</p>
				<v-layout wrap>
					<v-flex :xs12="$vuetify.breakpoint.xsOnly" class="outro-tipo-select pr-sm-0 pl-sm-1">
						<v-select hide-details v-model="posologia.outro_periodo.tipo" label="Primeiro ou último"
							:items="outro_opcao" item-text="nome" />
					</v-flex>
					<v-flex :xs12="$vuetify.breakpoint.xsOnly" class="outro-dia-select pr-sm-0 pl-sm-1">
						<v-select hide-details v-model="posologia.outro_periodo.dia" label="Dia da semana"
							:items="dias_da_semana" item-text="nome" />
					</v-flex>
					<v-flex :xs12="$vuetify.breakpoint.xsOnly">
						<span class="inline-block" style="font-size: 16px; padding-top: 20px">do mês.</span>
					</v-flex>
				</v-layout>
			</template>

		</v-flex>

		<v-dialog persistent scrollable v-model="dialog_horario" transition="fade" max-width="320px">
			<transition>
				<DialogAdicionarHorario v-if="dialog_horario" :horarios="posologia.horarios" :horario="horario_selecionado"
					@horarios="adicionaHorarios" @close="closeDialogAdicionarHorario" @excluir="excluirHorario" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_dia_mes" transition="fade" max-width="320px">
			<transition>
				<DialogAdicionarDias v-if="dialog_dia_mes" :dias="posologia.dias_do_mes" :close.sync="dialog_dia_mes"
					@dias="adicionaDiasMes" />
			</transition>
		</v-dialog>

	</v-layout>
</template>

<script>
import { posologyTypes } from '@Consts/posologia';
import DialogAdicionarHorario from './dialog-adicionar-horario';
import DialogAdicionarDias from './dialog-adicionar-dias';

export default {
	name: 'Posologia',
	components: {
		DialogAdicionarHorario, DialogAdicionarDias
	},
	props: {
		posologia: {
			type: Object,
			required: true
		},
		somente_leitura: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data: () => ({
		posologyTypes,
		quantidade_horario: [
			{ valor: 0.5, nome: 'meio' },
			{ valor: 1, nome: '1' },
			{ valor: 1.5, nome: '1 e meio' },
			{ valor: 2, nome: '2' },
			{ valor: 2.5, nome: '2 e meio' },
			{ valor: 3, nome: '3' },
			{ valor: 3.5, nome: '3 e meio' },
			{ valor: 4, nome: '4' },
			{ valor: 4.5, nome: '4 e meio' },
			{ valor: 5, nome: '5' },
			{ valor: 5.5, nome: '5 e meio' },
			{ valor: 6, nome: '6' },
			{ valor: 6.5, nome: '6 e meio' },
			{ valor: 7, nome: '7' },
			{ valor: 7.5, nome: '7 e meio' },
			{ valor: 8, nome: '8' },
			{ valor: 0.333, nome: '1/3' },
			{ valor: 0.666, nome: '2/3' },
			{ valor: 0.25, nome: '1/4' },
			{ valor: 0.75, nome: '3/4' }
		],
		dias_da_semana: [
			{ nome: 'Segunda', ativo: false },
			{ nome: 'Terça', ativo: false },
			{ nome: 'Quarta', ativo: false },
			{ nome: 'Quinta', ativo: false },
			{ nome: 'Sexta', ativo: false },
			{ nome: 'Sábado', ativo: false },
			{ nome: 'Domingo', ativo: false }
		],
		outro_opcao: [
			'Primeiro(a)',
			'Último(a)'
		],
		horario_selecionado: null,
		dialog_horario: false,
		dialog_dia_mes: false
	}),
	created() {
		this.dias_da_semana.forEach(dia => {
			if ((this.posologia.dias_da_semana || []).length)
				dia.ativo = !!this.posologia.dias_da_semana.find(d => d === dia.nome);
		});
	},
	watch: {
		'posologia.periodo_de_uso': {
			handler(newValue) {
				this.resetPosology();

				if (newValue === posologyTypes.weekly)
					this.dias_da_semana = this.dias_da_semana.map(day => ({ ...day, ativo: false }));

				if (newValue === posologyTypes.other)
					this.posologia.outro_periodo = { tipo: 'Primeiro(a)', dia: 'Segunda' };
			},
			deep: true
		}
	},
	methods: {
		adicionaHorarios(horarios) {
			// Array suporte para remover horários necessários
			const arr = [];

			// Se o horário não estiver no array da posologia, adiciona o novo horário
			horarios.forEach(hor => {
				if (this.posologia.horarios.findIndex(h => h.horario === hor) === -1)
					this.posologia.horarios.push({ horario: hor, quantidade_por_horario: 1 });
			});

			// Se o horário foi removido da lista de horário, remove ele da lista da posologia
			this.posologia.horarios.forEach(hor => {
				if (horarios.findIndex(h => h === hor.horario) !== -1)
					arr.push(hor);
			});
			this.posologia.horarios = arr;

			// Fecha o dialog de horarios
			this.closeDialogAdicionarHorario();
		},
		adicionaDiasMes(dias) {
			this.posologia.dias_do_mes = dias;
			this.dialog_dia_mes = false;
		},
		handleDiaSemana(dia) {
			dia.ativo = !dia.ativo;
			const i = this.posologia.dias_da_semana.findIndex(d => d === dia.nome);
			if (i !== -1)
				this.posologia.dias_da_semana.splice(i, 1);
			else
				this.posologia.dias_da_semana.push(dia.nome);
		},
		editarHorario(horario) {
			this.horario_selecionado = horario;
			this.dialog_horario = true;
		},
		closeDialogAdicionarHorario() {
			// Ordena os horário por ordem crescente
			this.posologia.horarios.sort((a, b) => (a.horario > b.horario ? 1 : -1));

			this.horario_selecionado = null;
			this.dialog_horario = false;
		},
		excluirHorario(horario) {
			const i = this.posologia.horarios.findIndex(h => h.horario === horario);
			if (i !== -1) {
				this.posologia.horarios.splice(i, 1);
				this.closeDialogAdicionarHorario();
			}
		},
		resetPosology() {
			this.posologia.dias_da_semana = [];
			this.posologia.dias_do_mes = [];
			this.posologia.outro_periodo = undefined;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

h2 {
	@media screen and (max-width: $break-point-sm) {
		font-size: 18px !important;
	}
}

.chip-wrapper {
	.v-chip {
		margin-left: 0 !important;
		margin-right: 16px !important;

		.icone {
			font-size: 12px;
			margin-right: 4px;
			opacity: 0.5;
		}
	}

	.qnt_hor {
		display: inline-block;
		max-width: 130px;
	}
}

.outro-tipo-select {
	@media screen and (min-width: $break-point-sm) {
		flex: 0;
		min-width: 150px;
	}
}

.outro-dia-select {
	@media screen and (min-width: $break-point-sm) {
		flex: 0;
		min-width: 130px;
	}
}

::v-deep .v-card__actions {
	background: #f5f5f5;
	padding: 16px 32px;
}

::v-deep .v-input--is-disabled {
	.v-text-field__prefix,
	.v-text-field__suffix {
		opacity: 0.3;
	}
}

::v-deep .v-input--selection-controls {
	.v-label {
		color: #222;
		font-size: 15px;
	}
}
</style>
