<template>
	<hr class="spacerStyle" />
</template>

<script>
export default {
	name: 'FarmeDivider'
};
</script>

<style lang="scss" scoped>
	.spacerStyle {
		border: 3px dotted rgba(255, 200, 117, 0.4);
		width: 100%;
		margin: 32px 0px;
	}
</style>
