<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>fas fa-box-open</v-icon>
			</v-avatar>
			<h3>Histórico box</h3>
			<v-spacer />
			<!-- <v-btn small depressed class="green white--text" @click="dialog_nova_box = true">
				<v-icon class="mr-2">fas fa-plus</v-icon>
				Nova Box
			</v-btn> -->
		</v-card-title>
		<v-card-text>
			<v-data-table disable-initial-sort :headers="headers" :items="boxes" class="tabela-boxes" :pagination.sync="pagination"
				no-data-text="Nenhuma box foi gerada para essa assinatura">
				<template v-slot:items="props">
					<tr class="pointer" @click="openBox(props.item.id)">
						<td>{{ props.item.id }}</td>
						<td class="text-xs-center">{{ props.item.inicio | formatDate('DD/MM/YYYY [(]dddd[)]') }}</td>
						<td class="text-xs-center">
							<v-chip slot="activator" small disabled class="mx-0 white--text info">
								{{ props.item.status | statusBox }}
							</v-chip>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props: {
		assinatura: {
			type: Object,
			required: false
		}
	},
	data: () => ({
		headers: [
			{ text: 'id', value: 'id', width: 1 },
			{ text: 'Data de início da box', value: 'inicio', align: 'center' },
			{
				text: 'Status', value: 'status', align: 'center', sortable: false
			}
		],
		dialog_nova_box: false,
		pagination: { descending: true, sortBy: 'id' }
	}),
	computed: {
		boxes() {
			return this.$store.state.assinatura.historico_boxes;
		}
	},
	methods: {
		apagarBox(id) {
			const i = this.boxes.findIndex(box => box.id === id);
			if (i !== -1)
				this.boxes.splice(i, 1);
		},
		openBox(id) {
			this.$store.commit('SET_BOX_ID', id);
			this.$store.commit('SET_ASSINATURA', this.assinatura);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.tabela-boxes {
  margin: 0 -15px -25px;
}
</style>
