<template>
	<section>
		<v-dialog
			persistent
			scrollable
			v-model="opened"
			width="920px">
			<template v-slot:activator="{ on }">
				<v-btn
					v-on="on"
					small
					:disabled="loading"
					:loading="loading"
					@click="getMedicines"
					color="primary"
					class="pa-4"
					style="border-radius: 10px;">
					Gerar <br> etiqueta
				</v-btn>
			</template>

			<v-card class="farme-dialog">
				<section class="farme-dialog-header">
					Gerar etiquetas
				</section>

				<section class="farme-dialog-body">
					<v-list
						style="background-color: transparent;"
						subheader
						three-line>

						<v-list-tile @click.prevent="tagTypes.rastreio = !tagTypes.rastreio">
							<v-list-tile-action @click.prevent="tagTypes.rastreio = !tagTypes.rastreio">
								<v-checkbox
									false-value
									v-model="tagTypes.rastreio"></v-checkbox>
							</v-list-tile-action>

							<v-list-tile-content>
								<v-list-tile-title>Etiquetas de rastreio</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>

						<v-list-tile @click.prevent="tagTypes.producao = !tagTypes.producao">
							<v-list-tile-action @click.prevent="tagTypes.producao = !tagTypes.producao">
								<v-checkbox
									false-value
									v-model="tagTypes.producao"></v-checkbox>
							</v-list-tile-action>

							<v-list-tile-content >
								<v-list-tile-title>Etiquetas de produção</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>

						<v-list-tile @click.prevent="tagTypes.saquinho = !tagTypes.saquinho">
							<v-list-tile-action @click.prevent="tagTypes.saquinho = !tagTypes.saquinho">
								<v-checkbox
									false-value
									v-model="tagTypes.saquinho"></v-checkbox>
							</v-list-tile-action>

							<v-list-tile-content >
								<v-list-tile-title>Etiquetas de saquinho</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
					</v-list>

					<template v-if="tagTypes.saquinho">
						<hr class="my-3">
						<v-card>
							<v-card-text>
								<p>Selecione os medicamentos a serem impressos na etiqueta de saquinho</p>
								<v-data-table
									v-model="selected"
									:items="medicines"
									:headers="headers"
									:loading="loading"
									select-all>
									<template v-slot:items="props">
										<tr :active="props.selected" @click="props.selected = !props.selected">
											<td>
												<v-checkbox
													:input-value="props.selected"
													primary
													hide-details></v-checkbox>
											</td>
											<td class="">{{ props.item.produto }}</td>
											<td class="">{{ props.item.dosagem }}</td>
											<td class="">{{ props.item.principio_ativo }}</td>
										</tr>
									</template>
								</v-data-table>

							</v-card-text>
						</v-card>
					</template>
				</section>

				<section class="farme-dialog-footer">

					<v-btn
						color="green darken-1"
						flat="flat"
						@click="opened = false">
						Cancelar
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						:loading="loading"
						:disabled="generateTagsIsDisabled"
						@click="generateTags">
						Gerar etiquetas
					</v-btn>
				</section>
			</v-card>
		</v-dialog>
	</section>
</template>

<script>

import { BoxPresenter } from '@Presenter/box-presenter';

export default {
	props: {
		boxId: {
			required: true
		}
	},
	data() {
		return {
			loading: false,
			tagTypes: {
				saquinho: true,
				rastreio: true,
				producao: true
			},
			opened: false,
			medicines: [],
			selected: [],
			pagination: {}
		};
	},
	computed: {
		headers() {
			return [
				{ text: 'Medicamento', sortable: false },
				{ text: 'Dosagem', sortable: false },
				{ text: 'Príncipio ativo', sortable: false }
			];
		},
		generateTagsIsDisabled() {
			return this.loading || Object.values(this.tagTypes)
				.every(value => value === false);
		}
	},
	methods: {
		confirm() {
			this.opened = false;
		},
		async getMedicines() {
			this.loading = true;
			try {
				const boxShowResponse = await BoxPresenter.show(this.boxId);
				this.selected = boxShowResponse.medicamentos.filter(sel => !sel.liquido && !sel.fora_da_box);
				this.medicines = boxShowResponse.medicamentos;
				this.selected = this.medicines;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Status da box atualizado com sucesso!'
				});
			} finally {
				this.loading = false;
			}
		},
		async generateTags() {
			const medsToIgnore = this.medicines.filter(({ id }) => !this.selected.find(med => med.id === id)).map(({ id }) => id);
			this.loading = true;
			const labels = Object.keys(this.tagTypes).filter(key => this.tagTypes[key]);
			try {
				await BoxPresenter.generateAllLabels(this.boxId, medsToIgnore, labels);
				this.opened = false;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao Gerar etiquetas', color: 'error' });
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
