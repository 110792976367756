<template>
	<Dialog titulo="Atualizações" @close="$emit('update:fecha', false)">

		<v-container fluid grid-list-lg class="pa-0">
			<v-layout row wrap>

				<v-flex xs12>
					<v-select :items="versoes" v-model="versao_selecionada" item-text="versao" item-value="versao"
						class="my-0 py-0" hide-details return-object />
				</v-flex>
				<v-layout row wrap>
					<template v-for="(item, i) in versao_selecionada.conteudo">
						<v-flex xs1 :key="i">
							<v-icon small :class="getColor(item)"> {{ getIcon(item) }} </v-icon>
						</v-flex>
						<v-flex xs11 :key="i">
							{{ item.texto }}
						</v-flex>
					</template>
				</v-layout>
				<v-flex xs12>
					<span class="data-atualizacao">
						{{ `Data da atualização: ${versao_selecionada.data}` }}
					</span>
				</v-flex>

			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { items } from '@Config/versions';

export default {
	name: 'DialogAtualizacoes',
	data: () => ({
		versao_selecionada: {},
		versoes: items
	}),
	created() {
		this.versao_selecionada = items[0];
	},
	methods: {
		getColor(item) {
			switch (item.tipo) {
				case 'add':
					return 'green--text';
				case 'del':
					return 'red--text';
				case 'star':
					return 'yellow--text';
				case 'bug':
					return 'pink--text';
				case 'edit':
					return 'blue--text';
				default:
					return '';
			}
		},
		getIcon(item) {
			switch (item.tipo) {
				case 'add':
					return 'add_circle';
				case 'del':
					return 'remove_circle';
				case 'star':
					return 'stars';
				case 'bug':
					return 'bug_report';
				case 'edit':
					return 'edit';
				default:
					return '';
			}
		}
	}
};
</script>

<style scoped>
.centralizar {
  position: absolute;
  left: 0;
  right: 0;
}

.data-atualizacao {
  color: #aaa;
  font-size: 12px;
}
</style>
