<template>
	<v-card class="paciente">
		<v-card-title class="green-title" />

		<!-- Conteúdo -->
		<v-card-text class="conteudo">
			<v-layout align-center justify-start>
				<!-- Avatar -->
				<v-flex class="flex-content pr-1" style="margin-left: -50px;">
					<v-avatar size="70">
						<span class="white--text headline">{{ paciente.nome.charAt(0) }}</span>
					</v-avatar>
				</v-flex><!-- Fim, avatar -->

				<!-- Textos -->
				<v-flex style="margin-right: 50px; padding-right: 50px">
					<h3 class="mb-1">{{ paciente.nome }}</h3>
					<p class="mb-0 grey--text text--darken-2">
						<v-icon small class="mr-1">phone</v-icon>
						{{ paciente.telefone | telefone }}
					</p>
					<p v-if="paciente.email" class="mb-0 grey--text text--darken-2">
						<v-icon small class="mr-1">mail</v-icon>
						{{ paciente.email }}
					</p>
					<p class="mb-0 grey--text text--darken-2">
						<v-icon small class="mr-1">folder_shared</v-icon>
						{{ paciente.cpf | cpf }}
					</p>
					<p v-if="paciente.casa_repouso" class="mb-0 grey--text text--darken-2">
						<v-icon small class="mr-1">home</v-icon>
						{{ paciente.casa_repouso.nome }}
					</p>
				</v-flex><!-- Fim, textos -->

				<v-flex v-if="visao_colaborador" class="flex-column actions-absolute">
					<v-tooltip left v-if="paciente.permissao === null">
						<v-icon slot="activator" color="grey" style="font-size: 16px">fas fa-user-alt-slash</v-icon>
						<span>Essa pessoa não possui usuário.</span>
					</v-tooltip>
					<v-tooltip left v-else-if="paciente.permissao === 1">
						<v-icon slot="activator" color="purple" style="font-size: 16px">fas fa-user-alt</v-icon>
						<span>Essa pessoa é usuário.</span>
					</v-tooltip>
					<v-tooltip left v-else-if="$functions.userIsColaborador(paciente)">
						<v-icon slot="activator" color="orange" style="font-size: 16px">fas fa-star</v-icon>
						<span>Essa pessoa é colaborador.</span>
					</v-tooltip>
					<v-tooltip left v-else-if="$functions.userIsVendedor(paciente)">
						<v-icon slot="activator" color="success" style="font-size: 16px">fas fa-comment-dollar</v-icon>
						<span>Essa pessoa é vendedora.</span>
					</v-tooltip>
				</v-flex>
			</v-layout>
		</v-card-text><!-- Fim, conteúdo -->
	</v-card><!-- Fim, box -->
</template>

<script>
export default {
	name: 'PacienteCard',
	props: {
		paciente: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		menu: false
	}),
	computed: {
		visao_colaborador() {
			return this.$store.getters.visao_colaborador;
		}
	}
};
</script>

<style lang="scss" scoped>
.paciente {
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 10px 100px rgba(#000, 0.14) !important;
  }

  .green-title {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    background-position-y: bottom;
    filter: brightness(120%);
    position: relative;
    z-index: 0;
  }

  .conteudo.v-card__text {
    background: #fff;
    border-radius: 10px 0 0 10px;
    margin-left: 50px;
    position: relative;
    z-index: 1;

    .flex-column {
      display: flex;
      flex-direction: column;
    }

    .flex-content {
      max-width: max-content;

      .v-avatar {
        overflow: hidden;

        img {
          width: 101%;
          height: 101%;
        }
      }
    }

    .actions-absolute {
      position: absolute;
      right: 50px;
    }

    h3 {
      font-size: 16px;
    }
  }
}
</style>
