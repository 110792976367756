<template>
	<v-layout wrap v-if="!tem_medicamento">
		<v-flex md12 xs12>
			<h3 class="mb-2" style="font-size: 14px">Nome do medicamento ou princípio ativo</h3>
			<v-btn depressed color="primary" class="mx-0" @click="escolherMedicamento">
				<v-icon class="mr-2" small>add</v-icon>
				Escolher o medicamento
			</v-btn>
		</v-flex>

		<v-dialog persistent v-if="dialog_pesquisar_medicamento" scrollable v-model="dialog_pesquisar_medicamento"
			transition="fade">
			<transition>
				<DialogPesquisarMedicamento :close.sync="dialog_pesquisar_medicamento"
					@medicamento-selecionado="med => medicamentoSelecionado(med)"
					:medicamento_antigo="medicamento_selecionado.principio_ativo"
					:dosagem="medicamento_selecionado.dosagem"
					:orcamento_filial_id="orcamento_filial_id"
					:restHomeId="restHomeId"
					:externalBilling="externalBilling"
					:fluxo="fluxo"
					:assinaturaId="assinaturaId"
					:tipoCliente="tipoCliente" />
			</transition>
		</v-dialog>
	</v-layout>

	<v-flex xs12 v-else>
		<h3 style="font-size: 14px">Medicamento selecionado</h3>
		<v-layout fill-height align-center>
			<v-flex :style="medicamento.produto_referencia || medicamento.portaria_344_98 ? 'max-width: max-content' : null">
				<p v-if="medicamento && medicamento.produto" class="mb-0">
					<strong>{{ medicamento.produto }}{{ medicamento.dosagem ? ' - ' +
						medicamento.dosagem : '' }} ({{ medicamento.laboratorio || '' }})</strong>
				</p>
				<p v-if="medicamento && medicamento.principio_ativo" class="mb-0">
					{{ medicamento.principio_ativo }}
				</p>
				<p v-if="medicamento && medicamento.apresentacao" class="mb-0">
					{{ medicamento.apresentacao }}
				</p>
			</v-flex>

			<v-flex v-if="medicamento.produto_referencia || medicamento.portaria_344_98">
				<v-tooltip right v-if="medicamento.produto_referencia">
					<v-icon slot="activator" color="success" class="mx-2">info</v-icon>
					<span>O {{ medicamento.produto }} é um produto {{
						medicamento.descricao_tipo_produto }} ao {{ medicamento.produto_referencia
					}}</span>
				</v-tooltip>

				<v-tooltip right v-if="medicamento.portaria_344_98">
					<v-icon slot="activator" color="error" class="mx-2">warning</v-icon>
					<span>Este medicamento necessita da retenção da prescrição médica.</span>
				</v-tooltip>
			</v-flex>

			<v-flex class="align-end" style="max-width: max-content;" v-show="!somente_leitura && permiteTrocarMedicamento">
				<v-tooltip top content-class="top">
					<v-btn small icon @click="medicamentoSelecionado({})" class="mr-0" slot="activator">
						<v-icon small>close</v-icon>
					</v-btn>
					<span>Escolher outro medicamento</span>
				</v-tooltip>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
import DialogPesquisarMedicamento from './dialog-pesquisar-medicamento';

export default {
	name: 'EscolherMedicamento',
	components: { DialogPesquisarMedicamento },
	props: {
		discount_percentage: {
			type: Number,
			require: false
		},
		medicamento: {
			type: Object,
			required: true
		},
		somente_leitura: {
			type: Boolean,
			default: false,
			required: false
		},
		medicamento_selecionado: {
			type: Object,
			default: () => ({})
		},
		orcamento_filial_id: {
			type: Number,
			required: false
		},
		fluxo: {
			type: String,
			required: false
		},
		assinaturaId: {
			type: Number,
			required: false
		},
		restHomeId: {
			required: false
		},
		externalBilling: {
			required: false
		},
		tipoCliente: {
			type: String,
			required: false
		},
		permiteTrocarMedicamento: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		dialog_pesquisar_medicamento: false
	}),
	methods: {
		medicamentoSelecionado(med) {
			this.$emit('medicamento-selecionado', med);
			this.dialog_pesquisar_medicamento = false;
		},
		escolherMedicamento() {
			if (!this.eh_colaborador)
				this.$gtag.event('escolher_medicamento_orcamento');

			this.dialog_pesquisar_medicamento = true;
		}
	},
	computed: {
		tem_medicamento() {
			return typeof this.medicamento.id === 'number';
		},
		eh_colaborador() {
			return this.$store.getters.eh_colaborador;
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
