<template>
	<section>
		<Menu />
		<v-toolbar fixed app flat height="70px">
			<v-toolbar-side-icon @click.stop="handleMenu"></v-toolbar-side-icon>
			<v-card-text>
				<h1 class="text-xs-left" style="opacity: .8">{{cidade}}</h1>
			</v-card-text>
		</v-toolbar>
		<v-content>
			<v-container fluid grid-list-xl>
				<transition name="page" mode="out-in">
					<router-view :key="$route.fullPath" />
				</transition>
			</v-container>
		</v-content>

		<v-dialog persistent scrollable v-model="dialog_box" fullscreen transition="fade">
			<DialogBox :fecha.sync="dialog_box" v-if="dialog_box" />
		</v-dialog>
	</section>
</template>

<script>
import { getCurrentCompanyUnit } from '@Config/unidades';
import Menu from './menu';
import DialogBox from './dialog-box';

export default {
	name: 'EsqueletoColaborador',
	components: {
		Menu, DialogBox
	},

	methods: {
		handleMenu() {
			this.$root.$emit('handle_menu');
		}

	},
	computed: {
		dialog_box() {
			return !!this.$store.state.box_id;
		},
		cidade() {
			return getCurrentCompanyUnit().nome;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.v-content {
  @media screen and (min-width: $break-point-md) {
    margin-top: -30px;
  }
}

.v-toolbar {
  @media screen and (min-width: $break-point-md) {
    background: transparent;
  }
}

</style>
