<template>
	<v-container fluid grid-list-md class="pa-0 pb-4">
		<v-layout wrap>
			<v-flex xs12 md6>
				<div class="barcode">
					<input :type="numeric" v-model="box_id" ref="box_input" @keyup.enter="confirmAndPrint" placeholder="Box ID">
				</div>
			</v-flex>
		</v-layout>

		<!-- Capture and confirmation buttons -->
		<v-select
			append-icon="filter_list"
			v-model="printer"
			label="Selecione a Impressora"
			:items="printers"
			:loading="loading"
			@input="setupPrinter(printer)"/>
		<v-btn depressed class="primary mx-0 pl-2 pr-3" @click="confirmAndPrint" :disabled="!box_id">
			<v-icon small class="mr-2">print</v-icon>
			Imprimir
		</v-btn>
	</v-container>
</template>

<script>
import $http from '@Config/axios';
import { decompress } from 'lz-string';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper-https';
import { numeric } from 'vee-validate/dist/rules.esm';

export default {
	computed: {
		numeric() {
			return numeric;
		}
	},
	data: () => ({
		loading: true,
		browserPrinter: null,
		printers: [],
		printer: null,
		box_id: ''
	}),
	mounted() {
		this.enumerateDevices();
	},
	methods: {
		enumerateDevices() {
			try {
				this.browserPrinter = new ZebraBrowserPrintWrapper();
				this.browserPrinter.getAvailablePrinters().then(printers => {
					this.printers = printers.map(device => ({
						text: device.name,
						value: device
					}));

					this.browserPrinter.getDefaultPrinter().then(device => {
						// Ensure selectedPrinter is set to match one of the items in printerOptions
						this.printer = device ? this.printers.find(printer => printer.value.name === device.name) : null;
						this.setupPrinter(device);
						this.inputFocus(); // Ensuring the DOM is updated before focusing
						this.loading = false;
					});
				}).catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Impressora indisponível.', color: 'error' });
					this.loading = false;
				});
			} catch (error) {
				this.loading = false;
			}
		},
		setupPrinter(printer) {
			// Use the correct property from selectedPrinter when setting up the printer
			this.browserPrinter.setPrinter(printer); // Assuming .value is the correct property
		},
		inputFocus() {
			this.$refs.box_input.focus();
		},
		async confirmAndPrint() {
			await this.$store.dispatch('SHOW_SNACKBAR', {
				message: `Não feche esta tela até que a execução esteja completa. ${ this.box_id }`,
				color: 'primary'
			});

			if (!this.box_id) {
				await this.$store.dispatch('SHOW_SNACKBAR', { message: 'Informe o código da caixa.', color: 'error' });
				return;
			}

			const printerStatus = await this.browserPrinter.checkPrinterStatus();
			if (!printerStatus.isReadyToPrint) {
				await this.$store.dispatch('SHOW_SNACKBAR', { message: 'Impressora não está pronta para imprimir.', color: 'error' });
				return;
			}

			await $http.post('logistica/printer', { ids: [this.box_id] }).then(response => {
				response.labels.forEach(label => {
					this.browserPrinter.print(decompress(label)).then(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Impressão feita com sucesso!', color: 'success' });
						this.box_id = ''; // Clear the input
						this.inputFocus(); // Refocus the input
					}).catch(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao imprimir!', color: 'error' });
					});
				});
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.barcode {
	border-radius: 10px;
	background: url("./barcode.png");

	input {
		text-align: center;
		font-weight: 900;
		font-size: 24px;
		letter-spacing: 1px;
		height: 60px;
		outline: none;
		width: 100%;
	}
}
</style>
