<template>
	<div class="px-md-5">
		<!-- Conteúdo -->
		<iframe width="100%" height="836" src="https://app.powerbi.com/view?r=eyJrIjoiOWRmMjgwNGMtZDQyNS00N2FjLTg0YmEtNThiMjVhN2Q2MGYxIiwidCI6IjdmOWQ4YjNiLWEwMGQtNDc1Yy1hOTFjLTYxOWE0NTc3MDFiYSJ9" frameborder="0" allowFullScreen="true"></iframe>
	</div>
</template>

<script>
export default {
	name: 'RelatorioFaturamento',
	components: {
	},
	data: () => ({
	})
};
</script>
