<template>
	<header class="mb-4">
		<v-layout wrap align-center>
			<v-flex style="max-width: max-content">
				<v-avatar size="64">
					<span class="white--text" style="font-size: 24px">
						{{ paciente.nome.charAt(0) }}
					</span>
				</v-avatar>
			</v-flex>
			<v-flex>
				<h1 class="text--text">{{ paciente.nome }}, {{
					paciente.nascimento |
						idade }}</h1>
				<p class="mb-0 grey--text text--darken-2">
					<v-icon small class="mr-1">phone</v-icon>
					{{ paciente.telefone | telefone }}
				</p>
				<p v-if="paciente.email" class="mb-0 grey--text text--darken-2">
					<v-icon small class="mr-1">mail</v-icon>
					{{ paciente.email }}
				</p>
				<p class="mb-0 grey--text text--darken-2">
					<v-icon small class="mr-1">folder_shared</v-icon>
					{{ paciente.cpf | cpf }}
				</p>
				<p v-if="paciente.plano_saude" class="mb-0 grey--text text--darken-2">
					<v-icon small class="mr-1">payment</v-icon>
					{{ paciente.plano_saude }}
				</p>
			</v-flex>
			<v-flex class="text-md-right">
				<v-btn depressed color="primary" class="mr-2" @click="openModalResetPassword">
					<v-icon small class="mr-2">lock</v-icon>
					Resetar senha
				</v-btn>
				<v-btn depressed color="primary" class="mx-0" @click="$emit('editar-paciente')">
					<v-icon small class="mr-2">edit</v-icon>
					Editar pessoa
				</v-btn>
			</v-flex>
		</v-layout>
		<v-dialog persistent v-model="dialogResetPassword" scrollable transition="fade" max-width="920px">
			<transition>
				<DialogResetPassword v-if="dialogResetPassword" :farmember="paciente" @close="dialogResetPassword = false" />
			</transition>
		</v-dialog>
	</header>
</template>

<script>
import DialogResetPassword from './dialog-resetar-senha.vue';

export default {
	components: { DialogResetPassword },
	props: {
		paciente: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		dialogResetPassword: false
	}),
	methods: {
		openModalResetPassword() {
			this.dialogResetPassword = true;
		}
	}
};
</script>
