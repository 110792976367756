export default {
	props: {
		quotation: {
			type: Object
		},
		isNewQuotation: {
			type: Boolean
		}
	}
};
