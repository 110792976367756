<template>
	<div class="px-md-5">
		<!-- Conteúdo -->
		<iframe width="100%" height="836" src="https://app.powerbi.com/view?r=eyJrIjoiMjhjNTQ3MTEtYWMwOC00MTRhLWE3MGEtMmY1ZTc0ZmMyZTc1IiwidCI6IjdmOWQ4YjNiLWEwMGQtNDc1Yy1hOTFjLTYxOWE0NTc3MDFiYSJ9&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>
	</div>
</template>

<script>
export default {
	name: 'RelatorioChurn',
	components: {
	},
	data: () => ({
	})
};
</script>
