<template>
	<v-autocomplete
		append-icon="filter_list" color="purple" v-model="selectedItems" :label="label"
		no-data-text="Nenhum resultado" :items="items" :item-text="itemText" :item-value="itemValue"
		:prepend-inner-icon="prependInnerIcon" placeholder="Digite para filtrar..."
		small-chips multiple deletable-chips clearable
		@blur="onBlur">
		<template v-slot:append-outer>
			<v-scale-transition mode="out-in">
				<v-icon
					:key="`icon-${hasUpdated}`"
					:disabled="!hasUpdated"
					:color="hasUpdated ? 'success' : 'info'"
					@click="onConfirm"
					v-text="hasUpdated ? 'check_circle' : 'check'"></v-icon>
			</v-scale-transition>
		</template>
	</v-autocomplete>
</template>

<script>
import { isEqual } from 'lodash';

export default {
	name: 'CampoSelecaoMultipla',
	props: {
		items: {
			type: Array,
			required: true
		},
		itemText: {
			type: [Function, String],
			required: true
		},
		itemValue: {
			type: [Function, String],
			required: true
		},
		label: {
			type: String,
			required: false
		},
		prependInnerIcon: {
			type: String,
			required: false
		}
	},
	data: () => ({
		selectedItems: [],
		emittedItems: [],
		hasUpdated: false
	}),
	watch: {
		selectedItems: {
			async handler(val) {
				if (!isEqual(val, this.emittedItems))
					this.hasUpdated = true;
				else
					this.hasUpdated = false;
			},
			deep: true
		}
	},
	methods: {
		onConfirm() {
			this.$emit('items-updated', this.selectedItems);
			this.emittedItems = [...this.selectedItems];
			this.hasUpdated = false;
		},
		onBlur() {
			if (this.hasUpdated)
				this.onConfirm();
		}
	}
};
</script>
