<template>
	<Dialog
		titulo="Cadastrar Princípio ativo"
		@acao="save()"
		btn_acao="Confirmar"
		@close="$emit('close')"
		background="rgba(255, 234, 204, 1)"
		backgroundFooter="rgba(255, 234, 204, 1)">
		<v-container>
			<v-layout wrap>
				<v-flex xs12>
					<v-text-field
						v-model="form.name"
						background-color="white"
						box
						label="Nome"
						v-validate="{ required: true }"
						data-vv-name="Nome"
						:error-messages="errors.collect('Nome')"/>
				</v-flex>

				<v-flex xs12>
					<v-text-field
						v-model="form.nameReduced"
						background-color="white"
						box
						label="Nome reduzido"
						v-validate="{ required: true }"
						data-vv-name="Nome reduzido"
						:error-messages="errors.collect('Nome reduzido')"/>
				</v-flex>

				<v-flex xs12>
					<v-textarea
						v-model="form.description"
						background-color="white"
						label="Descrição"
						box
						v-validate="{ required: true }"
						data-vv-name="Descrição"
						:error-messages="errors.collect('Descrição')"/>
				</v-flex>

				<v-flex xs12>
					<v-autocomplete
						v-model="form.atcId"
						background-color="white"
						prepend-inner-icon="search"
						:items="actsClassification"
						box
						label="Classificação ATC"
						clearable
						item-value="id"
						item-text="atc"
						data-vv-name="Classificação ATC"
						v-validate="{ required: false }"
						:error-messages="errors.collect('Classificação ATC')">
					</v-autocomplete>
				</v-flex>

				<v-flex xs12>
					<v-text-field
						v-model="form.dcb"
						background-color="white"
						box
						label="Código DCB"
						v-validate="{ required: true }"
						data-vv-name="Código DCB"
						:error-messages="errors.collect('Código DCB')"/>
				</v-flex>
			</v-layout>
		</v-container>
		<FarmeDivider />
	</Dialog>
</template>

<script>
import { ActiveIngredientPresenter } from '@Presenter/active-ingredient-presenter';
import FarmeDivider from '@Views/componentes/farme-divider.vue';

export default {
	name: 'DialogCreateActiveIngredient',
	$_veeValidate: { validator: 'new' },
	props: {
		actsClassification: {
			required: true,
			type: Array
		}
	},
	components: {
		FarmeDivider
	},
	data: () => ({
		loading: true,
		form: {
			name: null,
			nameReduced: null,
			description: null,
			atcId: null,
			dcb: null
		}
	}),
	methods: {
		async save() {
			const formIsValidated = await this.$validator.validateAll();

			if (!formIsValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário inválido',
					color: 'error'
				});
				return;
			}

			try {
				await ActiveIngredientPresenter.create({
					name: this.form.name,
					description: this.form.description,
					atcId: this.form.atcId,
					nameReduced: this.form.nameReduced,
					dcb: this.form.dcb
				});

				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Princípio ativo cadastrado com sucesso',
					color: 'success'
				});
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao criar Princípio ativo',
					color: 'error'
				});
				throw new Error(error);
			} finally {
				this.$emit('close');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
