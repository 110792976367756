import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = '/invoices';

export const InvoicesPresenter = {
	/**
 * @param {object} params
 * @param {String?} params.q
 * @param {Array?} params.status
 * @param {Number?} params.page
 * @param {Number?} params.perPage
 * @param {String?} params.sortBy
 * @param {String?} params.orderBy - ASC | DESC
 * @returns {Promise<{items: Array, page: Number, pages: Number, total: Number}>}
 */
	getAllInvoices: params => $http.get(buildQueryParams(prefix, params)),
	show: id => $http.get(`${prefix}/${id}`),
	getPayments: id => $http.get(`${prefix}/${id}/payments`),
	/**
	 * @param {String} invoiceId
	 * @param {Number | null} amount
	 * @returns {Promise<Object>}
	 */
	generateRefunds: (invoiceId, amount) => $http.post(`${prefix}/${invoiceId}/refunds`, { amount }),
	createInvoice: params => $http.post(`${prefix}`, params),
	manageInvoice: params => $http.post(`${prefix}/manage`, params),
	duplicateInvoice: (invoiceId, body) => $http.post(`${prefix}/${invoiceId}/duplicate`, body),
	cancelInvoice: invoiceId => $http.delete(`${prefix}/${invoiceId}`)
};
