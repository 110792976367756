<template>
	<Dialog
		titulo="Ocorrências"
		subtitulo="Caso não tenha acontecido algo, apenas clique em salvar."
		btn_acao="Salvar"
		btn_cancelar="Cancelar"
		@acao="salvar()"
		@close="fecharModal()"
		:loading="loading">
		<v-container class="pa-0">
			<v-layout wrap>
				<template v-if="status === 'CHECKED'">
					<v-flex xs12>
						<v-select
							v-model="ocorrencias"
							:items="opcoes"
							label="Ocorrencias"
							multiple>
							<template v-slot:prepend-item>
								<v-list-item
									ripple
									@mousedown.prevent>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template>
						</v-select>
					</v-flex>
					<v-flex v-if="checkOutros" xs12>
						<v-textarea class="mt-3" v-model="outros" box hide-details label="Outras ocorrências"
							placeholder="Quais foram as outras ocorrencias? Digite aqui" />
					</v-flex>
				</template>
				<v-flex v-else xs12>
					<v-textarea class="mt-3" v-model="ocorrencia" box hide-details label="Outras ocorrências"
						placeholder="Aconteceu algo durante a produção? Se sim, digite aqui." />
				</v-flex>
				<v-flex xs12>
					<slot />
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { OcorrenciaPresenter } from '@Presenter/ocorrencia-presenter';

export default {
	name: 'DialogOcorrencias',
	props: {
		status: {
			type: String,
			required: true
		},
		box_id: {
			type: Number,
			required: true
		},
		request: {
			type: Function,
			required: true
		}
	},
	data: () => ({
		loading: false,
		opcoes: [
			'Resetar Box',
			'Outros',
			'Medicamento no horário errado',
			'Falta de medicamento',
			'Produzido por outra pessoa',
			'Esqueceu finalizar',
			'Falta de etiqueta',
			'Esqueceu iniciar',
			'Sem box',
			'Comprimidos já cortados',
			'Problema Software',
			'Pausa para almoço',
			'Comprimido no copinho',
			'Caiu comprimido'
		],
		ocorrencias: [],
		ocorrencia: '',
		justificativa: '',
		solucao: '',
		outros: ''
	}),

	methods: {
		salvar() {
			if (this.opcoes.some(opt => opt.selected) && !this.solucao) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'É necessário informar a solução' });
				return;
			}
			this.loading = true;
			const ocorrenciaFinal = [...this.ocorrencias, this.ocorrencia].filter(o => !!o).map(ocorr => {
				if (ocorr === 'Outros' && this.outros)
					ocorr = `Outros: ${this.outros}`;

				return ocorr;
			}).join(' | ');
			Promise.all([
				OcorrenciaPresenter.store({
					box_id: this.box_id,
					status: this.status,
					ocorrencia: ocorrenciaFinal
				}),
				this.request()
			])
				.then(() => {
					this.fecharModal();
				})
				.finally(() => {
					this.loading = false;
				});
		},
		fecharModal() {
			this.$emit('update:close', false);
		}
	},
	computed: {
		checkOutros() {
			if (this.ocorrencias.includes('Outros'))
				return true;
			return false;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.chip {
	display: inline-block;
	background: rgba(0,0,0,.06);
	border-radius: 15px;
	padding: 5px 10px;
	margin: 5px;

	&.selected {
		background: $purple;
		color: white;
	}
}
</style>
