<template>
	<div>
		<v-container grid-list-md text-xs-center>
			<v-flex xs12>
				<v-text-field
					box
					background-color="white"
					label="Endereço"
					v-model="traceabilityForm.address"
					data-vv-name="Endereço"
					:error-messages="[]"
					:append-icon="'barcode_reader'"
					@keyup.enter="handleAction('endereco')"
					@click:append="scanBarCode('endereco')"/>
				<v-text-field
					box
					background-color="white"
					label="Etiqueta"
					v-model="traceabilityForm.label"
					ref="etiqueta"
					data-vv-name="Etiqueta"
					:error-messages="[]"
					:append-icon="'barcode_reader'"
					@keyup.enter="handleAction('etiqueta')"
					@click:append="scanBarCode('etiqueta')"/>
				<v-text-field
					box
					background-color="white"
					label="GTIN"
					v-model="traceabilityForm.gtin"
					data-vv-name="GTIN"
					ref="gtin"
					:error-messages="[]"
					:append-icon="'barcode_reader'"
					@click:append="scanBarCode('gtin')"/>
				<v-btn depressed class="primary mx-0 pl-2 pr-3 mr-4" @click="showCamera = true">
					<v-icon small class="mr-2">photo_camera</v-icon>
					Capturar lote e validade
				</v-btn>
				<v-text-field
					box
					background-color="white"
					label="Lote"
					v-model="traceabilityForm.lot"
					data-vv-name="Lote"
					:error-messages="[]"/>
				<v-text-field
					box
					background-color="white"
					label="Validade"
					v-model="traceabilityForm.expiration_date"
					data-vv-name="Validade"
					:error-messages="[]"/>
			</v-flex>
			<v-flex xs12>
				<v-btn depressed class="primary px-0" @click="saveForm" :loading="loadingForm || loading">
					<v-icon small class="mr-2">send</v-icon>
					Salvar
				</v-btn>
			</v-flex>
		</v-container>
		<v-container fluid grid-list-md class="pa-0 pb-4">
			<v-dialog
				v-model="showCamera"
				persistent
				max-width="600px">
				<Dialog titulo="Lote e Validade" subtitulo="Tire a foto do lote e validade" @close="closeCamera" :carregando="loading"
					btn_acao="Capturar" @acao="confirmAndUpload" :loading="loading">
					<v-layout align-center justify-center column v-show="!capturedImage1">
						<!-- Camera 1 and its dropdown -->
						<video ref="video1" width="320" height="240" autoplay playsinline
							face></video>
						<v-select
							append-icon="filter_list"
							v-model="selectedCamera1"
							label="Selecione a Câmera 1"
							:items="cameraOptions"
							@input="changeCamera()"/>
					</v-layout>
					<v-layout align-center justify-center row fill-height>
						<v-flex xs12>
							<img class="mx-4" v-show="capturedImage1" :src="capturedImage1" width="320" height="240"/>
						</v-flex>
					</v-layout>
				</Dialog>
			</v-dialog>
			<v-dialog
				v-model="showScanner"
				persistent
				max-width="600px">
				<StreamBarcodeReader @decode="onScanBarDecode" @error="onScanBarDecode"></StreamBarcodeReader>
			</v-dialog>
			<!-- Capture and confirmation buttons -->
		</v-container>
	</div>
</template>

<script>
import farme_ai_http from '@Config/farme-ai';
import { compressToBase64 } from 'lz-string';
import { StreamBarcodeReader } from 'vue-barcode-reader';

export default {
	components: {
		StreamBarcodeReader
	},
	data() {
		return {
			videoDevices: [],
			selectedCamera1: '',
			showWebcams: true,
			cameraOptions: [],
			capturedImage1: null,
			capturedImage2: null,
			loading: false,
			loadingForm: false,
			showCamera: false,
			showScanner: false,
			traceabilityResponse: {},
			traceabilityForm: {},
			barCodeScanning: null,
			initFocusEan: false
		};
	},
	mounted() {
		this.enumerateDevices();
	},
	beforeDestroy() {
		// Stop all webcam streams
		if (this.$refs.video1 && this.$refs.video1.srcObject)
			this.$refs.video1.srcObject.getTracks().forEach(track => track.stop());
	},
	methods: {
		handleAction(field) {
			if (field === 'endereco')
				this.$refs.etiqueta.focus();
			if (field === 'etiqueta')
				this.$refs.gtin.focus();
		},
		scanBarCode(data) {
			this.showScanner = true;
			this.barCodeScanning = data;
		},
		onScanBarDecode(data) {
			if (this.barCodeScanning === 'etiqueta')
				this.traceabilityForm.label = data;
			if (this.barCodeScanning === 'gtin')
				this.traceabilityForm.gtin = data;

			this.barCodeScanning = null;
			this.showScanner = false;
		},
		resetImagesAndWebcams() {
			if (this.capturedImage1) {
				this.capturedImage1 = null;
				this.showWebcams = true;
				this.showCamera = false;
				this.traceabilityForm = {};
				this.setupWebcams();
			}
		},
		closeCamera() {
			this.showCamera = false;
			this.capturedImage1 = null;
		},
		async enumerateDevices() {
			try {
				navigator.permissions.query(
					{ name: 'camera' }
				)
					.then(permission => console.log('Camera permission:', permission));
				const devices = await navigator.mediaDevices.enumerateDevices();
				this.videoDevices = devices.filter(device => device.kind === 'videoinput');
				this.cameraOptions = this.videoDevices.map(device => ({
					text: device.label || `Camera ${ device.deviceId }`,
					value: device.deviceId
				}));

				this.selectedCamera1 = this.videoDevices[this.videoDevices.length - 1]?.deviceId || '';
				await this.setupWebcams();
			} catch (error) {
				await this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Error enumerating devices',
					color: 'error'
				});
			}
		},
		async setupWebcams() {
			await this.setVideoSource(this.$refs.video1, this.selectedCamera1);
		},
		async setVideoSource(videoElement, deviceId) {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId, facingMode: { ideal: 'environment' } } });
				videoElement.srcObject = stream;
			} catch (error) {
				await this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Error setting up video source',
					color: 'error'
				});
			}
		},
		changeCamera() {
			this.setVideoSource(this.$refs.video1, this.selectedCamera1);
		},
		captureEnter() {
			this.capturedImage1 = this.takeSnapshot(this.$refs.video1);
		},
		takeSnapshot(videoElement) {
			const canvas = document.createElement('canvas');
			canvas.width = videoElement.videoWidth;
			canvas.height = videoElement.videoHeight;
			canvas.getContext('2d').drawImage(videoElement, 0, 0);
			return canvas.toDataURL('image/jpeg');
		},
		async uploadImages(image1) {
			const payload = {
				image1_base64: compressToBase64(image1)
			};
			return farme_ai_http.post('/traceability/images', payload);
		},
		async confirmAndUpload() {
			this.loading = true;
			this.captureEnter();
			await this.$store.dispatch('SHOW_SNACKBAR', {
				message: 'Não feche esta tela até que a execução esteja completa.',
				color: 'warning'
			});

			const error = () => {
				this.$store.dispatch('SHOW_SNACKBAR', { message: 'Não foi possível identificar os dados. Preencha manualmente.', color: 'error' });
				this.closeCamera();
			};
			this.uploadImages(
				this.capturedImage1
			)
				.then(response => {
					this.traceabilityResponse = response;
					this.traceabilityForm.parent_id = response.id;
					this.traceabilityForm.lot = response.lot;
					this.traceabilityForm.expiration_date = response.expiration_date;
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Revise os dados e finalize.', color: 'success' });
				})
				.catch(error)
				.finally(() => {
					this.loading = false;
					this.closeCamera();
				});
		},
		clearForm() {
			this.traceabilityForm = {};
		},
		async saveForm() {
			this.loadingForm = true;

			await farme_ai_http.post('/traceability', this.traceabilityForm)
				.then(() => {
					this.resetImagesAndWebcams();
					this.clearForm();
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Rastreabilidade atualizada com sucesso.', color: 'success' });
				})
				.catch(err => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Ocorreu um erro ao salvar os dados. Tente novamente.', color: 'error' });
					console.error(err);
				})
				.finally(() => {
					this.loadingForm = false;
				});
		}
	}
};
</script>
