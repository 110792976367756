import moment from 'moment';
import { daysOfWeek } from '@Consts/dias_semana';
import { departments, departmentsMap } from '@Consts/departamentos';
import { companyUnits } from '@Consts/unidades';

export const CalendarioMixin = {
	data: () => ({
		daysOfWeek,
		departments,
		departmentsMap,
		companyUnits,
		type: 'regra'
	}),
	methods: {
		convertDate(response) {
			return `${String(response.day).padStart(2, '0')}/${String(response.month).padStart(2, '0')}/${response.year || moment().year()}`;
		}
	}
};
