const PAYMENT_TYPE_OPEN_CLOSE_VALUE = 'ABERTURA_FECHAMENTO';
const PAYMENT_TYPE_OPEN_CLOSE_TEXT = 'Abertura e Fechamento';
export const PAYMENT_TYPE_OPEN_CLOSE = Object.freeze({
	value: PAYMENT_TYPE_OPEN_CLOSE_VALUE,
	text: PAYMENT_TYPE_OPEN_CLOSE_TEXT
});

const PAYMENT_TYPE_SINGLE_CHARGE_VALUE = 'COBRANCA_AVULSA';
const PAYMENT_TYPE_SINGLE_CHARGE_TEXT = 'Cobrança avulsa';
export const PAYMENT_TYPE_SINGLE_CHARGE = Object.freeze({
	value: PAYMENT_TYPE_SINGLE_CHARGE_VALUE,
	text: PAYMENT_TYPE_SINGLE_CHARGE_TEXT
});

export const PAYMENT_TYPES = Object.freeze([
	PAYMENT_TYPE_OPEN_CLOSE,
	PAYMENT_TYPE_SINGLE_CHARGE
]);
