<template>
	<v-navigation-drawer right v-model="drawer" width="240px" app>
		<v-toolbar flat dark height="70px">
			<v-list>
				<v-list-tile>
					<v-list-tile-title class="titulo">
						Menu
					</v-list-tile-title>
				</v-list-tile>
			</v-list>
		</v-toolbar>
		<v-list>
			<v-list-tile v-for="(item, index) in menu" :key="index" :to="item.path">
				<v-list-tile-action>
					<v-icon>{{ item.icone }}</v-icon>
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>{{ item.nome }}</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>

			<v-divider />

			<v-list-tile v-if="$store.getters.eh_colaborador" @click="irParaColaborador()">
				<v-list-tile-action>
					<v-icon>star</v-icon>
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>Colaborador</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>

			<v-list-tile v-if="$store.getters.eh_vendedor" @click="irParaOrcamentos()">
				<v-list-tile-action>
					<v-icon>star</v-icon>
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>Vendedor</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>

			<v-list-tile @click="sair">
				<v-list-tile-action>
					<v-icon>exit_to_app</v-icon>
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>Sair</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import Mixin from './mixin';
import menu from './menu';

export default {
	name: 'MenuMobile',
	mixins: [Mixin],
	data() {
		return {
			menu,
			drawer: false
		};
	},
	mounted() {
		this.$root.$on('nav-drawer', () => {
			this.drawer = !this.drawer;
		});
	},
	beforeDestroy() {
		this.$root.$off('nav-drawer');
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.titulo {
  font-weight: bold;
}

.v-toolbar {
  background: $purple_strong;
  background-size: cover;
  color: #fff;
}

</style>
