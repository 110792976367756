<template>
	<v-container fluid grid-list-md class="pa-0">
		<v-layout wrap>
			<v-flex xs12 md6>
				<v-text-field v-model="orcamento.nome" box label="Nome *" data-vv-name="Nome" hide-details
					v-validate="{ required: true }" :error-messages="errors.collect('Nome')" />
			</v-flex>
			<v-flex xs12 md6>
				<v-text-field v-model="orcamento.cpf" box hide-details label="CPF" mask="###.###.###-##"
					v-validate="{ cpf: true }" data-vv-name="cpf" :error-messages="errors.first('cpf')" />
			</v-flex>
			<v-flex xs12 md6>
				<v-text-field v-model="orcamento.telefone" hide-details v-mask="'(##) #####-####' || '(##) ####-####'"
					box label="Telefone *" data-vv-name="Telefone" v-validate="{ required: true, telefone: true }"
					:error-messages="errors.collect('Telefone')" />
			</v-flex>
			<v-flex xs12 md6>
				<v-text-field v-model="orcamento.email" box hide-details label="E-mail" data-vv-name="email"
					v-validate="{ required: false, email: true }" :error-messages="errors.collect('email')" />
			</v-flex>
			<v-flex xs12 md6>
				<div class="box" @click="dialog_tags = true">
					<label class="ml-2 v-label" :class="{ 'with-items': (orcamento.tags || []).length > 0 }">
						Tags
					</label>
					<v-chip v-for="tag in orcamento.tags" :key="tag.id" disabled class="purple white--text">
						{{ tag.nome }}
					</v-chip>
				</div>
			</v-flex>
			<v-flex xs12 md6>
				<v-autocomplete v-model="orcamento.casa_repouso" box hide-details label="Casa de repouso" :items="casas_repouso"
					placeholder="Digite para filtrar..." item-value="id" item-text="nome" :loading="loading_casas_repouso"
					:return-object="true" @input="checkILPI" :readonly="orcamento.medicamentos.length > 0" :clearable="orcamento.medicamentos.length === 0" />
			</v-flex>
			<v-flex xs12 md6>
				<v-autocomplete
					v-model="orcamento.filial_id"
					:items="units"
					box
					hide-details
					label="Unidade *" placeholder="Digita para filtrar..."
					clearable
					:error-messages="errors.collect('Unidade')"
					v-validate="{ required: true }"
					data-vv-name="Unidade"
					item-value="id" item-text="city" />
			</v-flex>
			<v-flex xs12 md6>
				<v-checkbox
					primary
					:readonly="orcamento.medicamentos.length > 0"
					hide-details
					v-model="orcamento.cobranca_externa"
					label="Cobrança externa"/>
			</v-flex>
		</v-layout>

		<v-dialog v-model="dialog_tags" persistent scrollable transition="fade" max-width="840px">
			<transition>
				<DialogTags v-if="dialog_tags" :tags="orcamento.tags"
					@novas-tags="tags => orcamento.tags = tags" @close="dialog_tags = false" />
			</transition>
		</v-dialog>
	</v-container>
</template>

<script>
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import DialogTags from '@Componentes/dialog-tags';
import { RESPONSIBLE_PAYMENT_REST_HOME } from '@Consts/rest-home/responsible-payment';
import mixin from './mixin';

export default {
	name: 'DadosPessoais',
	components: { DialogTags },
	$_veeValidate: { validator: 'new' },
	mixins: [mixin],
	emits: ['erros'],
	data: () => ({
		dialog_tags: false,
		casas_repouso: [],
		loading_casas_repouso: true,
		units: [
			{ id: 1, city: 'Belo Horizonte' },
			{ id: 2, city: 'São Paulo' }
		]
	}),
	async created() {
		this.casas_repouso = await CasaRepousoPresenter.index({ campos: ['id', 'nome'] });
		this.loading_casas_repouso = false;
	},
	watch: {
		'errors.items': function (err) {
			this.$emit('erros', err);
		}
	},
	methods: {
		checkILPI(restHome) {
			if (!restHome) {
				this.orcamento.cobranca_externa = false;
				return;
			}
			const { responsavel_pagamento } = restHome;
			if (responsavel_pagamento === RESPONSIBLE_PAYMENT_REST_HOME.value)
				this.orcamento.cobranca_externa = true;
		},
		async validate() {
			return Boolean(await this.$validator.validateAll());
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
