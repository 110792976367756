<template>
	<div :class="!noZoom && 'dialog-container'">
		<div v-if="loading" class="q-row items-center justify-center full-height">
			<v-progress-circular indeterminate color="primary" />
		</div>
		<v-layout v-else wrap>
			<v-flex class="flex-left" :class="noZoom ? 'xs12' : 'xs6 pa-4'">
				<Receitas
					:receitas="receitas"
					:readOnly="noUpload"
					:box_id="box_id"
					:assinatura_id="assinatura_id"
					:index="receita_selecionada_index"
					class="mb-4"
					@receita-selecionada="(i) => (receita_selecionada_index = i)"/>
				<v-layout v-if="!noZoom" wrap column>
					<v-card v-if="receita" class="flex-text-card">
						<span><b>Prescrição: </b> ID {{ receita.id }}</span>
						<template v-if="receita.prescritor">
							<span><b>Nome do prescritor: </b>{{ receita.prescritor.name}}</span>
							<span><b>UF: </b> {{ receita.prescritor.uf || "-"}}</span>
							<span><b>Tipo de registro: </b> {{ receita.prescritor.typeOfRegister || '-' }}</span>
							<span><b>Nº da notificação: </b> {{ receita.numero_notificacao || "-"}}</span>
						</template>
						<span>
							<b>Data da prescrição: </b>
							{{  receita.data_prescrita | formatDate('DD/MM/YYYY')}}
						</span>

						<template v-if="!receita.prescritor">
							Não há prescritor vinculado
						</template>
					</v-card>
					<v-flex v-if="receitas.length && receita && !receita.url.includes('.pdf')" class="flex-1 mt-3" style="max-height: 60%">
						<v-layout wrap class="img-container">
							<v-flex shrink>
								<v-icon color="grey" @click.stop="next(-1)">keyboard_arrow_left</v-icon>
							</v-flex>
							<v-flex grow style="background: #EAEAEA; max-height: 250px">
								<zoom-on-hover :img-normal="receita.url" />
							</v-flex>
							<v-flex shrink>
								<v-icon color="grey" @click.stop="next(1)">keyboard_arrow_right</v-icon>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex v-if="!noZoom" :class="`flex-right scroll-style ${ noZoom ? 'xs12' : 'pa-4 xs6'}`">
				<Farmembro :farmembro="assinatura.paciente" class="mb-4"/>
				<Observacao :assinatura="assinatura" class="mb-4" @observationUpdated="getSignatureAndBox"/>
				<Pendencias :box_id="this.box_id" :item="this.item" :readOnly="prescriptionReadonly" />
				<Medicamentos  v-if="!noMed" :receitas="receitas"
					:origem="origem"
					:assinatura="assinatura"
					:boxId="this.box_id"
					:medicamentos="medicamentos"
					:medicamentosComAproveitamentoReceita="medicamentosComAproveitamentoReceita"
					:readOnly="noCheck"
					:prescriptionReadonly="prescriptionReadonly"
					:prescriptionIsVisible="prescriptionIsVisible"
					:showApproveChanges="item.e_rascunho_finalizado"
					:eRescunho="this.eRascunho"
					:showCheckItem="true"
					@rascunho-aprovado="getSignatureAndBox"
					@receita-vinculada="getSignatureAndBox"
					class="mb-4" />
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { RascunhoPresenter } from '@Presenter/alteracoes-presenter';
import { BoxPresenter } from '@Presenter/box-presenter';
import { isBoxInProcess } from '@Util/functions';
import { ReceitaMixin } from '@Mixins/receitas';
import Observacao from '@Componentes/assinatura/observacao';
import Farmembro from '@Componentes/dialog-comparar-receitas-medicamentos/farmembro';
import Medicamentos from '@Componentes/dialog-comparar-receitas-medicamentos/medicamentos';
import Receitas from '@Componentes/dialog-comparar-receitas-medicamentos/receitas';
import Pendencias from '@Componentes/dialog-comparar-receitas-medicamentos/pendencias';

export default {
	name: 'DialogCompararReceitasMedicamentos',
	components: {
		Medicamentos, Receitas, Farmembro, Observacao, Pendencias
	},
	mixins: [ReceitaMixin],
	props: {
		item: {
			type: Object
		},
		box_id: {
			type: Number
		},
		assinatura_id: {
			type: Number
		},
		paciente_id: {
			type: Number
		},
		noUpload: {
			type: Boolean,
			required: false
		},
		noCheck: {
			type: Boolean,
			required: false
		},
		noMed: {
			type: Boolean,
			required: false
		},
		noZoom: {
			type: Boolean,
			required: false
		},
		prescriptionIsVisible: {
			type: Boolean,
			required: false,
			default: true
		},
		prescriptionReadonly: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data: () => ({
		assinatura: {},
		origem: 'Box',
		receitas: [],
		medicamentos: [],
		medicamentosComAproveitamentoReceita: [],
		loading: true,
		eRascunho: false
	}),
	created() {
		this.getSignatureAndBox();
	},
	methods: {
		close() {
			this.$emit('close');
		},
		getSignatureAndBox() {
			this.loading = true;
			const promises = [AssinaturaPresenter.show(this.assinatura_id)];

			if (this.box_id) {
				promises.push(BoxPresenter.show(this.box_id));
				promises.push(RascunhoPresenter.get(this.box_id));
			}

			Promise.all(promises).then(([assinatura, box, rascunho]) => {
				const item = box || assinatura;
				this.receitas = item.receitas;
				this.assinatura = assinatura;
				this.medicamentos = item.medicamentos;

				if (box && !isBoxInProcess(box.status)) {
					if (rascunho && rascunho.medicamentos) {
						this.eRascunho = true;
						if (box.status === 'SERVICE_ENDED')
							this.origem = 'Rascunho aprovado';
						else
							this.origem = this.item.e_alteracoes_resolvidas ? 'Rascunho finalizado' : 'Rascunho em aberto';
						const assinaturaMedicamentos = rascunho.medicamentos.map(am => ({
							alteracao_id: am.id,
							...am,
							...am.medicamento,
							posologia: JSON.parse(am.posologia)
							// receitas: am.receitas.filter(r => r.box_id === am.box_id)
						}));
						this.medicamentosComAproveitamentoReceita = assinaturaMedicamentos.map(i => ({
							medicineId: i.medicamento_id,
							totalCycles: i.ciclos_extras
						}));
						this.medicamentos = assinaturaMedicamentos;
					} else {
						this.origem = 'Assinatura';
						BoxPresenter.getAproveitamentoReceita(this.box_id).then(aproveitamento => {
							this.medicamentosComAproveitamentoReceita = aproveitamento;
							const assinaturaMedicamentos = assinatura.medicamentos.map(am => ({
								...am,
								receitas: am.receitas.filter(r => r.box_id === box.id)
							}));

							this.medicamentos = assinaturaMedicamentos;
						});
					}
				}
			}).finally(() => {
				this.loading = false;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
	.dialog-container {
		background: white;
		width: 100%;

		.titulo {
			font-size: 22px;
		}

		.layout {

			i {
				font-size: 70px;
			}

			.flex-left,
			.flex-right {
				height: 100%;
			}

			.flex-left {
				.layout {
					height: 100%;

					.flex-1 {
						height: 88%;
					}

					.grow {
						flex-basis: 70%;
					}
				}

				.zoom-on-hover {
					height: 95%;
					display: flex;
					justify-content: center;
					align-items: center;

					::v-deep .normal {
						width: unset;
						max-width: 100%;
						max-height: 100%;
					}
				}

				.flex {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
				}

				.flex-text-card{
					display: flex;
					padding: 10px;
					flex-direction: column;
					justify-content: space-evenly;
				}

				@media screen and (max-width: 1280px) {
					height: calc(100vh - 100px);
					overflow-y: scroll;
					scrollbar-width: none;
					-ms-overflow-style: none;
				}
			}

			.flex-right {
				overflow-y: auto;
				height: calc(100vh - 100px);
			}
		}

		.scroll-style::-webkit-scrollbar-track {
			border-radius: 5px;
			background-color: #F5F5F5;
		}

		.scroll-style::-webkit-scrollbar {
			width: 3px;
			background-color: #F5F5F5;
		}

		.scroll-style::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background-color: #555;
		}
	}
</style>
