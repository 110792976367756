<template>
	<Dialog titulo="Nova pendência" @close="close" btn_acao="Enviar" btn_cancelar="Cancelar" @acao="enviar"
		@cancelar="close" :btn_acao_disable="!this.categoria">
		<v-container class="pa-4">
			<v-layout wrap>
				<v-flex xs12>
					<v-select
						:hide-details="true"
						:items="dropdown_pendencia"
						label="Categoria *"
						outline
						v-model="categoria" />
				</v-flex>
				<v-flex xs12>
					<v-textarea
						box
						:hide-details="true"
						outline
						class="mt-3"
						label="Escreva a pendência aqui"
						v-model="descricao"
						counter="255"
						:rules="rules" />
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>

export default {
	name: 'NovaPendencia',
	data: () => ({
		categoria: '',
		descricao: '',
		enviando: true,
		dropdown_pendencia: [
			'Link Hubspot', 'Falta de medicamento', 'Divergência de dose', 'Divergência de medicamento', 'Divergência de posologia', 'Divergência de horário', 'Falta Receita', 'Receita a mais', 'Receita vencida', 'Aproveitar Receita', 'Receita com validade próxima', 'Receita sem carimbo e data', 'Outros'
		],
		rules: [v => v.length <= 255 || 'A descrição deve ter no máximo 255 caracteres']
	}),
	methods: {
		close() {
			this.$emit('close');
		},
		enviar() {
			this.$emit('acao', {
				categoria: this.categoria,
				descricao: this.descricao.trim()
			});
			this.close();
		}
	}
};
</script>

<style lang="scss" scoped>
