export const companyUnits = {
	ANY: 'Todas',
	BH: 'Belo Horizonte',
	SP: 'São Paulo'
};

export const companyUnitIds = Object.freeze({
	BH: 1,
	SP: 2
});

export const companyUnitAbbr = Object.freeze({
	1: 'BH',
	2: 'SP'
});

export function getCompanyUnitNameById(branchId) {
	const abbr = companyUnitAbbr[branchId];
	return companyUnits[abbr];
}

export const getAllCompanyUnits = () => Object.keys(companyUnits)
	.map(key => (
		{
			id: companyUnitIds[key],
			abbr: key,
			name: companyUnits[key]
		}
	)).filter(item => item.id);
