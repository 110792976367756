<template>
	<v-card :class="`${isSelecionado && 'selecionado'} endereco`">
		<v-card-text  @click="$emit('selecionado')">
			<v-switch
				v-model="switchValue"
				label="Usar esse endereço"
				color="success"/>
			<h3 v-if="!item.assinatura_id">
				Endereço ILPI
			</h3>
			<p class="mb-0">{{ item.logradouro }}</p>
			<h4>{{ item.numero }}</h4>
			<p class="mb-0">{{ item.complemento }}</p>
			<p class="mb-0">Bairro {{ item.bairro }}</p>
			<p class="mb-0">{{ item.cidade }}, {{ item.estado }}</p>
			<p class="mb-0">{{ item.cep }}</p>
			<p v-if="item.observacao" class="mb-0">Observações: {{ item.observacao }}</p>
		</v-card-text>

		<div class="actions" v-if="item.assinatura_id">
			<v-tooltip left>
				<v-btn small icon class="purple white--text ml-0" slot="activator" @click="editarEndereco">
					<v-icon small>edit</v-icon>
				</v-btn>
				<span>
					Editar endereço
				</span>
			</v-tooltip>

			<div v-if="!isSelecionado">
				<v-tooltip left>
					<v-btn small icon class="purple white--text ml-0" slot="activator" @click="dialog_remover_endereco = true">
						<v-icon small>delete</v-icon>
					</v-btn>
					<span>
						Remover endereço
					</span>
				</v-tooltip>
			</div>
			<v-dialog persistent scrollable v-model="dialog_remover_endereco" transition="fade" max-width="340px">
				<transition>
					<Dialog v-if="dialog_remover_endereco" titulo="Remover endereço"
						btn_acao="Remover" btn_acao_class="error" btn_cancelar="Cancelar" @acao="deleteItem"
						@close="dialog_remover_endereco = false">
						<p>Deseja excluir o endereço:</p>
						<h3>{{ item.logradouro }}</h3>
						<h4>{{ item.numero }}</h4>
						<p class="mb-0">{{ item.complemento }}</p>
						<p class="mb-0">Bairro {{ item.bairro }}</p>
						<p class="mb-0">{{ item.cidade }}, {{ item.estado }}</p>
						<p class="mb-0">{{ item.cep }}</p>
						<p v-if="item.observacao" class="mb-0">Observações: {{ item.observacao }}</p>
					</Dialog>
				</transition>
			</v-dialog>
		</div>
	</v-card>

</template>

<script>
export default {
	name: 'item-endereco',
	props: {
		item: {
			type: Object,
			required: true
		},
		isSelecionado: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		dialog_remover_endereco: false,
		dialog_editar_endereco: false,
		switchValue: false
	}),
	created() {
		this.switchValue = this.isSelecionado;
	},
	watch: {
		isSelecionado(value) {
			this.switchValue = value;
		}
	},
	methods: {
		deleteItem() {
			this.dialog_remover_endereco = false;
			this.$emit('delete');
		},
		editarEndereco() {
			this.$emit('editarEndereco');
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
.endereco {
	.actions {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 10px 0 0;

		@media screen and (min-width: $break-point-sm) {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 10px;
			margin: 0;
			flex-direction: column;
			justify-content: center;
		}

		@media screen and (min-width: $break-point-md) {
			transform: translateX(20px);
			opacity: 0;
			transition: all 0.3s;
		}
	}

	&:hover {
		.actions {
			transform: translateX(0px);
			opacity: 1;
		}
	}
}
</style>
