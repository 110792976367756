<template>
	<Dialog
		titulo="Cadastrar marca"
		@acao="save()"
		btn_acao="Confirmar"
		@close="$emit('close')"
		background="rgba(255, 234, 204, 1)"
		backgroundFooter="rgba(255, 234, 204, 1)">
		<v-container>
			<v-layout wrap>
				<v-flex xs12>
					<v-text-field
						v-model="brand.name"
						background-color="white"
						box
						label="Nome da marca"
						v-validate="{ required: true }"
						data-vv-name="name"
						:error-messages="errors.collect('name')"/>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { BrandPresenter } from '@Presenter/brand-presenter';

export default {
	name: 'DialogCreateBrand',
	$_veeValidate: { validator: 'new' },
	data: () => ({
		loading: true,
		brand: {}
	}),
	methods: {
		async save() {
			const formIsValidated = await this.$validator.validateAll();

			if (!formIsValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário inválido',
					color: 'error'
				});
				return;
			}

			try {
				await BrandPresenter.create({
					name: this.brand.name
				});

				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Marca cadastrada com sucesso',
					color: 'success'
				});

				this.$emit('close');
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao criar marca',
					color: 'error'
				});
				throw new Error(error);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
