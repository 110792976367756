<template>
	<Dialog titulo="Confirmar criação de fatura" @close="closeModal()"
		btn_acao="Confirmar fatura" @acao="$emit('createInvoice')"
		:carregando="loading">
		<div class="d-flex q-column items-center justify-center">
			<h2>Confirmo a criação da fatura para o responsável abaixo:</h2>
			<ResponsavelDetalhes :responsavel="responsible"/>
		</div>
	</Dialog>
</template>

<script>
import ResponsavelDetalhes from '@Componentes/dialog-iugu/responsavel-detalhes.vue';

export default {
	name: 'DialogConfirmInvoice',
	components: { ResponsavelDetalhes },
	props: {
		responsible: {
			type: Object,
			required: true
		},
		loading: {
			type: Boolean
		}
	},
	methods: {
		closeModal() {
			this.$emit('update:close', false);
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
