import $http from '@Config/axios';

const prefix = 'nf';

export const NfPresenter = {
	index: caminho => $http.post(`${prefix}/listar`, { caminho }),
	email: (email, paciente, tipo, items) => $http.post(`${prefix}/email`, {
		email, paciente, tipo, items
	})
};
