<template>
	<v-container class="pt-0">
		<v-flex class="q-row space-between">
			<span class="mt-3" style="font-size: 22px">Rota {{order.id}}</span>
			<LogisticsStatusChip :status="order.status"/>
		</v-flex>
		<v-divider class="mt-4 mb-4"></v-divider>

		<span class="mt-3 text--text"  style="font-size: 18px"><strong>Itens</strong></span>
		<container class="cargo-container mt-4 mb-4" v-for="cargo in order.cargos" :key="cargo.id">
			<v-expansion-panels >
				<v-expansion-panel :value="0" >
					<v-expansion-panel-content>
						<template v-slot:header>
							<v-flex grow><p class="text--text mt-3 ml-2" style="font-size: 16px"><strong>Cargo {{cargo.index}}</strong> (ID {{ cargo.external_id }})</p></v-flex>
							<p class="text--text mt-3 ml-5" style="font-size: 16px">{{`${cargo.address.street}, ${cargo.address.number}, ${cargo.address.complement || ''}. ${cargo.address.city} - ${cargo.address.state}. CEP: ${cargo.address.zip_code}`}}</p>
						</template>
						<div class="volume-container mt-2 mb-2">
							<v-divider class="cargo-divider"></v-divider>
							<ul>
								<li v-for="volume in cargo.volumes" :key="volume.id" class="mt-3">
									<v-flex class="q-row">
										<p class="text--text mt-2 ml-3" style="font-size: 16px">Volume - {{getRefereceTypeDescription(volume.reference_type)}} - {{volume.reference_id}}</p>
										<LogisticsStatusChip :status="volume.status"/>
									</v-flex>
									<v-flex class="q-row">
										<p class="text--text mt-2 ml-3" style="font-size: 16px"><b>Código de rastreio:</b> {{volume.tracking_code}}</p>
									</v-flex>
									<v-flex class="q-row">
										<p class="text--text mt-2 ml-3" style="font-size: 16px"><b>Cliente:</b> {{volume.recipient.name}}</p>
										<p class="text--text mt-2 ml-3" style="font-size: 16px"><b>Contato:</b> {{formatPhone(volume.recipient.phone)}}</p>
									</v-flex>
								</li>
							</ul>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</container>
		<span class="mt-3 text--text"  style="font-size: 18px"><strong>Fornecedor</strong></span>
		<div class="outlined-radio mb-2 mt-3">
			<v-checkbox
				class="pb-3  px-3"
				v-model="isChecked"
				color="success"
				disabled
				hide-details>
				<template v-slot:label>
					<h4 class="text-uppercase">{{order.fleet}}
						<template v-if="modality">
							({{ modality }})
						</template>
					</h4>
					<span class="ml-auto" v-if="quotationInfo">
						<strong>R$ {{quotationInfo.quotation}}</strong>
					</span>
				</template>
			</v-checkbox>
		</div>

	</v-container>
</template>

<script>

import { getLogisticReferenceTypeDescription } from '@Consts/reference-type-logistic';
import { formatCellphone } from 'luiz-fns';
import LogisticsStatusChip from './order-status-chip.vue';

export default {
	name: 'LogisticsOrderDetails',
	components: { LogisticsStatusChip },
	props: {
		order: {
			type: Object,
			required: true
		},
		modality: {
			required: false,
			default: null
		},
		quotationInfo: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		quotation: 'lalamove',
		isChecked: true,
		panelOpen: [true, true, true]
	}),
	created() {},
	methods: {
		getRefereceTypeDescription(type) {
			return getLogisticReferenceTypeDescription(type);
		},

		formatPhone(phoneNumber) {
			return formatCellphone(phoneNumber.slice(3));
		}
	}
};

</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.outlined-radio {
	border: 2px solid #2DCE71;
	border-radius: 4px;
  }

::v-deep .v-input--selection-controls .v-input__control,
::v-deep .v-input--selection-controls.v-input .v-label{
	width: 100%;
}

.cargo-container ::v-deep .v-expansion-panel .v-expansion-panel__container{
	background: rgba(255, 200, 117, .2);
	border-radius: 8px;
}

.cargo-container ::v-deep  .v-expansion-panel {
	box-shadow: none;
	padding: 10px 0;
}

.volume-container{
	padding: 0px 30px;
}
.cargo-divider{
	border-color: $primary!important;
	border-width: 2px;
}

</style>
