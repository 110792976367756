import Vue from 'vue';
import store from '..';
import { getState } from './state';

export const CLEAR_STATE = state => {
	Object.assign(state, getState());
};

export const SET_MEDICAMENTO = (state, params) => {
	if (params) {
		const { label, ean, medicamento } = params;
		state.medicamento = medicamento;
		state.medicamentos_lidos[ean] = medicamento;
		if (state.medicamentos[medicamento.id]) {
			state.medicamentos[medicamento.id].quantidade_inserida += 1;
			if (!state.medicamentos[medicamento.id].etiquetas)
				state.medicamentos[medicamento.id].etiquetas = [];

			state.medicamentos[medicamento.id].etiquetas.push(label);
		} else
			store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Medicamento não encontrado na assinatura ou pausado. Por favor, verifique se a caixa está correta.' });
	}
};

export const SET_MEDICAMENTOS = (state, medicamentos) => {
	state.medicamentos = medicamentos.reduce((obj, medicamento) => {
		const item = obj[medicamento.id];
		if (item)
			item.caixas += medicamento.caixas;
		else
			obj[medicamento.id] = medicamento;

		Vue.set(obj[medicamento.id], 'quantidade_inserida', 0);
		return obj;
	}, {});
};

export const SET_LOADING_MEDICAMENTO = (state, bool) => {
	state.loading_medicamento = bool;
};

export const SET_LOADING_MEDICAMENTOS = (state, bool) => {
	state.loading_medicamentos = bool;
};
