<template>
	<div class="px-md-5">

		<HeaderProcesso :titulo="`${tabSelected === 'boxesTab'?'Prontas':'Prontos'} para envio`"
			subtitulo="Insira o código de rastreio, faça o controle de envio das boxes e confirme se uma box foi entregue ou está atrasada"

			:btn_label_extra="tabSelected === 'boxesTab'?'Informar atraso para seleção':''"
			:btn_disabled_extra="selecionados.length === 0"
			:btn_on_click_extra="() => { dialogAtrasadaLote = true }"

			@busca="val => busca = val"

			:btn_label="`Confirmar entrega ${tabSelected === 'boxesTab'?'das box selecionadas':'dos pedidos selecionados'} `"
			:btn_disabled="selectedOrders.length === 0 && selecionados.length === 0"
			:btn_on_click="() => {dialogEntregueLote = true  }">

		</HeaderProcesso>

		<TabsDataTable :boxesLength="boxes.length" :ordersLength="ordersProcessTotal" :tabSelected="tabSelected"
			@switchTab="val => switchTab(val)" />

		<!-- Conteúdo -->
		<DataTableProcesso
			v-if="tabSelected === 'boxesTab'"
			selectable
			:boxes="boxes"
			:loading="carregando"
			:search="busca"
			:buttons="buttons"
			:extra_headers="[{ text: 'Região', value: 'demand_region', sortable: true }, { text: 'Código de rastreio', value: 'codigo_rastreio', sortable: false }]"
			@selected-updated="arr => selecionados = arr" />

		<DataTablePedidos
			v-if="tabSelected === 'ordersTab'"
			selectable
			:orders="orders"
			:loading="loadingOrders"
			:buttons="orderButtons"
			:total-items="ordersProcessTotal"
			:loader-specific-rows="loaderSpecificRows"
			@pagination-updated="val => getOrdersProcess(val)"
			@selected-updated="arr => selectedOrders = arr"/>

		<v-dialog persistent scrollable v-model="dialogAtrasada" transition="fade" max-width="500px">
			<transition>
				<DialogConfirmacao v-if="dialogAtrasada" @close="dialogAtrasada = false"
					tituloOpcional="Confirmação atrasada" :request="updateStatus('DELAYED')" :opcoes="[
						'Confirmo que a box está atrasada'
					]" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialogEntregue" transition="fade" max-width="500px">
			<transition>
				<DialogConfirmacao v-if="dialogEntregue" @close="dialogEntregue = false"
					tituloOpcional="Confirmação entregue"
					:request="() =>setBoxEntregue()" :opcoes="[
						`Confirmo que a box foi entregue corretamente`
					]" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialogAtrasadaLote" transition="fade" max-width="500px">
			<transition>
				<DialogConfirmacao
					v-if="dialogAtrasadaLote" @close="dialogAtrasadaLote = false"
					tituloOpcional="Confirmação atrasadas"
					:request="setBoxesAtrasadas()" :opcoes="[
						'Confirmo que as boxes estão atrasadas'
					]"/>
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialogLalamove" transition="fade" max-width="500px">
			<transition>
				<DialogConfirmacao
					v-if="dialogLalamove" @close="dialogLalamove = false"
					tituloOpcional="Confirmação Lalamove"
					:request="() =>setLalamove()" :opcoes="[
						`Confirmo o envio das boxes`
					]" />n>
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialogCodigoRastreioOrder" transition="fade" max-width="500px">
			<transition>
				<DialogCodigoRastreioOrder v-if="dialogCodigoRastreioOrder" :customer_name="item_selecionado.customer.name" :order_id="item_selecionado.id" :close.sync="dialogCodigoRastreioOrder" @changeOrderMetadata="metadata => orderMetadata = metadata"
					:request="changeStatusProcess()" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialogCodigoRastreio" transition="fade" max-width="500px">
			<transition>
				<DialogCodigoRastreio v-if="dialogCodigoRastreio" :box_id="item_selecionado.id" :close.sync="dialogCodigoRastreio" @salvarCodigoRastreio="codigoRastreio => salvarCodigoRastreio(codigoRastreio)" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialogEntregueLote" transition="fade" max-width="500px">
			<transition>
				<DialogEntregueEmLote v-if="dialogEntregueLote" :close.sync="dialogEntregueLote"
					@entregar="codigoRastreio => tabSelected === 'boxesTab' ? setBoxesEntregues(codigoRastreio): setOrdersDelivered(codigoRastreio)" />
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { ProcessoMixin } from '@Mixins/processo';
import { OrderProcessMixin } from '@Mixins/order-process';
import { LogisticaPresenter } from '@Presenter/logistica-presenter';
import { BoxPresenter } from '@Presenter/box-presenter';
import TabsDataTable from '@Componentes/tabs-data-table.vue';
import { ProcessTypes } from '@Consts/status-order-process';
import { OrderPresenter } from '@Presenter/order-presenter';
import DialogCodigoRastreio from './dialog-codigo-rastreio';
import DialogCodigoRastreioOrder from './dialog-codigo-rastreio-order';
import DialogEntregueEmLote from './dialog-entregue-em-lote.vue';

export default {
	name: 'ProntasParaEnvio',
	mixins: [ProcessoMixin, OrderProcessMixin],
	components: {
		DialogCodigoRastreio,
		DialogCodigoRastreioOrder,
		DialogEntregueEmLote,
		TabsDataTable
	},
	data: () => ({
		statusToShow: ['DESPATCHED', 'SENT', 'DELAYED'],
		demandRegion: true,
		processName: ProcessTypes.SHIPPING,
		dialogAtrasada: false,
		dialogEntregue: false,
		dialogAtrasadaLote: false,
		dialogEntregueLote: false,
		dialogCodigoRastreio: false,
		dialogCodigoRastreioOrder: false,
		loaderSpecificRows: [],
		orderMetadata: {}
	}),
	created() {
		this.orderButtons = [
			{
				label: 'Entregue',
				icon: 'fas fa-check',
				onClick: item => this.abrirDialog(item, 'dialogCodigoRastreioOrder')
			}
		];

		this.buttons = [
			{
				label: 'Atrasada',
				icon: 'fas fa-exclamation',
				onClick: item => this.abrirDialog(item, 'dialogAtrasada'),
				condition: item => item.status !== 'DELAYED'
			},
			{
				label: 'Atrasada',
				icon: 'fas fa-exclamation',
				color: 'red',
				condition: item => item.status === 'DELAYED'
			},
			{
				label: 'Inserir código de rastreio',
				icon: 'fas fa-barcode',
				onClick: item => this.abrirDialog(item, 'dialogCodigoRastreio')
			},
			{
				label: 'Entregue',
				icon: 'fas fa-check',
				onClick: item => this.abrirDialog(item, 'dialogEntregue')
			}
		];
	},

	computed: {
		idsSelecionados() {
			return this.selecionados.map(item => item.id);
		}
	},

	methods: {
		salvarCodigoRastreio(codigoRastreio) {
			BoxPresenter.update({
				id: this.item_selecionado.id,
				codigo_rastreio: codigoRastreio
			}).catch(() => {
				this.$store.dispatch('SHOW_SNACKBAR', { message: `Erro ao salvar código de rastreio ${codigoRastreio}`, color: 'error' });
			})
				.then(() => {
					this.afterSave();
				});
		},

		setBoxesAtrasadas() {
			const storeData = {
				box_ids: this.idsSelecionados
			};
			return () => new Promise((resolve, reject) => {
				LogisticaPresenter.atrasarBoxes(storeData)
					.then(() => {
						this.afterSave(resolve);
					})
					.catch(() => {
						reject();
					});
			});
		},

		async setBoxEntregue() {
			const boxId = this.item_selecionado.id;

			const { status, items } = await LogisticaPresenter.entregarBoxes({ box_ids: [boxId] });

			if (status === 'SUCCESS')
				return this.afterSave();

			return this.$store.dispatch('SHOW_SNACKBAR', {
				color: 'error',
				message: `Erro na box ${items[0].item}: ${items[0].error.message}`
			});
		},

		async setBoxesEntregues(codigoRastreio) {
			const storeData = { box_ids: this.idsSelecionados, codigo_rastreio: codigoRastreio };
			const results = await LogisticaPresenter.entregarBoxes(storeData);

			if (results.status === 'SUCCESS')
				return this.afterSave();

			const errorResults = results.items.filter(r => r.error).map(
				result => `Erro na box ${result.item}: ${result.error.message}`
			);

			this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: errorResults.join('\n') });
			this.getData();
		},

		async setOrdersDelivered(codigoRastreio) {
			this.$store.dispatch('SHOW_SNACKBAR', { message: 'Não feche esta tela até que a execução esteja completa.', color: 'primary' });

			this.loaderSpecificRows = this.selectedOrders.map(order => order.id);

			const promises = [];

			this.selectedOrders.forEach(order => {
				promises.push(
					OrderPresenter.updateOrderProcess(
						order.id, ProcessTypes.SHIPPING, { status: 'SUCCESS', metadata: { trackinCode: codigoRastreio } }
					)
						.then(() => {
							this.loaderSpecificRows = this.loaderSpecificRows.filter(item => item !== order.id);
							this.orders = this.orders.filter(item => item !== order.id);
						}).catch(() => {
							this.$store.dispatch('SHOW_SNACKBAR', { message: `Erro no envio do pedido ${order.id}.`, color: 'error' });
						})
				);
			});

			await Promise.all(promises)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Pedidos enviados com sucesso.', color: 'success' });
				})
				.finally(() => {
					this.getOrdersProcess();
				});
		},

		changeStatusProcess() {
			return this.updateOrderProcess(ProcessTypes.SHIPPING, 'SUCCESS', this.orderMetadata);
		}
	}
};
</script>
