<template>
	<section>
		<h3 class="my-3">Medidas</h3>
		<section>
			<h4 class="my-3">Recipiente</h4>
			<v-layout wrap>
				<v-flex md6 xs12>
					<v-text-field
						type="number"
						v-model="form.quantityForSale"
						background-color="white"
						box
						label="Quantidade"
						v-validate="{ required: true }"
						data-vv-name="Quantidade"
						:error-messages="errors.collect('Quantidade')"/>
				</v-flex>
				<v-flex md6 xs12>
					<v-autocomplete
						v-model="form.unitOfMeasurementForSaleSymbol"
						background-color="white"
						:items="unitsOfMeasurementItems"
						label="Unidade de medida"
						box
						clearable
						v-validate="{ required: true }"
						:error-messages="errors.collect('Unidade de medida')"
						@click:append-outer="openCreateUnitOfMeasurementDialog = true"
						append-outer-icon="add"
						data-vv-name="Unidade de medida"
						item-value="symbol"
						item-text="name"/>
				</v-flex>
			</v-layout>
		</section>

		<section>
			<h4 class="my-3">Conteúdo</h4>
			<v-layout wrap>
				<v-flex md6 xs12>
					<v-text-field
						type="number"
						v-model="form.quantity"
						background-color="white"
						box
						label="Quantidade"
						v-validate="{ required: true }"
						data-vv-name="Quantidade"
						:error-messages="errors.collect('Quantidade')"/>
				</v-flex>
				<v-flex md6 xs12>
					<v-autocomplete
						v-model="form.unitOfMeasurementSymbol"
						background-color="white"
						:items="unitsOfMeasurementItems"
						label="Unidade de medida"
						box
						clearable
						v-validate="{ required: true }"
						:error-messages="errors.collect('Unidade de medida')"
						@click:append-outer="openCreateUnitOfMeasurementDialog = true"
						append-outer-icon="add"
						data-vv-name="Unidade de medida"
						item-value="symbol"
						item-text="name"/>
				</v-flex>
			</v-layout>
		</section>
		<p class="my-3">Total de <b>{{getLabel("quantityForSale", "unitOfMeasurementForSaleSymbol", "caixa")}}</b> com <b>{{getLabel("quantity", "unitOfMeasurementSymbol", "comprimidos")}}</b></p>

		<v-dialog
			v-model="openCreateUnitOfMeasurementDialog"
			persistent
			scrollable
			transition="fade"
			max-width="840px">
			<transition>
				<DialogCreateUnitOfMeasurement
					v-if="openCreateUnitOfMeasurementDialog"
					@close="closeCreateUnitOfMeasurementDialog"/>
			</transition>
		</v-dialog>
	</section>
</template>

<script>
import DialogCreateUnitOfMeasurement from '../../../dialog-new-unit-of-measurement.vue';

export default {
	name: 'NewProductStepItemMeasurement',
	inject: ['$validator'],
	components: {
		DialogCreateUnitOfMeasurement
	},
	props: {
		form: {
			required: true,
			type: Object
		},
		unitsOfMeasurement: {
			required: true,
			type: Array
		}
	},
	data() {
		return {
			openCreateUnitOfMeasurementDialog: false
		};
	},
	computed: {
		unitsOfMeasurementItems() {
			return this.unitsOfMeasurement.map(item => ({
				symbol: item.symbol,
				name: `[${item.symbol}] - ${item.name}`
			}));
		}
	},
	methods: {
		getLabel(quantityProperty, unitOfMeasurementProperty, defaultMeasurement) {
			const unitOfMeasurementName = this.unitsOfMeasurement.find(u => u.symbol === this.form[unitOfMeasurementProperty]);
			return `${this.form[quantityProperty] || 0} ${unitOfMeasurementName?.name || defaultMeasurement}`;
		},
		async closeCreateUnitOfMeasurementDialog() {
			this.openCreateUnitOfMeasurementDialog = false;
			this.$emit('getUnitsOfMeasure');
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
