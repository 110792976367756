<template>
	<div>
		<v-data-table :headers="headers" :items="items" no-data-text="Nenhuma pendência" disable-initial-sort :loading="abrirHistoricoPendencia">
			<template v-slot:items="props">
				<tr class="pointer" @click="abrirHistorico(props.item)">
					<td class="text-xs-center">
						{{props.item.categoria}}
					</td>
					<td class="text-xs-center px-1">
						{{ props.item.openedBy.nome }}
					</td>
					<td class="text-xs-center">
						{{ props.item.created_at | formatDate('DD/MM/YYYY') }}
					</td>
					<td class="text-xs-center">
						{{ props.item.concluded_at | formatDate('DD/MM/YYYY') }}
					</td>
					<td v-if="props.item.status === 'CONCLUDED'" class="text-xs-center">
						<v-icon color="#13CE66" class="mr-2">fas fa-check-circle</v-icon>
					</td>
					<td v-else class="text-xs-center" >
						<v-icon color="#F7BA2A" class="mr-2">fas fa-exclamation-circle</v-icon>
					</td>
				</tr>
			</template>
		</v-data-table>

		<v-dialog persistent scrollable v-model="dialog_historico_pendencia" transition="fade" max-width="600px">
			<transition>
				<HistoricoPendencia v-if="dialog_historico_pendencia" :pendencia="historicoPendencia" @close="dialog_historico_pendencia = false" />
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { PendenciaPresenter } from '@Presenter/pendencia-presenter';
import HistoricoPendencia from '@Componentes/dialog-pendencias/historico-pendencia.vue';

export default {
	name: 'historico-pendencias',
	components: {
		HistoricoPendencia
	},
	props: {
		box_id: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		pendencias: [],
		historicoPendencia: {},
		carregaPendencias: false,
		abrirHistoricoPendencia: false,
		dialog_historico_pendencia: false,
		headers: [
			{ text: 'Categoria', value: 'categoria', align: 'center' },
			{ text: 'Usuário', value: 'openedBy.nome', align: 'center' },
			{ text: 'Data Abertura', value: 'created_at', align: 'center' },
			{ text: 'Data Encerramento', value: 'concluded_at', align: 'center' },
			{ text: 'Status de conclusão', value: 'status', align: 'center' }
		]
	}),
	created() {
		this.getPendencias(this.box_id);
	},
	computed: {
		items() {
			return this.pendencias;
		}
	},
	methods: {
		getPendencias(box_id) {
			this.carregaPendencias = true;
			PendenciaPresenter.buscarPendencias(box_id)
				.then(pendencias => {
					this.pendencias = pendencias;
				})
				.catch(() => {
				}).finally(() => {
					this.carregaPendencias = false;
				});
		},
		abrirHistorico(pendencia) {
			this.abrirHistoricoPendencia = true;
			PendenciaPresenter.buscaPendencia(pendencia.id)
				.then(pendenciaEscolhida => {
					this.dialog_historico_pendencia = true;
					this.historicoPendencia = pendenciaEscolhida;
				}).catch(() => {
				}).finally(() => {
					this.abrirHistoricoPendencia = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
