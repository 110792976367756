import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = '/disponibilidades';

export const AvailabilityPresenter = {
	list: params => $http.get(buildQueryParams(prefix, {
		page: params.page,
		perPage: params.perPage,
		productIds: params.ids,
		companyUnitId: params.companyUnitId
		// sortBy: params.sortBy,
		// sortDirection: params.sortDirection
	})),
	create: params => {
		const availabilityRequestParams = {
			productId: params.productId,
			companyUnitId: `${params.companyUnitId}`,
			status: params.status
		};

		return $http.post(prefix, availabilityRequestParams);
	}
};
