<template>
	<div class="dialog-footer">
		<v-btn @click="$emit('close')" flat>Cancelar</v-btn>
		<div>
			<v-btn @click="$emit('submit')" color="primary">Salvar</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RestHomeDialogFooter'
};
</script>

<style lang="scss" scoped>
.dialog-footer {
	display: flex;
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	justify-content: space-between;
}
</style>
