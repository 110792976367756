<template>
	<v-card>
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>
					fas fa-receipt
				</v-icon>
			</v-avatar>
			<h3>Alergias</h3>
			<v-spacer />
			<v-btn small depressed class="green white--text" :disabled="loading" @click="dialog_alergias = true">
				<v-icon class="mr-2">fas fa-pen</v-icon>
				Editar
			</v-btn>
		</v-card-title>
		<v-card-text class="pt-0">
			<div v-if="loading" class="loading">
				<v-progress-circular indeterminate color="primary" />
			</div>
			<template v-else>
				<span v-if="alergias.length === 0">
					Nenhuma alergia informada.
				</span>
				<v-chip v-else v-for="alergia in alergias" :key="alergia.id" small class="purple white--text"
					disabled>
					{{ alergia.descricao }}
				</v-chip>
			</template>
		</v-card-text>

		<v-dialog v-model="dialog_alergias" persistent scrollable transition="fade" max-width="840px">
			<transition>
				<DialogAlergias v-if="dialog_alergias" :alergias="alergias"
					@novas-alergias="onNovasAlergias" @close="dialog_alergias = false" />
			</transition>
		</v-dialog>

	</v-card>
</template>

<script>
import { PacientePresenter } from '@Presenter/paciente-presenter';
import DialogAlergias from '@Componentes/dialog-alergias';

export default {
	name: 'Alergias',
	components: { DialogAlergias },
	props: {
		paciente: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		alergias: [],
		loading: true,
		dialog_alergias: false
	}),
	async created() {
		this.alergias = await PacientePresenter.alergias(this.paciente.id);
		this.loading = false;
	},
	methods: {
		onNovasAlergias(alergias) {
			this.alergias = alergias;
			PacientePresenter.update({ ...this.paciente, alergias }, false);
		}
	}
};
</script>

<style lang="scss" scoped>
.loading {
	min-height: 50px;
}
</style>
