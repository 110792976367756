<template>
	<Dialog titulo="Deseja salvar as alterações?"
		:btn_acao="habilitar_reset ? 'Salvar apenas a assinatura' : 'Salvar'" btn_cancelar="Sair sem salvar"
		:btn_acao_extra="habilitar_reset ? 'Salvar e resetar a box' : ''" btn_acao_extra_class="error"
		:loading="salvando" :loading_extra="resetando"
		@acao="salvar(false)" @acao_extra="save" @close="close">
		<v-container v-if="alterou_data_proxima_box || habilitar_reset" fluid grid-list-lg class="pa-0">
			<v-layout wrap>
				<v-flex v-if="habilitar_reset  && !isNewResetFeatureFlagEnable" xs12>
					<v-textarea v-model="justificativa_reset" box hide-details
						label="Motivo do reset da box"
						placeholder="Como houve alterações nos medicamentos da assinatura, você pode salvar a assinatura e resetar a box para reflitir as alterações. Caso for resetar, justifique aqui o motivo."/>
				</v-flex>
				<v-flex v-if="!lastBoxIsOpen" xs12>
					<v-textarea v-model="justificativa" box hide-details
						label="Motivo da alteração da data da próxima box"
						placeholder="Por que você alterou a data da próxima box?"/>
				</v-flex>
			</v-layout>
		</v-container>

		<ResetBoxDialog
			v-if="isNewResetFeatureFlagEnable && habilitar_reset"
			ref="resetBoxDialog"
			:visibleActivator="false"
			:boxId="lastBox.id"
			@resetBox="resetBox" />
	</Dialog>
</template>

<script>
import { compress } from 'lz-string';
import { BoxPresenter } from '@Presenter/box-presenter';
import { OcorrenciaPresenter } from '@Presenter/ocorrencia-presenter';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import moment from 'moment';
import { featureFlags } from '@Consts/feature-flags';
import ResetBoxDialog from '../../esqueleto/dialog-box/reset/index.vue';

export default {
	name: 'DialogSalvar',
	components: {
		ResetBoxDialog
	},
	props: ['alterou_data_proxima_box', 'assinatura', 'alterou_medicamento'],
	data: () => ({
		justificativa: '',
		justificativa_reset: '',
		salvando: false,
		resetando: false,
		assinatura_alterada: false,
		resetBoxInfo: null
	}),
	computed: {
		lastBox() {
			return this.$store.getters['assinatura/lastBox'];
		},
		lastBoxIsOpen() {
			return this.$store.getters['assinatura/lastBoxIsOpen'];
		},
		habilitar_reset() {
			return this.lastBoxIsOpen && this.alterou_medicamento;
		},
		isNewResetFeatureFlagEnable() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.new_reset_box);
		}
	},
	methods: {
		async save() {
			if (this.isNewResetFeatureFlagEnable) {
				if (!this.$refs.resetBoxDialog)
					return;
				await this.$refs.resetBoxDialog.getBoxResetInfo();
			} else
				await this.salvar(true);
		},
		async resetBox(data) {
			this.resetBoxInfo = data;
			await this.salvar(true);
		},
		async salvar(reset = false) {
			if (this.alterou_data_proxima_box && !this.justificativa)
				return this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Por favor, insira uma justificativa' });
			try {
				if (reset)
					this.resetando = true;
				else
					this.salvando = true;
				const assinatura = this.$lodash.cloneDeep(this.assinatura);
				delete assinatura.data_proxima_box;
				assinatura.receitas = this.assinatura.receitas.filter(rec => !rec.id).map(rec => ({
					receita_base64: compress(rec.url.dataBase64),
					tipo: rec.url.type,
					name: rec.url.name
				}));
				assinatura.valor_servico = Number(this.$functions.onlyNumber(assinatura.valor_servico));
				if (this.justificativa) {
					assinatura.data_proxima_box = this.$functions.FormatDateToRequest(this.assinatura.data_proxima_box);
					this.assinatura.observacao = (this.assinatura.observacao || '').concat(`\n\nINÍCIO DA PRÓXIMA BOX ALTERADA PARA ${this.assinatura.data_proxima_box}: ${this.justificativa}`);
					assinatura.observacao = this.assinatura.observacao;
				}
				await AssinaturaPresenter.update(assinatura);
				if (reset) {
					if (!this.isNewResetFeatureFlagEnable) {
						const { id, inicio } = this.lastBox;
						await Promise.all([
							OcorrenciaPresenter.store({
								box_id: id,
								status: 'RESET',
								justificativa: this.justificativa_reset
							}),
							BoxPresenter.reset({ id, inicio: moment(inicio, 'DD/MM/YYYY').format('YYYY-MM-DD') })
						]);
					} else {
						const resetData = this.resetBoxInfo;
						if (!resetData)
							return;
						this.$refs.resetBoxDialog.opened = false;
						await BoxPresenter.resetBox(
							resetData.boxId,
							{
								startDate: resetData.startDate,
								reason: resetData.reason,
								keepMedicineIds: resetData.keepMedicineIds
							}
						);
					}
				}
				this.$emit('assinatura-salva');
				this.justificativa = '';
			} catch (error) {
				this.salvando = false;
				this.resetando = false;
			}
			this.salvando = false;
			this.resetando = false;
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
