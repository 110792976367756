import $http from '@Config/axios';
import $store from '@Store';
import { FormatDateToRequest } from '@Util/functions';
import { cloneDeep } from 'lodash';

const prefix = 'movimentacao';

function sanitize(movimentacoes) {
	const movements = cloneDeep(movimentacoes);
	return movements.items.map(item => ({
		...item,
		validade: item.validade ? FormatDateToRequest(item.validade) : null
	})).filter(({ quantidade }) => quantidade > 0);
}

export const MovimentacaoPresenter = {
	storeEntrada(request) {
		return new Promise((resolve, reject) => {
			$http.post(`${prefix}/storeEntrada`, {
				items: sanitize({ items: request.items }),
				subscriptionId: request.subscriptionId
			})
				.then(response => {
					$store.dispatch('SHOW_SNACKBAR', { message: 'Estoque atualizado com sucesso!' });
					resolve(response);
				})
				.catch(response => reject(response));
		});
	},
	storeSaida(items) {
		return new Promise((resolve, reject) => {
			$http.post(`${prefix}/storeSaida`, { items: sanitize(items) })
				.then(response => {
					$store.dispatch('SHOW_SNACKBAR', { message: 'Saída cadastrada com sucesso!' });
					resolve(response);
				})
				.catch(response => reject(response));
		});
	}
};
