import $http from '@Config/axios';
import { DefaultPresenter } from './default-presenter';

const prefix = 'pendencia';
const entity = 'Pendência';

export const PendenciaPresenter = {
	...DefaultPresenter({ prefix, entity }),
	buscarPendencias: box_id => $http.get(`pendencias/boxes/${box_id}`),
	enviarPendencia: params => $http.post('/pendencias', params),
	concluirPendencia: pendencia_id => $http.post(`pendencias/${pendencia_id}/concluidas`),
	respostaPendencia: (pendencia_id, params) => $http.post(`/pendencias/${pendencia_id}/respostas`, params),
	concluirTodasPendencias: box_id => $http.post(`boxes/${box_id}/concluirPendencias`),
	editarResposta: (resposta_id, params) => $http.put(`pendencias/respostas/${resposta_id}`, params),
	buscaPendencia: pendencia_id => $http.get(`/pendencias/${pendencia_id}`),
	semPendencias: box_id => $http.post(`boxes/${box_id}/semPendencias`)
};
