<template>
	<header>
		<v-layout wrap class="mb-4" align-center justify-space-between>
			<v-flex xs12 md5>
				<h1 class="text--text mb-2" style="opacity: .8">{{ titulo }}</h1>
				<p v-if="subtitulo" class="mb-1">{{ subtitulo }}</p>
				<p v-if="subtitulo2">{{ subtitulo2 }}</p>
				<v-layout wrap>
					<v-flex md6 xs3 v-if="filtro_dias">
						<v-text-field v-model="dias" append-icon="date_range" color="green" label="Filtro em dias"
							@input="dias => $emit('input', dias)" class="mt-3" type="number" min="1"
							hide-details />
					</v-flex>
					<v-flex md12 xs6>
						<v-text-field v-model="busca" :label="busca_label" class="mt-3" :placeholder="isSignatures ? 'ID ou nome do paciente' : 'ID do pedido, ID da assinatura ou nome do cliente'"
							append-icon="search" color="green"
							@input="value => $emit('busca', value)" />
					</v-flex>
					<v-flex md6 xs6 v-if="show_filter_status_box">
						<v-flex md4 style="max-width: 200px">
							<v-select append-icon="filter_list" v-model="filtro" label="Filtros" :items="filtros" @input="onInputFiltro"/>
						</v-flex>
					</v-flex>
				</v-layout>

				<CampoSelecaoMultipla v-if="show_casa_filter"
					:items="casas_repouso"
					:itemText="i => `${i.id} - ${i.nome}`"
					itemValue="id"
					label="Casas de repouso"
					prependInnerIcon="home"
					@items-updated="onCasasRepousoUpdate"/>

			</v-flex>
			<v-flex xs12 md6>
				<v-flex v-if="btn_label" md12 xs12 class="text-xs-right pb-0">
					<v-btn depressed class="primary" @click="btn_on_click" :disabled="btn_disabled"
						:loading="btn_loading">
						<v-icon v-if="btn_icon" class="mr-2" style="font-size: 12px">{{ btn_icon }}</v-icon>
						{{ btn_label }}
					</v-btn>
					<slot />
				</v-flex>

				<v-flex v-if="btn_label_extra" md12 xs12 class="text-xs-right pt-0">
					<v-btn depressed class="primary" @click="btn_on_click_extra" :loading="btn_loading_extra" :disabled="btn_disabled_extra">
						<v-icon v-if="btn_icon_extra" class="mr-2" style="font-size: 12px">{{ btn_icon_extra }}</v-icon>
						{{ btn_label_extra }}
					</v-btn>
					<slot />
				</v-flex>
			</v-flex>

			<v-flex xs12 v-if="exibirFiltroSuspensos">
				<v-checkbox v-model="mostrarSuspensos" label="Exibir Suspensos" @change="value => $emit('filtrarPorSuspensos', value)" />
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
import CampoSelecaoMultipla from '@Componentes/campo-selecao-multipla';
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';

export default {
	name: 'HeaderProcesso',
	components: {
		CampoSelecaoMultipla
	},
	props: {
		value: { // prop value is the parent v-model
			type: null,
			default: null
		},
		titulo: {
			type: String,
			required: true
		},
		subtitulo: {
			type: String,
			default: ''
		},
		subtitulo2: {
			type: String,
			default: ''
		},
		btn_label_lalamove: {
			type: String,
			default: ''
		},
		btn_label: {
			type: String,
			default: ''
		},
		btn_label_extra: {
			type: String,
			default: ''
		},
		btn_icon: {
			type: String,
			default: ''
		},
		btn_icon_lalamove: {
			type: String,
			default: ''
		},
		btn_icon_extra: {
			type: String,
			default: ''
		},
		btn_visible: {
			type: Boolean,
			default: true
		},
		btn_loading: {
			type: Boolean,
			default: false
		},
		btn_loading_lalamove: {
			type: Boolean,
			default: false
		},
		btn_loading_extra: {
			type: Boolean,
			default: false
		},
		btn_disabled_lalamove: {
			type: Boolean,
			default: true
		},
		btn_disabled: {
			type: Boolean,
			default: true
		},
		btn_disabled_extra: {
			type: Boolean,
			default: true
		},
		btn_on_click_lalamove: {
			type: Function,
			default: () => ({})
		},
		btn_on_click: {
			type: Function,
			default: () => ({})
		},
		btn_on_click_extra: {
			type: Function,
			default: () => ({})
		},
		filtro_dias: {
			type: Boolean,
			default: false
		},
		busca_label: {
			type: String,
			default: 'Busca'
		},
		show_casa_filter: {
			type: Boolean,
			default: false
		},
		exibirFiltroSuspensos: {
			type: Boolean,
			required: false,
			default: false
		},
		filtrarPorSuspensos: {
			type: Boolean,
			required: false,
			default: false
		},
		show_filter_status_box: {
			type: Boolean,
			required: false,
			default: false
		},
		isSignatures: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	created() {
		this.onInputFiltro(this.filtro);
		CasaRepousoPresenter.index({ campos: ['id', 'nome'] })
			.then(response => {
				this.casas_repouso = response;
			});
	},
	data() {
		return {
			dias: this.value,
			busca: '',
			casas_repouso: [],
			mostrarSuspensos: this.filtrarPorSuspensos,
			filtro: 'ativas',
			filtros: [
				{ value: null, text: 'Todas' },
				{ value: 'ativas', text: 'Ativas' },
				{ value: 'suspensas', text: 'Suspensas' }
			]
		};
	},
	watch: {
		value(newVal) {
			this.dias = newVal;
		}
	},
	methods: {
		onCasasRepousoUpdate(casasRepouso) {
			this.$emit('casas-repouso-updated', casasRepouso);
		},
		onInputFiltro(value) {
			this.$emit('filtro-status-box', value);
		}
	}
};
</script>
