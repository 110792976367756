export default {
	methods: {
		irParaColaborador() {
			this.$store.dispatch('SET_VISAO_COLABORADOR', true);
			this.$router.push({ name: 'Dashboard' });
		},
		irParaOrcamentos() {
			this.$store.dispatch('SET_VISAO_COLABORADOR', true);
			this.$router.push({ name: 'Orcamentos' });
		},
		sair() {
			this.$router.push({ name: 'Entrar', params: { stop: true } });
			this.$functions.Logout();
		}
	}
};
