<template>
	<Dialog
		backgroundHeader="white"
		@close="close()">
		<span class="text-uppercase ml-2">Informações adicionais</span>
		<v-divider class="my-3"></v-divider>
		<v-layout class="align-baseline q-column">
			<span class="mt-2"> <strong>{{ getLogisticReferenceTypeDescription(item.type) }} ID:</strong> {{ item.id }} </span>
			<span class="mt-3">
				<strong>Endereço:</strong> <br>
				{{ getAddressReadable(item.logradouro, item.numero, item.bairro, item.complemento) }}.

				{{`${item.cep} - ${item.cidade}. ${
					item.demand_region || ""
				}`}}
			</span>
			<strong class="my-3">Instruções de entrega:</strong>
			<span style="white-space: pre-wrap;" v-text="item.instrucoes_entrega || '-'"></span>
		</v-layout>
	</Dialog>
</template>

<script>

import { getLogisticReferenceTypeDescription } from '@Consts/reference-type-logistic';

export default {
	name: 'DialogLogisticsItemDetail',
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		getLogisticReferenceTypeDescription
	}),
	methods: {
		close() {
			this.$emit('update:close', false);
		},

		getAddressReadable(...lines) {
			return lines.filter(line => !!line).join(', ');
		}

	}
};
</script>
