<template>
	<v-flex xs12 md12 class="tabs">
		<v-btn
			v-for="tab in tabs"
			:key="tab.id"
			:disabled="tab.id === tabDisabled"
			depressed
			large
			class="styleButtons mr-2"
			@click="$emit('goto', tab.id)"
			:class="
				tabSelected === tab.id
					? 'colorButtonSelected cursor-default'
					: 'colorButtonsNotSelected pointer'
			">
			<span
				class="styleNumber"
				:style="tab.number === 1 ? 'padding: 0px 7px' : 'padding: 0px 6.5px'">{{ tab.number }}</span>
			<span class="textTabs">{{ tab.title }}</span>
		</v-btn>
	</v-flex>
</template>

<script>
import { steps } from '@Consts/product/product-form-steps';

export default {
	name: 'MedicineFormSteps',
	props: {
		tabSelected: {
			type: String,
			required: true
		},
		tabDisabled: {
			type: String,
			required: false,
			default: ''
		}
	},
	computed: {
		tabs() {
			return Object.entries(steps)
				.map(([value, text], index) => ({
					id: value,
					title: text,
					number: index + 1
				}));
		}
	}
};
</script>

<style lang="scss" scoped>
	.v-btn {
		margin: 0;
	}

	.styleButtons {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		padding: 0px 75px !important;
	}

	.colorButtonSelected {
		background-color: #ffeacc !important;
	}

	.colorButtonsNotSelected {
		opacity: 0.5;
		background-color: #ffeacc !important;

		&:hover {
			opacity: 0.7;
			background-color: #ffeacc !important;
		}
	}

	.textTabs {
		font-size: 14px;
		font-family: Roboto;
		color: black !important;
	}

	.tabs {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		top: 28px;
	}

	.styleNumber {
		border: 2px solid #342b1d;
		border-radius: 15px;
		margin-right: 5px;
		color: #ffeacc;
		background-color: #342b1d;
	}
</style>
