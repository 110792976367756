<template>
	<Dialog :titulo="item_editavel ? 'Editar endereço' : 'Novo endereço'" @close="$emit('update:close', false)" :btn_acao="item_editavel ? 'Editar' : 'Salvar'" @acao="item_editavel ? editarEndereco(endereco_selecionado) : submeter()"
		:loading="item_editavel ? editando : salvando">
		<v-container fluid grid-list-lg class="pa-0">
			<v-layout wrap>
				<v-flex xs12>
					<Cep :endereco="item_editavel ? endereco_selecionado : item" @endereco-encontrado="end => enderecoEncontrado(end)" />
					<Form :item="item_editavel ? endereco_selecionado : item" :campos="campos" />
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { CRUDMixin } from '@Mixins/crud';
import Cep from '@Componentes/cep';

export default {
	name: 'DialogEndereco',
	components: { Cep },
	mixins: [CRUDMixin],
	props: {
		assinaturaId: {
			type: Number,
			required: true
		},
		item_editavel: {
			type: Object,
			default: () => {}
		},
		editando: {
			type: Boolean,
			default: false
		},
		boxId: {
			type: Number,
			default: null
		}
	},
	data: () => ({
		type: 'endereco',
		fields_type: 'endereco',
		endereco_selecionado: {}
	}),
	created() {
		this.endereco_selecionado = this.item_editavel;
	},
	methods: {
		createData() {
			return {
				...this.item,
				numero: this.item.numero,
				cep: this.$functions.onlyNumber(this.item.cep),
				assinatura_id: this.assinaturaId
			};
		},
		editarEndereco(endereco) {
			this.$emit('enderecoEditado', endereco);
		},
		enderecoEncontrado(endereco) {
			if (this.endereco_selecionado)
				this.endereco_selecionado = endereco;
			this.item = endereco;
		}
	}
};
</script>

<style scoped>
.centralizar {
  position: absolute;
  left: 0;
  right: 0;
}

.data-atualizacao {
  color: #aaa;
  font-size: 12px;
}
</style>
