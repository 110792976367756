<template>
	<section>
		<v-card-text>
			<v-layout wrap>
				<v-flex xs12>
					<v-autocomplete
						v-model="model.activeIngredient"
						background-color="white"
						:items="activeIngredients"
						label="Principio ativo"
						:filter="filterActiveIngredient"
						box
						clearable
						append-outer-icon="add"
						@click:append-outer="openCreateActiveIngredientDialog = true"
						return-object
						:search-input.sync="searchActiveIngredient"
						item-text="name"/>
				</v-flex>
				<v-flex md6 xs12>
					<v-text-field
						type="number"
						v-model="model.concentration"
						background-color="white"
						box
						label="Concentração"/>
				</v-flex>
				<v-flex md6 xs12>
					<v-autocomplete
						v-model="model.unitOfMeasurementSymbol"
						background-color="white"
						:items="unitsOfMeasurements"
						label="Unidade de medida"
						box
						clearable
						append-outer-icon="add"
						@click:append-outer="openCreateUnitOfMeasurementDialog = true"
						item-value="symbol"
						item-text="name"/>
				</v-flex>
			</v-layout>
		</v-card-text>
		<v-dialog
			v-model="openCreateActiveIngredientDialog"
			persistent
			scrollable
			transition="fade"
			max-width="840px">
			<transition>
				<DialogCreateActiveIngredient
					:actsClassification="atcsClassification"
					v-if="openCreateActiveIngredientDialog"
					@close="closeCreateActiveIngredientDialog"/>
			</transition>
		</v-dialog>

		<v-dialog
			v-model="openCreateUnitOfMeasurementDialog"
			persistent
			scrollable
			transition="fade"
			max-width="840px">
			<transition>
				<DialogCreateUnitOfMeasurement
					v-if="openCreateUnitOfMeasurementDialog"
					@close="closeCreateUnitOfMeasurementDialog"/>
			</transition>
		</v-dialog>
	</section>
</template>

<script>

import { debounce } from 'lodash';
import DialogCreateActiveIngredient from '../../../dialog-new-active-ingredient.vue';
import DialogCreateUnitOfMeasurement from '../../../dialog-new-unit-of-measurement.vue';

export default {
	name: 'ActiveIngredientInput',
	components: {
		DialogCreateActiveIngredient,
		DialogCreateUnitOfMeasurement
	},
	props: {
		index: {
			type: Number
		},
		activeIngredients: {
			required: true,
			type: Array
		},
		unitsOfMeasurements: {
			required: true,
			type: Array
		},
		atcsClassification: {
			required: true,
			type: Array
		},
		model: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			openCreateActiveIngredientDialog: false,
			openCreateUnitOfMeasurementDialog: false,
			searchActiveIngredient: null
		};
	},
	watch: {
		searchActiveIngredient: debounce(function (search) {
			this.$emit('updateActiveIngredients', { search });
		}, 500)
	},
	methods: {
		filterActiveIngredient(item, queryText) {
			const searchText = queryText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			const itemName = item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			const itemNameReduced = item.nameReduced?.toLowerCase()?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');
			return (
				itemName.indexOf(searchText) > -1
			|| (item.nameReduced && itemNameReduced.indexOf(searchText) > -1)
			);
		},
		async closeCreateActiveIngredientDialog() {
			this.$emit('updateActiveIngredients');
			this.openCreateActiveIngredientDialog = false;
		},
		async closeCreateUnitOfMeasurementDialog() {
			this.$emit('updateUnitsOfMeasurements');
			this.openCreateUnitOfMeasurementDialog = false;
		}
	}
};
</script>
