<template>
	<v-tooltip top>
		<template v-slot:activator="{ on }">
			<img src="@Assets/icons/first.svg" alt=""  v-on="on" width="20" class="pl-1">
		</template>

		<span>Primeira Box</span>
	</v-tooltip>

</template>

<script>
export default {
	name: 'FirstBox'
};
</script>
