import { EANPresenter } from '@Presenter/ean-presenter';
import { BoxPresenter } from '@Presenter/box-presenter';

export const CLEAR_STATE = ({ commit }) => commit('CLEAR_STATE');

export const FETCH_MEDICAMENTO = ({ commit, state }, data) => new Promise(async resolve => {
	const { ean, label } = data;
	if (state.medicamentos_lidos[ean])
		commit('SET_MEDICAMENTO', { label, ean, medicamento: state.medicamentos_lidos[ean] });
	else {
		commit('SET_LOADING_MEDICAMENTO', true);
		try {
			const { medicamento } = await EANPresenter.show(ean);
			commit('SET_MEDICAMENTO', { ean, medicamento, label });
		} catch (error) {
			commit('SET_MEDICAMENTO', null);
		}
		commit('SET_LOADING_MEDICAMENTO', false);
	}
	resolve();
});

export const FETCH_MEDICAMENTOS = ({ commit }, box_id) => {
	commit('SET_LOADING_MEDICAMENTOS', true);
	BoxPresenter.listaDeCompras({ ids: [box_id] })
		.then(response => {
			commit('SET_MEDICAMENTOS', response[0].medicamentos);
		})
		.finally(() => {
			commit('SET_LOADING_MEDICAMENTOS', false);
		});
};
