<template>
	<main>
		<section class="flex items-center justify-space-between mb-3">
			<div>
				<h1 class="mb-2">Pedido #{{ order.id }}</h1>
				<p class="mb-0">Far.me Pontual</p>
			</div>

			<router-link
				:to="{ name: 'Orders' }"
				class="button py-2 px-5">
				Voltar
			</router-link>
		</section>
		<v-layout wrap>
			<v-flex xs12 md6>
				<v-layout wrap>
					<v-flex xs12>
						<OrderInfo v-if="order" :customer="order.customer" :address="order.address"/>
					</v-flex>
				</v-layout>

				<v-layout wrap>
					<v-flex xs12>
						<OrderObservation :observation="order.observation" />
					</v-flex>
				</v-layout>

				<v-layout wrap>
					<v-flex xs12>
						<OrderLinks :items="getLinks" />
					</v-flex>
				</v-layout>

				<v-layout wrap>
					<v-flex xs12>
						<OrderStatusHistory :items="getStatusHistory" />
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12>
						<OrderProcessHistory :items="orderProcessHistory" />
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex xs12 md6>
				<v-layout wrap>
					<v-flex xs12>
						<OrderItems :items="order.products"/>
					</v-flex>

					<CancelOrder v-if="isAdm && !orderHasBeenCanceled" :order_id="this.$route.params.id" :customer="order.customer" @getOrder="getOrder(order.id)" />
				</v-layout>
			</v-flex>

		</v-layout>
	</main>
</template>
<script>
import { OrderPresenter } from '@Presenter/order-presenter';
import CancelOrder from './cancel-order/index.vue';
import OrderInfo from './info.vue';
import OrderObservation from './observation.vue';
import OrderLinks from './links.vue';
import OrderItems from './items/index.vue';
import OrderStatusHistory from './status-history.vue';
import OrderProcessHistory from './process-history.vue';

export default {
	name: 'OrderDetails',
	components: {
		OrderInfo,
		OrderObservation,
		OrderLinks,
		OrderStatusHistory,
		OrderProcessHistory,
		OrderItems,
		CancelOrder
	},
	created() {
		Promise.all([
			this.getOrder(this.$route.params.id),
			this.getOrderProcessHistory(this.$route.params.id)
		]);
	},
	data() {
		return {
			order: {
				customer: {},
				address: {},
				products: [],
				attachments: [],
				statusHistory: []
			},
			orderProcessHistory: []
		};
	},
	computed: {
		getLinks() {
			return this.order.attachments;
		},
		getStatusHistory() {
			return this.order.statusHistory;
		},
		isAdm() {
			return this.$store.getters.eh_administrador;
		},
		orderHasBeenCanceled() {
			return this.order.status === 'CANCELED';
		}
	},
	methods: {
		async getOrder(id) {
			try {
				const ordersResponse = await OrderPresenter.find(id);

				this.order = ordersResponse;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { message: `Erro ao buscar pedido ${id}`, color: 'error' });
			} finally {
				this.loading = false;
			}
		},

		async getOrderProcessHistory(orderId) {
			try {
				const orderProcessHistoryResponse = await OrderPresenter.getOrderProcessHistory(orderId);

				this.orderProcessHistory = orderProcessHistoryResponse.items;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { message: `Erro ao buscar histórico de processos do pedido ${orderId}`, color: 'error' });
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
h1 {
	font-family: Inter;
	font-size: 30px;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: 0em;
	text-align: left;
}

.button {
	text-decoration: none;
	background-color: transparent;
	border: 2px solid #342B1D !important;
	border-radius: 5px;
	font-weight: 900 !important;
	color: #342B1D !important;

	&:hover {
		background-color: #342B1D !important;
		color: white!important;
	}
}
</style>
