import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';
import { DefaultPresenter } from './default-presenter';

const prefix = 'receita';
const entity = 'Receita';

export const ReceitaPresenter = {
	...DefaultPresenter({ prefix, entity }),
	index: (query = {}) => $http.get(buildQueryParams(prefix, query))
};
