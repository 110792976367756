<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>
					fas fa-user
				</v-icon>
			</v-avatar>
			<h3>Far.membro</h3>
		</v-card-title>
		<v-card-text>
			<v-container fluid grid-list-lg>
				<v-layout wrap>
					<v-flex>
						<v-flex md12 v-if="farmembro.nome" class="pb-0">
							<span class="font-style-name">
								{{ farmembro.nome || nome }}
							</span>
						</v-flex>
						<v-flex md12 v-if="farmembro.cpf" class="pb-0">
							<span class="font-style-cpf">
								<span class="text-bold">CPF:</span> {{ this.$functions.mascaraCpf(farmembro.cpf) || cpf }}
							</span>
						</v-flex>
					</v-flex>
				</v-layout>
			</v-container>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'Farmembro',
	props: {
		farmembro: {
			type: Object,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	padding: 0;
}

.v-card {
  ::v-deep .v-card {
    box-shadow: none !important;
  }
}

.font-style-name {
	font-family: 'Open Sans', sans-serif;
	font-size: 22px;
	font-weight: 600;
}

.font-style-cpf {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: 400;
}
</style>
