/* eslint-disable no-console */

import { register } from 'register-service-worker';
import $store from '@Store';

if (process.env.NODE_ENV !== 'local') {
	if (navigator?.serviceWorker?.controller?.scriptURL?.includes('service-worker-override')) {
		navigator.serviceWorker.getRegistration().then(reg => {
			if (reg) {
				reg.unregister().then(() => {
					console.log('Service worker unregistered.');
					window.location.reload();
					console.log('Reloading page.');
				});
			}
		});
	}
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready() {
			console.log(
				'App is being served from cache by a service worker.\n'
		+ 'For more details, visit https://goo.gl/AFskqB'
			);
		},
		registered() {
			console.log('Service worker has been registered.');
		},
		cached() {
			console.log('Content has been cached for offline use.');
		},
		updatefound() {
			$store.dispatch('SHOW_SNACKBAR', { color: 'primary', message: 'Baixando atualização.' });
		},
		updated() {
			$store.dispatch('SHOW_SNACKBAR', { color: 'primary', message: 'Nova versão disponível. Por favor, atualize a página (Ctrl + F5).' });
		},
		offline() {
			$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Sem conexão com a internet.' });
		},
		error(error) {
			console.error('Error during service worker registration:', error);
		}
	});
}
