export default {
	email: {
		label: 'E-mail',
		validation: { email: true },
		size: 'xs12 md6'
	},
	nome: {
		label: 'Nome completo',
		validation: { required: true },
		size: 'xs12 md6'
	},
	telefone: {
		label: 'Telefone',
		mask: ['(##) #####-####', '(##) ####-####'],
		size: 'xs12 md6'
	},
	permissao: {
		label: 'Permissão',
		component: 'select',
		item_text: 'text',
		item_value: 'value',
		items: [
			{
				text: 'Usuário comum',
				value: 1
			},
			{
				text: 'Vendedor',
				value: 5
			},
			{
				text: 'Operador',
				value: 10
			},
			{
				text: 'Financeiro',
				value: 11
			},
			{
				text: 'RT',
				value: 12
			},
			{
				text: 'Clinica',
				value: 13
			},
			{
				text: 'Administrador',
				value: 20
			}
		],
		return_object: true,
		validation: { required: true },
		size: 'xs12 md6'
	}
};
