<template>
	<v-layout wrap>
		<!--<v-flex md4 xs12 class="px-0">
			<v-text-field v-model="quotation.serviceValue" box background-color="white" hide-details label="Valor do serviço"
				v-money/>
		</v-flex>-->
		<v-flex md6 xs12 class="px-2">
			<v-text-field v-model="quotation.shippingPrice" box background-color="white" hide-details label="Valor do frete"
				v-money/>
		</v-flex>
		<v-flex md6 xs12 class="px-0">
			<v-text-field v-model="quotation.percentDiscountFirstBox" box background-color="white" hide-details label="Desconto da primeira box"
				:loading="loading" :rules="percentDiscountRules"
				type="number"
				suffix="%"/>
		</v-flex>
	</v-layout>
</template>

<script>
import Mixin from '../mixin';

export default {
	name: 'ServiceInputsQuotationV2',
	mixins: [Mixin],
	data: () => ({
		loading: false,
		percentDiscountRules: [v => (v >= 0 && v <= 100) || 'Porcentagem deve ser de 0 a 100']
	})
};
</script>
