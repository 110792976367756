<template>
	<header>
		<div class="items-center space-between">
			<h1 class="text--text mr-5" style="opacity: 0.8; font-size: 35px">
				Pre-produção
			</h1>

			<v-tabs
				color="transparent"
				v-model="filter.view"
				light
				:slider-color="getSliderColor">
				<v-tab
					v-for="(item, index) in totalResume"
					:style="item.name === 'ATRASADAS' ? 'color: red!important;font-weight: bold;' : null"
					:key="index"
					ripple>
					{{ item.name }} <v-chip small :color="item.name === 'ATRASADAS' ? 'red' : 'primary'" :dark="item.name === 'ATRASADAS'" class="ml-3">{{ item.total }}</v-chip>
				</v-tab>
			</v-tabs>
		</div>

		<div class="items-center space-between">
			<!--
			<v-text-field
				v-model="filter.search"
				label="Busca"
				placeholder="Digite o ID da box ou assinatura..."
				class="mr-4"
				hide-details
				append-icon="search" />
			-->

			<v-menu
				style="height: 100%;"
				:close-on-content-click="false">
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on"
						color="secondary"
						class="px-4 py-4 ma-0 items-center space-between"
						outline
						style="border-radius: 10px;">
						<span>Filtros</span>
						<v-icon small class="ml-5">keyboard_arrow_down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-tile>
						<v-list-tile-title>Filtros</v-list-tile-title>
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-3">
						<TextDateField v-model="filter.startDate"
							label="Data de início"
							placeholder="dd/mm/yyyy"
							:validate="{ required: true, data: true }"
							:flat="true" />
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-3">
						<TextDateField v-model="filter.productionDate"
							label="Data de produção"
							placeholder="dd/mm/yyyy"
							full-width
							:validate="{ required: true, data: true }"
							:flat="true" />
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-2">
						<v-autocomplete
							v-model="filter.restHomeId"
							full-width
							background-color="white"
							:loading="restHome.loading"
							:disabled="restHome.loading"
							:items="restHome.items"
							label="Casas de repouso"
							no-data-text="Nenhuma casa de repouso"
							box
							hide-details
							clearable
							item-value="id"
							item-text="nome" />
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-2">
						<v-combobox
							v-model="filter.ignoreTagsIds"
							background-color="white"
							full-width
							:loading="globalTags.loading"
							:disabled="globalTags.loading"
							:items="globalTags.items"
							label="Não listar tags"
							no-data-text="Nenhuma tag encontrada"
							hide-details
							items-id="id"
							item-text="text"
							:return-object="false"
							clearable
							multiple
							small-chips>
							<template v-slot:selection="data">
								<v-chip
									close
									@input="removeTag(data.item)"
									class="pointer">
									<strong>{{ data.item }}</strong>
								</v-chip>
							</template>
						</v-combobox>
					</v-list-tile>
				</v-list>
			</v-menu>

			<span class="mx-3">
				{{ selected.length }} selecionado(s)
			</span>

			<v-btn
				:loading="loading"
				:disabled="selected.length === 0 || loading"
				class="px-4"
				large
				@click="generateTags"
				color="primary"
				style="border-radius: 10px;">
				Gerar etiquetas
			</v-btn>
		</div>
	</header>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import { TagPresenter } from '@Presenter/tag-presenter';

const VIEWS = {
	0: 'ALL',
	1: 'TODAY',
	2: 'DELAY'
};
export default {
	name: 'ListProductionHeader',
	props: {
		views: {
			required: false,
			type: Array,
			default: () => ([
				{
					name: 'TOTAL',
					value: 0
				},
				{
					name: 'HOJE',
					value: 0
				},
				{
					name: 'ATRASADAS',
					value: 0
				}
			])
		},
		total: {
			required: false,
			type: Number,
			default: 0
		},
		totalDelayed: {
			required: false,
			type: Number,
			default: 0
		},
		totalToday: {
			required: false,
			type: Number,
			default: 0
		},
		selected: {
			required: false,
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			filter: {
				view: null,
				search: null,
				startDate: null,
				productionDate: null,
				restHomeId: null,
				userProducingId: null,
				ignoreTagsIds: []
			},
			restHome: {
				items: [],
				loading: false
			},
			globalTags: {
				items: [],
				loading: false
			},
			loading: false
		};
	},
	async created() {
		await Promise.all([
			this.getRestHomes(),
			this.getGlobalTags()
		]);
	},
	computed: {
		totalResume() {
			return [
				{
					name: 'TOTAL',
					total: this.total
				},
				{
					name: 'HOJE',
					total: this.totalToday
				},
				{
					name: 'ATRASADAS',
					total: this.totalDelayed
				}
			];
		},
		getSliderColor() {
			const view = VIEWS[this.filter.view];
			return view === 'DELAY' ? 'red' : 'primary';
		}
	},
	methods: {
		handleFilter() {
			this.$emit('filter', {
				filter: {
					...this.filter,
					startDate: this.$functions.FormatDateToRequest(this.filter.startDate),
					productionDate: this.$functions.FormatDateToRequest(this.filter.productionDate),
					view: VIEWS[this.filter.view],
					ignoreTagsIds: this.globalTags.items.filter(item => this.filter.ignoreTagsIds.includes(item.text)).map(i => i.id)
				}
			});
		},
		async getRestHomes() {
			this.restHome.loading = true;
			try {
				const restHomeResponse = await CasaRepousoPresenter.index();
				this.restHome.items = restHomeResponse;
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{ message: 'Erro ao carregar casas de repouso.', color: 'error' }
				);
			} finally {
				this.restHome.loading = false;
			}
		},
		async generateTags() {
			this.loading = true;
			const boxIds = this.selected.map(i => i.id);
			try {
				await BoxPresenter.generateAllLabels(boxIds, [], ['producao']);
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao Gerar etiquetas', color: 'error' });
			} finally {
				this.loading = false;
			}
		},
		async getGlobalTags() {
			this.globalTags.loading = true;
			try {
				const tags = await TagPresenter.index();
				const globalTags = (tags || []).filter(tag => tag.nome.includes('GLOBAL_'));
				this.globalTags.items = globalTags.map(t => ({ id: t.id, text: t.nome }));
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{ message: 'Erro ao carregar tags', color: 'error' }
				);
			} finally {
				this.globalTags.loading = false;
			}
		},
		removeTag(tagNameToRemove) {
			const tagToRemove = this.globalTags.items.find(item => item.text === tagNameToRemove);
			const indexOfItemToRemove = this.filter.ignoreTagsIds.findIndex(name => name === tagToRemove.text);
			this.filter.ignoreTagsIds.splice(indexOfItemToRemove, 1);
		}
	},
	watch: {
		filter: {
			deep: true,
			handler() {
				this.handleFilter();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
header {
	border-bottom-width: 5px;
	border-bottom-color: #ffd9a0 !important;
	border-bottom-style: solid;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
