<template>
	<Dialog titulo="Configurações" @close="$emit('update:fecha', false)" btn_acao="Salvar" :loading="salvando"
		@acao="submeter()">

		<v-container fluid grid-list-lg class="pa-0">
			<Form v-if="!loading" :item="configuracoes" :campos="campos" />
		</v-container>
	</Dialog>
</template>

<script>
import fields from '@Fields/configuracao-fields';
import { ConfiguracaoPresenter } from '@Presenter/configuracao-presenter';

export default {
	name: 'DialogConfiguracoes',
	$_veeValidate: { validator: 'new' },
	data: () => ({
		type: 'configuracao',
		fields_type: 'configuracao',
		configuracoes: {},
		salvando: false,
		loading: true,
		campos: {}
	}),
	created() {
		this.campos = fields;
		ConfiguracaoPresenter.show()
			.then(response => {
				this.configuracoes = response;
			})
			.finally(() => {
				this.loading = false;
			});
	},
	methods: {
		createData() {
			return {
				...this.configuracoes,
				valor_servico: Number(this.$functions.onlyNumber(this.configuracoes.valor_servico))
			};
		},
		async submeter() {
			const is_valid = await this.$validator.validateAll();
			if (!is_valid) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
				return;
			}
			const data = this.createData();
			this.salvar(data);
		},
		salvar(data) {
			this.salvando = true;
			const $http = this.is_new ? ConfiguracaoPresenter.store : ConfiguracaoPresenter.update;
			$http(data)
				.then(() => {
					this.fechaModal();
				})
				.finally(() => {
					this.salvando = false;
				});
		},
		fechaModal() {
			this.$emit('update:close', false);
		}
	},
	computed: {
		is_new() {
			return typeof this.configuracoes.id !== 'number';
		}
	}
};
</script>

<style scoped>
</style>
