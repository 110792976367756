import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = '/orders';

export const OrderPresenter = {
	get: params => $http.get(buildQueryParams(prefix, params)),
	find: id => $http.get(`${prefix}/${id}`),
	generatePdfOrderPickingList: ({
		orderIds
	}) => $http.get(buildQueryParams(`${prefix}/pickingList`, { orderIds })),
	updateOrderProcess: (id, typeProcess, params) => $http.put(`${prefix}/${id}/processes/${typeProcess}`, params),
	getOrdersByProcess: (process, params) => $http.get(buildQueryParams(`${prefix}/processes/${process}`, params)),
	getProcessByOrderAndProcessType: (orderId, typeProcess) => $http.get(`${prefix}/${orderId}/processes/${typeProcess}`),
	getOrderProcessHistory: orderId => $http.get(`${prefix}/${orderId}/processes`),
	cancelOrder: (orderId, reasonOfCancellation) => $http.delete(`${prefix}/${orderId}`, { data: { reasonOfCancellation } })
};
