<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:loading="carregando"
		:rows-per-page-items="[10, 20, 30]"
		no-results-text="Nenhum resultado encontrado"
		no-data-text="Nenhum medicamento para análise simplificada encontrada"
		rows-per-page-text="Itens por página">
		<template slot="items" slot-scope="props">
			<tr>
				<th v-if="!!props.item.merge && props.item.merge > 1" :rowspan="props.item.merge">{{ props.item.medicamento.nome }}</th>
				<th v-if="!props.item.merge">{{ props.item.medicamento.nome }}</th>
				<td>{{ `${props.item.principio_ativo.dosagem} ${props.item.principio_ativo.nome}` }}</td>
				<td>
					<v-tooltip right>
						<v-icon v-if="props.item.dose_maxima.dose_maxima_recomendada === 'NA'" slot="activator" color='purple'>help_outline</v-icon>
						<v-icon v-else slot="activator" :color="props.item.dose_maxima.dose_maxima_atingida ? 'warning': 'green'">{{ props.item.dose_maxima.dose_maxima_atingida ? 'warning' : 'check_circle_outline' }}</v-icon>
						<span>
							<p>{{`Dose diária: ${props.item.dose_maxima.dose_diaria}`}}</p>
							<p>{{`Dose diária máxima recomendada: ${props.item.dose_maxima.dose_maxima_recomendada}`}}</p>
						</span>
					</v-tooltip>
				</td>
				<td>
					<v-tooltip v-if="!!props.item.obs_mpp" right>
						<v-icon slot="activator" :color="props.item.is_mpp ? 'warning' : 'green'">{{ props.item.is_mpp ? 'warning' : 'check_circle_outline' }}</v-icon>
						<span>{{`${props.item.obs_mpp}`}}</span>
					</v-tooltip>
					<v-icon v-else :color="props.item.is_mpp ? 'warning' : 'green'">{{ props.item.is_mpp ? 'warning' : 'check_circle_outline' }}</v-icon>
				</td>
				<td>
					<v-tooltip v-if="!!props.item.obs_mpi" right>
						<v-icon slot="activator" :color="props.item.is_mpi ? 'warning' : 'green'">{{ props.item.is_mpi ? 'warning' : 'check_circle_outline' }}</v-icon>
						<span >{{`${props.item.obs_mpi}`}}</span>
					</v-tooltip>
					<v-icon v-else :color="props.item.is_mpi ? 'warning' : 'green'">{{ props.item.is_mpi ? 'warning' : 'check_circle_outline' }}</v-icon>
				</td>
			</tr>
		</template>
	</v-data-table>
</template>

<script>
import { AnalisePrescricaoPresenter } from '@Presenter/analise-prescricao-presenter';

export default {
	name: 'TabelaAnaliseSimplificada',
	props: {
		assinatura_id: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		carregando: false,
		items: [],
		headers: [
			{ text: 'Medicamento', value: 'nome_medicamento' },
			{ text: 'Principio Ativo', value: 'principio_ativo' },
			{ text: 'Dose Máxima', value: 'check_dose_maxima' },
			{ text: 'MPP', value: 'is_mpp' },
			{ text: 'MPI', value: 'is_mpi' }
		]
	}),
	created() {
		this.carregando = true;
		this.items = [];
		AnalisePrescricaoPresenter.analiseSimplificada(this.assinatura_id)
			.then(response => {
				const responseJson = this.$lodash.cloneDeep(response);
				const groupByMedicamento = this.$lodash.groupBy(responseJson, 'medicamento.nome');
				Object.keys(groupByMedicamento).forEach(nomeMedicamento => {
					const grupoDePrincipioAtivo = groupByMedicamento[nomeMedicamento];
					const qntDePrincipiosAtivos = grupoDePrincipioAtivo.length;
					if (qntDePrincipiosAtivos > 1) {
						let merge = qntDePrincipiosAtivos;
						grupoDePrincipioAtivo.forEach(principioAtivo => {
							principioAtivo.merge = merge;
							merge = 1;
						});
					}
					this.items = this.items.concat(grupoDePrincipioAtivo);
				});
			})
			.finally(() => {
				this.carregando = false;
			});
	},
	methods: {
	},
	computed: {
		error_message() {
			if (this.is_loading)
				return 'Carregando...';
			return this.request_error ? 'Erro ao carregar. Por favor, tente novamente' : 'Nenhum medicamento encontrado na assinatura';
		}
	}
};
</script>

<style scoped>
.centralizar {
  position: absolute;
  left: 0;
  right: 0;
}

.data-atualizacao {
  color: #aaa;
  font-size: 12px;
}

.center {
  text-align: center;
}
</style>
