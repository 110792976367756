import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

export const geraPdfEtiquetaRastreabilidade = (medicamentos, paciente) => {
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	const body = [['Produto', 'Lote', 'Validade']];
	medicamentos.forEach(med => {
		const arr = [];
		arr.push(med.produto || '');
		arr.push(med.lote || '');
		arr.push(med.validade || '');
		body.push(arr);
	});

	const content = [{
		table: {
			dontBreakRows: true,
			headerRows: 1,
			widths: ['auto', '*', '*'],
			body
		},
		layout: 'lightHorizontalLines'
	}];

	const header = {
		text: paciente.nome,
		margin: 5
	};

	const docDefinition = {
		pageSize: 'A7',
		pageOrientation: 'landscape',
		pageMargins: [5, 20, 5, 5],
		header,
		content,
		defaultStyle: {
			fontSize: 8
		}
	};

	return pdfMake.createPdf(docDefinition).open();
};
