<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>fas fa-exclamation</v-icon>
			</v-avatar>
			<h3>Observações da Box</h3>
		</v-card-title>
		<v-card-text>
			<v-textarea
				v-model="box.observacao"
				:placeholder="placeholder"
				:disabled="disabled"
				:readonly="disabled"
				hide-details/>
		</v-card-text>

		<v-card-actions>
			<v-flex class="display-flex justify-end pb-2">
				<v-btn
					:disabled="!disponivelParaAlterar"
					color="primary"
					:loading="loading"
					@click="atualizaObservacaoBox">Salvar observação</v-btn>
			</v-flex>
		</v-card-actions>
	</v-card>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';

export default {
	name: 'Observacao',
	props: {
		box: {
			type: Object,
			required: true
		},
		disabled: {
			type: Boolean,
			required: false
		}
	},
	data: () => ({
		loading: false,
		novaObservacao: ''
	}),
	created() {
		this.novaObservacao = this.box.observacao;
	},
	methods: {
		atualizaObservacaoBox() {
			this.loading = true;
			BoxPresenter.updateObservacao(
				this.box.id,
				this.box.observacao || ''
			)
				.then(response => {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'success',
						message: response.message
					});
					this.$emit('observacaoAtualizada');
				})
				.catch(err => {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: err.message
					});
				})
				.finally(() => {
					this.loading = false;
				});
		}
	},
	computed: {
		disponivelParaAlterar() {
			return this.novaObservacao !== this.box.observacao;
		},
		placeholder() {
			if (this.disabled)
				return 'Nenhuma observação';
			return 'Deseja acrescentar alguma observação?';
		}
	}
};
</script>
