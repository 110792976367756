<template>
	<div class="assinaturas-wrapper px-md-5">
		<!-- Assinaturas Header -->
		<Header @nova-assinatura="novaAssinatura" @busca="b => busca = b" />

		<!-- Conteúdo -->
		<v-card>
			<v-data-table :headers="headers" :items="assinaturas" :loading="carregando" :search="busca"
				:no-data-text="(carregando) ? 'Carregando...' : (erro) ? 'Erro ao carregar, tente novamente' : 'Nenhuma assinatura'"
				no-results-text="Nenhum resultado encontrado" :rows-per-page-items="[15, 30, 45, {text: 'Todos', value: -1}]"
				:pagination.sync="pagination" rows-per-page-text="Itens por página">
				<template slot="items" slot-scope="props">
					<tr @click="selecionaAssinatura(props.item.id)">
						<td class="text-xs-center">{{ props.item.id }}</td>
						<td class="py-2" style="white-space: nowrap">
							<v-avatar size="24" class="mr-2">
								<span class="white--text">
									{{ props.item.paciente.nome.charAt(0) }}
								</span>
							</v-avatar>
							<GlobalTags
								v-for="(global_tag, index) in props.item.paciente.global_tags"
								:key="index"
								:global_tag="global_tag"/>
							{{ props.item.paciente.nome }}
							<FirstBox v-if="props.item.primeira_box"/>
						</td>
						<td>
							{{ props.item.paciente.casa_repouso_id ? props.item.paciente.casa_repouso.nome : '-' }}
						</td>
						<td>
							{{ props.item.created_at | formatDate('DD/MM/YYYY [(]dddd[)]') }}
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog persistent v-model="nova_assinatura" fullscreen hide-overlay scrollable
			transition="fade">
			<NovaAssinatura v-if="nova_assinatura" type="b2b" :close.sync="nova_assinatura" @assinatura-salva="assinaturaSalva" :clinica="true" />
		</v-dialog>
	</div>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import NovaAssinatura from '@Componentes/assinatura/nova-assinatura';
import FirstBox from '@Componentes/first-box';
import GlobalTags from '@Componentes/global-tags';
import Header from './header';

export default {
	name: 'ClinicaGTM',
	components: {
		Header,
		NovaAssinatura,
		FirstBox,
		GlobalTags
	},
	data: () => ({
		carregando: true,
		erro: false,
		busca: null,
		nova_assinatura: false,
		headers: [
			{ text: 'ID', value: 'id' },
			{ text: 'Paciente', value: 'paciente.nome' },
			{ text: 'Parceiro', value: 'paciente.casa_repouso.nome' },
			{ text: 'Data de cadastro', value: 'created_at' }
		],
		assinaturas: [],
		index_props: {
			casa_repouso_ids: [29, 76],
			filtro: 'canceladas'
		},
		pagination: {
			sortBy: 'id',
			descending: true
		}
	}),
	created() {
		this.buscaAssinaturas();
	},
	methods: {
		buscaAssinaturas() {
			this.assinaturas = [];
			this.carregando = true;
			AssinaturaPresenter.index(this.index_props)
				.then(response => {
					this.assinaturas = response;
				})
				.finally(() => {
					this.carregando = false;
				});
		},
		selecionaAssinatura(id) {
			this.$router.push({ name: 'Assinatura', params: { id } });
		},
		novaAssinatura() {
			this.nova_assinatura = true;
		},
		assinaturaSalva(assinatura) {
			this.nova_assinatura = false;
			this.assinaturas.push(this.$lodash.cloneDeep(assinatura));
		}
	}
};
</script>

<style lang="scss" scoped>
.v-card {
  overflow: hidden;

  ::v-deep .v-table {
    thead,
    tbody {
      tr {
        th,
        td {
          &:first-child,
          &:last-child {
            width: 1%;
            white-space: nowrap;
          }
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}

::v-deep td.text-xs-right {
  .v-menu__activator {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
