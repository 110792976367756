<template>
	<v-card>
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="#342B1D" small>fas fa-receipt</v-icon>
			</v-avatar>
			<h3>Links importantes</h3>
		</v-card-title>

		<v-card-text>
			<v-data-table
				disable-initial-sort
				:headers="headers"
				:items="items">
				<template v-slot:items="props">
					<tr class="pointer" @click="openLink(props.item.url)">
						<td class="pl-0">{{ props.item.name}}</td>
						<td>
							{{ props.item.url }}
						</td>
					<tr/>
				</template>
			</v-data-table>
		</v-card-text>

	</v-card>
</template>

<script>
export default {
	name: 'OrderLinks',
	props: {
		items: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	computed: {
		headers() {
			return [
				{
					text: 'Arquivo', value: 'name', sortable: true, class: 'pl-0'
				},
				{ text: 'URL', value: 'url', sortable: false }
			];
		}
	},
	methods: {
		openLink(url) {
			window.open(url, '_blank');
		}
	}
};
</script>

<style scoped>
h3 {
	color: rgba(0, 0, 0, 0.87);
	/* font-family: Inter; */
	font-size: 13.38px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.57px;
}
</style>
