<template>
	<div class="list">
		<div v-for="(product, i) in products" :key="product.id">
			<v-layout wrap>
				<v-flex xs6>
					<div class="product-name">
						<h4>{{product.name}}</h4>
						<v-tooltip v-if="!product.gtin" right>
							<v-icon slot="activator" color='purple'>info_outline</v-icon>
							<span>Produto não possui GTIN</span>
						</v-tooltip>
					</div>
					<p>{{product.presentation}}</p>
				</v-flex>
				<v-flex xs3 class="text-center">
					<h5>Quant. necessária</h5>
					<p class="number">{{product.quantity}}</p>
				</v-flex>
				<v-flex xs3 class="text-center">
					<h5>Quant. inserida</h5>
					<div class="quantity-entered-container">
						<v-btn class="btn" small icon @click="product.enteredQuantity -= 1" :disabled="product.enteredQuantity < 1">
							<v-icon small color="red">remove</v-icon>
						</v-btn>
						<p class="number" :class="numeroClass(product)">
							{{product.enteredQuantity || 0}}
						</p>
						<v-btn class="btn" small icon @click="product.enteredQuantity += 1" :disabled="!!product.externalId">
							<v-icon small color="green">add</v-icon>
						</v-btn>
					</div>
				</v-flex>
			</v-layout>
			<v-divider v-if="i < (products.length - 1)" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'ProductList',
	props: {
		products: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		numeroClass(product) {
			if (product.enteredQuantity < product.quantity)
				return 'red--text';
			return 'green--text';
		}
	}
};
</script>

<style lang="scss" scoped>
.quantity-entered-container {
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		.btn {
			display: inherit;
		}
	}

	.btn {
		display: none;
	}
}

.list {
	padding-left: 4px;
	padding-right: 4px;
	overflow-y: auto;
	overflow-x: hidden;
	height: 400px;

	.product-name {
		display: flex;
		align-items: center;
	}

	.layout {
		margin-top: 0px !important;
		padding: 4px;

		.text-center {
			text-align: center;

			p {
				font-weight: 500;

				&.number {
					font-size: 28px;
				}
			}
		}
	}
	p {
		margin: 0;
	}
}
</style>
