<template>
	<v-dialog
		value="true"
		fullscreen>
		<div class="dialog">
			<div class="dialog-close">
				<v-btn
					outline
					flat
					@click="$emit('close')"
					style="color: #342b1d;border-radius: 5px">
					Fechar <v-icon small right>close</v-icon>
				</v-btn>
			</div>

			<RestHomeDialogHeader
				:title="title"
				:tabs="steps"
				:tab-selected="currentStep"
				@close="$emit('close')"
				@tabChanged="tab => currentStep = tab" />

			<div class="dialog-body">
				<v-tabs-items :value="currentStep">
					<v-tab-item :value="generalDataStep.id">
						<GeneralData :ref="generalDataStep.id" :general-data="generalData" />
					</v-tab-item>
				</v-tabs-items>
			</div>

			<RestHomeDialogFooter
				class="dialog-footer"
				@close="$emit('close')"
				@submit="submit()" />
		</div>
	</v-dialog>
</template>

<script>
import { getUFByStateName } from '@Consts/estados';
import { REST_HOME_GENERAL_DATA_STEP, REST_HOME_STEPS } from '@Consts/rest-home/form-steps';
import RestHomeDialogHeader from './rest-home-dialog-header.vue';
import RestHomeDialogFooter from './rest-home-dialog-footer.vue';
import GeneralData from '../form/general-data';

export default {
	name: 'RestHomeDialog',
	components: { RestHomeDialogHeader, RestHomeDialogFooter, GeneralData },
	props: {
		title: {
			required: true,
			type: String
		},
		restHome: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			currentStep: REST_HOME_GENERAL_DATA_STEP.id,
			steps: [
				{ id: REST_HOME_GENERAL_DATA_STEP.id, name: REST_HOME_GENERAL_DATA_STEP.text }
			],
			generalData: {
				info: {
					companyName: this.restHome.companyName,
					tradingName: this.restHome.tradingName,
					businessType: this.restHome.businessType,
					federalRegistration: this.$functions.onlyNumber(this.restHome.federalRegistration),
					municipalRegistration: this.restHome.municipalRegistration,
					stateRegistration: this.restHome.stateRegistration,
					responsibleName: this.restHome.responsibleName,
					responsibleEmail: this.restHome.responsibleEmail,
					responsiblePhone: this.restHome.responsiblePhone,
					classification: this.restHome.classification,
					simpleNationalTax: Boolean(this.restHome.simpleNationalTax),
					icmsTax: Boolean(this.restHome.icmsTax)
				},
				address: {
					zipcode: this.$functions.onlyNumber(this.restHome.zipcode),
					street: this.restHome.street,
					neighborhood: this.restHome.neighborhood,
					number: this.restHome.number,
					complement: this.restHome.complement,
					observation: this.restHome.observation,
					city: this.restHome.city,
					state: this.parseStateNameToUF(this.restHome.state)
				},
				discount: {
					maxDiscountPercentage: Number(this.restHome.maxDiscountPercentage) || 0
				},
				payment: {
					responsabilityForPayment: this.restHome.responsabilityForPayment,
					type: this.restHome.type,
					dueDay: this.restHome.dueDay,
					closingDay: this.restHome.closingDay,
					expireToPayInDays: Number(this.restHome.expireToPayInDays) || 0
				},
				delivery: {
					daysInAdvanceForDelivery: Number(this.restHome.daysInAdvanceForDelivery || 0),
					deliveryType: this.restHome.deliveryType
				}
			}
		};
	},
	computed: {
		steps2() {
			return REST_HOME_STEPS;
		},
		generalDataStep() {
			return REST_HOME_GENERAL_DATA_STEP;
		}
	},
	methods: {
		parseStateNameToUF(stateName) {
			return getUFByStateName(stateName);
		},
		async submit() {
			const stepValidated = await this.$refs[REST_HOME_GENERAL_DATA_STEP.id].validate();
			if (!stepValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário incompleto',
					color: 'error'
				});
				return;
			}
			const data = {
				...this.generalData
			};
			this.$emit('submited', data);
		}
	}
};
</script>

<style lang="scss" scoped>
.primary-light {
	background-color: red !important;
}
.tab-active {
	background:red !important;
}
.dialog {
	position: relative;
	width: 100%;
	height: 100%;
	height: 100vh;
	overflow-y: scroll;
	background-color: #FFEACC;
}

.dialog-close {
	position: absolute;
	top: 40px;
	right: 20px;
}

.dialog-header {
	height: 215px;
	background-color: rgba(255, 200, 117, 0.5);
}

.dialog-header-content {
	display: flex;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	min-height: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
}

.dialog-title {
	padding-top: 45px;
	font-size: 28px;
}

.dialog-body {
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	padding-top: 30px;
}

.dialog-footer {
	display: flex;
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	justify-content: space-between;
}
</style>
