<template>
	<v-flex class="dialogStyle">
		<v-layout column wrap class="d-flex items-center justify-center">
			<v-flex xs12 class="imgStyle d-flex items-center mb-4">
				<img src="/img/icons/prohibited-icon.svg" width="42" height="42" alt="Imagem de exclamação, sugerindo atenção">
			</v-flex>
			<v-flex xs12 class="mb-2 text-center sans text-bold title">
				Não foi possível concluir
			</v-flex>
			<v-flex xs12 class="mb-4 text-center sans subheading">
				Para submeter um orçamento, voê precisa preencher a posologia de todos os medicamentos que vão na box
			</v-flex>
			<v-flex xs12 class="d-flex items-center">
				<v-btn @click="$emit('update:close', false)">Voltar</v-btn>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
export default {
	name: 'DialogNoPosology'
};
</script>

<style lang="scss" scoped>
.dialogStyle {
	padding: 20px 50px;
	border-radius: 20px;
	background: #FFF3E2;
	border: 2px solid #E50000
}

.imgStyle {
	background-color: rgba(229, 0, 0, 0.1);
	padding: 15px;
	border-radius: 40px;
}
</style>
