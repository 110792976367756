<template>
	<Dialog titulo="Renovar orçamento" :subtitulo="orcamento.nome" btn_acao="Confirmar"
		@acao="renovar" @close="close">
		<TextDateField v-model="data_validade" box hideDetails label="Nova data de validade" placeholder="dd/mm/yyyy" />
	</Dialog>
</template>

<script>
import moment from 'moment';
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';

export default {
	name: 'DialogRenovar',
	props: {
		orcamento: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		loading: false,
		data_validade: moment().add(30, 'days').format('DD/MM/YYYY')
	}),
	methods: {
		renovar() {
			if (!moment(this.data_validade, 'DD/MM/YYYY').isValid()) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Data de validade invalida' });
				return;
			}
			this.loading = true;
			OrcamentoPresenter.renovacao({
				id: this.orcamento.id,
				data_validade: moment(this.data_validade, 'DD/MM/YYYY').format('YYYY-MM-DD')
			})
				.then(() => {
					this.$emit('refresh');
					this.close();
				})
				.finally(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
