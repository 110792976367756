<template>
	<div v-if="loading" class="loading">
		<v-progress-circular indeterminate color="primary" />
	</div>
	<div v-else>
		<Header @comparar="dialog_comparar_receitas_medicamentos = true" :assinatura="assinatura"/>
		<v-container fluid grid-list-lg>
			<v-layout wrap>
				<v-flex xs12 md6>
					<v-layout wrap>
						<v-flex xs12>
							<InformacoesPacienteResponsavelEndereco :paciente="assinatura.paciente" :responsaveis="assinatura.paciente.responsaveis" :endereco="assinatura.endereco" />
						</v-flex>
						<v-flex xs12 md6>
							<Alergias :paciente="assinatura.paciente" />
						</v-flex>
						<v-flex xs12 md6>
							<Tags :paciente="assinatura.paciente" />
						</v-flex>
						<v-flex xs12 md6>
							<Sonda :paciente="assinatura.paciente" />
						</v-flex>
						<v-flex xs12 md6>
							<ProximaBox :assinatura="assinatura" />
						</v-flex>
						<v-flex xs12>
							<Receitas :receitas="assinatura.receitas" />
						</v-flex>
						<v-flex xs12>
							<Observacao :assinatura="assinatura" disabled />
						</v-flex>
						<v-flex xs12>
							<HistoricoAssinatura :assinatura_id="assinatura.id"/>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 md6>
					<v-layout wrap>
						<v-flex xs12>
							<Arquivos :paciente_id="assinatura.paciente.id" />
						</v-flex>
						<v-flex xs12>
							<Medicamentos :assinatura="assinatura" :estoque="estoque_medicamentos" :medicamentos="assinatura.medicamentos" disabled />
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>

		<v-dialog v-model="dialog_comparar_receitas_medicamentos" fullscreen scrollable transition="fade">
			<transition>
				<DialogCompararReceitasMedicamentos v-if="dialog_comparar_receitas_medicamentos"
					:assinatura_id="assinatura.id" :assinatura_selecionada="assinatura" @close="dialog_comparar_receitas_medicamentos = false" />
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { PacientePresenter } from '@Presenter/paciente-presenter';
import Sonda from '@Componentes/assinatura/sonda';
import Tags from '@Componentes/assinatura/tags';
import Alergias from '@Componentes/assinatura/alergias';
import Receitas from '@Componentes/assinatura/receitas';
import Arquivos from '@Componentes/assinatura/arquivos';
import Observacao from '@Componentes/assinatura/observacao';
import Medicamentos from '@Componentes/assinatura/medicamentos';
import DialogCompararReceitasMedicamentos from '@Componentes/dialog-comparar-receitas-medicamentos';
import InformacoesPacienteResponsavelEndereco from '@Componentes/assinatura/informacoes-paciente-responsavel-endereco';
import HistoricoAssinatura from '@Componentes/assinatura/historico-assinatura';
import Header from './header';
import ProximaBox from './proxima-box';

export default {
	name: 'PacienteClinica',
	components: {
		InformacoesPacienteResponsavelEndereco, Medicamentos, Receitas, Alergias, Arquivos, DialogCompararReceitasMedicamentos, Header, Sonda, Observacao, ProximaBox, Tags, HistoricoAssinatura
	},
	data: () => ({
		loading: true,
		assinatura: null,
		carrega_estoque: false,
		estoque_medicamentos: [],
		dialog_comparar_receitas_medicamentos: false
	}),
	async created() {
		const { id } = this.$route.params;
		this.assinatura = await PacientePresenter.ultimaAssinatura(id);
		this.loading = false;
		if (this.assinatura) {
			this.carrega_estoque = true;
			PacientePresenter.estoque(this.assinatura.paciente_id, { assinatura_id: this.assinatura.id })
				.then(estoque => {
					this.estoque_medicamentos = estoque;
				})
				.catch(() => {
					this.estoque_medicamentos = [];
				})
				.finally(() => {
					this.carrega_estoque = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
