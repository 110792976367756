import $http from '@Config/axios';
import $store from '@Store';
import { DefaultPresenter } from './default-presenter';

const prefix = 'arquivo';
const entity = 'Arquivo';

export const ArquivoPresenter = {
	...DefaultPresenter({ prefix, entity }),
	store: formData => new Promise(async resolve => {
		const response = await $http.post(prefix, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
		$store.dispatch('SHOW_SNACKBAR', { message: `${entity} salvo(a) com sucesso!` });
		resolve(response);
	}),
	categorias: () => $http.get('arquivos/categorias')
};
