<template>
	<v-card style="overflow: hidden">
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" style="font-size: 14px">fas fa-pills</v-icon>
			</v-avatar>
			<h3>Estoque</h3>
			<v-spacer />
			<v-btn
				v-if="!carregando_estoque && ($store.getters.eh_administrador || $store.getters.eh_rt)"
				@click="dialog_adicionar_estoque = true"
				depressed
				small
				class="purple white--text">Adicionar ao estoque
			</v-btn>
		</v-card-title>
		<v-progress-circular v-if="carregando_estoque" indeterminate color="grey" />
		<v-card-text class="pa-0" v-show="!carregando_estoque">
			<v-tabs color="purple" dark centered slider-color="white">
				<v-tab>Atual</v-tab>
				<v-tab-item>
					<v-text-field
						v-model="busca_estoque"
						append-icon="search"
						placeholder="Buscar medicamento"
						class="ma-0 pa-0"
						hide-details/>
					<v-card flat>
						<ListaEstoque
							ref="estoque"
							:paciente_id="paciente.id"
							:busca="busca_estoque"
							:carregando.sync="carregando_estoque"
							@estoque-atualizado="atualizarEstoque"
							showStoreOutputButton/>
					</v-card>
				</v-tab-item>
				<v-tab>Histórico</v-tab>
				<v-tab-item>
					<v-card flat>
						<ListaHistoricoEstoque
							ref="historicoestoque"
							:paciente_id="paciente.id"
							:carregando.sync="carregando_estoque"/>
					</v-card>
				</v-tab-item>
			</v-tabs>
		</v-card-text>

		<!-- Adicionar estoque dialog -->
		<v-dialog
			persistent
			v-model="dialog_adicionar_estoque"
			max-width="920px"
			scrollable
			transition="fade">
			<transition>
				<DialogAdicionarEstoque
					v-if="dialog_adicionar_estoque"
					:paciente="paciente"
					:close.sync="dialog_adicionar_estoque"
					@estoque-atualizado="atualizarEstoque"/>
			</transition>
		</v-dialog>
	</v-card>
</template>

<script>
import DialogAdicionarEstoque from '@Componentes/pacientes/paciente/dialog-adicionar-estoque';
import ListaEstoque from './lista-estoque';
import ListaHistoricoEstoque from './lista-historico-estoque';

export default {
	name: 'InfoEstoque',
	components: {
		ListaEstoque,
		DialogAdicionarEstoque,
		ListaHistoricoEstoque
	},
	props: {
		paciente: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		carregando_estoque: true,
		busca_estoque: '',
		dialog_adicionar_estoque: false
	}),
	methods: {
		atualizarEstoque() {
			this.$refs.estoque.getEstoque();
			this.$refs.historicoestoque.getHistoricoEstoque();
		}
	}
};
</script>
