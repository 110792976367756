<template>
	<div>
		<v-data-table :headers="headers" :items="items" :loading="loading" :search="search"
			:rows-per-page-items="[10, 20, 30]" :no-data-text="loading ? 'Carregando...' : error ? 'Erro ao carregar, tente novamente' : 'Nenhum dado'"
			rows-per-page-text="Itens por página">
			<template slot="items" slot-scope="props">
				<tr class="pointer" @click="abrirDialog(props.item, 'dialog_ilpi')">
					<td>{{ props.item.nome }}</td>
					<td>{{ props.item.nome_responsavel || '-' }}</td>
					<td>{{ props.item.telefone_responsavel || '-' }}</td>
					<td>{{ props.item.email || '-' }} </td>
					<td>{{ props.item.__meta__.orcamentos_count || 0 }} </td>
					<td class="buttons">
						<v-tooltip top>
							<v-btn
								small
								icon
								@click.stop="abrirDialog(props.item, 'dialog_ilpi_batch_pdf')"
								slot="activator">
								<img src="@Assets/icons/switch_account.svg"
									alt="Termos de residentes"
									width="30"
									class="pl-1">
							</v-btn>
							<span>Baixar termos de vários residentes</span>
						</v-tooltip>
						<v-tooltip top>
							<v-btn small icon @click.stop="abrirDialog(props.item, 'dialog_enviar_email')" slot="activator">
								<v-icon color="green">fas fa-paper-plane</v-icon>
							</v-btn>
							<span>Enviar email</span>
						</v-tooltip>
						<v-tooltip top>
							<v-btn small icon @click.stop="copiarURL(props.item)" slot="activator">
								<v-icon color="green">fas fa-share</v-icon>
							</v-btn>
							<span>Copiar link</span>
						</v-tooltip>
						<v-tooltip top>
							<v-btn small icon slot="activator" @click.stop="gerarExcelIlpi(props.item)" :disabled="carregando">
								<v-icon color="green">upload_file</v-icon>
							</v-btn>
							<span>Gerar relatório</span>
						</v-tooltip>
					</td>
				</tr>
			</template>
		</v-data-table>

		<v-dialog persistent v-model="dialog_ilpi" scrollable transition="fade">
			<transition>
				<DialogILPI v-if="dialog_ilpi" :casa_repouso="item_selecionado"
					@close="dialog_ilpi = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialog_ilpi_batch_pdf" scrollable transition="fade" min-height="400px">
			<transition>
				<DialogILPIBatchPdf v-if="dialog_ilpi_batch_pdf" :casa_repouso="item_selecionado"
					@close="dialog_ilpi_batch_pdf = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialog_enviar_email" scrollable transition="fade" max-width="400px">
			<transition>
				<Dialog v-if="dialog_enviar_email" titulo="Envio de email" subtitulo="Tem certeza que deseja enviar este email?"
					btn_acao="Sim, enviar" btn_cancelar="Cancelar"
					@acao="enviarEmail('sendMailB2B')" @close="dialog_enviar_email = false" />
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import $store from '@Store';
import DialogILPI from './dialog-ilpi.vue';
import DialogILPIBatchPdf from './dialog-ilpi-batch-pdf.vue';
import mixin from './mixin-data-table';

export default {
	name: 'DataTableILPI',
	components: { DialogILPI, DialogILPIBatchPdf },
	mixins: [mixin],
	data: () => ({
		dialog_ilpi: false,
		dialog_ilpi_batch_pdf: false,
		carregando: false,
		headers: [
			{ text: 'Nome', value: 'nome' },
			{ text: 'Nome do responsável', value: 'nome_responsavel' },
			{ text: 'Telefone do responsável', value: 'Telefone_responsavel' },
			{ text: 'Email', value: 'email' },
			{ text: 'Quantidade de orçamentos', value: 'prazo_pagamento' },
			{ text: '', sortable: false }
		]
	}),
	methods: {
		gerarExcelIlpi(item) {
			if (item.__meta__.orcamentos_count > 0) {
				return new Promise(async (resolve, reject) => {
					this.carregando = true;
					try {
						const promises = [CasaRepousoPresenter.orcamentos(item.id), import('@Util/excel-orcamento-ilpi')];
						const responses = await Promise.all(promises);
						const { geraExcelDeOrcamentosIlpi } = responses[1];
						geraExcelDeOrcamentosIlpi(responses[0], item);
					} catch (erro) {
						reject();
					}
					this.carregando = false;
					resolve();
				});
			}
			return $store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'ILPI sem orçamentos' });
		}
	}
};
</script>
