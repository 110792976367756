import { companyUnitAbbr } from '@Consts/unidades';
import unleash from '@Util/unleash';

export const unidades = [
	{
		key: 'BH',
		nome: 'Belo Horizonte',
		id: 1
	},
	{
		key: 'SP',
		nome: 'São Paulo',
		id: 2
	}
];

export const localStorageUnidadeKey = 'unidade';

export const getCurrentCompanyUnit = () => {
	const companyUnitId = localStorage.getItem(localStorageUnidadeKey);
	return unidades.find(u => u.id === Number(companyUnitId));
};

export const setCurrentCompanyUnit = id => {
	localStorage.setItem(localStorageUnidadeKey, id);
	unleash.setContextField('companyUnitName', companyUnitAbbr[id]);
};
