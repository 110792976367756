export const availabilityKeys = Object.freeze({
	AVAILABLE: 'AVAILABLE',
	UNAVAILABLE: 'UNAVAILABLE',
	DISCONTINUED: 'DISCONTINUED'
});
export const availabilities = Object.freeze({
	AVAILABLE: 'Disponível',
	UNAVAILABLE: 'Indisponível',
	DISCONTINUED: 'Descontinuado'
});

export function getAvailabilityColorStatus(availability) {
	switch (availability) {
		case availabilityKeys.AVAILABLE:
			return 'success';
		case availabilityKeys.UNAVAILABLE:
			return 'red';
		case availabilityKeys.DISCONTINUED:
			return '#000';
		default:
			return 'gray';
	}
}

export function getAvailabilityColorDescription(availability) {
	switch (availability) {
		case availabilityKeys.AVAILABLE:
			return 'Disponível';
		case [availabilityKeys.UNAVAILABLE, availabilityKeys.DISCONTINUED].includes(availability):
			return 'Em falta';
		default:
			return 'Sujeito a disponibilidade';
	}
}
