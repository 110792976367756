<template>
	<section class="choice-item py-5">
		<div v-if="$vuetify.breakpoint.mdAndUp" class="header mb-4">
			<img width="70px" :src="qrCode" alt="logo-farme">
		</div>
		<div class="body">
			<p class="ma-0 font-weight-regular title">Ler QR Code da Box</p>
			<section
				v-if="scannerOpened"
				style="max-width: 300px;"
				class="mx-auto my-3">
				<CameraCodeScanner
					@scan="onScan" />
			</section>
			<v-btn
				v-else
				large
				outline
				color="secondary"
				class="mt-5"
				style="border-radius: 10px; border: 2px solid black;font-weight: bold!important;"
				@click="() => scannerOpened = true">
				Abrir leitor
			</v-btn>
		</div>
	</section>
</template>

<script>
import qrCode from '@Assets/icons/qrcode.svg';
import { CameraCodeScanner } from 'vue-barcode-qrcode-scanner';

export default {
	components: {
		CameraCodeScanner
	},
	data() {
		return {
			qrCode,
			scannerOpened: false
		};
	},
	computed: {
		getText() {
			return this.$vuetify.breakpoint.mdAndUp ? 'Número da assinatura' : 'Digite o número da assinatura para começar a produzir';
		}
	},
	methods: {
		onScan({ raw }) {
			const value = this.getBoxIdentifier(raw.text);
			this.$emit('search', value);
			this.scannerOpened = false;
		},
		getBoxIdentifier(value) {
			const arr = value.split('/');
			const identifier = arr.pop();
			return identifier;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
.choice-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	width: 100%;
	background-color: rgba(255, 235, 204, 1);
	border-radius: 55px;
	text-align: center;

	.header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100px !important;
		height: 100px !important;
		background-color: $primary-light;
		border-radius: 50%;
	}

	.body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.input-error {
		border: 2px solid red!important;
	}

	@media (max-width: 960px)  {
		border-radius: 40px 40px 0 0;
	}
}
</style>
