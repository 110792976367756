<template>
	<div class="usuarios-wrapper px-md-5">
		<HeaderUsuarios ref="headerUsuarios" :carregando="is_loading" @busca="b => busca = b"/>

		<v-progress-linear v-if="is_loading" indeterminate class="ma-0" color="purple" height="3" />

		<div>
			<transition name="fade">
				<v-container v-if="!is_loading" fluid grid-list-xl class="container-wrapper pa-0">
					<v-layout wrap class="layout-wrapper">
						<v-flex>
							<NenhumUsuario v-if="!!!items || items.length === 0" />

							<v-layout wrap row :class="usuarios_paginados.length < 4 ? 'layout-box-wrapper' : null">
								<v-flex xs12 sm6 md4 v-for="user in usuarios_paginados" :key="user.id">
									<UsuarioCard v-ripple :usuario="user" @click.native="() => onUsuarioSelecionado(user)" />
								</v-flex>

								<v-flex xs12 class="text-xs-center mt-4" v-if="contadorPagina > 1">
									<v-pagination circle v-model="pagina" color="purple" :length="contadorPagina"
										:total-visible="6" />
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-container>
			</transition>
		</div>

		<v-dialog persistent scrollable v-model="dialog_crud" transition="fade" max-width="1070px">
			<transition>
				<DialogUsuario v-if="dialog_crud" :item_pai="item_selecionado" :close.sync="dialog_crud"
					@inserir="salvo" />
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { ListagemMixin } from '@Mixins/listagem';
import { debounce } from 'lodash';
import HeaderUsuarios from './header-usuarios.vue';
import UsuarioCard from './usuario-card.vue';
import NenhumUsuario from './nenhum-usuario.vue';
import DialogUsuario from './dialog-usuario';

export default {
	name: 'Usuarios',
	mixins: [ListagemMixin],
	components: {
		HeaderUsuarios, UsuarioCard, NenhumUsuario, DialogUsuario
	},
	data: () => ({
		type: 'user',
		pagina: 1,
		itens_por_pagina: 12
	}),
	watch: {
		busca() {
			this.pagina = 1;
			this.pesquisar(this);
		}
	},
	computed: {
		contadorPagina() {
			const l = this.usuarios_buscados.length;
			const s = this.itens_por_pagina;
			return Math.ceil(l / s);
		},
		usuarios_paginados() {
			const start = (this.pagina - 1) * this.itens_por_pagina;
			const end = start + this.itens_por_pagina;
			return this.usuarios_buscados.slice(start, end);
		},
		usuarios_buscados() {
			return this.items.filter(usuario => !this.busca
        || this.$functions.clearQuery(usuario.nome).indexOf(this.$functions.clearQuery(this.busca)) > -1
        || this.$functions.clearQuery(usuario.email).indexOf(this.$functions.clearQuery(this.busca)) > -1);
		}
	},
	methods: {
		pesquisar: debounce(self => {
			self.index();
		}, 500),
		onUsuarioSelecionado(user) {
			this.item_selecionado = user;
			this.dialog_crud = true;
		},
		onResponse(response) {
			this.items = response;
		},
		afterSave() {
			this.index();
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.usuarios-wrapper {
  min-height: calc(100vh - 106px);

  .container-wrapper {
    min-height: calc(100vh - 291px);

    .layout-wrapper {
      min-height: calc(100vh - 315px);

      .layout-box-wrapper {
        justify-content: center;
      }
    }
  }
}
</style>
