<template>
	<ProductionV2 v-if="newVersion" />
	<ProductionV1 v-else />
</template>

<script>
import { featureFlagsMixin } from '@Mixins/feature-flag';
import { featureFlags } from '@Consts/feature-flags';
import ProductionV1 from './v1';
import ProductionV2 from './v2/list-production/index.vue';

export default {
	components: {
		ProductionV1,
		ProductionV2
	},
	mixins: [
		featureFlagsMixin
	],
	computed: {
		newVersion() {
			return this.isFeatureEnabled(featureFlags.enable_farme_new_production_process);
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
