export const getState = () => ({
	loading: false,
	assinatura: {
		posicao: '',
		paciente: {},
		responsavel: {},
		endereco: {},
		medicamentos: [],
		receitas: [],
		sem_box: false
	},
	historico_boxes: [],
	data_proxima_box: null
});
