export const typesOfRegister = Object.freeze({
	CRO: 'CRO',
	CRM: 'CRM',
	CRMV: 'CRMV',
	RMS: 'RMS'
});

export const typesOfRegisterData = Object.freeze({
	[typesOfRegister.CRO]: { color: '#A4D7DF', description: 'Conselho Regional de Odontologia' },
	[typesOfRegister.CRM]: { color: '#BDD183', description: 'Conselho Regional de Medicina' },
	[typesOfRegister.CRMV]: { color: '#E7E7E7', description: 'Conselho Regional de Medicina Veterinária' },
	[typesOfRegister.RMS]: { color: '#E7E7E7', description: 'Registro do Ministério da Saúde' }
});
