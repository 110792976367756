<template>
	<div class="dialog-header">
		<div class="dialog-header-content">
			<div class="dialog-title">
				<h1 class="titulo">{{ title }}</h1>
			</div>

			<v-tabs
				v-if="tabs.length"
				@change="value => $emit('tabChanged', value)"
				left
				height="50px"
				color="transparent"
				active-class="primary">

				<v-tab
					v-for="(tab, index) of tabs"
					:key="index"

					:href="`#${tab.id}`"
					style="background-color: #FFDEAF;">
					<div class="px-4">
						<v-avatar size="30" color="#342B1D" style="margin-right: 20px;">
							<span class="white--text">{{ ++index }}</span>
						</v-avatar>
						<strong>
							{{ tab.name }}
						</strong>
					</div>
				</v-tab>
			</v-tabs>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RestHomeDialogHeader',
	props: {
		title: {
			required: true,
			type: String
		},
		tabSelected: {
			required: true
		},
		tabs: {
			required: false,
			type: Array,
			default: () => []
		}
	}
};
</script>

<style lang="scss" scoped>
.primary-light {
	background-color: red !important;
}
.tab-active {
	background:red !important;
}
.dialog {
	position: relative;
	width: 100%;
	height: 100%;
	height: 100vh;
	overflow-y: scroll;
	background-color: #FFEACC;
}

.dialog-close {
	position: absolute;
	top: 40px;
	right: 20px;
}

.dialog-header {
	height: 215px;
	background-color: rgba(255, 200, 117, 0.5);
}

.dialog-header-content {
	display: flex;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	min-height: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
}

.dialog-title {
	padding-top: 45px;
	font-size: 28px;
}

</style>
