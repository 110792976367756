<template>
	<Dialog
		titulo="Conferencia Digital"
		subtitulo="Caso não tenha acontecido algo, apenas clique em salvar."
		btn_acao="Salvar"
		btn_cancelar="Cancelar"
		@acao="openSaveDialog()"
		@close="closeModal()"
		:loading="loading">
		<!-- Dialog Ocorrências -->
		<v-dialog persistent scrollable v-model="dialog_ocorrencias" transition="fade" max-width="700px">
			<transition>
				<DialogOcorrencias
					v-if="dialog_ocorrencias"
					:box_id="box.id"
					:request="storeAndClose"
					status="CHECKED"
					:close.sync="dialog_ocorrencias"/>
			</transition>
		</v-dialog>
		<v-layout wrap>
			<v-flex xs3>
				<v-navigation-drawer width="290">
					<v-layout class="date-picker">
						<v-date-picker
							locale="pt-br"
							:no-title=true
							:events="functionEvents"
							:show-current=false
							v-model="date"
							:allowed-dates="allowedDates"
							:max="maxData()"
							:min="minData()"/>
					</v-layout>
					<v-divider class="div-divider"></v-divider>
					<v-layout wrap style="margin: 10px">
						<v-flex xs3>
							<div class="suggestion_box_1"></div>
						</v-flex>
						<h4 style="margin-left: 10px">Visualização atual</h4>
					</v-layout>
					<v-layout wrap style="margin: 10px">
						<v-flex xs3>
							<div class="suggestion_box_3"></div>
						</v-flex>
						<h4 style="margin-left: 10px">Falta Imagem para horário</h4>
					</v-layout>
					<v-layout wrap style="margin: 10px">
						<v-flex xs3>
							<div class="suggestion_box_2"></div>
						</v-flex>
						<h4 style="margin-left: 10px">IA encontrou problemas</h4>
					</v-layout>
					<v-layout wrap style="margin: 10px">
						<v-flex xs3>
							<div class="suggestion_box_5"></div>
						</v-flex>
						<h4 style="margin-left: 10px">Todos horários aprovados!</h4>
					</v-layout>
					<v-layout wrap style="margin: 10px">
						<v-flex class="suggestion_box_4" xs3>
							<img src="@Assets/icons/left_arrow.svg" style="margin: auto; width: 50%"/>
							<img src="@Assets/icons/right_arrow.svg" style="margin: auto; width: 50%"/>
						</v-flex>
						<v-flex xs9>
							<h4 style="margin-left: 10px">Use as setas do teclado para navegar entre os dias</h4>
						</v-flex>
					</v-layout>
				</v-navigation-drawer>
			</v-flex>
			<v-flex xs9 v-if="!loading && date">
				<SacheCard
					v-for="(sache, index) in this.date_saches[this.date]"
					:name="get_trimmed_name()"
					:sache="sache"
					:key="index"/>
			</v-flex>
		</v-layout>
	</Dialog>
</template>

<script>
import DialogOcorrencias from '@Componentes/dialog-ocorrencias';
import { SachePresenter } from '@Presenter/sache-presenter';

import $http from '@Config/farme-ai';

import SacheCard from './sache/card';

export default {
	name: 'DialogConferenciaDigital',
	components: {
		SacheCard, DialogOcorrencias
	},
	props: {
		box: {
			type: Object,
			required: true
		},
		box_id: {
			type: Number,
			required: true
		},
		request: {
			type: Function,
			required: true
		}
	},
	data: () => ({
		loading: true,
		dialog_ocorrencias: false,
		saches: [],
		date_saches: [],
		selected: null,
		date: null
	}),
	async created() {
		try {
			const sacheList = await SachePresenter.show(this.box_id);

			if (sacheList.length === 0) {
				this.closeModal();
				return;
			}

			const parsedSacheList = sacheList.map(sache => this.parse_sache(sache));
			const checkResponse = await $http.post('/check', {
				box_id: this.box_id,
				schedules: parsedSacheList
			});

			sacheList.forEach(sache => {
				sache.images = checkResponse[sache.id];
			});

			this.saches = sacheList;
			this.date_saches = this.saches.reduce((acc, sache) => {
				const date = sache.scheduled_date.split(' ')[0];
				if (!acc[date])
					acc[date] = [];
				acc[date].push(sache);
				return acc;
			}, {});

			this.date = this.minData();
		} catch (error) {
			this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Essa box ainda não está disponível!' });
			this.closeModal();
		} finally {
			this.loading = false;
		}
	},
	methods: {
		checkAllSuccess(saches) {
			return saches.every(
				sache => sache.images
					&& sache.images.length > 0
					&& sache.images.every(image => image.ai_response.result === true)
			);
		},
		checkSomeSuccess(saches) {
			return saches.some(
				sache => sache.images
					&& sache.images.length > 0
					&& sache.images.every(image => image.ai_response.result === true)
			);
		},
		checkError(saches) {
			return saches.some(
				sache => sache.images
					&& sache.images.length > 0
					&& sache.images.some(image => image.ai_response.result === false)
			);
		},
		checkMissingImage(saches) {
			return saches.some(
				sache => sache.images
					&& sache.images.length === 0
			);
		},
		functionEvents(date) {
			if (!this.date_saches[date])
				return false;
			const ret = [];

			if (this.checkAllSuccess(this.date_saches[date]) || this.checkSomeSuccess(this.date_saches[date]))
				ret.push('success');
			if (this.checkMissingImage(this.date_saches[date]))
				ret.push('warning');
			if (this.checkError(this.date_saches[date]))
				ret.push('error');
			return ret;
		},
		parse_sache(sache) {
			return {
				id: sache.id,
				scheduled_at: sache.scheduled_at,
				scheduled_label: sache.scheduled_label,
				scheduled_date: sache.scheduled_date,
				scheduled_time: sache.scheduled_time,
				scheduled_medicines: sache.sache_medicamentos.filter(sm => sm.quantidade % 1 === 0).map(sm => ({
					id: sm.box_medicamento.medicamento.id,
					name: `${ sm.box_medicamento.medicamento.produto } ${ sm.box_medicamento.medicamento.dosagem }`,
					out_of_box: sm.box_medicamento.medicamento.fora_da_box,
					quantity: sm.quantidade
				}))
			};
		},
		openSaveDialog() {
			this.loading = true;
			this.dialog_ocorrencias = true;
			// this.fecharModal();
			this.loading = false;
		},
		storeAndClose() {
			this.loading = true;
			this.request()
				.then(() => {
					this.closeModal();
				})
				.finally(() => {
					this.loading = false;
				});
		},
		closeModal() {
			this.$emit('update:close', false);
		},
		allowedDates(val) {
			return Object.keys(this.date_saches).includes(val);
		},
		minData() {
			return Object.keys(this.date_saches)[0];
		},
		maxData() {
			return Object.keys(this.date_saches)[Object.keys(this.date_saches).length - 1];
		},
		get_name() {
			return this.box.paciente.nome;
		},
		get_trimmed_name() {
			const name = this.get_name();
			const trimmedName = name.length > 26 ? name.substring(0, name.lastIndexOf(' ', 25)) : name;
			return trimmedName.trim();
		},
		moveDate(days) {
			const dates = Object.keys(this.date_saches);
			const currentIndex = dates.indexOf(this.date);
			let newIndex = currentIndex + days;
			if (newIndex >= dates.length)
				newIndex = dates.length - 1; // Clamp to the end of the array
			else if (newIndex < 0)
				newIndex = 0; // Clamp to the start of the array

			this.date = dates[newIndex];
		},

		handleKeydown(event) {
			if (this.loading || this.dialog_ocorrencias)
				return;

			if (event.key === 'ArrowRight')
				this.moveDate(1);

			if (event.key === 'ArrowLeft')
				this.moveDate(-1);

			if (event.key === 'ArrowUp')
				this.moveDate(-7);

			if (event.key === 'ArrowDown')
				this.moveDate(7);

			if (event.key === 'Escape')
				this.closeModal();

			if (event.key === 'Enter')
				this.openSaveDialog();
		}
	},
	mounted() {
		window.addEventListener('keydown', this.handleKeydown);
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.handleKeydown);
	},
	computed: {}
};
</script>
<style lang="scss" scoped>
@import "@Assets/css/global.scss";
@import "@Assets/css/spacing-responsive.scss";
@import "@Assets/css/settings.scss";

.date-picker {
	background-color: $primary-light;
	border-radius: unset;
	box-shadow: unset;
}

.date-picker__body {
	background-color: $primary-light;
	border-radius: unset;
	box-shadow: unset;
}

::v-deep .v-card {
	box-shadow: none;
}
::v-deep .theme--light.v-picker__body {
	background-color: $primary-light;
	border-radius: unset;
	box-shadow: none;
}

.suggestion_box_1 {
	background-color: $info;
	min-height: 100%;
	border-radius: 15px;
}

.suggestion_box_2 {
	background-color: $error;
	min-height: 100%;
	border-radius: 15px;
}

.suggestion_box_3 {
	background-color: $warning;
	min-height: 100%;
	border-radius: 15px;
}

.suggestion_box_4 {
	align-self: center;
	min-height: 100%;
	border-radius: 15px;
}

.suggestion_box_5 {
	background-color: $success;
	min-height: 100%;
	border-radius: 15px;
}

.div-divider{
	border-color: $primary;
	border-width: 2px;
	margin: 20px;
}
</style>
