<template>
	<v-card style="width: 100%;" color="#FFEACC">
		<v-card-title class="pb-0">
			<span class="title">Histórico de status</span>
			<v-spacer />
		</v-card-title>
		<v-card-text>
			<v-data-table disable-initial-sort :headers="headers" :items="items"
				:pagination.sync="pagination" no-data-text="Não há histórico">
				<template v-slot:items="props">
					<tr class="pointer" >
						<!-- <td>{{ props.item.user }}</td> -->
						<td class="text-xs-center">{{ props.item.date | formatDate('DD/MM/YYYY - HH:mm:ss') }}</td>
						<td class="text-xs-center">
							<v-chip disabled :class="$functions.getStatusOrcamento(props.item.status).class"
								class="white--text px-3 text-uppercase caption text-bold">
								{{ $functions.getStatusOrcamento( props.item.status).label }}
							</v-chip>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'QuotationHistory',
	props: {
		items: {
			required: false,
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		headers: [
			// { text: 'Usuário', value: 'user' },
			{ text: 'Data', value: 'date', align: 'center' },
			{
				text: 'Status', value: 'status', align: 'center', sortable: false
			}
		],
		pagination: { descending: true, sortBy: 'status' }
	})
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.title {
	font-weight: 600;
	font-size: 13px;
}

.transparent-bg >>> .v-data-table, .transparent-bg {
	background-color: transparent !important;
  }
</style>
