<template>
	<Dialog titulo="Confirmação" subtitulo="Tenha certeza que cumpriu os passos abaixo antes de continuar" btn_acao="Confirmar"
		:loading="loading" @acao="confirmar" @close="close" :btn_acao_disable="disableActionButton">
		<p>Confirmo que realizei a expedição do pedido</p>
	</Dialog>
</template>

<script>
import { ProcessTypes } from '@Consts/status-order-process';
import { validateProcessIsAllowed } from '@Util/order-process';

export default {
	name: 'DialogDispatchOrder',
	props: {
		request: {
			type: Function,
			required: true
		},
		orderId: {
			type: Number
		}
	},
	data: () => ({
		loading: false,
		disableActionButton: false
	}),
	methods: {
		confirmar() {
			this.loading = true;
			validateProcessIsAllowed(this.orderId, ProcessTypes.DISPATCH)
				.then(isValid => {
					if (isValid) {
						this.request();
						this.close();
					} else
						this.disableActionButton = !isValid;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>
