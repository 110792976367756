<template>
	<div class="responsaveis-wrapper px-md-5">
		<!-- Responsavel Header -->
		<HeaderResponsavel ref="headerResponsavel" :carregando="is_loading" @novo-responsavel="crud"
			@busca="b => busca = b"	@filtro="f => filtro = f" />

		<!-- Estado is_loading -->
		<v-progress-linear v-if="is_loading" indeterminate class="ma-0" color="purple" height="3" />

		<div>
			<!-- Conteúdo, após carregado -->
			<transition name="fade">
				<!-- container-wrapper -->
				<v-container v-if="!is_loading" fluid grid-list-xl class="container-wrapper pa-0">
					<!-- layout-wrapper -->
					<v-layout wrap class="layout-wrapper">
						<v-flex>
							<!-- Mensagem nenhum responsavel cadastrado -->
							<NenhumResponsavel v-if="!!!responsaveis || responsaveis.length === 0" />

							<!-- Lista de responsaveis -->
							<v-layout v-else wrap row :class="responsaveis.length < 4 ? 'layout-box-wrapper' : null">
								<v-flex xs12 sm6 md4 v-for="responsavel in responsaveis" :key="responsavel.id">
									<ResponsavelCard v-ripple :responsavel="responsavel" @click.native="() => onResponsavelSelecionado(responsavel)" />
								</v-flex>

								<v-flex xs12 class="text-xs-center mt-4" v-if="paginas > 1">
									<v-pagination circle v-model="pagina_atual" color="purple" :length="paginas" @input="novaPagina"
										:total-visible="6" />
								</v-flex>
							</v-layout><!-- Fim lista de responsaveis -->
						</v-flex>
					</v-layout><!-- Fim, layout-wrapper -->
				</v-container><!-- Fim, container-wrapper -->
			</transition><!-- Fim, conteúdo após carregado -->
		</div>

		<!-- Dialog responsavel -->
		<v-dialog persistent scrollable v-model="dialog_crud" transition="fade" max-width="1070px">
			<transition>
				<DialogResponsavel v-if="dialog_crud" :item_pai="item_selecionado" :close.sync="dialog_crud"
					@inserir="salvo" @excluir="excluido" />
			</transition>
		</v-dialog> <!-- Fim, Dialog responsavel -->
	</div><!-- Fim, responsaveis-wrapper  -->
</template>

<script>
import { ListagemMixin } from '@Mixins/listagem';
import { ResponsavelPresenter } from '@Presenter/responsavel-presenter';
import { debounce } from 'lodash';
import DialogResponsavel from './dialog-responsavel';
import HeaderResponsavel from './header-responsavel';
import NenhumResponsavel from './nenhum-responsavel';
import ResponsavelCard from './responsavel-card';

export default {
	name: 'Responsaveis',
	mixins: [ListagemMixin],
	components: {
		HeaderResponsavel, NenhumResponsavel, ResponsavelCard, DialogResponsavel
	},

	data: () => ({
		filtro: 'ativo',
		type: 'responsavel',
		responsaveis: [],
		request: ResponsavelPresenter.list,
		paginas: 0,
		pagina_atual: 1,
		total: 0,
		itens_por_pagina: 12
	}),
	watch: {
		filtro() {
			this.pagina_atual = 1;
			this.index();
		},
		busca() {
			this.pagina_atual = 1;
			this.pesquisar(this);
		}
	},
	methods: {
		novaPagina(pagina) {
			this.pagina_atual = pagina;
			this.index();
		},
		pesquisar: debounce(self => {
			self.index();
		}, 500),
		onResponsavelSelecionado(responsavel) {
			const { id } = responsavel;
			this.$router.push({ name: 'Responsavel', params: { id } });
		},
		getIndexProps() {
			return {
				q: this.busca ? this.busca : null,
				status: this.filtro,
				pagina_atual: this.pagina_atual,
				itens_por_pagina: this.itens_por_pagina
			};
		},
		onResponse(response) {
			this.responsaveis = response.itens;
			this.paginas = response.paginas;
			this.total = response.total;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.responsaveis-wrapper {
  min-height: calc(100vh - 106px);

  .container-wrapper {
    min-height: calc(100vh - 291px);

    .layout-wrapper {
      min-height: calc(100vh - 315px);

      .layout-box-wrapper {
        justify-content: center;
      }
    }
  }
}
</style>
