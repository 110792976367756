<template>
	<v-dialog
		value="true"
		persistent
		scrollable
		max-width="500px">
		<Dialog
			v-if="!confirmDialog.opened"
			titulo="Editar porcentagem de desconto"
			@acao="openDialogToConfirm"
			btn_acao="Confirmar"
			@close="$emit('close')"
			background="rgba(255, 234, 204, 1)"
			backgroundFooter="rgba(255, 234, 204, 1)">

			<v-container grid-list-md>
				<v-layout wrap>
					<v-flex xs12>
						<v-text-field
							disabled
							background-color="white"
							label="Percentual máximo de desconto"
							:value="maxDiscountPercentage"
							append-icon="percent"
							box
							data-vv-name="Percentual máximo de desconto"/>
					</v-flex>

					<v-flex xs12>
						<v-text-field
							type="number"
							min="0"
							:max="maxDiscountPercentage"
							background-color="white"
							v-model.number="discount.discountPercentage"
							box
							label="Percentual de desconto"
							data-vv-name="Percentual de desconto"
							v-validate="{ required: true, gte: 0, lte: maxDiscountPercentage }"
							append-icon="percent"
							:error-messages="errors.collect('Percentual de desconto')"/>
					</v-flex>

					<v-flex>
						<TextDateField
							background-color="white"
							label="Data limite"
							placeholder="dd/mm/yyyy"
							hint="Essa data será utilizada para filtrar assinaturas que foram criadas até a data limite."
							persistent-hint
							box
							v-model="discount.limitDate"
							data-vv-name="Data de inicio"
							:validate="{ required: true, data: true }"
							:error-messages="errors.collect('Data de inicio')"/>

					</v-flex>
				</v-layout>
			</v-container>
		</Dialog>

		<Dialog
			v-if="confirmDialog.opened"
			titulo="Atenção"
			btn_acao="Confirmar"
			btn_acao_class="primary"
			btn_cancelar="Cancelar"
			:btn_acao_disable="!confirmDialog.confirmed"
			@acao="submit"
			@close="confirmDialog.opened = false">

			<div class="" style="max-width: 480px; margin: 0 auto;">
				<h2 class="mb-4">Ao prosseguir, atente-se para:</h2>
				<p>
					Os descontos dos medicamentos de assinaturas e orçamentos vinculadas a esta ILPI serão atualizados.
				</p>
				<v-checkbox
					primary
					hide-details
					:input-value="confirmDialog.confirmed"
					@click.stop="confirmDialog.confirmed = !confirmDialog.confirmed"
					label="Declaro estar ciente que essa alteração não poderá ser desfeita."/>
			</div>
		</Dialog>
	</v-dialog>
</template>

<script>
export default {
	name: 'UpdateDiscountRestHomeDialog',
	$_veeValidate: { validator: 'new' },
	props: {
		maxDiscountPercentage: {
			type: Number,
			required: true
		},
		discount: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			confirmDialog: {
				opened: false,
				confirmed: false
			}
		};
	},
	methods: {
		async openDialogToConfirm() {
			const stepValidated = await this.$validator.validateAll();
			if (!stepValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Dados inválidos',
					color: 'error'
				});
				return;
			}
			this.confirmDialog.opened = true;
		},
		async submit() {
			const data = {
				discountPercentage: this.discount.discountPercentage,
				limitDate: this.$functions.FormatDateToRequest(this.discount.limitDate)
			};
			this.$emit('updateDiscount', data);
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
