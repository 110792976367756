<template>
	<v-container>
		<v-layout wrap v-for="(dado) in dados" :key="dado.filial_id" xs12>
			<v-flex xs12>
				<h1 class="text--text" style="opacity: .8">{{ getUnidade(dado.filial_id) }}</h1>
			</v-flex>
			<v-flex sm12 md4>
				<v-text-field color="green" label="Preço *" v-model="dado.preco" box required
					s12 md4 v-money="true" />
			</v-flex>
			<v-flex sm12 md4>
				<v-text-field sm12 md4 :label="`Preço PBM${item.pbm ? ' *' : ''}`" box color="green"
					v-model="dado.preco_pbm" :disabled="!item.pbm" :required="item.pbm" v-money="true" />
			</v-flex>
			<v-flex sm12 md4>
				<v-select label="Disponibilidade *" :items="disponivel" v-model="dado.status" box sm12
					md4/>
			</v-flex>
			<v-flex sm12 md8>
				<v-text-field sm12 md4 label="Benefício PBM" box color="green"
					v-model="dado.beneficio_pbm" :disabled="!item.pbm" :required="item.pbm"/>
			</v-flex>
		</v-layout>
	</v-container>
</template>
<script>
import { unidades } from '@Config/unidades';
import disponibilidade from '@Consts/medicamento-status';

export default {
	name: 'PrecoMedicamento',
	props: {
		item: {
			type: Object,
			default: () => ({})
		}
	},
	data: () => ({
		disponivel: [],
		dados: []
	}),
	mounted() {
		this.disponivel = Object.keys(disponibilidade).map(id => ({ value: id, text: disponibilidade[id] }));
		this.dados = unidades.map(unidade => {
			const preco = this.item.precos.find(p => p.filial_id === unidade.id);
			const status = this.item.status.find(s => s.filial_id === unidade.id);

			return {
				filial_id: unidade.id,
				beneficio_pbm: preco?.beneficio_pbm,
				preco: preco?.preco,
				preco_pbm: preco?.preco_pbm,
				status: String(status?.status || 0)
			};
		});
	},
	methods: {
		getUnidade(id) {
			return unidades.find(unidade => unidade.id === id).nome || '';
		}
	}
};
</script>

<style>
</style>
