<template>
	<v-flex xs3>
		<v-layout column wrap>
			<v-flex xs12>
				<p class="mb-1 inter text-bold-medium titleOpacity">Horários</p>
				<div v-if="havePosology">
					<p v-for="(schedule, index) in posology.schedules" :key="index" class="mb-0 text-bold body-1">
						<v-icon small color="#000" class="pr-1">far fa-clock</v-icon>
						{{textSchedules(schedule)}}
					</p>
				</div>
				<p v-if="item.quantityOfBoxes" class="mb-0 text-bold body-1">
					<v-icon small color="#000" class="pr-1">inventory_2</v-icon>
					{{textOutOfBox()}}
				</p>
				<p v-if="havePosology && posology.frequency" class="mb-0 text-bold body-1 long-string">
					<v-icon small color="#000" class="pr-1">far fa-calendar-alt</v-icon>
					{{textFrequency()}}
				</p>
			</v-flex>
			<v-flex v-if="showPendingPosology" xs12 class="stylePendingPosology mt-1 mr-1 d-flex items-center">
				<v-icon small color="#FFF" class="pr-1">fas fa-exclamation-triangle</v-icon>
				<span class="textPendingPosology sans text-bold caption">Pendente posologia</span>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
import { posologyTypes } from '@Consts/quotation-v2';
import { getPropertyValue } from '@Util/ProductService';

export default {
	name: 'SchedulesItem',
	props: {
		posology: {
			type: Object,
			default: () => {}
		},
		item: {
			type: Object,
			default: () => {}
		}
	},
	data: () => ({
		posologyTypes
	}),
	computed: {
		havePosology() {
			return this.posology !== null;
		},
		isOutOfBox() {
			return this.isMedicine ? getPropertyValue(this.item.drug.properties, 'isOutOfBox') : false;
		},
		isDivisible() {
			return this.isMedicine ? getPropertyValue(this.item.drug.properties, 'isDivisible') : false;
		},
		showPendingPosology() {
			return ((this.havePosology && !this.posology.frequency) || (this.havePosology && !this.posology.schedules.length) || (!this.havePosology && this.isDivisible)) && !this.isOutOfBox;
		},
		isMedicine() {
			return this.item.drug;
		}
	},
	methods: {
		textSchedules(schedule) {
			return `${schedule.quantidade_por_horario} cp. às ${schedule.horario}`;
		},
		textFrequency() {
			switch (this.posology.frequency) {
				case posologyTypes.allDays:
					return 'Todos os dias';
				case posologyTypes.dayYesDayNot:
					return 'Dia sim, dia não';
				case posologyTypes.dayNotDayYes:
					return 'Dia não, dia sim';
				case posologyTypes.monthly:
					return `Dias: ${this.posology.daysOfMonth.map(day => day)}`;
				case posologyTypes.weekly:
					return `Dias: ${this.posology.daysOfWeek.map(day => day)}`;
				default:
					return '';
			}
		},
		textOutOfBox() {
			if (this.item.quantityOfBoxes)
				return `${this.item.quantityOfBoxes} caixa${this.item.quantityOfBoxes === 1 ? '' : 's'}`;
		}
	}
};
</script>

<style lang="scss" scoped>
	.titleOpacity {
		opacity: 0.7;
	}

	.stylePendingPosology {
		background-color: #FF823C;
		border-radius: 5px;
		padding: 5px !important;

		.textPendingPosology {
			color: #fff
		}
	}

	.long-string {
		word-break: break-all;
	}
</style>
