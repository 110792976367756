<template>
	<div class="px-md-5">
		<v-layout wrap justify-end>
			<v-flex grow class="items-center">
				<h1 class="text--text" style="opacity: 0.8">Atualizar Calendário</h1>
			</v-flex>
		</v-layout>
		<div>
			<v-container fluid grid-list-lg class="pa-0">
				<template>
					<input @change="loadFile" type="file" ref="upload" accept="text/csv"  />
					<br/>
					<br/>
					<div v-if="error">{{ error }}</div>
					<div v-if="file && !error">
						<span>Quantidade de linhas: {{ countOfLines }}</span>
					</div>
					<div v-if="nBoxUpdated">Boxes atualizadas: {{ nBoxUpdated }}</div>
					<div v-if="nBoxIgnored">Boxes não encontradas: {{ nBoxIgnored }}</div>
				</template>
				<progress v-if="loading"></progress>
				<v-btn block depressed class="ml-0 green white--text" :loading="loading" @click="atualizarCalendario"
					:disabled="!file">
					<v-icon small class="mr-2">cloud_upload</v-icon>
					Atualizar Calendário
				</v-btn>
			</v-container>
		</div>
	</div>
</template>

<script>
import { CalendarioPresenter } from '@Presenter/calendario-presenter';

export default {
	name: 'Calendario',
	comments: [CalendarioPresenter],
	data: () => ({
		loading: false,
		error: null,
		nBoxUpdated: null,
		nBoxIgnored: null,
		file: null,
		countOfLines: null
	}),
	computed: {

	},
	created() {
	},
	methods: {
		loadFile(input) {
			const self = this;

			this.file = input.target.files[0];
			const reader = new FileReader();

			reader.onload = function (event) {
				const content = event.target.result;
				const lineCounts = content.split('\n').filter(lineContent => lineContent).length;
				self.countOfLines = lineCounts;
			};
			reader.readAsText(this.file);
		},
		atualizarCalendario() {
			this.error = null;
			this.nBoxIgnored = null;
			this.nBoxUpdated = null;
			const formData = new FormData();
			formData.append('calendario', this.file);
			this.loading = true;
			CalendarioPresenter.upload(formData)
				.then(response => {
					this.nBoxUpdated = response.boxSuccessIds.length;
					this.nBoxIgnored = response.boxNotFoundIds.length;

					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Calendário atualizado!' });
				}, response => {
					this.error = response.message;
				}).finally(() => {
					this.loading = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
	@media (min-width: 960px) {
		.v-input--switch {
			justify-content: flex-end;
		}
	}

	.grid {
		gap: 0.5rem;
		display: grid;
		position: relative;
		grid-template-columns: repeat(7, 1fr);

		.v-progress-linear {
			z-index: 2;
			top: -25px;
			position: absolute;
			border-radius: 8px;
		}

		.loading {
			position: absolute;
			inset: 0;
			z-index: 5;
			backdrop-filter: blur(3px);
		}

		.day {
			position: relative;
			min-height: 64px;
			padding: 4px;

			.draggable-container {
				height: 100%;
			}
		}

		.titulo {
			font-size: 10px;
		}

		.draggable {
			cursor: grab;
		}
	}
</style>
