<template>
	<v-card style="width: 100%;">
		<v-card-title class="pb-0">
			<v-flex xs12 md6 style="display: flex; flex-direction: row; align-items: center">
				<v-avatar size="38" class="mr-3">
					<v-icon color="#342B1D" small>fas fa-history</v-icon>
				</v-avatar>
				<h4>Histórico da fatura</h4>
			</v-flex>
			<v-spacer />
		</v-card-title>
		<v-card-text>
			<v-data-table disable-initial-sort :headers="headers" :items="items"
				:pagination.sync="pagination" no-data-text="Não há histórico">
				<template v-slot:items="props">
					<tr>
						<td>{{ props.item.user }}</td>
						<td>{{ props.item.action }}</td>
						<td class="text-xs-center">{{ props.item.created_at | formatDate('DD/MM/YYYY - HH:mm:ss') }}</td>
					</tr>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { statusLabel } from '@Consts/invoices';

export default {
	name: 'History',
	props: {
		items: {
			required: false,
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		headers: [
			{ text: 'Executor', value: 'user' },
			{ text: 'Ação', value: 'action' },
			{ text: 'Data', value: 'created_at', align: 'center' }
		],
		pagination: { descending: true, sortBy: 'created_at' }
	}),
	methods: {
		formatAction(status) {
			return statusLabel[status] || status;
		}
	}
};
</script>
