<template>
	<v-dialog
		:value="true"
		persistent
		width="300">
		<v-card
			color="primary"
			class="pa-6"
			dark>
			<v-card-text class="text-center pa-5">
				<img width="100px" :src="logo" alt="logo-farme">
				<br>
				<h4 class="black--text">Por favor, aguarde!</h4>
				<v-progress-linear
					indeterminate
					color="white"
					class="mb-0"></v-progress-linear>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import logo from '@Assets/images/farme-logo-animada.gif';

export default {
	name: 'LoaderDialog',
	data() {
		return { logo };
	}
};
</script>

<style lang="scss" scoped>

</style>
