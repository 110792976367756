<template>
	<div v-if="isVisible">
		<div v-if="readonly">
			<div
				class="px-3 py-0 items-center space-between"
				:class="[selectedPrescriptionId.length ? 'py-0 noattached' : 'py-2 attached']">

				<div class="py-1 items-center">
					<v-icon class="mr-2">fas fa-file-medical</v-icon>
					<template v-if="selectedPrescriptionId.length">
						<v-chip
							color="#342B1D"
							text-color="#FFF"
							class="pointer"
							v-for="(item, index) of selectedPrescriptionId"
							:key="index"
							@click="showPrescription(item)">
							{{ item }}
						</v-chip>
					</template>
					<template v-else>
						Sem vínculo
					</template>
				</div>
			</div>

			<v-dialog
				v-model="showDetailPrescription"
				persistent
				scrollable
				transition="fade"
				max-width="1024px">
				<transition>
					<Dialog
						v-if="showDetailPrescription"
						:titulo="`Detalhe da Receita ${prescriptionData.id}`"
						btn_acao="Fechar"
						@acao="close"
						@close="close">
						<v-container class="pa-0">
							<template v-if="prescriptionData">
								<v-list>
									<v-list-tile v-if="prescriptionData.prescritor">
										<div>
											<strong>Prescritor: </strong> {{prescriptionData.prescritor.name}} <strong>
												<span
													v-if="prescriptionData.prescritor.typeOfRegister"
													:title="getDescriptionTypeRegistration(prescriptionData.prescritor.typeOfRegister)">
													{{ prescriptionData.prescritor.typeOfRegister }}
												</span>: </strong> {{prescriptionData.prescritor.number}}/{{prescriptionData.prescritor.uf}}
										</div>
									</v-list-tile>
									<v-list-tile>
										Número da notificação: {{prescriptionData.numero_notificacao || '-'}}
									</v-list-tile>
									<v-list-tile>
										Data prescrita:
										<template v-if="prescriptionData.data_prescrita">
											{{ prescriptionData.data_prescrita | formatDate('DD/MM/YYYY') }}
										</template>
										<template v-else>-</template>
									</v-list-tile>
								</v-list>

								<v-card>
									<embed
										v-if="isPdf(prescriptionData.url)"
										:src="prescriptionData.url"
										type="application/pdf"
										width="100%"/>
									<img v-else :src="prescriptionData.url" width="100%" />
								</v-card>
							</template>
						</v-container>
					</Dialog>
				</transition>
			</v-dialog>

		</div>

		<v-layout v-else align-center>
			<v-select
				class="ml-0"
				:class="prescriptionAttachedStyle"
				prepend-inner-icon="fa-file-medical"
				label="Vincular receita"
				item-value="id"
				item-text="name"
				outline
				flat
				solo
				:error="prescriptionAttachmentIsRequired"
				:items="prescriptionOptions"
				:value="selectedPrescriptionId"
				multiple
				small-chips
				@change.self="onChange"
				hide-details>
				<template v-slot:selection="{ item }">
					<v-chip
						color="#342B1D"
						text-color="#FFF">
						<span>{{ item.id }}</span>
					</v-chip>
				</template>
			</v-select>
		</v-layout>
	</div>
</template>
<script>
import { ReceitaPresenter } from '@Presenter/receita-presenter';
import { MedicamentoPresenter } from '@Presenter/medicamento-presenter';
import { debounce } from 'lodash';
import { typesOfRegisterData } from '@Consts/prescriber-register-types';

export default {
	name: 'BotaoReceita',
	props: {
		isVisible: {
			type: Boolean,
			required: false,
			default: false
		},
		readonly: {
			type: Boolean,
			required: false,
			default: true
		},
		isControlled: {
			type: Boolean,
			default: false
		},
		prescriptions: {
			type: Array,
			required: false,
			default: () => []
		},
		boxId: {
			required: false
		},
		medicineId: {
			required: false
		},
		estoqueMedicine: {
			required: false
		},
		medicineIsPaused: {
			required: false,
			type: Boolean,
			default: false
		},
		selectedPrescriptionId: {
			required: false,
			type: Array,
			default: () => []
		},
		neededPillsQuantity: {
			required: false
		}
	},
	data() {
		return {
			showDetailPrescription: false,
			showDetailPrescriptions: false,
			prescriptionData: null,
			prescriptionsData: null,
			selectedPrescriptionsIds: []
		};
	},
	computed: {
		prescriptionAttachedStyle() {
			if (this.selectedPrescriptionId.length)
				return 'purple';

			return null;
		},

		stockIsEnough() {
			return this.estoqueMedicine >= this.neededPillsQuantity;
		},

		prescriptionAttachmentIsRequired() {
			return this.isControlled
				&& !this.stockIsEnough
				&& !this.medicineIsPaused
				&& !this.selectedPrescriptionId.length;
		},

		prescriptionOptions() {
			return this.prescriptions.map(receita => ({ id: receita.id, name: receita.id }));
		}
	},
	methods: {
		onChange: debounce(async function (selectedPrescriptionsId = []) {
			const attachIds = selectedPrescriptionsId;
			const detachIds = this.prescriptions.filter(p => !attachIds.includes(p.id)).map(p => p.id) || [];

			try {
				await MedicamentoPresenter.syncPrescriptions(this.medicineId, attachIds, detachIds);
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Receitas vinculadas com sucesso' });
				this.$emit('receita-vinculada', true);
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao vincular receitas ao medicamento' });
			}
		}, 500),
		async showPrescription(prescriptionId) {
			try {
				this.prescriptionData = await ReceitaPresenter.show(prescriptionId);
				this.showDetailPrescription = true;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: `Erro ao buscar receitas do medicamento ${this.medicineId}` });
			}
		},
		close() {
			this.showDetailPrescription = false;
		},
		getDescriptionTypeRegistration(key) {
			return typesOfRegisterData[key].description;
		},
		isPdf(url) {
			const split = url.split('.');
			const ext = split.pop();
			return ext === 'pdf';
		}
	}

};
</script>

<style scoped>
.attached {
	border: dashed 1px gray; border-radius: 5px;
}
.noattached {
	background-color: #FFC875 !important;
	border: #FFC875 1px gray; border-radius: 5px;
}
</style>
