<template>
	<v-card style="overflow-y: auto;">
		<!-- Título e descrição -->
		<v-card-title class="purple-title">
			<v-layout fill-height align-center>
				<v-flex>
					<h2 style="font-weight: 600"> Comprovante de entrega </h2>
				</v-flex>
				<!-- Botão fechar -->
				<v-flex class="align-end">
					<v-btn small icon @click="$emit('update:close', false)" class="mr-0">
						<v-icon small color="white">close</v-icon>
					</v-btn>
				</v-flex><!-- Fim botão fechar -->
			</v-layout>
		</v-card-title><!-- Fim, título e descrição -->
		<v-layout>
			<v-flex>
				<v-layout v-for="(item, i) in items" :key="i">
					<v-flex grow pa-1>
						<v-text-field box hide-details v-model="items[i]"/>
					</v-flex>
					<v-flex shrink  pa-1>
						<v-btn small icon class="purple white--text ml-0" slot="activator"
							@click="items.splice(i, 1)">
							<v-icon small>delete</v-icon>
						</v-btn>
					</v-flex>
				</v-layout>
				<v-flex style="display: flex; max-width: max-content; align-items: center; max-height: 200px">
					<v-btn depressed class="primary" @click="items.push('')">
						Adicionar
					</v-btn>
					<v-btn depressed class="white" color="green" @click="generate_pdf()">
						Gerar Comprovante de Entrega
					</v-btn>
				</v-flex>
			</v-flex>
			<v-flex>
				<div v-if="is_loading" class="wrapper">
					<div class="carregando">
						<v-progress-circular indeterminate color="grey" />
						<p class="mt-3 grey--text">Gerando comprovante, por favor aguarde.</p>
					</div>
				</div>
				<embed v-else :src="pdf" type="application/pdf" style="width:100%; height:100%" />
			</v-flex>
		</v-layout>
	</v-card>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';

export default {
	name: 'DialogComprovanteEntrega',
	props: {
		selecionados: {
			type: Array,
			required: true
		}
	},
	data: () => ({
		is_loading: false,
		items: [],
		pdf: null
	}),
	async created() {
		const boxes = await Promise.all(this.selecionados.map(box => BoxPresenter.show(box.id)));
		const listasDeCompras = await BoxPresenter.listaDeCompras({ ids: this.selecionados.map(box => box.id) });

		const medicamentos_fora_da_box = {};

		boxes.forEach(box => {
			this.items.push(`1 Far.me BOX - ${box.assinatura.paciente.nome}`);
			const listaDeCompras = listasDeCompras.find(a => a.assinatura.id === box.assinatura.id);

			box.medicamentos
				.filter(m => !!listaDeCompras.medicamentos.find(l => l.id === m.id))
				.forEach(medicamento => {
					const medicamentoComprado = listaDeCompras.medicamentos.find(l => l.id === medicamento.id);
					if (!medicamento.liquido && !medicamento.fora_da_box)
						return;
					if (!medicamentos_fora_da_box[medicamento.id]) {
						medicamentos_fora_da_box[medicamento.id] = {
							quantidade: 0,
							nome: `${medicamento.produto} (${medicamento.apresentacao})`
						};
					}
					medicamentos_fora_da_box[medicamento.id].quantidade += medicamentoComprado.caixas || 1;
				});
		});

		Object.values(medicamentos_fora_da_box).forEach(medicamento => {
			this.items.push(`${medicamento.quantidade} ${medicamento.nome}`);
		});

		await this.generate_pdf();
	},
	methods: {
		async generate_pdf() {
			try {
				this.is_loading = true;
				let casa_repouso = null;
				const box_com_casa_de_repouco = this.selecionados.find(s => !!s.assinatura.paciente.casa_repouso_id);
				if (box_com_casa_de_repouco)
					casa_repouso = await CasaRepousoPresenter.show(box_com_casa_de_repouco.assinatura.paciente.casa_repouso_id);

				const { geraPdfComprovanteEnvio } = await import('@Util/pdf-comprovante-envio');
				geraPdfComprovanteEnvio(casa_repouso, this.items).getDataUrl(outDoc => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Comprovante gerado com sucesso!' });
					this.pdf = outDoc;
					this.is_loading = false;
				});
			} catch (error) {
				this.is_loading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-height: calc(100vh - 104px);

  .carregando {
    text-align: center;
    min-height: calc(100vh - 104px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
