<template>
	<div class="medicamento">
		<div v-if="loading" class="loading">
			<v-progress-circular indeterminate color="primary" />
		</div>
		<div v-else>
			<div v-if="medicamento">
				<h4>{{medicamento.descricao_tipo_produto}}</h4>
				<h3 class="teal--text text--darken-2">
					{{medicamento.produto}}
				</h3>
				<p v-if="medicamento.produto !== medicamento.principio_ativo">
					{{medicamento.principio_ativo}}
				</p>
				<p>{{medicamento.laboratorio}}</p>
				<p>{{medicamento.apresentacao}}</p>
			</div>
			<div v-else class="sem-medicamento">
				<h3 style="color: #342b1d !important"  class="text--darken-2">
					Pronto
				</h3>
				<h4>
					Utilize um leitor de código de barras ou insira o <b>EAN</b> manualmente
				</h4>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Medicamento',
	computed: {
		medicamento() {
			return this.$store.state.bipe.medicamento;
		},
		loading() {
			return this.$store.state.bipe.loading_medicamento;
		}
	}
};
</script>

<style lang="scss" scoped>
.medicamento {
	padding: 8px;
	border: 1px lightgrey solid;
	border-radius: 10px;
	margin-top: 15px;
	display: flex;

	h3 {
		font-size: 20px;
	}

	h4 {
		font-family: "Poppins", sans-serif;
		font-weight: 500;
		margin-bottom: 0;
		text-transform: uppercase;
		font-size: 14px;
		line-height: 12px;
	}

	p {
		font-size: 12px;
		margin: 0;
	}
}
</style>
