<template>
	<Dialog titulo="Iugu ID" @close="fecharModal()" btn_acao="Salvar" @acao="salvar()" :loading="salvando"
		:carregando="loading">
		<v-container fluid grid-list-lg class="pa-0">
			<v-layout wrap>
				<v-flex xs12>
					<v-autocomplete v-model="iugu_id_cliente_selecionado" :items="clientes" item-text="nome" item-value="id" label="Responsável pelo pagamento"
						box hide-details clearable @input="onSelect" />
				</v-flex>
				<v-flex xs12 style="display: flex; justify-content: center;">
					- ou -
				</v-flex>
				<v-flex xs12>
					<v-text-field v-model="iugu_id" label="Iugu ID" placeholder="Insira aqui o ID Iugu" box hide-details />
				</v-flex>
			</v-layout>
		</v-container>

	</Dialog>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';

export default {
	name: 'DialogIuguId',
	props: {
		assinatura: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		iugu_id: null,
		clientes: [],
		iugu_id_cliente_selecionado: null,
		salvando: false,
		loading: true
	}),
	created() {
		this.getClientesIugu();
	},
	methods: {
		onSelect(iugu_id_cliente_selecionado) {
			this.iugu_id = iugu_id_cliente_selecionado;
		},
		getClientesIugu() {
			AssinaturaPresenter.listaClientesIugu()
				.then(clientes => {
					this.clientes = clientes;
					const cliente = clientes.find(c => c.id === this.assinatura.iugu_id);
					if (cliente) {
						this.iugu_id = cliente.id;
						this.iugu_id_cliente_selecionado = cliente.id;
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		async salvar() {
			this.salvando = true;
			AssinaturaPresenter.updateIuguId(this.assinatura.id, this.iugu_id)
				.then(response => {
					this.assinatura.iugu_id = this.iugu_id;
					this.$store.dispatch('assinatura/SET_IUGU_ID', this.iugu_id);
					this.$store.dispatch('SHOW_SNACKBAR', { message: response.message });
					this.fecharModal();
				})
				.finally(() => {
					this.salvando = false;
				});
		},
		fecharModal() {
			this.$emit('update:close', false);
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
