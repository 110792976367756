<template>
	<v-flex xs12>
		<v-layout wrap>
			<v-flex class="d-flex items-center justify-center text-uppercase inter text-bold">
				<div v-for="action in actionMethod" :key="action.index" :class="[action.leftItem ? 'leftItem' : 'rightItem', {'unselectedItem': action.id !== quotationMethod}, {'pointer': !action.disabled}]" class="d-flex items-center"
					@click="action.disabled ? () => {} : selectMode(action)">
					<img
						:src="`/img/icons/${action.icon}.svg`"
						height="32px"
						width="32px"/>
					<span>{{action.label}}</span>
				</div>
			</v-flex>
			<v-flex class="d-flex items-center justify-center text-uppercase inter text-bold">
				<div v-for="action in actionSalesMethod" :key="action.index" :class="[action.leftItem ? 'leftItem' : 'rightItem', {'unselectedItem': action.id !== quotationSalesMethod}, {'pointer': !action.disabled}]" class="d-flex items-center pointer" @click="action.disabled ? () => {} : selectSalesMethod(action)">
					<img
						:src="`/img/icons/${action.icon}.svg`"
						height="32px"
						width="32px"/>
					<span>{{action.label}}</span>
				</div>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
import { getPropertyValue } from '@Util/ProductService';

export default {
	name: 'Actions',
	data: () => ({
		quotationMethod: 'subscription',
		quotationSalesMethod: 'schedules',
		actionMethod: [
			{
				id: 'subscription', label: 'Far.me Box', icon: 'recurrence-outline', leftItem: true, disabled: false
			},
			{
				id: 'onTime', label: 'Pontual', icon: 'on-time-icon', leftItem: false, disabled: true
			}
		],
		actionSalesMethod: [
			{
				id: 'schedules', label: 'Horários', icon: 'schedule-icon', leftItem: true, disabled: false
			},
			{
				id: 'boxes', label: 'Caixas', icon: 'boxes-icon', leftItem: false, disabled: false
			}
		]
	}),
	props: {
		salesMethod: {
			type: String
		},
		product: {
			type: Object,
			required: true
		}
	},
	created() {
		if (this.salesMethod)
			this.quotationSalesMethod = this.salesMethod;

		const isDivisible = this.product.drug ? Boolean(getPropertyValue(this.product.drug.properties, 'isDivisible')) : false;
		if (!isDivisible) {
			this.quotationSalesMethod = 'boxes';

			this.actionSalesMethod = this.actionSalesMethod.map(item => {
				if (item.id === 'schedules')
					return { ...item, disabled: true };
				return { ...item };
			});
		}

		this.$emit('methodSelected', this.quotationMethod);
		this.$emit('salesMethodSelected', this.quotationSalesMethod);
	},
	methods: {
		selectMode(mode) {
			this.quotationMethod = mode.id;
			this.$emit('methodSelected', this.quotationMethod);
		},
		selectSalesMethod(item) {
			this.quotationSalesMethod = item.id;
			this.$emit('salesMethodSelected', this.quotationSalesMethod);
		}
	}
};
</script>

<style lang="scss" scoped>
 .leftItem {
	padding: 12px 5px;
	border-radius: 10px 0px 0px 10px;
	background: #FFC875;
	border-right: 2px dashed rgba(52, 43, 29, 0.5);
 }

 .rightItem {
	padding: 12px 5px;
	border-radius: 0px 10px 10px 0px;
	background: #FFC875;
	border-left: 2px dashed rgba(52, 43, 29, 0.5);
 }

 .unselectedItem {
	opacity: 0.7
 }

</style>
