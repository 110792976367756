<template>
	<Dialog titulo="Nova pendência" @close="close">
		<v-flex v-if="loading" class="loading text-center">
			<v-progress-circular :size="70" indeterminate color="primary" />
		</v-flex>
		<template v-else>
			<v-expansion-panel :value="0" class="my-2">
				<v-expansion-panel-content focusable>
					<template v-slot:header>
						<div>
							<v-layout row>
								<v-flex xs12 md12 align-self-center>
									<span class="id-pendencia">{{ pendencia.id }}</span>  <span class="descricao-pendencia">{{ pendencia.descricao }}</span>
								</v-flex>
							</v-layout>
						</div>
					</template>
					<v-expansion-panel>
						<v-card>
							<v-card-text>
								<v-container class="pa-0">
									<v-flex xs12 md class="pa-1">
										<span class="descricao-pendencia">Categoria: </span><span class="info-pendencia">{{ pendencia.categoria }}</span>
									</v-flex>
									<v-layout align-center justify-space-between row fill-height>
										<v-layout column>
											<v-flex xs12 md class="pa-1">
												<span class="descricao-pendencia">Aberta por: </span><span class="info-pendencia">{{ pendencia.openedBy.nome }}</span>
											</v-flex>
											<v-flex xs12 md class="pa-1">
												<span class="descricao-pendencia">Concluida por: </span><span class="info-pendencia">{{ pendencia.concludedBy.nome }}</span>
											</v-flex>
										</v-layout>
										<v-layout column>
											<v-flex xs12 md class="pa-1">
												<span class="descricao-pendencia">Data de abertura: </span><span class="info-pendencia">{{ pendencia.created_at | formatDate('DD/MM/YYYY HH:mm') }}</span>
											</v-flex>
											<v-flex xs12 md class="pa-1">
												<span class="descricao-pendencia">Data de encerramento: </span><span class="info-pendencia">{{ pendencia.concluded_at | formatDate('DD/MM/YYYY HH:mm') }}</span>
											</v-flex>
										</v-layout>
									</v-layout>
								</v-container>
							</v-card-text>
							<v-flex xs12 md12 class="pt-3 px-3" v-if="pendencia.respostas && pendencia.respostas.length">
								<span class="historico-title">Histórico:</span>
							</v-flex>
							<v-container class="pa-2" v-if="pendencia.respostas && pendencia.respostas.length">
								<v-layout column>
									<v-flex :class="i % 2 === 0 ? 'style-respostas' : 'style-respostas2'" class="ma-1" xs12 md12 v-for="(resposta, i) in pendencia.respostas"
										:key="i">
										<v-layout row align-content-space-between>
											<v-flex align-self-center class="descricao-pendencia px-3" xs12 md12>{{ resposta.texto }}</v-flex>
											<v-flex align-self-center class="pa-3">
												<v-tooltip top :max-width="400">
													<template v-slot:activator="{ on }">
														<v-icon color="#929292" v-on="on">info</v-icon>
													</template>
													Data da resposta: {{ resposta.created_at | formatDate('DD/MM/YYYY HH:mm') }}
												</v-tooltip>
											</v-flex>
										</v-layout>
										<v-flex xs12 md12 class="px-3 py-2">
											<v-divider></v-divider>
										</v-flex>
										<v-flex xs12 md12 class="pa-3">
											<span class="descricao-pendencia">Respondido por: </span><span class="info-pendencia">{{ resposta.user.nome }}</span> <span class="descricao-pendencia pl-5">Área: </span> <span class="info-pendencia">{{ i % 2 === 0 ? atendimento : farmaceutico }}</span>
										</v-flex>
									</v-flex>
								</v-layout>
							</v-container>
						</v-card>
					</v-expansion-panel>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</template>
	</Dialog>
</template>

<script>

export default {
	name: 'HistoricoPendencia',
	props: {
		pendencia: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		loading: false,
		panel: [false],
		atendimento: 'Atendimento',
		farmaceutico: 'Farmacêutico'
	}),
	created() {
	},
	methods: {
		close() {
			this.$emit('close');
		}
	}
};
</script>

<style lang="scss" scoped>
.id-pendencia {
	font-family: 'Poppins';
	font-weight: 700;
	color: #929292;
}

.descricao-pendencia {
	font-family: 'Roboto';
	font-weight: 400;
	color: #929292;
}

.info-pendencia {
	font-family: 'Roboto';
	font-weight: 500;
	color: #5A5A5A;
}

.historico-title {
	font-family: 'Roboto';
	font-weight: 700;
	color: #929292;
}

.style-respostas {
	background: #F4F4F4;
	border-radius: 7px;
}

.style-respostas2 {
	border: 1px solid #CBCBCB;
	border-radius: 7px
}
</style>
