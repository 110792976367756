import { versao } from '@Config/versions';
import { featureFlags } from '@Consts/feature-flags';

export const getState = () => ({
	version: versao,
	box_id: null,
	novabox: null,
	contexto: null,
	visao_colaborador: false,
	msg_toast: null,
	snackbar: {
		visible: false,
		message: '',
		color: 'success',
		timeout: 4000
	},
	featureFlags: {
		[featureFlags.new_reset_box]: {
			enabled: false,
			variant: null
		}
	}
});
