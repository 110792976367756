<template>
	<Dialog titulo="Enviar receita" @close="$emit('update:close', false)" btn_acao="Salvar" :btn_acao_disable="!libera_botao" @acao="$emit('adicionar-receitas', receitas)"
		btn_cancelar="Cancelar">
		<v-container fluid grid-list-xl class="pa-0">
			<v-layout wrap>
				<v-flex xs12>
					<v-btn depressed class="ml-0 purple white--text" @click="$refs.upload.click()">
						<v-icon small class="mr-2">cloud_upload</v-icon>
						Escolher arquivo(s)
					</v-btn>
					<span v-if="erro_upload" class="error--text">
						Você enviou uma ou mais imagens com o formato não permitido. Envie apenas imagens no
						formato jpeg, png ou pdf.
					</span>
					<input type="file" ref="upload" @change="previewImage" accept="image/png, image/jpeg, application/pdf"
						multiple>

					<v-layout wrap v-if="receitas.length > 0">
						<v-flex xs12 md6 v-for="(item, index) in receitas" :key="item.id">
							<div class="img-wrapper">
								<v-btn small icon @click="abrirDialogRemover(index)" class="purple">
									<v-icon small color="white">delete</v-icon>
								</v-btn>
								<img v-if="item.type !== 'application/pdf'" :src="item.dataBase64" class="miniatura" />
								<v-icon v-else :disabled="abrindoPdf" @click="openPdf(item)" size="50" class="pointer pa-4 flex-center"
									color="purple">fas fa-file-pdf</v-icon>
							</div>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>

		<v-dialog persistent scrollable v-model="dialog_remover_receita" transition="fade" max-width="340px">
			<transition>
				<Dialog v-if="dialog_remover_receita" titulo="Remover receita" subtitulo="Deseja remover a raceita da lista?"
					btn_acao="Remover" btn_acao_class="error" btn_cancelar="Cancelar" @acao="removerReceita"
					@close="dialog_remover_receita = false" />
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import { openBuffer } from '@Util/functions';
import axios from 'axios';

export default {
	name: 'EnviarReceitas',
	data: () => ({
		receitas: [],
		erro_upload: false,
		abrindoPdf: false,
		posicao_selecionado: null,
		dialog_remover_receita: false
	}),
	computed: {
		libera_botao() {
			return this.receitas.length > 0;
		}
	},
	methods: {
		previewImage(e) {
			this.erro_upload = false;

			if (e.target) {
				for (let i = 0; i < e.target.files.length; i += 1) {
					const file = e.target.files[i];
					if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'application/pdf') {
						this.erro_upload = true;
						continue;
					}
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');
					const maxW = 1000;
					const maxH = 1500;

					if (file.type === 'application/pdf') {
						const reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onloadend = () => {
							const base64data = reader.result;
							const pdf = {
								dataBase64: base64data,
								type: 'application/pdf',
								name: file.name
							};
							this.receitas.push(pdf);
						};
					} else {
						const img = new Image();
						img.onload = () => {
							const iw = img.width;
							const ih = img.height;
							const scale = Math.min((maxW / iw), (maxH / ih));
							const iwScaled = iw * scale;
							const ihScaled = ih * scale;
							canvas.width = iwScaled;
							canvas.height = ihScaled;
							ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
							const dataUrl = canvas.toDataURL('image/jpeg', 0.7);
							const image = {
								dataBase64: dataUrl,
								type: 'image/jpeg',
								name: file.name
							};
							this.receitas.push(image);
						};
						img.src = URL.createObjectURL(file);
					}
					e.target.value = '';
				}
			}
		},
		abrirDialogRemover(posicao) {
			this.posicao_selecionado = posicao;
			this.dialog_remover_receita = true;
		},
		removerReceita() {
			this.receitas.splice(this.posicao_selecionado, 1);
			this.dialog_remover_receita = false;
			this.posicao_selecionado = null;
		},
		openPdf(receita) {
			this.abrindoPdf = true;
			axios.get(receita.url, { responseType: 'arraybuffer' })
				.then(response => openBuffer(response.data))
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao abrir PDF' });
				})
				.finally(() => {
					this.abrindoPdf = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

input {
  display: none;
}

.img-wrapper {
  position: relative;

  .v-btn {
    position: absolute;
    top: 5px;
		left: 5px;
  }

  .miniatura {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
  }
}
</style>
