import axios from 'axios';
import unleash from '@Util/unleash';
import { TOKEN } from '@Consts/strings';
import { versao } from '@Config/versions';
import { getCurrentCompanyUnit } from '@Config/unidades';
import { Logout } from '@Util/functions';
import { envs, localStorageEnvKey } from '@Config/environments';
import { clearAxiosResponseData } from 'luiz-fns';
import router from '../router';
import store from '../store';
import { url_api } from './url-api';

const companyUnityHeader = 'X-Company-Unit-Id';

const env = localStorage.getItem(localStorageEnvKey);

let url = url_api.Local;
if (env && env === envs.Prod)
	url = url_api.Prod;
else if (env && env === envs.Lab)
	url = url_api.Lab;
else if (env && env === envs.Dev)
	url = url_api.Dev;
else if (env && env === envs.Staging)
	url = url_api.Staging;

const http = axios.create({
	baseURL: url,
	headers: {
		Accept: 'application/json'
	},
	responseType: 'json'
});

// Create a cache object to store ongoing requests
const requestCache = {};

// Function to generate a unique request identifier
const generateRequestId = config => {
	// For some reason the config.data from response
	// is returned as string and from request as object
	if (config?.data) {
		if (typeof config.data === 'object')
			return `${config.method}${config.url}${JSON.stringify(config.data)}`;

		try {
			const bodyKey = JSON.stringify(JSON.parse(config.data));
			return `${config.method}${config.url}${bodyKey}`;
		} catch (e) {
			// In case of JSON error, removes all keys that may be affected from cache
			const initialCacheKey = `${config.method}${config.url}`;
			const cachesKeys = Object.keys(requestCache);
			const cachedKeysThatMayBeAffected = cachesKeys.filter(key => key.includes(initialCacheKey));
			cachedKeysThatMayBeAffected.forEach(key => {
				delete requestCache[key];
			});
		}
	}
	return `${config.method}${config.url}`;
};

http.interceptors.request.use(
	config => {
		const requestId = generateRequestId(config);

		const user_token = localStorage.getItem(TOKEN);
		config.headers.Authorization = `Bearer ${user_token}`;
		config.headers.version = versao;
		config.headers[companyUnityHeader] = getCurrentCompanyUnit()?.id || '';
		config.headers.colaboradorView = store.getters.visao_colaborador ? 'AF%u4nE9t2MB' : 'gRH!iqE%4mKA';

		// If request is already in progress, don't send another request
		if (requestCache[requestId])
			return clearAxiosResponseData(config);

		requestCache[requestId] = true;

		const variant = unleash.getVariant('FARMEBOX_PROD_URL');
		if (variant.feature_enabled && variant.enabled)
			config.baseURL = variant.payload.value || url_api.Prod;

		return config;
	},
	error => Promise.reject(error)
);

const exibeErro = async error => {
	const { response } = error;
	let mensagemErro = 'Erro desconhecido!';

	if (response) {
		if (response.data) {
			if (response.data.message)
				mensagemErro = response.data.message;
			else if (response.data.error.message)
				mensagemErro = response.data.error.message;
		}

		store.dispatch('SHOW_SNACKBAR', { color: 'error', message: mensagemErro });

		if (response.status === 401) {
			Logout();
			store.dispatch('SHOW_SNACKBAR', { color: 'error', message: mensagemErro });
			router.push({ name: 'Entrar', params: { stop: true } });
		}
		response.data.__status = response.status;
		return Promise.reject(response.data);
	}

	store.dispatch('SHOW_SNACKBAR', { color: 'error', message: mensagemErro });
	return Promise.reject(error);
};

const tentaNovamente = error => {
	const { config } = error;
	// Se não foi configurado, retorna o erro
	if (!config || !config.retry)
		return exibeErro(error);

	// Contador de tentativas
	config.__retryCount = config.__retryCount || 0;

	// Verifica se já tentou o número máximo de vezes
	if (config.__retryCount >= config.retry)
		return exibeErro(error);

	config.__retryCount += 1;

	// Cria nova promise com delay para tentar novamente
	const backoff = new Promise((resolve => {
		setTimeout(() => {
			resolve();
		}, config.retryDelay || 1);
	}));

	// Retorna a promise para o axios tentar novamente
	return backoff.then(() => http(config));
};

const clearRequestCache = config => {
	const requestId = generateRequestId(config);
	delete requestCache[requestId];
};

http.interceptors.response.use(
	response => {
		clearRequestCache(response.config);
		return clearAxiosResponseData(response);
	},
	error => {
		clearRequestCache(error.config);

		if (!error.response) {
			return exibeErro({
				response: { data: { message: 'Erro de conexão! Por favor, verifique sua internet e tente novamente.' } }
			});
		}

		if (error.response && (error.response.status === 500 || error.response.status === 502))
			return tentaNovamente(error);
		return exibeErro(error);
	}
);

export default http;
