<template>
	<header>
		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 md6>
				<h1 class="font-weight-bold mb-2" style="font-family: Inter; font-size: 30px">Far.me Pontual</h1>
				<p class="mb-0">Utilize a busca abaixo para encontrar os pedidos.</p>
				<p>É possível filtrar pelo status do pedido</p>
			</v-flex>

			<v-flex xs12 >
				<v-layout wrap>
					<v-flex xs12 md4>
						<v-text-field
							label="Buscar pedido"
							placeholder="ID ou nome do Cliente"
							append-icon="search"
							color="purple"
							@input="search => setSearch(search)" />
					</v-flex>

					<v-flex xs12 md4>
						<CampoSelecaoMultipla
							:items="origins"
							:itemText="i => `${i.id} - ${i.nome}`"
							itemValue="id"
							label="Casas de repouso"
							prependInnerIcon="home"
							@items-updated="setOrigin"/>
					</v-flex>

					<v-flex  xs12 md4>
						<CampoSelecaoMultipla
							:items="filters"
							:itemText="i => `${i.text}`"
							itemValue="value"
							label="Status"
							@items-updated="setStatus"/>
					</v-flex>
				</v-layout>

			</v-flex>
		</v-layout>
	</header>
</template>

<script>
import CampoSelecaoMultipla from '@Componentes/campo-selecao-multipla';

export default {
	name: 'Header',
	props: {
		filters: {
			required: true,
			type: Array
		},
		origins: {
			required: true,
			type: Array
		}
	},
	components: {
		CampoSelecaoMultipla
	},
	methods: {
		setSearch(search) {
			this.$emit('set-search', search);
		},
		setStatus(status) {
			this.$emit('set-status', status);
		},
		setOrigin(values) {
			this.$emit('set-origin', values);
		}
	}
};
</script>
