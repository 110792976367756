<template>
	<v-card>
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="#342B1D" small>fas fa-history</v-icon>
			</v-avatar>
			<h3>Histórico de Status</h3>
		</v-card-title>

		<v-card-text>
			<v-data-table
				disable-initial-sort
				:headers="headers"
				:items="items">
				<template v-slot:items="props">
					<tr class="pointer">
						<td class="pl-0">{{ getDescription(props.item.status) }}</td>
						<td width="190">
							{{ props.item.createdAt | formatDate('DD/MM/YYYY HH:mm') }}
						</td>
					<tr/>
				</template>
			</v-data-table>
		</v-card-text>

	</v-card>
</template>

<script>
import { getDescriptionOrderStatus } from '@Consts/status-order';

export default {
	name: 'OrderStatusHistory',
	props: {
		items: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	computed: {
		headers() {
			return [
				{
					text: 'Status', value: 'status', sortable: false, class: 'pl-0'
				},
				{ text: 'Data de criação', value: 'createdAt', sortable: true }
			];
		}
	},
	methods: {
		getDescription(status) {
			return getDescriptionOrderStatus(status);
		}
	}
};
</script>

<style scoped>
h3 {
	color: rgba(0, 0, 0, 0.87);
	font-family: Inter;
	font-size: 13.38px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.57px;
}
</style>
