<template>
	<section>
		<div class="icon">
			<img :src="iconeConferencia" alt="Ícone da conferênia">
		</div>

		<span
			class="inter"
			:class="getTextColorClass">
			Assinatura <strong>{{ subscriptionId }}</strong>
		</span>
	</section>
</template>

<script>
import iconeConferencia from '@Assets/icons/icone_conferencia.svg';
import { BoxProductionMixin } from '@Mixins/box-production';

export default {
	mixins: [BoxProductionMixin],
	props: {
		subscriptionId: { required: true }
	},
	data() {
		return {
			iconeConferencia
		};
	},
	computed: {
		getTextColorClass() {
			if (this.isInProgress)
				return 'white--text';
			return 'secondary--text';
		}
	}
};
</script>

<style lang="scss" scoped>
section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
	text-align: center;

	.icon {
		width: 85px !important;
		height: 85px !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #FFFFFFB2 !important;
		border-radius: 50%;

		img {
			width: 50px;
		}
	}

	span {
		font-size: 18px;
		font-weight: 400;
	}

	@media (max-width: 600px) {
		.icon {
			width: 65px !important;
			height: 65px !important;

			img {
				width: 40px;
			}
		}

		span {
			font-size: 16px;
		}
	}
}

</style>
