<template>
	<div class="orcamentos-wrapper px-md-5">
		<Header  v-model="exibir_ilpi" :habilita_lista_compra="!selecionados.length" @busca="onBuscaUpdate" @criar-orcamento="criarOrcamento" @filtro="onFiltroUpdate"
			@gerar-lista-de-compra="gerarListaDeCompra" />

		<v-card>
			<DataTableILPI v-show="exibir_ilpi"
				:items="casas_repouso" :loading="carregando_casas_repouso" :search="busca" :error="error" />
			<DataTableOrcamento
				v-show="!exibir_ilpi"
				ref="DataTableOrcamento"
				:items="orcamentos"
				:loading="carregando"
				:search="busca"
				:error="error"
				:totalItems="totalItems"
				@refresh="refresh"
				@pagination-update="onPaginationUpdate"
				@selected-updated="arr => selecionados = arr"/>
		</v-card>

	</div>
</template>

<script>
import { isEqual, debounce } from 'lodash';
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import Header from './header';
import DataTableOrcamento from './data-table-orcamento.vue';
import DataTableILPI from './data-table-ilpi.vue';

export default {
	name: 'Orcamentos',
	components: {
		Header, DataTableOrcamento, DataTableILPI
	},
	data: () => ({
		exibir_ilpi: false,
		orcamentos: [],
		selecionados: [],
		totalItems: 0,
		casas_repouso: [],
		busca: '',
		error: false,
		carregando: true,
		carregando_casas_repouso: false,
		index_props: {
			status: null,
			rowsPerPage: 15,
			page: 1,
			sortBy: 'id',
			order: 'asc'
		}
	}),
	watch: {
		index_props: {
			async handler(val, oldVal) {
				if (!isEqual(val, oldVal))
					await this.buscaOrcamentos();
			},
			deep: true
		},
		exibir_ilpi(val) {
			if (val && this.casas_repouso.length === 0)
				this.buscaCasasRepouso();
		}
	},
	methods: {
		gerarListaDeCompra() {
			return new Promise(async (resolve, reject) => {
				// this.gerando = true;
				try {
					const ids = this.selecionados.map(a => a.id);
					const promises = [OrcamentoPresenter.listaDeCompras(ids)];
					promises.push(import('@Util/excel-lista-compra'));
					const responses = await Promise.all(promises);
					const { geraListaDeCompras } = responses[1];
					geraListaDeCompras(responses[0]);
				} catch (error) {
					reject();
				}
				// this.gerando = false;
				resolve();
			});
		},
		criarOrcamento() {
			this.$refs.DataTableOrcamento.crud();
		},
		onFiltroUpdate(status) {
			this.index_props = { ...this.index_props, status };
		},
		onBuscaUpdate: debounce(function (search) {
			if	(this.exibir_ilpi)
				this.busca = search;
			else
				this.index_props = { ...this.index_props, search };
		}, 750),
		onPaginationUpdate({
			page, rowsPerPage, sortBy, descending
		}) {
			this.index_props = {
				...this.index_props,
				order: descending ? 'desc' : 'asc',
				page,
				rowsPerPage,
				sortBy
			};
		},
		refresh() {
			this.buscaOrcamentos();
			this.buscaCasasRepouso();
		},
		buscaCasasRepouso() {
			this.carregando_casas_repouso = true;
			CasaRepousoPresenter.index({})
				.then(casas_repouso => {
					this.casas_repouso = casas_repouso;
				})
				.finally(() => {
					this.carregando_casas_repouso = false;
				});
		},
		buscaOrcamentos() {
			this.error = false;
			this.carregando = true;
			OrcamentoPresenter.index(this.index_props)
				.then(response => {
					this.orcamentos = response.items;
					this.totalItems = response.total;
				})
				.catch(() => {
					this.error = true;
				})
				.finally(() => {
					this.carregando = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.v-card {
  overflow: hidden;
}

.status-input {
  margin-top: 15px;
  border-radius: 5px;
  padding: 7px 10px 7px 15px;
  max-width: 200px;

  ::v-deep.v-input__slot {
    &:before,
    &:after {
      width: 0 !important;
      border-color: transparent !important;
    }

    .v-select__selection,
    .v-icon {
      color: #fff !important;
    }
  }
}

::v-deep .v-menu__activator {
  a {
    margin: 0 auto;
  }
}
</style>
