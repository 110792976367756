<template>
	<Dialog
		titulo="Cadastrar PBM"
		@acao="save()"
		btn_acao="Confirmar"
		@close="$emit('close')"
		background="rgba(255, 234, 204, 1)"
		backgroundFooter="rgba(255, 234, 204, 1)">
		<v-container>
			<v-layout wrap>
				<v-flex xs12>
					<v-text-field
						v-model="form.program"
						background-color="white"
						box
						label="Programa"
						v-validate="{ required: true }"
						data-vv-name="program"
						:error-messages="errors.collect('program')"/>
				</v-flex>

				<v-flex xs12>
					<v-textarea
						v-model="form.description"
						background-color="white"
						label="Descrição"
						box
						v-validate="{ required: false }"
						data-vv-name="description"
						:error-messages="errors.collect('description')"/>
				</v-flex>

				<v-flex xs12>
					<v-text-field
						v-model="form.site"
						background-color="white"
						box
						label="Link do site"
						v-validate="{ required: true }"
						data-vv-name="site"
						:error-messages="errors.collect('site')"/>
				</v-flex>
			</v-layout>
		</v-container>

		<FarmeDivider />
	</Dialog>
</template>

<script>
import { PbmPresenter } from '@Presenter/pbm-presenter';
import FarmeDivider from '@Views/componentes/farme-divider.vue';

export default {
	name: 'DialogCreatePBM',
	$_veeValidate: { validator: 'new' },
	components: {
		FarmeDivider
	},
	data: () => ({
		loading: true,
		form: {
			program: null,
			description: null,
			site: null
		}
	}),
	methods: {
		async save() {
			const formIsValidated = await this.$validator.validateAll();

			if (!formIsValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário inválido',
					color: 'error'
				});
				return;
			}

			try {
				await PbmPresenter.create({
					program: this.form.program,
					description: this.form.description,
					site: this.form.site
				});

				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'PBM cadastrado com sucesso',
					color: 'success'
				});

				this.$emit('close');
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao criar PBM',
					color: 'error'
				});
				throw new Error(error);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
