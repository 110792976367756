import { render, staticRenderFns } from "./historic.vue?vue&type=template&id=fdb9047c&scoped=true"
import script from "./historic.vue?vue&type=script&lang=js"
export * from "./historic.vue?vue&type=script&lang=js"
import style0 from "./historic.vue?vue&type=style&index=0&id=fdb9047c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdb9047c",
  null
  
)

export default component.exports