<template>
	<v-container fluid grid-list-md class="pa-0">
		<v-layout wrap>
			<v-flex xs12 md6 v-for="(medicamento, index) in orcamento.medicamentos" :key="index">
				<MedicamentoCard
					:medicamento="medicamento"
					:medicamentos="orcamento.medicamentos"
					inverter_cores
					:medicamentosPorHorarios="medicamentosPorHorarios"
					:discountPercentage="discountPercentage"
					:orcamento_filial_id="orcamento.filial_id"
					:restHomeId="getRestHomeId"
					:fluxo="fluxo"
					:assinaturaId="assinaturaId"
					:tipoCliente="tipoCliente"
					:permiteTrocarMedicamento="true"
					:externalBilling="Boolean(orcamento.cobranca_externa)"/>
			</v-flex>
			<v-flex xs12 class="text-xs-center">
				<v-btn @click="escolherMedicamento({})" depressed class="green white--text" small>
					<v-icon small class="mr-2">add</v-icon>
					Adicionar medicamento
				</v-btn>
			</v-flex>
		</v-layout>

		<DialogMedicamento
			v-if="dialog_medicamento"
			:visible="dialog_medicamento"
			:close.sync="dialog_medicamento"
			:medicamento_selecionado="medicamento_selecionado"
			:medicamentos="orcamento.medicamentos"
			:eh_orcamento="true"
			:medicamentosPorHorarios="medicamentosPorHorarios"
			:orcamento_filial_id="orcamento.filial_id"
			:restHomeId="getRestHomeId"
			:externalBilling="Boolean(orcamento.cobranca_externa)"
			:fluxo="fluxo"
			:assinaturaId="assinaturaId"
			:tipoCliente="tipoCliente"
			:discount_percentage="discountPercentage"/>

		<v-dialog persistent v-model="dialog_excluir_medicamento" scrollable transition="fade"
			max-width="400px">
			<transition>
				<Dialog v-if="dialog_excluir_medicamento" titulo="Remover medicamento" subtitulo="Tem certeza que deseja remover o medicamento?"
					btn_acao="Excluir" btn_acao_class="error" btn_cancelar="Cancelar" @acao="excluirMedicamento"
					@close="dialog_excluir_medicamento = false" />
			</transition>
		</v-dialog>
	</v-container>
</template>

<script>
import MedicamentoCard from '@Componentes/medicamento/medicamento-card';
import DialogMedicamento from '@Componentes/medicamento/dialog-medicamento';
import mixin from './mixin';

export default {
	name: 'Medicamentos',
	components: { DialogMedicamento, MedicamentoCard },
	mixins: [mixin],
	props: {
		fluxo: {
			type: String,
			required: false
		},
		assinaturaId: {
			type: Number,
			required: false
		},
		tipoCliente: {
			type: String,
			required: false
		}
	},
	data: () => ({
		index_selecionado: null,
		dialog_medicamento: false,
		medicamento_selecionado: null,
		dialog_excluir_medicamento: false
	}),
	methods: {
		escolherMedicamento(medicamento) {
			this.medicamento_selecionado = this.$lodash.cloneDeep(medicamento);
			this.dialog_medicamento = true;
		},
		confirmarExcluirMedicamento(index) {
			this.index_selecionado = index;
			this.dialog_excluir_medicamento = true;
		},
		excluirMedicamento() {
			this.orcamento.medicamentos.splice(this.index_selecionado, 1);
			this.dialog_excluir_medicamento = false;
		}
	},
	computed: {
		getRestHomeId() {
			if (this.orcamento && this.orcamento.casa_repouso)
				return this.orcamento.casa_repouso.id;
			return null;
		},
		medicamentos() {
			return this.orcamento.medicamentos;
		},
		medicamentosPorHorarios() {
			const posologias = this.medicamentos.map(med => med.posologia);
			const horarios = posologias.map(posologia => posologia.horarios);

			const contagemPorHorario = horarios.flat();

			const res = {};
			contagemPorHorario.forEach(v => {
				res[v.horario] = (res[v.horario] || 0) + 1;
			});

			return res;
		},
		discountPercentage() {
			if (!this.orcamento.casa_repouso)
				return 0;

			return this.orcamento.casa_repouso.porcentagem_desconto;
		}

	}
};
</script>
