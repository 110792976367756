<template>
	<div class="paciente-wrapper px-md-5">
		<div v-if="carregando" class="carregando">
			<v-progress-circular width="3" indeterminate />
		</div>

		<Header v-if="!carregando" :paciente="paciente" @editar-paciente="dialog_paciente = true" />

		<v-container v-if="!carregando" grid-list-md class="pa-0">
			<v-layout wrap>
				<v-flex xs12 md6>
					<v-layout wrap>
						<v-flex xs12>
							<InfoAssinaturas :id_paciente="paciente.id" />
						</v-flex>
					</v-layout>
				</v-flex>

				<v-flex xs12 md6>
					<InfoResponsavel :pacienteId="paciente.id" />
				</v-flex>
			</v-layout>

			<v-flex xs12>
				<v-layout wrap>
					<v-flex>
						<InfoEstoque :paciente="paciente" />
					</v-flex>
				</v-layout>
			</v-flex>
		</v-container>

		<!-- Dialog paciente -->
		<v-dialog persistent scrollable v-model="dialog_paciente" transition="fade" max-width="1070px">
			<transition>
				<DialogPaciente
					v-if="dialog_paciente"
					:item_pai="paciente"
					:close.sync="dialog_paciente"
					@inserir="salvo"
					@excluir="excluido"/>
			</transition>
		</v-dialog>
		<!-- Fim, dialog paciente -->
	</div>
</template>

<script>
import { PacientePresenter } from '@Presenter/paciente-presenter';
import DialogPaciente from '../dialog-paciente';
import Header from './header';
import InfoAssinaturas from './info-assinaturas';
import InfoEstoque from './info-estoque';
import InfoResponsavel from './info-responsavel';

export default {
	name: 'Paciente',
	components: {
		Header,
		DialogPaciente,
		InfoAssinaturas,
		InfoEstoque,
		InfoResponsavel
	},
	data: () => ({
		carregando: true,
		dialog_paciente: false,
		paciente: {}
	}),
	created() {
		this.getPaciente();
	},
	methods: {
		getPaciente() {
			this.carregando = true;
			PacientePresenter.show(this.$route.params.id)
				.then(paciente => {
					this.paciente = paciente;
				})
				.finally(() => {
					this.carregando = false;
				});
		},
		salvo() {
			this.getPaciente();
		},
		excluido() {
			this.$router.push({ name: 'Pacientes' });
		}
	},
	computed: {
		sou_paciente() {
			if (this.paciente.user && this.paciente.user.id)
				return this.paciente.user.paciente_id !== this.paciente.id;
			return true;
		}
	}
};
</script>

<style lang="scss" scoped>
.carregando {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 88px);
}
</style>
