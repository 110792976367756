<template>
	<div>
		<v-toolbar class="header">
			<h1 class="text--text inline-block">Meu perfil</h1>

			<!-- Botões de ação -->
			<span class="navbar-buttons">
				<!-- Botão mudar email -->
				<v-btn depressed @click="dialog_alterar_email = true">
					<v-icon small class="mr-2">mail</v-icon>
					Alterar email
				</v-btn>

				<!-- Botão mudar senha -->
				<v-btn depressed @click="dialog_alterar_senha = true">
					<v-icon small class="mr-2">lock</v-icon>
					Alterar senha
				</v-btn>

				<!-- Botão adicionar novo paciente -->
				<v-btn depressed color="green" class="mr-0" @click="$emit('salvar-perfil')" :loading="salvando">
					<v-icon v-if="$vuetify.breakpoint.smAndUp" small class="mr-2">check</v-icon>
					Salvar perfil
				</v-btn><!-- Fim, botão adicionar novo paciente -->
			</span><!-- Fim, botões de ação -->
		</v-toolbar>

		<v-dialog persistent scrollable v-model="dialog_alterar_email" transition="fade" max-width="490px">
			<transition>
				<DialogAlterarEmail v-if="dialog_alterar_email" :close.sync="dialog_alterar_email" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_alterar_senha" transition="fade" max-width="490px">
			<transition>
				<DialogAlterarSenha v-if="dialog_alterar_senha" :close.sync="dialog_alterar_senha" />
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import DialogAlterarSenha from './dialog-alterar-senha';
import DialogAlterarEmail from './dialog-alterar-email';

export default {
	name: 'PerfilHeader',
	components: {
		DialogAlterarSenha, DialogAlterarEmail
	},
	props: {
		salvando: {
			type: Boolean,
			required: true
		}
	},
	data: () => ({
		dialog_alterar_senha: false,
		dialog_alterar_email: false
	})
};
</script>
