import $http from '@Config/axios';
import $store from '@Store';
import { buildQueryParams } from 'luiz-fns';

const prefix = 'nfs';

export const NfsPresenter = {
	index: (params = {}) => $http.get(buildQueryParams(prefix, params)),
	issue: dados => new Promise((resolve, reject) => {
		$http.post(prefix, dados)
			.then(response => {
				$store.dispatch('SHOW_SNACKBAR', { message: 'NFS-e emitida com sucesso!' });
				resolve(response);
			})
			.catch(response => reject(response));
	})
};
