const DELIVERY_TYPE_B2B_VALUE = 'B2B';
const DELIVERY_TYPE_B2B_TEXT = 'Entrega no endereço da ILPI';
export const DELIVERY_TYPE_B2B = Object.freeze({
	value: DELIVERY_TYPE_B2B_VALUE,
	text: DELIVERY_TYPE_B2B_TEXT
});

const DELIVERY_TYPE_B2C_VALUE = 'B2C';
const DELIVERY_TYPE_B2C_TEXT = 'Entrega no endereço Definido pelo Farmembro';
export const DELIVERY_TYPE_B2C = Object.freeze({
	value: DELIVERY_TYPE_B2C_VALUE,
	text: DELIVERY_TYPE_B2C_TEXT
});

export const DELIVERY_TYPES = Object.freeze([
	DELIVERY_TYPE_B2B,
	DELIVERY_TYPE_B2C
]);
