<template>
	<v-card>
		<v-card-title v-if="title" class="pb-0">
			<v-layout wrap>
				<v-flex style="display: flex; flex-direction: row; align-items: center">
					<v-avatar size="38" class="mr-3">
						<v-icon color="#342B1D" small>fas fa-user</v-icon>
					</v-avatar>
					<h4>{{title}}</h4>
				</v-flex>
			</v-layout>
		</v-card-title>
		<v-card-text style="padding: 32px">
			<v-layout wrap>
				<v-flex>
					<h4>{{ name }}</h4>

					<p class="mb-0">
						<i aria-hidden="true" class="v-icon mr-1 material-icons theme--light"
							style="font-size: 16px;">phone</i> {{ phone | telefone }}
					</p>
					<p class=" mb-0" style=" display: flex;">
						<i aria-hidden="true" class="v-icon mr-1 material-icons theme--light"
							style="font-size: 16px;">mail</i> {{ mail }}
					</p>
					<p class="mb-0" v-if="document">
						<i aria-hidden="true" class="v-icon mr-1 material-icons theme--light"
							style="font-size: 16px;">folder_shared</i> {{ document }}
					</p>
					<p class="mb-0" v-if="birthday">
						{{ birthday | formatDate('DD/MM/YYYY') }} ({{ birthday  }})
					</p>
					<p class="mb-0">
						<template v-if="age">{{ age }} anos</template>
						<template >{{ `${age?' - ':''}${gender || ''}` }}</template>
					</p>
				</v-flex>
			</v-layout>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'PersonInfo',
	props: {
		title: { type: String },
		name: { type: String },
		mail: { type: String },
		phone: { type: String },
		document: { type: String },
		birthday: { type: String },
		age: { type: Number },
		gender: { type: String }
	}
};
</script>
