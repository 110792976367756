import Vue from 'vue';
import moment from 'moment';
import { getStatusBox } from '@Consts/status-box';
import {
	mascaraCpf, mascaraDinheiro, formatDate, mascaraPorcentagem
} from '@Util/functions';

import { formatCellphone } from 'luiz-fns';

Vue.filter('formatDate', formatDate);

Vue.filter('idade', nascimento => {
	if (nascimento && nascimento.length === 10) {
		const data_atual = moment();
		let data_nascimento;

		if (nascimento.indexOf('-') === -1)
			data_nascimento = moment.duration(data_atual.diff(moment(nascimento, 'DD/MM/YYYY')));
		else
			data_nascimento = moment.duration(data_atual.diff(moment(nascimento, 'YYYY-MM-DD')));

		if (data_nascimento.years() < 2) {
			if (data_nascimento.years() < 1) {
				if (data_nascimento.months() === 1)
					return `${data_nascimento.months()} mês e ${data_nascimento.days()} dia(s)`;
				return `${data_nascimento.months()} meses e ${data_nascimento.days()} dia(s)`;
			}
			if (data_nascimento.months() === 1)
				return '1 ano e 1 mês';
			return `1 ano e ${data_nascimento.months()} meses`;
		} return `${data_nascimento.years()} anos`;
	}

	return nascimento;
});

Vue.filter('cpf', mascaraCpf);

Vue.filter('telefone', formatCellphone);

Vue.filter('dinheiro', mascaraDinheiro);

Vue.filter('porcentagem', mascaraPorcentagem);

Vue.filter('statusBox', status => getStatusBox(status));
