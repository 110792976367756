<template>
	<Dialog titulo="Informações" @close="$emit('update:close', false)">
		<div class="loading" v-if="carregando">
			<v-progress-circular indeterminate color="primary"/>
		</div>
		<v-container v-else fluid grid-list-lg class="pa-0">
			<v-layout wrap>
				<v-flex xs12>
					<InformacoesPacienteResponsavelEndereco :paciente="assinatura.paciente" :responsaveis="assinatura.paciente.responsaveis" :endereco="assinatura.endereco" disabled />
				</v-flex>
				<v-card style="width: 100%">
					<v-card-title class="pb-0">
						<v-avatar size="38" class="mr-3">
							<v-icon color="white" small>fas fa-exclamation</v-icon>
						</v-avatar>
						<h3>Observação</h3>
					</v-card-title>
					<v-card-text>
						<v-textarea v-model="assinatura.observacao" :readonly="true" hide-details/>
					</v-card-text>
				</v-card>
				<v-flex md6 v-for="(medicamento, index) in medicamentos" :key="index">
					<MedicamentoCard :disabled="true" :estoque="estoque_medicamentos" somente_leitura :medicamento="medicamento"
						:mostrar_excluir="false" :data_base_pausa="data_base_pausa"/>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { isBoxBeforePicking } from '@Util/functions';

import MedicamentoCard from '@Componentes/medicamento/medicamento-card';
import InformacoesPacienteResponsavelEndereco from '@Componentes/assinatura/informacoes-paciente-responsavel-endereco';
import { BoxPresenter } from '@Presenter/box-presenter';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { PacientePresenter } from '@Presenter/paciente-presenter';
import moment from 'moment';

export default {
	name: 'DialogInformacoesBox',
	components: { InformacoesPacienteResponsavelEndereco, MedicamentoCard },
	props: {
		assinatura_id: {
			type: Number,
			required: true
		},
		box_id: {
			type: Number,
			default: null
		}
	},
	data: () => ({
		assinatura: null,
		medicamentos: [],
		carregando: true,
		carrega_estoque: false,
		estoque_medicamentos: []
	}),
	async created() {
		const promises = [AssinaturaPresenter.show(this.assinatura_id)];
		if (this.box_id)
			promises.push(BoxPresenter.show(this.box_id));
		const [assinatura, box] = await Promise.all(promises);
		this.assinatura = assinatura;
		this.medicamentos = box ? box.medicamentos : assinatura.medicamentos;
		this.carregando = false;
		if (this.assinatura) {
			this.carrega_estoque = true;
			PacientePresenter.estoque(this.assinatura.paciente_id, { assinatura_id: this.assinatura.id })
				.then(estoque => {
					this.estoque_medicamentos = estoque;
				})
				.catch(() => {
					this.estoque_medicamentos = [];
				})
				.finally(() => {
					this.carrega_estoque = false;
				});
		}
	},
	computed: {
		data_base_pausa() {
			let date;
			// verifica se box ja passou pelo picking
			const box = this.assinatura.boxes[0];
			const antesPicking = box && isBoxBeforePicking(box.status);

			if (antesPicking)
				date = moment(box.inicio, 'YYYY-MM-DD');
			else
				date = moment(this.assinatura.data_proxima_box, 'DD/MM/YYYY');

			return date;
		}
	}
};
</script>
