<template>
	<v-card flat>
		<v-container fluid grid-list-lg class="pa-1">
			<v-flex xs4>
				<v-text-field box v-model="valor_servico" x4 label="Valor" v-money
					color="green" />
			</v-flex>
			<v-flex>
				<v-text-field box v-model="paciente.nome" label="Nome" v-validate="{ required: true }"
					data-vv-name="nome" :error-messages="errors.first('nome')" color="green" />
			</v-flex>
			<v-layout row>
				<v-flex>
					<v-text-field box label="CPF" v-model="paciente.cpf" v-validate="{ required: true, cpf: true }"
						data-vv-name="cpf" :error-messages="errors.first('cpf')" color="green" />
				</v-flex>
				<v-flex>
					<v-text-field box label="E-mail" v-model="paciente.email" v-validate="{ required: true, email: true }"
						data-vv-name="email" :error-messages="errors.first('email')" color="green" />
				</v-flex>
			</v-layout>
			<v-layout row>
				<v-flex>
					<Cep :endereco="endereco" @endereco-encontrado="end => endereco = end" />
					<Form :item="endereco" :campos="campos" />
				</v-flex>
			</v-layout>
			<v-layout>
				<v-flex class="display-flex justify-end">
					<v-btn depressed class="primary my-0" @click="onClick" :loading="enviando">
						Emitir NFS-e
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>
	</v-card>
</template>

<script>
import Cep from '@Componentes/cep';
import { NfsPresenter } from '@Presenter/nfs-presenter';
import { EnderecoPresenter } from '@Presenter/endereco-presenter';

export default {
	name: 'GerarNFSE',
	$_veeValidate: { validator: 'new' },
	components: { Cep },
	props: {
		box: {
			type: Object,
			required: true
		},
		assinatura: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		paciente: null,
		endereco: null,
		valor_servico: 0,
		campos: {},
		enviando: false
	}),
	created() {
		this.paciente = this.$lodash.clone(this.box.assinatura.paciente);
		this.endereco = this.$lodash.clone(this.box.endereco);
		this.valor_servico = Number(this.$functions.onlyNumber(this.assinatura.valor_servico));
		import('@Fields/endereco-fields').then(Fields => {
			this.campos = this.$lodash.merge(this.$lodash.cloneDeep(Fields.default), this.$lodash.cloneDeep(this.custom_field || {}));
		});
	},
	methods: {
		async onClick() {
			const is_valid = await this.$validator.validateAll();
			if (!is_valid) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
				return;
			}
			this.enviando = true;
			const enderecoAtualizado = await this.atualizarEndereco(this.endereco.cep);

			if (enderecoAtualizado) {
				NfsPresenter.issue({
					box_id: this.box.id,
					assinatura_id: this.assinatura.id,
					nome: this.paciente.nome,
					cpf: this.paciente.cpf,
					email: this.paciente.email,
					telefone: this.paciente.telefone,
					endereco: this.endereco,
					valor_servico: Number(this.$functions.onlyNumber(this.valor_servico))
				})
					.then(() => {
						this.$emit('carregar-nfs');
					})
					.catch(error => {
						this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: error.message });
					})
					.finally(() => {
						this.enviando = false;
					});
			}
		},
		atualizarEndereco(cep) {
			return new Promise(resolve => {
				EnderecoPresenter.buscaCep(cep)
					.then(endereco => {
						this.endereco.ibge = endereco.ibge;
						this.endereco.uf = endereco.uf;
						resolve(true);
					})
					.catch(e => {
						this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: e.message });
						resolve(false);
					});
			});
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
