import { render, staticRenderFns } from "./dialog-new-category.vue?vue&type=template&id=89d27162&scoped=true"
import script from "./dialog-new-category.vue?vue&type=script&lang=js"
export * from "./dialog-new-category.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89d27162",
  null
  
)

export default component.exports