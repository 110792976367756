import HeaderProcesso from '@Componentes/header-processo';
import DataTableProcesso from '@Componentes/data-table-processo';
import { BoxPresenter } from '@Presenter/box-presenter';
import { StatusBoxPresenter } from '@Presenter/status-box-presenter';
import { geraPdfEtiquetaForaDaBox } from '@Util/pdf-etiqueta-fora-da-box';
import { isEqual } from 'lodash';

export const ProcessoMixin = {
	components: { HeaderProcesso, DataTableProcesso },
	data: () => ({
		BoxPresenter,
		busca: '',
		dias: 18,
		statusToShow: [],
		demandRegion: false,
		statusToHide: [],
		response: [],
		buttons: [],
		gerando: false,
		carregando: false,
		selecionados: [],
		item_selecionado: null,
		dialog_status_history: false,
		selectedOrders: [],
		loadingOrders: false,
		tabSelected: 'boxesTab' // boxesTab | ordersTab
	}),
	computed: {
		boxes() {
			return this.response.filter(box => !(box.status_box || []).find(status => this.statusToHide.some(s => s === status)));
		},
		isCompra() {
			return this.$route.name === 'Compra';
		},
		isAtendimento() {
			return this.$route.name === 'Atendimento';
		},
		presenter() {
			return BoxPresenter;
		}
	},
	created() {
		this.getData();
	},
	watch: {
		tabSelected: {
			async handler(val, oldVal) {
				if (!isEqual(val, oldVal)) {
					this.selecionados = [];
					this.selectedOrders = [];
				}
			},
			deep: true
		}
	},
	methods: {
		request() {
			return this.BoxPresenter.index({ status: this.statusToShow, demand_region: this.demandRegion });
		},
		getData() {
			this.carregando = true;
			this.request()
				.then(response => {
					this.response = response.map(o => this.formatBox(o));
				})
				.finally(() => {
					this.carregando = false;
				});
		},
		abrirDialog(item, dialog_name) {
			this.item_selecionado = item;
			this[dialog_name] = true;
		},
		updateStatus(status) {
			return () => new Promise((resolve, reject) => {
				StatusBoxPresenter.store({
					box_id: this.item_selecionado.id,
					status
				})
					.then(() => {
						this.afterSave(resolve);
					})
					.catch(() => {
						reject();
					});
			});
		},
		afterSave(resolve) {
			this.getData();
			this.$store.dispatch('SHOW_SNACKBAR', { message: 'Status da box atualizado com sucesso!' });
			if (resolve)
				resolve();
		},
		formatBox(box) {
			return {
				...box,
				box_id: box.id,
				paciente: box.assinatura.paciente
			};
		},
		gerarListaCompra(tipo) {
			return new Promise(async (resolve, reject) => {
				this.gerando = true;
				try {
					const property = this.isCompra || this.isAtendimento ? 'assinatura_id' : 'box_id';
					const ids = this.selecionados.map(a => a[property]);
					const promises = [this.presenter.listaDeCompras({ ids, compra: this.isCompra })];
					if (tipo === 'pdf')
						promises.push(import('@Util/pdf-lista-compra'));
					else
						promises.push(import('@Util/excel-lista-compra'));
					const responses = await Promise.all(promises);
					const { geraListaDeCompras } = responses[1];
					geraListaDeCompras(responses[0]);
					if (this.isCompra)
						this.getData();
				} catch (error) {
					reject();
				}
				this.gerando = false;
				resolve();
			});
		},
		gerarEtiquetaRastreio({ box_id }) {
			BoxPresenter.gerarEtiquetas({ ids: [box_id] })
				.then(({ etiquetasDeRastreio }) => {
					geraPdfEtiquetaForaDaBox(etiquetasDeRastreio);
				});
		},
		switchTab(tab) {
			this.tabSelected = tab;
		}
	}
};
