<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>fas fa-history</v-icon>
			</v-avatar>
			<h3>Histórico da assinatura</h3>
		</v-card-title>
		<v-card-text>
			<v-data-table disable-initial-sort :headers="headers" :items="historico" class="tabela-boxes" :pagination.sync="pagination"
				:no-data-text="(carregando) ? 'Carregando...' : (erro) ? 'Erro ao carregar, tente novamente' : 'Essa assinatura ainda não foi atualizada'">
				<template v-slot:items="props">
					<tr class="pointer" @click="abrirHistorico(props.item)">
						<td>{{ props.item.usuario }}</td>
						<td>
							{{ props.item.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}
							<span>{{(props.item.is_new ? '(Criação)' : '') }}</span>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card-text>

		<v-dialog persistent v-model="dialog_historico" fullscreen hide-overlay scrollable
			transition="fade">
			<DialogHistorico v-if="dialog_historico" :id="historico_selecionado.id" @close="dialog_historico = false" />
		</v-dialog>
	</v-card>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import DialogHistorico from './dialog-historico';

export default {
	components: { DialogHistorico },
	props: {
		assinatura_id: {
			type: Number,
			required: false
		}
	},
	data() {
		return {
			dialog_historico: false,
			historico_selecionado: null,
			headers: [
				{ text: 'Alterada por', value: 'usuario' },
				{ text: 'Data da alteração', value: 'created_at' }
			],
			pagination: { descending: true, sortBy: 'created_at' },
			historico: [],
			carregando: true,
			erro: false
		};
	},
	created() {
		AssinaturaPresenter.historico(this.$route.name.includes('Clinica') ? this.assinatura_id : this.$route.params.id)
			.then(response => {
				this.historico = response;
			})
			.finally(() => {
				this.carregando = false;
			});
	},
	methods: {
		abrirHistorico(historico) {
			if (historico.is_new)
				return;
			this.historico_selecionado = historico;
			this.dialog_historico = true;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.tabela-boxes {
  margin: 0 -15px -25px;
}
</style>
