<template>
	<div class="medicamentos-wrapper px-md-5">
		<!-- Medicamentos Header -->
		<Header @novo-medicamento="crud" @gerar-planilha="dialog_gerar_planilha = true"
			@relatorio-medicamento-pacientes="relatorioMedicamentoPacientes"
			@enviar-planilha="dialog_enviar_planilha = true" @busca="b => busca = b"
			:switch.sync="apenas_excluidos" :gerando-relatorio="gerando_relatorio" />

		<!-- Conteúdo -->
		<v-card>
			<v-data-table :headers="headers" :items="items" :loading="is_loading"
				:rows-per-page-items="[10, 20, 30]" :no-data-text="error_message"
				no-results-text="Nenhum resultado encontrado" :pagination.sync="pagination"
				:total-items="total_items" rows-per-page-text="Itens por página">
				<template slot="items" slot-scope="props">
					<tr class="pointer" @click="crud(props.item)">
						<td>{{ props.item.id }}</td>
						<td>{{ props.item.produto }}</td>
						<td>{{ props.item.principio_ativo }}</td>
						<td>{{ props.item.apresentacao }}</td>
						<td>{{ props.item.laboratorio }}</td>
						<td style = "display: flex">
							<v-chip small disabled class="primary mx-0 white--text pointer">
								{{ (props.item.pbm ? props.item.preco_pbm : props.item.preco ) | dinheiro }}
							</v-chip>
							<v-tooltip v-if="props.item.pbm" top>
								<v-chip class="justify-center" color="orange" style="max-width: 20px; max-height: 20px;" slot="activator" dark
									small><strong>%</strong></v-chip>
								<span>{{ props.item.beneficio_pbm || "PBM" }}</span>
							</v-tooltip>
						</td>
						<td>{{ props.item.qtd_assinaturas }}</td>
						<td class="pointer text-xs-center" @click.stop>
							<v-tooltip bottom>
								<v-icon slot="activator" class="mx-0 white--text pointer"
									:color="props.item.status === 1 ? 'success': props.item.status === 2 ? 'red' : 'grey'">
									fas fa-circle
								</v-icon>
								<p>{{ `${medicamentoStatus[props.item.status || 0]}` }}</p>
							</v-tooltip>
						</td>
					</tr>
				</template>
			</v-data-table>
			<v-flex xs12 style="margin-left: 16px">
				<v-icon class="mr-1" color="success">fas fa-circle</v-icon>
				<span class="mr-3">Disponível</span>
				<v-icon class="mr-1" color="grey">fas fa-circle</v-icon>
				<span class="mr-3">Sujeito a disponibilidade</span>
				<v-icon class="mr-1" color="red">fas fa-circle</v-icon>
				<span class="mr-3">Em falta</span>
				<v-chip class="justify-center" color="orange" style="max-width: 24px; max-height:24px; margin-bottom: 13px;  " slot="activator" dark
					small><strong>%</strong></v-chip>
				<span class="mr-3">Informações de PBM</span>
			</v-flex>
		</v-card>

		<!-- Dialog medicamento -->
		<v-dialog persistent v-model="dialog_crud" scrollable transition="fade" max-width="920px">
			<transition>
				<DialogMedicamento v-if="dialog_crud" :item_pai="item_selecionado" :close.sync="dialog_crud"
					@inserir="salvo" @excluir="excluido" />
			</transition>
		</v-dialog>

		<!-- Dialog gerar planilha -->
		<v-dialog persistent v-model="dialog_gerar_planilha" scrollabl transition="fade"
			max-width="520px">
			<transition>
				<DialogGerarPlanilha v-if="dialog_gerar_planilha" :close.sync="dialog_gerar_planilha" />
			</transition>
		</v-dialog>

		<!-- Dialog enviar planilha -->
		<v-dialog persistent v-model="dialog_enviar_planilha" scrollable transition="fade"
			max-width="400px">
			<transition>
				<DialogEnviarPlanilha v-if="dialog_enviar_planilha" :close.sync="dialog_enviar_planilha"
					@planilha-enviada="planilhaEnviada" />
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { ListagemMixin } from '@Mixins/listagem';
import medicamentoStatus from '@Consts/medicamento-status';
import { MedicamentoPresenter } from '@Presenter/medicamento-presenter';
import DialogEnviarPlanilha from './dialog-enviar-planilha';
import DialogGerarPlanilha from './dialog-gerar-planilha';
import DialogMedicamento from './dialog-medicamento';
import Header from './header';

export default {
	name: 'Medicamentos',
	mixins: [ListagemMixin],
	components: {
		Header, DialogMedicamento, DialogEnviarPlanilha, DialogGerarPlanilha
	},
	data: () => ({
		dialog_enviar_planilha: false,
		dialog_gerar_planilha: false,
		pagination: {},
		total_items: 0,
		apenas_excluidos: false,
		novo_preco: null,
		nova_quantidade: null,
		novo_status: null,
		gerando_relatorio: false,
		medicamentoStatus,
		headers: [
			{ text: 'id', value: 'id' },
			{ text: 'Produto', value: 'produto' },
			{ text: 'Principio ativo', value: 'principio_ativo' },
			{ text: 'Apresentação', value: 'apresentacao' },
			{ text: 'Laboratório', value: 'laboratorio' },
			{ text: 'Preço', value: '', sortable: false },
			{ text: 'Qtd Ass', value: 'qtd_assinaturas' },
			{ text: 'Status', value: 'status', sortable: false }
		]
	}),
	computed: {
		request() {
			return MedicamentoPresenter.search;
		}
	},
	watch: {
		pagination: {
			handler() {
				this.index();
			},
			deep: true
		},
		busca() {
			this.index();
		},
		apenas_excluidos() {
			this.index();
		}
	},
	methods: {
		getIndexProps() {
			return {
				onlyTrashed: this.apenas_excluidos,
				search: this.busca,
				...this.pagination
			};
		},
		relatorioMedicamentoPacientes() {
			this.gerando_relatorio = true;
			MedicamentoPresenter.countAssinaturas()
				.then(medicamentos => {
					import('./excel-pacientes.medicamento')
						.then(Module => {
							Module.default(medicamentos);
						});
				})
				.finally(() => {
					this.gerando_relatorio = false;
				});
		},
		onResponse(response) {
			this.total_items = response.total;
			this.items = response.items;
		},
		planilhaEnviada() {
			this.dialog_enviar_planilha = false;
			this.index();
		}
	}
};
</script>
