<template>
	<div v-if="loading" class="loading">
		<v-progress-circular indeterminate color="primary" />
	</div>
	<v-container v-else class="orcamento-container pa-3" fluid grid-list-lg>
		<v-layout wrap>
			<v-flex :class="orcamento.memed ? 'xs6' : 'xs12'">
				<Card :titulo="orcamento.client.name" icone="fas fa-user" class="prospect">
					<IconText icon="fas fa-at" :text="orcamento.client.email" />
					<IconText icon="fas fa-phone" :text="orcamento.client.cellphone" />
					<IconText icon="fas fa-building" :text="orcamento.client.city" />
					<IconText icon="fas fa-asterisk" :text="orcamento.client.contactPreference" />
				</Card>
			</v-flex>
			<v-flex v-if="orcamento.memed" xs6>
				<Card titulo="Memed" icone="fas fa-pills" class="prospect">
					<IconText icon="fas fa-signature" :text="orcamento.memed.clientName" />
					<IconText icon="fas fa-user-md" :text="orcamento.memed.doctorName" />
					<IconText icon="fas fa-calendar-alt" :text="formatDate(orcamento.memed.date, 'DD/MM/YYYY HH:mm:ss')" />
				</Card>
			</v-flex>
			<v-flex v-if="(orcamento.prescriptions || []).length" xs12>
				<Card titulo="Prescrições" icone="fas fa-pills">
					<v-container class="pa-0" fluid grid-list-lg>
						<v-layout wrap>
							<v-flex xs6 md2 v-for="p in orcamento.prescriptions" :key="p.id" class="text-center">
								<img :src="p.link" @click="openImage(p.link)">
							</v-flex>
						</v-layout>
					</v-container>
				</Card>
			</v-flex>
			<v-flex xs6 v-for="(medicine, i) in medicines" :key="i">
				<Card :titulo="medicine.name">
					<template v-if="medicine.memed" v-slot:icon>
						<v-tooltip class="ml-1" top>
							<v-icon slot="activator" small color="primary">info</v-icon>
							<span>Este medicamento veio de uma prescrição digital Memed</span>
						</v-tooltip>
					</template>
					<IconText icon="fas fa-prescription-bottle" :text="medicine.description" />
					<IconText icon="fas fa-pills" :text="medicine.usage" />
				</Card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { FarmeOSPresenter } from '@Presenter/farmeos-presenter';
import { formatDate } from '@Util/functions';

export default {
	name: 'orcamento',
	props: {
		id: {
			type: String,
			required: true
		}
	},
	data: () => ({
		formatDate,
		orcamento: null,
		loading: true
	}),
	computed: {
		medicines() {
			if (!this.orcamento)
				return [];
			const medicines = [];
			if (this.orcamento.memed)
				medicines.push(...this.orcamento.memed.medicines.map(med => ({ ...med, memed: true })));
			return [...medicines, ...this.orcamento.medicines];
		}
	},
	created() {
		FarmeOSPresenter.showQuotation(this.id)
			.then(response => {
				this.orcamento = response;
			})
			.finally(() => {
				this.loading = false;
			});
	},
	methods: {
		openImage(link) {
			window.open(link, '_blank');
		}
	}
};
</script>

<style lang="scss" scoped>
.orcamento-container {
	background: #fafafa;

	img {
		cursor: pointer;
		height: 100px;
		border-radius: 4px;
	}

	::v-deep .v-card {
		min-height: 106px;
	}

	::v-deep .prospect {
		min-height: 162px;
	}
}
</style>
