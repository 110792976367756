<template>
	<section>
		<v-data-table
			v-model="selected"
			:headers="headers"
			:items="items"
			hide-actions
			item-key="id"
			no-data-text="Nenhum medicamento"
			select-all>
			<template v-slot:headers="props">
				<tr>
					<th width="10px" v-if="selectable" class="text-left">
						<!--
						<v-checkbox
							:input-value="props.all"
							:indeterminate="props.indeterminate"
							primary
							hide-details
							@click.stop="toggleAll"></v-checkbox>
						-->
					</th>
					<th
						v-for="header in props.headers"
						:key="header.text"
						class="column text-left">
						{{ header.text }}
					</th>
				</tr>
			</template>
			<template v-slot:items="props">
				<tr
					:active="selectable && props.selected"
					:class="{'pointer': selectable && !props.item.keep }"
					@click="props.item.keep ? () => {} : props.selected = !props.selected">
					<td width="10px" v-if="selectable" class="text-center">
						<v-checkbox
							:input-value="props.selected || props.item.keep"
							:disabled="props.item.keep"
							primary
							hide-details></v-checkbox>
					</td>
					<td class="text-center">{{ props.item.id }}</td>
					<td class="text-center">{{ props.item.name }}</td>
					<td class="text-center">{{ props.item.quantity }}</td>
				</tr>
			</template>
		</v-data-table>
	</section>

</template>

<script>
export default {
	props: {
		items: {
			required: false,
			type: Array,
			default: () => []
		},
		selectable: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			selected: []
		};
	},
	computed: {
		headers() {
			return [{ text: 'id', sortable: false }, { text: 'Nome', sortable: false }, { text: 'Quantidade', sortable: false, align: 'right' }];
		}
	},
	methods: {
		toggleAll() {
			if (this.selected.length)
				this.selected.concat(this.items.filter(i => i.keep).slice());
			else
				this.selected = this.items.filter(i => !i.keep).slice();
		},
		changeSort(column) {
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		}
	},
	watch: {
		selected(val) {
			this.$emit('selected', val);
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
