<template>
	<div>
		<v-text-field mask="##/##/####" return-masked-value v-model="model" :label="_label" :disabled="disabled"
			@input="updateValue" v-validate="validate" :data-vv-scope="_scope" :data-vv-name="`${_tag}`"
			:error-messages="errors.collect(`${_collect}`)" :outline="outline" :box="box" :solo="solo"
			:flat="flat" :hide-details="hideDetails" append-icon="event" @click:append="() => (picker = !picker)"
			:dark="dark" :placeholder="placeholder" :color="color" :hint="hint" :persistent-hint="persistentHint"
			:background-color="backgroundColor" />
		<v-dialog lazy v-model="picker" width="290px" transition="fade"
			full-width>
			<v-date-picker v-model="picked_value" @input="valuePicked" color="green" locale="pt-br"
				:events="array_events" :min="minData" :max="maxData" />
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	props: {
		value: { // prop value is the v-model
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: 'TITLE'
		},
		validate: {
			type: Object,
			default() {
				return { data: true };
			}
		},
		disabled: {
			type: Boolean,
			default: false
		},
		outline: {
			type: Boolean,
			default: false
		},
		box: {
			type: Boolean,
			default: false
		},
		solo: {
			type: Boolean,
			default: false
		},
		flat: {
			type: Boolean,
			default: false
		},
		hideDetails: {
			type: Boolean,
			default: false
		},
		nMarkDays: {
			type: Number,
			default: 0
		},
		scope: {
			type: String,
			default: ''
		},
		color: {
			type: String,
			default: null
		},
		placeholder: {
			type: String,
			default: null
		},
		dark: {
			type: Boolean,
			default: false
		},
		minData: {
			type: String,
			default: null
		},
		maxData: {
			type: String,
			default: null
		},
		hint: {
			type: String,
			default: null
		},
		persistentHint: {
			type: Boolean,
			default: false
		},
		backgroundColor: {
			type: String,
			default: null
		}
	},
	watch: {
		value(val) {
			this.model = val;
			this.picked_value = this.FormatDateToRequest(val);
			this.setEvents();
		}
	},
	inject: ['$validator'], // inject parent's validator
	name: 'TextDateField',
	data() {
		return {
			picker: false,
			array_events: null,
			picked_value: this.FormatDateToRequest(this.value),
			model: this.value
		};
	},
	mounted() {
		this.setEvents();
	},
	methods: {
		setEvents() {
			this.array_events = [];
			for (let i = 0; i < this.nMarkDays; i += 1)
				this.array_events.push(moment(this.FormatDateToRequest(this.model)).add(i, 'days').format('YYYY-MM-DD'));
		},
		valuePicked() {
			this.model = this.FormatDateFromRequest(this.picked_value);
			this.picker = false;
			this.updateValue();
		},
		updateValue() {
			this.$emit('input', this.model);
		},
		FormatDateFromRequest(date) { // returns DD/MM/YYYY
			if (!date || date.length !== 10)
				return null;
			const [year, month, day] = date.split('-');
			return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
		},
		FormatDateToRequest(date) { // returns YYYY-MM-DD
			if (!date || date.length !== 10)
				return null;
			const [day, month, year] = date.split('/');
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
		}
	},
	computed: {
		_label() {
			return `${this.label}${this.validate.required ? ' *' : ''}`;
		},
		_tag() {
			return this.label.toLowerCase().replace(/\W+/g, '_');
		},
		_collect() {
			return `${this.scope ? `${this.scope}.` : ''}${this._tag}`;
		},
		_scope() {
			return this.scope ? String(this.scope) : null;
		},
		_disabled_date() {
			if (this.disabledDate) {
				const ano = this.disabledDate.slice(6, 10);
				const mes = this.disabledDate.slice(3, 5);
				const dia = this.disabledDate.slice(0, 2);
				return { to: new Date(ano, mes, dia) - 8640000 };
			}
			return null;
		}
	}
};
</script>
