<template>
	<CardLogin>
		<section style="width: 100%">
			<div :class="{'text-center': entrando}" class="mt-5">
				<v-text-field v-model="email" color="green_strong" label="E-mail" placeholder="seu.email@farme.com.br"
					@keyup.enter="entrar" data-vv-name="e-mail" v-validate="{ required: true, email: true, regex: /^.*@farme.com.br$/ }"
					:error-messages="errors.collect('e-mail')" />
				<v-text-field v-model="password" type="password" color="green_strong" label="Senha"
					@keyup.enter="entrar" placeholder="********" data-vv-name="senha" v-validate="{ required: true, min: 6 }"
					:error-messages="errors.collect('senha')" />

				<v-layout mt-3 mb-4 align-center justify-space-around>
					<div class="d-flex align-center" style="cursor: pointer">
						<img  @click="changeState('mg')" :class="{bg: selectMg}" class="selectState mr-2" width="40px"
							:src="MG"
							alt="imagem de minas">
						<span style="font-weight: bold;">BH</span>
					</div>

					<div class="d-flex align-center" style="cursor: pointer">
						<img @click="changeState('sp')" :class="{bg: selectSp}" class="selectState mr-2" width="40px" :src="SP"
							alt="imagem de são paulo">
						<span style="font-weight: bold;">SP</span>
					</div>

				</v-layout>

				<v-btn depressed :loading="entrando" :icon="entrando" @click.stop="entrar" :block="!entrando && $vuetify.breakpoint.xsOnly"
					class="ml-0 green_strong white--text" :style="entrando ? null : 'padding: 0 64px'">
					Entrar
				</v-btn>
				<v-btn outline v-if="!entrando" to="/cadastrar" :block="$vuetify.breakpoint.xsOnly" class="mr-0 px-4 green green_strong--text"
					style="border: 0px">
					Cadastrar
				</v-btn>
			</div>
			<footer class="title mt-4">
				<p class="mb-2 pb-0">
					Esqueceu sua senha? <a @click="dialog_esqueceu_senha = true">Clique aqui</a>
				</p>
				<p class="mb-0 pb-0">
					Reenviar e-mail de confirmação? <a @click="dialog_reenviar_email = true">Clique aqui</a>
				</p>
			</footer>

			<v-dialog persistent scrollable v-model="dialog_esqueceu_senha" transition="fade" max-width="490px">
				<DialogEsqueceuSenha v-if="dialog_esqueceu_senha" :close.sync="dialog_esqueceu_senha" />
			</v-dialog>

			<v-dialog scrollable persistent v-model="dialog_reenviar_email" transition="fade" max-width="490px">
				<DialogReenviarEmail v-if="dialog_reenviar_email" :close.sync="dialog_reenviar_email" />
			</v-dialog>

		</section>
	</CardLogin>

</template>

<script>
import MG from '@Assets/images/mg.png';
import SP from '@Assets/images/sp.png';
import { TOKEN } from '@Consts/strings';
import logo from '@Assets/images/FarmeOS.svg';
import { UserPresenter } from '@Presenter/user-presenter';
import { unidades, setCurrentCompanyUnit } from '@Config/unidades';
import DialogEsqueceuSenha from './dialog-esqueceu-senha';
import DialogReenviarEmail from './dialog-reenviar-email';
import CardLogin from './card-login';

export default {
	name: 'Entrar',
	$_veeValidate: { validator: 'new' },
	components: {
		DialogEsqueceuSenha, DialogReenviarEmail, CardLogin
	},
	data: () => ({
		logo,
		MG,
		SP,
		unidades: [],
		entrando: false,
		companyUnity: null,
		email: null,
		password: null,
		dialog_esqueceu_senha: false,
		dialog_reenviar_email: false
	}),
	created() {
		this.unidades = unidades;
		this.$getLocation({})
			.then(coordinates => {
				if (
					// range de Belo Horizonte
					coordinates.lat > -20.08498
					&& coordinates.lat < -19.62386
					&& coordinates.lng > -44.24900
					&& coordinates.lng < -43.62004
				)
					this.companyUnity = unidades[0].id;
				else if (
					// range de São Paulo
					coordinates.lat > -23.79724
					&& coordinates.lat < -23.35672
					&& coordinates.lng > -46.90291
					&& coordinates.lng < -46.28218
				)
					this.companyUnity = unidades[1].id;
			})
			.catch(() => {
			});
	},
	mounted() {
		if (this.$store.getters.msg_toast)
			this.$store.dispatch('SHOW_SNACKBAR', { message: this.$store.getters.msg_toast });
	},
	computed: {
		selectMg() {
			return this.companyUnity === 1;
		},
		selectSp() {
			return this.companyUnity === 2;
		}
	},
	methods: {
		entrar() {
			this.$validator.validateAll().then(valido => {
				if (!valido) {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
					return;
				}

				if (this.companyUnity === null) {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Selecione uma unidade' });
					return;
				}

				this.entrando = true;
				setCurrentCompanyUnit(this.companyUnity);

				UserPresenter.login({ email: this.email, password: this.password })
					.then(response => {
						localStorage.setItem(TOKEN, response.access_token.token);
						this.$store.dispatch('SET_VISAO_COLABORADOR', false);
						this.$store.dispatch('CONTEXTO', response.contexto);
						this.$root.$emit('entrando');

						setTimeout(() => {
							this.$router.push({ name: 'Perfil' });
							this.entrando = false;
						}, 1500);
					})
					.catch(() => {
						this.entrando = false;
					});
			});
		},
		changeState(state) {
			if (state === 'mg') {
				const bh = this.unidades[0].id;
				this.companyUnity = bh;
			} else {
				const sp = this.unidades[1].id;
				this.companyUnity = sp;
			}
		}
	}
};
</script>

<style scoped>
	.selectState{
		border: 2px solid #000;
		border-radius: 40px;
	}

	.bg{
		background: #FFC875;
		border: 2px solid #FFC875;
		transition: all 0.3s;
	}

</style>
