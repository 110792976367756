import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = '/regras-de-prescricao';

export const PrescriptionRulesPresenter = {
	list: (params = {}) => $http.get(buildQueryParams(prefix, {
		page: params.page,
		perPage: params.perPage,
		ids: params.ids,
		sortBy: params.sortBy,
		sortDirection: params.sortDirection
	}))
};
