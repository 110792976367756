import {
	userIsColaborador, userIsRT, userIsClinica, userIsVendedor, userIsAdministrador, userIsFinanceiro, userIsFarmeos
} from '@Util/functions';

export const contexto = state => state.contexto;
export const visao_colaborador = state => state.visao_colaborador;
export const user = state => (state.contexto || {}).user;
export const eh_colaborador = (state, getters) => userIsColaborador(getters.user);
export const eh_rt = (state, getters) => userIsRT(getters.user);
export const eh_clinica = (state, getters) => userIsClinica(getters.user);
export const eh_vendedor = (state, getters) => userIsVendedor(getters.user);
export const eh_finaneceiro = (state, getters) => userIsFinanceiro(getters.user);
export const eh_administrador = (state, getters) => userIsAdministrador(getters.user);
export const allow_delete_box = (state, getters) => getters.user?.permissionToDeleteBox;
export const allow_apply_discount_ilpi = (state, getters) => getters.user?.permissionToApplyDiscountIlpi;
export const allow_update_calendario = (state, getters) => getters.user?.permissionToUpdateCalendario;
export const allow_edit_discount_ilpi = (state, getters) => getters.user?.permissionToEditDiscountIlpi;
export const eh_tech_team = (state, getters) => getters.user?.permissionToTechTeam;
export const eh_farmeos = (state, getters) => userIsFarmeos(getters.user);
export const getNovabox = state => state.novabox;
export const getSnackbar = state => state.snackbar;
export const msg_toast = state => state.msg_toast;
export const version = state => state.version;
export const getFeatureFlag = state => function (name) {
	const fs = state.featureFlags[name];
	if (fs)
		return fs;
	return null;
};

export const isFeatureFlagEnabled = state => function (name) {
	const fs = getFeatureFlag(state)(name);
	if (!fs)
		return false;
	return fs.enabled;
};
