<template>
	<Dialog :titulo="is_new ? 'Cadastrar tag' : 'Editar tag'" btn_cancelar="Cancelar"
		:btn_acao="is_new ? 'Cadastrar' : 'Editar'" :btn_acao_disable="!habilitarBtnAcao" :btn_excluir="messagem_dialog_excluir.btn_excluir"
		@acao="submeter()" @excluir="modal_excluir = true" @close="fechaModal()" :loading="salvando" :carregando="loading">

		<v-container fluid grid-list-lg class="pa-0">
			<Form :item="item" :campos="campos" />
		</v-container>

		<v-dialog persistent scrollable v-model="modal_excluir" transition="fade" max-width="340px">
			<transition>
				<Dialog v-if="modal_excluir" :titulo="messagem_dialog_excluir.titulo" :subtitulo="messagem_dialog_excluir.subtitulo"
					:btn_acao="messagem_dialog_excluir.btn_acao" btn_acao_class="error" btn_cancelar="Cancelar"
					@acao="excluir()" :loading="salvando" @close="modal_excluir = false" />
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import { CRUDMixin } from '@Mixins/crud';

export default {
	name: 'DialogTag',
	mixins: [CRUDMixin],
	data: () => ({
		type: 'tag',
		fields_type: 'tag'
	}),
	methods: {
		setItem() {},
		onPresenterLoaded() {
			if (!this.is_new) {
				this.loading = true;
				this.requestShow(this.item_pai.id)
					.then(resp => {
						this.item = resp;
					})
					.finally(() => {
						this.loading = false;
					});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
::v-deep .v-card__actions {
  background: #f5f5f5;
  padding: 16px 32px;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;

  .v-avatar {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.06);
    transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    &:hover {
      background: rgba(0, 0, 0, 0.12);
    }

    &:active {
      background: rgba(0, 0, 0, 0.06);
    }
  }
}
</style>
