import { Validator } from 'vee-validate';
import _ from 'lodash';
import moment from 'moment';
import { onlyNumber } from '@Util/functions';

Validator.extend('telefone', {
	getMessage: field => `O campo ${field} não é válido.`,
	validate: value => {
		value = String(value).replace(/\D/g, '');
		return String(value).length >= 10 && String(value).length <= 11;
	}
});

Validator.extend('positive', {
	getMessage: field => `O campo ${field} deve ser positivo.`,
	validate: value => Number(onlyNumber(value)) > 0
});

Validator.extend('objetoRequired', {
	getMessage: field => `O campo ${field} é obrigatório.`,
	validate: value => !_.isEmpty(value)
});

Validator.extend('data', {
	getMessage: field => `O campo ${field} não é válido.`,
	validate: value => {
		if (!value || value.length < 10)
			return false;
		const data = moment(value, 'DD/MM/YYYY');
		return data.isValid();
	}
});

Validator.extend('hora', {
	getMessage: field => `O campo ${field} não é válido.`,
	validate: value => /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(value)
});

Validator.extend('cnpj', {
	getMessage: field => `O campo ${field} não é válido`,
	validate: cnpj => {
		cnpj = (cnpj || '').replace(/\D/g, '');
		if (!cnpj || cnpj.length !== 14)
			return false;

		if (cnpj === '00000000000000'
      || cnpj === '11111111111111'
      || cnpj === '22222222222222'
      || cnpj === '33333333333333'
      || cnpj === '44444444444444'
      || cnpj === '55555555555555'
      || cnpj === '66666666666666'
      || cnpj === '77777777777777'
      || cnpj === '88888888888888'
      || cnpj === '99999999999999')
			return false;

		let tamanho = cnpj.length - 2;
		let numeros = cnpj.substring(0, tamanho);
		const digitos = cnpj.substring(tamanho);
		let soma = 0;
		let pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i -= 1) {
			soma += numeros.charAt(tamanho - i) * pos;
			pos -= 1;
			if (pos < 2)
				pos = 9;
		}
		let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

		if (String(resultado) !== digitos.charAt(0))
			return false;

		tamanho += 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i -= 1) {
			soma += numeros.charAt(tamanho - i) * pos;
			pos -= 1;
			if (pos < 2)
				pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
		if (String(resultado) !== digitos.charAt(1))
			return false;

		return true;
	}
});

Validator.extend('cpf', {
	getMessage: field => `O campo ${field} não é válido`,
	validate: value => {
		value = (value || '').replace(/\D/g, '');
		if (!value || value.length !== 11)
			return false;
		// gera lista com 11 characteres repitindo numeros
		let aux = 0;
		const invalido = Array(10)
			.fill()
			.map(() => String(aux++).repeat(11));

		// elimina CPFs inválidos
		if (value.length !== 11 || invalido.includes(value))
			return false;

		// verifica primeiro digito
		let soma = 0;
		for (let i = 0; i < 9; i++)
			soma += parseInt(value[i] * (10 - i), 10);

		let reverte = 11 - (soma % 11);
		if (reverte === 10 || reverte === 11)
			reverte = 0;
		if (reverte !== parseInt(value[9], 10))
			return false;

		// verifica segundo digito
		soma = 0;
		for (let i = 0; i < 10; i++)
			soma += parseInt(value[i] * (11 - i), 10);

		reverte = 11 - (soma % 11);
		if (reverte === 10 || reverte === 11)
			reverte = 0;
		if (reverte !== parseInt(value[10], 10))
			return false;

		return true;
	}
});

Validator.extend('cpf_cnpj', {
	getMessage: field => `O campo ${field} não é válido`,
	validate: value => {
		value = value.replace(/\D/g, '');
		if (!value)
			return false;
		if (value.length === 11) {
			// gera lista com 11 characteres repitindo numeros
			let aux = 0;
			const invalido = Array(10)
				.fill()
				.map(() => String(aux++).repeat(11));

			// elimina CPFs inválidos
			if (value.length !== 11 || invalido.includes(value))
				return false;

			// verifica primeiro digito
			let soma = 0;
			for (let i = 0; i < 9; i++)
				soma += parseInt(value[i] * (10 - i), 10);

			let reverte = 11 - (soma % 11);
			if (reverte === 10 || reverte === 11)
				reverte = 0;
			if (reverte !== parseInt(value[9], 10))
				return false;

			// verifica segundo digito
			soma = 0;
			for (let i = 0; i < 10; i++)
				soma += parseInt(value[i] * (11 - i), 10);

			reverte = 11 - (soma % 11);
			if (reverte === 10 || reverte === 11)
				reverte = 0;
			if (reverte !== parseInt(value[10], 10))
				return false;

			return true;
		} if (value.length === 14) {
			if (value === '00000000000000'
        || value === '11111111111111'
        || value === '22222222222222'
        || value === '33333333333333'
        || value === '44444444444444'
        || value === '55555555555555'
        || value === '66666666666666'
        || value === '77777777777777'
        || value === '88888888888888'
        || value === '99999999999999')
				return false;

			let tamanho = value.length - 2;
			let numeros = value.substring(0, tamanho);
			const digitos = value.substring(tamanho);
			let soma = 0;
			let pos = tamanho - 7;
			for (let i = tamanho; i >= 1; i -= 1) {
				soma += numeros.charAt(tamanho - i) * pos;
				pos -= 1;
				if (pos < 2)
					pos = 9;
			}
			let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

			if (String(resultado) !== digitos.charAt(0))
				return false;

			tamanho += 1;
			numeros = value.substring(0, tamanho);
			soma = 0;
			pos = tamanho - 7;
			for (let i = tamanho; i >= 1; i -= 1) {
				soma += numeros.charAt(tamanho - i) * pos;
				pos -= 1;
				if (pos < 2)
					pos = 9;
			}
			resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
			if (String(resultado) !== digitos.charAt(1))
				return false;

			return true;
		}
		return false;
	}
});

Validator.extend('maximo', {
	getMessage: (field, args) => `O campo ${field} deve ser menor ou igual a ${args[0]}.`,
	validate: (value, args) => Number(value) <= Number(args[0])
});

Validator.extend('lte', {
	getMessage: (field, args) => `O campo ${field} deve ser menor ou igual a ${args[0]}.`,
	validate: (value, args) => Number(value) <= Number(args[0])
});

Validator.extend('gte', {
	getMessage: (field, args) => `O campo ${field} deve ser maior ou igual a ${args[0]}.`,
	validate: (value, args) => Number(value) >= Number(args[0])
});

Validator.extend('notSameNumber', {
	getMessage: (field, args) => `O campo ${field} deve ser diferente de ${args[0]}.`,
	validate: (value, args) => Number(value) !== Number(args[0])
});
