<template>
	<Dialog titulo="Confirmar envio de fatura" @close="fecharModal()"
		btn_acao="Gerar fatura" @acao="$emit('gera-fatura', creditCardOnly)"
		btn_acao_extra="Cobrar no cartão" @acao_extra="$emit('cobrar-cartao')" :btn_acao_extra_disable="cliente.default_payment_method_type !== 'credit_card' || podeParcelar"
		btn_acao_extra_2="Trocar cliente" @acao_extra_2="$emit('trocar-cliente')"
		:carregando="loading">
		<p>A fatura será enviada para a seguinte pessoa:</p>
		<ResponsavelDetalhes v-if="responsavel" :responsavel="responsavel" class="pl-4" />
		<v-checkbox v-model="creditCardOnly">
			<template v-slot:label>
				<strong>Somente cartão de crédito</strong>
			</template>
		</v-checkbox>
	</Dialog>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import ResponsavelDetalhes from './responsavel-detalhes.vue';

export default {
	components: { ResponsavelDetalhes },
	name: 'DialogConfirmarFatura',
	props: {
		assinatura: {
			type: Object,
			required: true
		},
		podeParcelar: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		cliente: {},
		creditCardOnly: false,
		loading: true,
		responsavel: null
	}),
	created() {
		this.getClienteIugu();
	},
	methods: {
		getClienteIugu() {
			AssinaturaPresenter.buscaClienteIugu(this.assinatura.iugu_id)
				.then(cliente => {
					this.cliente = cliente;
					this.responsavel = {
						nome: cliente.name,
						telefone: cliente.phone_number,
						email: cliente.email,
						cpf: cliente.cpf_cnpj
					};
				})
				.finally(() => {
					this.loading = false;
				});
		},
		fecharModal() {
			this.$emit('update:close', false);
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
