<template>
	<v-container fluid>
		<v-layout column>
			<Header :invoices="invoices" :tabs="tabs" @tabChanged="value => filterDataByStatus(value)" @statusChanged="value => getData(value)" @typeChanged="value => getData(value)"
				@onSearch="onChangeSearch"/>
			<v-divider class="my-2 divider"></v-divider>
			<InvoiceDataTable :loading="loading" :items="invoices"
				reference="DataTableInvoice" @openDetails="openDetails" @changePagination="changePagination" :pagination="pagination" :totalItems="totalInvoices"
				@load-invoice="getData(pagination)"/>
		</v-layout>
	</v-container>
</template>

<script>
import { statusEnum as invoicesStatusEnum } from '@Consts/invoices';
import { InvoicesPresenter } from '@Presenter/invoices-presenter';
import { debounce } from 'lodash';
import Header from './header.vue';
import InvoiceDataTable from './data-table.vue';

export default {
	name: 'Invoices',
	components: {
		Header, InvoiceDataTable
	},
	data() {
		return {
			invoices: [],
			totalInvoices: 0,
			loading: false,
			pagination: {
				page: 1,
				rowsPerPage: 15,
				sortBy: 'created_at',
				descending: true
			},
			tabs: [
				{
					key: '',
					text: 'Todas',
					value: 0,
					style: 'default'
				},
				{
					key: invoicesStatusEnum.PROTESTED,
					text: 'Em protesto',
					value: 0,
					style: 'default'
				},
				{
					key: invoicesStatusEnum.OVERDUE,
					text: 'Vencidas',
					value: 0,
					style: 'delay'
				}]
		};
	},
	methods: {
		openDetails(item) {
			this.$router.push({ name: 'invoice', params: { id: item.id } });
		},
		getData(filter) {
			this.pagination = {
				...this.pagination,
				...filter
			};

			this.loading = true;
			InvoicesPresenter.getAllInvoices(this.pagination)
				.then(response => {
					this.invoices = response.items;
					this.totalInvoices = response.total;
					this.updateCounters();
				}).catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao buscar as faturas', color: 'error' });
				}).finally(() => {
					this.loading = false;
				});
		},
		updateCounters() {
			const initialTabIsActive = !this.pagination.status;
			if (initialTabIsActive) {
				this.tabs.find(t => t.key === '').value = this.totalInvoices;

				InvoicesPresenter.getAllInvoices({
					status: invoicesStatusEnum.PROTESTED, type: this.pagination.type, rowsPerPage: 1
				})
					.then(response => {
						this.tabs.find(t => t.key === invoicesStatusEnum.PROTESTED).value = response.total;
					}).catch(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao buscar as faturas em Protesto', color: 'error' });
					});

				InvoicesPresenter.getAllInvoices({
					status: invoicesStatusEnum.OVERDUE, type: this.pagination.type, rowsPerPage: 1
				})
					.then(response => {
						this.tabs.find(t => t.key === invoicesStatusEnum.OVERDUE).value = response.total;
					}).catch(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao buscar as faturas Vencidas', color: 'error' });
					});
			}

			if (this.pagination.status === invoicesStatusEnum.PROTESTED)
				this.tabs.find(t => t.key === invoicesStatusEnum.PROTESTED).value = this.totalInvoices;

			if (this.pagination.status === invoicesStatusEnum.OVERDUE)
				this.tabs.find(t => t.key === invoicesStatusEnum.OVERDUE).value = this.totalInvoices;
		},
		changePagination(pagination) {
			this.pagination.page = pagination.page;
			this.pagination.rowsPerPage = pagination.rowsPerPage;
			this.pagination.sortBy = pagination.sortBy;
			this.pagination.descending = pagination.descending;

			this.getData(this.pagination);
		},
		async filterDataByStatus(statusParam) {
			this.pagination = {
				...this.pagination,
				status: statusParam,
				page: 1
			};

			this.getData(this.pagination);
		},
		onChangeSearch: debounce(function (params) {
			this.getData(params);
		}, 750)
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.divider{
	border: 2px solid #FFD9A0 !important;
}

</style>
