<template>
	<Dialog titulo="Estoque" :subtitulo="paciente.nome" @close="$emit('update:close', false)">
		<ListaEstoque :paciente_id="paciente.id" :box_id="box_id" :assinatura_id="assinatura_id" />
	</Dialog>
</template>

<script>
import ListaEstoque from '@Componentes/pacientes/paciente/lista-estoque';

export default {
	name: 'DialogEstoque',
	components: { ListaEstoque },
	props: {
		paciente: {
			type: Object,
			required: true
		},
		box_id: {
			type: Number,
			default: null
		},
		assinatura_id: {
			type: Number,
			default: null
		}
	}
};
</script>
