export const OrderStatusMap = Object.freeze({
	CREATED: 'Serviço iniciado',
	PAYMENT_CHECKED: 'Pagamento verificado',
	READY: 'Em processamento',
	PROCESSED: 'Expedição',
	DISPATCHED: 'Expedida',
	DELIVERED: 'Entregue',
	DONE: 'Finalizado',
	CANCELED: 'Cancelado'
});

export const OrderProcessDescription = Object.freeze({
	CHARGE: 'CHARGE',
	PICKING: 'Picking',
	BILLING: 'Faturamento',
	DISPATCH: 'Expedição',
	SHIPPING: 'Pronto para envio',
	CONCLUSION: 'Concluído',
	CANCELED: 'Cancelado'
});

export const getAllStatusOrder = () => (OrderStatusMap);

export const getDescriptionOrderStatus = status => OrderStatusMap[status];
