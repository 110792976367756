<template>
	<v-layout row wrap>
		<v-flex xs9 md3>
			<v-text-field v-model="endereco.cep" box :label="required ? 'CEP *' : 'CEP'" append-icon="search" mask="#####-###"
				@click:append="buscaCep()" data-vv-name="cep" v-validate="{ required: required, numeric: true, min: 8 }"
				:error-messages="errors.first(getScope('cep'))" @keyup.enter="buscaCep()" @blur="buscaCep()" />
		</v-flex>
		<v-flex v-if="buscando_cep" xs3 md3>
			<v-progress-circular class="mt-2" indeterminate color="primary" />
		</v-flex>
	</v-layout>
</template>

<script>
import { EnderecoPresenter } from '@Presenter/endereco-presenter';
import { statesWithSymbol } from '@Consts/estados';

export default {
	name: 'EscolherEndereco',
	inject: ['$validator'], // inject parent's validator
	props: {
		endereco: {
			type: Object,
			required: true
		},
		scope: {
			type: String,
			default: null
		},
		required: {
			type: Boolean,
			default: true
		}
	},
	data: () => ({
		buscando_cep: false
	}),
	methods: {
		buscaCep() {
			const cep = this.$functions.onlyNumber(this.endereco.cep);
			if (cep.length !== 8) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'CEP deve ter 8 dígitos.' });
				return;
			}
			this.buscando_cep = true;
			EnderecoPresenter.getAddressByZipcode(cep)
				.then(endereco => {
					this.$emit('endereco-encontrado', { ...endereco, estado: statesWithSymbol[endereco.estado] });
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao buscar endereço por CEP.' });
				})
				.finally(() => {
					this.buscando_cep = false;
				});
		},
		getScope(key) {
			return this.scope ? `${this.scope}.${key}` : key;
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
