<template>
	<v-layout row wrap>
		<v-flex v-for="(responsavel, index) in responsaveis" :key="index" md4 sm4 xs12>
			<v-card flat min-height="320" :class="responsavelProprio(responsavel) ? 'responsavelProprio' : ''">
				<v-card-title>
					<v-layout row wrap class="pa-2">
						<v-flex align-self-center md12 sm12 xs12 class="text-center pb-0">
							<v-icon class="pa-1" color="green darken-3" size="20"
								style="border: 2px solid #312b1d; border-radius: 30px; ">{{
								getIconeResponsavel(responsavel.tipo)
							}}
							</v-icon>
						</v-flex>
						<v-flex align-self-center md12 sm12 xs12 class="text-center pb-1">
							<span class="text-bold" style="font-size: 13px">{{
								getDescricaoResponsavel(responsavel.tipo)
							}}</span>
						</v-flex>
					</v-layout>
					<v-progress-circular v-if="carregando_responsavel" indeterminate color="grey" />
				</v-card-title>

				<hr class="hr mx-2 mb-2">

				<v-card-text class="text-left" v-if="!carregando_responsavel">
					<v-layout row wrap>
						<v-flex md12 xs12 class="pt-3 pb-0 text-truncate">
							<p v-if="!responsavelProprio(responsavel)" class="text-bold" style="font-size: 13px">{{ responsavel.nome }}</p>
							<p v-else style="font-size: 13px">Não possui.</p>
						</v-flex>
						<v-flex v-if="!responsavelProprio(responsavel)" md12 xs12 class="py-0">
							<p class="grey--text text--darken-1 ma-0 text-truncate" style="font-size: 13px">{{ responsavel.telefone | telefone }}</p>
							<p class="grey--text text--darken-1 ma-0 text-truncate" style="font-size: 13px">{{ responsavel.email }}</p>
						</v-flex>
					</v-layout>
				</v-card-text>

				<v-card-actions>
					<v-layout row wrap class="my-1">
						<v-flex md12 xs12 v-if="!responsavelProprio(responsavel)">
							<v-layout row wrap align-center justify-center class="pa-2">
								<v-flex md3 xs3 class="px-0" v-if="responsavel.pessoaId">
									<v-tooltip top class="pa-1">
										<v-btn small icon class="green white--text ml-0" slot="activator"
											@click="irParaPerfilResponsavel(responsavel.id)">
											<v-icon size="12">fas fa-user</v-icon>
										</v-btn>
										<span>
											Ir para perfil do responsável
										</span>
									</v-tooltip>
								</v-flex>
								<v-flex md3 xs3 class="px-0">
									<v-tooltip top class="pa-1">
										<v-btn small icon class="green white--text ml-0" slot="activator"
											@click="adicionarResponsavel(responsavel)">
											<v-icon size="12">fa-solid fa-plus</v-icon>
										</v-btn>
										<span>
											Cadastrar um novo responsável
										</span>
									</v-tooltip>
								</v-flex>
								<v-flex md3 xs3 class="px-0">
									<v-tooltip top class="pa-1">
										<v-btn small icon class="white--text ml-0" slot="activator"
											style="background-color: #312b1d"
											@click="abrirDialogDesvincular(responsavel)">
											<v-icon size="12">fas fa-user-times</v-icon>
										</v-btn>
										<span>
											Desvincular responsável
										</span>
									</v-tooltip>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex v-else md12 xs12 class="icon-position" style="text-align: end !important;">
							<v-tooltip top class="pa-1">
								<v-btn small icon class="green white--text ml-0 text-right" slot="activator" @click="adicionarResponsavel(responsavel)">
									<v-icon size="12">fas fa-pen</v-icon>
								</v-btn>
								<span>
									Adicionar um responsável
								</span>
							</v-tooltip>
						</v-flex>
					</v-layout>
				</v-card-actions>
			</v-card>
		</v-flex>

		<v-dialog persistent scrollable v-model="dialog_responsavel" transition="fade" max-width="700px">
			<transition>
				<DialogNovoResponsavel v-if="dialog_responsavel"
					:tipo="dadosResponsavel.tipo"
					:pacientId="pacienteId"
					:restHomeId="restHomeId"
					:isIlpiFinancialResponsible="isIlpiFinancialResponsible"
					:patientResponsibleFromQuotation="patientResponsibleFromQuotation"
					:patientResponsibles="responsaveis"
					@inserir="(resp) => responsavelAtualizado(resp)"
					@close="dialog_responsavel = false"/>
			</transition>
		</v-dialog>
		<v-dialog persistent scrollable v-model="dialog_desvincular_responsavel" transition="fade" max-width="340px">
			<transition>
				<Dialog v-if="dialog_desvincular_responsavel" titulo="Remover responsável" btn_acao="Remover"
					btn_acao_class="error" btn_cancelar="Cancelar" @acao="desvincularResponsavel"
					@close="dialog_desvincular_responsavel = false">
					<h3>Deseja desvincular o responsável e colocar o próprio farmembro como responsável por si próprio ?
					</h3>
					<p class="pt-2 mb-0">Nome: {{ responsavelAtual.nome }}</p>
					<p class="pt-0">Tipo: Responsável {{ responsavelAtual.tipo }}</p>
				</Dialog>
			</transition>
		</v-dialog>
	</v-layout>

</template>

<script>
import DialogNovoResponsavel from '@Componentes/pacientes/dialog-paciente/novo-responsavel.vue';
import { ResponsavelPresenter } from '@Presenter/responsavel-presenter';
import { PacientePresenter } from '@Presenter/paciente-presenter';
import { tiposResponsaveis } from '@Util/functions';
import { first } from 'lodash';
import { RESPONSIBLE_PAYMENT_REST_HOME } from '@Consts/rest-home/responsible-payment';
import { PAYMENT_TYPE_OPEN_CLOSE } from '@Consts/rest-home/payment-types';

export default {
	name: 'InfoResponsavel',
	components: { DialogNovoResponsavel },
	props: {
		pacienteId: {
			type: Number,
			required: true
		},
		restHomeId: {
			type: Number,
			required: false
		},
		patientResponsibleFromQuotation: {
			type: Object,
			required: false,
			default: null
		},
		sendUpdatePatientResponsibles: {
			type: Function,
			required: false
		},
		checkFinancialResponsible: {
			type: Boolean,
			required: false
		}
	},
	data: () => ({
		carregando_responsavel: false,
		dialog_responsavel: false,
		dialog_desvincular_responsavel: false,
		isIlpiFinancialResponsible: false,
		responsavelAtual: {},
		responsaveis: []
	}),
	created() {
		if (!this.responsaveis || !this.responsaveis.length)
			this.getResponsavel();
		if (this.patientResponsibleFromQuotation) {
			const pivotType = Object.keys(this.patientResponsibleFromQuotation.tipo).filter(k => this.patientResponsibleFromQuotation.tipo[k]);
			this.dadosResponsavel = {
				...this.patientResponsibleFromQuotation,
				tipo: pivotType?.length > 0 ? pivotType[0] : '',
				vinculo: this.patientResponsibleFromQuotation.vinculo
			};
			this.dialog_responsavel = true;
		}
	},
	methods: {
		getIconeResponsavel(tipo) {
			return tiposResponsaveis[tipo]?.icone ?? 'help';
		},
		getDescricaoResponsavel(tipo) {
			return tiposResponsaveis[tipo]?.descricao ?? 'Responsável desconhecido';
		},
		getResponsavel() {
			this.carregando_responsavel = true;
			PacientePresenter.show(this.pacienteId)
				.then(paciente => {
					this.responsaveis = paciente.responsaveis?.sort((a, b) => a.tipo.localeCompare(b.tipo));
					if (this.sendUpdatePatientResponsibles)
						this.sendUpdatePatientResponsibles(this.responsaveis);

					if (this.checkFinancialResponsible)
						this.checkIlpiIsFinancialResponsible(paciente?.casa_repouso);
				})
				.finally(() => {
					this.carregando_responsavel = false;
				});
		},
		checkIlpiIsFinancialResponsible(restHome) {
			if (!restHome)
				return false;

			const financialResponsible = first(this.responsaveis.filter(
				responsavel => responsavel.tipo === 'financeiro'
			));

			if (restHome?.responsavel_pagamento === RESPONSIBLE_PAYMENT_REST_HOME.value
					&& restHome?.tipo_pagamento === PAYMENT_TYPE_OPEN_CLOSE.value
						&& this.responsavelProprio(financialResponsible)) {
				this.isIlpiFinancialResponsible = true;
				this.adicionarResponsavel(financialResponsible);
			} else
				this.isIlpiFinancialResponsible = false;
		},
		responsavelAtualizado() {
			this.getResponsavel();
		},
		irParaPerfilResponsavel(id) {
			this.$router.push({ name: 'Responsavel', params: { id } });
		},
		adicionarResponsavel(responsavel) {
			this.dadosResponsavel = responsavel;
			this.dadosResponsavel.vinculo = responsavel.vinculo;
			this.dialog_responsavel = true;
		},
		abrirDialogDesvincular(responsavel) {
			this.responsavelAtual = responsavel;
			this.dialog_desvincular_responsavel = true;
		},
		async desvincularResponsavel() {
			try {
				await ResponsavelPresenter.deleteResponsavel(this.pacienteId, this.responsavelAtual.id, this.responsavelAtual.tipo);
			} finally {
				this.dialog_desvincular_responsavel = false;
				this.getResponsavel();
			}
		},
		responsavelProprio(responsavel) {
			return responsavel?.pessoaId === this.pacienteId;
		}
	}
};
</script>

<style lang="scss" scoped>
 .responsavelProprio {
		color: rgba(33, 33, 33, 1) !important;
 }

 .icon-position {
	position: relative !important;
	top: 38px;
 }
</style>
