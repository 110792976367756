export const boxProductionStatusMap = {
	WAITING: 'WAITING',
	IN_PROGRESS: 'IN_PROGRESS',
	FINISHED: 'FINISHED'
};
const BOX_PRODUCTION_STATUS = Object.values(boxProductionStatusMap);

export const BoxProductionMixin = {
	props: {
		status: {
			required: true,
			type: String,
			validator: value => BOX_PRODUCTION_STATUS.includes(value)
		}
	},
	computed: {
		isWaiting() { return this.status === boxProductionStatusMap.WAITING; },
		isInProgress() { return this.status === boxProductionStatusMap.IN_PROGRESS; },
		isFinished() { return this.status === boxProductionStatusMap.FINISHED; }
	}
};
