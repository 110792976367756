import Vue from 'vue';
import './boot';
import 'vuetify/dist/vuetify.min.css';
import VueGeolocation from 'vue-browser-geolocation';
import { initializeUnleash } from '@Util/unleash';
import router from './router';
import store from './store';
import App from './App.vue';

initializeUnleash();

Vue.config.productionTip = false;
Vue.use(VueGeolocation);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
