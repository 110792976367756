export const featureFlags = Object.freeze({
	enable_farme_finance_and_invoices: 'enable_farme_finance_and_invoices',
	enable_farme_new_production_process: 'enable_farme_new_production_process',
	enable_farme_finance_bh: 'enable_farme_finance_bh',
	enable_farme_finance_sp: 'enable_farme_finance_sp',
	enable_farme_pre_production_process: 'enable_farme_pre_production_process',
	new_reset_box: 'FARMEBOX_NEW_RESET_BOX',
	enable_only_single_purchase: 'enable_only_single_purchase',
	enable_single_purchase: 'enable_single_purchase',
	rastreabilidade_por_foto: 'FARMEBOX_RASTREABILIDADE_POR_FOTO',
	conferencia_digital: 'FARME_BOX_CONFERENCIA_DIGITAL_ENABLED'
});
