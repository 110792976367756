<template>
	<div class="px-md-5">
		<!-- Conteúdo -->
		<iframe width="100%" height="804" src="https://app.powerbi.com/view?r=eyJrIjoiMmQ5NjRkMzgtMDdjMS00MzdjLWI4OTktYmY3OTE4OTA2NDllIiwidCI6IjdmOWQ4YjNiLWEwMGQtNDc1Yy1hOTFjLTYxOWE0NTc3MDFiYSJ9" frameborder="0" allowFullScreen="true"></iframe>
	</div>
</template>

<script>
export default {
	name: 'RelatorioProdutividadeBox',
	components: {
	},
	data: () => ({
	})
};
</script>
