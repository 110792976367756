<template>
	<section class="">
		<ListProductionHeader
			:total="total"
			:totalDelayed="totalDelayed"
			:totalToday="totalToday"
			:selected="selected"
			@filter="headerFilterParams => updateFilters(headerFilterParams)"/>
		<ListProductionTable
			:loading="isLoading"
			:items="items"
			:total="total"
			@updatedSelected="items => selected = items"
			@filter="tableFilterParams => updateFilters(tableFilterParams)"/>

		<LoaderDialog v-if="isLoading" />
	</section>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import { isEqual, debounce } from 'lodash';
import LoaderDialog from '@Componentes/loader-dialog';
import ListProductionHeader from './header';
import ListProductionTable from './table';

export default {
	name: 'ListProduction',
	components: { ListProductionHeader, ListProductionTable, LoaderDialog },
	data() {
		return {
			isLoading: false,
			filterParams: {
				pagination: {},
				sorting: {},
				filter: {}
			},
			items: [],
			selected: [],
			total: 0,
			totalDelayed: 0,
			totalToday: 0
		};
	},
	methods: {
		updateFilters(filters) {
			this.filterParams = {
				...this.filterParams,
				...filters
			};
		},
		getBoxesToProduction: debounce(async function () {
			this.isLoading = true;
			try {
				const boxesToProductionResponse = await BoxPresenter.getBoxesToProduce({
					filter: this.filterParams.filter,
					pagination: this.filterParams.pagination,
					sorting: this.filterParams.sorting
				});
				this.items = boxesToProductionResponse.items;
				this.total = boxesToProductionResponse.total;
				this.totalDelayed = boxesToProductionResponse.totalDelay;
				this.totalToday = boxesToProductionResponse.totalToday;
			} catch (e) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{ message: 'Erro ao carregar dados.', color: 'error' }
				);
			} finally {
				this.isLoading = false;
			}
		}, 500)
	},
	watch: {
		filterParams: {
			deep: true,
			async handler(newVal, oldVal) {
				if (isEqual(newVal, oldVal))
					return;
				await this.getBoxesToProduction();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
