var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"space-between items-start q-column",attrs:{"xs5":""}},[_c('div',[_c('p',{staticClass:"mb-0 inter text-bold-medium titleOpacity long-string"},[_vm._v(_vm._s(_vm.medicineType))]),_c('p',{staticClass:"mb-0 text-bold subheading long-string"},[_vm._v(_vm._s(_vm.productInfo.name))]),_c('p',{staticClass:"mb-0 body-2"},[_vm._v(_vm._s(_vm.productInfo.presentation)+" - "+_vm._s(_vm.productInfo.manufacturer.name))])]),_c('div',{class:_vm.discountGreaterThanZero ? 'mb-4' : 'mt-4'},[_c('v-layout',{attrs:{"wrap":"","row":"","align-center":"","justify-start":""}},[(_vm.isGeneric)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"iconStyle generic",attrs:{"small":"","color":"#212B72"}},on),[_vm._v("fab fa-google")])]}}],null,false,333334274)},[_c('span',{staticClass:"inter text-bold",staticStyle:{"color":"#000"}},[_vm._v("Genérico")])]):_vm._e(),(_vm.isPbm)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"iconStyle percentage",staticStyle:{"width":"33px","height":"33px"},attrs:{"src":"/img/icons/percentage.svg","alt":"Imagem de porcentagem"}},on))]}}],null,false,2447084462)},[_c('span',{staticClass:"inter text-bold",staticStyle:{"color":"#000"}},[_vm._v("PBM")])]):_vm._e(),(_vm.isControlled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"iconStyle receipt",attrs:{"small":"","color":"#E50000"}},on),[_vm._v("fas fa-file")])]}}],null,false,57815031)},[_c('span',{staticClass:"inter text-bold",staticStyle:{"color":"#000"}},[_vm._v("Retenção de receita")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"iconStyle recurrence",staticStyle:{"width":"30px","height":"30px"},attrs:{"src":"/img/icons/recurrence.svg","alt":"Imagem de recorrência"}},on))]}}])},[_c('span',{staticClass:"inter text-bold",staticStyle:{"color":"#000"}},[_vm._v("Medicamento recorrente")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }