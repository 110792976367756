<template>
	<v-card>
		<v-card-title>
			<v-avatar size="36" class="mr-3">
				<v-icon color="primary" small>fa-solid fa-cash-register</v-icon>
			</v-avatar>
			<h3 class="text--text mr-2">Novos Cadastros</h3>
			<v-spacer />
			<v-btn small depressed to="/colaborador/pessoas">Ver todos</v-btn>
		</v-card-title>
		<v-card-text class="pt-0">
			<div v-if="carregando" class="carregando">
				<v-progress-circular indeterminate color="grey" />
			</div>
			<div v-else>
				<div v-if="usuarios.length === 0" class="carregando">
					<v-icon color="grey" class="mb-1" style="font-size: 32px; opacity: .5">check_circle_outline</v-icon>
					<p class="mb-0">Nenhum usuário.</p>
				</div>
				<v-list v-else avatar two-line>
					<v-list-tile v-for="item in usuarios" :key="item.id">
						<v-list-tile-avatar size="36">
							<span class="white--text" style="font-size: 14px">
								{{ item.nome.charAt(0) }}
							</span>
						</v-list-tile-avatar>
						<v-list-tile-content>
							<v-list-tile-title>
								<strong class="grey--text text--darken-1">
									{{ item.nome }}
								</strong>
							</v-list-tile-title>
							<v-list-tile-sub-title>
								<strong>Cadastro em:</strong> {{ item.created_at | formatDate('DD/MM/YYYY') }}
							</v-list-tile-sub-title>
						</v-list-tile-content>
					</v-list-tile>
				</v-list>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { UserPresenter } from '@Presenter/user-presenter';

export default {
	name: 'NovosCadastros',
	data: () => ({
		usuarios: [],
		carregando: true
	}),
	async created() {
		this.usuarios = await UserPresenter.index({ limit: 10 });
		this.carregando = false;
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.v-card__text {
  overflow-y: auto;
  height: 300px;
}

.v-card__title {
  .v-avatar {
    background: rgba($warning, 0.2) !important;
  }
}
</style>
