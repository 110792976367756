<template>
	<div>
		<v-data-table :headers="headers" :items="estoque" :loading="is_loading"
			:no-data-text="(is_loading) ? 'Carregando...' : (request_error) ? 'Erro ao carregar, tente novamente' : 'Nenhum medicamento no estoque'"
			:rows-per-page-items="[15, 30, 45, {text: 'Todos', value: -1}]" no-results-text="Nenhum resultado encontrado"
			rows-per-page-text="Itens por página">
			<template slot="items" slot-scope="props">
				<tr>
					<td>{{ props.item.medicamento.produto }}</td>
					<td>{{ props.item.medicamento.apresentacao }}</td>
					<td>{{ props.item.medicamento.laboratorio }}</td>
					<td>{{ props.item.quantidade }}</td>
					<td>
						{{ getLabel(props.item) }}
						<v-tooltip v-if="props.item.observacao" right>
							<v-icon slot="activator" color='purple'>info_outline</v-icon>
							<p>{{props.item.observacao}}</p>
						</v-tooltip>
					</td>
					<td>{{ props.item.user ? props.item.user.nome : '-' }}</td>
					<td>{{ props.item.lote ? props.item.lote : '-' }}</td>
					<td v-if="props.item.validade">{{ props.item.validade | formatDate('DD/MM/YYYY') }}</td>
					<td v-else>-</td>
					<td>{{ props.item.created_at | formatDate('DD/MM/YYYY') }}</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { PacientePresenter } from '@Presenter/paciente-presenter';

export default {
	name: 'ListaHistoricoEstoque',
	props: {
		paciente_id: {
			type: Number,
			required: true
		},
		busca: {
			type: String,
			default: null
		}
	},
	data: () => ({
		dialog_controle: false,
		estoque: [],
		search: '',
		is_loading: false,
		request_error: false,
		headers: [
			{ text: 'Produto', value: 'produto' },
			{ text: 'Apresentação', value: 'apresentacao' },
			{ text: 'Laboratório', value: 'laboratorio' },
			{ text: 'Quantidade', value: 'quantidade' },
			{ text: 'Movimentação', value: 'movimentacao' },
			{ text: 'Responsável', value: 'user.nome' },
			{ text: 'Lote', value: 'lote' },
			{ text: 'validade', value: 'validade' },
			{ text: 'Data', value: 'data' }
		],
		controle: {}
	}),
	created() {
		this.getHistoricoEstoque();
	},
	watch: {
		is_loading(newVal) {
			this.$emit('update:carregando', newVal);
		}
	},
	methods: {
		getLabel(item) {
			let label = item.entrada ? 'Entrada' : 'Saída';
			if (item.observacao)
				label += ' manual';
			return label;
		},
		getHistoricoEstoque() {
			this.is_loading = true;
			this.estoque = [];
			PacientePresenter.historicoEstoque(this.paciente_id)
				.then(response => {
					this.estoque = response;
					this.$emit('estoque', response);
				})
				.catch(() => {
					this.request_error = true;
				})
				.finally(() => {
					this.is_loading = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
p {
	margin: 0;
}
</style>
