<template>
	<div class="dialog-container">
		<div class="purple-title q-row py-2 px-4 items-center">
			<span class="titulo">
				Receitas/Medicamentos
			</span>
			<v-spacer />
			<v-btn icon dark flat @click="close">
				<v-icon>close</v-icon>
			</v-btn>
		</div>
		<div v-if="loading" class="q-row items-center justify-center full-height">
			<v-progress-circular indeterminate color="primary" />
		</div>
		<v-layout v-else wrap>
			<v-flex xs6 class="flex-left">
				<v-layout wrap column>
					<v-flex class="flex-1">
						<v-layout wrap class="img-container">
							<v-flex shrink>
								<v-icon color="grey" @click.stop="next(-1)">keyboard_arrow_left</v-icon>
							</v-flex>
							<v-flex grow>
								<zoom-on-hover v-if="receita" :img-normal="receita.url" />
							</v-flex>
							<v-flex shrink>
								<v-icon color="grey" @click.stop="next(1)">keyboard_arrow_right</v-icon>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex xs6 class="flex-right pa-4">
				<Receitas
					noUpload
					:receitas="receitas"
					:index="receita_selecionada_index"
					class="mb-4"
					@receita-selecionada="i => receita_selecionada_index = i" />
				<Medicamentos
					:assinatura="assinatura_selecionada"
					:medicamentos="medicamentos"
					:medicamentosComAproveitamentoReceita="medicamentosComAproveitamentoReceita"
					:showCheckItem="true" />
			</v-flex>
		</v-layout>

	</div>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { BoxPresenter } from '@Presenter/box-presenter';
import { isBoxInProcess } from '@Util/functions';
import { ReceitaMixin } from '@Mixins/receitas';
import Medicamentos from './medicamentos';
import Receitas from './receitas';

export default {
	name: 'DialogCompararReceitasMedicamentos',
	components: { Medicamentos, Receitas },
	mixins: [ReceitaMixin],
	props: {
		box_id: {
			type: Number
		},
		assinatura_id: {
			type: Number
		},
		assinatura_selecionada: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		assinatura: null,
		receitas: [],
		medicamentos: [],
		loading: true,
		medicamentosComAproveitamentoReceita: []
	}),
	created() {
		const promises = [AssinaturaPresenter.show(this.assinatura_id)];
		if (this.box_id)
			promises.push(BoxPresenter.show(this.box_id));
		Promise.all(promises)
			.then(([assinatura, box]) => {
				const item = box || assinatura;
				this.receitas = item.receitas;
				this.medicamentos = item.medicamentos;
				this.assinatura = item.assinatura;
				if (box && !isBoxInProcess(box.status))
					this.medicamentos = assinatura.medicamentos;
				this.loading = false;
			});
	},
	methods: {
		close() {
			this.$emit('close');
		}
	}
};
</script>

<style lang="scss" scoped>
.dialog-container {
	background: white;
	width: 100%;

	.titulo {
		font-size: 22px;
	}

	.layout {
		height: calc(100vh - 64px);

		i {
			font-size: 70px;
		}

		.flex-left, .flex-right {
			height: 100%;
		}

		.flex-left {
			.layout {
				height: 100%;

				.flex-1 {
					height: 88%;
				}

				.grow {
					flex-basis: 70%;
				}
			}

			.img-container {
				.flex {
					height: 100%;
				}
			}

			.zoom-on-hover {
				height: 95%;
				display: flex;
				justify-content: center;
				align-items: center;

				::v-deep .normal {
					width: unset;
					max-width: 100%;
					max-height: 100%;
				}
			}

			.flex {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
			}
		}

		.flex-right {
			overflow-y: auto;
		}
	}
}
</style>
