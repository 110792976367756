import $http from '@Config/axios';

const prefix = 'brands';

export const BrandPresenter = {
	findAll: () => $http.get(`${prefix}`),
	create: params => $http.post(`${prefix}`, {
		name: params.name
	})
};
