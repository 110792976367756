<template>
	<div class="center-content">
		<!-- Imagem ilustrativa -->
		<img :src="familia" width="250px" alt="Família" class="mb-4 user-select" />

		<!-- Título e texto -->
		<h2 class="text--text" style="opacity: .8">Nenhum responsável encontrado</h2>
		<p>Para criar um novo responsável, basta clicar no botão acima.</p>
	</div>
</template>

<script>
import familia from '@Assets/images/familia.png';

export default {
	name: 'NenhumResponsavel',
	data() {
		return {
			familia
		};
	},
	methods: {
		abrir_ajuda() {
			this.$root.$emit('abrir_ajuda');
		}
	}
};
</script>

<style lang="scss" scoped>
.center-content {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
