import $http from '@Config/axios';
import { DefaultPresenter } from './default-presenter';

const prefix = 'configuracao';
const entity = 'Configuração';

export const ConfiguracaoPresenter = {
	...DefaultPresenter({ prefix, entity }),
	show: () => $http.get(prefix)
};
