<template>
	<div class="px-md-5">
		<LogisticsHeader
			:totalSelectedIds="selectedIdsSize"
			:button="headerButton"
			@headerButtonAction="getQuotations"
			:title="'Base de entregas'"
			:filter="filterField"
			@filterChanged="filterChanged"/>
		<!-- Conteúdo -->

		<v-layout row class="mb-2 switch">
			<v-flex class="q-row items-center justify-end">
				<v-switch
					v-model="groupedDataView"
					hide-details
					inset
					:label="`Listagem agrupada`"></v-switch>
				<v-switch
					class="ml-4"
					v-model="selectedOnlyView"
					hide-details
					inset
					:label="`Apenas selecionados`"></v-switch>
			</v-flex>
		</v-layout>

		<div>
			<div v-if="groupedDataView" class="mt-3">
				<div class="demand-region-container" v-for="demandRegion in getGroups()" :key="demandRegion">
					<v-expansion-panel>
						<v-expansion-panel-content>
							<template v-slot:header>
								<v-flex grow>
									<div>
										<v-checkbox
											:class="{ 'custom-icon-checked': getToggleAllValue(demandRegion) }"
											:input-value="getToggleAllValue(demandRegion)"
											primary
											:label="getPanelTitle(demandRegion)"
											hide-details
											@click.stop="() => toggleAll(demandRegion)"/>
									</div>
								</v-flex>
							</template>
							<div class="mt-2 mb-2">
								<ShippingDataTable :loading="loading" @setItemChecked="setItemChecked" :items="getItemsToShow(demandRegion)" :key="demandRegion"
									v-if="itemsPerDemandRegion[demandRegion].items.length > 0"
									:reference="`DataTableShipping${demandRegion}`" @openDetails="openDetails" @changePagination="changePagination" :pagination="pagination" :totalItems="totalItems"/>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</div>
			</div>
			<div v-if="!groupedDataView" class="mt-3">
				<ShippingDataTable :loading="loading" @setItemChecked="setItemChecked" :items="getItemsToShow()"
					:reference="`DataTableShippingUniq`" :showToggleAll="true" @toggleAll="toggleAll" :toggleAllValue="getToggleAllValue()"
					@openDetails="openDetails" @changePagination="changePagination" :pagination="pagination" :totalItems="totalItems"/>
			</div>

		</div>
		<v-dialog
			scrollable
			v-model="showQuotations"
			transition="fade"
			max-width="450px">
			<transition>
				<DialogLogisticsQuotation
					v-if="showQuotations"
					:selectedIdsSize="selectedIdsSize"
					:quotedItems="selected"
					:quotations="quotations"
					@reloadData="getData()"
					@clearSelected="clearSelected()"
					:close.sync="showQuotations"/>
			</transition>
		</v-dialog>

		<v-dialog
			scrollable
			v-model="openDialogItemDetail"
			transition="fade"
			max-width="450px">
			<transition>
				<DialogLogisticsItemDetail v-if="openDialogItemDetail" :item="detailedItem" :close.sync="openDialogItemDetail"/>
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { LogisticaPresenter } from '@Presenter/logistica-presenter';
import { debounce } from 'lodash';
import { getCurrentCompanyUnit } from '@Config/unidades';
import { getLogisticReferenceTypeDescription } from '@Consts/reference-type-logistic';
import { logisticaStatus } from '@Consts/status-logistics';
import LogisticsHeader from '../header.vue';
import ShippingDataTable from './data-table.vue';
import DialogLogisticsQuotation from './dialog-quotations.vue';
import DialogLogisticsItemDetail from './dialog-item-detail.vue';

export default {
	name: 'Shipping',
	components: {
		LogisticsHeader,
		ShippingDataTable,
		DialogLogisticsQuotation,
		DialogLogisticsItemDetail
	},
	data: () => ({
		detailedItem: {},
		groupedDataView: false,
		selectedOnlyView: false,
		openDialogItemDetail: false,
		demandRegion: true,
		typesSelected: [],
		selectedIlpiIds: [],
		demandRegionsSelected: [],
		statusFilterSelected: [logisticaStatus.DESPATCHED, logisticaStatus.DELAYED],
		loaderSpecificRows: [],
		toggleAllValue: false,
		selected: {},
		selectedIdsSize: 0,
		totalItems: 0,
		items: [],
		allItems: [],
		itemsPerDemandRegion: {},
		headerButton: {
			show: true,
			disabled: false,
			loading: false,
			text: 'Gerar rota'
		},
		loading: false,
		showQuotations: false,
		quotations: [],
		searchIds: '',
		search: '',
		pagination: {
			page: 1,
			rowsPerPage: 100,
			sortBy: 'data_com_antecedencia',
			descending: false,
			sortable: ['data_com_antecedencia', 'data_inicio', 'paciente_nome']
		},
		filterField: {
			show: true,
			inputSearchFilter: {
				show: true,
				label: 'Nome do cliente ou ID da assinatura'
			},
			inputIdsFilter: {
				show: true,
				label: 'ID da Box ou ID do Pontual'
			},
			showFleetFilter: false,
			showDemandRegionFilter: true,
			showStatusFilter: true,
			showTypeFilter: true,
			showIlpiFilter: true
		},
		getLogisticReferenceTypeDescription
	}),
	created() {
		this.getData();
	},
	watch: {
		items(data) {
			const map = {};
			data.forEach(i => {
				const demandRegionName = i.demand_region || 'Sem região de demanda';
				if (map[demandRegionName])
					map[demandRegionName].items.push(i);
				else
					map[demandRegionName] = { items: [i], toggleAllValue: false };
			});
			this.itemsPerDemandRegion = map;
		}
	},
	methods: {
		openDetails(item) {
			this.detailedItem = item;
			this.openDialogItemDetail = true;
		},

		getToggleAllValue(demandRegion) {
			const hasFilters = this.search || this.demandRegionsSelected.length > 0 || this.statusFilterSelected.length > 0 || this.typesSelected.length > 0;

			if (!hasFilters && !demandRegion && !this.selectedOnlyView)
				return this.items.length > 0 && this.selectedIdsSize === this.items.length;

			const mapIdsSelected = Object.values(this.selected).map(it => it.id);
			const its = this.getItemsToShow(demandRegion);
			return its.length > 0 && its.every(it => mapIdsSelected.includes(it.id));
		},

		changePagination(pagination) {
			this.pagination.page = pagination.page;
			this.pagination.rowsPerPage = pagination.rowsPerPage;
			this.pagination.sortBy = pagination.sortBy;
			this.pagination.descending = pagination.descending;
			this.getData();
		},

		toggleAll(demandRegion) {
			if (this.selectedOnlyView)
				this.getItemsToShow(demandRegion).forEach(it => this.setItemChecked({ item: it, value: false }));
			else if (demandRegion) {
				this.itemsPerDemandRegion[demandRegion].toggleAllValue = !this.itemsPerDemandRegion[demandRegion].toggleAllValue;
				this.items.forEach(it => {
					if (it.demand_region === demandRegion)
						this.setItemChecked({ item: it, value: this.itemsPerDemandRegion[demandRegion].toggleAllValue });
				});
			} else {
				this.toggleAllValue = !this.toggleAllValue;
				this.items.forEach(it => this.setItemChecked({ item: it, value: this.toggleAllValue }));
			}
		},

		setItemChecked({ item, value }) {
			item.selected = value;

			if (item.selected)
				this.selected[item.id] = item;
			else if (this.selected[item.id])
				delete this.selected[item.id];
			this.selectedIdsSize = Object.keys(this.selected).length;
		},

		filterChanged: debounce(function (filterParams) {
			this.pagination.page = 1;
			this.searchIds = filterParams.searchIdsInput;
			this.search = filterParams.search;
			this.demandRegionsSelected = filterParams.demandRegions;
			this.statusFilterSelected = filterParams.selectedStatuses;
			this.typesSelected = filterParams.selectedTypes;
			this.toggleAllValue = false;
			this.selectedIlpiIds = filterParams.selectedIlpiIds;
			this.getData();
		}, 500),

		getPanelTitle(demandRegion) {
			const total = this.selectedOnlyView
				? this.itemsPerDemandRegion[demandRegion].items.filter(i => i.selected).length
				: this.itemsPerDemandRegion[demandRegion].items.length;
			return `${demandRegion} (${total})`;
		},

		getItemsToShow(demandRegion) {
			if (demandRegion) {
				return this.selectedOnlyView
					? this.itemsPerDemandRegion[demandRegion].items.filter(i => i.selected)
					: this.itemsPerDemandRegion[demandRegion].items;
			}
			return this.selectedOnlyView ? Object.values(this.selected) : this.items;
		},

		getGroups() {
			if (this.selectedOnlyView) {
				const demandRegionsSelected = this.getItemsToShow().map(i => i.demand_region);
				return [...new Set(demandRegionsSelected)];
			}

			return Object.keys(this.itemsPerDemandRegion);
		},

		sortItemsByColumn(arrayToSort) {
			if (!this.pagination.sortable.includes(this.pagination.sortBy))
				return arrayToSort;

			const column = this.pagination.sortBy;
			const columnIsDate = column.includes('data');
			const sortable = arrayToSort.map(item => {
				const columnValue = columnIsDate ? new Date(item[column]) : item[column]?.replace(/\s/g, '');

				return {
					...item,
					originalColumn: item[column],
					[column]: columnValue
				};
			});

			if (this.pagination.descending)
				sortable.sort((a, b) => (columnIsDate ? (a[column] - b[column]) : a[column].localeCompare(b[column])));
			else
				sortable.sort((a, b) => (columnIsDate ? (b[column] - a[column]) : b[column].localeCompare(a[column])));

			return columnIsDate ? sortable.map(item => ({
				...item,
				[column]: item.originalColumn
			})) : [...sortable];
		},

		getData() {
			this.loading = true;

			LogisticaPresenter.v2.getShippingData({
				ilpiIds: this.selectedIlpiIds,
				branch: getCurrentCompanyUnit().id,
				searchIds: this.searchIds,
				search: this.search,
				types: this.typesSelected,
				demand_regions_ids: this.demandRegionsSelected,
				statuses: this.statusFilterSelected,
				page: this.pagination.page,
				perPage: this.pagination.rowsPerPage
			})
				.then(response => {
					this.allItems = response.items.map(o => ({
						...o,
						box_id: o.id,
						paciente: o.assinatura.paciente,
						selected: !!this.selected[o.id],
						paciente_nome: o.assinatura.paciente.nome
					}));
					this.totalItems = response.total;
					this.items = this.sortItemsByColumn(this.allItems);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async getQuotations() {
			if (this.selectedIdsSize === 0) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Selecione ao menos 1 item'
				});
			}

			try {
				this.loading = true;
				this.headerButton.loading = true;

				const quotationResponse = await LogisticaPresenter.v2.getQuotations({
					box_ids: Object.values(this.selected).filter(item => item.type === 'box').map(s => s.id),
					order_ids: Object.values(this.selected).filter(item => item.type === 'order').map(s => s.id)
				});
				this.quotations = quotationResponse.quotations;
				this.showQuotations = true;
			} catch (e) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: `Erro ao buscar cotações: ${e.message}`
				});
			} finally {
				this.loading = false;
				this.headerButton.loading = false;
			}
		},

		clearSelected() {
			this.selected = {};
			this.selectedIdsSize = 0;
		}
	}
};
</script>

<style lang="scss" scoped>
	@import "@Assets/css/settings.scss";

	::v-deep .switch .v-input{
		flex: 0 0 auto!important;
	}

	::v-deep .v-input--selection-controls .v-input__control,
	::v-deep .v-input--selection-controls.v-input .v-label {
		width: 100%;
	}

	.outlined-radio {
		border: 2px solid rgba(0, 0, 0, 0.12);
		border-radius: 4px;
	}

	.custom-icon-checked ::v-deep .v-icon {
		color: #2dce71 !important;
		caret-color: #2dce71 !important;
	}
	.demand-region-container ::v-deep .v-expansion-panel .v-expansion-panel__container{
		background: $primary_light;
	}
	.demand-region-container ::v-deep .v-expansion-panel__header{
		border-bottom: 1px dashed $text;
		padding-left: 0;
	}

	.demand-region-container ::v-deep  .v-expansion-panel {
		box-shadow: none;
		padding: 10px 0;
	}
	.demand-region-container ::v-deep .v-input--selection-controls .v-input__control,
	.demand-region-container ::v-deep .v-input--selection-controls.v-input .v-label {
		width: auto;
	}

</style>
