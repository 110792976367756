import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';
import { copyToClipboard } from '@Util/functions';

export default {
	props: {
		items: {
			type: Array,
			required: true
		},
		loading: {
			type: Boolean,
			required: true
		},
		error: {
			type: Boolean,
			required: true
		},
		search: {
			type: String,
			required: true
		}
	},
	data: () => ({
		item_selecionado: null,
		dialog_enviar_email: false
	}),
	methods: {
		enviarEmail(fn) {
			OrcamentoPresenter[fn](this.item_selecionado.uuid)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Email enviado com sucesso' });
					this.dialog_enviar_email = false;
				});
		},
		copiarURL({ url }) {
			copyToClipboard(url);
		},
		abrirDialog(item, name) {
			this.item_selecionado = item;
			this[name] = true;
		}
	}
};
