<template>
	<div>
		<v-data-table
			class="custom-data-table"
			:headers="headers"
			:items="items"
			:loading="loading"
			:ref="reference"
			:no-data-text="loading ? 'Carregando...' : 'Nenhum item'"
			no-results-text="Nenhum resultado encontrado"
			:rows-per-page-items="[items.length]"
			:total-items="totalItems"
			:pagination.sync="localPagination">
			<template slot="headers" slot-scope="props">
				<tr>
					<th :class="{ 'pl-1': showToggleAll }">
						<v-checkbox
							v-if="showToggleAll"
							:class="{ 'custom-icon-checked': toggleAllValue }"
							:input-value="toggleAllValue"
							primary
							hide-details
							@click.stop="$emit('toggleAll')"/>
					</th>
					<th
						v-for="(header) in props.headers"
						:key="header.text"
						@click="changeSort(header.value)"
						class="column text-xs-center"
						:class="[
							header.class,
							{ sortable: header.sortable },
							pagination.descending ? 'desc' : 'asc',
							header.value === pagination.sortBy ? 'active' : '',
						]">
						{{ header.text }}
						<v-icon small v-if="header.sortable">arrow_upward</v-icon>
					</th>
				</tr>
			</template>
			<template slot="items" slot-scope="props">
				<tr :active="props.item.selected">
					<td class="px-1" @click="$emit('setItemChecked', {item: props.item, value: !props.item.selected} )">
						<v-checkbox
							:key="props.item.id"
							:class="{ 'custom-icon-checked': props.item.selected }"
							:input-value="props.item.selected"
							primary
							hide-details>
						</v-checkbox>
					</td>
					<td class="text-xs-center">{{ getLogisticReferenceTypeDescription(props.item.type) }}</td>
					<td class="text-xs-center">
						<RouterLink
							v-if="props.item.type === 'order'"
							class="secondary--text"
							:to="{name: 'Order', params: { id: props.item.id }}">
							{{ props.item.id }}
						</RouterLink>
						<template v-else> {{ props.item.id }} </template>
						/
						<RouterLink
							v-if="props.item.assinatura_id"
							class="secondary--text"
							:to="{name: 'Assinatura', params: { id: props.item.assinatura_id }}">
							{{ props.item.assinatura_id }}
						</RouterLink>
						<template v-else> - </template>
					</td>
					<td class="text-xs-center">{{ logisticaStatusLabels[props.item.status] }}</td>
					<td class="text-xs-center">{{ props.item.data_com_antecedencia | formatDate('DD/MM/YYYY') }}</td>
					<td class="text-xs-center">{{ props.item.data_inicio | formatDate('DD/MM/YYYY') }}</td>
					<td class="text-xs-center">
						{{ props.item.paciente.nome }}
					</td>
					<td class="text-xs-center px-2">
						{{ getAddressReadable(props.item.logradouro, props.item.numero, props.item.complemento) }} - {{ props.item.cidade }}
					</td>
					<td class="text-xs-center">
						{{ props.item.casa_de_repouso || '-' }}
					</td>
					<td class="text-xs-center">
						{{ props.item.demand_region || '-' }}
					</td>
					<td class="text-center px-1">
						<v-btn flat fab @click="$emit('openDetails', props.item)">
							<v-badge
								class="pointer"
								color="red"
								overlap>
								<template v-slot:badge v-if="!!props.item.instrucoes_entrega">
									<span>!</span>
								</template>
								<v-icon
									large
									color="secondary">
									add_circle_outline
								</v-icon>
							</v-badge>
						</v-btn>
					</td>
				</tr>
			</template>
			<template v-slot:footer>
				<tr>
					<td></td>
					<td :style="'padding:0;'" v-for="header in headers" :key="header.text">
						<div class="custom-footer-container" >
							<span v-if="header.text === ''" class="custom-footer">
								{{  getPaginationText()}}
							</span>
						</div>
					</td>
				</tr>
			</template>
		</v-data-table>

	</div>
</template>

<script>
import { logisticaStatusLabels } from '@Consts/status-logistics';
import { getLogisticReferenceTypeDescription } from '@Consts/reference-type-logistic';

export default {
	name: 'ShippingDataTable',
	props: {
		items: {
			type: Array,
			required: false,
			default: () => []
		},
		pagination: {
			type: Object,
			required: true
		},
		totalItems: {
			type: Number,
			required: false,
			default: 0
		},
		loading: {
			type: Boolean,
			required: false,
			default: false
		},
		reference: {
			type: String,
			required: false,
			default: ''
		},
		showToggleAll: {
			type: Boolean,
			required: false
		},
		setItemChecked: {
			type: Function,
			required: false
		},
		toggleAllValue: {
			type: Boolean,
			required: false,
			default: false
		},
		toggleAll: {
			type: Function,
			required: false
		},
		openDetails: {
			type: Function,
			required: false
		},
		changePagination: {
			type: Function,
			required: false
		}
	},
	data: () => ({
		localPagination: {},
		headers: [
			{
				text: 'Tipo',
				value: 'header-type'
			},
			{
				text: 'ID / ID Assinatura',
				value: 'header-idassinatura'
			},
			{
				text: 'Status',
				value: 'header-status',
				class: 'px-1'
			},
			{
				text: 'Data de entrega',
				value: 'data_com_antecedencia',
				// this property has to be on sortable from pagination
				sortable: true
			},
			{
				text: 'Data de inicio',
				value: 'data_inicio',
				// this property has to be on sortable from pagination
				sortable: true
			},
			{
				text: 'Nome',
				value: 'paciente_nome',
				// this property has to be on sortable from pagination
				sortable: true
			},
			{
				text: 'Endereço',
				value: 'header-endereco'
			},
			{
				text: 'Casa de repouso',
				value: 'header-ilpi'
			},
			{
				text: 'Região',
				value: 'header-regiao'
			},
			{
				text: '',
				value: '',
				class: 'px-1'

			}
		],
		logisticaStatusLabels,
		getLogisticReferenceTypeDescription
	}),
	mounted() {
		this.localPagination = { ...this.pagination };
	},
	watch: {
		localPagination: {
			async handler(val) {
				this.$emit('changePagination', val);
			},
			deep: true
		}
	},
	methods: {
		getPaginationText() {
			const currentPage = this.localPagination.page;
			const currentPageToCalculate = this.localPagination.page - 1;
			const totalByPage = currentPage > 1 ? ((currentPageToCalculate * this.localPagination.rowsPerPage) + this.items.length) : this.items.length;
			return `${currentPage} - ${totalByPage} de ${this.totalItems}`;
		},

		changeSort(column) {
			if (this.localPagination.sortBy === column)
				this.localPagination.descending = !this.localPagination.descending;
			else {
				this.localPagination.sortBy = column;
				this.localPagination.descending = false;
			}
		},

		getAddressReadable(...lines) {
			return lines.filter(line => !!line).join(', ');
		}
	}
};
</script>

<style lang="scss" scoped>
	@import "@Assets/css/settings.scss";

	::v-deep .custom-data-table .v-table thead tr,
	::v-deep .custom-data-table .v-table tbody tr,
	::v-deep .v-datatable .v-datatable__actions {
		border: 0;
	}

	::v-deep .custom-data-table .v-table tbody td {
		padding-top: 10px;
		padding-bottom: 10px;
		background-color: $secondary-light;
		height: 100%;
	}

	::v-deep .custom-data-table .v-table tbody td:first-child {
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border: 2px solid $secondary-light;
	}
	::v-deep .custom-data-table .v-table tbody td:last-child {
		border: 4px solid $secondary-light;
		border-bottom-right-radius: 10px;
		border-top-right-radius: 10px;
	}

	::v-deep .custom-data-table .v-table {
		background-color: $primary_light;
		border-collapse: separate;
		border-spacing: 0 15px;
	}

	::v-deep .custom-data-table .v-datatable .v-datatable__actions {
		background-color: $primary_light;
	}

	::v-deep .v-input--selection-controls .v-input__control,
	::v-deep .v-input--selection-controls.v-input .v-label {
		width: 100%;
	}

	::v-deep .v-datatable__actions__pagination{
		display: none;
	}

	.custom-icon-checked ::v-deep .v-icon {
		color: #2dce71 !important;
		caret-color: #2dce71 !important;
	}

	.custom-footer{
		font-size: 12px;
	}

	.custom-footer-container{
		padding:0;
		display: flex;
		flex-direction: column;
		align-self:end
	}

</style>
