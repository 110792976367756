<template>
	<v-data-table :headers="headers" :items="items" no-data-text="Nenhum status" disable-initial-sort>
		<template v-slot:items="props">
			<td class="text-xs-center">
				<v-chip slot="activator" small disabled class="mx-0 white--text info">
					{{ props.item.status | statusBox }}
				</v-chip>
			</td>
			<td class="text-xs-center">
				{{ props.item.user.nome }}
			</td>
			<td class="text-xs-center">
				{{ props.item.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}
			</td>
		</template>
	</v-data-table>
</template>

<script>
export default {
	name: 'historico-status',
	props: {
		box: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		headers: [
			{ text: 'Status', value: 'status', align: 'center' },
			{ text: 'Usuário', value: 'user.nome', align: 'center' },
			{ text: 'Data', value: 'created_at', align: 'center' }
		]
	}),
	computed: {
		items() {
			return this.box.status_box;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
