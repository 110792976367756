<template>
	<div v-if="text">
		<v-icon small>
			{{icon}}
		</v-icon>
		{{ text }}
	</div>
</template>

<script>
export default {
	name: 'icontext',
	props: {
		icon: {
			type: String,
			required: true
		},
		text: {
			type: String,
			default: ''
		}
	}
};
</script>

<style lang="scss" scoped>
.v-icon {
	width: 25px;
}
</style>
