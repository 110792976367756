<template>
	<v-card>
		<v-card-title>
			<v-avatar size="36" class="mr-3">
				<v-icon color="primary" small>fa-solid fa-file-signature</v-icon>
			</v-avatar>
			<h3 class="text--text mr-2">Novas Assinaturas</h3>
			<v-spacer />
			<v-btn small depressed to="/colaborador/assinaturas">Ver todas</v-btn>
		</v-card-title>
		<v-card-text class="pt-0">
			<div v-if="carregando" class="carregando">
				<v-progress-circular indeterminate color="grey" />
			</div>
			<div v-else>
				<div v-if="assinaturas.length === 0" class="carregando">
					<v-icon color="grey" class="mb-1" style="font-size: 32px; opacity: .5">check_circle_outline</v-icon>
					<p class="mb-0">Nenhuma assinatura está pendente.</p>
				</div>
				<v-list v-else avatar two-line>
					<v-list-tile v-for="item in assinaturas" :key="item.id">
						<v-list-tile-avatar size="36">
							<span class="white--text" style="font-size: 14px">
								{{ item.paciente.nome.charAt(0) }}
							</span>
						</v-list-tile-avatar>
						<v-list-tile-content>
							<v-list-tile-title><strong class="grey--text text--darken-1">Assinatura {{ item.id }}</strong>
								- {{ item.paciente.nome }}</v-list-tile-title>
							<v-list-tile-sub-title>
								<strong>Data da assinatura:</strong> {{ item.created_at }} - <strong>Início primeira box:</strong>
								{{ item.data_proxima_box | formatDate('DD/MM/YYYY') }}
							</v-list-tile-sub-title>
						</v-list-tile-content>
					</v-list-tile>
				</v-list>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';

export default {
	name: 'NovasAssinaturas',
	data: () => ({
		assinaturas: [],
		carregando: true
	}),
	async created() {
		this.assinaturas = await AssinaturaPresenter.index({ limit: 10 });
		this.carregando = false;
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.v-card__text {
  overflow-y: auto;
  height: 300px;
}

.v-card__title {
  .v-avatar {
    background: rgba($warning, 0.2) !important;
  }
}
</style>
