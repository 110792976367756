<template>
	<div class="orcamentos-wrapper px-md-5">
		<Header :enablePurchaseList="!selected.length" :quotationSellers="quotationSellers" @search="onChangeSearch" @filter="onChangeFilter"
			@generatePurchaseList="generatePurchaseList" @createQuotationV2="createQuotationV2" />

		<v-card>
			<DataTableOrcamento
				ref="DataTableOrcamento"
				:sellers="quotationSellers"
				:searchQuotation="search"
				:filter="filter"
				@selected-updated="arr => selected = arr"/>
		</v-card>

		<v-dialog persistent v-model="quotationV2" fullscreen hide-overlay scrollable
			transition="fade">
			<QuotationV2 v-if="quotationV2" @close="quotationV2 = false" @cancelQuotation="quotationV2 = false" />
		</v-dialog>
	</div>
</template>

<script>
import { debounce } from 'lodash';
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';
import QuotationV2 from '@Componentes/quotation-v2';
import { UserPresenter } from '@Presenter/user-presenter';
import Header from './header';
import DataTableOrcamento from './data-table-orcamento-b2c.vue';

export default {
	name: 'OrcamentosB2C',
	components: {
		Header, DataTableOrcamento, QuotationV2
	},
	data: () => ({
		selected: [],
		quotationSellers: [],
		isLoading: true,
		quotationV2: false,
		filter: {},
		search: ''
	}),
	created() {
		UserPresenter.index()
			.then(response => {
				this.quotationSellers = response.map(seller => ({ id: seller.id, name: seller.nome, phone: seller.telefone }));
			})
			.catch(() => {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao buscar vendedores' });
			});
	},
	watch: {
		quotationV2(value) {
			if (value === false)
				this.$refs.DataTableOrcamento.refresh();
		}
	},
	methods: {
		generatePurchaseList() {
			// PRECISA AJUSTAR ESSE ENDPOINT
			return new Promise(async (resolve, reject) => {
				// this.gerando = true;
				try {
					const ids = this.selected.map(a => a.id);
					const promises = [OrcamentoPresenter.listaDeCompras(ids)];
					promises.push(import('@Util/excel-lista-compra'));
					const responses = await Promise.all(promises);
					const { geraListaDeCompras } = responses[1];
					geraListaDeCompras(responses[0]);
				} catch (error) {
					reject();
				}
				// this.gerando = false;
				resolve();
			});
		},
		onChangeFilter(status, seller) {
			this.filter = { status, seller };
		},
		onChangeSearch: debounce(function (search) {
			this.search = search;
		}, 750),
		createQuotationV2() {
			this.quotationV2 = true;
		}
	}
};
</script>

<style lang="scss" scoped>
.v-card {
  overflow: hidden;
}

.status-input {
  margin-top: 15px;
  border-radius: 5px;
  padding: 7px 10px 7px 15px;
  max-width: 200px;

  ::v-deep.v-input__slot {
    &:before,
    &:after {
      width: 0 !important;
      border-color: transparent !important;
    }

    .v-select__selection,
    .v-icon {
      color: #fff !important;
    }
  }
}

::v-deep .v-menu__activator {
  a {
    margin: 0 auto;
  }
}
</style>
