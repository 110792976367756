<template>
	<v-container fluid grid-list-md class="pa-0">
		<v-layout wrap>
			<v-flex md6 xs12 class="mt-5">
				<p class="inter subheading mb-0">Público</p>
				<p class="inter text-bold headline">Domicílio</p>
			</v-flex>
			<v-flex md6 xs12 class="mt-5">
				<v-autocomplete
					v-model="quotation.companyUnit"
					:items="units"
					label="Unidade *"
					box
					clearable
					v-validate="{ required: true }"
					:error-messages="errors.collect('Unidade')"
					data-vv-name="Unidade"
					background-color="white"
					item-value="id" item-text="city" />
			</v-flex>
			<hr class="spacerQuotationStyle">
			<PersonInfo ref="personInfo" :quotation="quotation" :tabSelected="tabSelected" />
			<hr class="spacerQuotationStyle">
			<v-flex md6 xs12>
				<v-autocomplete
					v-model="quotation.seller"
					:items="sellers"
					box
					hide-details
					label="Vendedor *" placeholder="Digite para filtrar..."
					clearable
					background-color="white"
					return-object item-text="name" data-vv-name="Vendedor" :error-messages="errors.collect('Vendedor')" v-validate="{ required: true }"  />
			</v-flex>
			<v-flex md6 xs12>
				<TextDateField v-model="quotation.expiresAt" box background-color="white" label="Validade"
					placeholder="dd/mm/yyyy"/>
			</v-flex>

			<hr class="spacerQuotationStyle">

			<v-flex xs12 md12 class="mb-2" style="padding: 4px 4px 33px !important">
				<div class="box" style="background: white" @click="dialogTags = true">
					<label class="ml-2 v-label" :class="{ 'with-items': (quotation.tags || []).length > 0 }">
						Tags
					</label>
					<v-chip v-for="tag in quotation.tags" :key="tag.id" disabled class="purple white--text">
						{{ tag.nome || tag }}
					</v-chip>
				</div>
			</v-flex>
			<v-dialog v-model="dialogTags" persistent scrollable transition="fade" max-width="840px">
				<transition>
					<DialogTags v-if="dialogTags" :tags="quotation.tags"
						@novas-tags="tags => quotation.tags = tags" @close="dialogTags = false" />
				</transition>
			</v-dialog>
		</v-layout>
	</v-container>
</template>

<script>
import DialogTags from '@Componentes/dialog-tags';
import { isEqual } from 'lodash';
import PersonInfo from './person-info.vue';
import Mixin from '../mixin';

export default {
	name: 'GeneralDataQuotationV2',
	components: { DialogTags, PersonInfo },
	$_veeValidate: { validator: 'new' },
	mixins: [Mixin],
	props: {
		tabSelected: {
			type: String
		},
		sellers: {
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		dialogTags: false,
		// loadingRestHomes: true
		restHomes: [],
		units: [
			{ id: 'BH', city: 'Belo Horizonte' },
			{ id: 'SP', city: 'São Paulo' }
		],
		priceTables: []

	}),
	watch: {
		'quotation.expiresAt': {
			handler(val, oldVal) {
				if (!isEqual(val, oldVal))
					this.$emit('changedExpiresAt', true);
			},
			deep: true
		}
	},
	methods: {
		async validate() {
			const personInfoValidate = Boolean(this.$refs.personInfo.validate());
			return Boolean(await this.$validator.validateAll() && personInfoValidate);
		}
	}
};
</script>
