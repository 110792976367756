<template>
	<Dialog titulo="Pendências" @close="close" btn_acao="Concluir" btn_cancelar="Cancelar" @acao="concluir"
		@cancelar="close" :btn_acao_disable="!pendencias_concluidas" >
		<v-container class="pa-4">
			<v-layout wrap>
				<v-flex xs12 md12>
					<v-checkbox v-model="pendencias_concluidas" label="Declaro que revisei as pendências e sinalizações. Produto está apto a prosseguir." />
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>

export default {
	name: 'ConcluirPendencia',
	data: () => ({
		pendencias_concluidas: false
	}),
	methods: {
		close() {
			this.$emit('close');
		},
		concluir() {
			this.$emit('concluir');
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
