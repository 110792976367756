<template>
	<v-tooltip top>
		<template v-slot:activator="{ on }">
			<i class="fas fa-circle pl-1" v-on="on" :class="color + '--text'"></i>
		</template>

		<span>{{ description }}</span>
	</v-tooltip>

</template>

<script>
export default {
	name: 'PrioridadeAtendimento',
	props: {
		days: {
			required: true,
			type: Number
		},
		status: {
			required: true,
			default: null
		},
		hasCalendar: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		color() {
			return this.hasCalendar
				? this.colorWithCalendar()
				: this.colorWithoutCalendar();
		},
		description() {
			if (this.days < 0 || this.status === 'DELAYED')
				return `Em atraso, ${Math.abs(this.days)} dia(s)`;

			if (this.days === 0)
				return 'Hoje';

			return `Início em: ${this.days} dia(s)`;
		}
	},
	methods: {
		colorWithCalendar() {
			if (this.days < 0)
				return 'red';

			if (this.days < 4)
				return 'orange';

			return 'light-green';
		},

		colorWithoutCalendar() {
			if (this.days < 4 || this.status === 'DELAYED')
				return 'red';

			if (this.days < 7)
				return 'orange';

			return 'light-green';
		}
	}
};
</script>
