<template>
	<div v-if="zxcvbn" class="barra-forca" :class="cor" />
</template>

<script>

export default {
	name: 'ForcaSenha',
	props: {
		senha: {
			default: ''
		},
		hide_details: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		forca: 0,
		zxcvbn: null
	}),
	created() {
		import('zxcvbn').then(imp => {
			this.zxcvbn = imp.default;
		});
	},
	computed: {
		forcaSenha() {
			if (this.senha === '') {
				this.$emit('forca-senha', { forca: -1, mensagem: 'A senha deve conter no mínimo 10 caracteres.' });
				return -1;
			}
			const forca = this.zxcvbn(this.senha).score;
			switch (forca) {
				case 0: this.$emit('forca-senha', { forca, mensagem: 'A senha é muito fraca.' }); break;
				case 1: this.$emit('forca-senha', { forca, mensagem: 'A senha é fraca.' }); break;
				case 2: this.$emit('forca-senha', { forca, mensagem: 'A senha é razoável.' }); break;
				case 3: this.$emit('forca-senha', { forca, mensagem: 'A senha é forte.' }); break;
				case 4: this.$emit('forca-senha', { forca, mensagem: 'A senha é muito forte.' }); break;
				default: break;
			}
			return forca;
		},
		cor() {
			switch (this.forcaSenha) {
				case -1: return this.hide_details ? 'hide-details' : null;
				case 0: return this.hide_details ? 'b20 hide-details' : 'b20';
				case 1: return this.hide_details ? 'b40 hide-details' : 'b40';
				case 2: return this.hide_details ? 'b60 hide-details' : 'b60';
				case 3: return this.hide_details ? 'b80 hide-details' : 'b80';
				case 4: return this.hide_details ? 'b100 hide-details' : 'b100';
				default: return null;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.barra-forca {
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 2px;
  background: transparent;
  margin-top: -29px;
  margin-bottom: 28px;

  &::before {
    position: absolute;
    z-index: -1;
    width: 0;
    top: 0;
    bottom: 0;
    content: " ";
    transition: width 0.3s;
  }

  &.hide-details {
    margin-top: -1px !important;
    margin-bottom: 0px !important;
  }

  &.b20 {
    background: $error-light;

    &::before {
      background: $error;
      width: 20% !important;
    }
  }

  &.b40 {
    background: $error-light;

    &::before {
      background: $error;
      width: 40% !important;
    }
  }

  &.b60 {
    background: $warning-light;

    &::before {
      background: $warning;
      width: 60% !important;
    }
  }

  &.b80 {
    background: $success-light;

    &::before {
      background: $success;
      width: 80% !important;
    }
  }

  &.b100 {
    background: $success-light;

    &::before {
      background: $success;
      width: 100% !important;
    }
  }
}
</style>
