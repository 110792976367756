<template>
	<v-card>
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>payment</v-icon>
			</v-avatar>
			<h3>Assinaturas</h3>
			<v-spacer />
			<v-progress-circular v-if="carregando_assinaturas" indeterminate color="grey" />
			<span v-else-if="minhas_assinaturas.length === 0">
				Essa pessoa não possui assinatura.
			</span>
		</v-card-title>
		<v-card-text class="pt-0" v-if="!carregando_assinaturas && minhas_assinaturas.length !== 0">
			<v-list two-line>
				<v-list-tile v-for="(assinatura, index) in minhas_assinaturas" :key="index" avatar @click="selecionaAssinatura(assinatura.id)">
					<v-list-tile-avatar class="avatar-transparent">
						<v-icon color="grey" style="font-size: 18px">fas fa-pills</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title>Assinatura {{ assinatura.id }}</v-list-tile-title>
						<v-list-tile-sub-title>
							Número de medicamentos: {{ assinatura.__meta__.medicamentos_count }}
						</v-list-tile-sub-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list>
		</v-card-text>
	</v-card>
</template>

<script>
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';

export default {
	name: 'InfoAssinaturas',
	props: {
		id_paciente: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		carregando_assinaturas: true,
		minhas_assinaturas: []
	}),
	created() {
		this.getAssinaturas();
	},
	methods: {
		getAssinaturas() {
			this.carregando_assinaturas = true;
			AssinaturaPresenter.index({ paciente_id: this.id_paciente })
				.then(assinaturas => {
					this.minhas_assinaturas = assinaturas;
				})
				.finally(() => {
					this.carregando_assinaturas = false;
				});
		},
		selecionaAssinatura(id) {
			this.$router.push({ name: 'Assinatura', params: { id } });
		}
	}
};
</script>

<style lang="scss" scoped>
::v-deep .avatar-transparent {
  .v-avatar {
    background: transparent !important;
  }
}
</style>
