<template>
	<v-card flat>
		<v-container fluid grid-list-lg class="pa-0">
			<v-data-table :headers="headers" :items="nfs" :loading="carregando" :rows-per-page-items="[10, 20, 30]"
				no-results-text="Nenhum resultado encontrado" no-data-text="Nenhum resultado encontrado"
				rows-per-page-text="Itens por página">
				<template slot="items" slot-scope="props">
					<tr>
						<td>{{ props.item.created_at | formatDate('DD/MM/YYYY HH:mm') }}</td>
						<td>{{ props.item.valor | dinheiro }}</td>
						<td>{{ props.item.status + (props.item.motivo_status ? ` (${props.item.motivo_status})` : '') || '-' }}</td>
					</tr>
				</template>
			</v-data-table>
		</v-container>
	</v-card>
</template>

<script>
import { NfsPresenter } from '@Presenter/nfs-presenter';

export default {
	name: 'ConsultarNFSE',
	props: {
		box: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		headers: [
			{ text: 'Data', value: 'data' },
			{ text: 'Valor', value: 'valor' },
			{ text: 'Situação', value: 'status' }
		],
		carregando: false,
		nfs: []
	}),
	created() {
		this.carregarNfs();
	},
	methods: {
		carregarNfs() {
			this.carregando = true;
			NfsPresenter.index({ assinatura_id: this.box.assinatura_id })
				.then(response => {
					this.nfs = response;
				})
				.finally(() => {
					this.carregando = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
