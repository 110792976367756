<template>
	<Dialog titulo="Alterar e-mail" subtitulo="Mude o e-mail apenas se necessário." btn_acao="Salvar"
		btn_cancelar="Cancelar" @acao="alterarEmail" @close="fechaModal()" :loading="loading">

		<h4>E-mail atual:</h4>
		<p>{{ email_atual }}</p>

		<v-divider class="mb-4" />

		<v-text-field box v-model="dados.email" label="Novo E-mail" data-vv-name="novo e-mail"
			v-validate="{ email: true }" :error-messages="errors.collect('novo e-mail')" @keyup.enter="alterarEmail()" />

		<v-text-field box v-model="dados.senha" type="password" label="Senha" data-vv-name="senha"
			v-validate="{ min: 6, required: true }" :error-messages="errors.collect('senha')"
			@keyup.enter="alterarEmail()" />

		<span>Você terá que fazer login novamente após trocar seu e-mail.</span>

	</Dialog>
</template>

<script>
import { UserPresenter } from '@Presenter/user-presenter';

export default {
	name: 'AlterarEmail',
	$_veeValidate: { validator: 'new' },
	data: () => ({
		dados: {},
		loading: false
	}),
	methods: {
		alterarEmail() {
			this.$validator.validateAll().then(valido => {
				if (!valido) {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
					return;
				}
				this.loading = true;

				UserPresenter.atualizarEmail({ ...this.dados })
					.then(() => {
						this.$store.dispatch('MSG_TOAST', 'E-mail alterado com sucesso! Por favor, confira sua caixa de email.');
						this.$router.push({ name: 'Entrar', params: { stop: true } });
						this.$functions.Logout();
					})
					.finally(() => {
						this.loading = false;
					});
			});
		},
		fechaModal() {
			this.$emit('update:close', false);
		}
	},
	computed: {
		email_atual() {
			return (this.$store.getters.contexto.user || {}).email || '';
		}
	}
};
</script>
