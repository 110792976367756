<template>
	<div class="px-md-5">
		<HeaderListProduct
			@openCreateProductDialog="openCreateProductDialog = true"
			@onSearch="(params) => onSearch(params)"/>

		<v-card>
			<v-data-table
				ref="dataTableProducts"
				:items="products"
				:headers="headers"
				:loading="loadingProducts"
				:no-data-text="
					loadingProducts ? 'Carregando...' : 'Nenhum produto encontrado'
				"
				no-results-text="Nenhum resultado encontrado"
				:total-items="totalItems"
				:rows-per-page-items="rowsPerPageItems"
				rows-per-page-text="Itens por página"
				:pagination.sync="pagination"
				item-key="id">
				<template slot="items" slot-scope="props">
					<tr @click="selectProduct(props.item.id)" style="cursor: pointer">
						<td>{{props.item.id}}</td>
						<td>
							<strong>{{props.item.name}} </strong>
							<br> {{props.item.presentation}} <v-tooltip v-if="props.item.drug && props.item.drug.pbm" top>
								<v-chip class="justify-center" color="orange" style="max-width: 20px; max-height: 20px;" slot="activator" dark
									small><strong>%</strong></v-chip>
								<span>{{ props.item.drug.pbm.program || "PBM" }}</span>
							</v-tooltip>
						</td>
						<td>
							<template v-if="props.item.drug">
								{{ props.item.drug.activeIngredientReadable }}
							</template>
							<template v-else>-</template>
						</td>
						<td>{{props.item.brand}}</td>
						<td>
							<template v-if="props.item.drug">
								{{ getMedicineTypeDescription(props.item.drug.type) }}
							</template>
							<template v-else>
								-
							</template>
						</td>
						<td class="text-center">
							<v-tooltip right>
								<template v-slot:activator="{ on }">
									<v-icon
										class="mx-0 pointer"
										v-on="on"
										:color="getColorStatus(props.item.availability)">
										fas fa-circle
									</v-icon>
								</template>
								{{ getAvailabilityDescription(props.item.availability) }}
							</v-tooltip>
						</td>
						<td>{{props.item.price | dinheiro}}</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog
			persistent
			v-model="openCreateProductDialog"
			fullscreen
			hide-overlay
			scrollable
			transition="fade">
			<CreateProduct
				v-if="openCreateProductDialog"
				@close="closeCreateProductDialog"/>
		</v-dialog>

		<v-dialog
			persistent
			v-model="openEditProductDialog"
			fullscreen
			hide-overlay
			scrollable
			transition="fade">
			<EditProduct
				v-if="openEditProductDialog"
				:productId="productId"
				@close="closeEditProductDialog"/>
		</v-dialog>
	</div>
</template>

<script>
import { ProductPresenter } from '@Presenter/product-presenter';
import { debounce, isEqual } from 'lodash';
import { getCurrentCompanyUnit } from '@Config/unidades';
import { medicineTypesLabel } from '@Consts/medicine-types';
import { availabilities, getAvailabilityColorStatus } from '@Consts/availabities';
import CreateProduct from '../new';
import EditProduct from '../edit';
import HeaderListProduct from './header.vue';

export default {
	components: {
		HeaderListProduct,
		CreateProduct,
		EditProduct
	},
	async created() {
		// await this.getProducts();
	},
	data() {
		return {
			pagination: {
				page: 1,
				rowsPerPage: 20
			},
			params: {
				q: null,
				layerId: null,
				ids: null
			},
			loadingProducts: true,
			totalItems: 0,
			products: [],
			productId: null,
			openCreateProductDialog: false,
			openEditProductDialog: false
		};
	},
	computed: {
		headers() {
			return [
				{
					text: 'ID',
					value: 'id',
					sortable: true,
					align: 'left'
				},

				{
					text: 'Nome',
					value: 'name',
					sortable: false,
					align: 'left'
				},
				{
					text: 'Princípio ativo',
					sortable: false,
					align: 'left'
				},
				{
					text: 'Marca',
					value: 'brand',
					sortable: false,
					align: 'left'
				},
				{
					text: 'Tipo',
					value: 'drug',
					sortable: false,
					align: 'left'
				},
				{
					text: 'Disponibilidade',
					value: 'availability',
					sortable: false,
					align: 'left'
				},
				{
					text: 'Preço',
					value: 'price',
					sortable: false,
					align: 'left'
				}
			];
		},
		rowsPerPageItems() {
			return [10, 15, 20, 45, 90];
		}
	},
	methods: {
		getMedicineTypeDescription(type) {
			return medicineTypesLabel[type];
		},
		getAvailabilityDescription(availability) {
			return availabilities[availability];
		},
		getColorStatus(availability) {
			return getAvailabilityColorStatus(availability);
		},
		getProducts: debounce(async function () {
			try {
				this.loadingProducts = true;

				const productsResponse = await ProductPresenter.getProducts({
					page: this.pagination.page,
					perPage: this.pagination.rowsPerPage,
					ids: this.params.ids,
					q: this.params.q,
					layerId: this.params.layerId,
					companyUnitId: getCurrentCompanyUnit().id,
					sortBy: this.pagination.sortBy,
					sortDirection: this.pagination.descending ? 'DESC' : 'ASC'
				});

				this.products = productsResponse.items;
				this.totalItems = productsResponse.total;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao buscar produtos',
					color: 'error'
				});
			} finally {
				this.loadingProducts = false;
			}
		}, 500),
		async selectProduct(productId) {
			this.openEditProductDialog = true;
			this.productId = productId;
		},
		onSearch: debounce(function (params) {
			this.params = { ...this.params, q: params.q, layerId: params.layerId };
		}, 100),
		async closeCreateProductDialog() {
			this.openCreateProductDialog = false;
			await this.getProducts();
		},
		async closeEditProductDialog() {
			this.openEditProductDialog = false;
			await this.getProducts();
		}
	},
	watch: {
		pagination: {
			deep: true,
			async handler(val, oldVal) {
				if (isEqual(val, oldVal))
					return;

				await this.getProducts();
			}
		},
		params: {
			deep: true,
			async handler(val, oldVal) {
				if (isEqual(val, oldVal))
					return;

				await this.getProducts();
			}
		}
	}
};
</script>
