import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify, {
	theme: {
		primary: '#ffc875',
		primary_light: '#fff3e2',
		info: '#999999',
		info_light: '#008000',
		success: '#00b503',
		warning: '#ffb300',
		warning_light: '#fce3aa',
		error: '#fa3201',

		accent: '#ffc875',

		purple: '#ffc875',
		green: '#342b1d',
		green_strong: '#342b1d',

		text: '#25352C',

		...Vuetify.theme
	}
});
