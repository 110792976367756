import Vue from 'vue';

import Card from '@Componentes/card';
import Form from '@Componentes/form';
import Dialog from '@Componentes/dialog';
import IconText from '@Componentes/icon-text';
import TextDateField from '@Componentes/text-date-field';
import DialogConfirmacao from '@Componentes/dialog-confirmacao';

Vue.component('Card', Card);
Vue.component('Form', Form);
Vue.component('Dialog', Dialog);
Vue.component('IconText', IconText);
Vue.component('TextDateField', TextDateField);
Vue.component('DialogConfirmacao', DialogConfirmacao);
