<template>
	<Dialog titulo="Detalhes do orçamento" :subtitulo="`Orçamento: ${quotation.id}`" @close="$emit('update:close', false)"
		background="#FFEACC" background-header="#FFEACC">
		<v-container fluid grid-list-md class="pa-0">
			<v-layout wrap>
				<v-card color="#FFF3E2" class="cardStyle sans pb-sm-3 pt-sm-4 px-sm-4">
					<v-flex class="d-flex items-center justify-center">
						<div style="width: 68px !important">
							<v-avatar size="68" style="width: 68px !important" color="white">
								<img src="/img/icons/patient.svg" alt="Icone de paciente"
									style="width: 35px;height: 39px" />
							</v-avatar>
						</div>
						<v-flex xs12 md6>
							<p class="font-weight-bold mb-2" style="font-size: 18px">{{ quotation.customer.name}}</p>
							<p class="mb-0"><strong>Telefone:</strong> {{ quotation.customer.phone | telefone }}</p>
							<p v-if="quotation.customer.email" class="mb-0"><strong>E-mail:</strong> {{ quotation.customer.email }}</p>
						</v-flex>
						<v-flex xs12 md6>
							<p class="mb-0"><strong>Criação:</strong> {{ quotation.createdAt | formatDate('DD/MM/YYYY') }}
							</p>
							<p class="mb-0"><strong>Validade:</strong> {{ quotation.expiresAt | formatDate('DD/MM/YYYY')}}</p>
							<p class="mb-0"><strong>Qtd. medicamentos:</strong> {{ quotationItems.length || 0 }}</p>
							<p class="mb-0"><strong>Vendedor:</strong> {{ quotation.seller.name }}</p>
						</v-flex>
						<v-flex xs12 md6>
							<p class="mb-0"><strong>Data de início: </strong> <br> {{ quotation.startDate | formatDate('DD/MM/YYYY')}} </p>
							<v-chip disabled :class="$functions.getStatusOrcamento(quotation.status).class"
								class="white--text text-uppercase px-3">
								{{ $functions.getStatusOrcamento(quotation.status).label }}
							</v-chip>
						</v-flex>
					</v-flex>
				</v-card>

				<v-flex d-flex justify-center style="gap: 10px">
					<div v-for="item in quotationValues" :key="item.label" class="py-sm-4">
						<span style="font-weight: 600; font-size: 11px;">{{ item.label }}</span>
						<v-card :style="`${item.transparent ? 'color: #857B6B ' : '#342B1D'} `"
							class="px-sm-2 mt-xs-1 py-sm-2 boldValue" :color="'#FFF3E2'">
							{{ item.value }}
						</v-card>
					</div>
				</v-flex>
				<section v-if="loadingProducts" class="d-flex items-center justify-center loading full-width">
					<v-progress-circular indeterminate color="primary" />
				</section>
				<section v-else class="full-width">
					<div v-if="quotationItems.length > 0">
						<div class="sans pt-sm-3 pl-sm-3 pr-sm-3 mb-2 cardStyle">
							<div style="justify-content: space-between !important; flex-direction: row; display: flex;">
								<div>
									<span class="smallTitleBold pr-4">Qtd.</span>
									<span class="smallTitleBold pl-4">Produto</span>
								</div>
								<div class="medicine-container">
									<v-flex d-flex style="text-align: center;">
										<span class="smallTitleBold" style="width: 65px !important">Preço da caixa/pacote</span>
										<span class="smallTitleBold" style="width: 65px !important">Desconto na caixa</span>
										<span class="smallTitleBold" style="width: 65px !important">Preço total</span>
									</v-flex>
								</div>
							</div>
						</div>
						<DialogProductItem v-for="(item, index) in quotationItems" :key="index"
							:item="item"
							:product="products.find(product=> product.id === item.productId)"/>
					</div>
					<div v-else class="flex-center justify-center my-5">
						<span class="title text-bold">Nenhum produto adicionado</span>
					</div>
				</section>

				<v-flex md12 xs12 class="mt-3">
					<v-textarea
						v-model="quotation.publicObservations"
						placeholder="Observações do cliente"
						readonly
						box
						background-color="white"
						hide-details/>
				</v-flex>

				<div class="mx-3 mt-3 mb-2 separator" />

				<QuotationHistoric :items="quotation.history"/>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { mascaraDinheiro, mascaraPorcentagem } from '@Util/functions';
import QuotationHistoric from '@Componentes/quotation-v2/historic';
import { QuotationPresenter } from '@Presenter/quotation-presenter';
import { ProductPresenter } from '@Presenter/product-presenter';
import { getQuantityByProductItem } from '@Util/ProductService';
import { companyUnitIds } from '@Consts/unidades';
import DialogProductItem from './dialog-detail-b2c-product.vue';

import mixin from './mixin-data-table';

export default {
	name: 'DialogDetalhesB2C',
	mixins: [mixin],
	components: {
		QuotationHistoric, DialogProductItem
	},
	props: {
		quotation_id: {
			type: Number
		},
		sellers: {
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		quotation: {
			customer: {}, seller: {}, items: [], history: []
		},
		loadingQuotation: false,
		loadingProducts: false,
		products: []
	}),
	created() {
		this.loadingQuotation = true;
		QuotationPresenter.show(this.quotation_id)
			.then(response => {
				this.quotation = response;
				this.getProducts();
			})
			.finally(() => {
				this.loadingQuotation = false;
			});
	},
	methods: {
		getProducts() {
			if (this.quotationItems.length > 0) {
				this.loadingProducts = true;
				const idsSelected = this.quotationItems.map(product => product.productId);

				ProductPresenter.getProducts({ ids: idsSelected, companyUnitId: companyUnitIds[this.quotation.companyUnit], layerId: this.quotation.pricingLayerId })
					.then(response => {
						this.products = response.items;
					})
					.catch(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao buscar produtos', color: 'error' });
					})
					.finally(() => {
						this.loadingProducts = false;
					});
			}
		}
	},
	computed: {
		quotationItems() {
			if (this.quotation.items && this.quotation.items.length > 0)
				return this.quotation.items;
			return [];
		},
		totalValue() {
			return this.quotation.total + this.discountValue;
		},
		discountValue() {
			const discount = this.quotationItems.reduce((accumulator, currentValue) => {
				const closedPackages = this.getClosedPackages(currentValue);

				const quantityByProductItem = getQuantityByProductItem(currentValue.posology, closedPackages);

				const numberPackagesNeeded = this.getNumberPackagesNeeded(quantityByProductItem, currentValue);

				return accumulator + currentValue.discount * numberPackagesNeeded.toFixed(2);
			}, 0);

			return discount;
		},
		quotationValues() {
			return [
				{ value: mascaraDinheiro(this.totalValue), label: 'Preço total', transparent: true },
				{ value: mascaraDinheiro(this.discountValue), label: 'Desconto', transparent: true },
				{ value: mascaraDinheiro(this.quotation.total), label: 'Preço a cada 30 dias' },
				{ value: `${mascaraPorcentagem(this.quotation.percentDiscountFirstBox)} | ${mascaraDinheiro(this.firsthMontPriceNominal)}`, label: 'Desconto 1º mês', transparent: true },
				{ value: mascaraDinheiro(this.firstMonthPrice), label: 'Preço 1º mês' }
			];
		},
		firstMonthPrice() {
			let total = this.quotation.total;
			if (this.quotation.percentDiscountFirstBox) {
				total -= ((total - this.quotation.shippingPrice) * this.quotation.percentDiscountFirstBox) / 100;
				return total;
			}

			return total;
		},
		firsthMontPriceNominal() {
			return this.quotation.total - this.firstMonthPrice;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.cardStyle {
	width: 100%;
	border-radius: 0px 10px 10px 0px;
	color: rgba(52, 43, 29, 1);
}

.boldValue {
	font-weight: 600;
	font-size: 16px;
}

.smallTitle {
	font-size: 9px
}

.smallTitleBold {
	font-size: 11px;
	font-weight: 600;
}

.medicine-container {
	width: 370px;

	.medicine-value {
		font-weight: 600;
		font-size: 16px;
		text-align: center;
	}
}
.separator {
	width: 100%;
	border-top: 4px dotted rgba(255, 200, 117, 0.6);
}
</style>
