const BUSINESS_TYPE_B2B_VALUE = 'B2B';
const BUSINESS_TYPE_B2B_TEXT = 'B2B';
export const BUSINESS_TYPE_B2B = Object.freeze({
	value: BUSINESS_TYPE_B2B_VALUE,
	text: BUSINESS_TYPE_B2B_TEXT
});

const BUSINESS_TYPE_B2C_VALUE = 'B2C';
const BUSINESS_TYPE_B2C_TEXT = 'B2C';
export const BUSINESS_TYPE_B2C = Object.freeze({
	value: BUSINESS_TYPE_B2C_VALUE,
	text: BUSINESS_TYPE_B2C_TEXT
});

export const BUSINESS_TYPES = Object.freeze([
	BUSINESS_TYPE_B2B,
	BUSINESS_TYPE_B2C
]);
