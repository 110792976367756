import Login from '@Views/login';
import Entrar from '@Views/login/entrar';
import Cadastrar from '@Views/login/cadastrar';
import RecuperarSenha from '@Views/login/recuperar-senha';
import VerificacaoEmail from '@Views/login/verificacao-email';
import CriarSenha from '@Views/login/criar-senha';

import Esqueleto from '@Views/usuario/esqueleto';

import Paciente from '@Componentes/pacientes/paciente';
import Responsavel from '@Views/colaborador/responsaveis/responsavel';
import Perfil from '@Views/usuario/perfil';

import EsqueletoColaborador from '@Views/colaborador/esqueleto';
import Dashboard from '@Views/colaborador/dashboard';

import Orders from '@Views/colaborador/orders';
import Order from '@Views/colaborador/orders/order';

import AssinaturasColaborador from '@Views/colaborador/assinaturas';
import AssinaturaColaborador from '@Views/colaborador/assinaturas/assinatura';
import Orcamentos from '@Views/colaborador/orcamentos';
import OrcamentosB2C from '@Views/colaborador/orcamentos-b2c';

import Calendario from '@Views/colaborador/calendario';
import OrcamentosExternos from '@Views/colaborador/orcamentos-externos';

import Pacientes from '@Componentes/pacientes';
import Responsaveis from '@Views/colaborador/responsaveis';
import ConfiguracoesUsuarios from '@Views/colaborador/configuracoes/usuarios';
import ConfiguracoesCalendario from '@Views/colaborador/configuracoes/calendario';
import ConfiguracoesTag from '@Views/colaborador/configuracoes/tag';
import CasaRepousoColaborador from '@Views/colaborador/casa-repouso';
import MedicamentosColaborador from '@Views/colaborador/medicamentos';
import ProductsView from '@Views/colaborador/products/list';
import AiImages from '@Views/colaborador/aiimages';
import Rastreabilidade from '@Views/colaborador/rastreabilidade-fotos';

import Picking from '@Views/colaborador/processo/picking';
import Faturamento from '@Views/colaborador/processo/faturamento';
import Producao from '@Views/colaborador/processo/producao';
import PreProducao from '@Views/colaborador/processo/pre-production/list';
import Produzir from '@Views/colaborador/processo/producao/v2/in-production';
import Conferencia from '@Views/colaborador/processo/conferencia';

import Farmaceutico from '@Views/colaborador/backoffice/farmaceutico';
import Compra from '@Views/colaborador/backoffice/compra';
import Atendimento from '@Views/colaborador/backoffice/atendimento';
import Cobranca from '@Views/colaborador/backoffice/cobranca';

import Expedicao from '@Views/colaborador/logistica/expedicao';
import ProntasParaEnvio from '@Views/colaborador/logistica/prontas-para-envio';
import Shipping from '@Views/colaborador/logistica/shipping';
import ShippingRoutes from '@Views/colaborador/logistica/routes';
import ShippingFollowUp from '@Views/colaborador/logistica/follow-up';
import ShippingPrint from '@Views/colaborador/logistica/print';

import ClinicaGTM from '@Views/clinica/gtm';
import ClinicaPaciente from '@Views/clinica/paciente';
import ClinicaMolecula from '@Views/clinica/molecula';
import ClinicaPrincipioAtivo from '@Views/clinica/principio-ativo';
import ClinicaDoseMaxima from '@Views/clinica/dose-maxima';

import RelatorioAlteracoes from '@Views/relatorios/alteracoes';
import RelatorioEstoquePaciente from '@Views/relatorios/estoque';
import RelatorioComercial from '@Views/relatorios/comercial';
import RelatorioComercialCR from '@Views/relatorios/comercialCR';
import RelatorioChurn from '@Views/relatorios/churn';
import RelatorioVendaSpot from '@Views/relatorios/vendaSpot';
import RelatorioComercialParceiros from '@Views/relatorios/comercialParceiros';
import RelatorioMetricasClinica from '@Views/relatorios/metricasClinica';
import RelatorioFaturamento from '@Views/relatorios/faturamentoR';
import RelatorioFornecedores from '@Views/relatorios/fornecedores';
import RelatorioFunil from '@Views/relatorios/funil';
import RelatorioProdutividade from '@Views/relatorios/produtividadeBox';
import Invoices from '@Views/colaborador/invoices';
import Invoice from '@Views/colaborador/invoices/invoice';

import { url_minha_box } from '@Config/url-minha-box';

export const routes = [
	{
		path: '/',
		name: 'Esqueleto',
		component: Esqueleto,
		meta: { auth: true },
		children: [
			{
				path: '/perfil',
				name: 'Perfil',
				component: Perfil
			}
		]
	},
	{
		path: '/colaborador',
		name: 'EsqueletoColaborador',
		component: EsqueletoColaborador,
		meta: { auth: true, colaborador: true },
		children: [
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: Dashboard
			},
			{
				path: 'pedidos',
				name: 'Orders',
				component: Orders
			},
			{
				path: 'pedidos/:id',
				name: 'Order',
				component: Order
			},
			{
				path: 'assinaturas',
				name: 'Assinaturas',
				component: AssinaturasColaborador
			},
			{
				path: 'assinaturas/:id',
				name: 'Assinatura',
				component: AssinaturaColaborador
			},
			{
				path: 'orcamentos/:id?',
				name: 'Orcamentos',
				component: Orcamentos
			},
			{
				path: 'orcamentosB2C/:id?',
				name: 'orcamentosB2C',
				component: OrcamentosB2C
			},
			{
				path: 'calendario',
				name: 'Calendario',
				component: Calendario
			},
			{
				path: 'orcamentos-externos/:id?',
				name: 'OrcamentosExternos',
				component: OrcamentosExternos
			},
			{
				path: 'pessoas',
				name: 'Pacientes',
				component: Pacientes
			},
			{
				path: 'pessoas/:id',
				name: 'Paciente',
				component: Paciente
			},
			{
				path: 'responsaveis',
				name: 'Responsaveis',
				component: Responsaveis
			},
			{
				path: 'responsavel/:id',
				name: 'Responsavel',
				component: Responsavel
			},
			{
				path: 'medicamentos',
				name: 'Medicamentos',
				component: MedicamentosColaborador
			},
			{
				path: 'produtos',
				name: 'produtos',
				component: ProductsView
			},
			{
				path: 'casarepouso',
				name: 'CasaRepouso',
				component: CasaRepousoColaborador
			},
			{
				path: 'aiimages',
				name: 'AiImages',
				component: AiImages
			},
			{
				path: 'rastreabilidade',
				name: 'Rastreabilidade',
				component: Rastreabilidade
			},
			{
				path: 'configuracoes/usuarios',
				name: 'ConfiguracoesUsuarios',
				component: ConfiguracoesUsuarios
			},
			{
				path: 'configuracoes/calendario',
				name: 'ConfiguracoesCalendario',
				component: ConfiguracoesCalendario
			},
			{
				path: 'configuracoes/tag',
				name: 'ConfiguracoesTag',
				component: ConfiguracoesTag
			},
			{
				path: 'farmaceutico',
				name: 'Farmaceutico',
				component: Farmaceutico
			},
			{
				path: 'compra',
				name: 'Compra',
				component: Compra
			},
			{
				path: 'atendimento',
				name: 'Atendimento',
				component: Atendimento
			},
			{
				path: 'cobranca',
				name: 'Cobranca',
				component: Cobranca
			},
			{
				path: 'picking',
				name: 'Picking',
				component: Picking
			},
			{
				path: 'faturamento',
				name: 'Faturamento',
				component: Faturamento
			},
			{
				path: 'pre-producao',
				name: 'PreProducao',
				component: PreProducao
			},
			{
				path: 'producao',
				name: 'Producao',
				component: Producao
			},
			{
				path: 'produzir',
				name: 'Produzir',
				component: Produzir
			},
			{
				path: 'conferencia',
				name: 'Conferencia',
				component: Conferencia
			},
			{
				path: 'expedicao',
				name: 'Expedicao',
				component: Expedicao
			},
			{
				path: 'prontas-para-envio',
				name: 'ProntasParaEnvio',
				component: ProntasParaEnvio
			},
			{
				path: 'expedicao-base-entregas',
				name: 'Shipping',
				component: Shipping
			},
			{
				path: 'expedicao-rotas',
				name: 'ShippingRoutes',
				component: ShippingRoutes
			},
			{
				path: 'expedicao-acompanhamento',
				name: 'ShippingFollowUp',
				component: ShippingFollowUp
			},
			{
				path: 'impressao',
				name: 'ShippingPrint',
				component: ShippingPrint
			},
			{
				path: 'faturas',
				name: 'invoices',
				component: Invoices
			},
			{
				path: 'faturas/:id',
				name: 'invoice',
				component: Invoice
			}
		]
	},
	{
		path: '/administrador', // Mantive esta rota apenas para os emails antigos não pararem de funcionar
		component: EsqueletoColaborador,
		meta: { auth: true, colaborador: true },
		children: [
			{
				path: 'orcamentos/:id?',
				name: 'OrcamentosColaborador',
				component: Orcamentos
			}
		]
	},
	{
		path: '/clinica',
		name: 'EsqueletoClinicaColaborador',
		component: EsqueletoColaborador,
		meta: { auth: true, colaborador: true },
		children: [
			{
				path: 'gtm',
				name: 'ClinicaGTM',
				component: ClinicaGTM
			},
			{
				path: 'molecula',
				name: 'ClinicaMolecula',
				component: ClinicaMolecula
			},
			{
				path: 'pacientes',
				name: 'ClinicaPacientes',
				component: Pacientes
			},
			{
				path: 'pacientes/:id',
				name: 'ClinicaPaciente',
				component: ClinicaPaciente
			},
			{
				path: 'principioativo',
				name: 'ClinicaPrincipioAtivo',
				component: ClinicaPrincipioAtivo
			},
			{
				path: 'dosemaxima',
				name: 'ClinicaDoseMaxima',
				component: ClinicaDoseMaxima
			}
		]
	},
	{
		path: '/relatorios',
		name: 'EsqueletoRelatoriosColaborador',
		component: EsqueletoColaborador,
		meta: { auth: true, colaborador: true },
		children: [
			{
				path: 'alteracoes',
				name: 'RelatorioAlteracoes',
				component: RelatorioAlteracoes
			},
			{
				path: 'estoque',
				name: 'RelatorioEstoquePaciente',
				component: RelatorioEstoquePaciente
			},
			{
				path: 'comercial',
				name: 'RelatorioComercial',
				component: RelatorioComercial
			},
			{
				path: 'comercialCR',
				name: 'RelatorioComercialCR',
				component: RelatorioComercialCR

			},
			{
				path: 'comercialParceiros',
				name: 'RelatorioComercialParceiros',
				component: RelatorioComercialParceiros
			},
			{
				path: 'vendaSpot',
				name: 'RelatorioVendaSpot',
				component: RelatorioVendaSpot
			},
			{
				path: 'faturamentoR',
				name: 'RelatorioFaturamento',
				component: RelatorioFaturamento

			},
			{
				path: 'metricasClinica',
				name: 'RelatorioMetricasClinica',
				component: RelatorioMetricasClinica
			},
			{
				path: 'churn',
				name: 'RelatorioChurn',
				component: RelatorioChurn
			},
			{
				path: 'produtividade',
				name: 'RelatorioProdutividade',
				component: RelatorioProdutividade
			},
			{
				path: 'fornecedores',
				name: 'RelatorioFornecedores',
				component: RelatorioFornecedores
			},
			{
				path: 'funil',
				name: 'RelatorioFunil',
				component: RelatorioFunil
			}
		]
	},
	{
		path: '/',
		name: 'Login',
		component: Login,
		children: [
			{
				path: '/entrar',
				name: 'Entrar',
				component: Entrar
			},
			{
				path: '/cadastrar',
				name: 'Cadastrar',
				component: Cadastrar
			},
			{
				path: '/recuperar-senha/:email/:token',
				name: 'RecuperarSenha',
				component: RecuperarSenha
			},
			{
				path: '/verificacao-email/:email/:token',
				name: 'VerificacaoEmail',
				component: VerificacaoEmail
			},
			{
				path: '/criar-senha/:email/:token',
				name: 'CriarSenha',
				component: CriarSenha
			}
		]
	},
	{
		path: '/qrcode/:qrcode',
		name: 'MedicamentosQrcode',
		beforeEnter: to => {
			const queryParams = Object.entries(to.query).reduce((a, b) => `${a}${b[0]}=${b[1]}`, '?');
			const queryParamsToForward = queryParams === '?' ? '' : queryParams;
			window.location.href = `https://${url_minha_box[localStorage.getItem('env')] || url_minha_box.Prod}/${to.params.qrcode}${queryParamsToForward}`;
		},
		meta: { skipHandleNext: true }
	}
];
