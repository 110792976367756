<template>
	<v-flex xs12 md12>
		<v-btn depressed class="styleButtons mr-2" large :style="tabSelected==='boxesTab' ? '' : 'opacity: 70%'" :color="tabSelected==='boxesTab' ? '#fff' : '#FFE2B6'"
			@click="() => switchTab('boxesTab')" >
			<v-icon small class="mr-2" color="#000">fa-sharp fa-regular fa-file-signature</v-icon>
			<span class="textTabs">Assinaturas</span> <span class="ml-4 lenghtItems">{{boxesLength}}</span>
		</v-btn>
		<v-btn depressed class="styleButtons" large :style="tabSelected!=='ordersTab' ? 'opacity: 70%' : ''" :color="tabSelected==='ordersTab' ? '#fff': '#FFE2B6' "
			@click="() => switchTab('ordersTab')" >
			<v-icon small class="mr-2" color="#000">shopping_cart</v-icon>
			<span class="textTabs">Far.me Pontual</span> <span class="ml-4 lenghtItems">{{ordersLength}}</span>
		</v-btn>
	</v-flex>
</template>

<script>
export default {
	name: 'TabsDataTable',
	props: {
		boxesLength: {
			type: Number,
			default: 0
		},
		ordersLength: {
			type: Number,
			default: 0
		},
		tabSelected: {
			type: String
		}
	},
	methods: {
		switchTab(tab) {
			this.$emit('switchTab', tab);
		}
	}
};
</script>

<style lang="scss" scoped>
 .v-btn {
	margin: 0;
	}

	.styleButtons {
		border-top-left-radius: 10px !important;
		border-top-right-radius: 10px !important;
	}

	.textTabs {
		font-size: 14px;
		font-family: Inter;
		color: #000
	}

 .lenghtItems {
	background-color: #342b1d;
	color: #fff;
	padding: 2px 6px;
	border-radius: 5px;
	font-size: 13px;
 }
</style>
