<template>
	<!-- class="purple-title" -->
	<v-toolbar app flat class="purple-title" height="70px">
		<img :src="logo" width="120px" alt="Farme Assinatura" />
		<v-toolbar-side-icon dark v-if="$vuetify.breakpoint.smAndDown" @click.stop="handleDrawer"></v-toolbar-side-icon>
		<span v-if="$vuetify.breakpoint.mdAndUp">
			<v-btn flat v-for="(item, index) in menu" :key="index" :to="item.path">
				{{ item.nome }}
			</v-btn>
		</span>
		<span v-if="$vuetify.breakpoint.mdAndUp">
			<v-btn v-if="$store.getters.eh_colaborador" flat @click="irParaColaborador()">
				<v-icon small class="mr-2">star</v-icon>
				Colaborador
			</v-btn>
			<v-btn v-if="$store.getters.eh_vendedor" flat @click="irParaOrcamentos()">
				<v-icon small class="mr-2">star</v-icon>
				{{ $vuetify.breakpoint.lgAndUp ? 'Área do Vendedor' : 'Vendedor' }}
			</v-btn>
			<v-btn flat @click="sair" class="mr-0">
				<v-icon small class="mr-2">exit_to_app</v-icon> Sair
			</v-btn>
		</span>
	</v-toolbar>
</template>

<script>
import logo from '@Assets/images/FarmeOS.svg';
import Mixin from './mixin';
import menu from './menu';

export default {
	name: 'Navbar',
	mixins: [Mixin],
	data: () => ({
		logo,
		menu
	}),
	methods: {
		handleDrawer() {
			this.$root.$emit('nav-drawer');
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.v-toolbar {
  ::v-deep .v-toolbar__content {
    display: flex;
    justify-content: space-between !important;

    .v-btn {
      .v-btn__content {
        text-transform: uppercase;
      }
    }
  }
}
</style>
