<template>
	<v-card>
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>
					fas fa-user-tag
				</v-icon>
			</v-avatar>
			<h3>Tags</h3>
			<v-spacer />
			<v-btn small depressed class="green white--text" :disabled="loading" @click="dialog_tags = true">
				<v-icon class="mr-2">fas fa-pen</v-icon>
				Editar
			</v-btn>
		</v-card-title>
		<v-card-text class="pt-0">
			<div v-if="loading" class="loading">
				<v-progress-circular indeterminate color="primary" />
			</div>
			<template v-else>
				<span v-if="tags.length === 0">
					Nenhuma tag informada.
				</span>
				<v-chip v-else v-for="tag in tags" :key="tag.id" small class="purple white--text"
					disabled>
					{{ tag.nome }}
				</v-chip>
			</template>
		</v-card-text>

		<v-dialog v-model="dialog_tags" persistent scrollable transition="fade" max-width="840px">
			<transition>
				<DialogTags v-if="dialog_tags" :tags="tags"
					@novas-tags="onNovasTags" @close="dialog_tags = false" />
			</transition>
		</v-dialog>

	</v-card>
</template>

<script>
import { PacientePresenter } from '@Presenter/paciente-presenter';
import DialogTags from '@Componentes/dialog-tags';

export default {
	name: 'Tags',
	components: { DialogTags },
	props: {
		paciente: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		tags: [],
		loading: true,
		dialog_tags: false
	}),
	async created() {
		this.tags = await PacientePresenter.tags(this.paciente.id);
		this.loading = false;
	},
	methods: {
		onNovasTags(tags) {
			this.tags = tags;
			PacientePresenter.update({ ...this.paciente, tags }, false);
		}
	}
};
</script>

<style lang="scss" scoped>
.loading {
	min-height: 50px;
}
</style>
