<template>
	<v-card>
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="#342B1D" small>fas fa-pills</v-icon>
			</v-avatar>
			<h3>Produtos</h3>
		</v-card-title>
		<v-card-text>
			<OrderItemsItem v-for="(item, index) of items" :key="index" :item="item" class="mb-4"/>
		</v-card-text>
	</v-card>
</template>

<script>
import OrderItemsItem from './item.vue';

export default {
	name: 'OrderItems',
	components: {
		OrderItemsItem
	},
	props: {
		items: {
			type: Array,
			required: false,
			default: () => []
		}
	}
};
</script>

<style scoped>
h3 {
	color: rgba(0, 0, 0, 0.87);
	font-family: Inter;
	font-size: 13.38px;
	font-style: normal;
	font-weight: 700;
	line-height: 24.57px;
}
</style>
