import $http from '@Config/axios';
import $store from '@Store';
import { buildQueryParams } from 'luiz-fns';
import { geraPdfEtiquetasAntigas } from '@Util/pdf-etiquetas-antigas';
import { geraPdfEtiquetaForaDaBox } from '@Util/pdf-etiqueta-fora-da-box';
import { geraExcelProducao } from '@Util/excel-producao';
import { DefaultPresenter } from './default-presenter';

const prefix = 'box';
const entity = 'Box';
const PREFIX_V2 = '/v2/boxes';
const PREFIX_BOX_PRODUCTION = `${PREFIX_V2}/production`;
const PREFIX_PRE_PRODUCTION = `${PREFIX_V2}/pre-production`;

/**
 *
 * @param {Number} boxId
 *
 * @typedef {Object} GetBoxResetInfoMedicineItem
 * @property {Number} id
 * @property {String} name
 * @property {Number} quantity
 *
 * @typedef {Object} GetBoxResetInfoResponse
 * @property {Number} boxId
 * @property {Boolean} wasBilled
 * @property {Array<GetBoxResetInfoMedicineItem>} medicinesToBeMantained
 * @property {Array<GetBoxResetInfoMedicineItem>} medicinesToBeRemoved
 *
 * @returns {Promise<GetBoxResetInfoResponse>}
 */
async function getBoxResetInfo(boxId) {
	const response = await $http.get(`${PREFIX_V2}/${boxId}/reset`);
	return {
		boxId: response.id,
		wasBilled: response.was_billed,
		medicinesToBeRemoved: response.medicines_to_be_removed.map(i => ({ id: i.id, name: i.name, quantity: Number(i.quantity) })),
		medicinesToBeMantained: response.medicines_to_be_mantained.map(i => ({ id: i.id, name: i.name, quantity: Number(i.quantity) }))
	};
}

/**
 * Reseta o processo da Box (v2)
 *
 * @param {Number} boxId
 * @param {{startDate: String, reason: String, keepMedicineIds: Array<Number>}} params
 * @returns {Promise<void>}
 */
async function resetBox(boxId, params) {
	await $http.post(
		`${PREFIX_V2}/${boxId}/reset`,
		{
			start_date: params.startDate,
			keep_medicine_ids: params.keepMedicineIds,
			reason: params.reason
		}
	);
}

/**
 * Lista das boxes pré-produção
 *
 * @typedef {Object} Filter
 * @property {String} view
 * @property {String} search
 * @property {String} startDate
 * @property {String} productionDate
 * @property {String} restHomeId
 * @property {Array} ignoreTagsIds
 *
 * @typedef {Object} Pagination
 * @property {Number} page
 * @property {Number} perPage

 * @typedef {Object} Sorting
 * @property {Number} field
 * @property {(DESC|ASC)} direction
 *
 * @typedef {Object} Params
 * @property {Filter} filter
 * @property {Pagination} pagination
 * @property {Sorting} sorting
 *
 * @typedef {Object} Response
 * @property {Array} items
 * @property {Number} total
 * @property {Number} totalDelay
 * @property {Number} totalToday
 * @property {Number} page
 * @property {Number} perPage

 * @param {Params} params
 * @returns {Promise<Response>}
 */
async function getBoxesToPreProduce(params = {}) {
	const query = {
		page: params.pagination.page,
		perPage: params.pagination.perPage,
		restHomeId: params.filter.restHomeId,
		startDate: params.filter.startDate,
		productionDate: params.filter.productionDate,
		view: params.filter.view,
		sortBy: params.sorting.field,
		sortDir: params.sorting.direction,
		q: params.filter.search,
		ignoreTagsIds: params.filter.ignoreTagsIds
	};
	const response = await $http.get(buildQueryParams(PREFIX_PRE_PRODUCTION, query));
	return response;
}

/**
 * @param {Number} boxId
 * @param {Boolean} printed
 * @returns {Promise<void>}
 */
async function markAsPrintedLabel(boxId, printed) {
	const response = await $http.put(`${PREFIX_PRE_PRODUCTION}/${boxId}/label/printed`, { printedLabel: printed });
	return response;
}

/**
 * @param {Number} boxId
 * @param {String} comment
 * @returns {Promise<void>}
 */
async function savePreProductionComment(boxId, comment) {
	const response = await $http.post(`${PREFIX_V2}/${boxId}/occurrences`, { occurrence: comment });
	return response;
}

/**
 * @param {Number} boxId
 * @returns {Promise<void>}
 */
async function finishPreProduction(boxId) {
	const response = await $http.post(`${PREFIX_PRE_PRODUCTION}/${boxId}/finish`);
	return response;
}

/**
 * Lista das boxes em produção (a serem produzidas e em produçã)
 *
 * @typedef {Object} Filter
 * @property {String} view
 * @property {String} search
 * @property {String} startDate
 * @property {String} productionDate
 * @property {Number} restHomeId
 * @property {Number} userProducingId
 *
 * @typedef {Object} Pagination
 * @property {Number} page
 * @property {Number} perPage

 * @typedef {Object} Sorting
 * @property {Number} field
 * @property {(DESC|ASC)} direction
 *
 * @typedef {Object} Params
 * @property {Filter} filter
 * @property {Pagination} pagination
 * @property {Sorting} sorting
 *
 * @typedef {Object} Response
 * @property {Array} items
 * @property {Number} total
 * @property {Number} totalDelay
 * @property {Number} totalToday
 * @property {Number} page
 * @property {Number} perPage

 * @param {Params} params
 * @returns {Promise<Response>}
 */
async function getBoxesToProduce(params = {}) {
	const query = {
		page: params.pagination.page,
		perPage: params.pagination.perPage,
		restHomeId: params.filter.restHomeId,
		userProducingId: params.filter.userProducingId,
		startDate: params.filter.startDate,
		productionDate: params.filter.productionDate,
		ignoreTagsIds: params.filter.ignoreTagsIds ? params.filter.ignoreTagsIds.join(',') : '',
		view: params.filter.view,
		sortBy: params.sorting.field,
		sortDir: params.sorting.direction,
		q: params.filter.search
	};
	const response = await $http.get(buildQueryParams(PREFIX_BOX_PRODUCTION, query));
	return response;
}

/**
 * Busca detalhes de uma Box para produção
 *
 * @param {Number|String} identifier
 * @param {object}
 * @returns
 */
async function getBoxToProduce(identifier) {
	const url = `${PREFIX_BOX_PRODUCTION}/${identifier}`;
	const response = await $http.get(url);
	return response;
}

/**
 * Inicia produção de uma Box
 *
 * @param {Number|String} identifier
 * @param {object}
 * @returns
 */
async function startBoxProduction(identifier) {
	const url = `${PREFIX_BOX_PRODUCTION}/${identifier}/start`;
	const startBoxProductionResponse = await $http.post(url);
	return startBoxProductionResponse;
}

/**
 * Finaliza produção de uma Box
 *
 * @param {Number|String} identifier
 * @param {String | null} observation
 * @param {object}
 * @returns
 */
async function finishBoxProduction(identifier, observation = null) {
	const url = `${PREFIX_BOX_PRODUCTION}/${identifier}/finish`;
	const finishBoxProductionResponse = await $http.post(url, { observation });
	return finishBoxProductionResponse;
}

/**
 * Busca a boxes em produção do usuário logado
 * @returns
 */
async function getCurrentBoxInProduce() {
	const url = `${PREFIX_BOX_PRODUCTION}/user`;
	const response = await $http.get(url);
	if (!response || !Array.isArray(response) || response.length === 0)
		return null;
	if (response.length > 1)
		throw new Error('Usuário com mais uma box em produção');
	const boxIdInProduction = await getBoxToProduce(response[0]);
	return boxIdInProduction;
}

/**
 * Busca os items da conferência do kit
 *
 * @param {Number} boxId
 * @typedef {Object} ConferenceKitItemResponse
 * @property {String} label
 * @property {String} description
 * @property {String} value
 * @returns {Promise<Array<ConferenceKitItemResponse>>}
 */
async function getConferenceKitItems(boxId) {
	const url = `${PREFIX_V2}/${boxId}/conference-kit`;
	const conferenceKitItemsResponse = await $http.get(url);
	return conferenceKitItemsResponse;
}

/**
 *
 * @param {Array<Number>} boxId
 * @param {Array<Number>} medicineIdsToIgnore
 * @param {Array<saquinho|rastreio|producao>} types
 * @returns
 */
async function generateAllLabels(boxIds = [], medicineIdsToIgnore = [], types = []) {
	const labels = await this.gerarEtiquetas({ ids: boxIds, meds_to_ignore: medicineIdsToIgnore });
	const promises = [];

	if (types.includes('saquinho'))
		promises.push(geraPdfEtiquetasAntigas(labels.etiquetasDoSaquinho));

	if (types.includes('rastreio'))
		promises.push(geraPdfEtiquetaForaDaBox(labels.etiquetasDeRastreio));

	if (types.includes('producao'))
		promises.push(geraExcelProducao(labels, undefined, this.boxId));

	return Promise.all(promises);
}

export const BoxPresenter = {
	...DefaultPresenter({ prefix, entity }),
	show(id) {
		return new Promise((resolve, reject) => {
			$http.get(`${prefix}/${id}`)
				.then(response => {
					response.medicamentos_nfe = JSON.parse(response.medicamentos_nfe || '[]');
					resolve(response);
				})
				.catch(response => reject(response));
		});
	},
	/**
	 * @param {Number} boxId
	 *
	 * @param {object} endereco
	 * @param {String} endereco.cep
	 * @param {String} endereco.logradouro
	 * @param {String|Number} endereco.numero
	 * @param {String} endereco.bairro
	 * @param {String|null} endereco.complemento
	 * @param {String|null} endereco.instrucoes_entrega
	 * @param {String} endereco.cidade
	 * @param {String} endereco.estado
	 * @param {String | null} endereco.longitude
	 * @param {String | null} endereco.longitude
	 * @returns
	 */
	atualizarEndereco: (boxId, endereco) => {
		const body = {
			cep: endereco.cep,
			logradouro: endereco.logradouro,
			numero: endereco.numero,
			bairro: endereco.bairro,
			cidade: endereco.cidade,
			estado: endereco.estado,
			complemento: endereco.complemento || null,
			longitude: endereco.longitude || null,
			latitude: endereco.latitude || null,
			instrucoes_entrega: endereco.instrucoes_entrega || null
		};

		return $http.put(`${prefix}/${boxId}/endereco`, body);
	},
	/**
	 * @param {Number} boxId
	 * @returns
	 */
	getBoxMedicines: boxId => $http.get(`${prefix}/${boxId}/box-medicamentos`),
	getAproveitamentoReceita: id => $http.get(`${prefix}/${id}/aproveitamentoReceitas`),
	updateAproveitamentoReceita: (id, params) => $http.post(`${prefix}/${id}/aproveitamentoReceitas`, params),
	updateObservacao: (box_id, observacao) => $http.put(`${prefix}/${box_id}/observacao`, { observacao }),
	ocorrencias: id => $http.get(`${prefix}/${id}/ocorrencias`),
	cobrancaProporcional: id => $http.get(`${prefix}/${id}/cobranca/proporcional`),
	cobrancaIntegral: id => $http.get(`${prefix}/${id}/cobranca/integral`),
	reset: params => $http.patch(`${prefix}/${params.id}`, params),
	index: (params = {}) => $http.get(buildQueryParams(`${prefix}`, { ...params, visao_colaborador: $store.getters.visao_colaborador ? 1 : 0 })),
	gerarEtiquetas: params => $http.get(buildQueryParams(`${prefix}/etiquetas`, params)),
	count: () => $http.get(`${prefix}/count`),
	fetchCobrancas: params => $http.get(`${prefix}/fetchCobrancas`, params),
	fetchCompras: params => $http.get(`${prefix}/fetchCompras`, params),
	fetchFarmaceutico: params => $http.get(`${prefix}/fetchFarmaceutico`, params),
	fetchAtendimento: params => $http.get(`${prefix}/fetchAtendimento`, params),
	receitas: box_id => $http.get(`${prefix}/${box_id}/receitas`),
	medicamentos: box_id => $http.get(`${prefix}/${box_id}/medicamentos`),
	listaDeCompras: ({ ids }) => $http.get(buildQueryParams(`${prefix}/listaDeCompras`, { ids })),
	gerarPdfPicking: ({ ids }) => $http.get(buildQueryParams(`${prefix}/gerarPdfPicking`, { ids })),
	gerarPdfOrdemDeProducao: box_id => $http.get(buildQueryParams(`${prefix}/${box_id}/gerarPdfOrdemDeProducao`)),
	gerarPdfPickingEOrdemDeProducao: ({ ids }) => $http.get(buildQueryParams(`${prefix}/gerarPdfPickingEOrdemDeProducao`, { ids })),
	getBoxTraceability: boxId => $http.get(`${prefix}/${boxId}/rastreabilidade`),
	getBoxToProduce,
	startBoxProduction,
	finishBoxProduction,
	getCurrentBoxInProduce,
	getBoxesToProduce,
	getBoxesToPreProduce,
	markAsPrintedLabel,
	savePreProductionComment,
	finishPreProduction,
	generateAllLabels,
	getConferenceKitItems,
	getBoxResetInfo,
	resetBox
};
