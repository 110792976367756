<template>
	<v-container fluid grid-list-md class="pa-0">
		<v-layout wrap>
			<v-flex xs12 md6>
				<v-text-field box hide-details label="Valor do serviço" v-model="orcamento.valor_servico" v-money/>
			</v-flex>
			<v-flex xs12 md6>
				<TextDateField v-model="orcamento.data_validade" box hideDetails label="Validade do Orçamento" :disabled="eh_vendedor"
					placeholder="dd/mm/yyyy"/>
			</v-flex>
			<v-flex xs12>
				<v-autocomplete v-model="orcamento.vendedor_id" box hide-details label="Vendedor"
					item-text="nome" item-value="id" :items="vendedores" :loading="loading_vendedores"/>
			</v-flex>
			<v-flex xs6>
				<v-text-field box hide-details label="Porcentagem desconto primeira box" v-model="desconto_primeira_box"
					type="number"
					suffix="%"
					:rules ="!eh_vendedor ? rules : rulesVendedor"/>
			</v-flex>
			<v-flex v-if="!eh_vendedor" xs12>
				<v-switch v-model="orcamento.sem_box" hide-details label="Sem box"/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { ConfiguracaoPresenter } from '@Presenter/configuracao-presenter';
import { UserPresenter } from '@Presenter/user-presenter';
import moment from 'moment';
import mixin from './mixin';

export default {
	name: 'DadosOrcamento',
	mixins: [mixin],
	data: () => ({
		vendedores: [],
		loading_vendedores: true,
		pct_desconto: 0,
		rules: [
			v => v >= 0 || 'desconto deve ser maior que 0%',
			v => v <= 100 || 'desconto deve ser menor que 100%'
		],
		rulesVendedor: [
			v => v >= 0 || 'desconto deve ser maior que 0%',
			v => v <= 50 || 'desconto deve ser menor ou igual a 50%'
		]
	}),
	computed: {
		eh_vendedor() {
			return this.$store.getters.eh_vendedor;
		},
		desconto_primeira_box: {
			get() {
				return (this.pct_desconto / 100).toFixed(2);
			},
			set(newValue) {
				newValue = newValue ? newValue.replace('.', '').replace(',', '') : '0';
				this.pct_desconto = parseInt(newValue, 10);
				this.orcamento.pct_desconto_primeira_box = this.pct_desconto;
			}
		}
	},
	created() {
		this.pct_desconto = this.orcamento.pct_desconto_primeira_box;
		ConfiguracaoPresenter.show()
			.then(({ valor_servico }) => {
				if (!this.orcamento.id)
					this.orcamento.valor_servico = valor_servico || 0;
			});
		UserPresenter.vendedores()
			.then(vendedores => {
				this.vendedores = vendedores;
				if (!this.orcamento.vendedor_id)
					this.orcamento.vendedor_id = this.$store.getters.contexto.user.id;
				if (this.eh_vendedor) {
					this.orcamento.valor_servico = 0;
					if (!this.orcamento.id)
						this.orcamento.data_validade = moment().add(3, 'days').format('DD/MM/YYYY');
				}
			})
			.finally(() => {
				this.loading_vendedores = false;
			});
	}
};
</script>

<style lang="scss" scoped>
::v-deep.v-input--switch {
	margin: 0;
	padding-top: 8px;
	justify-content: flex-end;
}
</style>
