<template>
	<v-card class="usuario">
		<v-card-title class="green-title" />

		<v-card-text class="conteudo">
			<v-layout align-center justify-start>

				<v-flex class="flex-content pr-1" style="margin-left: -50px;">
					<v-avatar size="70">
						<span class="white--text headline">{{ usuario.nome.charAt(0) }}</span>
					</v-avatar>
				</v-flex>

				<v-flex style="margin-right: 50px; padding-right: 50px">
					<h3 class="mb-1">{{ usuario.nome }}</h3>
					<p v-if="usuario.telefone" class="mb-0 grey--text text--darken-2">
						<v-icon small class="mr-1">phone</v-icon>
						{{ usuario.telefone | telefone }}
					</p>
					<p v-if="usuario.email" class="mb-0 grey--text text--darken-2">
						<v-icon small class="mr-1">mail</v-icon>
						{{ usuario.email }}
					</p>
				</v-flex>
				<v-flex class="flex-column actions-absolute">
					<v-tooltip left v-if="usuario.permissao >= 10 && usuario.permissao < 99">
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" color="#DAA520" style="font-size: 16px">fas fa-star</v-icon>
						</template>
						<span>Colaborador</span>
					</v-tooltip>
				</v-flex>

				<v-flex class="flex-column actions-absolute">
					<v-tooltip left v-if="usuario.permissao >= 1 && usuario.permissao < 5">
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" color="purple" style="font-size: 16px">fas fa-user</v-icon>
						</template>
						<span>Usuário comum</span>
					</v-tooltip>
				</v-flex>

			</v-layout>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'UsuarioCard',
	props: {
		usuario: {
			type: Object,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
.usuario {
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 10px 100px rgba(#000, 0.14) !important;
  }

  .green-title {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    background-position-y: bottom;
    filter: brightness(120%);
    position: relative;
    z-index: 0;
  }

  .conteudo.v-card__text {
    background: #fff;
    border-radius: 10px 0 0 10px;
    margin-left: 50px;
    position: relative;
    z-index: 1;

    .flex-column {
      display: flex;
      flex-direction: column;
    }

    .flex-content {
      max-width: max-content;

      .v-avatar {
        overflow: hidden;

        img {
          width: 101%;
          height: 101%;
        }
      }
    }

    .actions-absolute {
      position: absolute;
      right: 50px;
    }

    h3 {
      font-size: 16px;
    }
  }
}
</style>
