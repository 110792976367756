<template>
	<div class="compra-wrapper px-md-5">

		<HeaderProcesso
			filtro_dias
			v-model="dias"
			titulo="Compra"
			subtitulo="Veja as assinaturas que estão em fase de compra e gere a lista de compras."
			subtitulo2="Aqui estão listadas as assinaturas que ainda não possuem box, as que a última box já foi entregue ou as que o Atendimento já iniciou o atendimento."
			btn_label="Gerar lista de compra"
			btn_label_extra="Gerar lista de compra em lote"
			:btn_disabled="selecionados.length === 0"
			:btn_on_click="() => { gerarListaCompra('excel') }"
			:btn_on_click_extra="() => { gerarListaCompraEmLote() }"
			:btn_disabled_extra="false"
			:btn_loading="gerando"
			:btn_loading_extra="gerando"
			@busca="val => busca = val"
			:show_casa_filter="true"
			:exibirFiltroSuspensos="true"
			:filtrarPorSuspensos="exibirSuspensos"
			@filtrarPorSuspensos="val => exibirSuspensos = val"
			@casas-repouso-updated="onCasaRepousoIds" />

		<!-- Conteúdo -->
		<DataTableProcessoPagination
			selectable
			:loading="carregando"
			:exibirSuspensos="exibirSuspensos"
			:search="busca"
			:days='dias'
			:extra_headers="extra_headers"
			:buttons="buttons"
			:sort_by="sortBy"
			:descending="descending"
			:fetch_data="getDataPagination"
			ref="dataTablePagination"
			:rows_per_page="[15, 30, 45, {text: 'Todos', value: -1}]"
			@selected-updated="arr => selecionados = arr" />

		<!-- Dialog Informações Cliente -->
		<v-dialog persistent scrollable v-model="dialog_informacoes_cliente" transition="fade" max-width="820px">
			<transition>
				<DialogInformacoesBox v-if="dialog_informacoes_cliente" :assinatura_id="item_selecionado.assinatura_id" :close.sync="dialog_informacoes_cliente" />
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import DialogInformacoesBox from '@Componentes/dialog-informacoes-box';
import HeaderProcesso from '@Componentes/header-processo';
import DataTableProcessoPagination from '@Componentes/data-table-processo-pagination';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { ComprasPresenter } from '@Presenter/compras-presenter';

export default {
	name: 'Compra',
	components: { HeaderProcesso, DialogInformacoesBox, DataTableProcessoPagination },
	data: () => ({
		dialog_informacoes_cliente: false,
		carregando: false,
		busca: '',
		dias: '18',
		selecionados: [],
		gerando: false,
		item_selecionado: null,
		sortBy: 'inicio',
		descending: true,
		casaRepousoIds: [],
		exibirSuspensos: false
	}),
	computed: {
		isCompra() {
			return this.$route.name === 'Compra';
		},
		isAtendimento() {
			return this.$route.name === 'Atendimento';
		}
	},
	created() {
		this.extra_headers = [
			{
				text: 'Status',
				value: 'e_comprada',
				format: comprada => {
					if (comprada)
						return 'Comprada';
					return '-';
				},
				sortable: true
			}
		];
		this.buttons = [
			{
				label: 'Informações do cliente',
				icon: 'fas fa-prescription-bottle-alt',
				onClick: item => this.abrirDialog(item, 'dialog_informacoes_cliente')
			}
		];
	},
	methods: {

		async getDataFromAPI(pagination, search, days, casaRepousoIds, exibirSuspensos) {
			this.carregando = true;

			const params = {
				...pagination, search, days, casa_repouso_ids: casaRepousoIds, exibir_suspensos: exibirSuspensos
			};

			const resp = {};
			await ComprasPresenter.fetchCompras({ params })
				.then(response => {
					resp.items = response.items;
					resp.total = response.total;
				})
				.finally(() => {
					this.carregando = false;
				});
			return resp;
		},

		async getDataPagination() {
			const pagination = this.$refs.dataTablePagination.pagination;
			pagination.order = pagination.descending ? 'desc' : 'asc';

			const resp = await this.getDataFromAPI(pagination, this.busca, this.dias, this.casaRepousoIds, this.exibirSuspensos);
			const items = resp.items;
			const total = resp.total;
			this.$refs.dataTablePagination.updatePage(items, total);
		},

		gerarListaCompra(tipo) {
			return new Promise(async (resolve, reject) => {
				this.gerando = true;
				try {
					const ids = this.selecionados.map(a => a.box_id);
					const promises = [AssinaturaPresenter.listaDeCompras({ ids, compra: this.isCompra })];
					if (tipo === 'pdf')
						promises.push(import('@Util/pdf-lista-compra'));
					else
						promises.push(import('@Util/excel-lista-compra'));
					const responses = await Promise.all(promises);
					const { geraListaDeCompras } = responses[1];
					geraListaDeCompras(responses[0]);
					if (this.isCompra)
						this.getDataPagination();
				} catch (error) {
					reject();
				}
				this.gerando = false;
				resolve();
			});
		},

		gerarListaCompraEmLote() {
			return new Promise(async (resolve, reject) => {
				this.gerando = true;
				try {
					const params = {
						search: this.busca, days: this.dias, casa_repouso_ids: this.casaRepousoIds, exibir_suspensos: this.exibirSuspensos
					};
					const promises = [ComprasPresenter.listaDeCompras({ params })];
					promises.push(import('@Util/excel-lista-compra'));
					const responses = await Promise.all(promises);
					const { geraListaDeCompras } = responses[1];
					geraListaDeCompras(responses[0]);
				} catch (error) {
					reject();
				}
				this.gerando = false;
				resolve();
			});
		},

		onCasaRepousoIds(casasRepouso) {
			this.casaRepousoIds = casasRepouso;
			this.getDataPagination();
		},

		abrirDialog(item, dialog_name) {
			this.item_selecionado = item;
			this[dialog_name] = true;
		}

	}
};
</script>
