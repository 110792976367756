import $http from '@Config/axios';
import $store from '@Store';
import { buildQueryParams } from 'luiz-fns';
import { onlyNumber, FormatDateToRequest } from '@Util/functions';
import { cloneDeep } from 'lodash';
import { DefaultPresenter } from './default-presenter';

const prefix = 'paciente';
const entity = 'Paciente';

function sanitize(pac) {
	const paciente = cloneDeep(pac);
	paciente.nome = paciente.nome ? paciente.nome : null;
	paciente.email = paciente.email ? paciente.email : '';
	paciente.telefone = paciente.telefone ? onlyNumber(paciente.telefone) : null;
	paciente.cpf = paciente.cpf ? onlyNumber(paciente.cpf) : null;
	paciente.nascimento = paciente.nascimento ? FormatDateToRequest(paciente.nascimento) : null;
	paciente.responsavel = paciente.responsavel ? sanitize(paciente.responsavel) : null;
	Object.keys(paciente).forEach(key => paciente[key] == null && delete paciente[key]);
	return paciente;
}

export const PacientePresenter = {
	...DefaultPresenter({ prefix, entity }),
	list: () => $http.get(prefix),
	count: () => $http.get(`${prefix}/count`),
	salvar(paciente) {
		return new Promise((resolve, reject) => {
			(paciente.id ? this.update : this.store)(paciente)
				.then(response => resolve(response))
				.catch(response => reject(response));
		});
	},
	store(paciente) {
		return new Promise((resolve, reject) => {
			$http.post(prefix, sanitize(paciente))
				.then(response => {
					$store.dispatch('SHOW_SNACKBAR', { message: 'Paciente salvo com sucesso!' });
					resolve(response);
				})
				.catch(response => reject(response));
		});
	},
	update(paciente, show_message = true) {
		return new Promise((resolve, reject) => {
			$http.put(`${prefix}/${paciente.id}`, sanitize(paciente))
				.then(response => {
					if (show_message)
						$store.dispatch('SHOW_SNACKBAR', { message: 'Paciente salvo com sucesso!' });
					resolve(response);
				})
				.catch(response => reject(response));
		});
	},
	estoque(paciente_id, query) {
		return new Promise((resolve, reject) => {
			$http.get(buildQueryParams(`${prefix}/${paciente_id}/estoque`, query))
				.then(response => {
					const estoque = Object.keys(response.estoque).map(key => {
						const id = Number(key);
						return {
							id,
							produto: response.medicamentos[key].produto,
							qtd_comprimidos: response.estoque[key].qtd_comprimidos,
							qtd_caixas: response.estoque[key].qtd_caixas
						};
					});
					resolve(estoque);
				})
				.catch(response => reject(response));
		});
	},
	stockWithBatchAndExpirationDate: (patient_id, query) => $http.get(buildQueryParams(`${prefix}/${patient_id}/stock-with-batch-and-expiration`, query)),
	aniversariantes: (mes = new Date().getMonth() + 1) => $http.get(`${prefix}/aniversariantes?mes=${mes}`),
	historicoEstoque: paciente_id => $http.get(`${prefix}/${paciente_id}/historicoEstoque`),
	ultimaAssinatura: paciente_id => $http.get(`${prefix}/${paciente_id}/ultimaAssinatura`),
	alergias: paciente_id => $http.get(`${prefix}/${paciente_id}/alergias`),
	tags: paciente_id => $http.get(`${prefix}/${paciente_id}/tags`),
	arquivos: (paciente_id, query) => $http.get(buildQueryParams(`${prefix}/${paciente_id}/arquivos`, query)),
	resetPassword: data => $http.post(`${prefix}/reset-password`, data)
};
