import { isBoxInProcess } from '@Util/functions';

export const lastBox = state => state.historico_boxes[0] || {};

export const lastBoxIsOpen = (state, getters) => isBoxInProcess(getters.lastBox.status);

export const hintDiasAntecedenciaEntrega = state => {
	if (!state.assinatura.paciente.casa_repouso)
		return null;
	return `${state.assinatura.paciente.casa_repouso.nome} possui ${state.assinatura.paciente.casa_repouso.dias_antecedencia_entrega} dias de antecedência`;
};

export const subscription = state => state.assinatura;
