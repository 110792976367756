<template>
	<Dialog
		titulo="Histórico de alterações"
		:subtitulo="`Orçamento: ${quotationId}`"
		@close="$emit('update:close', false)"
		:padding="false">
		<v-container fluid grid-list-md class="pa-0">
			<v-data-table
				:headers="headers"
				:loading="loading"
				:no-data-text="loading ? 'Carregando' : 'Nenhum item encontrado'"
				no-results-text="Nenhum item encontrado"
				:items="history"
				:expand="true"
				item-key="id">
				<template v-slot:items="props">
					<tr >
						<td>
							<template v-if="props.item.user">
								{{ props.item.user.name }} <br> <small>{{ props.item.user.email }}</small>
							</template>
							<template v-else>
								-
							</template>
						</td>
						<td class="pa-0">{{ getAuditDescription(props.item.event, props.item.resource_name) }}</td>
						<td class="pa-0"> {{ formattedDate(props.item.created_at) }}</td>
						<td class="pa-0">
							<v-btn  @click="props.expanded = !props.expanded" small flat>+ Detalhes</v-btn>
						</td>
					</tr>
				</template>
				<template v-slot:expand="props">
					<v-card
						flat
						color="rgba(217, 217, 217, 0.4)"
						class="pa-4 mx-4 mt-2 mb-4"
						style="border: 2px solid rgba(217, 217, 217, 1)">
						<ToFrom :to="props.item.after" :from="props.item.before" :diff="props.item.diff"/>
					</v-card>
				</template>
			</v-data-table>
		</v-container>
	</Dialog>
</template>

<script>
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';
import { getAuditLabelByEvent } from '@Consts/audit';
import ToFrom from './to-from.vue';

export default {
	name: 'QuotationUpdateHistoryDialog',
	components: {
		ToFrom
	},
	props: {
		quotationId: {
			required: false,
			type: Number
		}
	},
	async created() {
		await this.getUpdateHistory();
	},
	data() {
		return {
			loading: false,
			history: []
		};
	},
	computed: {
		headers() {
			return [
				{
					text: 'Usuário',
					value: 'user',
					align: 'left',
					sortable: false
				},
				{
					text: 'Descrição',
					value: 'event',
					width: '250px',
					align: 'left',
					sortable: false
				},
				{
					text: 'Realizado em',
					value: 'created_at',
					width: '180px',
					sortable: false
				},
				{
					text: 'Ações', value: '', width: '10px', sortable: false
				}
			];
		}
	},
	methods: {
		getAuditDescription(event, resourceName) {
			const resourceNameMap = {
				Orcamento: 'Orçamento',
				OrcamentoMedicamento: 'Medicamento'
			};

			return `${getAuditLabelByEvent(event)} ${resourceNameMap[resourceName]}`;
		},
		async getUpdateHistory() {
			this.loading = true;
			this.history = [];
			try {
				this.history = await OrcamentoPresenter.getUpdateHistory(this.quotationId);
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: error.message });
			} finally {
				this.loading = false;
			}
		},
		formattedDate(date) {
			const options = {
				year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
			};
			return date
				? new Date(date).toLocaleString('pt-BR', options)
				: 'Data não disponível';
		}
	}
};
</script>
