import { render, staticRenderFns } from "./aproveitamento-receita.vue?vue&type=template&id=740688c1&scoped=true"
import script from "./aproveitamento-receita.vue?vue&type=script&lang=js"
export * from "./aproveitamento-receita.vue?vue&type=script&lang=js"
import style0 from "./aproveitamento-receita.vue?vue&type=style&index=0&id=740688c1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "740688c1",
  null
  
)

export default component.exports