import { permissoes } from '@Consts/permissoes';
import lodash from 'lodash';
import moment from 'moment';
import { normalizeString } from 'luiz-fns';
import { allQuotationStatus } from '@Consts/quotation-v2';
import store from '../store';

export const xmlToJson = xml => {
	// Create the return object
	let obj = {};

	if (xml.nodeType === 1) { // element
		// do attributes
		if (xml.attributes.length > 0) {
			obj['@attributes'] = {};
			for (let j = 0; j < xml.attributes.length; j += 1) {
				const attribute = xml.attributes.item(j);
				obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
			}
		}
	} else if (xml.nodeType === 3) // text
		obj = xml.nodeValue;

	// do children
	// If just one text node inside
	if (xml.hasChildNodes() && xml.childNodes.length === 1 && xml.childNodes[0].nodeType === 3)
		obj = xml.childNodes[0].nodeValue;
	else if (xml.hasChildNodes()) {
		for (let i = 0; i < xml.childNodes.length; i += 1) {
			const item = xml.childNodes.item(i);
			const { nodeName } = item;
			if (typeof (obj[nodeName]) === 'undefined')
				obj[nodeName] = xmlToJson(item);
			else {
				if (typeof (obj[nodeName].push) === 'undefined') {
					const old = obj[nodeName];
					obj[nodeName] = [];
					obj[nodeName].push(old);
				}
				obj[nodeName].push(xmlToJson(item));
			}
		}
	}

	return obj;
};

export const qntComprimidos = qnt => {
	if (!qnt)
		return '';
	switch (qnt) {
		case 0.5: return 'Meio comprimido';
		case 1: return '1 comprimido';
		case 1.5: return '1 comprimido e meio';
		case 2.5: return '2 comprimidos e meio';
		case 3.5: return '3 comprimidos e meio';
		case 4.5: return '4 comprimidos e meio';
		case 5.5: return '5 comprimidos e meio';
		case 6.5: return '6 comprimidos e meio';
		case 7.5: return '7 comprimidos e meio';
		case 0.333: return '1/3 de comprimido';
		case 0.666: return '2/3 de comprimido';
		case 0.75: return '3/4 de comprimido';
		case 0.25: return '1/4 de comprimido';
		default: return `${qnt} comprimidos`;
	}
};

export const isVendedor = permissao => permissao === permissoes.Vendedor;

export const isColaborador = permissao => permissao >= permissoes.Operador;

export const isAdministrador = permissao => permissao === permissoes.Administrador;

export const isOperador = permissao => permissao === permissoes.Operador;

export const isFinanceiro = permissao => permissao === permissoes.Financeiro;

export const isClinica = permissao => permissao === permissoes.Clinica;

export const isRT = permissao => permissao === permissoes.RT;

export const isFarmeos = permissao => permissao === permissoes.Farmeos;

export const getPermissao = user => (user || {}).permissao;

export const getPermissaoLabel = permissao => Object.entries(permissoes).find(entry => entry[1] === permissao)[0];

export const userIsVendedor = user => isVendedor(getPermissao(user));

export const userIsColaborador = user => isColaborador(getPermissao(user));

export const userIsAdministrador = user => isAdministrador(getPermissao(user));

export const userIsOperador = user => isOperador(getPermissao(user));

export const userIsFinanceiro = user => isFinanceiro(getPermissao(user));

export const userIsRT = user => isRT(getPermissao(user));

export const userIsClinica = user => isClinica(getPermissao(user));

export const userIsFarmeos = user => isFarmeos(getPermissao(user));

/*
  normalize('NFD') separa acentos das letras
  replace(/[\u0300-\u036f]/g, '') remove acentos da string [^a-zA-Z -]
  */
export const clearQuery = normalizeString;

export const tiposResponsaveis = {
	faturamento: {
		icone: 'receipt_long',
		descricao: 'Responsável pela nota fiscal'
	},
	financeiro: {
		icone: 'monetization_on',
		descricao: 'Responsável pelo pagamento'
	},
	clinico: {
		icone: 'health_and_safety',
		descricao: 'Responsável por alterações'
	}
};

export const Logout = () => {
	store.dispatch('CLEAR_STATE');
	localStorage.clear();
};

export const onlyNumber = str => {
	str = String(str);
	return str && str.replace(/\D/g, '');
};

export const FormatDateFromRequest = date => {
	if (!date)
		return null;
	const data = date.split(' ');
	const [year, month, day] = data[0].split('-');
	if (data.length > 1)
		return `${day}/${month}/${year} ${data[1]}`;
	return `${day}/${month}/${year}`;
};

export const validateOrderIdFormat = value => /^(\d{6})-(\w{6})-(\w{4})$/.test(value);

export const FormatDateToRequest = date => {
	if (!date || date.length !== 10)
		return null;
	const [day, month, year] = date.split('/');
	return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const RandomString = size => {
	let text = '';
	const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';

	for (let i = 0; i < size; i += 1)
		text += possible.charAt(Math.floor(Math.random() * possible.length));

	return text;
};

export const getHorario = hor => {
	let str;
	if (['Após o café da manhã', 'Antes do almoço', 'Ao deitar', 'Após o almoço', 'Após jantar', 'Antes do jantar'].includes(hor.horario))
		str = `${qntComprimidos(hor.quantidade_por_horario)} ${hor.horario.toLowerCase()}`;
	else if (['Jejum', 'Manhã', 'Noite', 'Tarde'].includes(hor.horario))
		str = `${qntComprimidos(hor.quantidade_por_horario)} de ${hor.horario.toLowerCase()}`;
	else
		str = `${qntComprimidos(hor.quantidade_por_horario)} às ${hor.horario}`;
	return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const nameQuantidade = qtd => {
	switch (qtd) {
		case 0.5: return '1/2';
		case 1.5: return '1 + 1/2';
		case 2.5: return '2 + 1/2';
		case 3.5: return '3 + 1/2';
		case 4.5: return '4 + 1/2';
		case 5.5: return '5 + 1/2';
		case 6.5: return '6 + 1/2';
		case 7.5: return '7 + 1/2';
		case 0.333: return '1/3';
		case 0.666: return '2/3';
		case 0.75: return '3/4';
		case 0.25: return '1/4';
		default: return qtd || '';
	}
};

export const isB2CQuotation = value => value === 'b2c';

const filterQuotationStatus = statusList => statusList.reduce((obj, status) => ({ ...obj, [status]: allQuotationStatus[status] }), {});

export const statusOrcamento = () => filterQuotationStatus(['UNDER_ANALYSIS', 'UNDER_REVISION', 'APPROVED', 'DONE', 'EXPIRED', 'CANCELED']);
export const statusOrcamentoB2C = () => ({ ...filterQuotationStatus(['DRAFT', 'SUBMITTED', 'CREATED', 'PAID', 'DONE', 'EXPIRED', 'CANCELED']) });

export const getStatusOrcamento = status => allQuotationStatus[status] || { label: '' };

export const mascaraDinheiro = (centavos = 0) => Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(centavos / 100);

export const mascaraPorcentagem = (percentual = 0) => Intl.NumberFormat('pt-br', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(percentual / 100);

export const getOrdemProducao = (num_columns, horarios) => {
	const main_body = [];
	let line = [];

	const getWidthConfig = () => {
		const arr = [];
		for (let i = 0; i < num_columns; i += 1)
			arr.push('*');
		return arr;
	};

	const rowMedicamentos = _medicamentos => {
		const medicamentos = [];
		_medicamentos.forEach(medicamento => {
			let nome_medicamento = `${nameQuantidade(medicamento.qtd)} ${medicamento.produto} ${medicamento.dosagem || ''}${medicamento.fora_da_box ? ' (fora da box)' : ''}`;
			if (medicamento.periodo)
				nome_medicamento += ` - ${medicamento.periodo}`;
			if (medicamento.mpp)
				nome_medicamento += ' *';

			medicamentos.push([{ text: nome_medicamento, fontSize: 8, color: (medicamento.mpp ? 'red' : null) }]);
		});
		return medicamentos;
	};

	horarios.forEach(({
		horario, medicamentos, fillColor, color
	}) => {
		const str_horario = horario.toUpperCase();
		const cell = [
			[{
				text: str_horario, alignment: 'center', bold: true, fontSize: horario.length > 10 ? 10 : 14, fillColor, color
			}],
			...rowMedicamentos(medicamentos)
		];

		line.push({
			table: {
				widths: ['*'],
				body: cell
			}
		});
		if (line.length === num_columns) {
			main_body.push(line);
			line = [];
		}
	});

	if (line.length > 0) {
		const n_iterations = num_columns - line.length;
		for (let i = 0; i < n_iterations; i += 1)
			line.push([]);
		main_body.push(line);
	}

	return		{
		layout: 'noBorders',
		margin: [0, 15, 0, 0],
		table: {
			dontBreakRows: true,
			widths: getWidthConfig(),
			heights: 80,
			body: main_body
		}
	};
};

export const mascaraCpf = cpf => {
	if (!cpf || cpf.length !== 11)
		return cpf;
	return `${cpf.substr(0, 3)}.${cpf.substr(3, 3)}.${cpf.substr(6, 3)}-${cpf.substr(9, 2)}`;
};

export const hyphensToCamel = (string = '') => `${string.charAt(0).toUpperCase() + lodash.camelCase(string.slice(1) || '')}`;

export const isBoxInProcess = status => (status
	&& ['WAITING', 'PURCHASED', 'SERVICE_STARTED', 'SERVICE_ENDED', 'DELIVERED'].every(s => s !== status));

export const isBoxBeforePicking = status => ['WAITING', 'PURCHASED', 'SERVICE_STARTED', 'SERVICE_ENDED'].includes(status);

export const formatDate = (date, formato) => {
	if (!date)
		return '-';
	if (typeof date === 'number')
		return moment(date).format(formato);
	if (date.includes('/'))
		return moment(String(date), 'DD/MM/YYYY').format(formato);
	return moment(String(date)).format(formato);
};

export const copyToClipboard = (text, textName = 'Link') => {
	if (navigator.clipboard && window.isSecureContext) {
		navigator.clipboard.writeText(text);
		store.dispatch('SHOW_SNACKBAR', { message: `${textName} copiado para a área de transferência` });
	} else {
		const textArea = document.createElement('textarea');
		textArea.value = text;

		textArea.style.position = 'fixed';
		textArea.style.left = '-999999px';
		textArea.style.top = '-999999px';
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		return new Promise((res, rej) => {
			// eslint-disable-next-line no-unused-expressions
			document.execCommand('copy') ? res() : rej();
			textArea.remove();
		});
	}
};

export const downloadBuffer = (buffer, fileName) => {
	const arr = new Uint8Array(buffer);
	const blob = new Blob([arr], { type: 'application/pdf' });
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	reader.onloadend = () => {
		const base64data = reader.result;
		const a = document.createElement('a');
		a.href = base64data;
		a.download = fileName;
		a.click();
	};
};

export const openBuffer = buffer => {
	const arr = new Uint8Array(buffer);
	const blob = new Blob([arr], { type: 'application/pdf' });
	const newWindow = window.open('', '_blank');
	newWindow.location = URL.createObjectURL(blob);
};

export const isDomicilio = casaRepousoName => {
	const nameSanitized = casaRepousoName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	return nameSanitized.trim().split(' ').includes('domicilio');
};
