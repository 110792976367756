import $http from '@Config/axios';

const prefix = 'grupos-de-produtos';

export const ProductGroupPresenter = {
	findAll: () => $http.get(`${prefix}`),
	create: params => {
		const body = {
			name: params.name
		};

		return $http.post(prefix, body);
	}
};
