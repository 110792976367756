<template>
	<Dialog titulo="POLÍTICA DE PRIVACIDADE E SEGURANÇA DA FAR.ME" btn_acao="Fechar" @acao="$emit('update:close', false)"
		@close="$emit('update:close', false)">

		<!-- Arquivo de word convertido usando: https://wordhtml.com/ -->
		<p><strong>SUM&Aacute;RIO</strong></p>
		<ol>
			<li><strong> Propriedade e Apresenta&ccedil;&atilde;o</strong></li>
			<li><strong> Objeto e Defini&ccedil;&otilde;es</strong></li>
			<li><strong> Comunica&ccedil;&otilde;es e Contato Far.me </strong></li>
			<li><strong> Coleta, Utiliza&ccedil;&atilde;o e Guarda de Dados</strong></li>
			<li><strong> Compartilhamento e Divulga&ccedil;&atilde;o dos Dados do USU&Aacute;RIO</strong></li>
			<li><strong> Medidas de Seguran&ccedil;a</strong></li>
			<li><strong> Direitos das Pessoas Sobre os Dados Coletados</strong></li>
			<li><strong> Pol&iacute;tica para Menores de Dezoito Anos</strong></li>
		</ol>
		<p>&nbsp;</p>
		<ol>
			<li><strong> Propriedade e Apresenta&ccedil;&atilde;o</strong></li>
		</ol>
		<p><span style="font-weight: 400;">Este </span><strong>SITE</strong><span style="font-weight: 400;">
			&eacute; de propriedade exclusiva da </span><strong>FAR.ME FARMACOTERAPIA OTIMIZADA LTDA</strong><span
			style="font-weight: 400;">, ao longo deste documento designada t&atilde;o somente como
		</span><strong>Far.me</strong><span style="font-weight: 400;">, tendo a
			manuten&ccedil;&atilde;o e opera&ccedil;&atilde;o estabelecida como atividade exercida
			exclusivamente por ela, da qual a sede se encontra no endere&ccedil;o Av. Cristiano
			Machado, n&ordm; 640, loja 10 Pavimento 1, bairro Sagrada Fam&iacute;lia, Munic&iacute;pio
			de Belo Horizonte, Estado de Minas Gerais, CEP 31.030-514, inscrita no Cadastro Nacional de
			Pessoas Jur&iacute;dicas do Minist&eacute;rio da Fazenda sob o n&ordm; 25.228.539/0001-23 e
			correio eletr&ocirc;nico contato@farme.com.br.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			&eacute; uma farm&aacute;cia que, por meio dessa plataforma digital, tem o objetivo de
			simplificar a rotina de pessoas que necessitam tomar m&uacute;ltiplos medicamentos por dia.
			A </span><strong>Far.me</strong><span style="font-weight: 400;">&nbsp;fornece os
			medicamentos organizados de acordo com o hor&aacute;rio e dia da prescri&ccedil;&atilde;o
			m&eacute;dica e proporciona um suporte farmac&ecirc;utico focado no uso mais seguro e
			efetivo do tratamento medicamentoso.</span></p>
		<p>&nbsp;</p>
		<ol start="2">
			<li><strong> Objeto e Defini&ccedil;&otilde;es</strong></li>
		</ol>
		<p><span style="font-weight: 400;">O presente documento &eacute; acess&oacute;rio, parte
			integrante e insepar&aacute;vel dos <a @click="$emit('abrir-termos-uso')">Termos de Uso</a>
			da </span><strong>Far.me </strong><span style="font-weight: 400;">
			e tem por objeto estabelecer a Pol&iacute;tica de Privacidade e Seguran&ccedil;a das
			funcionalidades do site &ldquo;farme.com&rdquo;, adiante designado como </span><span style="font-weight: 400;">&ldquo;</span><strong>SITE</strong><span
			style="font-weight: 400;">&rdquo;</span><span style="font-weight: 400;"> e dos
			servi&ccedil;os oferecidos pela </span><strong>Far.me</strong><span style="font-weight: 400;">,
			conforme defini&ccedil;&otilde;es a seguir.</span></p>
		<p><span style="font-weight: 400;">Esse documento descreve a Pol&iacute;tica de Privacidade e
			Seguran&ccedil;a que deve ser observada pela </span><strong>Far.me</strong><span style="font-weight: 400;">,
			detalhando as formas de coleta, guarda, utiliza&ccedil;&atilde;o, compartilhamento e
			divulga&ccedil;&atilde;o dos Dados pessoais das pessoas que acessarem e utilizarem o </span><strong>SITE</strong><span
			style="font-weight: 400;">, adiante designadas como </span><span style="font-weight: 400;">&ldquo;</span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;">&rdquo;</span><span style="font-weight: 400;">, no singular, ou,
		</span><strong>&ldquo;USU&Aacute;RIOS&rdquo;</strong><span style="font-weight: 400;">, no
			plural, detalhando ainda da forma mais clara poss&iacute;vel as medidas que s&atilde;o
			tomadas para assegurar a prote&ccedil;&atilde;o dos Dados coletados pela </span><strong>Far.me</strong><span
			style="font-weight: 400;">.</span></p>
		<p><strong>&ldquo;Contato&rdquo;</strong><span style="font-weight: 400;"> &eacute; a forma de
			contato com a </span><strong>Far.me</strong><span style="font-weight: 400;">,
			funcionalidade pela qual o </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			poder&aacute; entrar em contato atrav&eacute;s dos canais de comunica&ccedil;&atilde;o
			disponibilizados, de acordo com a solicita&ccedil;&atilde;o que necessita para sanar
			eventuais problemas e d&uacute;vidas oriundas do </span><strong>SITE</strong><span style="font-weight: 400;">.</span></p>
		<p><strong>&ldquo;Conta de Acesso&rdquo;</strong><span style="font-weight: 400;"> &eacute; a
			credencial definida pelo nome de usu&aacute;rio &ldquo;login&rdquo; e senha de um </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> cadastrado, pessoal e intransfer&iacute;vel, que permite acesso
			&agrave; &aacute;rea restrita e &agrave;s funcionalidades e benef&iacute;cios exclusivos do
		</span><strong>SITE</strong><span style="font-weight: 400;">, tais como a
			utiliza&ccedil;&atilde;o dos servi&ccedil;os de compra, organiza&ccedil;&atilde;o e entrega
			dos medicamentos de forma personalizada de acordo com os dados disponibilizados na conta;
			inserir ou alterar dados de pagamento; dentre outras. </span></p>
		<p><strong> &ldquo;Propriedade Intelectual&rdquo;</strong><span style="font-weight: 400;"> do
		</span><strong>Far.me </strong><span style="font-weight: 400;">&nbsp;s&atilde;o todos os bens
			de propriedade intelectual de sua titularidade ou de terceiro que tenha uso licenciado e/ou
			autorizado, tais como patentes, marcas, inven&ccedil;&otilde;es ou modelos de utilidade,
			desenhos industriais, know-how, segredos de neg&oacute;cio, ilustra&ccedil;&otilde;es,
			fotografias e/ou conte&uacute;dos de qualquer tela do </span><strong>SITE</strong><span
			style="font-weight: 400;"> ou quaisquer obras intelectuais ou outros conte&uacute;dos que
			estejam inseridos por qualquer obra intelectual ou qualquer bem protegido por direito de
			propriedade intelectual. </span></p>
		<p><span style="font-weight: 400;">Fazem parte desses </span><strong>TERMOS</strong><span style="font-weight: 400;">
			os demais documentos acess&oacute;rios espec&iacute;ficos das ferramentas do </span><strong>SITE</strong><span
			style="font-weight: 400;">, tais como os <a @click="$emit('abrir-termos-uso')">Termos de
			Uso</a> </span><span style="font-weight: 400;"> e as
			Condi&ccedil;&otilde;es Particulares.</span></p>
		<p>&nbsp;</p>
		<ol start="3">
			<li><strong> Comunica&ccedil;&otilde;es e Contato Far.me </strong></li>
		</ol>
		<p><span style="font-weight: 400;">O </span><strong>Contato</strong><span style="font-weight: 400;">
			&eacute; a forma de comunica&ccedil;&atilde;o que o </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> possui para falar com a </span><strong>Far.me</strong><span
			style="font-weight: 400;">, que pode ser contatada atrav&eacute;s dos seguintes canais de
			comunica&ccedil;&atilde;o:</span></p>
		<ul style="list-style-type:upper-roman">
			<li><span style="font-weight: 400;"> Espa&ccedil;o para Contato no site www.farme.com.br;</span></li>
			<li><span style="font-weight: 400;"> Do telefone (31) 3566-6526;</span></li>
			<li><span style="font-weight: 400;">E-mail contato@farme.com.br;</span></li>
			<li><span style="font-weight: 400;"> Correspond&ecirc;ncia endere&ccedil;ada &agrave; </span><strong>Far.me</strong><span
				style="font-weight: 400;">, conforme endere&ccedil;o informado no item 1;</span></li>
		</ul>
		<p><span style="font-weight: 400;">Em caso de realiza&ccedil;&atilde;o de contato, por qualquer
			meio, com a </span><strong>Far.me</strong><span style="font-weight: 400;">, as
			informa&ccedil;&otilde;es do </span><strong>USU&Aacute;RIOS</strong><span style="font-weight: 400;">
			dever&atilde;o estar explicitadas obrigatoriamente para possibilitar a resposta e
			finaliza&ccedil;&atilde;o do atendimento.</span></p>
		<p><span style="font-weight: 400;">Em todos os casos o </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> receber&aacute; atrav&eacute;s do mesmo canal de
			comunica&ccedil;&atilde;o uma confirma&ccedil;&atilde;o imediata do recebimento da sua
			demanda, que ser&aacute; tratada e respondida pela </span><strong>Far.me</strong><span
			style="font-weight: 400;"> em um prazo m&aacute;ximo de 5 (cinco) dias &uacute;teis ou
			dentro do prazo que se fizer necess&aacute;rio para a an&aacute;lise espec&iacute;fica do
			caso.</span></p>
		<p><span style="font-weight: 400;">Da mesma forma, todas as comunica&ccedil;&otilde;es que a
		</span><strong>Far.me</strong><span style="font-weight: 400;"> desejar fazer ao </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> ser&atilde;o realizadas de forma segura, conforme cadastro e
			informa&ccedil;&otilde;es prestadas pelo </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			perante a </span><strong>Far.me</strong><span style="font-weight: 400;">.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			tamb&eacute;m se comunicar&aacute; com o </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			por outros meios de comunica&ccedil;&atilde;o, como telefone celular, SMS ou
			correspond&ecirc;ncia f&iacute;sica, quando houver necessidade.</span></p>
		<p>&nbsp;</p>
		<ol start="4">
			<li><strong> Coleta, Utiliza&ccedil;&atilde;o e Guarda de Dados</strong></li>
		</ol>
		<p><span style="font-weight: 400;">A </span><strong>Far.me </strong><span style="font-weight: 400;">se
			compromete a respeitar os Dados do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">,
			a Privacidade e a Seguran&ccedil;a dos </span><strong>USU&Aacute;RIOS</strong><span style="font-weight: 400;">,
			sempre tomando cautelosamente as medidas necess&aacute;rias para garantir a
			prote&ccedil;&atilde;o de seus </span><strong>USU&Aacute;RIOS</strong><span style="font-weight: 400;">
			nos momentos em que estiver utilizando o </span><strong>SITE</strong><span style="font-weight: 400;">.</span></p>
		<p><span style="font-weight: 400;">Para a total utiliza&ccedil;&atilde;o do</span><strong> SITE</strong><span
			style="font-weight: 400;"> &eacute; necess&aacute;ria a cria&ccedil;&atilde;o da </span><strong>Conta
			de Acesso</strong><span style="font-weight: 400;"> com o registro de Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;">.</span></p>
		<p><span style="font-weight: 400;">Os Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> solicitados nos formul&aacute;rios do</span><strong> SITE</strong><span
			style="font-weight: 400;">&nbsp;(nome completo, e-mail, data de nascimento, telefone, CPF,
			endere&ccedil;o e lista de medicamentos de uso cont&iacute;nuo com suas respectivas
			posologias, mais anexo da prescri&ccedil;&atilde;o) servem para gest&atilde;o,
			navega&ccedil;&atilde;o e consulta do pr&oacute;prio </span><strong>SITE</strong><span
			style="font-weight: 400;">.</span></p>
		<p><strong>A utiliza&ccedil;&atilde;o do SITE implica no consentimento do USU&Aacute;RIO para
			coleta, utiliza&ccedil;&atilde;o e guarda das informa&ccedil;&otilde;es fornecidas e suas
			atualiza&ccedil;&otilde;es, Dados de tr&aacute;fego, endere&ccedil;os IP, dentre outros
			necess&aacute;rios e informados na navega&ccedil;&atilde;o do USU&Aacute;RIO.</strong></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me </strong><span style="font-weight: 400;">poder&aacute;
			receber e registrar automaticamente em seus servidores informa&ccedil;&otilde;es do
			navegador ou dispositivo do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">,
			incluindo o endere&ccedil;o IP, atributos de software e hardware, identificadores de
			dispositivos m&oacute;veis, informa&ccedil;&atilde;o sobre uso de aplicativo, e outras
			informa&ccedil;&otilde;es de dispositivo e sistema. Isso pode acontecer no</span><strong>
			SITE</strong> <span style="font-weight: 400;">ou em servi&ccedil;os de terceiros.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me </strong><span style="font-weight: 400;">se
			reserva ao direito de bloquear o acesso ao </span><strong>SITE</strong><span style="font-weight: 400;">
			de </span><strong>USU&Aacute;RIOS</strong><span style="font-weight: 400;"> alocados em
			alguns pa&iacute;ses, identificados de acordo com o IP utilizado.</span></p>
		<p><span style="font-weight: 400;">O </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			autoriza a </span><strong>Far.me </strong><span style="font-weight: 400;">a, a seu
			crit&eacute;rio, preservar, armazenar todos os conte&uacute;dos submetidos ao </span><strong>SITE</strong><span
			style="font-weight: 400;"> bem como todos os Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> e demais informa&ccedil;&otilde;es fornecidas e/ou coletadas,
			para a efetiva realiza&ccedil;&atilde;o dos servi&ccedil;os propostos pela </span><strong>Far.me</strong><span
			style="font-weight: 400;"> e contratados pelo </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;">.</span></p>
		<p><span style="font-weight: 400;">O </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			reconhece e concorda expressamente que a base de Dados poder&aacute; ser total ou
			parcialmente cedida ou transferida a terceiros, desde que respeitada finalidade
			&uacute;nica e exclusiva de dar continuidade &agrave; atividade prestada pela </span><strong>Far.me
		</strong><span style="font-weight: 400;">ou se existir uma obrigatoriedade legal nesse
			sentido.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me </strong><span style="font-weight: 400;">n&atilde;o
			processa pagamentos diretamente e n&atilde;o armazena informa&ccedil;&otilde;es de
			cart&otilde;es de cr&eacute;dito dos </span><strong>USU&Aacute;RIOS</strong><span style="font-weight: 400;">,
			que s&atilde;o realizados atrav&eacute;s da IUGU, plataforma de gest&atilde;o e
			automa&ccedil;&atilde;o de pagamentos online.</span></p>
		<p><span style="font-weight: 400;">Os Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> s&atilde;o armazenados em servidores pr&oacute;prios com
			prote&ccedil;&atilde;o SSL (criptografia) para obedecer &agrave; legisla&ccedil;&atilde;o
			p&aacute;tria aplic&aacute;vel e para melhorar a qualidade dos servi&ccedil;os da </span><strong>Far.me.
		</strong><span style="font-weight: 400;">Caso o </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> requeira a exclus&atilde;o de seus Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> da base de Dados, a </span><strong>Far.me </strong><span style="font-weight: 400;">se
			reserva ao direito de manter os referidos Dados em c&oacute;pias de salvaguarda por 06
			(seis) meses, a fim de cumprir obriga&ccedil;&otilde;es legais de guarda
			obrigat&oacute;ria, ou, em outro prazo em caso de altera&ccedil;&atilde;o da
			legisla&ccedil;&atilde;o p&aacute;tria.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me </strong><span style="font-weight: 400;">respeita
			a privacidade de seus </span><strong>USU&Aacute;RIOS</strong><span style="font-weight: 400;">
			e n&atilde;o envia e-mails indesejados e n&atilde;o solicitados aos mesmos.</span></p>
		<p><span style="font-weight: 400;">Quando o </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			se cadastra no</span><strong> SITE</strong><span style="font-weight: 400;"> e cria uma
		</span><strong>Conta de Acesso</strong><span style="font-weight: 400;">, permite o envio de
			e-mails com mensagens publicit&aacute;rias e promocionais sobre as funcionalidades do</span><strong>
			SITE</strong><span style="font-weight: 400;"> e/ou de seus Parceiros, produtos,
			servi&ccedil;os, promo&ccedil;&otilde;es e/ou novidades, passando a receb&ecirc;-los,
			entretanto, sempre que um </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			decida que n&atilde;o pretende mais receb&ecirc;-los, dever&aacute; mencionar tal fato
			&agrave; </span><strong>Far.me </strong><span style="font-weight: 400;">, no momento em que
			recebe o e-mail no campo de descadastro existente nos e-mails enviados pela </span><strong>Far.me
		</strong><span style="font-weight: 400;">&nbsp;ou, posteriormente, atrav&eacute;s de qualquer
			um dos canais disponibilizados no <a href="https://www.farme.com" title="Far.me" target="_blank">item
				3</a> </span><span style="font-weight: 400;"> deste documento. Quando a
		</span><strong>Far.me </strong><span style="font-weight: 400;">for cientificada dessa
			decis&atilde;o do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;"> de
			n&atilde;o mais receber os e-mails, envidar&aacute; de meios para o cumprimento dessa
			solicita&ccedil;&atilde;o, deixando de programar o envio de novos e-mails em at&eacute;
			cinco dias, n&atilde;o se responsabilizando apenas pelos e-mails que j&aacute; estavam
			programados para serem enviados antes da solicita&ccedil;&atilde;o e que n&atilde;o puderem
			ser cancelados.</span></p>
		<p><span style="font-weight: 400;">A utiliza&ccedil;&atilde;o do</span><strong> SITE</strong><span
			style="font-weight: 400;"> implica no consentimento do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> para coleta, armazenamento e uso das informa&ccedil;&otilde;es
			fornecidas por ele e suas atualiza&ccedil;&otilde;es, Dados de tr&aacute;fego,
			endere&ccedil;os IP, dentre outros para a manuten&ccedil;&atilde;o e devido funcionamento
			do </span><strong>SITE</strong><span style="font-weight: 400;">. O </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> desde j&aacute; autoriza a </span><strong>Far.me </strong><span
			style="font-weight: 400;">a, a seu crit&eacute;rio, preservar, armazenar todos os
			conte&uacute;dos submetidos ao </span><strong>SITE</strong><span style="font-weight: 400;">,
			bem como todos os seus Dados do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			e demais informa&ccedil;&otilde;es fornecidas e/ou coletadas.</span></p>
		<p><span style="font-weight: 400;">Os Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> poder&atilde;o ser compartilhados com terceiros contratados pela
		</span><strong>Far.me </strong><span style="font-weight: 400;">nos termos desta
			Pol&iacute;tica, ressaltando-se que a cria&ccedil;&atilde;o de cada </span><strong>Conta de
			Acesso</strong><span style="font-weight: 400;"> ou qualquer cadastro no</span><strong> SITE</strong><span
			style="font-weight: 400;">, para o qual seja necess&aacute;rio o envio de qualquer dado
			pessoal do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">,
			pressup&otilde;e o consentimento expresso quanto &agrave; coleta, uso, armazenamento e
			tratamento de Dados do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">.</span></p>
		<p><span style="font-weight: 400;">O </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			&eacute; respons&aacute;vel, nas esferas civil e criminal, pela veracidade e
			atualiza&ccedil;&atilde;o dos Dados fornecidos </span><span style="font-weight: 400;">e a
		</span><strong>Far.me </strong><span style="font-weight: 400;">&nbsp;se exime de qualquer
			responsabilidade por danos decorrentes do preenchimento incompleto, impreciso ou inexato do
			cadastro pelo </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">, sob
			qualquer meio ou forma, ou, ainda, pelo uso desse cadastro de forma indevida por qualquer
			terceiro n&atilde;o autorizado a usar tais Dados ou, ainda, por terceiros que tenham,
			devida ou &nbsp;indevidamente, obtido os Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> para acesso no</span><strong> SITE</strong><span style="font-weight: 400;">,
			agindo como se ele fosse. </span></p>
		<p><span style="font-weight: 400;">Com inten&ccedil;&atilde;o de oferecer a melhor
			experi&ecirc;ncia de navega&ccedil;&atilde;o e buscas dentro do</span><strong> SITE</strong><span
			style="font-weight: 400;"> ao </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">,
			a </span><strong>Far.me </strong><span style="font-weight: 400;">utiliza de tecnologias
			para coletar e armazenar informa&ccedil;&otilde;es relacionadas &agrave; visita do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> no</span><strong> SITE</strong><span style="font-weight: 400;">
			e isso pode incluir o envio de um ou mais cookies ou identificadores an&ocirc;nimos que
			coletam Dados relativos &agrave;s prefer&ecirc;ncias de navega&ccedil;&atilde;o e &agrave;s
			p&aacute;ginas visitadas pelo </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">.
			Desta forma, a apresenta&ccedil;&atilde;o do </span><strong>SITE</strong><span style="font-weight: 400;">
			fica personalizada e alinhada de acordo com os interesses pessoais e necessidades do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;">. A utiliza&ccedil;&atilde;o desses Dados fica restrita ao
			objetivo indicado e a </span><strong>Far.me </strong><span style="font-weight: 400;">se
			compromete a n&atilde;o utilizar ou permitir a utiliza&ccedil;&atilde;o de tais Dados com
			outra finalidade. Importante destacar que a coleta, guarda e tratamento dos Dados &eacute;
			absolutamente automatizada, n&atilde;o havendo nenhuma possibilidade de contato humano com
			os Dados em quest&atilde;o, n&atilde;o havendo exposi&ccedil;&atilde;o dos </span><strong>USU&Aacute;RIOS</strong><span
			style="font-weight: 400;">. O </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			pode, a qualquer tempo, em caso de discord&acirc;ncia da pol&iacute;tica de cookies,
			utilizar as ferramentas de seu navegador que impedem a instala&ccedil;&atilde;o de cookies
			e ainda apagar quaisquer cookies existentes em seus dispositivos de conex&atilde;o com a
			internet. Neste caso, algumas funcionalidades do</span><strong> SITE</strong><span style="font-weight: 400;">
			poder&atilde;o apresentar erros.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me </strong><span style="font-weight: 400;">poder&aacute;
			ainda utilizar-se de outras tecnologias para a coleta de Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;">, comprometendo-se a guard&aacute;-los, trat&aacute;-los e
			utiliz&aacute;-los em conformidade com essa Pol&iacute;tica. </span></p>
		<p><span style="font-weight: 400;">Conforme disp&otilde;e a legisla&ccedil;&atilde;o
			p&aacute;tria vigente, a fim de coibir ao m&aacute;ximo o anonimato, a </span><strong>Far.me
		</strong><span style="font-weight: 400;">manter&aacute; em sua base de Dados todas as
			informa&ccedil;&otilde;es relativas aos acessos do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> ao </span><strong>SITE</strong><span style="font-weight: 400;">,
			incluindo, mas n&atilde;o se limitando ao endere&ccedil;o IP, &agrave;s p&aacute;ginas
			acessadas, aos hor&aacute;rios e datas de acesso, e o dispositivo de acesso utilizado.
			Esses registros poder&atilde;o ser utilizados em investiga&ccedil;&otilde;es internas ou
			p&uacute;blicas para averigua&ccedil;&atilde;o de pr&aacute;ticas que possam gerar
			quaisquer preju&iacute;zos &agrave; </span><strong>Far.me</strong><span style="font-weight: 400;">,
			a outros </span><strong>USU&Aacute;RIOS</strong><span style="font-weight: 400;"> e a
			terceiros, inclusive a pr&aacute;tica de crimes em ambientes virtuais.</span></p>
		<p><span style="font-weight: 400;">Excepcionalmente a </span><strong>Far.me</strong><span style="font-weight: 400;">
			poder&aacute; coletar Dados do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">,
			que n&atilde;o os expressamente identificados aqui, que sejam inseridos pelo </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> e sobre o </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			voluntariamente no processo de navega&ccedil;&atilde;o do </span><strong>SITE</strong><span
			style="font-weight: 400;"> ou quando entrar em contato com o </span><strong>Contato</strong><span
			style="font-weight: 400;">.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			poder&aacute; acessar bases de Dados p&uacute;blicas ou privadas para confirmar a
			veracidade dos Dados do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			informados pelo mesmo.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			utilizar&aacute; o e-mail do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			prioritariamente para enviar informa&ccedil;&otilde;es necess&aacute;rias sobre a
			cria&ccedil;&atilde;o de </span><strong>Conta de Acesso</strong><span style="font-weight: 400;">,
			Informa&ccedil;&otilde;es sobre os procedimentos agendados, intera&ccedil;&otilde;es
			realizadas dentro do </span><strong>SITE</strong><span style="font-weight: 400;">,
			recupera&ccedil;&atilde;o da senha de acesso &agrave;s &aacute;reas restritas e quaisquer
			outros autorizados e solicitados.</span></p>
		<p><span style="font-weight: 400;">O e-mail cadastrado tamb&eacute;m ser&aacute; utilizado para
			outras situa&ccedil;&otilde;es, desde que autorizadas e solicitadas pelo </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;">.</span></p>
		<p><span style="font-weight: 400;">Al&eacute;m das formas expostas acima, a </span><strong>Far.me</strong><span
			style="font-weight: 400;"> poder&aacute;, a seu exclusivo crit&eacute;rio, utilizar os
			Dados do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;"> nas
			seguintes formas:</span></p>
		<ul style="list-style-type:upper-roman">
			<li><span style="font-weight: 400;"> atualiza&ccedil;&atilde;o de cadastro;</span></li>
			<li><span style="font-weight: 400;"> para prestar servi&ccedil;os ao </span><strong>USU&Aacute;RIO</strong><span
				style="font-weight: 400;">, incluindo gerenciar e criar </span><strong>Conta de Acesso</strong><span
				style="font-weight: 400;">, resolver dificuldades t&eacute;cnicas e habilitar ou
				desabilitar funcionalidades;</span></li>

			<li><span style="font-weight: 400;">garantia da seguran&ccedil;a do </span><strong>USU&Aacute;RIO</strong><span
				style="font-weight: 400;">;</span></li>

			<li><span style="font-weight: 400;"> resposta a solicita&ccedil;&otilde;es do pr&oacute;prio
			</span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">;</span></li>
			<li><span style="font-weight: 400;"> informa&ccedil;&atilde;o acerca de
				altera&ccedil;&otilde;es nos </span><strong>TERMOS</strong><span style="font-weight: 400;">
				e seus acess&oacute;rios;</span></li>
			<li><span style="font-weight: 400;"> elabora&ccedil;&atilde;o de estat&iacute;sticas com
				rela&ccedil;&atilde;o ao uso do</span><strong> SITE</strong><span style="font-weight: 400;">
				e dos servi&ccedil;os contratados pela </span><strong>Far.me</strong><span style="font-weight: 400;">,
				garantido o anonimato do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">,
				inclusive para fins de aperfei&ccedil;oamento e entendimento do perfil dos </span><strong>USU&Aacute;RIOS</strong><span
				style="font-weight: 400;"> para a melhoria do </span><strong>SITE</strong><span style="font-weight: 400;">;</span></li>

			<li><span style="font-weight: 400;">aperfei&ccedil;oamento de ferramentas de
				interatividade
				entre o </span><strong>SITE</strong><span style="font-weight: 400;"> e o </span><strong>USU&Aacute;RIO</strong><span
				style="font-weight: 400;">, garantido seu anonimato;</span></li>
			<li><span style="font-weight: 400;">cumprimento de ordens judiciais;</span></li>

			<li><span style="font-weight: 400;"> defesa dos direitos da </span><strong>Far.me</strong><span
				style="font-weight: 400;"> contra o </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
				e terceiros em procedimentos judiciais ou administrativos;</span></li>
			<li><span style="font-weight: 400;"> para customizar ofertas e experi&ecirc;ncias;</span></li>
			<li><span style="font-weight: 400;"> para acompanhar as atividades do </span><strong>USU&Aacute;RIO</strong><span
				style="font-weight: 400;"> e trafego no</span><strong> SITE</strong><span style="font-weight: 400;">;</span></li>

			<li><span style="font-weight: 400;">para entrar em contato com o </span><strong>USU&Aacute;RIO</strong><span
				style="font-weight: 400;">, incluindo sobre assuntos de servi&ccedil;o, respostas do
			</span><strong>Contato</strong><span style="font-weight: 400;"> ou
				comunica&ccedil;&otilde;es de marketing permitidas
				atrav&eacute;s de qualquer meio de comunica&ccedil;&atilde;o dispon&iacute;vel;</span></li>
			<li><span style="font-weight: 400;">para auxiliar no cumprimento dos Termos de Uso do
			</span><strong>SITE</strong><span style="font-weight: 400;"> e de suas Pol&iacute;ticas.</span></li>
		</ul>
		<p>&nbsp;</p>
		<ol start="5">
			<li><strong> Compartilhamento e Divulga&ccedil;&atilde;o dos Dados do USU&Aacute;RIO</strong></li>
		</ol>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			preza pela confidencialidade dos Dados do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			e a tem como prioridade em seus neg&oacute;cios. A </span><strong>Far.me</strong><span
			style="font-weight: 400;"> assume o compromisso de n&atilde;o divulgar, compartilhar, dar
			acesso, facilitar acesso, alugar, vender, trocar ou de qualquer outra forma disponibilizar
			tais informa&ccedil;&otilde;es a outros </span><strong>USU&Aacute;RIOS</strong><span style="font-weight: 400;">
			e terceiros, sob nenhum pretexto, exceto nos casos autorizados expressamente pelo </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> e aqui indicados.</span></p>
		<p><span style="font-weight: 400;">Tamb&eacute;m ser&atilde;o permitidas a
			divulga&ccedil;&atilde;o de Dados do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			em caso de cumprimento de ordens judiciais e/ou defesa dos direitos da </span><strong>Far.me</strong><span
			style="font-weight: 400;"> contra o </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			e terceiros em procedimentos judiciais ou administrativos. A </span><strong>Far.me</strong><span
			style="font-weight: 400;"> se compromete a divulgar esses Dados apenas na medida
			necess&aacute;ria para cumprir a determina&ccedil;&atilde;o judicial, permanecendo
			sigilosos os Dados n&atilde;o requeridos pela autoridade em quest&atilde;o ou n&atilde;o
			necess&aacute;rios para a demanda.</span></p>
		<p><span style="font-weight: 400;">O </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			reconhece e concorda expressamente que a base de Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> poder&aacute; ser total ou parcialmente cedida ou transferida a
			terceiros, desde que respeitada a finalidade de dar continuidade &agrave; atividade
			prestada pela </span><strong>Far.me.</strong></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			poder&aacute; utilizar terceiros prestadores de servi&ccedil;o para auxiliar a oferecer
			funcionalidades dos servi&ccedil;os prestados pela mesma, mas nesse caso eles n&atilde;o
			ter&atilde;o qualquer poder para utilizar informa&ccedil;&otilde;es pessoais que eles
			processam para a </span><strong>Far.me</strong><span style="font-weight: 400;"> e
			dever&atilde;o se sujeitar aos termos dessa Pol&iacute;tica.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			se reserva ao direito de compartilhar certas informa&ccedil;&otilde;es n&atilde;o pessoais
			e anonimizadas - Dados que n&atilde;o identificam os </span><strong>USU&Aacute;RIOS</strong><span
			style="font-weight: 400;"> do </span><strong>SITE </strong><span style="font-weight: 400;">individualmente
			- com terceiros prestadores de servi&ccedil;os, empresas parceiras ou pesquisadores
			aprovados, no intuito de melhor compreender quais publicidades e servi&ccedil;os podem
			interessar ao </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">, para
			melhorar a experi&ecirc;ncia do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			e qualidade e a efetividade geral dos servi&ccedil;os prestados pela </span><strong>Far.me</strong><span
			style="font-weight: 400;"> ou servi&ccedil;o deles, ou para contribuir em pesquisas
			acad&ecirc;micas.</span></p>
		<p><strong>5.1.</strong> <strong>Uso de Dados em Caso de Altera&ccedil;&atilde;o de Controle da
			Far.me</strong></p>
		<p><span style="font-weight: 400;">Os Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> coletados podem ser eventualmente transferidos pela </span><strong>Far.me
		</strong><span style="font-weight: 400;">a um terceiro, sob qualquer meio ou forma, em caso
			de:</span></p>
		<ul style="list-style-type:upper-roman">
			<li><span style="font-weight: 400;"> altera&ccedil;&atilde;o do controle;</span></li>
			<li><span style="font-weight: 400;"> aquisi&ccedil;&atilde;o;</span></li>
			<li><span style="font-weight: 400;">iii. incorpora&ccedil;&atilde;o;</span></li>
			<li><span style="font-weight: 400;"> fus&atilde;o;</span></li>
			<li><span style="font-weight: 400;"> venda de ativos.</span></li>
		</ul>
		<p>&nbsp;</p>
		<ol start="6">
			<li><strong> Medidas de Seguran&ccedil;a</strong></li>
		</ol>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			recomenda que o </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			mantenha seus Dados (login e senha) para acesso &agrave;s &aacute;reas restritas do </span><strong>SITE</strong><span
			style="font-weight: 400;"> sob total sigilo, n&atilde;o as divulgando a outros </span><strong>USU&Aacute;RIOS</strong><span
			style="font-weight: 400;"> ou a terceiros.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			nunca solicitar&aacute; ao </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			que informe sua senha fora do </span><strong>SITE</strong><span style="font-weight: 400;">,
			por telefone, e-mail ou por qualquer outro meio de comunica&ccedil;&atilde;o. A senha do
		</span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;"> dever&aacute; ser
			usada exclusivamente no momento do acesso &agrave; </span><strong>Conta de Acesso</strong><span
			style="font-weight: 400;"> do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			no </span><strong>SITE</strong><span style="font-weight: 400;">. Caso o </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> suspeite que sua senha tenha sido exposta a outros </span><strong>USU&Aacute;RIOS</strong><span
			style="font-weight: 400;"> ou a terceiros, a </span><strong>Far.me</strong><span style="font-weight: 400;">
			recomenda a imediata substitui&ccedil;&atilde;o da senha.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			envia ao </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">, apenas
			e-mails com mensagens publicit&aacute;rias, divulgando produtos e servi&ccedil;os ou
			atualizando informa&ccedil;&otilde;es, conforme definido neste documento.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			n&atilde;o envia e-mail:</span></p>
		<ul style="list-style-type:upper-roman">
			<li><span style="font-weight: 400;"> solicitando Dados pessoais do </span><strong>USU&Aacute;RIO</strong><span
				style="font-weight: 400;">;</span></li>
			<li><span style="font-weight: 400;"> solicitando a senha ou Dados financeiros do </span><strong>USU&Aacute;RIO</strong><span
				style="font-weight: 400;">;</span></li>
			<li><span style="font-weight: 400;">iii. com arquivos anexos (exceto documentos em formatos
				&ldquo;png&rdquo;, &ldquo;jpg&rdquo;, &ldquo;gif&rdquo;, &ldquo;bmp&rdquo;, e
				&ldquo;pdf&rdquo;);</span></li>
			<li><span style="font-weight: 400;"> n&atilde;o autorizados pelo </span><strong>USU&Aacute;RIO</strong><span
				style="font-weight: 400;"> (spam);</span></li>
			<li><span style="font-weight: 400;"> com links para download de arquivos.</span></li>
		</ul>
		<p><span style="font-weight: 400;">Caso receba um e-mail com tais caracter&iacute;sticas,
			desconsidere-o e entre em contato atrav&eacute;s de qualquer um dos canais disponibilizados
			no <a href="https://www.farme.com" title="Far.me" target="_blank">item 3</a> </span><span
			style="font-weight: 400;"> deste documento.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			n&atilde;o armazena em sua base de Dados informa&ccedil;&otilde;es financeiras do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;">, como as informa&ccedil;&otilde;es referentes a cart&otilde;es
			de cr&eacute;dito. O procedimento de aprova&ccedil;&atilde;o do pagamento ocorre entre o
		</span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">, os bancos e as
			administradoras de cart&otilde;es, sem interven&ccedil;&atilde;o da </span><strong>Far.me</strong><span
			style="font-weight: 400;">. </span></p>
		<p><span style="font-weight: 400;">Mesmo com os maiores esfor&ccedil;os e
			dedica&ccedil;&atilde;o da </span><strong>Far.me</strong><span style="font-weight: 400;">
			para com os seus </span><strong>USU&Aacute;RIOS</strong><span style="font-weight: 400;">, o
			atual est&aacute;gio da tecnologia n&atilde;o permite que seja criada uma base de Dados
			absolutamente segura contra ataques, sendo que a </span><strong>Far.me</strong><span style="font-weight: 400;">
			utiliza-se de toda a prote&ccedil;&atilde;o compat&iacute;vel com a sua
			atua&ccedil;&atilde;o e porte.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			adota recursos tecnol&oacute;gicos avan&ccedil;ados para garantir a seguran&ccedil;a de
			todos os Dados do </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			coletados e armazenados. Nem mesmo os funcion&aacute;rios da </span><strong>Far.me</strong><span
			style="font-weight: 400;"> possuem livre acesso &agrave; base de Dados dos </span><strong>USU&Aacute;RIOS</strong><span
			style="font-weight: 400;">, sendo este limitado apenas &agrave;quelas pessoas cujas
			fun&ccedil;&otilde;es exigem o contato com Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;">. Entre as medidas de seguran&ccedil;a implementadas,
			est&atilde;o a utiliza&ccedil;&atilde;o de modernas formas de prote&ccedil;&atilde;o SSL
			(criptografia) e a instala&ccedil;&atilde;o de barreiras contra o acesso indevido &agrave;
			base de dados (firewalls).</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			tem como princ&iacute;pio de sua atua&ccedil;&atilde;o o respeito ao </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;">, agindo sempre em conformidade com as disposi&ccedil;&otilde;es
			do C&oacute;digo de Prote&ccedil;&atilde;o e Defesa do Consumidor (Lei Federal n&ordm;
			8.078 de 1990), do Marco Civil da Internet (Lei Federal n&ordm; 12.965 de 2014), da Lei
			Geral de Prote&ccedil;&atilde;o de Dados (Lei Federal n&ordm; 13.709, de 2018) e das demais
			normas referentes ao com&eacute;rcio de produtos em ambiente eletr&ocirc;nico previstas em
			nosso ordenamento jur&iacute;dico p&aacute;trio.</span></p>
		<p>&nbsp;</p>
		<ol start="7">
			<li><strong> Direitos das Pessoas Sobre os Dados Coletados</strong></li>
		</ol>
		<p><span style="font-weight: 400;">O </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			tem a possibilidade acessar os pr&oacute;prios dados que forem alvo de processamento pela
			plataforma.</span></p>
		<p><span style="font-weight: 400;">O </span><strong>USU&Aacute;RIO</strong><span style="font-weight: 400;">
			declara ser o leg&iacute;timo titular de seus Dados e poder&aacute;, a qualquer momento,
			utilizar as ferramentas do </span><strong>SITE</strong><span style="font-weight: 400;">
			para edit&aacute;-los, atualiz&aacute;-los ou remov&ecirc;-los preventivamente de nossa
			base de Dados.</span></p>
		<p><span style="font-weight: 400;">A </span><strong>Far.me</strong><span style="font-weight: 400;">
			manter&aacute; preventivamente os Dados do </span><strong>USU&Aacute;RIO</strong><span
			style="font-weight: 400;"> removidos em sigilo pelo prazo de 06 (seis) meses, a fim de
			cumprir obriga&ccedil;&otilde;es legais de guarda obrigat&oacute;ria, ou, em outro prazo em
			caso de altera&ccedil;&atilde;o da legisla&ccedil;&atilde;o p&aacute;tria, descartando-os
			definitivamente ap&oacute;s tal per&iacute;odo.</span></p>
		<p>&nbsp;</p>
		<ol start="8">
			<li><strong> Pol&iacute;tica para Menores de Dezoito Anos</strong></li>
		</ol>
		<p><span style="font-weight: 400;">Ser&aacute; proibido o cadastro no </span><strong>SITE</strong><span
			style="font-weight: 400;"> de </span><strong>USU&Aacute;RIOS</strong><span style="font-weight: 400;">
			menores de 18 (dezoito) anos que n&atilde;o estejam devidamente</span> <span style="font-weight: 400;">acompanhados
			ou representados pelos seus respons&aacute;veis legais no momento do cadastro devido
			&agrave; sua incapacidade civil. </span></p>
		<p><span style="font-weight: 400;">Os formul&aacute;rios e question&aacute;rios do </span><strong>SITE</strong><span
			style="font-weight: 400;"> da </span><strong>Far.me</strong><span style="font-weight: 400;">
			n&atilde;o t&ecirc;m como inten&ccedil;&atilde;o obter dados de menores de dezoito anos.
		</span><span style="font-weight: 400;">Caso tais dados sejam inseridos por menores, seu
			representante legal que esteja em desacordo poder&aacute; entrar em contato atrav&eacute;s
			de qualquer um dos canais disponibilizados no <a href="https://www.farme.com" title="Far.me"
				target="_blank">item 3</a> </span><span style="font-weight: 400;"> deste documento para
			retificar, modificar ou remover tais dados.</span></p>
		<p><span style="font-weight: 400;">A cria&ccedil;&atilde;o de uma </span><strong>Conta de
			Acesso</strong><span style="font-weight: 400;"> no </span><strong>SITE</strong><span style="font-weight: 400;">
			em nome de um menor pressup&otilde;e a representa&ccedil;&atilde;o desse menor por uma
			pessoa maior de dezoito anos, que ser&aacute; reputada respons&aacute;vel civil e
			criminalmente por qualquer negocia&ccedil;&atilde;o realizada, viola&ccedil;&atilde;o
			cometida ou declara&ccedil;&atilde;o falsa, incompleta, desatualizada ou imprecisa prestada
			pelo menor de dezoito anos.</span></p>

	</Dialog>
</template>

<script>
export default {
	name: 'TermosDeUso'
};
</script>
