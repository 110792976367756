<template>
	<div :class="!noZoom && 'dialog-container'">
		<div v-if="loading" class="q-row items-center justify-center full-height">
			<v-progress-circular indeterminate color="primary" />
		</div>
		<v-layout v-else wrap>
			<v-flex v-if="!noZoom" :class="`flex-right scroll-style`">
				<Farmembro :farmembro="assinatura.paciente" class="mb-4"/>
				<Observacao :assinatura="assinatura" class="mb-4" @observationUpdated="reload"/>
				<Pendencias :box_id="this.box_id" :item="this.item" />
				<Medicamentos  v-if="!noMed" :receitas="receitas"
					:origem="origem"
					:assinatura="assinatura"
					:boxId="this.box_id"
					:medicamentos="medicamentos"
					:medicamentosComAproveitamentoReceita="medicamentosComAproveitamentoReceita"
					:readOnly="noCheck"
					:prescriptionReadonly="prescriptionReadonly"
					:prescriptionIsVisible="prescriptionIsVisible"
					:editavel="!readonly"
					:eRescunho="!readonly"
					@receita-vinculada="reload()"
					@pausa-adicionada="reload()"
					@pausa-editada="reload()"
					@pausa-removida="reload()"
					@produto-adicionado="onProdutoAdicionado"
					@produto-editado="onProdutoEditado"
					@produto-removido="onProdutoRemovido"
					class="mb-4" />
				<v-flex xs12>
					<v-layout wrap>
						<v-flex xs6 class="text-xs-left">
							<v-btn depressed :loading="loading" class="success" @click="finalizarRascunho">
								Finalizar rascunho
							</v-btn>
						</v-flex>
						<v-flex xs6 class="text-xs-right">
							<v-btn small flat :loading="loading" color="error"
								@click="() => this.dialog_confirmacao_reiniciar = true">
								Reiniciar rascunho
							</v-btn>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-flex>
		</v-layout>
		<v-dialog persistent v-model="dialog_confirmacao_reiniciar" scrollable transition="fade" max-width="400px">
			<transition>
				<Dialog v-if="dialog_confirmacao_reiniciar" titulo="Reiniciar rascunho" subtitulo="Tem certeza que deseja reiniciar este rascunho? Esta ação irá apagar todos os rascunhos e retornará idêntico à assinatura."
					btn_acao="Sim" btn_acao_class="error" btn_cancelar="Cancelar" @acao="resetarRascunho"
					@close="dialog_confirmacao_reiniciar = false" />
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { RascunhoPresenter } from '@Presenter/alteracoes-presenter';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { BoxPresenter } from '@Presenter/box-presenter';
import { StatusBoxPresenter } from '@Presenter/status-box-presenter';
import { ReceitaMixin } from '@Mixins/receitas';
import Observacao from '@Componentes/assinatura/observacao';
import Farmembro from '@Componentes/dialog-comparar-receitas-medicamentos/farmembro';
import Medicamentos from '@Componentes/dialog-comparar-receitas-medicamentos/medicamentos';
import Pendencias from './dialog-comparar-receitas-medicamentos/pendencias.vue';

export default {
	name: 'DialogAlteracoes',
	components: {
		Medicamentos, Farmembro, Observacao, Pendencias
	},
	mixins: [ReceitaMixin],
	props: {
		item: {
			type: Object
		},
		box_id: {
			type: Number
		},
		assinatura_id: {
			type: Number
		},
		paciente_id: {
			type: Number
		},
		noUpload: {
			type: Boolean,
			required: false
		},
		noCheck: {
			type: Boolean,
			required: false
		},
		noMed: {
			type: Boolean,
			required: false
		},
		noZoom: {
			type: Boolean,
			required: false
		},
		prescriptionIsVisible: {
			type: Boolean,
			required: false,
			default: true
		},
		prescriptionReadonly: {
			type: Boolean,
			required: false,
			default: true
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		assinatura: {},
		receitas: [],
		medicamentos: [],
		medicamentosComAproveitamentoReceita: [],
		loading: true,
		dialog_confirmacao_reiniciar: false,
		origem: 'Box'
	}),
	created() {
		this.reload();
	},
	methods: {
		close() {
			this.$emit('close');
		},
		onProdutoAdicionado(produto) {
			RascunhoPresenter.addProduct(this.box_id, { medicamento_id: produto.id, ...produto }).then(responseData => {
				produto.alteracao_id = responseData.id;
			}).catch(this.reload);
		},
		onProdutoEditado(produto) {
			RascunhoPresenter.updateProduct(this.box_id, produto.alteracao_id, produto).catch(this.reload);
		},
		onProdutoRemovido(produto) {
			if (produto.alteracao_id)
				RascunhoPresenter.deleteProduct(this.box_id, produto.alteracao_id).catch(this.reload);
		},
		reload() {
			this.loading = true;
			const promises = [
				AssinaturaPresenter.show(this.assinatura_id),
				BoxPresenter.show(this.box_id),
				// BoxPresenter.getAproveitamentoReceita(this.box_id),
				RascunhoPresenter.get(this.box_id)
			];

			Promise.all(promises).then(([assinatura, box, rascunho]) => {
				this.origem = this.item.e_alteracoes_resolvidas ? 'Rascunho finalizado' : 'Rascunho em aberto';
				this.assinatura = assinatura;
				this.receitas = box.receitas;
				this.medicamentosComAproveitamentoReceita = rascunho.medicamentos.map(i => ({
					medicineId: i.medicamento_id,
					totalCycles: i.ciclos_extras || 1
				}));

				const assinaturaMedicamentos = rascunho.medicamentos.map(am => ({
					alteracao_id: am.id,
					...am,
					...am.medicamento,
					posologia: JSON.parse(am.posologia)
					// receitas: am.receitas.filter(r => r.box_id === am.box_id)
				}));

				this.medicamentos = assinaturaMedicamentos;
			}).finally(() => {
				this.loading = false;
			});
		},
		finalizarRascunho() {
			this.loading = true;
			StatusBoxPresenter.store({
				box_id: this.box_id,
				status: 'CHANGED'
			})
				.then(() => {
					this.loading = false;
					this.close();
				})
				.catch(() => {
					this.loading = false;
				});
		},
		resetarRascunho() {
			this.loading = true;
			RascunhoPresenter.reset(this.box_id)
				.then(() => {
					this.loading = false;
					this.close();
				})
				.catch(() => {
					this.loading = false;
				}).finally(() => {
					this.dialog_confirmacao_reiniciar = false;
				});
		}

	}
};
</script>

<style lang="scss" scoped>
	.dialog-container {
		background: white;
		width: 100%;

		.titulo {
			font-size: 22px;
		}

		.layout {

			i {
				font-size: 70px;
			}

			.flex-left,
			.flex-right {
				height: 100%;
			}

			.flex-left {
				.layout {
					height: 100%;

					.flex-1 {
						height: 88%;
					}

					.grow {
						flex-basis: 70%;
					}
				}

				.zoom-on-hover {
					height: 95%;
					display: flex;
					justify-content: center;
					align-items: center;

					::v-deep .normal {
						width: unset;
						max-width: 100%;
						max-height: 100%;
					}
				}

				.flex {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
				}

				.flex-text-card{
					display: flex;
					padding: 10px;
					flex-direction: column;
					justify-content: space-evenly;
				}

				@media screen and (max-width: 1280px) {
					height: calc(100vh - 100px);
					overflow-y: scroll;
					scrollbar-width: none;
					-ms-overflow-style: none;
				}
			}

			.flex-right {
				overflow-y: auto;
				height: calc(100vh - 100px);
			}
		}

		.scroll-style::-webkit-scrollbar-track {
			border-radius: 5px;
			background-color: #F5F5F5;
		}

		.scroll-style::-webkit-scrollbar {
			width: 3px;
			background-color: #F5F5F5;
		}

		.scroll-style::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background-color: #555;
		}
	}
</style>
