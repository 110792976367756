<template>
	<v-container fluid grid-list-md class="pa-0 py-5">

		<h3 class="my-3">Preços</h3>
		<section
			v-for="(price, index) of prices"
			:key="index"
			style="background-color: rgba(255, 200, 117, 0.3); border-radius: 5px;"
			class="mb-4">

			<v-layout wrap class="pa-4 pt-5">
				<v-flex md6 xs12>
					<v-text-field
						disabled
						:value="prices[index].layer.name"
						background-color="white"
						box
						label="Tabela de Preço"
						:data-vv-name="`layer-id-${index}`"
						v-validate="{ required: true }"
						:error-messages="errors.collect(`layer-id-${index}`)"/>
				</v-flex>

				<v-flex md6 xs12>
					<v-text-field
						v-money
						v-model="prices[index].value"
						background-color="white"
						box
						label="Preço"
						:data-vv-name="`Preço [${prices[index].layer.name}]`"
						v-validate="{ required: true }"
						:error-messages="errors.collect(`Preço [${prices[index].layer.name}]`)"/>
				</v-flex>
			</v-layout>
		</section>

		<FarmeDivider />

		<h3 class="my-3">Disponibilidade</h3>

		<v-layout wrap class="my-3">
			<v-flex md6 xs12 v-for="(availability, index) of availabilities" :key="index">
				<v-autocomplete
					v-model="availabilities[index].status"
					background-color="white"
					:items="getAvailabilities"
					:label="`${availability.companyUnit.name}`"
					no-data-text="Item não encontrado"
					hide-selected
					box
					clearable
					v-validate="{ required: true }"
					:error-messages="errors.collect(`Disponibilidade em ${availability.companyUnit.abbr}`)"
					:data-vv-name="`Disponibilidade em ${availability.companyUnit.abbr}`"
					item-value="id"
					item-text="name"/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import FarmeDivider from '@Componentes/farme-divider.vue';
import { availabilities } from '@Consts/availabities';

export default {
	name: 'NewProductStepPrice',
	$_veeValidate: { validator: 'new' },
	components: {
		FarmeDivider
	},
	props: {
		prices: {
			required: true,
			type: Array
		},
		availabilities: {
			required: true,
			type: Array
		}
	},
	computed: {
		getAvailabilities() {
			const arr = Object.keys(availabilities);

			return arr.map(item => ({
				id: item,
				name: availabilities[item]
			}));
		}
	},
	methods: {
		async validate() {
			return Boolean(await this.$validator.validateAll());
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
