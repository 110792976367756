<template>
	<Dialog titulo="Enviar Notas Fiscais" @close="$emit('update:close', false)"
		:carregando="carregando_modal" btn_acao="Enviar e-mail" @acao="onClick" :loading="loading">
		<v-container grid-list-lg class="pa-0">
			<v-layout>
				<v-flex>
					<v-text-field box v-model="email" label="E-mail" data-vv-name="e-mail"
						v-validate="{ required: true }" />
				</v-flex>
				<v-flex xs3>
					<v-radio-group v-model="tipos_nf" row>
						<v-radio label="NFC" value="nfc"></v-radio>
						<v-radio label="NFC + NFS" value="nfc_nfs"></v-radio>
					</v-radio-group>
				</v-flex>
			</v-layout>
			<v-layout>
				<v-flex>
					<v-text-field x8 box v-model="pasta" label="Pasta no Dropbox" data-vv-name="pasta"
						v-validate="{ required: true }" :error-messages="errors.first('pasta')" @keyup.enter="carregarArquivos()" @blur="carregarArquivos()" />
				</v-flex>
			</v-layout>
		</v-container>
		<v-container fluid grid-list-lg class="pa-0">
			<v-data-table v-model="selecionados" :headers="headers" :items="items"
				:loading="carregando" :no-data-text="(carregando) ? 'Carregando...' : (error) ? 'Erro ao carregar, tente novamente' : 'Nenhuma nota fiscal encontrada'"
				:pagination.sync="pagination" no-results-text="Nenhum resultado encontrado"
				:rows-per-page-items="[15, 30, 45, {text: 'Todos', value: -1}]"
				rows-per-page-text="Itens por página" select-all>
				<template slot="headers" slot-scope="props">
					<tr>
						<th class="pr-0">
							<v-checkbox :input-value="props.all" :indeterminate="props.indeterminate" primary
								hide-details @click.stop="toggleAll" />
						</th>
						<th v-for="(header) in props.headers" :key="header.text" :class="['column sortable text-xs-left', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
							@click="changeSort(header.value)">
							{{ header.text }}
							<v-icon small>arrow_upward</v-icon>
						</th>
					</tr>
				</template>
				<template slot="items" slot-scope="props">
					<tr :active="props.selected" @click="props.selected = !props.selected">
						<td class="pr-0">
							<v-checkbox :input-value="props.selected" primary hide-details />
						</td>
						<td class="text-xs-left">{{ props.item.nome }}</td>
						<td class="text-xs-left">{{ props.item.modificacao | formatDate('DD/MM/YYYY HH:mm') }}</td>
						<td class="text-xs-left">{{ props.item.tipo }}</td>
					</tr>
				</template>
			</v-data-table>
		</v-container>
	</Dialog>
</template>

<script>
import { NfPresenter } from '@Presenter/nf-presenter';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { getCurrentCompanyUnit } from '@Config/unidades';

export default {
	name: 'DialogEnviarNf',
	$_veeValidate: { validator: 'new' },
	props: {
		assinatura_id: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		headers: [
			{ text: 'Nome', value: 'nome' },
			{ text: 'Ultima modificação', value: 'modificacao' },
			{ text: 'Tipo', value: 'tipo' }
		],
		pagination: {
			sortBy: 'modificacao',
			descending: true
		},
		carregando_modal: false,
		items: [],
		medicamentos: [],
		paciente: {},
		responsavel: {},
		email: '',
		pasta: '',
		carregando: false,
		loading: false,
		error: false,
		tipos_nf: 'nfc_nfs',
		selecionados: []
	}),
	created() {
		this.carregando_modal = true;
		AssinaturaPresenter.show(this.assinatura_id)
			.then(response => {
				const assinatura = response;
				this.paciente = assinatura.paciente;
				this.responsavel = (this.paciente.responsaveis || []).find(r => r.tipo === 'financeiro');
				this.email = this.responsavel ? this.responsavel.email : this.paciente.email;
				this.atualizarPastaPaciente();
			}).finally(() => {
				this.carregando_modal = false;
			});
	},
	methods: {
		async onClick() {
			this.loading = true;
			this.$validator.validateAll().then(valido => {
				if (!valido) {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
					return null;
				}
				return this.selecionados;
			}).then(items => NfPresenter.email(this.email, this.paciente, this.tipos_nf, items)).then(() => {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Email enviado com sucesso' });
			})
				.catch(err => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: err.message });
				})
				.finally(() => {
					this.loading = false;
				});
		},
		async atualizarPastaPaciente() {
			let pasta = '/far-me/notas fiscais/xml';
			if (getCurrentCompanyUnit().key === 'SP')
				pasta = '/Far.me SP/notas fiscais/xml';
			this.pasta = `${pasta}/${this.assinatura_id}`;
			this.carregarArquivos();
		},
		async carregarArquivos() {
			this.carregando = true;

			NfPresenter.index(this.pasta)
				.then(response => {
					this.items = response;
				})
				.catch(() => {
					this.items = [];
				})
				.finally(() => {
					this.carregando = false;
				});
		},
		toggleAll() {
			if (this.selecionados.length)
				this.selecionados = [];
			else
				this.selecionados = this.items.slice();
		},
		changeSort(column) {
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__text {
  background: #f0f5f6;
}

.items-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  min-height: calc(100vh - 116px);
  justify-content: center;

  &.carregando {
    align-items: center;
  }

  .iugu-wrapper {
    overflow: auto;
    background: #fff;
    max-width: 1080px;
    border: 2px solid #1f59a2;
    border-radius: 5px;
    padding: 32px;
  }
}

::v-deep .tabela {
  border: 1px solid #ddd;
  border-radius: 5px;

  thead {
    th {
      font-weight: bold !important;
      color: #000 !important;
      font-size: 14px !important;
    }
  }
}
</style>
