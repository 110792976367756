<template>
	<v-card>
		<v-card-title>
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>
					fas fa-utensils
				</v-icon>
			</v-avatar>
			<h3>Sonda</h3>
		</v-card-title>
		<v-card-text class="pt-0">
			<span>{{ paciente.sonda ? paciente.sonda : 'Este paciente não utiliza sonda' }}</span>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'Sonda',
	props: {
		paciente: {
			type: Object,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
.loading {
	min-height: 50px;
}
</style>
