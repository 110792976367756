<template>
	<Dialog titulo="Gerar fatura no Iugu" :subtitulo="subtitulo" @close="closeIuguDialog()" :carregando="carregando"
		btn_acao="Realizar Cobrança" @acao="onClick" :loading="loading">
		<v-layout row wrap align-center justify-center>
			<v-flex xs6>
				Responsável financeiro no FarmeOS:
				<ResponsavelDetalhes v-if="responsavel" :responsavel="responsavel" />
			</v-flex>
			<v-flex xs6>
				<template v-if="show_modalidade">
					<strong>Modalidade da assinatura: {{ modalidade }}</strong> <br>
				</template>
				<TextDateField v-model="vencimento" label="Vencimento" box hide-details />
			</v-flex>
		</v-layout>
		<v-container fluid grid-list-lg class="pa-0 mt-3">
			<v-layout v-for="(item, i) in items" :key="i" wrap>
				<v-flex class="my-2" v-if="item.first" xs12>
					<v-divider />
				</v-flex>
				<v-flex>
					<v-text-field box hide-details v-model="item.nome"
						:label="item.tipo === 'extra' ? 'ITEM PORTFOLIO EXTRA' : 'Produto'" color="green"
						:class="{ 'label-bold': item.tipo === 'extra' }" />
				</v-flex>
				<v-flex xs1>
					<v-text-field box hide-details v-model.number="item.quantidade" label="Qtd" type="number"
						color="green"
						step="1" :disabled="box_id && (ehProporcional || !item.id)" />
				</v-flex>
				<v-flex xs2>
					<v-text-field box hide-details v-model="item.preco" label="Preço" v-money
						color="green" />
				</v-flex>
				<v-flex xs2>
					<v-text-field box hide-details v-model="item.desconto" label="Desconto" v-money
						color="green" />
				</v-flex>
				<v-flex xs2>
					<v-text-field box hide-details :readonly="true" :value="totals(item)" label="Total"
						color="green" />
				</v-flex>
				<v-flex style="display: flex; max-width: max-content; align-items: center;">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn flat icon color="red darken-2" v-on="on" @click="removerItem(i)">
								<v-icon>remove_circle_outline</v-icon>
							</v-btn>
						</template>
						<span>Remover</span>
					</v-tooltip>
				</v-flex>
			</v-layout>
			<div class="items-center mt-3">
				<v-btn style="color: #342b1d !important" depressed class="purple white--text" @click="novoItem">
					<v-icon style="color: #342b1d" small class="mr-2">fas fa-plus</v-icon>
					Adicionar item
				</v-btn>
			</div>
		</v-container>

		<v-dialog persistent v-model="dialog_iugu_id" scrollable transition="fade" max-width="520px">
			<transition>
				<DialogIuguId v-if="dialog_iugu_id" :assinatura="assinatura" :close.sync="dialog_iugu_id" />
			</transition>
		</v-dialog>

		<v-dialog v-if="dialog_confirm_invoice" persistent v-model="dialog_confirm_invoice" scrollable transition="fade"
			max-width="520px">
			<transition>
				<DialogConfirmInvoice v-if="dialog_confirm_invoice" :responsible="responsavel" :loading="loadingInvoice"
					:close.sync="dialog_confirm_invoice" @createInvoice="createInvoice" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-else v-model="dialog_confirmar_fatura" scrollable transition="fade"
			max-width="520px">
			<transition>
				<DialogConfirmarFatura v-if="dialog_confirmar_fatura" :assinatura="assinatura"
					:close.sync="dialog_confirmar_fatura" @gera-fatura="val => request('geraFatura', val)"
					@cobrar-cartao="request('cobrarCartao')" @trocar-cliente="trocarCliente"
					:podeParcelar="pagamentoEhParcelado" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialog_status_fatura" scrollable transition="fade" max-width="520px">
			<transition>
				<DialogStatusFatura v-if="dialog_status_fatura"
					@close="() => { dialog_status_fatura = false; this.closeIuguDialog() }"
					:totalAmount="paymentChecked.totalAmount" :totalAmountPaid="paymentChecked.totalAmountPaid"
					:difference="paymentChecked.difference" :operation="paymentChecked.operation"
					:paymentMethod="paymentChecked.paymentMethod" />
			</transition>
		</v-dialog>

		<template v-slot:bottom-left>
			<InformacaoValores :desconto="desconto" :descontoPrimeiraBox="percent_discount_first_box" :subtotal="subtotal"
				:total="total" :totalPrimeiraBox="totalFirstBox" :showInfoPrimeiraBox="isFirstBoxFromJornada"
				:permiteParcelar="permiteParcelar" :pagamentoEhParcelado="pagamentoEhParcelado"
				@alterouParcelamento="val => pagamentoEhParcelado = val" />
		</template>
	</Dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import moment from 'moment';
import assinatura_modalidades from '@Consts/modalidade_assinatura';
import { QuotationPresenter } from '@Presenter/quotation-presenter';
import { featureFlags } from '@Consts/feature-flags';
import { featureFlagsMixin } from '@Mixins/feature-flag';
import DialogConfirmInvoice from '@Views/colaborador/invoices/dialog-confirm-invoice.vue';
import { InvoicesPresenter } from '@Presenter/invoices-presenter';
import {
	documentTypeEnum, paymentsMethod, typesEnum, enumGateways
} from '@Consts/invoices';
import { getCurrentCompanyUnit } from '@Config/unidades';
import { onlyNumber } from '@Util/functions';
import { calculatePricePerPillsOrPackage } from '@Util/ProductService';
import { getUFByStateName } from '@Consts/estados';
import DialogIuguId from './dialog-iugu-id';
import DialogConfirmarFatura from './dialog-confirmar-fatura';
import DialogStatusFatura from './dialog-status-fatura';
import ResponsavelDetalhes from './responsavel-detalhes.vue';
import InformacaoValores from './informacao-valores.vue';

export default {
	name: 'DialogIugu',
	mixins: [featureFlagsMixin],
	components: {
		DialogIuguId,
		DialogConfirmarFatura,
		ResponsavelDetalhes,
		InformacaoValores,
		DialogStatusFatura,
		DialogConfirmInvoice
	},
	props: {
		box_id: {
			type: Number,
			default: null
		},
		assinatura_id: {
			type: Number,
			required: true
		},
		show_modalidade: {
			type: Boolean,
			required: false,
			default: false
		},
		parcelamentoHabilitado: {
			type: Boolean,
			required: false,
			default: false
		},
		cobrancaAvulsa: {
			type: Boolean,
			required: false,
			default: false
		},
		isFirstBox: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		SHIPPING_ITEM_NAME: 'Serviços farmacêuticos',
		box: null,
		assinatura: null,
		responsavel: null,
		casa_repouso: null,
		dialog_iugu_id: false,
		dialog_confirmar_fatura: false,
		dialog_status_fatura: false,
		dialog_confirm_invoice: false,
		vencimento: moment().add(1, 'days').format('DD/MM/YYYY'),
		carregando: true,
		loading: false,
		error: false,
		items: [],
		pagamentoEhParcelado: false,
		percent_discount_first_box: 0,
		paymentChecked: {},
		loadingInvoice: false,
		invoiceGateway: ''
	}),
	computed: {
		modalidade() {
			return this.assinatura ? this.assinatura.modalidade : null;
		},
		ehProporcional() {
			return this.modalidade === assinatura_modalidades.PROPORCIONAL;
		},
		permiteParcelar() {
			return this.parcelamentoHabilitado && (this.cobrancaAvulsa || !this.ehProporcional);
		},
		subtitulo() {
			if (this.assinatura)
				return this.assinatura.paciente.nome;
			return 'Carregando...';
		},
		subtotal() {
			return this.items.reduce((sum, item) => {
				sum += item.quantidade * Number(this.$functions.onlyNumber(item.preco));
				return sum;
			}, 0);
		},
		desconto() {
			return this.items.reduce((sum, item) => {
				sum += item.quantidade * Number(this.$functions.onlyNumber((item.desconto)));
				return sum;
			}, 0);
		},
		total() {
			return this.subtotal - this.desconto;
		},
		isFirstBoxFromJornada() {
			if (!this.box_id || !this.isFirstBox)
				return false;

			return this.assinatura?.pedido_id && this.assinatura?.pedido_id !== 'NA';
		},
		priceShippingCents() {
			const shippingItem = this.items.find(item => (item.nome || '').trim() === this.SHIPPING_ITEM_NAME);
			if (!shippingItem)
				return 0;
			return this.totalItemCents(shippingItem);
		},
		totalFirstBox() {
			const totalWithoutShipping = this.total - this.priceShippingCents;
			const discountCents = totalWithoutShipping * (this.percent_discount_first_box / 100);
			return this.total - discountCents;
		},
		totalDiscount() {
			const totalWithoutShipping = this.total - this.priceShippingCents;
			return totalWithoutShipping * (this.percent_discount_first_box / 100);
		},

		isFarmeFinanceEnable() {
			if (!this.isFeatureEnabled(featureFlags.enable_farme_finance_and_invoices))
				return false;

			if (this.cobrancaAvulsa)
				return this.isFeatureEnabled(featureFlags.enable_single_purchase);

			if (this.assinatura.filial_id === 1)
				return this.isFeatureEnabled(featureFlags.enable_farme_finance_bh);

			return this.isFeatureEnabled(featureFlags.enable_farme_finance_sp);
		},
		dueDateIsValid() {
			const dueDate = moment(this.vencimento, 'DD/MM/YYYY');
			const today = moment().startOf('day');
			if (dueDate.isSameOrAfter(today))
				return true;
			return false;
		}
	},
	async created() {
		this.assinatura = await AssinaturaPresenter.show(this.assinatura_id);
		this.responsavel = this.assinatura && this.assinatura.paciente.responsaveis.find(x => x.tipo === 'financeiro');
		this.casa_repouso = this.assinatura.paciente.casa_repouso;

		if (this.isFirstBoxFromJornada) {
			try {
				await this.loadFirstBoxJornadaDiscount();
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { message: `Erro ao buscar orçamento para verificar desconto 1ª box: ${error.message}`, color: 'error' });
				throw error;
			}
		}

		if (this.box_id) {
			const modalidade = this.assinatura.modalidade;

			const promises = [
				BoxPresenter.show(this.box_id),
				modalidade === assinatura_modalidades.INTEGRAL
					? BoxPresenter.cobrancaIntegral(this.box_id)
					: BoxPresenter.cobrancaProporcional(this.box_id),
				// Essa é feita para ambos os tipos de assinatura?
				BoxPresenter.getAproveitamentoReceita(this.box_id)
			];

			Promise.all(promises)
				.then(([box, items, aproveitamentoReceitas]) => {
					this.box = box;

					if (aproveitamentoReceitas.length > 0 && modalidade === assinatura_modalidades.INTEGRAL)
						this.pagamentoEhParcelado = true;

					this.items.push(
						{
							nome: this.SHIPPING_ITEM_NAME,
							quantidade: 1,
							preco: this.assinatura.valor_servico
						},
						...items
					);
					this.setVencimento();
				})
				.catch(() => {
					this.error = true;
				})
				.finally(() => {
					this.carregando = false;
				});
		} else {
			this.novoItem();
			this.carregando = false;
		}
	},
	methods: {
		closeIuguDialog() {
			this.$emit('close');
		},
		totalItemCents(item) {
			return item.quantidade * (this.$functions.onlyNumber(item.preco) - this.$functions.onlyNumber(item.desconto));
		},
		totals(item) {
			return this.$functions.mascaraDinheiro(this.totalItemCents(item));
		},
		removerItem(index) {
			this.items.splice(index, 1);
		},
		novoItem() {
			this.items.push({
				nome: '',
				quantidade: 1,
				preco: null,
				desconto: 0
			});
		},
		setVencimento() {
			const prazo_pagamento = (this.casa_repouso || { prazo_pagamento: 0 }).prazo_pagamento;
			const data_proxima_box = moment(this.box.inicio).add(!prazo_pagamento ? -1 : prazo_pagamento, 'days');
			this.vencimento = data_proxima_box.format('DD/MM/YYYY');
		},
		trocarCliente() {
			this.dialog_confirmar_fatura = false;
			this.dialog_iugu_id = true;
		},
		onClick() {
			const invalido = this.items.find(item => !item.nome || !item.quantidade || !this.$functions.onlyNumber(item.preco));
			if (invalido) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Existe algum item incompleto' });
				return;
			}

			if (!this.dueDateIsValid) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Data de vencimento inválida' });
				return;
			}

			if (this.isFarmeFinanceEnable && this.total <= 0) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Valor da fatura zerado' });
				return;
			}

			if (this.isFarmeFinanceEnable) {
				if (this.isFirstBoxFromJornada && this.invoiceGateway === enumGateways.IUGU)
					this.updateOrCreateClienteIugu();
				else
					this.dialog_confirm_invoice = true;
			} else
				this.updateOrCreateClienteIugu();
		},
		createInvoice() {
			this.dialog_confirmar_fatura = false;
			this.loadingInvoice = true;
			const params = this.buildParamsToCreateInvoice();
			InvoicesPresenter.manageInvoice(params)
				.then(response => {
					if (!response || response.operation === 'nothing') {
						this.dialog_confirm_invoice = false;
						this.closeIuguDialog();
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Não foi necessário gerar uma nova fatura', color: 'success' });
					} else if (response.operation === 'refund' || response.operation === 'charge') {
						this.paymentChecked = {
							operation: response.operation,
							difference: response.difference,
							totalAmount: response.totalAmount,
							totalAmountPaid: response.totalAmountPaid
						};
						this.paymentChecked.paymentMethod = response.operation === 'refund' ? response.paymentMethod : undefined;
						this.dialog_status_fatura = true;
					} else {
						this.dialog_confirm_invoice = false;
						this.closeIuguDialog();
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Fatura criada com sucesso', color: 'success' });
					}
				})
				.catch(err => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: err.message, color: 'error' });
				})
				.finally(() => {
					this.loadingInvoice = false;
				});
		},
		buildParamsToCreateInvoice() {
			const address = this.assinatura.endereco;
			const customer = this.assinatura.paciente;
			const dueDate = this.vencimento;
			const expirationDate = moment().add(30, 'days').format('YYYY-MM-DD');

			const responsibleId = String(this.responsavel.id);

			const params = {
				invoice: {
					company_unit: getCurrentCompanyUnit().key,
					reference_type: this.cobrancaAvulsa ? typesEnum.PONTUAL : typesEnum.BOX,
					reference_id: this.cobrancaAvulsa ? null : String(this.box_id),
					due_date: this.$functions.FormatDateToRequest(dueDate),
					discount: Number(onlyNumber(this.totalDiscount)),
					expiration_date: expirationDate,
					customer: {
						external_id: String(customer.id),
						name: customer.nome,
						document: customer.cpf,
						document_type: documentTypeEnum.CPF,
						email: customer.email || this.responsavel.email,
						phone: customer.telefone
					},
					address: {
						street: address.logradouro,
						address_number: String(address.numero),
						neighborhood: address.bairro,
						city: address.cidade,
						state: getUFByStateName(address.estado),
						complement: address.complemento,
						zip_code: address.cep
					},
					payer: {
						external_id: responsibleId,
						name: this.responsavel.nome,
						document: this.responsavel.cpfCnpj,
						document_type: documentTypeEnum.CPF,
						email: this.responsavel.email,
						phone: this.responsavel.telefone
					},
					items: this.items.map(item => ({
						item_id: String(item.id),
						amount: Number(this.$functions.onlyNumber(item.preco)),
						quantity: item.quantidade,
						description: item.nome,
						discount: Number(this.$functions.onlyNumber(item.desconto))
					})),
					payments_config: [
						{
							method: paymentsMethod.creditCard,
							installments: 3,
							installment_value: 15000
						},
						{
							method: paymentsMethod.pix,
							installments: 1
						},
						{
							method: paymentsMethod.bankSlip,
							installments: 1
						}
					],
					observation: '',
					properties: { assinatura_id: this.assinatura_id }
				},
				proportionality: this.ehProporcional
			};

			if (this.box_id)
				params.box_id = this.box_id;

			return params;
		},
		updateOrCreateClienteIugu() {
			this.loading = true;
			AssinaturaPresenter.buscaOuCriaClienteIugu(this.assinatura.id).then(cliente => {
				this.assinatura.iugu_id = cliente.id;
				return AssinaturaPresenter.updateIuguId(this.assinatura.id, cliente.id);
			}).then(response => {
				this.$store.dispatch('assinatura/SET_IUGU_ID', this.iugu_id);
				this.$store.dispatch('SHOW_SNACKBAR', { message: response.message });
			}).finally(() => {
				this.loading = false;
				if (this.isFarmeFinanceEnable)
					this.dialog_confirm_invoice = true;
				else
					this.dialog_confirmar_fatura = true;
			});
		},
		async loadFirstBoxJornadaDiscount() {
			if (!this.isFirstBoxFromJornada)
				this.percent_discount_first_box = 0;

			const orcamento = await QuotationPresenter.show(this.assinatura.pedido_id);

			this.invoiceGateway = orcamento.invoice.gateway;
			this.percent_discount_first_box = orcamento ? orcamento.percentDiscountFirstBox || 0 : 0;
		},
		request(requestName, somenteCartaoDeCredito) {
			this.dialog_confirmar_fatura = false;
			this.loading = true;
			const params = {
				assinatura_id: this.assinatura_id,
				credit_card_only: somenteCartaoDeCredito,
				due_date: this.$functions.FormatDateToRequest(this.vencimento),
				proportionality: this.ehProporcional,
				canPayInInstallments: this.pagamentoEhParcelado,
				items: this.items.map(item => ({
					nome: item.nome,
					quantidade: item.quantidade,
					preco: Number(this.$functions.onlyNumber(item.preco)),
					desconto: Number(this.$functions.onlyNumber(item.desconto))
				}))
			};

			if (this.box_id)
				params.box_id = this.box_id;

			AssinaturaPresenter[requestName](params)
				.then(response => {
					if (response.url)
						window.open(response.url);
					else {
						this.paymentChecked = response;
						this.dialog_status_fatura = true;
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		calculatePricePerPills(price, discount, pills) {
			if (pills) {
				const priceWithDiscount = (Number(onlyNumber(price)) - Number(onlyNumber(discount)));

				const pricePerPills = calculatePricePerPillsOrPackage(priceWithDiscount, pills, this.ehProporcional);
				return pricePerPills;
			}
			return Number(onlyNumber(price)) - Number(onlyNumber(discount));
		}
	}
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__text {
	background: #f0f5f6;
}

::v-deep .label-bold label {
	font-weight: bold
}

.items-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.wrapper {
	display: flex;
	min-height: calc(100vh - 116px);
	justify-content: center;

	&.carregando {
		align-items: center;
	}

	.iugu-wrapper {
		overflow: auto;
		background: #fff;
		max-width: 1080px;
		border: 2px solid #1f59a2;
		border-radius: 5px;
		padding: 32px;
	}
}

::v-deep .tabela {
	border: 1px solid #ddd;
	border-radius: 5px;

	thead {
		th {
			font-weight: bold !important;
			color: #000 !important;
			font-size: 14px !important;
		}
	}
}
</style>
