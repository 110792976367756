export const medicineTypes = Object.freeze({
	REFERENCIA: 'REFERENCIA',
	SIMILAR: 'SIMILAR',
	GENERICO: 'GENERICO',
	BIOLOGICO: 'BIOLOGICO',
	NOVO: 'NOVO',
	FITOTERAPICO: 'FITOTERAPICO',
	ESPECIFICO: 'ESPECIFICO',
	OUTRO: 'OUTRO'
});

export const medicineTypesLabel = Object.freeze({
	REFERENCIA: 'Referência',
	SIMILAR: 'Similar',
	GENERICO: 'Genérico',
	BIOLOGICO: 'Biológico',
	NOVO: 'Novo',
	FITOTERAPICO: 'Fitoterápico',
	ESPECIFICO: 'Específico',
	OUTRO: 'Outro'
});
