export const CRUDMixin = {
	props: ['item_pai', 'custom_fields'],
	$_veeValidate: { validator: 'new' },
	data: () => ({
		item: {},
		campos: {},
		campos_externos: {},
		loading: false,
		salvando: false,
		excluindo: false,
		modal_excluir: false,
		requestShow: null,
		requestStore: null,
		requestUpdate: null,
		requestDestroy: null,
		requestRestore: null
	}),
	created() {
		this.setItem();
		import(`@Presenter/${this.type}-presenter`).then(Module => {
			const Presenter = Module[`${this.$functions.hyphensToCamel(this.type)}Presenter`];
			this.requestShow = Presenter.show;
			this.requestStore = Presenter.store;
			this.requestUpdate = Presenter.update;
			this.requestDestroy = Presenter.destroy;
			this.requestRestore = Presenter.restore;
			this.onPresenterLoaded();
		});
		if (this.fields_type) {
			import(`@Fields/${this.fields_type}-fields`).then(Fields => {
				this.campos = this.$lodash.merge(this.$lodash.cloneDeep(Fields.default), this.$lodash.cloneDeep(this.custom_field || {}));
			});
		}
	},
	methods: {
		onPresenterLoaded() {},
		createData() {
			return { ...this.item };
		},
		setItem() {
			this.item = this.item_pai ? this.$lodash.clone(this.item_pai) : {};
		},
		async submeter() {
			const is_form_valid = await this.$validator.validateAll();
			const extra_validation_message = await this.extraValidation();
			if (!is_form_valid || extra_validation_message) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: extra_validation_message || this.errors.all()[0] });
				return;
			}
			const data = this.createData();
			this.salvar(data);
		},
		extraValidation() {
			return new Promise(resolve => resolve(null));
		},
		salvar(data) {
			this.salvando = true;
			const $http = this.is_new ? this.requestStore : this.requestUpdate;
			$http(data)
				.then(response => {
					this.$emit('inserir', response);
					this.fechaModal();
				})
				.finally(() => {
					this.salvando = false;
				});
		},
		excluir() {
			this.excluindo = true;
			const request = this.item_pai.deleted_at ? this.requestRestore : this.requestDestroy;
			request(this.item_pai.id)
				.then(() => {
					this.modal_excluir = false;
					this.$emit('excluir', this.item_pai);
					this.fechaModal();
				})
				.finally(() => {
					this.excluindo = false;
				});
		},
		fechaModal() {
			this.$emit('update:close', false);
		}
	},
	computed: {
		messagem_dialog_excluir() {
			const deleted = !!(this.item_pai || {}).deleted_at;
			return {
				titulo: `${deleted ? 'Restaurar' : 'Excluir'} ${this.type}`,
				subtitulo: `Deseja ${deleted ? 'restaurar' : 'excluir'} o(a) ${this.type}?`,
				btn_acao: deleted ? 'Restaurar' : 'Excluir',
				btn_excluir: this.is_new ? null : (deleted ? 'Restaurar' : 'Excluir') // eslint-disable-line no-nested-ternary
			};
		},
		is_new() {
			return !(this.item_pai || {}).id;
		},
		habilitarBtnAcao() {
			const campos = this.campos_externos && Object.entries(this.campos_externos).length !== 0 ? this.campos_externos : this.campos;
			return Object
				.entries(campos)
				.map(([key, value]) => ({
					key, ...value
				}))
				.reduce((oldVal, newVal) => {
					const tem_valor = !!this.item[newVal.key];
					const is_required = (campos[newVal.key].validation || {}).required === true;
					const bloquear = is_required && !tem_valor;
					return oldVal && !bloquear;
				}, true);
		}
	}
};
