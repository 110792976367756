import estados from '@Consts/estados';

export default {
	logradouro: {
		label: 'Logradouro',
		validation: { required: true },
		size: 'xs12 md9'
	},
	numero: {
		label: 'Número',
		validation: { required: true },
		size: 'xs12 md3',
		type: 'text',
		min: '0'
	},
	bairro: {
		label: 'Bairro',
		validation: { required: true },
		size: 'xs12 md3'
	},
	cidade: {
		label: 'Cidade',
		validation: { required: true },
		size: 'xs12 md3'
	},
	estado: {
		label: 'Estado',
		component: 'select',
		validation: { required: true },
		size: 'xs12 md3',
		items: estados
	},
	complemento: {
		label: 'Complemento',
		size: 'xs12 md3'
	},
	observacao: {
		label: 'Observações do endereço',
		component: 'textarea',
		size: 'xs12 md12',
		type: 'text'
	}
};
