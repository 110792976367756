<template>
	<div class="px-md-5">
		<Header @novo-principio-ativo="crud" @busca="(b) => (busca = b)" />

		<v-card>
			<v-data-table
				:headers="headers"
				:items="items"
				:search="busca"
				:loading="is_loading"
				:rows-per-page-items="[10, 20, 30]"
				:no-data-text="error_message"
				no-results-text="Nenhum resultado encontrado"
				rows-per-page-text="Itens por página">
				<template slot="items" slot-scope="props">
					<tr class="pointer" @click="crud(props.item)">
						<td>{{ props.item.id }}</td>
						<td>{{ props.item.descricao }}</td>
						<td>
							{{ props.item.observacao }}
						</td>
						<td>
							<v-icon v-if="props.item.mpp" color="red">fas fa-check</v-icon>
							<v-icon v-else>fas fa-minus</v-icon>
							<v-tooltip right>
								<template v-slot:activator="{ on }">
									<v-icon color="red" v-if="props.item.mpp_observacao" v-on="on">fas fa-comment</v-icon>
								</template>
								<span>{{ props.item.mpp_observacao }}</span>
							</v-tooltip>
						</td>
						<td>
							<v-icon class="mr-2" color="warning" v-if="props.item.mpi">fas fa-check</v-icon>
							<v-icon v-else>fas fa-minus</v-icon>
							<v-tooltip right class="ml-2">
								<template v-slot:activator="{ on }">
									<v-icon
										color="warning"
										v-if="props.item.mpi_observacao"
										v-on="on">fas fa-comment</v-icon>
								</template>
								<span>{{ props.item.mpi_observacao }}</span>
							</v-tooltip>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog
			persistent
			v-model="dialog_crud"
			scrollable
			transition="fade"
			max-width="920px">
			<transition>
				<DialogPrincipioAtivo
					v-if="dialog_crud"
					:moleculas="moleculas"
					:item_pai="item_selecionado"
					:close.sync="dialog_crud"
					@inserir="salvo"/>
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { ListagemMixin } from '@Mixins/listagem';
import { MoleculaPresenter } from '@Presenter/molecula-presenter';
import DialogPrincipioAtivo from './dialog-principio-ativo';
import Header from './header';

export default {
	name: 'PrincipioAtivo',
	mixins: [ListagemMixin],
	components: {
		Header,
		DialogPrincipioAtivo
	},
	data: () => ({
		type: 'principio-ativo',
		moleculas: [],
		headers: [
			{ text: 'ID', value: 'id' },
			{ text: 'Nome', value: 'descricao' },
			{ text: 'Observações', value: 'observacao' },
			{ text: 'MPP', value: 'mpp' },
			{ text: 'MPI', value: 'mpi' }
		]
	}),
	methods: {
		index(index_props) {
			this.is_loading = true;
			const index = this.request || this.presenter.index;
			Promise.all([
				index(index_props || this.getIndexProps()),
				MoleculaPresenter.index()
			])
				.then(([principios_ativos, moleculas]) => {
					this.onResponse(principios_ativos);
					this.moleculas = moleculas;
				})
				.catch(() => { this.request_error = true; })
				.finally(() => { this.is_loading = false; });
		}
	}
};
</script>
