<template>
	<div>
		<v-container fluid grid-list-md class="pa-0">
			<v-layout wrap>
				<v-flex xs12 md6>
					<v-text-field v-model="orcamento.nome" box hide-details label="Nome *" data-vv-name="nome"
						v-validate="{ required: true }" :error-messages="errors.collect('nome')" />
				</v-flex>
				<v-flex xs12 md6>
					<v-text-field v-model="orcamento.cpf" box hide-details label="CPF" mask="###.###.###-##"
						v-validate="{ cpf: true }" data-vv-name="cpf" :error-messages="errors.first('cpf')" />
				</v-flex>
				<v-flex xs12 md6>
					<v-text-field v-model="orcamento.telefone" v-mask="'(##) #####-####' || '(##) ####-####'"
						box hide-details :label="`Telefone ${!eh_administrador ? '*' : ''}`" data-vv-name="telefone" v-validate="{ required: !eh_administrador, telefone: true }"
						:error-messages="errors.collect('telefone')" />
				</v-flex>
				<v-flex xs12 md6>
					<v-text-field v-model="orcamento.email" box hide-details :label="`E-mail ${!eh_administrador ? '*' : ''}`" data-vv-name="email"
						v-validate="{ required: !eh_administrador, email: true }" :error-messages="errors.collect('email')" />
				</v-flex>
				<template v-if="visao_colaborador">
					<v-flex xs12 md6>
						<v-text-field box hide-details label="Valor do serviço" v-model="orcamento.valor_servico" v-money />
					</v-flex>
					<v-flex xs12 md6 v-if="visao_colaborador">
						<div class="box" @click="dialog_tags = true">
							<label class="ml-2 v-label" :class="{ 'with-items': (orcamento.tags || []).length > 0 }">
								Tags
							</label>
							<v-chip v-for="tag in orcamento.tags" :key="tag.id" disabled class="purple white--text">
								{{ tag.nome }}
							</v-chip>
						</div>
					</v-flex>
					<v-flex xs12 md6>
						<TextDateField v-model="orcamento.data_validade" box hideDetails label="Validade" placeholder="dd/mm/yyyy"
							:disabled="!!orcamento.id" />
					</v-flex>
				</template>
				<v-flex v-if="eh_colaborador && !is_new" xs12>
					<v-select box hide-details label="Situação" v-model="orcamento.status" :items="lista_status"
						:color="$functions.getStatusOrcamento(orcamento.status).class" />
				</v-flex>
				<v-flex xs12 :class="{ 'order-xs2': $vuetify.breakpoint.smAndDown }">
					<v-textarea v-model="orcamento.observacao" box hide-details label="Observação / Medicamento não encontrado" />
				</v-flex>
				<v-flex xs12 v-if="!visao_colaborador">
					<v-select box hide-details label="Como você conheceu a Far.me?" v-model="orcamento.indicacao"
						:items="lista_indicacoes" data-vv-name="Como você conheceu a Far.me?"
						v-validate="{ required: true }" :error-messages="errors.collect('Como você conheceu a Far.me?')" />
				</v-flex>
				<v-flex xs12 v-if="visao_colaborador">
					<v-switch v-model="orcamento.sem_box" hide-details label="Sem box" />
				</v-flex>
				<v-flex v-if="$vuetify.breakpoint.smAndUp" xs12>
					<v-divider class="my-2" />
				</v-flex>
				<v-flex xs12>
					<div v-if="orcamento.medicamentos.length === 0" class="text-xs-center">
						Você precisa escolher pelo menos um medicamento para criar um orçamento.
					</div>
					<div v-else>
						<div v-for="(item, index) in orcamento.medicamentos" :key="index" class="medicamento pa-2 mb-3">
							<v-layout align-center>
								<v-flex class="informacao">
									<h4 class="text--text">{{ item.principio_ativo }}</h4>
									<h3 class="teal--text text--darken-2">{{ item.produto }}</h3>
									<p class="mb-0 grey--text text--darken-2">{{ item.apresentacao }}</p>
									<v-chip small class="info white--text ma-0 mt-1" slot="activator" v-if="eh_colaborador">
										{{ (item.preco_com_desconto) | dinheiro }}
									</v-chip>
								</v-flex>
								<v-flex style="max-width: max-content">
									<v-btn @click="escolherMedicamento(item)" small icon class="ma-0 pa-0">
										<v-icon color="green" small>edit</v-icon>
									</v-btn>
									<v-btn @click="confirmarExcluirMedicamento(index)" small icon class="ma-0 pa-0">
										<v-icon color="error" small>close</v-icon>
									</v-btn>
								</v-flex>
							</v-layout>
						</div>
					</div>
				</v-flex>
				<v-flex xs12 class="text-xs-center">
					<v-btn @click="escolherMedicamento({})" depressed class="green white--text" small>
						<v-icon small class="mr-2">add</v-icon>
						Adicionar medicamento
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>

		<v-dialog persistent v-model="dialog_excluir_medicamento" scrollable transition="fade"
			max-width="400px">
			<transition>
				<Dialog v-if="dialog_excluir_medicamento" titulo="Remover medicamento" subtitulo="Tem certeza que deseja remover o medicamento?"
					btn_acao="Excluir" btn_acao_class="error" btn_cancelar="Cancelar" @acao="excluirMedicamento"
					@close="dialog_excluir_medicamento = false" />
			</transition>
		</v-dialog>

		<DialogMedicamento v-if="dialog_medicamento" :visible="dialog_medicamento" :close.sync="dialog_medicamento" :permiteTrocarMedicamento="true"
			:medicamento_selecionado="medicamento_selecionado" :medicamentos="orcamento.medicamentos" :eh_orcamento="true" />

		<v-dialog v-model="dialog_tags" persistent scrollable transition="fade" max-width="840px">
			<transition>
				<DialogTags v-if="dialog_tags" :tags="orcamento.tags"
					@novas-tags="tags => orcamento.tags = tags" @close="dialog_tags = false" />
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import DialogTags from '@Componentes/dialog-tags';
import DialogMedicamento from '@Componentes/medicamento/dialog-medicamento';
import { ConfiguracaoPresenter } from '@Presenter/configuracao-presenter';
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';
import moment from 'moment';

export default {
	name: 'orcamento',
	$_veeValidate: { validator: 'new' },
	components: { DialogMedicamento, DialogTags },
	data: () => ({
		orcamento: {
			nome: null,
			cpf: null,
			email: null,
			sem_box: false,
			valor_servico: 0,
			tags: [],
			data_validade: moment().add(30, 'days').format('DD/MM/YYYY'),
			medicamentos: [],
			indicacao: ''
		},
		dialog_tags: false,
		dialog_medicamento: false,
		medicamento_selecionado: {},
		dialog_excluir_medicamento: false,
		lista_status: [
			{ value: 1, text: 'Em análise' },
			{ value: 2, text: 'Aprovado' },
			{ value: 3, text: 'Cancelado' }
		],
		lista_indicacoes: [
			{ value: 'Na Casa de Repouso do meu familiar', text: 'Na Casa de Repouso do meu familiar' },
			{ value: 'Indicação', text: 'Indicação' },
			{ value: 'Redes Sociais', text: 'Redes Sociais' },
			{ value: 'Google', text: 'Google' },
			{ value: 'Parceria Dasa', text: 'Parceria Dasa' },
			{ value: 'Parceria Amil', text: 'Parceria Amil' }
		]
	}),
	computed: {
		is_new() {
			return !this.orcamento.id;
		},
		eh_administrador() {
			return this.$store.getters.eh_administrador;
		},
		visao_colaborador() {
			return this.$store.getters.visao_colaborador;
		},
		eh_colaborador() {
			return this.$store.getters.eh_colaborador;
		},
		vendedor() {
			if (this.visao_colaborador) {
				return {
					...this.$store.getters.contexto,
					vendedor_id: this.$store.getters.contexto.user.id
				};
			}
			return {};
		}
	},
	created() {
		ConfiguracaoPresenter.show()
			.then(configuracao => {
				if (!this.orcamento.id)
					this.orcamento.valor_servico = configuracao.valor_servico || 0;
			});
	},
	methods: {
		setOrcamento(orcamento) {
			this.orcamento = this.$lodash.cloneDeep(orcamento);
		},
		getOrcamento() {
			return this.$lodash.cloneDeep(this.orcamento);
		},
		createData() {
			const medicamentos = this.orcamento.medicamentos.map(med => ({
				id: med.id,
				posologia: med.posologia ? JSON.stringify(med.posologia) : null,
				preco_personalizado: med.preco_personalizado ? med.preco_personalizado : null,
				qtd_caixas: med.qtd_caixas ? Number(med.qtd_caixas) : null,
				desconto: med.desconto
			}));
			const orcamento = {
				...this.orcamento,
				data_validade: moment(this.orcamento.data_validade, 'DD/MM/YYYY').format('YYYY-MM-DD'),
				casa_repouso_id: this.orcamento.casa_repouso_id || null,
				telefone: this.$functions.onlyNumber(this.orcamento.telefone),
				valor_servico: Number(this.$functions.onlyNumber(this.orcamento.valor_servico)),
				vendedor_id: this.vendedor.vendedor_id || null,
				medicamentos
			};
			if (this.$store.getters.contexto)
				orcamento.responsavel_id = this.$store.getters.paciente.responsaveis[0].id;
			return orcamento;
		},
		async submeter() {
			const is_valid = await this.$validator.validateAll();
			if (!is_valid) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
				return;
			}
			if (!this.orcamento.medicamentos.length) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Você deve inserir pelo menos um medicamento' });
				return;
			}
			const data = this.createData();
			this.salvar(data);
		},
		salvar(data) {
			if (!this.eh_colaborador)
				this.$gtag.event('salvar_orcamento');

			data.timer = (Date.now() - this.timeStart);
			const $http = this.is_new ? OrcamentoPresenter.store : OrcamentoPresenter.update;
			this.$emit('loading', true);
			$http(data)
				.then(response => {
					this.$emit('saved', response);
				})
				.finally(() => {
					this.$emit('loading', false);
				});
		},
		escolherMedicamento(_medicamento) {
			if (!this.eh_colaborador)
				this.$gtag.event('adicionar_medicamento_orcamento');

			const medicamento = this.$lodash.cloneDeep(_medicamento);
			this.medicamento_selecionado = medicamento;
			this.dialog_medicamento = true;
		},
		confirmarExcluirMedicamento(index) {
			this.index_selecionado = index;
			this.dialog_excluir_medicamento = true;
		},
		excluirMedicamento() {
			this.orcamento.medicamentos.splice(this.index_selecionado, 1);
			this.dialog_excluir_medicamento = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.v-input--switch {
	margin: 0;
	padding-top: 8px;
	justify-content: flex-end;
}

.medicamento {
  margin-top: 12px;
  border-radius: 5px;
  transition: background 0.3s;

  &:hover {
    background: rgba(#000, 0.06);
  }

  .informacao {
    overflow: hidden;

    h4 {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 12px;
      overflow: hidden;
      text-overflow: ellipsis !important;
    }

    h3 {
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis !important;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis !important;
    }
  }
}
</style>
