export const paymentMethodMap = Object.freeze({
	pix: 'pix',
	bank_slip: 'bank_slip',
	credit_card: 'credit_card'
});

export const paymentMethodDescriptionMap = Object.freeze({
	pix: 'Pix',
	bank_slip: 'Boleto',
	credit_card: 'Cartão de crédito'
});

export function getPaymentMethodDescription(paymentMethod) {
	return paymentMethodDescriptionMap[paymentMethod];
}
