<template>
	<div class="px-md-5">

		<HeaderProcesso
			titulo="Faturamento"
			:subtitulo="`Faça o faturamento ${tabSelected==='boxesTab' ? 'das boxes' : 'dos pedidos'} pelo sistema BIG.`"
			:subtitulo2="`Aqui estão listadas ${tabSelected==='boxesTab' ? 'as boxes' : 'os pedidos'} que saíram de picking.`"
			@busca="val => busca = val" />

		<TabsDataTable
			:boxesLength="boxes.length"
			:ordersLength="ordersProcessTotal"
			:tabSelected="tabSelected"
			@switchTab="val => switchTab(val)"/>

		<!-- Conteúdo -->
		<DataTableProcesso
			v-if="tabSelected==='boxesTab'"
			:boxes="boxes"
			:loading="carregando"
			:search="busca"
			:buttons="buttons"
			@selected-updated="arr => selecionados = arr" />

		<DataTablePedidos
			v-if="tabSelected==='ordersTab'"
			:orders="orders"
			:loading="loadingOrders"
			:buttons="orderButtons"
			:total-items="ordersProcessTotal"
			@pagination-updated="val => getOrdersProcess(val)"
			@selected-updated="arr => selectedOrders = arr"/>

		<!-- Dialog upload -->
		<v-dialog persistent scrollable v-model="dialog_upload" transition="fade" max-width="920px">
			<transition>
				<DialogUpload v-if="dialog_upload" :box_id="item_selecionado.box_id" :close.sync="dialog_upload" />
			</transition>
		</v-dialog>

		<!-- Dialog rastreabilidade -->
		<v-dialog persistent scrollable v-model="openTraceabilityDialog" transition="fade" max-width="920px">
			<transition>
				<TraceabilityDialog
					v-if="openTraceabilityDialog"
					:boxId="item_selecionado.box_id"
					:traceability="traceability"
					:close.sync="openTraceabilityDialog"/>
			</transition>
		</v-dialog>

		<!-- Dialog receitas -->
		<v-dialog persistent scrollable v-model="openedPrescriptionsDialog" transition="fade" max-width="920px">
			<transition>
				<PrescriptionsDialog
					v-if="openedPrescriptionsDialog"
					:boxId="item_selecionado.box_id"
					:close.sync="openedPrescriptionsDialog"/>
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_upload_order" transition="fade"
			max-width="920px">
			<transition>
				<DialogUploadOrder v-if="dialog_upload_order && item_selecionado" @changeOrderNF="metadata => orderNF = metadata"
					:request="changeStatusProcess()" :order_id="item_selecionado.id" :close.sync="dialog_upload_order" :customer="item_selecionado.customer" />
			</transition>
		</v-dialog>

		<!-- Dialog Enviar Produção -->
		<v-dialog persistent scrollable v-model="dialog_enviar_producao" transition="fade" max-width="500px">
			<transition>
				<DialogConfirmacao v-if="dialog_enviar_producao" @close="dialog_enviar_producao = false" :request="updateStatus('BILLED')" :opcoes="[
					'Confirmo que faturei a box corretamente'
				]" />
			</transition>
		</v-dialog>

		<!-- Dialog Enviar Produção -->
		<v-dialog persistent scrollable v-model="dialog_enviar_expedicao" transition="fade" max-width="500px">
			<transition>
				<DialogConfirmacao v-if="dialog_enviar_expedicao" @close="dialog_enviar_expedicao = false" :request="updateStatus('CHECKED')" :opcoes="[
					'Confirmo que esta assinatura não possui box',
					'Confirmo que fiz o faturamento corretamente'
				]" />
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { ProcessoMixin } from '@Mixins/processo';
import DialogUpload from '@Componentes/dialog-upload-nota';
import DialogUploadOrder from '@Componentes/dialog-upload-nota/order';
import TabsDataTable from '@Componentes/tabs-data-table.vue';
import { OrderProcessMixin } from '@Mixins/order-process';
import { ProcessTypes } from '@Consts/status-order-process';
import { BoxPresenter } from '@Presenter/box-presenter';
import TraceabilityDialog from './traceability-dialog.vue';
import PrescriptionsDialog from './prescriptions-dialog.vue';

export default {
	name: 'Faturamento',
	components: {
		DialogUpload, TabsDataTable, DialogUploadOrder, TraceabilityDialog, PrescriptionsDialog
	},
	mixins: [ProcessoMixin, OrderProcessMixin],
	data: () => ({
		statusToShow: ['PICKED'],
		processName: ProcessTypes.BILLING,
		dialog_upload: false,
		dialog_upload_order: false,
		openTraceabilityDialog: false,
		openedPrescriptionsDialog: false,
		traceability: [],
		dialog_enviar_producao: false,
		dialog_enviar_expedicao: false,
		orderNF: {}
	}),
	created() {
		this.buttons = [
			{
				label: 'Receitas',
				icon: 'fas fa-file-medical',
				onClick: item => this.openPrescriptionsDialog(item),
				condition: item => !!item.info_receitas.someControlledHasPrescription
			},
			{
				label: 'Rastreabilidade',
				icon: 'fas fa-file-upload',
				onClick: item => this.openDialogUploadOrTraceability(item)
			},
			{
				label: 'Enviar para produção',
				icon: 'inventory_2',
				onClick: item => this.abrirDialog(item, 'dialog_enviar_producao'),
				condition: item => !item.sem_box
			},
			{
				label: 'Enviar para expedição',
				icon: 'fas fa-boxes',
				onClick: item => this.abrirDialog(item, 'dialog_enviar_expedicao'),
				condition: item => item.sem_box
			}
		];

		this.orderButtons = [
			{
				label: 'Enviar nota fiscal',
				icon: 'fas fa-file-upload',
				onClick: item => this.abrirDialog(item, 'dialog_upload_order')
			}
		];
	},
	methods: {
		changeStatusProcess() {
			return this.updateOrderProcess(ProcessTypes.BILLING, 'SUCCESS', { nfData: this.orderNF });
		},
		async openDialogUploadOrTraceability(item) {
			this.traceability = await BoxPresenter.getBoxTraceability(item.box_id);

			if (this.traceability.length)
				this.abrirDialog(item, 'openTraceabilityDialog');
			else
				this.abrirDialog(item, 'dialog_upload');
		},
		async openPrescriptionsDialog(item) {
			this.abrirDialog(item, 'openedPrescriptionsDialog');
		}
	}
};
</script>
