<template>
	<Dialog
		titulo="Cancelar pedido"
		:subtitulo="subtitulo"
		btn_acao="Confirmar"
		btn_cancelar="Cancelar"
		:loading="loading"
		:btn_acao_disable="disableActionBtn"
		@acao="confirm"
		@close="close">
		<span class="font-weight-medium">
			Estou ciente de que ao cancelar este pedido não será possível restaurar e e ele sairá da linha de produção e entrega.
		</span>

		<v-container fluid grid-list-lg class="pa-0 mt-3">
			<v-layout wrap>
				<v-flex xs12>
					<v-textarea
						v-model="justification"
						label="Descreva a justificativa"
						:rules="[rules.required]"/>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
export default {
	name: 'CancelOrderModal',
	props: {
		loading: {
			type: Boolean,
			default: false
		},
		subtitulo: {
			type: String
		}
	},
	data() {
		return {
			justification: '',
			rules: {
				required: v => !!v || 'Campo obrigatório'
			}
		};
	},
	computed: {
		disableActionBtn() {
			return !this.justification;
		}
	},
	watch: {
		justification: {
			handler(val) {
				this.$emit('justification', val);
			},
			deep: true
		}
	},
	methods: {
		confirm() {
			this.$emit('confirm');
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>
