<template>
	<v-card style="background-color: #FFF3E2" class="dark--text py-5 px-4" >
		<v-btn flat class="ml-auto" icon @click="fecharModal()">
			<v-icon color="dark" size="10">fas fa-times</v-icon>
		</v-btn>

		<v-container class="text-center">

			<v-flex>
				<v-avatar v-if="operation === ''" color="rgba(45, 206, 113, 0.2)" size="80" class="mr-2 mb-4">
					<v-icon color="success" size="40">fas fa-check</v-icon>
				</v-avatar>

				<v-avatar v-else color="primary" size="80" class="mr-2 mb-4">
					<v-icon color="secondary" size="40">fas fa-exclamation</v-icon>
				</v-avatar>
			</v-flex>

			<v-flex class="my-3">
				<span style="font-weight: bold" v-html="title"></span>

				<p class="my-2">{{subtitle}}</p>
			</v-flex>

			<v-flex v-if="operation">
				<table width="100%">
					<thead>
						<tr>
							<th class="pa-1">Forma</th>
							<th>Total</th>
							<th>Pago</th>
							<th>Diferença</th>
						</tr>
					</thead>
					<tbody>
						<tr style="font-weight: bold;">
							<td class="pa-3">
								<div style="display: flex; justify-content: center;">
									<img :src="getPaymentMethodPathIcon" alt="Ícone do meio de pagamento" height="20px" class="mr-2">

									{{ getPaymentMethodDescription(paymentMethod) }}
								</div>
							</td>
							<td>{{ totalAmount | dinheiro }}</td>
							<td>{{ totalAmountPaid | dinheiro }} </td>
							<td>
								<span :class="[operation === 'refund' ? 'error--text' : 'success--text']">
									{{ difference | dinheiro }}
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</v-flex>

			<v-btn @click="fecharModal()" style="width: 50%" class="mx-auto mt-5 primary">
				Fechar
			</v-btn>

		</v-container>
	</v-card>
</template>

<script>
import pixIcon from '@Assets/icons/pix.svg';
import bankSlipIcon from '@Assets/icons/bank_slip.svg';
import creditCardIcon from '@Assets/icons/credit_card.svg';
import invoiceIcon from '@Assets/icons/invoice.svg';
import { getPaymentMethodDescription as getPaymentMethodDescriptionFunction, paymentMethodMap } from '@Consts/payment-methods';
import { mascaraDinheiro } from '@Util/functions';

export default {
	name: 'DialogStatusFatura',
	props: {
		totalAmount: {
			required: true,
			type: Number
		},
		totalAmountPaid: {
			required: true,
			type: Number
		},
		difference: {
			required: true,
			type: Number
		},
		operation: {
			required: true,
			type: String,
			validator: val => ['', 'refund', 'charge'].includes(val)
		},
		paymentMethod: {
			required: false,
			type: String,
			validator: val => Object.keys(paymentMethodMap).includes(val)
		}
	},
	data: () => ({}),
	computed: {
		title() {
			return this.operation !== ''
				? 'Diferença de valores identificada'
				: 'Não foi encontrada diferença de <br> valores na realização da cobrança.';
		},
		subtitle() {
			if (this.operation === '')
				return 'Nenhum processo adicional necessário';

			if (this.operation === 'charge')
				return `Uma nova cobrança de ${mascaraDinheiro(Math.abs(this.difference))} foi realizada com sucesso.`;

			if (this.operation === 'refund')
				return `Um estorno de ${mascaraDinheiro(Math.abs(this.difference))} foi realizado com sucesso.`;

			throw new Error('Problemas ao identificar cobrança ou estorno');
		},

		getPaymentMethodPathIcon() {
			let resp;
			if (!this.paymentMethod)
				resp = invoiceIcon;
			else {
				const iconsMap = {
					[paymentMethodMap.pix]: pixIcon,
					[paymentMethodMap.bank_slip]: bankSlipIcon,
					[paymentMethodMap.credit_card]: creditCardIcon
				};
				resp = iconsMap[this.paymentMethod];
			}

			return resp;
		}
	},
	methods: {
		getPaymentMethodDescription(paymentMethod) {
			return getPaymentMethodDescriptionFunction(paymentMethod);
		},
		fecharModal() {
			this.$emit('close', true);
		}
	}
};
</script>

<style lang="scss" scoped>
table, th, td {
	border: 2px solid #FFC875;
	border-collapse: collapse;
}
</style>
