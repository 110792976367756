<template>
	<div class="paciente-wrapper px-md-5">
		<div v-if="carregando" class="carregando">
			<v-progress-circular width="3" indeterminate />
		</div>

		<Header v-if="!carregando" :responsavel="responsavel" @editar-responsavel="dialog_responsavel = true" />

		<v-container v-if="!carregando" grid-list-md class="pa-0">
			<v-layout wrap>
				<v-flex xs12 md12>
					<v-card>
						<v-card-title>
							<v-avatar size="38" class="mr-3">
								<v-icon color="white" small>payment</v-icon>
							</v-avatar>
							<h3>Responsável por: </h3>
							<v-spacer />
							<v-progress-circular v-if="carregando_farmembros" indeterminate color="grey" />
							<span v-else-if="meus_farmembros.length === 0">
								Esse responsável não possui Farmembros.
							</span>
						</v-card-title>

						<v-layout wrap row :class="meus_farmembros.length < 4 ? 'layout-box-wrapper' : null" style="margin: 5px">
							<v-flex xs12 md4 v-for="(farmembro, index) in meus_farmembros" :key="index">
								<v-card class="farmembros" @click.native="() => farmembroSelecionado(farmembro)">
									<v-card-title class="green-title" />

									<v-card-text class="conteudo">
										<v-layout align-center justify-start>

											<v-flex class="flex-content pr-1" style="margin-left: -50px;">
												<v-avatar size="70">
													<span class="white--text headline">{{ farmembro.nome.charAt(0) }}</span>
												</v-avatar>
											</v-flex>

											<v-flex style="margin-right: 50px; padding-right: 50px">
												<h3 class="mb-1">Farmembro: {{ farmembro.nome }}</h3>
											</v-flex>

										</v-layout>
									</v-card-text>
								</v-card>
							</v-flex>
						</v-layout>
					</v-card>
					<v-flex xs12 class="text-xs-center mt-4" v-if="paginas > 1">
						<v-pagination circle v-model="pagina_atual" color="purple" :length="paginas" @input="novaPagina"
							:total-visible="6" />
					</v-flex>
				</v-flex>
			</v-layout>
		</v-container>

		<!-- Dialog responsavel -->
		<v-dialog persistent scrollable v-model="dialog_responsavel" transition="fade" max-width="1070px">
			<transition>
				<DialogResponsavel
					v-if="dialog_responsavel"
					:item_pai="responsavel"
					:close.sync="dialog_responsavel"
					@inserir="atualizarPagina"
					@excluir="excluido"/>
			</transition>
		</v-dialog>
		<!-- Fim, dialog responsavel -->
	</div>
</template>

<script>
import { ResponsavelPresenter } from '@Presenter/responsavel-presenter';
import DialogResponsavel from '../dialog-responsavel';
import Header from './header';

export default {
	name: 'Responsavel',
	components: {
		Header,
		DialogResponsavel
	},
	data: () => ({
		carregando: true,
		dialog_responsavel: false,
		responsavel: {},
		carregando_farmembros: false,
		meus_farmembros: [],
		paginas: 0,
		pagina_atual: 0,
		total: 0,
		itens_por_pagina: 6
	}),
	created() {
		this.getResponsavel();
		this.getFarmembros();
	},
	methods: {
		getResponsavel() {
			this.carregando = true;
			ResponsavelPresenter.show(this.$route.params.id)
				.then(resp => {
					this.responsavel = resp;
				})
				.finally(() => {
					this.carregando = false;
				});
		},
		getFarmembros() {
			this.carregando_farmembros = true;
			ResponsavelPresenter.listFarmembros(this.$route.params.id, { pagina_atual: this.pagina_atual, itens_por_pagina: this.itens_por_pagina })
				.then(response => {
					this.meus_farmembros = response.itens;
					this.paginas = response.paginas;
					this.total = response.total;
				})
				.finally(() => {
					this.carregando_farmembros = false;
				});
		},
		atualizarPagina() {
			this.getResponsavel();
		},
		excluido() {
			this.$router.push({ name: 'Responsaveis' });
		},
		novaPagina(pagina) {
			this.pagina_atual = pagina;
			this.index();
		},
		farmembroSelecionado(farmembro) {
			const { id } = farmembro;
			this.$router.push({ name: 'Paciente', params: { id } });
		}
	}
};
</script>

<style lang="scss" scoped>
.carregando {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 88px);
}

::v-deep .avatar-transparent {
  .v-avatar {
    background: transparent !important;
  }
}

.farmembros {
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 10px 100px rgba(#000, 0.14) !important;
  }

  .green-title {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    background-position-y: bottom;
    filter: brightness(120%);
    position: relative;
    z-index: 0;
  }

  .conteudo.v-card__text {
    background: #fff;
    border-radius: 10px 0 0 10px;
    margin-left: 50px;
    position: relative;
    z-index: 1;

    .flex-column {
      display: flex;
      flex-direction: column;
    }

    .flex-content {
      max-width: max-content;

      .v-avatar {
        overflow: hidden;

        img {
          width: 101%;
          height: 101%;
        }
      }
    }

    .actions-absolute {
      position: absolute;
      right: 50px;
    }

    h3 {
      font-size: 16px;
    }
  }
}
</style>
