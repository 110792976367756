<template>
	<header>
		<v-layout wrap align-content-space-between class="mb-4">
			<v-flex xs12 md9>
				<h1 class="text--text" style="opacity: .8">Estoque</h1>
				<p>Lista do estoque de medicamentos dos pacientes Far.me.</p>
				<v-btn depressed class="primary mx-0 pl-2 pr-3" @click="$emit('baixar-planilha')">
					<v-icon small class="mr-2">add</v-icon>
					Baixar planilha
				</v-btn>
			</v-flex>

			<v-flex xs12 md3 align-self-center>
				<v-text-field label="Buscar paciente" placeholder="Nome do paciente" append-icon="search"
					color="purple" @input="value => $emit('busca', value)" />
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
export default {
	name: 'RelatorioEstoquePacienteHeader'
};
</script>
