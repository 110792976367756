<template>
	<Dialog titulo="Medicamentos ABC Farma" btn_cancelar="Cancelar"
		btn_acao="OK" @acao="$emit('medicamento-selected', medicamento)" @close="$emit('close')" >
		<v-container fluid grid-list-lg>
			<v-layout wrap>
				<v-flex class="xs12">
					<v-autocomplete
						box
						color="green"
						v-model="model"
						:loading="loading"
						label="Pesquisa"
						item-value="id"
						item-text="nome"
						:items="medicamentos"
						:return-object="true"
						:hide-details="true"
						@input="show" />
				</v-flex>
			</v-layout>
			<Form v-if="medicamento" style="margin-top:8px" :item="medicamento" :campos="medicamentos_fields" disabled >
				<v-flex xs8>
					<v-combobox v-model="medicamento.eans" :items="[]" class="ean" placeholder="EAN" color="green"
						multiple chips box flat disabled
						hide-details>
						<template v-slot:selection="data">
							<v-chip
								:selected="data.selected"
								close
								@input="deleteEAN(data.item)">
								<strong>{{ data.item }}</strong>
							</v-chip>
						</template>
					</v-combobox>
				</v-flex>
			</Form>
		</v-container>
	</Dialog>
</template>

<script>
import { MedicamentoAbcFarmaPresenter } from '@Presenter/medicamento-abcfarma-presenter';
import medicamentos_fields from '@Fields/medicamento-fields';

export default {
	name: 'DialogAbcFarma',
	data: () => ({
		medicamentos_fields,
		model: null,
		eans: [],
		medicamento: null,
		medicamentos: [],
		loading: false
	}),
	created() {
		this.search();
	},
	methods: {
		search() {
			this.loading = true;
			MedicamentoAbcFarmaPresenter.index()
				.then(response => {
					this.medicamentos = response;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		show() {
			MedicamentoAbcFarmaPresenter.show(this.model.id)
				.then(response => {
					delete response.id;
					this.medicamento = response;
					if (response.ean) {
						this.medicamento.eans = [response.ean];
						delete response.ean;
					}
				});
		}
	}
};
</script>

<style>

</style>
