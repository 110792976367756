<template>
	<Dialog :titulo="titulo" :subtitulo="subtitulo" btn_acao="Concluir"
		:btn_excluir="editandoHorario ? 'Excluir' : ''" @acao="acao" @excluir="excluir" @close="$emit('close')">
		<div style="margin: -16px">
			<v-tabs v-model="tab" color="green" dark grow slider-color="purple_strong">
				<v-tab>Dia</v-tab>
				<v-tab>Noite</v-tab>
				<v-tab>Outros</v-tab>
				<v-tab-item class="pa-3">
					<v-btn small depressed round v-for="(hor, index) in horarios_dia" :key="index"
						@click="handleHorarios(hor)"
						:class="hor.ativo ? 'primary' : null" class="mx-1">
						{{ hor.horario }}
					</v-btn>
				</v-tab-item>
				<v-tab-item class="pa-3">
					<v-btn small depressed round v-for="(hor, index) in horarios_noite" :key="index"
						@click="handleHorarios(hor)"
						:class="hor.ativo ? 'primary' : null" class="mx-1">
						{{ hor.horario }}
					</v-btn>
				</v-tab-item>
				<v-tab-item class="pa-3">
					<v-btn small depressed round v-for="(hor, index) in horarios_outros" :key="index"
						@click="handleHorarios(hor)"
						:class="hor.ativo ? 'primary' : null" class="mx-1">
						{{ hor.horario }}
					</v-btn>
				</v-tab-item>
			</v-tabs>
		</div>
	</Dialog>
</template>

<script>
export default {
	name: 'DialogAdicionarHorario',
	props: {
		horarios: {
			type: Array,
			required: true
		},
		horario: {
			type: Object,
			default: null
		}
	},
	data: () => ({
		tab: null,
		novos_horarios: [],
		horarios_dia: [
			{ horario: '06:00', ativo: false }, { horario: '06:30', ativo: false }, { horario: '07:00', ativo: false }, { horario: '07:30', ativo: false }, { horario: '08:00', ativo: false }, { horario: '08:30', ativo: false }, { horario: '09:00', ativo: false }, { horario: '09:30', ativo: false },
			{ horario: '10:00', ativo: false }, { horario: '10:30', ativo: false }, { horario: '11:00', ativo: false }, { horario: '11:30', ativo: false }, { horario: '12:00', ativo: false }, { horario: '12:30', ativo: false }, { horario: '13:00', ativo: false }, { horario: '13:30', ativo: false },
			{ horario: '14:00', ativo: false }, { horario: '14:30', ativo: false }, { horario: '15:00', ativo: false }, { horario: '15:30', ativo: false }, { horario: '16:00', ativo: false }, { horario: '16:30', ativo: false }, { horario: '17:00', ativo: false }, { horario: '17:30', ativo: false }
		],
		horarios_noite: [
			{ horario: '18:00', ativo: false }, { horario: '18:30', ativo: false }, { horario: '19:00', ativo: false }, { horario: '19:30', ativo: false }, { horario: '20:00', ativo: false }, { horario: '20:30', ativo: false }, { horario: '21:00', ativo: false }, { horario: '21:30', ativo: false },
			{ horario: '22:00', ativo: false }, { horario: '22:30', ativo: false }, { horario: '23:00', ativo: false }, { horario: '23:30', ativo: false }, { horario: '00:00', ativo: false }, { horario: '00:30', ativo: false }, { horario: '01:00', ativo: false }, { horario: '01:30', ativo: false },
			{ horario: '02:00', ativo: false }, { horario: '02:30', ativo: false }, { horario: '03:00', ativo: false }, { horario: '03:30', ativo: false }, { horario: '04:00', ativo: false }, { horario: '04:30', ativo: false }, { horario: '05:00', ativo: false }, { horario: '05:30', ativo: false }
		],
		horarios_outros: [
			{ horario: 'Jejum', ativo: false }, { horario: 'Após o café da manhã', ativo: false }, { horario: 'Manhã', ativo: false }, { horario: 'Antes do almoço', ativo: false }, { horario: 'Após o almoço', ativo: false }, { horario: 'Tarde', ativo: false }, { horario: 'Noite', ativo: false }, { horario: 'Antes do jantar', ativo: false }, { horario: 'Após jantar', ativo: false }, { horario: 'Ao deitar', ativo: false }
		]
	}),
	computed: {
		editandoHorario() {
			return !!this.horario;
		},
		titulo() {
			if (this.editandoHorario)
				return 'Edite seu horário';
			return 'Escolha os horários';
		},
		subtitulo() {
			return !this.editandoHorario ? 'Selecione os horários e confirme.' : '';
		}
	},
	created() {
		if (this.editandoHorario)
			this.novos_horarios = [this.horario.horario];
		else
			this.novos_horarios = this.horarios.map(hor => hor.horario);
		this.novos_horarios.forEach(hor => {
			this.horarios_dia.forEach(dia => {
				if (dia.horario === hor)
					dia.ativo = true;
			});
			this.horarios_noite.forEach(noite => {
				if (noite.horario === hor)
					noite.ativo = true;
			});
			this.horarios_outros.forEach(outro => {
				if (outro.horario === hor)
					outro.ativo = true;
			});
		});
	},
	methods: {
		handleHorarios(hor) {
			if (this.editandoHorario && !hor.ativo) {
				this.horarios_dia.forEach(horario => {
					horario.ativo = false;
				});
				this.horarios_noite.forEach(horario => {
					horario.ativo = false;
				});
				this.horarios_outros.forEach(horario => {
					horario.ativo = false;
				});
				this.novos_horarios = [hor.horario];
				hor.ativo = true;
			} else if (hor.ativo) {
				if (this.editandoHorario)
					return;
				hor.ativo = false;
				const i = this.novos_horarios.findIndex(h => h === hor.horario);
				this.novos_horarios.splice(i, 1);
			} else {
				hor.ativo = true;
				this.novos_horarios.push(hor.horario);
			}
			this.novos_horarios.sort();
		},
		acao() {
			if (this.editandoHorario) {
				if (this.horarios.find(hor => hor.horario === this.novos_horarios[0])) {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Este horário já existe' });
					return;
				}
				this.horario.horario = this.novos_horarios[0];
				this.$emit('close');
			} else
				this.$emit('horarios', this.novos_horarios);
		},
		excluir() {
			this.$emit('excluir', this.horario.horario);
		}
	}
};
</script>
