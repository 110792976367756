export const pfsLabel = Object.freeze({
	pfWithoutTax: 'Sem Imposto',
	pf0: '0%',
	pf12: '12%',
	pf12Alc: '12% ALC',
	pf17: '17%',
	pf17Alc: '17% ALC',
	pf17_5: '17,5%',
	pf17_5Alc: '17,5% ALC',
	pf18: '18%',
	pf18Alc: '18% ALC',
	pf19: '19%',
	pf19Alc: '19% ALC',
	pf19_5: '19,5%',
	pf19_5Alc: '19,5% ALC',
	pf20: '20%',
	pf20Alc: '20% ALC',
	pf20_5: '20,5%',
	pf21: '21%',
	pf21Alc: '21% ALC',
	pf22: '22%',
	pf22Alc: '22% ALC'
});
