<template>
	<Dialog titulo="Enviar planilha" btn_cancelar="Cancelar" @acao="submit()" @close="fecharModal()"
		:loading="enviando" btn_acao="Enviar" :btn_acao_disable="!habilitarBtn">

		<v-container v-if="!habilitarBtn" fluid grid-list-lg class="pa-0">
			<v-progress-linear v-if="is_loading" color="purple" indeterminate />
			<template v-else>
				<input @change="carregarPlanilha" type="file" ref="upload" style="display: none" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
				<v-btn block depressed class="ml-0 green white--text" @click="$refs.upload.click()">
					<v-icon small class="mr-2">cloud_upload</v-icon>
					Escolher planilha
				</v-btn>
				<div v-if="nome_arquivo" class="mr-2 text-xs-center">{{ nome_arquivo }}</div>
				<p v-if="uploading" class="mt-2 text-xs-center"><strong>Carregando a planilha, por favor
					aguarde...</strong></p>
				<p v-if="semAlteracao && !uploading" class="mt-2 text-xs-center"><strong>Nenhuma alteração
					foi realizada.</strong></p>
			</template>
		</v-container>

		<v-container v-else fluid grid-list-lg class="pa-0">
			<v-layout wrap align-center>
				<v-flex class="mr-2" style="max-width: max-content">
					<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
						<path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
					</svg>
				</v-flex>
				<v-flex style="flex: 1">
					<h3>Upload feito com sucesso.</h3>
					<p class="mb-0"><strong>{{ alteracoes.length }}</strong> {{ alteracoes.length === 1 ?
						'medicamento foi alterado' : 'medicamentos foram alterados' }}.</p>
				</v-flex>
			</v-layout>
		</v-container>

	</Dialog>
</template>

<script>
import { MedicamentoPresenter } from '@Presenter/medicamento-presenter';
import header from './header-planilha';

export default {
	name: 'DialogEnviarPlanilha',
	data: () => ({
		is_loading: true,
		request_error: false,
		enviando: false,
		alteracoes: [],
		uploading: false,
		medicamentos: null,
		nome_arquivo: null
	}),
	created() {
		MedicamentoPresenter.index(header)
			.then(medicamentos => {
				this.medicamentos = medicamentos;
			})
			.catch(() => {
				this.request_error = true;
			})
			.finally(() => {
				this.is_loading = false;
			});
	},
	methods: {
		carregarPlanilha(input) {
			this.uploading = true;
			let planilha = input.target.files[0];
			if (!planilha)
				return;
			const reader = new FileReader();
			reader.onload = e => {
				import('xlsx').then(imp => {
					const XLSX = imp.default;
					const data = new Uint8Array(e.target.result);
					const workbook = XLSX.read(data, { type: 'array' });
					const sheet_medicamentos = workbook.Sheets.Medicamentos;
					if ((workbook.Custprops || {}).key !== 'farmebox_medicamentos' || !sheet_medicamentos) {
						planilha = null;
						input.target.value = '';
						this.alteracoes = [];
						this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Planilha inválida' });
						return;
					}
					const medicamentos_planilha = XLSX.utils.sheet_to_json(sheet_medicamentos);
					const id_map1 = {};
					const id_map2 = {};
					this.medicamentos.forEach(med => {
						med.liquido = Number(med.liquido);
						id_map1[med.id] = this.$lodash.pickBy(med, a => a !== null);
					});
					medicamentos_planilha.forEach(med => {
						med.liquido = Number(med.liquido);
						id_map2[med.id] = this.$lodash.pickBy(med, a => a !== null);
					});
					Object.keys(id_map1).forEach(key => {
						if (!this.$lodash.isEqual(id_map1[key], id_map2[key]))
							this.alteracoes.push(this.$lodash.cloneDeep(id_map2[key]));
					});
					this.uploading = false;
				});
			};
			this.nome_arquivo = planilha.name;
			reader.readAsArrayBuffer(planilha);
		},
		submit() {
			this.enviando = true;
			MedicamentoPresenter.bulkUpdate(this.alteracoes)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Medicamentos salvos com sucesso!' });
					this.$emit('planilha-enviada');
				})
				.finally(() => {
					this.enviando = false;
				});
		},
		fecharModal() {
			this.$emit('update:close', false);
		}
	},
	computed: {
		habilitarBtn() {
			return this.alteracoes.length > 0;
		},
		semAlteracao() {
			return this.nome_arquivo !== null && this.alteracoes.length === 0;
		}
	}
};
</script>

<style lang="scss" scoped>
$green: #7ac142;
$white: #fff;
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $green;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}
</style>
