<template>
	<section class="choice-item py-5">
		<div v-if="$vuetify.breakpoint.mdAndUp" class="header mb-4">
			<img width="70px" :src="optionIcon" alt="logo-farme">
		</div>
		<div class="body">
			<div class="mb-4 font-weight-regular title mx-5 mx-md-0">{{ getText }}</div>
			<v-text-field
				v-model="id"
				class="search-input"
				solo
				flat
				hide-details
				background-color="white"
				:error="error.active"
				:class="{'input-error': error.active}"/>

			<v-btn
				large
				depressed
				color="primary"
				:disabled="!id"
				style="border-radius: 10px;" class="px-5 mt-4"
				@click="search">
				Buscar
			</v-btn>

			<span v-if="error.active" class="red--text font-weight-bold mt-4">{{ error.message }}</span>
		</div>
	</section>
</template>

<script>
import optionIcon from '@Assets/icons/input.filled.svg';

export default {
	props: {
		error: {
			required: false,
			type: Object,
			default: () => ({
				active: false,
				message: null
			})
		}
	},
	data() {
		return {
			optionIcon,
			id: null
		};
	},
	methods: {
		search() {
			this.$emit('search', this.id);
		}
	},
	computed: {
		getText() {
			return this.$vuetify.breakpoint.mdAndUp ? 'ID da Box' : 'Digite o ID da Box para começar a produzir';
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
section .choice-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	width: 100%;
	height: 100% !important;
	background-color: rgba(255, 235, 204, 1);
	border-radius: 55px;
	text-align: center;

	.header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100px !important;
		height: 100px !important;
		background-color: $primary-light;
		border-radius: 50%;
	}

	.body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.input-error {
		border: 2px solid red!important;
	}

	@media (max-width: 960px)  {
		border-radius: 0 0 40px 40px;
	}
}
</style>
