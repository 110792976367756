<template>
	<v-card>
		<!-- Título e descrição -->
		<v-card-title class="purple-title">
			<v-layout fill-height align-center>
				<v-flex>
					<h2 style="font-weight: 600"> Etiquetas </h2>
				</v-flex>
				<!-- Botão fechar -->
				<v-flex class="align-end">
					<v-btn small icon @click="$emit('update:close', false)" class="mr-0">
						<v-icon small color="white">close</v-icon>
					</v-btn>
				</v-flex><!-- Fim botão fechar -->
			</v-layout>
		</v-card-title><!-- Fim, título e descrição -->
		<div v-if="gerando" class="wrapper">
			<div class="carregando">
				<v-progress-circular indeterminate color="grey" />
				<p class="mt-3 grey--text">Gerando etiqueta, por favor aguarde.</p>
			</div>
		</div>
		<embed v-else :src="pdf" type="application/pdf" style="width:100%; height:100%" />
	</v-card>
</template>

<script>

export default {
	name: 'DialogEtiqueta',
	props: {
		medicamentos: {
			type: Array,
			required: true
		},
		paciente: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		gerando: true,
		pdf: null
	}),
	created() {
		import('./pdf-lote-validade').then(pdf => {
			pdf.default(this.medicamentos, this.paciente).getDataUrl(outDoc => {
				this.$store.dispatch('SHOW_SNACKBAR', { message: 'Etiqueta criada com sucesso!' });
				this.pdf = outDoc;
				this.gerando = false;
			});
		});
	}
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 104px);

  .carregando {
    text-align: center;
    min-height: calc(100vh - 104px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
