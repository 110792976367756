<template>
	<v-flex xs2 class="mx-3">
		<v-layout column>
			<v-flex>
				<p class="mb-0 inter text-bold-medium titleOpacity">ID</p>
				<p class="mb-0 text-bold subheading titleOpacity">{{item.id}}</p>
			</v-flex>
			<v-flex>
				<p class="mb-0 inter text-bold-medium titleOpacity">Valor</p>
				<p class="mb-0 text-bold subheading" :class="{'lineThrough caption' : discountGreaterThanZero}">{{totalPriceOriginal | dinheiro}}</p>
				<p v-if="discountGreaterThanZero" class="mb-0 text-bold title colorfinalPrice">{{totalFinalPrice | dinheiro}}</p>
			</v-flex>
			<v-flex v-if="discountGreaterThanZero">
				<p class="mb-0 inter text-bold-medium titleOpacity">Desconto</p>
				<p class="text-bold text-start body-1 colorfinalPrice">{{discountPercentage}}%</p>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
export default {
	name: 'OthersProductInfo',
	props: {
		item: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		totalPriceOriginal() {
			return this.item.values.totalPriceOriginal;
		},
		totalFinalPrice() {
			return this.item.values.totalFinalPrice;
		},
		discountPercentage() {
			return this.item.values.discountPercentage;
		},
		discountGreaterThanZero() {
			return this.item.values.discount > 0;
		}
	}
};
</script>

<style lang="scss" scoped>
	.colorfinalPrice {
		color: #039800;
	}

	.titleOpacity {
		opacity: 0.7;
	}

	.lineThrough {
		text-decoration: line-through !important;
	}
</style>
