<template>
	<header class="mb-4">
		<v-layout wrap align-center>
			<v-flex style="max-width: max-content">
				<v-avatar size="64">
					<span class="white--text" style="font-size: 24px">
						{{ responsavel.nome.charAt(0) }}
					</span>
				</v-avatar>
			</v-flex>
			<v-flex>
				<h1 class="text--text">{{ responsavel.nome }}</h1>
				<p class="mb-0 grey--text text--darken-2">
					<v-icon small class="mr-1">phone</v-icon>
					{{ responsavel.telefone | telefone }}
				</p>
				<p v-if="responsavel.email" class="mb-0 grey--text text--darken-2">
					<v-icon small class="mr-1">mail</v-icon>
					{{ responsavel.email }}
				</p>
				<p class="mb-0 grey--text text--darken-2">
					<v-icon small class="mr-1">folder_shared</v-icon>
					{{ responsavel.cpf | cpf }}
				</p>
			</v-flex>
			<v-flex class="text-md-right">
				<v-btn depressed color="primary" class="mx-0" @click="$emit('editar-responsavel')">
					<v-icon small class="mr-2">edit</v-icon>
					Editar responsável
				</v-btn>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
export default {
	props: {
		responsavel: {
			type: Object,
			required: true
		}
	}
};
</script>
