<template>
	<section>
		<div class="d-flex justify-space-between">
			<v-card style="width:50%; border-right: 3px dashed #3B2C1B1A; border-radius: 10px 0 0 10px !important;">
				<v-card-title class="pb-0">
					<h3>Antes</h3>
				</v-card-title>
				<v-card-text>
					<template v-if="from">
						<ul>
							<li v-for="(field, index) of getSortedFields(Object.keys(from))" :key="index" :class="{ 'red--text' : Object.keys(diff || {}).includes(field)}">
								<template v-if="field !== 'posologia'">
									{{ getParsedField(field) }}: {{ getParsedValue(field).fromParsedValue }}
								</template>
								<template v-else>
									Posologia:
									<ul v-if="from && from.posologia">
										<li>
											Período de uso: {{ formtPeriodOfUse(from.posologia.periodo_de_uso) }}
										</li>
										<li>
											Horários: {{ formatSchedulePosology(from.posologia.horarios) }}
										</li>
										<li>
											Dias do mês: {{ from.posologia.dias_do_mes.join(', ') || '-' }}
										</li>
										<li>
											Dias do semana: {{ from.posologia.dias_da_semana.join(', ') || '-' }}
										</li>
										<li>
											Outro período: {{ !from.posologia.outro_periodo ? '-' : `${from.posologia.outro_periodo.tipo} ${from.posologia.outro_periodo.dia}` }}
										</li>
									</ul>
									<template v-else>-</template>
								</template>
							</li>
						</ul>
					</template>
					<template v-else>-</template>
				</v-card-text>
			</v-card>
			<v-card style="width:50%; border-radius: 0; border-radius: 0 10px 10px 0 !important;">
				<v-card-title class="pb-0">
					<h3>Depois</h3>
				</v-card-title>
				<v-card-text>
					<template v-if="to">
						<ul>
							<li v-for="(field, index) of getSortedFields(Object.keys(to))" :key="index" :class="{ 'success--text' : Object.keys(diff || {}).includes(field)}">
								<template v-if="field !== 'posologia'">
									{{ getParsedField(field) }}: {{ getParsedValue(field).toParsedValue }}
								</template>
								<template v-else>
									Posologia:
									<ul v-if="to && to.posologia">
										<li>
											Período de uso: {{ formtPeriodOfUse(to.posologia.periodo_de_uso) }}
										</li>
										<li>
											Horários: {{ formatSchedulePosology(to.posologia.horarios) }}
										</li>
										<li>
											Dias do mês: {{ to.posologia.dias_do_mes.join(', ') || '-' }}
										</li>
										<li>
											Dias do semana: {{ to.posologia.dias_da_semana.join(', ') || '-' }}
										</li>
										<li>
											Outro período: {{ !to.posologia.outro_periodo ? '-' : `${to.posologia.outro_periodo.tipo} ${to.posologia.outro_periodo.dia}` }}
										</li>
									</ul>
									<template v-else>-</template>
								</template>
							</li>
						</ul>
					</template>
					<template v-else>-</template>
				</v-card-text>
			</v-card>
		</div>
	</section>
</template>

<script>
import { getCompanyUnitNameById } from '@Consts/unidades';
import { mascaraDinheiro, formatDate, mascaraPorcentagem } from '@Util/functions';
import { PERIODO_DE_USO_LABEL } from '@Consts/posologia';

export default {
	name: 'UpdateHistoryToFrom',
	props: {
		from: {
			required: true,
			default: {}
		},
		to: {
			required: true,
			default: {}
		},
		diff: {
			required: true,
			defaut: {}
		}
	},
	computed: {

	},
	methods: {
		getParsedField(field) {
			const fieldMapper = {
				preco_personalizado: 'Preço personalizado',
				desconto: 'Desconto',
				data_validade: 'Data de validade',
				renewed_at: 'Data renovada',
				nome: 'Nome',
				email: 'E-mail',
				telefone: 'Telefone',
				sem_box: 'Sem Box',
				observacao: 'Observação',
				valor_servico: 'Valor do serviço farmacêutico',
				cpf: 'CPF',
				status: 'Status',
				filial_id: 'Filial',
				casa_repouso_id: 'Casa de Repouso [ID]',
				vendedor_id: 'Vendedor [ID]',
				pct_desconto_primeira_box: '% de desconto na 1ª Box',
				posologia: 'Posologia',
				qtd_caixas: 'Quantidade de caixas',
				medicamento_id: 'Medicamento [ID]'
			};

			return fieldMapper[field] || `Campo desconhecido ${field}`;
		},
		getParsedValue(field) {
			const dateFields = ['data_validade', 'renewed_at'];
			const percentageFields = ['pct_desconto_primeira_box'];
			const moneyFields = ['preco_personalizado', 'desconto', 'valor_servico'];
			const booleanFields = ['sem_box'];
			const normalFields = [
				'cpf', 'nome', 'email', 'telefone', 'qtd_caixas',
				'vendedor_id', 'casa_repouso_id', 'observacao', 'medicamento_id'
			];

			let fromParsedValue = '-';
			let toParsedValue = '-';

			if (moneyFields.includes(field)) {
				fromParsedValue = this.from ? this.formatMoney(this.from[field]) : fromParsedValue;
				toParsedValue = this.to ? this.formatMoney(this.to[field]) : toParsedValue;
			}

			if (percentageFields.includes(field)) {
				fromParsedValue = this.from ? this.formatPercentage(this.from[field]) : fromParsedValue;
				toParsedValue = this.to ? this.formatPercentage(this.to[field]) : toParsedValue;
			}

			if (dateFields.includes(field)) {
				fromParsedValue = this.from ? this.formatDate(this.from[field]) : fromParsedValue;
				toParsedValue = this.to ? this.formatDate(this.to[field]) : toParsedValue;
			}

			if (booleanFields.includes(field)) {
				fromParsedValue = this.from ? this.formatBoolean(this.from[field]) : fromParsedValue;
				toParsedValue = this.to ? this.formatBoolean(this.to[field]) : toParsedValue;
			}

			if (normalFields.includes(field)) {
				fromParsedValue = this.from && this.from[field] ? this.from[field] : fromParsedValue;
				toParsedValue = this.to && this.to[field] ? this.to[field] : toParsedValue;
			}

			if (field === 'filial_id') {
				fromParsedValue = this.from ? getCompanyUnitNameById(this.from[field]) : fromParsedValue;
				toParsedValue = this.to ? getCompanyUnitNameById(this.to[field]) : toParsedValue;
			}

			if (field === 'status') {
				fromParsedValue = this.from ? this.$functions.getStatusOrcamento(this.from[field]).label.toUpperCase() : fromParsedValue;
				toParsedValue = this.to ? this.$functions.getStatusOrcamento(this.to[field]).label.toUpperCase() : toParsedValue;
			}

			return { fromParsedValue, toParsedValue };
		},
		formatDate(value) {
			if (!value)
				return '-';
			return formatDate(value, 'DD/MM/YYYY');
		},
		formatBoolean(value) {
			return value ? 'Sim' : 'Não';
		},
		formatMoney(value) {
			if (!value)
				return '-';
			return mascaraDinheiro(value);
		},
		formatPercentage(value) {
			if (!value)
				return '-';
			return mascaraPorcentagem(value / 100);
		},
		formatSchedulePosology(schedules = []) {
			if (!schedules || !Array.isArray(schedules) || schedules.length === 0)
				return '-';
			return schedules.map(h => `${h.quantidade_por_horario} (QTD.) às ${h.horario}`).join(', ');
		},
		getBranchNameById(id) {
			return getCompanyUnitNameById(id);
		},
		formtPeriodOfUse(value) {
			return PERIODO_DE_USO_LABEL[value] || 'Período inválido';
		},
		getSortedFields(fields = []) {
			const order = {
				medicamento_id: 1,
				preco_personalizado: 2,
				posologia: 3
			};

			return fields.sort((a, b) => (Number(order[a]) > Number(order[b]) ? 1 : -1));
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
