<template>
	<section>
		<h3 class="my-3">Dados técnicos</h3>
		<v-layout wrap>
			<v-flex md6 xs12>
				<v-autocomplete
					v-model="form.routeAdministrationIds"
					background-color="white"
					:items="routesAdministration"
					label="Via de administração"
					box
					clearable
					v-validate="{ required: true }"
					data-vv-name="Via de administração"
					:error-messages="errors.collect('Via de administração')"
					item-value="id"
					item-text="name"
					multiple
					small-chips/>
			</v-flex>

			<v-flex md6 xs12>
				<v-autocomplete
					v-model="form.dosageFormId"
					background-color="white"
					:items="dosageForms"
					label="Formato"
					box
					clearable
					v-validate="{ required: true }"
					data-vv-name="Formato"
					:error-messages="errors.collect('Formato')"
					item-value="id"
					item-text="name"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-text-field
					v-model="form.anvisaId"
					background-color="white"
					box
					label="ID Anvisa"
					data-vv-name="ID Anvisa"
					v-validate="{ required: false }"
					:error-messages="errors.collect('ID Anvisa')"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-autocomplete
					v-model="form.atcId"
					background-color="white"
					prepend-inner-icon="search"
					:items="atcsClassification"
					box
					label="Classificação ATC"
					clearable
					item-value="id"
					item-text="atc"
					data-vv-name="Classificação ATC"
					v-validate="{ required: false }"
					:error-messages="errors.collect('Classificação ATC')">
				</v-autocomplete>
			</v-flex>

			<v-flex md6 xs12>
				<v-autocomplete
					v-model="form.stripe"
					background-color="white"
					:items="medicineStripes"
					label="Tarja"
					box
					clearable
					v-validate="{ required: true }"
					data-vv-name="Tarja"
					:error-messages="errors.collect('Tarja')"
					item-value="id"
					item-text="label"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-text-field
					v-model="form.properties.aggregateItem"
					background-color="white"
					box
					label="Item agregado"
					data-vv-name="Item agregado"
					v-validate="{ required: false }"
					:error-messages="errors.collect('Item agregado')"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-autocomplete
					v-model="form.type"
					background-color="white"
					:items="medicineTypes"
					label="Tipo do medicamento"
					box
					clearable
					v-validate="{ required: true }"
					data-vv-name="Tipo do medicamento"
					:error-messages="errors.collect('Tipo do medicamento')"
					item-value="id"
					item-text="label"/>
			</v-flex>

			<v-flex md6 xs12>
				<v-autocomplete
					v-model="form.referenceDrug"
					:loading="referenceDrug.loading"
					:search-input.sync="referenceDrug.term"
					hide-no-data
					background-color="white"
					prepend-inner-icon="search"
					no-data-text="Nenhum resultado encontrado"
					:items="referenceDrug.items"
					box
					label="Referência"
					placeholder="Digite o nome do medicamento..."
					clearable
					item-text="name">
				</v-autocomplete>
			</v-flex>

			<v-flex md6 xs12>
				<v-autocomplete
					v-model="form.pnu"
					hide-no-data
					background-color="white"
					no-data-text="Nenhum resultado encontrado"
					:items="pnuList"
					box
					v-validate="{ required: true }"
					data-vv-name="PNU"
					:error-messages="errors.collect('PNU')"
					label="PNU"
					clearable
					item-value="value"
					item-text="key">
				</v-autocomplete>
			</v-flex>

			<v-flex md6 xs12>
				<v-autocomplete
					v-model="form.prescriptionRule"
					:prepend-inner-icon="prescriptionRules.loading ? 'sync' : 'search'"
					background-color="white"
					:items="prescriptionRules.items"
					:loading="prescriptionRules.loading"
					:disabled="prescriptionRules.loading"
					label="Regra de prescrição"
					box
					clearable
					v-validate="{ required: false }"
					data-vv-name="Regra de prescrição"
					:error-messages="errors.collect('Regra de prescrição')"
					return-object
					item-text="description"
					item-value="id">
					<template v-slot:selection="data">
						{{ data.item.name }}
					</template>
					<template v-slot:item="data">
						<template>
							<v-list-tile-content :title="data.item.description">
								<v-list-tile-title>
									<strong>{{ data.item.name }}</strong>
								</v-list-tile-title>
								<v-list-tile-sub-title>{{data.item.description}}</v-list-tile-sub-title>
							</v-list-tile-content>
						</template>
					</template>
				</v-autocomplete>
			</v-flex>

		</v-layout>
	</section>
</template>

<script>
import { medicineTypesLabel } from '@Consts/medicine-types';
import { medicineStripes } from '@Consts/medicine-stripes';

export default {
	name: 'NewProductStepMedicineTechnicalData',
	inject: ['$validator'],
	props: {
		form: {
			required: true,
			type: Object
		},
		routesAdministration: {
			required: true,
			type: Array
		},
		dosageForms: {
			required: true,
			type: Array
		},
		atcsClassification: {
			required: true,
			type: Array
		},
		prescriptionRules: {
			required: true,
			type: Object
		},
		referenceDrug: {
			required: true,
			type: Array
		}
	},
	data() {
		return {
			pnuList: [{
				key: 'Positiva',
				value: 'POSITIVA'
			}, {
				key: 'Negativa',
				value: 'NEGATIVA'
			}, {
				key: 'Neutra',
				value: 'NEUTRA'
			}]
		};
	},
	computed: {
		medicineTypes() {
			const types = Object.keys(medicineTypesLabel);

			return types.map(type => ({
				id: type,
				label: medicineTypesLabel[type]
			}));
		},
		medicineStripes() {
			const stripes = Object.keys(medicineStripes);
			return stripes.map(stripe => ({
				id: stripe,
				label: medicineStripes[stripe]
			}));
		}
	},
	watch: {
		'form.atcId': async function () {
			this.form.atcName = this.atcsClassification.find(a => a.id)?.atc || '';
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
