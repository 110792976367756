import { isProd, isStaging } from '@Config/environments';

export const url_jornada_digital = {
	Dev: 'farme-digital-dev.infra.farme.com.br',
	Staging: 'farme-digital-stg.infra.farme.com.br',
	Prod: 'minha.farme.com.br',
	Local: 'farme-digital-dev.infra.farme.com.br'
};

export function getUrlJornadaDigital() {
	if (isProd())
		return url_jornada_digital.Prod;
	if (isStaging())
		return url_jornada_digital.Staging;
	return url_jornada_digital.Dev;
}
