<template>
	<Dialog titulo="Detalhes do orçamento" :subtitulo="`Orçamento: ${orcamento.id}`" @close="$emit('update:close', false)">
		<v-container fluid grid-list-md class="pa-0">
			<v-layout wrap>
				<v-flex xs12 md6>
					<p class="mb-0" @click="copiarMensagemDeRelacaoDeMedicamento"><strong>Nome:</strong> {{ orcamento.nome }}</p>
					<p class="mb-0"><strong>Telefone:</strong> {{ orcamento.telefone | telefone }}</p>
					<p v-if="orcamento.email" class="mb-0"><strong>E-mail:</strong> {{ orcamento.email }}</p>
					<p class="mb-0"><strong>Vendedor:</strong> {{ (orcamento.vendedor || {}).nome || '-' }}</p>
				</v-flex>
				<v-flex xs12 md6>
					<p class="mb-0"><strong>Situação:</strong>
						<v-chip small disabled :class="$functions.getStatusOrcamento(orcamento.status).class"
							class="white--text my-0" style="margin-top: -3px !important">
							{{ $functions.getStatusOrcamento(orcamento.status).label }}
						</v-chip>
					</p>
					<p class="mb-0"><strong>Data de criação do Orçamento:</strong> {{ orcamento.updated_at | formatDate('DD/MM/YYYY') }}</p>
					<p class="mb-0"><strong>Data de validade do Orçamento:</strong> {{ orcamento.data_validade | formatDate('DD/MM/YYYY') }}</p>
					<p class="mb-0"><strong>Quantidade de medicamentos:</strong> {{ orcamento.medicamentos.length }}</p>
					<v-tooltip v-if="orcamento.cpf" right>
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" color="green" @click="copiarMensagemDeRelacaoDeMedicamento" style="font-size: 16px; cursor: pointer; margin-left:3px">fas fa-envelope-open-text</v-icon>
						</template>
						<span>Copiar Relação de Medicamentos escrita</span>
					</v-tooltip>
					<span v-if="orcamento.sem_box"><v-icon small class="mr-1" color="warning">fas fa-exclamation-circle</v-icon><strong>Orçamento sem box</strong></span>
				</v-flex>
				<v-flex xs12 v-if="orcamento.observacao">
					<p class="mb-0"><strong>Observação:</strong> {{ orcamento.observacao }}</p>
				</v-flex>
				<v-flex xs12>
					<v-divider class="my-3" />
					<p class="mb-0"><strong>Preço total: </strong>{{ orcamento.valor_total | dinheiro }}</p>
					<p class="mb-0"><strong>Desconto: </strong>{{ orcamento.desconto_total | dinheiro }}</p>
					<p class="mb-0"><strong>Preço a cada 30 dias: </strong>{{ orcamento.valor_final | dinheiro }}</p>
					<br/>
					<p class="mb-0"><strong>Desconto no primeiro mês: </strong>{{ orcamento.pct_desconto_primeira_box | porcentagem }}</p>
					<p class="mb-0"><strong>Preço no primeiro mês: </strong>{{ orcamento.valor_primeira_box | dinheiro }}</p>
				</v-flex>
				<v-flex xs12>
					<v-divider class="my-3" />
					<div class="legenda">
						<strong>Legenda: </strong><br>
						<span class="caixa">Preço da caixa/pacote</span><br>
						<span class="desconto">Desconto no valor da caixa/pacote</span><br>
						<span class="total">Preço total</span><br>
					</div>
				</v-flex>
				<v-flex xs12 v-for="(item, index) in orcamento.medicamentos" :key="index" class="medicamento">
					<v-layout align-center>
						<v-flex class="pr-2" style="max-width: max-content">
							<v-tooltip top content-class="top">
								<v-avatar size="32" class="white--text" slot="activator">{{ item.qnt_mensal }}</v-avatar>
								<span>Quantidade de caixas no mês</span>
							</v-tooltip>
						</v-flex>
						<v-flex>
							<h4 class="text--text">{{ item.principio_ativo }}</h4>
							<h3 class="brown--text text--darken-2">{{ item.produto }}
								<v-tooltip v-if="item.pbm" top>
									<v-chip class="justify-center" color="orange" style="max-width: 20px; max-height: 20px;" slot="activator" dark
										small><strong>%</strong></v-chip>
									<span>{{ item.precos[0].beneficio_pbm || 'PBM'}}</span>
								</v-tooltip>
							</h3>
							<p class="mb-0 grey--text text--darken-2"> {{ item.apresentacao }} </p>
						</v-flex>
						<v-flex class="pl-2" style="max-width: max-content">
							<v-tooltip top content-class="top">
								<v-chip small class="info white--text" slot="activator">
									{{ (item.preco) | dinheiro }}
								</v-chip>
								<span>Preço da caixa - {{ item.produto }}</span>
							</v-tooltip>
							<v-tooltip top content-class="top">
								<v-chip small class="warning white--text" slot="activator">
									{{ (item.desconto) | dinheiro }}
								</v-chip>
								<span>Desconto na caixa - {{ item.produto }}</span>
							</v-tooltip>
							<v-tooltip top content-class="top">
								<v-chip small class="green white--text" slot="activator">
									{{ item.valor_final | dinheiro }}
								</v-chip>
								<span>Preço total - {{ item.produto }}</span>
							</v-tooltip>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';
import { copyToClipboard } from '@Util/functions';
import { getPeriodoDeUsoFormatado } from '@Util/medicamento';

export default {
	name: 'DialogDetalhes',
	props: ['orcamento_id'],
	data: () => ({
		orcamento: { vendedor: {}, medicamentos: [] },
		carregando: true
	}),
	created() {
		if (typeof this.orcamento_id === 'number') {
			OrcamentoPresenter.show(this.orcamento_id)
				.then(response => {
					this.orcamento = response;
				})
				.finally(() => {
					this.carregando = false;
				});
		}
	},
	methods: {
		copiarMensagemDeRelacaoDeMedicamento() {
			const msg = `*Importante*: Para garantir a segurança do seu tratamento pedimos que você revise com atenção a dose, \
			quantidade de comprimidos e horários dos seus medicamentos, conforme abaixo. \
			Se estiver tudo certo, nos envie "Ok. De acordo".

			\
			${this.orcamento.medicamentos.map(medicamento => `${medicamento.produto} ${medicamento.apresentacao}\

			${getPeriodoDeUsoFormatado(medicamento)}`).join(`

			\
			`)}`;

			copyToClipboard(msg, 'Relação de Medicamentos escrita');
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.legenda {
  span {
    padding-left: 20px;
    position: relative;
    margin-left: 10px;

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 1px;
      height: 14px;
      width: 14px;
      border-radius: 50%;
    }

    &.caixa {
      &:before {
        background: $info;
      }
    }

	&.desconto {
      &:before {
        background: $warning;
      }
    }

    &.total {
      &:before {
        background: $green;
      }
    }
  }
}

.medicamento {
  margin-top: 12px;
  border-radius: 5px;
  transition: background 0.3s;

  &:hover {
    background: rgba(#000, 0.06);
  }

  h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
  }

  h3 {
    font-size: 15px;
  }
}
</style>
