import { render, staticRenderFns } from "./novas-assinaturas.vue?vue&type=template&id=6c38b361&scoped=true"
import script from "./novas-assinaturas.vue?vue&type=script&lang=js"
export * from "./novas-assinaturas.vue?vue&type=script&lang=js"
import style0 from "./novas-assinaturas.vue?vue&type=style&index=0&id=6c38b361&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c38b361",
  null
  
)

export default component.exports