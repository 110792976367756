<template>
	<Dialog titulo="Confirmação de cancelamento da fatura" btn_acao="Confirmar"
		:loading="loading" @acao="confirm" @close="close">
		<div class="d-flex items-center justify-center">
			<div class="d-flex q-column items-center justify-center text-center">
				<h2 class="roboto mb-3" style="font-size: 18px; font-weight: 400;">
					<span style="font-weight: bold;">Atenção!</span> Você está prestes a cancelar a fatura <span style="font-weight: bold">{{ invoiceId }}</span>.
				</h2>
				<h3 class="roboto" style="font-size: 17px; font-weight: bold;">Certifique-se de que deseja prosseguir com esta ação, pois ela não poderá ser desfeita.</h3>
			</div>
		</div>
	</Dialog>
</template>

<script>
import { InvoicesPresenter } from '@Presenter/invoices-presenter';

export default {
	name: 'DialogCancelInvoice',
	props: {
		invoiceId: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		loading: false
	}),
	methods: {
		confirm() {
			this.loading = true;
			InvoicesPresenter.cancelInvoice(this.invoiceId)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Cancelamento de fatura efetuado', color: 'success' });
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao cancelar fatura', color: 'error' });
				})
				.finally(() => {
					this.close();
					this.loading = false;
				});
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
