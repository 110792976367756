<template>
	<div class="orcamentos-wrapper px-md-5">
		<Header @busca="b => busca = b" />
		<v-card>
			<v-data-table :headers="headers" :items="orcamentos" :loading="loading"
				:search="busca" :no-data-text="(loading) ? 'Carregando...' : (erro) ? 'Erro ao carregar, tente novamente' : 'Nenhum orçamento'"
				no-results-text="Nenhum resultado encontrado" :pagination.sync="pagination" :total-items="totalItems" rows-per-page-text="Itens por página">
				<template v-slot:items="props">
					<tr @click="abrirDialog(props.item, 'dialog_detalhes')" class="pointer">
						<td class="py-2" style="white-space: nowrap">
							<v-avatar size="24" class="mr-2">
								<span class="white--text">
									{{ (props.item.client.name || '').charAt(0) }}
								</span>
							</v-avatar>
							{{ (props.item.client.name || '') }}
						</td>
						<td class="text-xs-center">{{ props.item.client.cellphone }}</td>
						<td class="text-xs-center">{{ props.item.client.city }}</td>
						<td class="text-xs-center">{{ props.item.createdAt | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog v-model="dialog_delete" transition="fade" max-width="450px">
			<transition>
				<Dialog v-if="dialog_delete" titulo="Apagar orçamento" subtitulo="Tem certeza? Esta ação não pode ser desfeita."
					btn_acao="Sim, apagar" btn_acao_class="error" btn_cancelar="Cancelar" :loading="deleting"
					@acao="deleteOrcamento" @close="dialog_delete = false" />
			</transition>
		</v-dialog>

		<v-dialog v-model="dialog_detalhes" scrollable transition="fade" max-width="850px">
			<transition>
				<Dialog v-if="dialog_detalhes" titulo="Detalhes do orçamento" :subtitulo="item_selecionado.client.name"
					:padding="false" btn_acao="Criar orçamento" btn_excluir="Excluir"
					@acao="novoOrcamento(item_selecionado)" @excluir="abrirDialog(item_selecionado, 'dialog_delete')" @close="dialog_detalhes = false">
					<Orcamento :id="item_selecionado.id" />
				</Dialog>
			</transition>
		</v-dialog>

		<v-dialog v-model="dialog_novo_orcamento" scrollable transition="fade" fullscreen>
			<transition>
				<DialogNovoOrcamento v-if="dialog_novo_orcamento" :id="item_selecionado.id" @close="dialog_novo_orcamento = false" @saved="onSaved" />
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { FarmeOSPresenter } from '@Presenter/farmeos-presenter';
import Header from './header';
import Orcamento from './orcamento';
import DialogNovoOrcamento from './dialog-novo-orcamento';

export default {
	name: 'OrcamentosExternos',
	components: { Header, Orcamento, DialogNovoOrcamento },
	data: () => ({
		orcamentos: [],
		headers: [
			{ text: 'Nome', value: 'cliente.name' },
			{ text: 'Telefone', value: 'cliente.cellphone', align: 'center' },
			{ text: 'Cidade', value: 'cliente.city', align: 'center' },
			{ text: 'Criado em', value: 'created_at', align: 'center' }
		].map(h => ({ ...h, sortable: false })),
		pagination: {
			page: 1,
			sortBy: 'created_at',
			descending: true,
			rowsPerPage: 15
		},
		deleting: false,
		dialog_delete: false,
		dialog_detalhes: false,
		dialog_novo_orcamento: false,
		totalItems: 0,
		busca: '',
		erro: false,
		loading: false,
		item_selecionado: null
	}),
	watch: {
		pagination() {
			this.getOrcamentos();
		}
	},
	created() {
		this.getOrcamentos();
	},
	methods: {
		abrirDialog(item, dialog_name) {
			this.item_selecionado = item;
			this[dialog_name] = true;
		},
		novoOrcamento(item) {
			this.dialog_detalhes = false;
			this.abrirDialog(item, 'dialog_novo_orcamento');
		},
		getOrcamentos() {
			this.loading = true;
			const query = { page: this.pagination.page - 1 };
			if (this.pagination.rowsPerPage !== -1)
				query.limit = this.pagination.rowsPerPage;
			FarmeOSPresenter.quotations(query)
				.then(({ items, count }) => {
					this.orcamentos = items;
					this.totalItems = count;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		onSaved() {
			this.dialog_novo_orcamento = false;
			this.getOrcamentos();
		},
		deleteOrcamento() {
			this.deleting = true;
			FarmeOSPresenter.deleteQuotation(this.item_selecionado.id)
				.then(() => {
					this.dialog_detalhes = false;
					this.dialog_delete = false;
					this.getOrcamentos();
				})
				.finally(() => {
					this.deleting = false;
				});
		}
	}
};
</script>
