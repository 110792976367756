<template>
	<v-card
		:class="isSelected ? 'selecionado' : null">
		<v-card-text>
			<v-switch
				label="Usar esse endereço"
				v-model="switchValue"
				color="success"/>
			<h3 v-if="restHome">
				Endereço ILPI - {{restHome.nome}}
			</h3>
			<p class="mb-0">{{ addressItem.logradouro }}</p>
			<h4>{{ addressItem.numero }}</h4>
			<p class="mb-0">{{ addressItem.complemento }}</p>
			<p class="mb-0">Bairro {{ addressItem.bairro }}</p>
			<p class="mb-0">{{ addressItem.cidade }}, {{ addressItem.estado }}</p>
			<p class="mb-0">{{ addressItem.cep }}</p>
			<p v-if="addressItem.observacao" class="mb-0">{{ addressItem.observacao }}</p>
		</v-card-text>
	</v-card>
</template>

<script>

export default {
	name: 'CardEndereco',
	props: {
		isSelected: {
			type: Boolean,
			default: false
		},
		restHome: {
			type: Object,
			default: null
		},
		addressItem: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		switchValue: false
	}),
	watch: {
		isSelected(value) {
			this.switchValue = value;
		}
	},
	created() {
		this.switchValue = this.isSelected;
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.v-card {
  height: 100%;
  cursor: pointer;
  border: 2px solid #fff;

  &.selecionado {
    border: 2px solid $success;
  }

  &.selecionado-novo-endereco {
    border: 2px solid $primary;
  }

  .v-card__text {
    height: 100%;

    &.flex-center {
      font-weight: 600;
    }
  }
}
</style>
