<template>
	<Dialog titulo="TERMOS DE USO DA FAR.ME" btn_acao="Fechar" @acao="$emit('update:close', false)"
		@close="$emit('update:close', false)">

		<!-- Arquivo de word convertido usando: https://wordhtml.com/ -->
		<p style="text-align: center;"><strong><span style="font-size: 12.0pt; font-family: 'Arial',sans-serif; color: black;">TERMOS
			DE USO DA FAR.ME</span></strong></p>
		<p style="text-align: center;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">&nbsp;</span></strong></p>
		<p><strong><span style="font-family: 'Arial',sans-serif; color: black;">SUM&Aacute;RIO</span></strong></p>
		<ol>
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Propriedade e
				Apresenta&ccedil;&atilde;o</span></strong></li>
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Objeto e
				Defini&ccedil;&otilde;es</span></strong></li>
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Condi&ccedil;&otilde;es de acesso e uso do SITE </span></strong></li>
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Cadastro de
				USU&Aacute;RIOS</span></strong></li>
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;">Pre&ccedil;os e
				pagamentos &agrave; Far.me</span></strong></li>
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Dados Pessoais,
				Privacidade e Seguran&ccedil;a</span></strong></li>
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Propriedade
				Intelectual e Industrial</span></strong></li>
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Exclus&atilde;o de
				Garantias e Responsabilidade</span></strong></li>
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Comunica&ccedil;&otilde;es e Contato Far.me </span></strong></li>
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Dura&ccedil;&atilde;o e Atualiza&ccedil;&otilde;es</span></strong></li>
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Cancelamento da Far.me</span></strong></li>
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Condi&ccedil;&otilde;es Gerais</span></strong></li>
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Registro P&uacute;blico</span></strong></li>
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Lei aplic&aacute;vel e Foro de Elei&ccedil;&atilde;o</span></strong></li>
		</ol>
		<p>&nbsp;</p>
		<ol>
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Propriedade e
				Apresenta&ccedil;&atilde;o</span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Este <strong>SITE</strong>
			&eacute; de propriedade exclusiva da </span><strong><span style="font-family: 'Arial',sans-serif;">FAR.ME
			FARMACOTERAPIA OTIMIZADA LTDA</span></strong><span style="font-family: 'Arial',sans-serif; color: black;">,
			ao longo deste documento designada t&atilde;o somente como <strong>Far.me</strong>, tendo a
			manuten&ccedil;&atilde;o e opera&ccedil;&atilde;o estabelecida como atividade exercida
			exclusivamente por ela, da qual a sede se encontra no endere&ccedil;o Av. Cristiano
			Machado, n&ordm; 640, loja 10 Pavimento 1, bairro Sagrada Fam&iacute;lia, Munic&iacute;pio
			de Belo Horizonte, Estado de Minas Gerais, CEP 31.030-514, inscrita no Cadastro Nacional de
			Pessoas Jur&iacute;dicas do Minist&eacute;rio da Fazenda sob o n&ordm; 25.228.539/0001-23 e
			correio eletr&ocirc;nico contato@farme.com.br.</span></p>
		<p><span style="font-family: 'Arial',sans-serif;">A <strong>Far.me</strong> &eacute; uma
			farm&aacute;cia que, por meio dessa plataforma digital, tem o objetivo de simplificar a
			rotina de pessoas que necessitam tomar m&uacute;ltiplos medicamentos por dia. A <strong>Far.me</strong>&nbsp;fornece
			os medicamentos organizados de acordo com o hor&aacute;rio e dia da
			prescri&ccedil;&atilde;o m&eacute;dica e proporciona um suporte farmac&ecirc;utico focado
			no uso mais seguro e efetivo do tratamento medicamentoso.</span></p>
		<ol start="2">
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Objeto e
				Defini&ccedil;&otilde;es</span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Este documento tem por objeto
			estabelecer os <strong>TERMOS</strong> de Uso das funcionalidades do site </span><a href="http://www.farme.com"><span
			style="font-family: 'Arial',sans-serif;">www.farme.com</span></a> <span style="font-family: 'Arial',sans-serif; color: black;">
			designado adiante como<strong> &ldquo;SITE&rdquo;</strong><span style="color: black; text-decoration: none; text-underline: none;">
				e dos servi&ccedil;os prestados pela <strong>Far.me</strong>,</span> conforme
			defini&ccedil;&otilde;es a seguir.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O documento aqui exposto
			descreve os <strong>TERMOS</strong> e Condi&ccedil;&otilde;es de Uso que devem ser
			observados pela <strong><u>Far.me</u></strong> e pelas pessoas que acessarem, utilizarem
			e/ou contratarem a <strong>Far.me</strong>, adiante designadas como <u>&ldquo;<strong>USU&Aacute;RIO</strong>&rdquo;</u>,
			no singular, ou, <strong><u>&ldquo;USU&Aacute;RIOS&rdquo;</u></strong>, no plural.</span></p>
		<p><strong><u><span style="font-family: 'Arial',sans-serif; color: black;">&ldquo;Contato&rdquo;</span></u></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> &eacute; a forma de contato com a
			<strong>Far.me</strong>, funcionalidade pela qual o <strong>USU&Aacute;RIO</strong>
			poder&aacute; entrar em contato atrav&eacute;s dos canais de comunica&ccedil;&atilde;o
			disponibilizados, de acordo com a solicita&ccedil;&atilde;o que necessita para sanar
			eventuais problemas e d&uacute;vidas oriundas do <strong>SITE</strong>.</span></p>
		<p><strong><u><span style="font-family: 'Arial',sans-serif; color: black;">&ldquo;Conta de
			Acesso&rdquo;</span></u></strong><span style="font-family: 'Arial',sans-serif; color: black;">
			&eacute; a credencial definida pelo nome de usu&aacute;rio &ldquo;login&rdquo; e senha de
			um <strong>USU&Aacute;RIO</strong> cadastrado, pessoal e intransfer&iacute;vel, que permite
			acesso &agrave; &aacute;rea restrita e &agrave;s funcionalidades e benef&iacute;cios
			exclusivos do <strong>SITE</strong>, tais como a utiliza&ccedil;&atilde;o dos
			servi&ccedil;os de compra, organiza&ccedil;&atilde;o e entrega dos medicamentos de forma
			personalizada de acordo com os dados disponibilizados na conta; inserir ou alterar dados de
			pagamento; dentre outras. </span></p>
		<p><strong><u><span style="font-family: 'Arial',sans-serif; color: black;">&ldquo;Propriedade
			Intelectual&rdquo;</span></u></strong><span style="font-family: 'Arial',sans-serif; color: black;">
			da <strong>Far.me</strong> s&atilde;o todos os bens de propriedade intelectual de sua
			titularidade ou de terceiro que tenha uso licenciado e/ou autorizado, tais como patentes,
			marcas, inven&ccedil;&otilde;es ou modelos de utilidade, desenhos industriais, know-how,
			segredos de neg&oacute;cio, ilustra&ccedil;&otilde;es, fotografias e/ou conte&uacute;dos de
			qualquer tela do <strong>SITE</strong> ou quaisquer obras intelectuais ou outros
			conte&uacute;dos que estejam inseridos por qualquer obra intelectual ou qualquer bem
			protegido por direito de propriedade intelectual. </span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Fazem parte desses <strong>TERMOS</strong>
			os demais documentos acess&oacute;rios espec&iacute;ficos das ferramentas do <strong>SITE</strong>,
			tais como a <a @click="$emit('abrir-politica-privacidade')">Pol&iacute;tica de Privacidade
				e Seguran&ccedil;a</a> e outras Condi&ccedil;&otilde;es Particulares que venham a ser
			apresentadas e aceitas pelo <strong>USU&Aacute;RIO</strong> de forma t&aacute;cita ou
			expressa, conforme o caso.</span></p>
		<ol start="3">
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Condi&ccedil;&otilde;es de acesso e uso do SITE </span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Ser&aacute; permitido o acesso
			ao <strong>SITE </strong>e a utiliza&ccedil;&atilde;o de suas ferramentas pelo
			p&uacute;blico interessado, sendo o aceite t&aacute;cito ou expresso pelo <strong>USU&Aacute;RIO</strong>
			desse documento o originador do dever de seguir as condi&ccedil;&otilde;es estabelecidas
			nesses <strong>TERMOS</strong> e seus acess&oacute;rios. </span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O aceite t&aacute;cito
			ser&aacute; estabelecido por meio da navega&ccedil;&atilde;o pelo <strong>USU&Aacute;RIO</strong>
			da parte n&atilde;o restrita do <strong>SITE </strong>importando, no que couber, na
			ader&ecirc;ncia &agrave;s condi&ccedil;&otilde;es deste documento e de todos os seus
			acess&oacute;rios para a utiliza&ccedil;&atilde;o do <strong>SITE</strong>.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A ader&ecirc;ncia dever&aacute;
			ser expressa quando a utiliza&ccedil;&atilde;o for por parte do <strong>USU&Aacute;RIO</strong>
			cadastrado na plataforma <strong>Far.me</strong> para usufruto da parte restrita e das
			ferramentas do <strong>SITE, </strong>importando sua ader&ecirc;ncia com
			aceita&ccedil;&atilde;o integral &agrave;s condi&ccedil;&otilde;es desse documento e de
			todos os seus acess&oacute;rios, desde o momento em que o <strong>USU&Aacute;RIO</strong>
			acesse a sua &aacute;rea restrita.</span></p>
		<p><strong><span style="font-family: 'Arial',sans-serif; color: black;">Assim, o USU&Aacute;RIO
			declara ter lido e aceito esses Termos de Uso e seus acess&oacute;rios antes de ter
			iniciado a utiliza&ccedil;&atilde;o do SITE. </span></strong></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Ser&aacute; vedado ao <strong>USU&Aacute;RIO</strong>
			a utiliza&ccedil;&atilde;o do <strong>SITE </strong>e acesso aos benef&iacute;cios
			oferecidos pela <strong>Far.me </strong>caso n&atilde;o aceite esses <strong>TERMOS</strong>
			e seus acess&oacute;rios em seu formato original, disponibilizado online e registrado.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O <strong>USU&Aacute;RIO</strong>
			poder&aacute; solicitar altera&ccedil;&otilde;es neste documento caso tenha alguma
			discord&acirc;ncia, devendo faz&ecirc;-lo atrav&eacute;s do <strong>CONTATO</strong>, sendo
			que a utiliza&ccedil;&atilde;o do <strong>SITE</strong> antes do retorno da <strong>Far.me</strong>
			sobre as considera&ccedil;&otilde;es realizadas, estar&aacute; sujeita &agrave;
			observa&ccedil;&atilde;o do ora estabelecido em sua integralidade.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A <strong>Far.me</strong> se
			reserva ao direito de n&atilde;o aceitar eventuais solicita&ccedil;&otilde;es de
			altera&ccedil;&otilde;es destes <strong>TERMOS</strong> por discord&acirc;ncia, analisando
			caso a caso. </span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O <strong>USU&Aacute;RIO</strong>
			reconhece e aceita que o uso do <strong>SITE</strong> ser&aacute; feito por sua exclusiva
			conta, responsabilidade e riscos, se comprometendo a utiliz&aacute;-las de acordo com esses
			<strong>TERMOS</strong> e seus acess&oacute;rios, com a legisla&ccedil;&atilde;o vigente
			aplic&aacute;vel no ordenamento jur&iacute;dico brasileiro, a moral, os bons costumes e a
			ordem p&uacute;blica.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O <strong>USU&Aacute;RIO</strong>
			se compromete, sob sua total e irrestrita responsabilidade, a n&atilde;o violar quaisquer
			das disposi&ccedil;&otilde;es aven&ccedil;adas nesses <strong>TERMOS</strong> ou em seus
			acess&oacute;rios, direitos de outros <strong>USU&Aacute;RIOS</strong>, direitos de
			terceiros.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O <strong>USU&Aacute;RIO</strong>
			se compromete, de forma exemplificativa, a: </span></p>
		<ol>
			<li><span style="font-family: 'Arial',sans-serif; color: black;"> n&atilde;o utilizar login e
				senha para a <strong>Conta de Acesso</strong> aos distintos servi&ccedil;os e/ou
				conte&uacute;dos do&nbsp;<strong><span style="border: none windowtext 1.0pt; padding: 0cm;">SITE
				</span></strong>que sejam de titularidade de outros <strong>USU&Aacute;RIOS</strong>;</span></li>
			<li><span style="font-family: 'Arial',sans-serif; color: black;"> n&atilde;o difundir,
				transmitir ou colocar &agrave; disposi&ccedil;&atilde;o de terceiros quaisquer tipos de
				informa&ccedil;&otilde;es, elementos ou conte&uacute;dos que impliquem ou possam implicar
				em viola&ccedil;&atilde;o ao sigilo das comunica&ccedil;&otilde;es e &agrave;
				privacidade;</span></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">iii. n&atilde;o introduzir ou
			difundir na rede programas de dados (v&iacute;rus inform&aacute;ticos e softwares nocivos)
			que possam provocar danos aos sistemas inform&aacute;ticos do provedor de acesso, aos
			sistemas inform&aacute;ticos do&nbsp;<strong><span style="border: none windowtext 1.0pt; padding: 0cm;">SITE</span></strong>&nbsp;ou
			a terceiros e <strong>USU&Aacute;RIOS</strong> da rede mundial de Internet;</span></p>
		<ol>
			<li><span style="font-family: 'Arial',sans-serif; color: black;"> n&atilde;o difundir,
				transmitir ou colocar &agrave; disposi&ccedil;&atilde;o de terceiros qualquer tipo de
				informa&ccedil;&atilde;o, elemento ou conte&uacute;do que implique ou possa implicar em
				viola&ccedil;&atilde;o dos direitos de propriedade intelectual e industrial, patentes,
				marcas ou <em>copyright</em> de titularidade dos propriet&aacute;rios do&nbsp;<strong><span
					style="border: none windowtext 1.0pt; padding: 0cm;">SITE </span></strong>ou de
				terceiros;</span></li>
			<li style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">
				utilizar o <strong>SITE </strong>exclusivamente com o prop&oacute;sito de usufruir dos
				servi&ccedil;os restritos ao escopo de atividade da <strong>Far.me</strong> expostos no
				presente documento e seus acess&oacute;rios e no <strong>SITE</strong>, n&atilde;o
				realizando quaisquer atos que de alguma forma possam implicar em qualquer preju&iacute;zo
				ou dano ao <strong>SITE</strong>, &agrave; <strong>Far.me</strong>, aos <strong>USU&Aacute;RIOS</strong>
				ou &agrave; terceiros;</span></li>
			<li style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">
				n&atilde;o acessar ilicitamente o <strong>SITE </strong>ou sistemas inform&aacute;ticos
				de terceiros que possam causar danos de terceiros relacionados ao <strong>SITE </strong>ou
				&agrave;<strong> Far.me</strong> sob qualquer meio e/ou forma;</span></li>
		</ol>
		<p style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">vii.
			n&atilde;o executar atos que limitem ou impe&ccedil;am o acesso e a
			utiliza&ccedil;&atilde;o do <strong>SITE</strong>, em condi&ccedil;&otilde;es adequadas aos
			demais <strong>USU&Aacute;RIOS</strong>;</span></p>
		<p style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">viii.
			n&atilde;o utilizar mecanismos que n&atilde;o os expressamente habilitados ou recomendados
			pelo <strong>SITE </strong>para obten&ccedil;&atilde;o de informa&ccedil;&otilde;es,
			conte&uacute;dos e servi&ccedil;os;</span></p>
		<ol>
			<li style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">
				realizar ou permitir engenharia reversa, traduzir, modificar, alterar a linguagem,
				modificar, compilar, decompilar, alugar, reproduzir, sublocar, divulgar, distribuir,
				transmitir, usar ou, de outra maneira que n&atilde;o as descritas aqui, dispor do <strong>SITE</strong>
				ou das ferramentas e funcionalidades nele disponibilizadas sob qualquer meio ou forma,
				inclusive de modo a violar direitos da <strong>Far.me</strong>, de <strong>USU&Aacute;RIOS</strong>,
				do <strong>SITE</strong> e de terceiros;</span></li>
			<li style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">praticar
				ou participar de qualquer ato que constitua uma viola&ccedil;&atilde;o de qualquer
				direito </span><span style="font-family: 'Arial',sans-serif; color: black;">da <strong>Far.me</strong></span><span
				style="font-family: 'Arial',sans-serif; color: black;">, de <strong>USU&Aacute;RIOS</strong>,
				de terceiros ou ainda de qualquer lei aplic&aacute;vel, ou agir sob qualquer meio ou
				forma que possa contribuir com tal viola&ccedil;&atilde;o;</span></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">xii. utilizar aplicativos
			automatizados de coleta e sele&ccedil;&atilde;o de dados para realizar
			opera&ccedil;&otilde;es massificadas ou para quaisquer finalidades ou ainda para coletar e
			transferir quaisquer dados que possam ser extra&iacute;dos do </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">SITE
		</span></strong><span style="font-family: 'Arial',sans-serif; color: black;">para fins
			n&atilde;o permitidos expressamente ou il&iacute;citos.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A seu exclusivo crit&eacute;rio
			e livre arb&iacute;trio, ser&aacute; atribu&iacute;do &agrave; <strong>Far.me</strong> o
			poder de bloquear, restringir, desabilitar ou impedir o acesso de qualquer <strong>USU&Aacute;RIO</strong>
			ao </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">SITE</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;">, total ou parcialmente, sem
			qualquer aviso pr&eacute;vio, sempre que for constatada uma conduta inadequada do <strong>USU&Aacute;RIO</strong>,
			sem preju&iacute;zo das medidas administrativas, extrajudiciais e judiciais que julgar
			convenientes.</span></p>
		<ol start="4">
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Cadastro de
				USU&Aacute;RIOS</span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Os <strong>USU&Aacute;RIOS
		</strong>dever&atilde;o criar sua <strong>Conta De Acesso</strong> para poderem usufruir
			dos servi&ccedil;os, conte&uacute;do completo e todas as funcionalidades, ferramentas e
			benef&iacute;cios do <strong>SITE</strong> e da <strong>Far.me</strong> onde ser&aacute;
			solicitado dados pessoais &ndash; como nome completo, e-mail, data de nascimento, telefone,
			CPF, endere&ccedil;o e lista dos medicamentos de uso cont&iacute;nuo com suas respectivas
			posologias somado ao anexo da prescri&ccedil;&atilde;o &ndash; a fim de serem armazenados e
			utilizados para identifica&ccedil;&atilde;o e fornecimento dos servi&ccedil;os da <strong>Far.me</strong>
			e conforme este documento e seus acess&oacute;rios.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">&Eacute; v&aacute;lido
			ressaltar que a cria&ccedil;&atilde;o de cada <strong>Conta de Acesso</strong>
			pressup&otilde;e o consentimento expresso sobre coleta, uso, armazenamento e tratamento de
			dados pessoais pela <strong>Far.me</strong> e/ou por terceiros por ela contratados para
			realizar qualquer procedimento ou processo relacionado &agrave; <strong>Far.me</strong>,
			inclusive processamento de pagamentos.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">No momento da
			cria&ccedil;&atilde;o de sua Conta de Acesso, o <strong>USU&Aacute;RIO</strong>
			definir&aacute; um nome de usu&aacute;rio (login) e definir&aacute; a sua senha de acesso.
			&Eacute; de exclusiva responsabilidade do <strong>USU&Aacute;RIO</strong> a
			manuten&ccedil;&atilde;o do sigilo do nome de usu&aacute;rio e/ou da senha de acesso
			relativos &agrave; sua <strong>Conta de Acesso</strong>.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Outros dados a serem coletados
			para cadastro na plataforma ser&atilde;o os receitu&aacute;rios m&eacute;dicos originais de
			forma f&iacute;sica ou atrav&eacute;s de fotografia a ser enviada pelo <strong>USU&Aacute;RIO</strong>,
			de acordo com a necessidade do caso, especifica&ccedil;&otilde;es de hor&aacute;rios,
			endere&ccedil;o para entrega dentre outros necess&aacute;rios para a completa
			presta&ccedil;&atilde;o dos servi&ccedil;os da <strong>Far.me</strong>, quando houver
			necessidade informada pela pr&oacute;pria <strong>Far.me</strong>. A exig&ecirc;ncia dos
			dados pode se alterar de acordo com a especificidade do m&eacute;todo de entrega desejado.
		</span></p>
		<p><strong><span style="font-family: 'Arial',sans-serif; color: black;">Ao completar a sua
			Conta de Acesso, o USU&Aacute;RIO declara que as informa&ccedil;&otilde;es fornecidas
			s&atilde;o completas, verdadeiras, atuais e precisas, sendo de total responsabilidade do
			USU&Aacute;RIO a atualiza&ccedil;&atilde;o dos dados de sua Conta de Acesso sempre que
			houver modifica&ccedil;&atilde;o de nome, endere&ccedil;o, medicamentos que faz uso ou
			qualquer outra informa&ccedil;&atilde;o relevante.</span></strong></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A <strong>Far.me</strong>
			poder&aacute; recusar, suspender ou cancelar a Conta de Acesso de um <strong>USU&Aacute;RIO</strong>
			sem pr&eacute;vio aviso sempre que suspeitar que as informa&ccedil;&otilde;es fornecidas
			s&atilde;o falsas, incompletas, desatualizadas, duplicadas ou imprecisas ou ainda nos casos
			indicados nas leis e regulamentos vigentes aplic&aacute;veis, nesses <strong>TERMOS</strong>
			e seus acess&oacute;rios, mesmo que anteriormente aceito.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Ser&aacute; proibido o cadastro
			no <strong>SITE</strong> de <strong>USU&Aacute;RIOS</strong> menores de 18 (dezoito) anos
			que n&atilde;o estejam devidamente acompanhados ou representados pelos seus
			respons&aacute;veis legais no momento do cadastro devido &agrave; sua incapacidade civil.
		</span></p>
		<p><strong><span style="font-family: 'Arial',sans-serif; color: black;">4.1.</span></strong>
			<strong><span style="font-family: 'Arial',sans-serif; color: black;">Direitos e
				informa&ccedil;&otilde;es </span></strong></p>
		<p><span style="font-family: 'Arial',sans-serif;">Os <strong><span style="color: black;">USU&Aacute;RIOS</span></strong><span
			style="color: black;"> cadastrados na plataforma <strong>Far.me</strong></span> somente
			ter&atilde;o direito de acesso aos servi&ccedil;os prestados pela <strong>Far.me</strong>
			caso estejam em dia com o pagamento acordado.</span></p>
		<p><span style="font-family: 'Arial',sans-serif;">O <strong>USU&Aacute;RIO</strong> tem como
			inteira responsabilidade manter as informa&ccedil;&otilde;es de cadastro e forma de
			pagamento atualizadas com o dever de comunicar &agrave; <strong>Far.me</strong> qualquer
			modifica&ccedil;&atilde;o. </span></p>
		<ol start="5">
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;">Pre&ccedil;os e
				pagamentos &agrave; Far.me</span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Os </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">USU&Aacute;RIOS
		</span></strong><span style="font-family: 'Arial',sans-serif; color: black;">cadastrados na
			plataforma <strong>Far.me</strong> </span><span style="font-family: 'Arial',sans-serif; color: black;">se
			obrigam a pagar os valores definidos no momento da contrata&ccedil;&atilde;o, de acordo com
			o valor dos medicamentos aos quais faz uso e do servi&ccedil;o de organiza&ccedil;&atilde;o
			dos medicamentos de acordo com o hor&aacute;rio e dia prescritos para obter o recebimento
			no dia e local desejado, dentro das regras e condi&ccedil;&otilde;es informadas no momento
			da contrata&ccedil;&atilde;o, podendo optar por envio de boleto ou cadastro do
			cart&atilde;o de cr&eacute;dito.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">As informa&ccedil;&otilde;es do
			cart&atilde;o de cr&eacute;dito a ser cadastrado n&atilde;o ser&atilde;o armazenadas na
			plataforma, tendo em vista que a cobran&ccedil;a do cart&atilde;o ser&aacute; realizada
			pela IUGU, plataforma de gest&atilde;o e automa&ccedil;&atilde;o de pagamentos online.
		</span></p>
		<p><strong><span style="font-family: 'Arial',sans-serif; color: black;">A assinatura dos
			servi&ccedil;os ser&aacute; de car&aacute;ter mensal, tendo sua renova&ccedil;&atilde;o
			realizada automaticamente por per&iacute;odos iguais e sucessivos na hip&oacute;tese de
			n&atilde;o ser comunicada manifesta&ccedil;&atilde;o em contr&aacute;rio, no sentido de
			n&atilde;o interesse na renova&ccedil;&atilde;o, por uma das partes e com no
			m&iacute;nimo 15 (quinze) dias de anteced&ecirc;ncia ao ciclo seguinte de envio da box de
			medicamentos.</span></strong></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Em casos de cancelamentos
			n&atilde;o ser&atilde;o cobradas taxas.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A <strong>Far.me</strong> se
			reserva ao direito de mudan&ccedil;a unilateral na plataforma e procedimento do pagamento
			que, a seu crit&eacute;rio, melhor lhe couber por meio de aviso pr&eacute;vio ao </span><strong><span
			style="font-family: 'Arial',sans-serif; color: black;">USU&Aacute;RIOS</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> cadastrados na plataforma <strong>Far.me</strong></span><span
			style="font-family: 'Arial',sans-serif; color: black;">, que poder&atilde;o optar com
			liberalidade pelo cancelamento da assinatura da <strong>Far.me</strong> caso discordem da
			mudan&ccedil;a realizada.</span></p>
		<ol start="6">
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Dados Pessoais,
				Privacidade e Seguran&ccedil;a</span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A <strong>Far.me</strong> se
			compromete a respeitar os Dados Pessoais, a Privacidade e a Seguran&ccedil;a dos <strong>USU&Aacute;RIOS</strong>,
			sempre tomando cautelosamente as medidas necess&aacute;rias para garantir a
			prote&ccedil;&atilde;o de seus <strong>USU&Aacute;RIOS</strong> nos momentos em que estiver
			utilizando o <strong>SITE</strong>.</span></p>
		<p style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">Para
			a total utiliza&ccedil;&atilde;o do <strong>SITE</strong> &eacute; necess&aacute;ria a
			<strong>Conta de Acesso</strong> com o registro de alguns dados de
			informa&ccedil;&otilde;es pessoais do <strong>USU&Aacute;RIO</strong>.</span></p>
		<p style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">Os
			dados solicitados nos formul&aacute;rios do&nbsp;<strong><span style="border: none windowtext 1.0pt; padding: 0cm;">SITE</span></strong>&nbsp;servem
			para gest&atilde;o, navega&ccedil;&atilde;o e consulta do pr&oacute;prio <strong>SITE</strong>.
			O <strong>USU&Aacute;RIO</strong> reconhece e concorda expressamente que a base de dados
			poder&aacute; ser total ou parcialmente cedida ou transferida a terceiros, desde que
			respeitada finalidade &uacute;nica e exclusiva de dar continuidade &agrave; atividade
			prestada pela&nbsp;<strong><span style="border: none windowtext 1.0pt; padding: 0cm;">Far.me</span></strong>&nbsp;ou
			se existir uma obrigatoriedade legal nesse sentido. Os dados do <strong>USU&Aacute;RIO</strong>
		</span><span style="font-family: 'Arial',sans-serif; color: black;">s&atilde;o armazenados em
			servidores terceirizados com prote&ccedil;&atilde;o SSL (criptografia) para obedecer
			&agrave; legisla&ccedil;&atilde;o p&aacute;tria aplic&aacute;vel e para melhorar a
			qualidade dos servi&ccedil;os da </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><strong><span
			style="font-family: 'Arial',sans-serif; color: black;">.</span></strong></p>
		<p style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">A
			<strong>Far.me</strong> respeita a privacidade de seus <strong>USU&Aacute;RIOS</strong> e
			n&atilde;o envia e-mails indesejados e n&atilde;o solicitados aos mesmos. Quando o <strong>USU&Aacute;RIO</strong>
			se cadastra no <strong>SITE </strong>e cria uma <strong>Conta de Acesso</strong> e permite
			o envio de e-mails marketing sobre as funcionalidades do <strong>SITE</strong> e seus
			servi&ccedil;os, passar&aacute; a receb&ecirc;-los, entretanto, sempre que um <strong>USU&Aacute;RIO</strong>
			decida que n&atilde;o pretende mais receb&ecirc;-los, dever&aacute; mencionar tal fato
			&agrave;&nbsp;<strong>Far.me</strong>, no momento em que recebe o e-mail ou,
			posteriormente, por meio dos canais de atendimento disponibilizados pelo&nbsp;<strong><span
				style="border: none windowtext 1.0pt; padding: 0cm;">SITE</span></strong>. Quando a
			<strong>Far.me</strong> for cientificada dessa decis&atilde;o do <strong>USU&Aacute;RIO</strong>
			de n&atilde;o mais receber os e-mails, envidar&aacute; de meios para o cumprimento dessa
			solicita&ccedil;&atilde;o, deixando de programar o envio de novos e-mails em at&eacute;
			cinco dias, n&atilde;o se responsabilizando apenas pelos e-mails que j&aacute; estavam
			programados para serem enviados antes da solicita&ccedil;&atilde;o e que n&atilde;o puderem
			ser cancelados.</span></p>
		<p style="margin: 0cm; margin-bottom: .0001pt; background: white; vertical-align: baseline;"><span
			style="font-size: 11.0pt; font-family: 'Arial',sans-serif; color: black;">A
			utiliza&ccedil;&atilde;o do <strong>SITE</strong> implica no consentimento do <strong>USU&Aacute;RIO</strong>
			para coleta, armazenamento e uso das informa&ccedil;&otilde;es da empresa ou pessoais
			fornecidas e suas atualiza&ccedil;&otilde;es, dados de tr&aacute;fego, endere&ccedil;os IP,
			entre outros. O <strong>USU&Aacute;RIO</strong> desde j&aacute; autoriza a <strong>Far.me</strong>
			a, a seu crit&eacute;rio, preservar, armazenar todos os conte&uacute;dos submetidos ao
			<strong>SITE</strong>, bem como todos os seus dados da empresa ou pessoais e demais
			informa&ccedil;&otilde;es fornecidas e/ou coletadas.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> disp&otilde;e de uma
			pol&iacute;tica espec&iacute;fica para regular a coleta, guarda e utiliza&ccedil;&atilde;o
			de dados, bem como a sua seguran&ccedil;a: <a @click="$emit('abrir-politica-privacidade')">Pol&iacute;tica
				de Privacidade e Seguran&ccedil;a</a> Essa pol&iacute;tica &eacute; acess&oacute;ria e
			integra inseparavelmente esses <strong>TERMOS</strong> de Uso.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Os dados de
			utiliza&ccedil;&atilde;o do <strong>SITE</strong> ser&atilde;o arquivados conforme
			legisla&ccedil;&atilde;o p&aacute;tria em vigor.</span></p>
		<ol start="7">
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Propriedade
				Intelectual e Industrial</span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">N&atilde;o ser&aacute;
			autorizada a utiliza&ccedil;&atilde;o das Marcas, sob nenhuma hip&oacute;tese, pelos
			<strong>USU&Aacute;RIOS</strong> e/ou terceiros, nem, tampouco suas
			reprodu&ccedil;&otilde;es parciais ou integrais ou ainda suas imita&ccedil;&otilde;es,
			independentemente da destina&ccedil;&atilde;o de tal uso. O <strong>USU&Aacute;RIO</strong>
			compromete-se a n&atilde;o contestar a validade de qualquer Marca ou de qualquer outro
			sinal distintivo depositado ou registrado pela </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> ou por quaisquer empresas a ela
			vinculadas, sob qualquer forma, no Brasil ou no exterior.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Todo o conte&uacute;do do
			<strong>SITE</strong>, incluindo o nome de dom&iacute;nio &ldquo;farme.com&rdquo;,
			programas, bases de dados, arquivos, textos, fotos, layouts, cabe&ccedil;alhos e demais
			elementos foi criado, desenvolvido ou cedido &agrave; </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;">, sendo de sua propriedade ou a ela
			licenciado e encontra-se protegido pelas leis brasileiras vigentes e tratados
			internacionais que versam sobre direitos de propriedade intelectual. </span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O <strong>USU&Aacute;RIO</strong>,
			ao acessar o <strong>SITE</strong> concorda que respeitar&aacute; a exist&ecirc;ncia e a
			extens&atilde;o dos direitos de Propriedade Intelectual da </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;">, bem como de todos os direitos de
			terceiros que sejam usados, a qualquer t&iacute;tulo, no <strong>SITE</strong> ou que
			venham a ser disponibilizados de outras formas.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O acesso ao <strong>SITE</strong>
			e a sua regular utiliza&ccedil;&atilde;o pelo <strong>USU&Aacute;RIO</strong> n&atilde;o
			lhe confere qualquer direito ou prerrogativa sobre o uso de qualquer Propriedade
			Intelectual, Marca ou outro conte&uacute;do nele inserido.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O <strong>USU&Aacute;RIO</strong>
			que violar as proibi&ccedil;&otilde;es contidas na legisla&ccedil;&atilde;o p&aacute;tria
			vigente sobre propriedade intelectual e nesses <strong>TERMOS</strong> ser&atilde;o
			responsabilizados, civil e criminalmente, pelas infra&ccedil;&otilde;es cometidas.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O <strong>USU&Aacute;RIO</strong>
			est&aacute; de acordo e reconhece que a totalidade dos elementos do <strong>SITE</strong> e
			cada uma de suas ferramentas, as informa&ccedil;&otilde;es e conte&uacute;dos materiais do
			e dos programas operacionais utilizados por ambos, est&atilde;o protegidos por direitos de
			propriedade intelectual e industrial da </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> ou de terceiros.</span></p>
		<ol start="8">
			<li><strong><span style="font-family: 'Arial',sans-serif; color: black;"> Exclus&atilde;o de
				Garantias e Responsabilidade</span></strong></li>
		</ol>
		<p style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">8.1.
			Disponibilidade e Continuidade do SITE e dos Servi&ccedil;os</span></strong></p>
		<p style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">De
			acordo com os limites estabelecidos na legisla&ccedil;&atilde;o p&aacute;tria vigente,
			a&nbsp;<strong>Far.me</strong>&nbsp;n&atilde;o ser&aacute; respons&aacute;vel pelos danos e
			preju&iacute;zos causados ao <strong>USU&Aacute;RIO</strong> como consequ&ecirc;ncia da
			indisponibilidade, falhas de acesso e falta de continuidade do&nbsp;<strong><span style="border: none windowtext 1.0pt; padding: 0cm;">SITE</span></strong>&nbsp;e
			de seus servi&ccedil;os por raz&otilde;es alheias &agrave; sua vontade ou quando, assim
			comunicar com anteced&ecirc;ncia, por livre liberalidade, ao <strong>USU&Aacute;RIO</strong>.</span></p>
		<p style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">8.2.
			Conte&uacute;dos e Servi&ccedil;os da Far.me</span></strong></p>
		<p><span style="font-family: 'Arial',sans-serif;">A <strong>Far.me </strong>&eacute; uma
			prestadora de servi&ccedil;os, oferecendo como vantagem a simplifica&ccedil;&atilde;o e
			praticidade em lidar com medicamentos de forma mais segura e acess&iacute;vel para aqueles
			que necessitam de medicamentos cont&iacute;nuos<span style="color: black;">; a <strong>Far.me</strong>
			</span></span><span style="font-family: 'Arial',sans-serif; color: black;">tamb&eacute;m
			traz como benef&iacute;cio a entrega dos medicamentos organizados conforme posologia,
			devidamente identificados e acondicionados em seu dispensador personalizado com a
			rastreabilidade completa do processo e rotulagem conforme legisla&ccedil;&atilde;o vigente.
		</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Caso o <strong>USU&Aacute;RIO</strong>
			opte por usufruir da consulta farmac&ecirc;utica, esse poder&aacute; ser atendido em
			domic&iacute;lio, na farm&aacute;cia sede ou por via remota por um farmac&ecirc;utico, com
			objetivo de promover o uso seguro de medicamentos e garantir a m&aacute;xima efetividade e
			seguran&ccedil;a no tratamento, mediante contrata&ccedil;&atilde;o separada da assinatura
			dos medicamentos atrav&eacute;s de solicita&ccedil;&atilde;o expressa &agrave; <strong>Far.me</strong>.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Dentro dos servi&ccedil;os
			prestados pela <strong>Far.me</strong> h&aacute; a comercializa&ccedil;&atilde;o dos
			medicamentos aos quais o <strong>USU&Aacute;RIO</strong> fa&ccedil;a uso a fim de que a box
			de medicamentos seja eficientemente montada e disponibilizada.</span></p>
		<p style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">A
			<strong>Far.me</strong>&nbsp;procura manter o&nbsp;<strong><span style="border: none windowtext 1.0pt; padding: 0cm;">SITE
			</span></strong>sempre atualizado, da melhor forma poss&iacute;vel, estando preciso e
			completo, mas n&atilde;o se responsabiliza por eventuais imprecis&otilde;es, erros,
			fraudes, inexatid&atilde;o ou diverg&ecirc;ncias nos dados. Sendo verificada qualquer das
			situa&ccedil;&otilde;es aqui elencadas, o <strong>USU&Aacute;RIO</strong> pode entrar em
			contato com a <strong>Far.me</strong> atrav&eacute;s do <strong>Contato</strong> para que a
			<strong>Far.me</strong> possa ser cientificada da situa&ccedil;&atilde;o e consequentemente
			san&aacute;-la, sendo o caso.</span></p>
		<p style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">A
			<strong>Far.me </strong>garante que todos servi&ccedil;os prestados ser&atilde;o executados
			por profissionais habilitados e competentes, inscritos em seu conselho profissional
			respons&aacute;vel.</span></p>
		<p style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">A
			Far.me n&atilde;o se responsabiliza por prescri&ccedil;&otilde;es realizadas por
			m&eacute;dicos e/ou farmac&ecirc;uticos regularmente credenciados nos
			&oacute;rg&atilde;os competentes, Conselho Regional de Medicina-CRM e Conselho Regional
			de Farm&aacute;cia-CRF respectivamente, de confian&ccedil;a do USU&Aacute;RIO, ficando
			sob sua exclusiva responsabilidade a escolha destes.</span></strong></p>
		<p style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">O
			usu&aacute;rio concorda expressamente que a Far.me est&aacute; isenta de qualquer
			responsabilidade pelos medicamentos indicados pelo m&eacute;dico do USU&Aacute;RIO,
			cabendo apenas a venda e organiza&ccedil;&atilde;o dos medicamentos indicados pelo
			USU&Aacute;RIO atrav&eacute;s de seu m&eacute;dico e/ou farmac&ecirc;utico de
			confian&ccedil;a.</span></strong></p>
		<p style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">A&nbsp;<strong>Far.me</strong>&nbsp;responder&aacute;
			&uacute;nica e exclusivamente pelas atividades que desenvolva por si mesmo e pelos
			conte&uacute;dos diretamente criados ou produzidos pela mesma&nbsp;e identificados.</span></p>
		<p><strong><span style="font-family: 'Arial',sans-serif; color: black;">8.3. Conduta dos
			USU&Aacute;RIOS</span></strong></p>
		<p><span style="font-family: 'Arial',sans-serif;">O <strong>USU&Aacute;RIO</strong>
			dever&aacute; respeitar as orienta&ccedil;&otilde;es do m&eacute;dico e/ou
			farmac&ecirc;uticos respons&aacute;veis, sendo seu dever exclusivo a
			administra&ccedil;&atilde;o dos medicamentos de forma correta ap&oacute;s a entrega da box
			de medicamentos personalizada.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O <strong>USU&Aacute;RIO</strong>
			dever&aacute; informar &agrave; <strong>Far.me</strong> eventual altera&ccedil;&atilde;o do
			tratamento em at&eacute;, no m&aacute;ximo, 24 horas ap&oacute;s a mudan&ccedil;a,
			considerando o limite m&aacute;ximo de at&eacute; dez dias antes da data de entrega do
			pr&oacute;ximo ciclo, sob pena do <strong>USU&Aacute;RIO</strong> arcar com perda dos
			medicamentos que eventualmente n&atilde;o mais estiver fazendo o uso. Dessa forma, o
			&ocirc;nus ser&aacute; de responsabilidade exclusiva do <strong>USU&Aacute;RIO</strong> em
			se organizar no sentido de comunicar previamente &agrave; <strong>Far.me</strong> em caso
			de mudan&ccedil;as nos medicamentos aos quais faz uso regular.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A&nbsp;</span><strong><span
			style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span style="font-family: 'Arial',sans-serif; color: black;">&nbsp;n&atilde;o
			consegue garantir que os <strong>USU&Aacute;RIOS</strong> do&nbsp;<strong><span style="border: none windowtext 1.0pt; padding: 0cm;">SITE</span></strong>&nbsp;o
			utilizem </span><span style="font-family: 'Arial',sans-serif; color: black;">de acordo com
			esses <strong>TERMOS</strong> e seus acess&oacute;rios, com a legisla&ccedil;&atilde;o
			vigente aplic&aacute;vel no ordenamento jur&iacute;dico brasileiro, a moral, os bons
			costumes e a ordem p&uacute;blica. </span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;">&nbsp;n&atilde;o ser&aacute;
			respons&aacute;vel direta ou subsidiariamente por:</span></p>
		<ol>
			<li style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">
				danos e preju&iacute;zos causados a terceiros originados ou derivados da
				utiliza&ccedil;&atilde;o do&nbsp;<strong><span style="border: none windowtext 1.0pt; padding: 0cm;">SITE</span></strong>por
				parte do <strong>USU&Aacute;RIO</strong>;</span></li>
		</ol>
		<p style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">iii.
			danos e preju&iacute;zos de qualquer natureza derivados da utiliza&ccedil;&atilde;o do
			<strong>SITE</strong> pela falta de veracidade, autenticidade dos dados, exatid&atilde;o ou
			incorre&ccedil;&atilde;o da identidade dos <strong>USU&Aacute;RIOS</strong> e de toda
			informa&ccedil;&atilde;o que estes proporcionem ou fa&ccedil;am acess&iacute;veis a outros<strong>
				USU&Aacute;RIOS</strong> ou a terceiros;</span></p>
		<ol>
			<li style="background: white; vertical-align: baseline;"><span style="font-family: 'Arial',sans-serif; color: black;">
				descumprimento por parte de <strong>USU&Aacute;RIOS</strong> desses <strong>TERMOS</strong>
				e seus acess&oacute;rios e de orienta&ccedil;&otilde;es expressamente realizadas no
				<strong>SITE</strong>.</span></li>
		</ol>
		<ol start="9">
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Comunica&ccedil;&otilde;es e CONTATO Far.me </span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O <strong>Contato</strong>
			&eacute; a forma de comunica&ccedil;&atilde;o que o <strong>USU&Aacute;RIO</strong> possui
			para falar com a </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;">, que pode</span><span style="font-family: 'Arial',sans-serif; color: black;">
			ser contatada atrav&eacute;s dos seguintes canais de comunica&ccedil;&atilde;o:</span></p>
		<ol>
			<li><span style="font-family: 'Arial',sans-serif; color: black;"> Espa&ccedil;o para Contato
				no site www.farme.com;</span></li>
			<li><span style="font-family: 'Arial',sans-serif; color: black;"> Do telefone/whatsapp (31)
				4147-7009;</span></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">iii. E-mail contato@farme.com.br;</span></p>
		<ol>
			<li><span style="font-family: 'Arial',sans-serif; color: black;"> Correspond&ecirc;ncia
				endere&ccedil;ada &agrave; <strong>Far.me</strong>, conforme endere&ccedil;o informado no
				item 1;</span></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Em caso de
			realiza&ccedil;&atilde;o de contato, por qualquer meio, com a <strong>Far.me</strong>, as
			informa&ccedil;&otilde;es do <strong>USU&Aacute;RIOS</strong> dever&atilde;o estar
			explicitadas obrigatoriamente para possibilitar a resposta e finaliza&ccedil;&atilde;o do
			atendimento.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Em todos os casos o <strong>USU&Aacute;RIO</strong>
			receber&aacute; atrav&eacute;s do mesmo canal de comunica&ccedil;&atilde;o uma
			confirma&ccedil;&atilde;o imediata do recebimento da sua demanda, que ser&aacute; tratada e
			respondida pela </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> em um prazo m&aacute;ximo de 5
			(cinco) dias &uacute;teis ou dentro do prazo que se fizer necess&aacute;rio para a
			an&aacute;lise espec&iacute;fica do caso.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Da mesma forma, todas as
			comunica&ccedil;&otilde;es que a </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> desejar fazer ao <strong>USU&Aacute;RIO</strong>
			ser&atilde;o realizadas de forma segura, conforme cadastro e informa&ccedil;&otilde;es
			prestadas pelo <strong>USU&Aacute;RIO</strong> perante a </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;">.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A <strong>Far.me</strong>
			tamb&eacute;m se comunicar&aacute; com o <strong>USU&Aacute;RIO</strong> por outros meios
			de comunica&ccedil;&atilde;o, como telefone celular, SMS ou correspond&ecirc;ncia
			f&iacute;sica, quando houver necessidade.</span></p>
		<ol start="10">
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Dura&ccedil;&atilde;o e Atualiza&ccedil;&otilde;es</span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Os presentes <strong>TERMOS</strong>
			e Condi&ccedil;&otilde;es de Uso e seus acess&oacute;rios t&ecirc;m dura&ccedil;&atilde;o
			indefinida e permanecer&atilde;o em vigor enquanto o <strong>SITE</strong>, a <strong>Far.me</strong>
			e suas funcionalidades estiverem ativas.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A reda&ccedil;&atilde;o
			aplic&aacute;vel ser&aacute; aquela da data do acesso &agrave; <strong>Far.me</strong>. Da
			mesma forma, o acesso e a utiliza&ccedil;&atilde;o do <strong>SITE</strong> e dos recursos
			e ferramentas por ele oferecidos t&ecirc;m, em princ&iacute;pio, dura&ccedil;&atilde;o
			indeterminada, a exclusivo crit&eacute;rio da </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;">.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">No entanto, a</span><strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> Far.me</span></strong><span style="font-family: 'Arial',sans-serif; color: black;">
			reserva-se ao direito de suspender e/ou cancelar, de forma unilateral e a qualquer momento,
			o acesso ao <strong>SITE</strong> ou a algumas de suas partes ou a alguns de seus recursos,
			sem necessidade de pr&eacute;vio aviso aos <strong>USU&Aacute;RIOS</strong>, com
			exce&ccedil;&atilde;o dos <strong>USU&Aacute;RIOS</strong> cadastrados na plataforma
			<strong>Far.me</strong></span><span style="font-family: 'Arial',sans-serif;"> <span style="color: black;">que
			ser&atilde;o avisados com 30 (trinta) dias de anteced&ecirc;ncia.</span></span></p>
		<p><strong><span style="font-family: 'Arial',sans-serif; color: black;">A </span></strong><strong><span
			style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> tamb&eacute;m se reserva ao
			direito de revisar, aprimorar, modificar e/ou atualizar, unilateralmente e a qualquer
			momento, qualquer cl&aacute;usula ou disposi&ccedil;&atilde;o contida nesses TERMOS e
			Condi&ccedil;&otilde;es de Uso ou em seus acess&oacute;rios.</span></strong></p>
		<p><strong><span style="font-family: 'Arial',sans-serif; color: black;">A vers&atilde;o
			atualizada valer&aacute; para o uso do SITE e para os acessos realizados a partir de sua
			divulga&ccedil;&atilde;o pela </span></strong><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><strong><span
			style="font-family: 'Arial',sans-serif; color: black;">, sendo que a continuidade de
			acesso ou utiliza&ccedil;&atilde;o desse SITE, depois da divulga&ccedil;&atilde;o de
			quaisquer modifica&ccedil;&otilde;es, confirmar&aacute; a aceita&ccedil;&atilde;o dos
			novos TERMOS e Condi&ccedil;&otilde;es de Uso e de seus acess&oacute;rios.</span></strong></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Caso o <strong>USU&Aacute;RIO</strong>
			n&atilde;o esteja de acordo com uma determinada altera&ccedil;&atilde;o dos <strong>TERMOS</strong>
			e Condi&ccedil;&otilde;es de Uso e de seus acess&oacute;rios, poder&aacute; rescindir seu
			v&iacute;nculo com a </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> por meio de pedido de
			exclus&atilde;o da <strong>Conta de Acesso</strong> no <strong>SITE</strong> atrav&eacute;s
			do <strong>Contato</strong>, entretanto, caso o <strong>USU&Aacute;RIO</strong> queira
			realizar alguma retifica&ccedil;&atilde;o ou observa&ccedil;&atilde;o sobre as
			altera&ccedil;&otilde;es posteriores realizadas nesses <strong>TERMOS</strong> e em seus
			acess&oacute;rios, dever&aacute; entrar em contato com a </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> atrav&eacute;s dos meios
			disponibilizados e aguardar o retorno da </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> acerca do aceite &agrave;s
			observa&ccedil;&otilde;es e/ou retifica&ccedil;&otilde;es solicitadas. A </span><strong><span
			style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span style="font-family: 'Arial',sans-serif; color: black;">
			se reserva no direito de n&atilde;o aceitar as retifica&ccedil;&otilde;es e
			observa&ccedil;&otilde;es realizadas pelo <strong>USU&Aacute;RIO</strong> e a vedar o
			acesso para utiliza&ccedil;&atilde;o do <strong>SITE</strong> caso n&atilde;o aceite esses
			<strong>TERMOS</strong> e seus acess&oacute;rios sem as mesmas, em seu formato original e
			registrado.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">No entanto, a
			solicita&ccedil;&atilde;o de rescis&atilde;o devido a uma altera&ccedil;&atilde;o de
			conte&uacute;do realizada unilateralmente pela </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> n&atilde;o eximir&aacute; o <strong>USU&Aacute;RIO</strong>
			de cumprir com todas as obriga&ccedil;&otilde;es assumidas sob as vers&otilde;es
			precedentes das dos <strong>TERMOS</strong> e Condi&ccedil;&otilde;es de Uso e de seus
			acess&oacute;rios.</span></p>
		<ol start="11">
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Cancelamento da Far.me</span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Caso o <strong>USU&Aacute;RIO</strong>
			cadastrado na plataforma <strong>Far.me</strong></span><span style="font-family: 'Arial',sans-serif;">
			<span style="color: black;">cancele a ader&ecirc;ncia &agrave; assinatura <strong>Far.me</strong>
				os pagamentos j&aacute; realizados n&atilde;o ser&atilde;o reembolsados, bem como em caso
				de cancelamento em prazo inferior a dez dias de anteced&ecirc;ncia ao pr&oacute;ximo
				ciclo os pagamentos dever&atilde;o ser realizados normalmente.</span></span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Caso o <strong>USU&Aacute;RIO</strong>
			tenha interesse em cancelar a assinatura <strong>Far.me</strong>, dever&aacute;
			faz&ecirc;-lo com no m&iacute;nimo 10 (dez) dias de anteced&ecirc;ncia ao pr&oacute;ximo
			ciclo programado de envio da pr&oacute;xima box de medicamentos. Neste caso, n&atilde;o
			haver&aacute; qualquer taxa a ser cobrada pelo cancelamento.</span></p>
		<ol start="12">
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Condi&ccedil;&otilde;es Gerais</span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Em caso de problemas com o
			<strong>SITE</strong>, o <strong>USU&Aacute;RIO</strong> dever&aacute; entrar em <strong>Contato</strong>
			com a <strong>Far.me </strong>atrav&eacute;s dos seus diversos canais de atendimento.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Nenhuma das pessoas envolvidas
			nesse contrato ser&aacute; responsabilizada perante a outra quando o descumprimento ou o
			cumprimento extempor&acirc;neo de uma obriga&ccedil;&atilde;o prevista nesses <strong>TERMOS</strong>
			e em seus acess&oacute;rios for causado por casos fortuitos ou eventos de for&ccedil;a
			maior, enquanto perduraram as suas consequ&ecirc;ncias.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Nas contrata&ccedil;&otilde;es
			da <strong>Far.me</strong> realizadas pelo <strong>SITE</strong>, dever&aacute; ser
			considerado oficial o hor&aacute;rio da capital do Brasil, Bras&iacute;lia, inclusive nos
			per&iacute;odos de hor&aacute;rio de ver&atilde;o.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Caso, em uma eventualidade, a
		</span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> n&atilde;o consiga fazer valer ou
			cumprir qualquer cl&aacute;usula ou condi&ccedil;&atilde;o contida nesses <strong>TERMOS</strong>
			e em seus acess&oacute;rios, tal fato n&atilde;o configurar&aacute; desist&ecirc;ncia,
			toler&acirc;ncia ou nova&ccedil;&atilde;o dessa cl&aacute;usula ou condi&ccedil;&atilde;o
			desses <strong>TERMOS</strong> e de seus acess&oacute;rios.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Se alguma cl&aacute;usula ou
				condi&ccedil;&atilde;o contida nesses <strong>TERMOS</strong> e em seus acess&oacute;rios
				for declarada inv&aacute;lida, no todo ou parcialmente, tal invalidade n&atilde;o
				afetar&aacute; as demais cl&aacute;usulas dos <strong>TERMOS</strong> e de seus
				acess&oacute;rios. Neste caso, a </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong>
			<span style="font-family: 'Arial',sans-serif; color: black;">far&aacute; as
				adapta&ccedil;&otilde;es necess&aacute;rias para que reflitam, da forma mais pr&oacute;xima
				poss&iacute;vel, os <strong>TERMOS</strong> da provis&atilde;o declarada
				inexequ&iacute;vel. </span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> tem como princ&iacute;pio de sua
			atua&ccedil;&atilde;o o respeito ao <strong>USU&Aacute;RIO</strong>, agindo sempre em
			conformidade com as disposi&ccedil;&otilde;es do C&oacute;digo de Prote&ccedil;&atilde;o e
			Defesa do Consumidor (Lei Federal n&ordm; 8.078 de 1990), do Marco Civil da Internet (Lei
			Federal n&ordm; 12.965 de 2014) e das demais normas referentes ao com&eacute;rcio de
			produtos em ambiente eletr&ocirc;nico previstas em nosso ordenamento jur&iacute;dico
			p&aacute;trio.</span></p>
		<ol start="13">
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Registro P&uacute;blico</span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Esses <strong>TERMOS</strong> e
			Condi&ccedil;&otilde;es de Uso e os seus acess&oacute;rios, encontram-se registrados no
			1&ordm; Of&iacute;cio de Registro de T&iacute;tulos e Documentos da comarca da capital do
			estado de Minas Gerais, Belo Horizonte.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Eventuais
			atualiza&ccedil;&otilde;es desses <strong>TERMOS</strong> e Condi&ccedil;&otilde;es de Uso,
			bem como de qualquer de seus acess&oacute;rios, ser&atilde;o imediatamente registradas no
			mesmo cart&oacute;rio para acesso p&uacute;blico de qualquer pessoa interessada.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">De qualquer forma, todos esses
			documentos podem ser consultados online no <strong>SITE</strong>.</span></p>
		<ol start="14">
			<li style="background: white; vertical-align: baseline;"><strong><span style="font-family: 'Arial',sans-serif; color: black;">
				Lei aplic&aacute;vel e Foro de Elei&ccedil;&atilde;o</span></strong></li>
		</ol>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">O <strong>SITE</strong>
			&eacute; controlado, operado e administrado pela </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> na cidade de Belo Horizonte, Estado
			de Minas Gerais, Brasil, podendo ser acessado por qualquer dispositivo conectado &agrave;
			rede mundial de computadores.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">Em vista das diferen&ccedil;as
			que podem existir entre as legisla&ccedil;&otilde;es locais e nacionais, ao acessar o
			<strong>SITE</strong>, o <strong>USU&Aacute;RIO</strong> concorda que a
			legisla&ccedil;&atilde;o aplic&aacute;vel para fins desses <strong>TERMOS</strong> e seus
			acess&oacute;rios ser&aacute; aquela vigente na Rep&uacute;blica Federativa do Brasil.</span></p>
		<p><span style="font-family: 'Arial',sans-serif; color: black;">A </span><strong><span style="font-family: 'Arial',sans-serif; color: black;">Far.me</span></strong><span
			style="font-family: 'Arial',sans-serif; color: black;"> e o <strong>USU&Aacute;RIO</strong>
			concordam que preferencialmente o foro da comarca de Belo Horizonte, Minas Gerais, Brasil,
			ser&aacute; o competente para dirimir qualquer quest&atilde;o ou controv&eacute;rsia
			oriunda ou resultante do uso do <strong>SITE</strong>.</span></p>

	</Dialog>
</template>

<script>
export default {
	name: 'TermosDeUso'
};
</script>
