<template>
	<transition>
		<v-card style="border-radius: 0 !important">
			<v-toolbar dark flat class="purple-title" height="70px">
				<v-toolbar-title class="titulo">{{title}}</v-toolbar-title>
				<v-spacer />
				<v-btn dark flat @click="$emit('close')">
					<v-icon small class="mr-2">close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-text v-if="assinatura">
				<v-container grid-list-xl class="pa-0">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<InformacoesPacienteResponsavelEndereco :paciente="assinatura.paciente" :responsaveis="assinatura.paciente.responsaveis || [assinatura.responsavel]" :endereco="assinatura.endereco" :assinatura="assinatura" disabled />
								</v-flex>
								<v-flex xs12>
									<v-card>
										<v-card-title class="pb-0">
											<v-avatar size="38" class="mr-3">
												<v-icon color="white" small>fas fa-exclamation</v-icon>
											</v-avatar>
											<h3>Observação</h3>
										</v-card-title>
										<v-card-text>
											<v-textarea v-model="assinatura.observacao" placeholder="Deseja acrescentar alguma observação?" disabled />
										</v-card-text>
									</v-card>
								</v-flex>
								<v-flex xs6>
									<v-card>
										<v-card-title class="pb-0">
											<v-avatar size="38" class="mr-3">
												<v-icon color="white" small>fas fa-funnel-dollar</v-icon>
											</v-avatar>
											<h3>Serviços Farmaceuticos</h3>
										</v-card-title>
										<v-card-text>
											<v-text-field v-model="assinatura.valor_servico" v-money placeholder="Desconto na assinatura" disabled />
										</v-card-text>
									</v-card>
								</v-flex>
								<v-flex xs6>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12 v-if="assinatura.medicamentos">
									<Medicamentos :medicamentos="assinatura.medicamentos" disabled />
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-text v-else>
				<div class="carregando">
					<v-progress-circular indeterminate color="primary" />
				</div>
			</v-card-text>
		</v-card>
	</transition>
</template>

<script>
import { HistoricoAssinaturaPresenter } from '@Presenter/historico-assinatura-presenter';
import moment from 'moment';
import InformacoesPacienteResponsavelEndereco from './informacoes-paciente-responsavel-endereco';
import Medicamentos from './medicamentos';

export default {
	components: { InformacoesPacienteResponsavelEndereco, Medicamentos },
	props: {
		id: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		historico: null,
		loading: true,
		error: false
	}),
	computed: {
		assinatura() {
			return (this.historico || {}).alteracoes;
		},
		title() {
			if (this.historico)
				return `Histórico da assinatura - ${moment(this.historico.created_at).format('DD/MM/YYYY HH:mm:ss')}`;
			return 'Histórico da assinatura';
		}
	},
	created() {
		HistoricoAssinaturaPresenter.show(this.id)
			.then(response => {
				this.historico = response;
			})
			.catch(() => {
				this.error = true;
			})
			.finally(() => {
				this.loading = false;
			});
	}
};
</script>

<style lang="scss" scoped>

</style>
