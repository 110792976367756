var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"custom-data-table",attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"total-items":_vm.total,"disable-initial-sort":true,"pagination":_vm.pagination,"rows-per-page-text":"Items por página","rows-per-page-items":_vm.itemsPerPageOptions,"no-results-text":"Nenhum resultado encontrado","no-data-text":_vm.loading ? 'Carregando...' : 'Nenhum intem encontrado'},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',[_c('th',{attrs:{"width":"10px"}},[_c('v-checkbox',{attrs:{"color":"primary","input-value":props.all,"indeterminate":props.indeterminate,"hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleAll.apply(null, arguments)}}})],1),_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"column",class:[
						header.sortable ? 'sortable' :  '',
						_vm.pagination.descending ? 'desc' : 'asc',
						header.value === _vm.pagination.sortBy ? 'active' : ''
					],attrs:{"width":header.width},on:{"click":function($event){return _vm.changeSort(header)}}},[(header.sortable )?_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]):_vm._e(),_vm._v(" "+_vm._s(header.text)+" ")],1)})],2)]}},{key:"items",fn:function(props){return [_c('tr',{attrs:{"active":props.selected}},[_c('td',{on:{"click":function($event){props.selected = !props.selected}}},[_c('v-checkbox',{attrs:{"input-value":props.selected,"primary":"","hide-details":""}})],1),_c('td',{staticClass:"text-left"},[_c('router-link',{staticClass:"black--text",attrs:{"to":{name: 'Assinatura', params: {id: props.item.subscriptionId}}}},[_vm._v(" "+_vm._s(props.item.subscriptionId)+" ")]),_vm._v(" | "+_vm._s(props.item.id)+" ")],1),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"items-center justify-center"},[(props.item.patient.globalTags && props.item.patient.globalTags.length > 0)?_vm._l((props.item.patient.globalTags),function(globalTag,index){return _c('GlobalTags',{key:index,attrs:{"global_tag":globalTag}})}):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(props.item.patient.name))])],2)]),_c('td',{staticClass:"text-center"},[(props.item.restHome)?[_vm._v(" "+_vm._s(props.item.restHome.name)+" ")]:[_vm._v(" ---- ")]],2),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"mr-2",class:{'font-weight-bold' : props.item.isProductionDateCalculated}},[_vm._v(_vm._s(_vm._f("formatDate")(props.item.productionDate,'DD/MM/YYYY')))]),(props.item.isProductionDateCalculated)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c('v-avatar',{attrs:{"size":"30"}},[_c('v-icon',_vm._g({},on),[_vm._v("error_outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Data calculada pelo sistema")])]):_vm._e()],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatDate")(props.item.startDate,'DD/MM/YYYY')))]),_c('td',[_c('v-select',{attrs:{"background-color":"white","value":props.item.printedLabel,"hide-selected":"","items":[
							{ text: 'Sim', value: true },
							{ text: 'Não', value: false }
						],"hide-details":"","item-value":"value","item-text":"text","solo":""},on:{"change":function (value) { return _vm.markAsPrintedLabel(props.item.id, value); }}})],1),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"column-overflow"},[(props.item.occurrence)?[_vm._v(" "+_vm._s(props.item.occurrence)+" ")]:[_c('PreProducitonInsertCommentDialog',{on:{"save-comment":function (comment) { return _vm.saveComment(props.item.id, comment); }}})]],2)]),_c('td',[_c('div',{staticClass:"items-center"},[_c('GenerateTagsDialog',{attrs:{"boxId":props.item.id}}),_c('FinishPreProductionDialog',{attrs:{"boxId":props.item.id},on:{"confirm":function (boxId) { return _vm.finishPreProduction(boxId); }}})],1)])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }