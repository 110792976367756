export const ReceitaMixin = {
	data: () => ({
		receita_selecionada_index: 0
	}),
	computed: {
		receita() {
			return this.receitas[this.receita_selecionada_index];
		}
	},
	mounted() {
		window.addEventListener('keydown', this.onKeyDown);
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.onKeyDown);
	},
	methods: {
		onKeyDown(e) {
			if (e.key === 'ArrowRight')
				this.next(1);
			else 			if (e.key === 'ArrowLeft')
				this.next(-1);
		},
		next(step) {
			const index = this.receita_selecionada_index + step;
			if (index < 0)
				this.receita_selecionada_index = this.receitas.length - 1;
			else
				this.receita_selecionada_index = index % this.receitas.length;
		},
		close() {
			this.$emit('close');
		}
	}
};
