import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = 'principios-ativos';

/**
 *
 * @param {object} params
 * @param {String} params.name
 * @param {String} params.nameReduced
 * @param {String} params.description
 * @param {Number|null} params.atcId
 * @param {String} params.dcb
 */
function create(params) {
	return $http.post(`${prefix}`, {
		name: params.name,
		description: params.description,
		atcId: params.atcId,
		nameReduced: params.nameReduced,
		dcb: params.dcb
	});
}

export const ActiveIngredientPresenter = {
	findAll: params => $http.get(buildQueryParams(`${prefix}`, params)),
	create
};
