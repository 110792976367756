import $http from '@Config/axios';
import { onlyNumber } from '@Util/functions';
import $store from '@Store';
import { DefaultPresenter } from './default-presenter';

const prefix = 'responsavel';
const entity = 'Responsável';

function sanitize(resp) {
	const responsavel = {
		...resp,
		telefone: resp.telefone ? onlyNumber(resp.telefone) : null,
		cpf: resp.cpf ? onlyNumber(resp.cpf) : null
	};
	return responsavel;
}

export const ResponsavelPresenter = {
	...DefaultPresenter({ prefix, entity }),
	list: params => new Promise((resolve, reject) => {
		$http.get(prefix, { params })
			.then(response => {
				const newResponse = {
					...response,
					itens: response.itens.map(item => sanitize(item))
				};
				resolve(newResponse);
			})
			.catch(err => reject(err));
	}),
	listFarmembros: (responsavel_id, params) => new Promise((resolve, reject) => {
		$http.get(`${prefix}/${responsavel_id}/farmembros`, params)
			.then(response => {
				resolve(response);
			})
			.catch(err => reject(err));
	}),
	listResponsavelFarmembro: farmembro_id => new Promise((resolve, reject) => {
		$http.get(`farmembros/${farmembro_id}/responsavel`)
			.then(response => {
				resolve(response);
			})
			.catch(err => reject(err));
	}),
	updateResponsavelFarmembro: (data, show_message = true) => new Promise((resolve, reject) => {
		$http.put(`farmembros/${data.paciente_id}/personsResponsible/${data.id}`, data)
			.then(response => {
				if (show_message)
					$store.dispatch('SHOW_SNACKBAR', { message: `${entity} atualizado(a) com sucesso!` });
				resolve(response);
			})
			.catch(err => reject(err));
	}),
	updateResponsavel: (farmembro_id, responsavel_id, show_message = true) => new Promise((resolve, reject) => {
		$http.post(`farmembros/${farmembro_id}/responsavel/${responsavel_id}/relacao`, {})
			.then(response => {
				if (show_message)
					$store.dispatch('SHOW_SNACKBAR', { message: `${entity} atualizado(a) com sucesso!` });
				resolve(response);
			})
			.catch(err => reject(err));
	}),
	deleteResponsavel: (farmembro_id, responsavel_id, tipo, show_message = true) => new Promise((resolve, reject) => {
		$http.delete(`farmembros/${farmembro_id}/responsavel/${responsavel_id}/relacao/${tipo}`, {})
			.then(response => {
				if (show_message)
					$store.dispatch('SHOW_SNACKBAR', { message: `${entity} desvinculado com sucesso` });
				resolve(response);
			})
			.catch(err => reject(err));
	})
};
