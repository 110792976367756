<template>
	<div class="option" :class="getState">
		<section class="option-checkbox">
			<v-checkbox
				false-value
				:input-value="isChecked"
				value
				disabled
				readonly
				:color="getCheckboxColor" class="pa-0 ma-0" hide-details
				style="margin-left: 100%!important;"></v-checkbox>
		</section>

		<div class="option-content">
			<div class="option-content-icon mb-3">
				<img v-if="icon" width="60px" :src="icon" :alt="`Ícone de ${text}`">
			</div>
		</div>

		<span class="option-content-text font-weight-bold leading">{{ text }}</span>
	</div>
</template>

<script>

export default {
	name: 'OptionKit',
	props: {
		icon: {
			required: false
		},
		text: {
			required: true,
			type: String
		},
		current: {
			required: false,
			type: Boolean,
			default: false
		},
		checked: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		isChecked() {
			return !!this.checked;
		},
		isCurrent() {
			return !!this.current;
		},
		getState() {
			if (this.isChecked)
				return 'checked';
			if (this.isCurrent)
				return 'current';
			return null;
		},
		getCheckboxColor() {
			if (this.isChecked)
				return 'success';
			if (this.isCurrent)
				return 'secondary';
			return null;
		}
	}
};
</script>

<style lang="scss" scoped>
.option {
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 290px;
	background-color: transparent;
	padding: 10px;
	align-items: center;
	border: 3px dashed #342B1D;
	opacity: 50%;

	.option-checkbox {
		display: flex;
		width: 100%;
		justify-content: end;
	}

	.option-content {
		.option-content-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100px;
			height: 100px;
			background-color: #FFD9A080;
			border-radius: 50%;
		}

	}

	&.checked {
		border: 3px solid #2DCE71;
		background-color: #96D4884D;
		opacity: 100%;
		.option-content {
			.option-content-icon {
				background-color: #96D488;
			}
		}
	}

	&.current {
		border: 3px solid #342B1D;
		background-color: transparent;
		opacity: 100%
	}
}

</style>
