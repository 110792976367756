<template>
	<div class="barcode">
		<input v-model="input" ref="input" @keyup.enter="enter">
	</div>
</template>

<script>
import { getCurrentCompanyUnit } from '@Config/unidades';

export default {
	name: 'InputCodigoBarrasPedidos',
	data: () => ({
		input: ''
	}),
	mounted() {
		this.inputFocus();
	},
	computed: {
		cidade() {
			return getCurrentCompanyUnit().nome;
		}
	},
	methods: {
		inputFocus() {
			if (this.$refs.input)
				this.$refs.input.focus();
		},
		enter() {
			if (this.input) {
				this.$emit('addItem', this.input);
				this.input = '';
				this.inputFocus();
				// this.$emit('loading', true);
				// const url = 'URL';
				// axios.get(url, { responseType: 'blob' })
				// 	.then(response => {
				// 		const image = URL.createObjectURL(response.data);
				// 		this.$emit('image', image);
				// 		this.$emit('mostrarMsg', false);
				// 	})
				// 	.then(() => {
				// 		this.$store.dispatch('order/FETCH_ORDER', this.input);
				// 	}).catch(() => {
				// 		this.$emit('image', null);
				// 		this.$emit('mostrarMsg', true);
				// 		this.$store.state.order = null;
				// 		this.$store.dispatch('SHOW_SNACKBAR', { message: 'Produto sem fotos cadastradas', color: 'error' });
				// 	})
				// 	.finally(() => {
				// 		this.$emit('loading', false);
				// 		this.input = '';
				// 		this.inputFocus();
				// 	});
			}
		}
	}
};

</script>

<style lang="scss" scoped>
.barcode {
	border-radius: 10px;
	background: url("../../processo/picking/dialog-bipe/barcode.png");

	input {
		text-align: center;
		font-weight: 900;
		font-size: 24px;
		letter-spacing: 1px;
		height: 60px;
		outline: none;
		width: 100%;
	}
}
</style>
