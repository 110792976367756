<template>
	<div class="medicamentos-wrapper px-md-5">
		<Header @nova-dose-maxima="crud" @busca="b => busca = b" />

		<!-- Conteúdo -->
		<v-card>
			<v-data-table :headers="headers" :items="items" :search="busca" :loading="is_loading" :rows-per-page-items="[10, 20, 30]"
				:no-data-text="error_message" no-results-text="Nenhum resultado encontrado"
				rows-per-page-text="Itens por página">
				<template slot="items" slot-scope="props">
					<tr class="pointer" @click="crud(props.item)">
						<td v-if="!!props.item.rowspan" :rowspan="props.item.rowspan">{{ props.item.principio_ativo.descricao }}</td>
						<td>{{ props.item.molecula.descricao }}</td>
						<td>{{ props.item.dose_maxima ? `${props.item.dose_maxima}${props.item.dose_maxima_medida}` : ' - '}}</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog persistent v-model="dialog_crud" scrollable transition="fade" max-width="920px">
			<transition>
				<DialogDoseMaxima v-if="dialog_crud" :item_pai="item_selecionado" :close.sync="dialog_crud"
					@inserir="index" />
			</transition>
		</v-dialog>

	</div>
</template>

<script>
import { ListagemMixin } from '@Mixins/listagem';
import DialogDoseMaxima from './dialog-dose-maxima';
import Header from './header';

export default {
	name: 'DoseMaxima',
	mixins: [ListagemMixin],
	components: {
		Header,
		DialogDoseMaxima
	},
	data: () => ({
		type: 'dose-maxima',
		headers: [
			{ text: 'Principio Ativo', value: 'principio_ativo.descricao' },
			{ text: 'Molécula', value: '' },
			{ text: 'Dose máxima', value: 'dose_maxima' }
		]
	}),
	methods: {
		onResponse(response) {
			const group = this.$lodash.groupBy(response, 'principio_ativo_id');
			const values = Object.values(group);
			values.forEach(g => { g[0].rowspan = g.length; });
			this.items = values.flatMap(m => m);
		}
	}
};
</script>
