<template>
	<Dialog titulo="Pausar medicamento" btn_cancelar="Cancelar" :btn_acao="medicamento_pausado ? 'Aplicar alterações' : 'Confirmar'" :btn_acao_extra="medicamento_pausado ? 'Ativar medicamento imediatamente' : ''" :btn_acao_extra_class="medicamento_pausado ? 'warning text--text' : ''"
		:btn_acao_disable="medicamento_pausado ? disableSaveButton : false"
		@acao="medicamento_pausado ? editarMedicamento() : pausarMedicamento()"
		@acao_extra="ativarMedicamento"
		@close="$emit('close')">
		<v-container fluid grid-list-md>
			<v-layout wrap>
				<v-flex md6 xs12>
					<span class="montserrat text-bold">Pausar por:</span>
					<v-select
						label="Quantidade de ciclos da box"
						v-model="pausa.ciclos"
						class="pt-2"
						:items="ciclos" box></v-select>
				</v-flex>
				<v-flex md6 xs12>
					<span class="montserrat text-bold">Data prevista para ativação:</span>
					<TextDateField
						disabled
						label="Data"
						v-model="prev_ativacao"
						hideDetails
						placeholder="dd/mm/yyyy"
						color="green_strong"
						class="text-date-field pt-2" box/>
				</v-flex>
				<v-flex md12 xs12 class="pt-3">
					<span class="montserrat text-bold">Motivo:</span>
					<v-select
						label="Selecione aqui"
						v-model="pausa.motivo"
						class="pt-2"
						:items="motivos" box></v-select>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { RascunhoPresenter } from '@Presenter/alteracoes-presenter';
import { AssinaturaMedicamentoPresenter } from '@Presenter/assinatura-medicamento-presenter';
import { isEqual } from 'lodash';

export default {
	name: 'PausarMedicamento',
	props: {
		medicamento_selecionado: {
			type: Object,
			required: true
		},
		data_base_pausa: {
			type: Object,
			required: false
		},
		medicamento_pausado: {
			type: Boolean,
			default: false
		},
		e_rascunho: {
			type: Boolean,
			default: false
		}
	},
	created() {
		this.is_rascunho = this.e_rascunho;
		this.presenter = this.is_rascunho ? RascunhoPresenter : AssinaturaMedicamentoPresenter;
		this.id_selecionado = this.is_rascunho ? this.medicamento_selecionado.alteracao_id : this.medicamento_selecionado.id_assinatura_medicamento;
		if (this.medicamento_pausado) {
			this.pausa.ciclos = this.medicamento_selecionado.pausa.ciclos;
			this.pausa.motivo = this.medicamento_selecionado.pausa.motivo;
		}
	},
	data: () => ({
		is_rascunho: false,
		presenter: null,
		id_selecionado: null,
		ciclos: [
			{
				text: '1 ciclo',
				value: 1
			},
			{
				text: '2 ciclos',
				value: 2
			},
			{
				text: '3 ciclos',
				value: 3
			}
		],
		motivos: [
			'Produto fornecido fora da Far.me',
			'Falta de receita',
			'Suspeita de suspensão',
			'Falta de medicamento no mercado',
			'Medicamento SUS em estoque com paciente',
			'Medicamento líquido em estoque com paciente',
			'Medicamento fora box em estoque com paciente',
			'Outro'
		],
		pausa: {
			ciclos: 3,
			motivo: 'Produto fornecido fora da Far.me'
		},
		loading: false,
		disableSaveButton: true
	}),
	watch: {
		pausa: {
			handler({ ciclos: new_cycles, motivo: new_reason }) {
				if (this.medicamento_selecionado.pausa) {
					const { ciclos, motivo } = this.medicamento_selecionado.pausa;

					if (isEqual({ ciclos: new_cycles, motivo: new_reason }, { ciclos, motivo }))
						this.disableSaveButton = true;
					else
						this.disableSaveButton = false;
				}
			},
			deep: true
		}
	},
	computed: {
		prev_ativacao() {
			return this.data_base_pausa.clone().add(this.pausa.ciclos * 30, 'days').format('DD/MM/YYYY');
		},
		user_id() {
			return this.$store.getters.user.id;
		}
	},
	methods: {
		pausarMedicamento() {
			this.loading = true;
			this.presenter.pausarMedicamento(this.id_selecionado, this.pausa.ciclos, this.pausa.motivo)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: `Medicamento [${this.medicamento_selecionado.produto}] pausado` });
					this.$emit('pausa-adicionada');
					if (!this.is_rascunho)
						this.$store.dispatch('assinatura/FETCH_ASSINATURA', this.$route.params.id);
				}).catch(() => {
				})
				.finally(() => {
					this.$emit('close');
					this.loading = false;
				});
		},
		editarMedicamento() {
			this.loading = true;
			this.presenter.editarMedicamento(this.id_selecionado, this.pausa.ciclos, this.pausa.motivo, this.user_id)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: `Medicamento [${this.medicamento_selecionado.produto}] editado` });
					this.$emit('pausa-editada');
					if (!this.is_rascunho)
						this.$store.dispatch('assinatura/FETCH_ASSINATURA', this.$route.params.id);
				})
				.catch(() => {
				}).finally(() => {
					this.$emit('close');
					this.loading = false;
				});
		},
		ativarMedicamento() {
			this.loading = true;
			this.presenter.deletarPausaMedicamento(this.id_selecionado)
				.then(() => {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'success', message: `Medicamento [${this.medicamento_selecionado.produto}]
					ativado`
					});
					this.$emit('pausa-removida');
					if (!this.is_rascunho)
						this.$store.dispatch('assinatura/FETCH_ASSINATURA', this.$route.params.id);
				}).catch(() => {
				}).finally(() => {
					this.$emit('close');
					this.loading = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
