<template>
	<div>
		<v-icon class="mr-2" small color="#616161">
			pause_circle
		</v-icon>
		<span class="text-bold">{{ motivo }}</span>
		<p class="grey--text text--darken-1">Prev. de ativação: {{ prev_ativacao }}</p>
	</div>
</template>

<script>
export default {
	name: 'MotivoPausa',
	props: {
		motivo: {
			type: String,
			required: true
		},
		prev_ativacao: {
			type: String,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
