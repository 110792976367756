<template>
	<div class="dialog-container" @click="close">
		<v-btn class="close-btn" icon @click="close">
			<v-icon color="white">close</v-icon>
		</v-btn>
		<div class="img-container q-row items-center justify-center">
			<v-icon color="white" @click.stop="next(-1)">keyboard_arrow_left</v-icon>
			<img v-if="!receita.url.includes('.pdf')" :alt="receita.name" :title="receita.name" :src="receita.url" style="max-width: 80%" />
			<v-icon color="white" @click.stop="next(1)">keyboard_arrow_right</v-icon>
		</div>

	</div>

</template>

<script>
import { ReceitaMixin } from '@Mixins/receitas';

export default {
	name: 'dialog-visualizar-receitas',
	mixins: [ReceitaMixin],
	props: {
		receitas: {
			type: Array,
			required: true
		},
		index: {
			type: Number,
			default: 0
		}
	},
	created() {
		this.receita_selecionada_index = this.index;
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.dialog-container {
	height: 100vh;
	background: rgba($color: #212121, $alpha: .46);
	z-index: 201;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.close-btn {
		position: absolute;
		right: 20px;
	}

	.img-container {
		height: 100%;

		img {
			height: 90%;
		}

		i {
			font-size: 100px;
		}
	}
}
</style>
