<template>
	<v-dialog
		persistent
		scrollable
		v-model="opened"
		max-width="890">
		<template v-slot:activator="{ on }">
			<v-btn
				v-on="on"
				@click="getLabels"
				small
				color="primary"
				class="pa-4"
				style="border-radius: 10px;">
				Enviar
			</v-btn>
		</template>

		<section class="farme-dialog">
			<section class="farme-dialog-header">
				Conferência do kit do Cliente
			</section>

			<section v-if="!kit.loading" class="farme-dialog-body">
				<section class="justify-between items-center">
					<p class="mr-5 mb-0">
						Realize a leitura do código de barras <br>
						ou QR Code na sequência abaixo:
					</p>

					<InputScanner v-if="showScann" @scanned="validateInput" />
				</section>

				<div class="options my-4">
					<OptionKit
						v-for="(item, i) of kit.items"
						:icon="item.icon"
						:text="item.text"
						:current="isCurrent(item.name)"
						:checked="!!item.checked"
						:key="i"/>
				</div>
			</section>

			<section class="farme-dialog-footer mt-4">
				<v-btn
					color="green darken-1"
					flat="flat"
					@click="opened = false">
					Cancelar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					:disabled="!isCompleted"
					@click="confirm">
					Finalizar pre-produção
				</v-btn>
			</section>
		</section>
	</v-dialog>
</template>

<script>
import iconEtiquetaSaquinho from '@Assets/icons/icon_etiqueta_saquinho.svg';
import iconEtiquetaBox from '@Assets/icons/icon_etiqueta_box.svg';
import iconOrdemProducao from '@Assets/icons/icon_ordem_producao.svg';
import { BoxPresenter } from '@Presenter/box-presenter';
import OptionKit from './option-kit.vue';
import InputScanner from './input-scanner.vue';

export default {
	components: {
		OptionKit,
		InputScanner
	},
	props: {
		boxId: {
			required: true,
			type: Number
		}
	},
	data() {
		return {
			opened: false,
			validating: false,
			showScann: false,
			kit: {
				loading: false,
				items: []
			},
			observation: null
		};
	},
	computed: {
		showQRScanner() {
			return !this.isCompleted && !this.validating;
		},
		isCompleted() {
			return this.kit.items.every(key => key.checked === true);
		},
		getCurrent() {
			const currentOption = this.kit.items.find(option => option.checked === false);
			return currentOption;
		}
	},
	methods: {
		async getLabels() {
			try {
				this.kit.loading = true;
				const kit = await BoxPresenter.getConferenceKitItems(this.boxId);
				this.kit.items = kit.map(i => ({
					checked: false,
					name: i.label,
					value: i.value,
					text: i.description,
					icon: this.getKitItemIcon(i.label)
				}));
				this.showScann = true;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao buscar os itens de conferência do kit', color: 'error' });
			} finally {
				this.kit.loading = false;
			}
		},
		getKitItemIcon(label) {
			if (label === 'etiqueta_saquinho')
				return iconEtiquetaSaquinho;
			if (label === 'etiqueta_box')
				return iconEtiquetaBox;
			if (label === 'ordem_producao')
				return iconOrdemProducao;
			return null;
		},
		validateInput(value) {
			const currentOption = this.getCurrent;
			const valueScanned = value;
			if (!(valueScanned === currentOption.value)) {
				this.validating = false;
				return this.$store.dispatch('SHOW_SNACKBAR', { message: `Erro ao validar ${currentOption.text}`, color: 'error' });
			}
			this.kit.items.forEach(option => {
				if (option.name === currentOption.name)
					option.checked = true;
			});
		},
		isCurrent(key) {
			const currentOption = this.getCurrent;
			return currentOption && currentOption.name === key;
		},
		confirm() {
			this.opened = false;
			this.$emit('confirm', this.boxId);
		}
	}
};
</script>

<style lang="scss" scoped>
.farme-card-header {
	background-color: #FFDEAF;
}

.action {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 50px;
	color: white;

	button {
		background-color: #FFF;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		box-shadow: 0px 4.62px 4.62px 0px rgba(217, 172, 106, 0.2);

		svg {
			width: 45px;
			height: 45px;
		}
	}

	@media (max-width: 600px) {
		gap: 20px;
		button {
			width: 75px;
			height: 75px;
		}
	}
}

.options {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;

	.option {
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 290px;
		background-color: transparent;
		padding: 10px;
		align-items: center;

		border: 3px dashed #342B1D;
		opacity: 50%;

		.option-checkbox {
			display: flex;
			width: 100%;
			justify-content: end;
		}

		.option-content {
			.option-content-icon {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100px;
				height: 100px;
				background-color: #FFD9A080;
				border-radius: 50%;
			}

		}

		&.checked {
			border: 3px solid #2DCE71;
			background-color: #96D4884D;
			opacity: 100%;
			.option-content {
				.option-content-icon {
					background-color: #96D488;
				}
			}
		}

		&.current {
			border: 3px solid #342B1D;
			background-color: transparent;
			opacity: 100%
		}

	}

}
</style>
