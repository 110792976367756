<template>
	<Dialog
		titulo="Definir rastreabilidade"
		subtitulo="Escolha a nota fiscal (XML) para fazer upload"
		btn_acao="Salvar rastreabilidade"
		btn_cancelar="Cancelar"
		:btn_acao_extra_disable="false"
		@acao="save"
		@close="$emit('update:close', false);">

		<v-container fluid grid-list-lg class="pa-0">
			<v-layout wrap align-center>
				<v-flex xs12>
					<v-data-table
						:headers="headers"
						:items="items"
						:rows-per-page-items="[{text: 'Todos', value: -1}]"
						rows-per-page-text="Itens por página" hide-actions>
						<template v-slot:items="props">
							<tr>
								<td class="text-xs-left">
									{{ props.item.produto }}

									<template v-if="props.item.estoque">
										<br>
										<v-chip  small color="primary" text-color="white">
											<v-icon>storage</v-icon>
											Tem estoque
										</v-chip>
									</template>
								</td>
								<!--  -->
								<td>
									<v-text-field
										:disabled="isDisabled(props.item)"
										v-model="props.item.lote"
										single-line
										solo
										v-validate="{ required: true }"
										:data-vv-name="`lote_${props.index+1}`"
										:error-messages="
											errors.collect(`lote_${props.index+1}`)
										"/>
								</td>
								<td>
									<TextDateField
										:disabled="isDisabled(props.item)"
										v-model="props.item.validade"
										:label="`validade_${props.index+1}`"
										placeholder="dd/mm/yyyy"
										single-line
										solo
										v-validate="{ required: true }"
										:data-vv-name="`validade_${props.index+1}`"
										:error-messages="
											errors.collect(`validade_${props.index+1}`)
										"/>
								</td>
								<td class="text-xs-center" style="display: flex">
									<v-tooltip top v-if="props.item.estoque && !isDisabled(props.item)">
										<v-btn small icon @click.stop="removeMedicine(props.index)" slot="activator">
											<v-icon color="red">close</v-icon>
										</v-btn>
										<span>Remover medicamento</span>
									</v-tooltip>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</v-container>

		<v-container fluid grid-list-lg class="pa-0">
			<template>
				<input @change="loadXML" type="file" ref="upload" style="display: none" accept="text/xml" />
				<v-btn block depressed class="ml-0 green white--text" @click="$refs.upload.click()">
					<v-icon small class="mr-2">cloud_upload</v-icon>
					Escolher nota fiscal (XML)
				</v-btn>
			</template>
		</v-container>
	</Dialog>
</template>

<script>
import { TraceabilityPresenter } from '@Presenter/traceability-presenter';
import { formatDate } from '@Util/functions';

export default {
	name: 'TraceabilityDialog',
	$_veeValidate: { validator: 'new' },
	props: {
		boxId: {
			required: true,
			type: Number
		},
		traceability: {
			required: true,
			type: Array
		}
	},
	data: () => ({
		items: [],
		nfe: [],
		file: null,
		headers: [
			{
				text: 'Produto', value: 'produto', sortable: false
			},
			{
				text: 'Lote', value: 'lote', sortable: false, width: '230px'
			},
			{
				text: 'Validade', value: 'validade', sortable: false, width: '280px'
			}
		]
	}),
	created() {
		this.traceability.forEach(item => {
			this.items.push({
				id: item.id,
				lote: item.lote,
				quantidade: item.quantidade,
				validade: formatDate(item.validade, 'DD/MM/YYYY'),
				estoque: item.estoque,
				produto: `${item.medicamento.produto} ${item.medicamento.apresentacao} - ${item.medicamento.laboratorio}`,
				eans: item.medicamento.eans,
				produtoId: item.medicamento.id
			});
		});
	},
	methods: {
		close() {
			this.$emit('update:close', false);
		},
		async save() {
			const formIsValidated = await this.$validator.validateAll();

			if (!formIsValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário inválido',
					color: 'error'
				});
				return;
			}

			try {
				const formData = new FormData();
				formData.append('xml', this.file);
				formData.append('boxId', this.boxId);
				formData.append('items', JSON.stringify(this.items.map(item => ({
					id: item.id,
					lote: item.lote,
					quantidade: item.quantidade,
					produto: item.produto,
					validade: this.$functions.FormatDateToRequest(item.validade),
					produtoId: item.produtoId,
					estoque: item.estoque
				}))));
				formData.append('nfe', JSON.stringify(this.nfe));

				await TraceabilityPresenter.updateTraceability(formData);

				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Rastreabilidade concluída com sucesso',
					color: 'success'
				});

				this.$emit('update:close', false);
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao concluir a rastreabilidade',
					color: 'error'
				});
			}
		},
		nfeItems(xmlItems) {
			this.nfe = xmlItems;
		},
		combineItems(xmlItems) {
			const rastros = xmlItems;

			const combinedItems = this.items.map(item => {
				const rastroIndex = rastros.findIndex(xmlItem => item.eans.includes(xmlItem.ean) && !item.estoque);

				if (rastroIndex === -1)
					return ({ ...item });

				const rastro = {
					...item,
					lote: rastros[rastroIndex].lote || item.lote,
					validade: rastros[rastroIndex].validade || item.validade
				};

				rastros.splice(rastroIndex, 1);

				return rastro;
			});

			this.items = combinedItems;
		},
		extractItemToTraceability(det) {
			const items = det.filter(d => !!d.prod.rastro)
				.reduce((prev, d) => {
					const rastro = d.prod.rastro.length ? d.prod.rastro : [d.prod.rastro];
					rastro.forEach(ras => {
						const itemVezesRasto = Array(Number(ras.qLote)).fill({
							ean: d.prod.cEAN,
							produto: d.prod.xProd,
							lote: ras.nLote,
							validade: this.$functions.FormatDateFromRequest(ras.dVal)
						});

						prev.push(...itemVezesRasto);
					});
					return prev;
				}, [])
				.sort((a, b) => (a.produto > b.produto ? 1 : -1));
			return items;
		},
		extractItemToBilling(det) {
			const items = det.reduce((prev, d) => {
				const qtd = Math.round(Number(d.prod.qCom)) || 1;
				const valor = Math.round((Number(d.prod.vUnCom) || 0) * 100);
				const desconto = Math.round((((Number(d.prod.vDesc) || 0) / qtd) * 100));
				const valorUnitario = (valor - desconto);

				const item = {
					valor,
					desconto,
					valorUnitario,
					qtd,
					valorFinal: Math.round((valorUnitario * qtd)),
					ean: d.prod.cEAN,
					produto: d.prod.xProd
				};

				prev.push(item);
				return prev;
			}, [])
				.sort((a, b) => (a.produto > b.produto ? 1 : -1));
			return items;
		},
		loadXML(input) {
			this.file = input.target.files[0];
			if (!this.file)
				return;

			const reader = new FileReader();
			reader.onload = e => {
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(e.target.result, 'text/xml');
				const json = this.$functions.xmlToJson(xmlDoc);
				const det = json.nfeProc.NFe.infNFe.det.length
					? json.nfeProc.NFe.infNFe.det
					: [json.nfeProc.NFe.infNFe.det];

				this.combineItems(this.extractItemToTraceability(det));
				this.nfe = this.extractItemToBilling(det);
			};
			reader.readAsText(this.file);
			this.$store.dispatch('SHOW_SNACKBAR', { message: 'Upload feito!' });
		},
		removeMedicine(index) {
			this.items.splice(index, 1);
		},
		isDisabled(item) {
			const originalItem = this.traceability.find(({ id }) => id === item.id);
			return originalItem.estoque && Boolean(originalItem.lote);
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
