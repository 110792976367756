<template>
	<Dialog titulo="Alterar senha" subtitulo="A senha deve conter no mínimo 10 caracteres, incluindo uma mistura de números, letras maiúsculas, minúsculas e símbolos especiais."
		btn_acao="Salvar" btn_cancelar="Cancelar" @acao="alterarSenha" @close="fechaModal()" :loading="loading">

		<v-text-field box v-model="senhas.senha_atual" type="password" label="Senha atual" data-vv-name="senha atual"
			v-validate="{ min: 6, required: true }" :error-messages="errors.collect('senha atual')" />

		<v-text-field box v-model="senhas.nova_senha" type="password" label="Nova senha" data-vv-name="nova senha"
			v-validate="{ min: 10, required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{10,}$/ }" :error-messages="errors.collect('nova senha')" ref="nova senha"
			:hint="forca_nova_senha.mensagem" />

		<ForcaSenha :senha="senhas.nova_senha" @forca-senha="f => forca_nova_senha = f" />

		<v-text-field box v-model="senhas.repetir_nova_senha" type="password" label="Repetir nova senha"
			data-vv-name="repetir nova senha" v-validate="{ min: 10, required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{10,}$/, confirmed: 'nova senha' }"
			:error-messages="errors.collect('repetir nova senha')" />

		<span>Você terá que fazer login novamente após trocar sua senha.</span>

	</Dialog>
</template>

<script>
import { UserPresenter } from '@Presenter/user-presenter';
import ForcaSenha from '@Componentes/forca-senha';

export default {
	name: 'AlterarSenha',
	$_veeValidate: { validator: 'new' },
	components: { ForcaSenha },
	data: () => ({
		senhas: {},
		forca_nova_senha: {},
		loading: false
	}),
	methods: {
		alterarSenha() {
			this.$validator.validateAll().then(valido => {
				if (!valido) {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
					return;
				}
				this.loading = true;
				UserPresenter.atualizarSenha(this.senhas)
					.then(() => {
						this.$store.dispatch('MSG_TOAST', 'Senha alterada com sucesso! Por favor, faça login novamente.');
						this.$router.push({ name: 'Entrar', params: { stop: true } });
						this.$functions.Logout();
					})
					.finally(() => {
						this.loading = false;
					});
			});
		},
		fechaModal() {
			this.$emit('update:close', false);
		}
	}
};
</script>
