export default {
	principio_ativo: {
		label: 'Princípio ativo',
		hide_details: true,
		validation: { required: true },
		size: 'xs12 md6'
	},
	produto: {
		label: 'Produto',
		hide_details: true,
		validation: { required: true },
		size: 'xs12 md6'
	},
	laboratorio: {
		hide_details: true,
		label: 'Laboratório',
		size: 'xs12 md4'
	},
	apresentacao: {
		label: 'Apresentação',
		validation: { required: true },
		hide_details: true,
		size: 'xs12 md4'
	},
	dosagem: {
		label: 'Dosagem',
		hide_details: true,
		size: 'xs12 md4'
	},
	qtd_embalagem: {
		label: 'Quantidade na embalagem',
		hide_details: true,
		validation: { integer: true, min_value: 0, required: true },
		size: 'xs12 md4',
		type: 'number'
	},
	id_anvisa: {
		label: 'Registo Anvisa',
		hide_details: true,
		size: 'xs12 md4'
	},
	ncm: {
		label: 'NCM',
		hide_details: true,
		size: 'xs6 md4'
	},
	portaria_344_98: {
		label: 'Portaria 344',
		hide_details: true,
		size: 'xs6 md4'
	},
	descricao_tipo_produto: {
		label: 'Tipo do produto',
		component: 'combobox',
		items: [],
		hide_details: true,
		size: 'xs6 md4'
	},
	produto_referencia: {
		label: 'Produto referência',
		hide_details: true,
		size: 'xs6 md4'
	},
	liquido: {
		label: 'É liquido?',
		size: 'xs6 md4',
		hide_details: true,
		component: 'switch'
	},
	fora_da_box: {
		label: 'Fora da box?',
		size: 'xs6 md4',
		hide_details: true,
		component: 'switch'
	},
	pbm: {
		label: 'PBM?',
		size: 'xs6 md4',
		hide_details: true,
		component: 'switch'
	},
	refrigerado: {
		label: 'Armazenar no refrigerador?',
		size: 'xs6 md4',
		hide_details: true,
		component: 'switch'
	}
};
