<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-layout wrap>
				<v-flex xs12 md6 style="display: flex; flex-direction: row; align-items: center">
					<v-avatar size="38" class="mr-3">
						<v-icon color="white" small>fas fa-user</v-icon>
					</v-avatar>
					<h3>Paciente</h3>
				</v-flex>
				<v-flex xs12 md6 style="display: flex; flex-direction: row; align-items: center">
					<v-avatar size="38" class="mr-3">
						<v-icon color="white" small>fas fa-truck</v-icon>
					</v-avatar>
					<h3>Informações de envio</h3>
				</v-flex>
			</v-layout>
		</v-card-title>
		<v-card-text style="padding: 32px">
			<v-layout wrap>
				<v-flex xs12 md4>
					<h3 class="titulo">{{ paciente.nome }}</h3>

					<p class="mb-0">
						<i aria-hidden="true" class="v-icon mr-1 material-icons theme--light"
							style="font-size: 16px;">phone</i> {{ paciente.telefone | telefone }}
					</p>
					<p class="mb-0">
						<i aria-hidden="true" class="v-icon mr-1 material-icons theme--light"
							style="font-size: 16px;">mail</i> {{ paciente.email }}
					</p>
					<p class="mb-0">
						<i aria-hidden="true" class="v-icon mr-1 material-icons theme--light"
							style="font-size: 16px;">folder_shared</i> {{ paciente.cpf | cpf }}
					</p>
					<p class="mb-0" v-if="paciente.nascimento">
						{{ paciente.nascimento | formatDate('DD/MM/YYYY') }} ({{ paciente.nascimento | idade }})
					</p>
					<p class="mb-0" v-if="paciente.casa_repouso || paciente.nascimento">
						<template v-if="paciente.casa_repouso && paciente.nascimento">
							&nbsp;-&nbsp;
						</template>
						<template v-if="paciente.casa_repouso">
							{{ paciente.casa_repouso.nome }}
						</template>
					</p>
					<p class="mb-0" v-if="paciente.sexo">
						{{ paciente.sexo }} <template v-if="paciente.genero"> - {{ paciente.genero }}</template>
					</p>
					<p class="mb-0" v-if="paciente.sonda">
						<b>Sonda:</b> {{ paciente.sonda }}
					</p>
					<p class="mb-0" v-if="paciente.plano_saude">
						{{ paciente.plano_saude }}
					</p>
					<div class="responsavel mt-2" v-if="tem_responsavel">
						<div style="margin-right: 20px">
							<strong>Responsável:</strong>
							<p class="mb-0">
								{{ responsavel.nome }}
							</p>
							<p class="mb-0" v-if="responsavel.email">
								{{ responsavel.email }}
							</p>
						</div>
						<div class="actions"
							v-if="assinatura && !disabled && ($store.getters.eh_administrador || $store.getters.eh_clinica)">
							<v-tooltip right>
								<v-btn small icon class="green white--text ml-0" slot="activator"
									@click="modal_novo_responsavel = true">
									<v-icon small>edit</v-icon>
								</v-btn>
								<span>
									Editar responsável
								</span>
							</v-tooltip>
						</div>
					</div>
				</v-flex>

				<v-flex md2 align-self-center>
					<v-tooltip top>
						<v-btn icon slot="activator" @click="selecionaPessoa(paciente.id)">
							<v-icon color="grey">keyboard_arrow_right</v-icon>
						</v-btn>
						<span>Ir para o perfil do(a) {{ paciente.nome }}</span>
					</v-tooltip>
				</v-flex>

				<v-flex xs12 md6 class="endereco">
					<h3 class="titulo">{{ paciente.nome }}</h3>
					<p class="mb-0">
						{{ endereco.logradouro }}, {{ endereco.numero }}
					</p>
					<p class="mb-0">
						<span v-if="endereco.complemento">
							{{ endereco.complemento }},
						</span>
						Bairro {{ endereco.bairro }}
					</p>
					<p class="mb-0">{{ endereco.cidade }}, {{ endereco.estado }} - {{ endereco.cep }}</p>
					<p v-if="rastreio" class="mb-0">Código de rastreio: <strong>{{ rastreio }}</strong></p>
					<p v-if="endereco.observacao" class="mb-0">Observação: {{ endereco.observacao }}</p>

					<div class="actions"
						v-if="assinatura && !disabled && ($store.getters.eh_administrador || $store.getters.eh_clinica)">
						<v-tooltip left>
							<v-btn small icon class="green white--text ml-0" slot="activator"
								@click="dialog_endereco = true">
								<v-icon small>edit</v-icon>
							</v-btn>
							<span>
								Editar endereço
							</span>
						</v-tooltip>
					</div>
				</v-flex>
			</v-layout>
		</v-card-text>

		<v-dialog v-if="assinatura" persistent scrollable v-model="dialog_endereco" transition="fade"
			max-width="980px">
			<transition>
				<Enderecos v-if="dialog_endereco" :assinatura="assinatura" :close.sync="dialog_endereco"
					@trocar-endereco="trocar" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="modal_novo_responsavel" transition="fade" max-width="980px">
			<transition>
				<DialogResponsavelComp v-if="modal_novo_responsavel" :close.sync="modal_novo_responsavel"
					@responsavel-preenchido="adicionarResponsavel" />
			</transition>
		</v-dialog>
		<transition>
			<v-dialog persistent scrollable v-model="dialog_confirmacao_responsavel" transition="fade"
				max-width="480px">
				<Dialog titulo="Confirmação responsável" btn_acao="Alterar" btn_cancelar="Cancelar"
					@acao="alterarResponsavel" @close="dialog_confirmacao_responsavel = false" :loading="alterando">
					<p>Deseja alterar o responsável de <span>{{ paciente.nome }}</span> para <span>{{
						responsavel_selecionado.nome
					}}</span>?</p>
				</Dialog>
			</v-dialog>
		</transition>
	</v-card>
</template>

<script>
import DialogResponsavelComp from '@Componentes/responsavel/dialog-responsavel';
import { ResponsavelPresenter } from '@Presenter/responsavel-presenter';
import Enderecos from './enderecos';

export default {
	name: 'InformacoesPacienteResponsavelEndereco',
	props: {
		paciente: {
			type: Object,
			required: true
		},
		endereco: {
			type: Object,
			required: true
		},
		responsaveis: {
			type: Array,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		assinatura: {
			type: Object,
			default: null
		},
		rastreio: {
			type: String,
			default: ''
		}
	},
	components: { Enderecos, DialogResponsavelComp },
	data: () => ({
		responsavel: {},
		responsavel_selecionado: {},
		dialog_confirmacao_responsavel: false,
		dialog_endereco: false,
		modal_novo_responsavel: false,
		alterando: false,
		cadastrando: false
	}),
	created() {
		this.responsavel = (this.responsaveis || []).find(r => r.tipo === 1);
	},
	methods: {
		trocar(endereco) {
			this.$root.$emit('ALTEROU', true);
			this.assinatura.endereco = endereco;
			this.assinatura.endereco_id = endereco.id;
			this.dialog_endereco = false;
		},

		adicionarResponsavel(resp) {
			this.responsavel_selecionado = resp;
			if (resp.id)
				this.dialog_confirmacao_responsavel = true;
			else
				this.cadastrarResponsavel();
		},

		alterarResponsavel() {
			this.alterando = true;
			ResponsavelPresenter.updateResponsavel(this.paciente.id, this.responsavel_selecionado.id)
				.then(response => {
					this.responsavel = response;
				})
				.catch(err => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: err.message || 'Erro ao alterar responsável' });
				})
				.finally(() => {
					this.alterando = false;
					this.modal_novo_responsavel = false;
					this.dialog_confirmacao_responsavel = false;
				});
		},

		cadastrarResponsavel() {
			this.cadastrando = true;
			ResponsavelPresenter.store({ ...this.responsavel_selecionado, paciente_id: this.paciente.id })
				.then(response => {
					this.responsavel = response;
					this.modal_novo_responsavel = false;
				})
				.catch(err => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: err.message || 'Erro ao cadastrar responsável' });
				})
				.finally(() => {
					this.cadastrando = false;
				});
		},

		selecionaPessoa(id) {
			this.$router.push({ name: 'Paciente', params: { id } });
		}
	},
	computed: {
		tem_responsavel() {
			return !!this.responsavel;
		},
		eh_clinica() {
			return this.$route.name.includes('clinica');
		}
	}
};
</script>

<style lang="scss" scoped>
span {
	font-weight: bold;
}

@import "@Assets/css/settings.scss";

.v-card {
	::v-deep .v-card {
		box-shadow: none !important;
	}
}

.responsavel {
	display: flex;
	flex-direction: row;

	.actions {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 10px 0 0;

		@media screen and (min-width: $break-point-md) {
			transform: translateX(20px);
			opacity: 0;
			transition: all 0.3s;
		}
	}

	&:hover {
		.actions {
			transform: translateX(0px);
			opacity: 1;
		}
	}
}

.endereco {
	.actions {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 10px 0 0;

		@media screen and (min-width: $break-point-sm) {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 10px;
			margin: 0;
			flex-direction: column;
			justify-content: center;
		}

		@media screen and (min-width: $break-point-md) {
			transform: translateX(20px);
			opacity: 0;
			transition: all 0.3s;
		}
	}

	&:hover {
		.actions {
			transform: translateX(0px);
			opacity: 1;
		}
	}

}
</style>
