<template>
	<Dialog titulo="Pesquisa de medicamento" @close="$emit('update:close', false)">
		<v-layout row wrap>
			<v-flex xs12>
				<v-text-field placeholder="Digite o nome, princípio ativo, laboratório ou dosagem para buscar o medicamento"
					label="Pesquise o medicamento" v-model="search_medicamento" @input="chamaGetMedicamentos" />
			</v-flex>
			<v-flex xs12>
				<v-data-table :items="medicamentos" :headers="headers" :loading="carregando" :disable-initial-sort="true"
					:rows-per-page-items="[5, 10]" rows-per-page-text="Itens por página" :pagination.sync="pagination"
					:total-items="total_items" :no-results-text="no_data_text" no-data-text="Nenhum medicamento encontrado">
					<template slot="items" slot-scope="props">
						<tr class="pointer" @click="!showCopyIcon ? medicamentoSelecionado(props.item)
							: copyEan(props.item)">
							<td>{{ props.item.id }}</td>
							<td>{{ props.item.produto }}<v-chip v-if="props.item.pbm" class="justify-center" color="blue" style="max-width: 20px; max-height: 20px;" slot="activator"
								dark
								small><strong>%</strong></v-chip></td>
							<td>{{ props.item.principio_ativo }}</td>
							<td>
								<v-tooltip top open-on-hover>
									<template v-slot:activator="{ on }">
										<div v-on="on" @mouseover="showCopyIcon = props.item.id"
											@mouseleave="showCopyIcon = false">
											{{ props.item.ean }}
											<Transition name="fade" mode="out-in">
												<v-icon size="12px" v-show="showCopyIcon === props.item.id" class="pl-1" v-on="on">fas fa-copy</v-icon>
											</Transition>
										</div>
									</template>
									<span>Copiar Ean</span>
								</v-tooltip>
							</td>
							<td>{{ props.item.apresentacao }}</td>
							<td>{{ props.item.laboratorio }}</td>
							<td>{{ props.item.descricao_tipo_produto }}</td>
							<td v-if="visao_colaborador">{{ textPrice(props.item) | dinheiro }}</td>
							<td v-if="visao_colaborador">{{ props.item.qtd_assinaturas }}</td>
							<td v-if="visao_colaborador">
								<v-tooltip right>
									<template v-slot:activator="{ on }">
										<v-icon class="mx-0 white--text pointer" v-on="on"
											:color="medicamentoStatus[props.item.disponibilidade || 0] === 'Disponível' ? 'success': 'red'">fas fa-circle </v-icon>
									</template>
									<span>{{ `${medicamentoStatus[props.item.disponibilidade || 0] || 'Desconhecido' }` }} <br/></span>
								</v-tooltip>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-flex>
			<v-flex v-if="visao_colaborador" xs12 class="legenda">
				<v-icon class="mr-1" color="success">fas fa-circle</v-icon>
				<span class="mr-3">Disponível</span>
				<v-icon class="mr-1" color="red">fas fa-circle</v-icon>
				<span class="mr-3">Indisponível</span>
				<v-chip class="justify-center" color="blue" style="max-width: 20px; max-height: 20px;" slot="activator" dark
					small><strong>%</strong></v-chip>
				<span class="mr-3">PBM</span>
			</v-flex>
		</v-layout>
	</Dialog>
</template>

<script>
import medicamentoStatus from '@Consts/medicamento-status';
import { MedicamentoPresenter } from '@Presenter/medicamento-presenter';
import { debounce } from 'lodash';
import { copyToClipboard } from '@Util/functions';

export default {
	name: 'DialogPesquisarMedicamento',
	props: {
		medicamento_antigo: {
			type: String,
			default: ''
		},
		dosagem: {
			type: String,
			default: ''
		},
		orcamento_filial_id: {
			type: Number,
			required: false
		},
		restHomeId: {
			required: false
		},
		externalBilling: {
			required: false
		},
		fluxo: {
			type: String,
			required: true
		},
		assinaturaId: {
			type: Number,
			required: false
		},
		tipoCliente: {
			type: String,
			required: false
		}
	},
	data: () => ({
		medicamentos: [],
		carregando: false,
		search_medicamento: '',
		error: false,
		headers: [
			{ text: 'ID', value: 'id', sortable: false },
			{ text: 'Produto', value: 'produto', sortable: false },
			{ text: 'Princípio ativo', value: 'principio_ativo', sortable: false },
			{
				text: 'Ean', value: 'ean', sortable: false, width: '13%'
			},
			{ text: 'Apresentação', value: 'apresentacao', sortable: false },
			{ text: 'Laboratório', value: 'laboratorio', sortable: false },
			{ text: 'Tipo do produto', value: 'descricao_tipo_produto', sortable: false },
			{ text: 'Preço', value: 'preco', sortable: false },
			{ text: 'Qtd Ass', value: 'qtd_assinaturas', sortable: false },
			{ text: 'Disponibilidade', value: 'disponibilidade', sortable: false }
		],
		pagination: {},
		total_items: 0,
		medicamentoStatus,
		showCopyIcon: 0
	}),
	watch: {
		pagination: {
			handler() {
				this.getMedicamentos();
			},
			deep: true
		}
	},
	created() {
		if (this.medicamento_antigo)
			this.search_medicamento = this.medicamento_antigo;
	},
	methods: {
		chamaGetMedicamentos: debounce(function (search) {
			this.getMedicamentos(search);
		}, 500),
		getMedicamentos() {
			this.carregando = true;
			MedicamentoPresenter.search({
				search: this.search_medicamento,
				orcamento_filial_id: this.orcamento_filial_id,
				restHomeId: this.restHomeId,
				externalCharge: this.externalBilling,
				flow: this.fluxo,
				clientType: this.tipoCliente,
				subscriptionId: this.assinaturaId,
				dosagem: this.dosagem,
				...this.pagination
			})
				.then(response => {
					this.total_items = response.total;
					this.medicamentos = response.items;
				})
				.finally(() => {
					this.carregando = false;
				});
		},
		medicamentoSelecionado(med) {
			if (!med.preco_tabela) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Este item não está precificado para esta tabela',
					color: 'error'
				});
			}
			if (med.disponibilidade !== 'AVAILABLE') {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Este item não está disponível',
					color: 'error'
				});
			}
			this.$emit('medicamento-selecionado', med);
		},
		copyEan(item) {
			copyToClipboard(item.ean, `${item.produto}: Ean`);
		},
		textPrice(item) {
			return item.preco_tabela;
		}
	},
	computed: {
		tem_medicamento() {
			return typeof this.medicamento.id === 'number';
		},
		visao_colaborador() {
			return this.$store.getters.visao_colaborador;
		},
		no_data_text() {
			if (this.carregando)
				return 'Carregando...';
			if (this.error)
				return 'Erro ao carregar. Por favor, tente novamente';
			if (this.medicamentos.length === 0 && !this.search_medicamento)
				return 'Utilize o campo acima para buscar um medicamento';
			return 'Nenhum medicamento encontrado';
		}
	}
};
</script>

<style lang="scss" scoped>
.legenda {
	display: flex;
	align-items: center;
}
</style>
