<template>
	<v-layout wrap class="mt-2">
		<v-flex md3 xs12 class="d-flex q-column items-start justify-start">
			<span class="inter text-bold body-1">Preço da caixa</span>
			<v-text-field v-model="values.price" readonly box class="inputQuotationStyle mt-3" hide-details
				label="Preço da Caixa *"
				v-money
				data-vv-name="boxPrice"
				v-validate="{ required: true }"/>
		</v-flex>
		<v-flex v-if="showDiscountType" md3 xs12 class="d-flex q-column items-start justify-start">
			<span class="inter text-bold body-1">{{showDiscountText}}</span>
			<v-text-field readonly box class="inputQuotationStyle mt-3" hide-details
				:value="discountTextValue"
				:label="showDiscountText"/>
		</v-flex>
		<v-flex md3 xs12 class="items-end">
			<v-tooltip top :disabled="!isPbm">
				<v-btn slot="activator" class="px-5 my-0" color="primary" @click="dialogSelectDiscount = true" :disabled="isPbm">
					Selecionar desconto
				</v-btn>
				<span>Não é permitido aplicar desconto em um medicamento PBM</span>
			</v-tooltip>
		</v-flex>
		<v-flex v-if="discountGreaterThanZero && !isNew" xs12 class="text-start pb-0">
			<span class="inter text-bold body-1">*Desconto de ({{this.values.discountPercentage}}%) liberado pelo sistema considerando o arredondamento pela proporcionalidade seguindo a posologia adicionada ou quantidade de caixas.</span>
		</v-flex>
		<v-dialog persistent v-if="dialogSelectDiscount" v-model="dialogSelectDiscount"
			transition="fade" max-width="800px">
			<transition>
				<DialogSelectDiscount :isNew="isNew" :values="values" @saveDiscount="val => values = val" :close.sync="dialogSelectDiscount"/>
			</transition>
		</v-dialog>
	</v-layout>
</template>

<script>
import { mascaraDinheiro, onlyNumber } from '@Util/functions';
import DialogSelectDiscount from '../dialog-select-discount.vue';

export default {
	name: 'ProductValues',
	$_veeValidate: { validator: 'new' },
	components: {
		DialogSelectDiscount
	},
	props: {
		isNew: {
			type: Boolean,
			required: false
		},
		product: {
			type: Object,
			required: true
		},
		discountType: {
			type: String
		}
	},
	data: () => ({
		values: {
			price: 0,
			discount: 0,
			nominalDiscount: 0,
			finalPrice: 0,
			discountType: ''
		},
		dialogSelectDiscount: false,
		percentageRules: [v => (v >= 0 && v <= 100) || 'Porcentagem deve ser de 0 a 100'],
		finalPriceRules: [v => (Number(onlyNumber(v)) >= 0) || 'Valor deve ser maior que zero']
	}),
	created() {
		this.values.price = this.product.price;
		if (!this.isNew)
			this.values = this.$lodash.cloneDeep(this.product.values);
	},
	computed: {
		showDiscountType() {
			return this.showDiscountText;
		},
		showDiscountValue() {
			let value;
			if (this.values.discountType) {
				switch (this.values.discountType) {
					case 'percentage':
						value = this.values.discount; break;
					case 'nominalDiscount':
						value = this.values.nominalDiscount; break;
					case 'finalPrice':
						value = this.values.finalPrice; break;
					default: value = undefined; break;
				}
			}
			return Number(this.$functions.onlyNumber(value));
		},
		showDiscountText() {
			let text = '';
			if (this.values.discountType) {
				switch (this.values.discountType) {
					case 'percentage':
						text = 'Desconto por porcentagem'; break;
					case 'nominalDiscount':
						text = 'Desconto nominal'; break;
					case 'finalPrice':
						text = 'Preço final'; break;
					default: text = ''; break;
				}
			}
			return text;
		},
		discountTextValue() {
			let text = '';
			if (this.values.discountType) {
				switch (this.values.discountType) {
					case 'percentage':
						text = `${this.showDiscountValue} %`; break;
					case 'nominalDiscount':
						text = mascaraDinheiro(this.showDiscountValue); break;
					case 'finalPrice':
						text = mascaraDinheiro(this.showDiscountValue); break;
					default: text = ''; break;
				}
			}
			return text;
		},
		discountGreaterThanZero() {
			return this.values.discount > 0;
		},
		isPbm() {
			return this.product.drug && this.product.drug.pbm !== null;
		}
	},
	watch: {
		discountType: {
			handler(newVal) {
				if (newVal) {
					this.values.discount = 0;
					this.values.nominalDiscount = 0;
					this.values.finalPrice = 0;
				}
			}
		},
		values: {
			handler(newVal) {
				const price = Number(this.$functions.onlyNumber(this.values.price));
				const discount = Number(this.$functions.onlyNumber(this.values.discount));
				const nominalDiscount = Number(this.$functions.onlyNumber(this.values.nominalDiscount));
				const finalPrice = Number(this.$functions.onlyNumber(this.values.finalPrice));

				if (newVal) {
					const values = {
						price,
						discount,
						nominalDiscount,
						finalPrice,
						discountType: this.values.discountType
					};

					this.$emit('updateValues', values);
				}
			},
			deep: true
		}
	}
};
</script>
