<template>
	<Dialog
		titulo="Cadastrar unidade de medida"
		@acao="save()"
		btn_acao="Confirmar"
		@close="$emit('close')"
		background="rgba(255, 234, 204, 1)"
		backgroundFooter="rgba(255, 234, 204, 1)">
		<v-container>
			<v-layout wrap>
				<v-flex xs12>
					<v-text-field
						v-model="form.name"
						background-color="white"
						box
						label="Nome"
						v-validate="{ required: true }"
						data-vv-name="Nome"
						:error-messages="errors.collect('Nome')"/>
				</v-flex>

				<v-flex xs12>
					<v-text-field
						v-model="form.symbol"
						background-color="white"
						box
						label="Símbolo"
						v-validate="{ required: true }"
						data-vv-name="Símbolo"
						:error-messages="errors.collect('Símbolo')"/>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { UnitOfMeasurePresenter } from '@Presenter/unit-of-measure-presenter';

export default {
	name: 'DialogCreateUnitOfMeasurement',
	$_veeValidate: { validator: 'new' },
	data: () => ({
		form: {
			name: null,
			symbol: null
		}
	}),
	methods: {
		async save() {
			const formIsValidated = await this.$validator.validateAll();

			if (!formIsValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário inválido',
					color: 'error'
				});
				return;
			}

			try {
				await UnitOfMeasurePresenter.create({
					name: this.form.name,
					symbol: this.form.symbol
				});

				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Unidade de medida cadastrado com sucesso',
					color: 'success'
				});
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao criar unidade de medida',
					color: 'error'
				});
				throw new Error(error);
			} finally {
				this.$emit('close');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
