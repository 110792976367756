import $http from '@Config/axios';

const prefix = 'rascunho';

export const RascunhoPresenter = {
	get: box_id => $http.get(`${prefix}/${box_id}`),
	addProduct: (box_id, data) => $http.post(`${prefix}/${box_id}/produtos`, data),
	updateProduct: (box_id, alteracao_id, data) => $http.put(`${prefix}/${box_id}/produtos/${alteracao_id}`, data),
	deleteProduct: (box_id, alteracao_id) => $http.delete(`${prefix}/${box_id}/produtos/${alteracao_id}`),
	reset: box_id => $http.delete(`${prefix}/${box_id}`),
	print: box_id => $http.post(`${prefix}/${box_id}/imprimir`),
	pausarMedicamento: (alteracao_id, ciclos, motivo) => $http.post(`itens_rascunho/${alteracao_id}/pausas`, { ciclos, motivo }),
	editarMedicamento: (alteracao_id, ciclos, motivo, user_id) => $http.put(`itens_rascunho/${alteracao_id}/pausas`, { ciclos, motivo, user_id }),
	deletarPausaMedicamento: alteracao_id => $http.delete(`itens_rascunho/${alteracao_id}/pausas`),
	updateAproveitamentoReceita: (alteracao_id, data) => $http.put(`itens_rascunho/${alteracao_id}/aproveitamentoReceitas`, data)
};
