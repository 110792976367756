import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';
import { DefaultPresenter } from './default-presenter';

const prefix = 'clinica/principios_ativos';
const entity = 'Princípios ativos';

export const PrincipioAtivoPresenter = {
	...DefaultPresenter({ prefix, entity }),
	index: params => $http.get(buildQueryParams(prefix, params))
};
