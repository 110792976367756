import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';

export const FETCH_ASSINATURA = ({ commit, getters }, assinatura_id) => {
	commit('SET_LOADING', true);
	Promise.all([
		AssinaturaPresenter.show(assinatura_id),
		AssinaturaPresenter.historicoBoxes(assinatura_id)
	])
		.then(([assinatura, historico]) => {
			commit('SET_ASSINATURA', assinatura);
			commit('SET_HISTORICO', historico);
			commit('SET_DATA_PROXIMA_BOX', getters.lastBoxIsOpen ? historico[0].inicio : assinatura.data_proxima_box);
		})
		.finally(() => {
			commit('SET_LOADING', false);
		});
};

export const SET_IUGU_ID = ({ commit }, iugu_id) => {
	commit('SET_IUGU_ID', iugu_id);
};
