<template>
	<div class="products">
		<div v-if="productIsNotEmpty">
			<h3 class="green--text text--darken-2">
				{{product.name}}
			</h3>
			<p>{{product.presentation}}</p>
		</div>
		<div v-else>
			<div class="noProducts">
				<h3 style="color: #342b1d !important"  class="text--darken-2">
					Pronto
				</h3>
				<h4>
					Utilize um leitor de código de barras ou insira o EAN manualmente
				</h4>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Product',
	props: {
		product: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		productIsNotEmpty() {
			return !!Object.keys(this.product).length;
		}
	}
};
</script>

<style lang="scss" scoped>
.products {
	padding: 8px;
	border: 1px lightgrey solid;
	border-radius: 10px;
	margin-top: 15px;
	display: flex;

	h3 {
		font-size: 20px;
	}

	h4 {
		font-family: "Poppins", sans-serif;
		font-weight: 500;
		margin-bottom: 0;
		text-transform: uppercase;
		font-size: 14px;
		line-height: 12px;
	}

	p {
		font-size: 12px;
		margin: 0;
	}
}
</style>
