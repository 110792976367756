<template>
	<v-flex xs12 style="padding: 0px !important">
		<v-card color="#FFF3E2" class="cardStyle sans" :class="isOutOfBox ? 'outOfBoxStyle' : '' ">
			<v-layout wrap class="full-height">
				<v-flex xs2 class="q-column items-center fit imageStyle" :class="isOutOfBox ? 'imgBorderOutOfBoxStyle' : 'imgBorderStyle'" :style="isOutOfBox ? 'padding: 20px !important' : 'padding: 30px !important'">
					<span v-if="isOutOfBox" class="textOutOfBox mb-1 text-bold caption sans">Fora da box</span>
					<img
						src="/img/default-medicine-box.svg" width="100%" height="100" alt="Imagem do produto"/>
				</v-flex>
				<v-flex xs10 class="items-center justify-space-between" :style="!isOutOfBox ? 'padding: 20px !important' : 'padding: 30px !important'">
					<v-layout wrap>
						<v-flex xs6>
							<p class="subheading text-bold mb-0 text-uppercase">{{product.name}}</p>
							<p class="body-1">{{nameActiveIngredient(product)}}</p>
						</v-flex>
						<v-flex xs4>
							<p class="mb-0">{{product.presentation}}</p>
							<p class="mb-0">{{textQuantity}}</p>
							<p class="mb-0">{{product.brand}}</p>
						</v-flex>
						<v-flex xs2 class="flex-center">
							<v-tooltip v-if="isGeneric" top>
								<template v-slot:activator="{ on }">
									<v-icon v-on="on" small color="#212B72" class="iconStyle generic">fab fa-google</v-icon>
								</template>
								<span class="inter text-bold" style="color: #000;">Genérico</span>
							</v-tooltip>
							<v-tooltip v-if="isPbm" top>
								<template v-slot:activator="{ on }">
									<img
										v-on="on"
										src="/img/icons/percentage.svg"
										alt="Imagem de porcentagem"
										class="iconStyle percentage"
										style="width: 33px;height: 33px"/>
								</template>
								<span class="inter text-bold" style="color: #000;">PBM</span>
							</v-tooltip>
							<v-tooltip v-if="isControlled" top>
								<template v-slot:activator="{ on }">
									<v-icon v-on="on" small color="#E50000" class="iconStyle receipt">fas fa-file</v-icon>
								</template>
								<span class="inter text-bold" style="color: #000;">Retenção de receita</span>
							</v-tooltip>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-card>
	</v-flex>

</template>

<script>
import { getPropertyValue } from '@Util/ProductService';

export default {
	name: 'CardProduct',
	props: {
		product: {
			type: Object,
			required: true
		}
	},
	computed: {
		textQuantity() {
			return `${this.product.quantity} ${this.product.unitOfMeasurement.name}`;
		},
		isOutOfBox() {
			return this.isMedicine ? Boolean(getPropertyValue(this.product.drug.properties, 'isOutOfBox')) : false;
		},
		isPbm() {
			return this.isMedicine ? this.product.drug.pbm !== null : false;
		},
		isGeneric() {
			return this.isMedicine ? this.product.drug.type === 'GENERICO' : false;
		},
		isControlled() {
			return this.isMedicine ? !!this.product.drug.prescriptionRule : false;
		},
		isMedicine() {
			return this.product.drug;
		}
	},
	methods: {
		nameActiveIngredient(product) {
			if (product.drug && product.drug.compositions)
				return product.drug.compositions.map(item => item.activeIngredient.name).join(' + ');
			return '';
		}
	}
};
</script>

<style lang="scss" scoped>
.cardStyle {
	border-radius: 0px 10px 10px 0px;
	color: rgba(52, 43, 29, 1);
	padding: 12px;
}

.textOutOfBox {
	position: relative;
	top: -22px;
	background-color: #FFC875;
	border-radius: 0px 0px 4px 4px;
	padding: 2px 4px;
}

.imageStyle {
	background-color: white;
	padding-top: 0px !important;
}

.imgBorderStyle {
	border-radius: 10px 0px 0px 10px;
	border: 1px solid #DCDCDC;
}

.imgBorderOutOfBoxStyle {
	border-radius: 10px 0px 0px 10px;
	border-right: 2px dashed rgba(255, 200, 117, 1) !important;
}

.outOfBoxStyle {
		border: 2px dashed rgba(255, 200, 117, 1) !important;
		border-radius: 0px 10px 10px 0px;
	}

.iconStyle {
		border-radius: 17px;
		margin: 5px
	}

	.generic {
		background-color: #E7EAFF;
		padding: 7px;
	}

	.percentage {
		background-color: rgba(45, 206, 113, 0.2);
		padding: 7px;
	}

	.receipt {
		background-color: rgba(229, 0, 0, 0.2);
		padding: 7px 10px;
	}

	.exclamation {
		background-color: rgba(255, 200, 117, 0.4);
		padding: 7px;
	}

</style>
