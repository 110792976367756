<template>
	<Dialog
		titulo="Receitas vinculadas"
		:btn_acao_extra_disable="false"
		btn_acao="Fechar"
		@acao="close"
		@close="close">

		<v-container fluid grid-list-lg class="pa-0">
			<v-layout wrap align-center>
				<v-flex xs12>
					<v-data-table
						:headers="headers"
						:items="items"
						:rows-per-page-items="[{text: 'Todos', value: -1}]"
						rows-per-page-text="Itens por página" hide-actions>
						<template v-slot:items="props">
							<tr>
								<th class="text-xs-left" :rowspan="props.item.receitas.length + 1" >
									{{ props.item.medicamento.produto }} {{ props.item.medicamento.apresentacao }}
								</th>
							</tr>

							<tr v-for="(receita, index) of props.item.receitas" :key="Math.random() + index">
								<td class="text-xs-left" >
									{{ receita.id || '-' }}
								</td>
								<td class="text-xs-left">
									<template v-if="receita && receita.numero_notificacao">
										{{ receita.numero_notificacao }}
									</template>
									<template v-else>
										-
									</template>
								</td>
								<td class="text-xs-left">
									<template v-if="receita && receita.data_prescrita">
										{{ receita.data_prescrita | formatDate('DD/MM/YYYY')}}
									</template>
									<template v-else>
										-
									</template>
								</td>
								<td class="text-xs-left">
									<template v-if="receita && receita.prescritor">
										{{ receita.prescritor.nome }} <br />
										<strong
											v-if="receita.prescritor.tipo_registro"
											:title="getDescriptionFromTypeRegister(receita.prescritor.tipo_registro)">{{ receita.prescritor.tipo_registro }}: </strong>
										{{ receita.prescritor.numero }} <strong>UF: </strong>{{ receita.prescritor.uf }}
									</template>
									<template v-else>
										-
									</template>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import { typesOfRegisterData } from '@Consts/prescriber-register-types';

export default {
	name: 'PrescriptionsDialog',
	$_veeValidate: { validator: 'new' },
	props: {
		boxId: {
			required: true,
			type: Number
		}
	},
	async created() {
		await this.getMedicinesWithPrescrition();
	},
	data: () => ({
		items: [],
		headers: [
			{
				text: 'Medicamento', sortable: false
			},
			{
				text: 'ID Receita', sortable: false, width: '10px'
			},
			{
				text: 'Nro Notificação', sortable: false, width: '80px'
			},
			{
				text: 'Data prescrita', sortable: false, width: '80px'
			},
			{
				text: 'Prescritor', sortable: false, width: '250px'
			}
		]
	}),
	methods: {
		close() {
			this.$emit('update:close', false);
		},
		async getMedicinesWithPrescrition() {
			const response = await BoxPresenter.getBoxMedicines(this.boxId);

			this.items = response.boxMedicamentos;
		},
		getDescriptionFromTypeRegister(key) {
			if (typesOfRegisterData[key])
				return typesOfRegisterData[key].description;
			return 'Tipo inválido';
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
