<template>
	<div class="flex-box">
		<img :src="logo" width="140px" />
		<div class="my-5">
			<h2 class="text--text">Criar senha</h2>
			<p class="titulo mb-5 pb-3">Crie uma senha para acessar o sistema.</p>

			<div style="position: relative">
				<v-text-field v-model="nova_senha" type="password" box color="green_strong" label="Senha"
					@keyup.enter="criarSenha" placeholder="********" data-vv-name="senha" v-validate="{ required: true, min: 6 }"
					:error-messages="errors.collect('senha')" />

				<ForcaSenha :senha="nova_senha" @forca-senha="f => forca_nova_senha = f" :hide-details="true"
					style="position: absolute; top: 85px" />
			</div>

			<v-text-field v-model="repetir_nova_senha" type="password" box color="green_strong" label="Confirmar senha"
				@keyup.enter="criarSenha" placeholder="********" data-vv-name="confirmar senha" v-validate="{ required: true, min: 6 }"
				:error-messages="errors.collect('confirmar senha')" />

			<v-btn depressed :loading="criando" :icon="criando" @click.stop="criarSenha" :block="!criando && $vuetify.breakpoint.xsOnly"
				class="ml-0 green_strong white--text" :style="criando ? null : 'padding: 0 64px'">
				Criar senha
			</v-btn>
		</div>
		<footer class="title" />
	</div>
</template>

<script>
import { UserPresenter } from '@Presenter/user-presenter';
import ForcaSenha from '@Componentes/forca-senha';
import logo from '@Assets/images/logo.png';

export default {
	name: 'CriarSenha',
	$_veeValidate: { validator: 'new' },
	components: { ForcaSenha },
	data: () => ({
		logo,
		criando: false,
		nova_senha: '',
		repetir_nova_senha: '',
		forca_nova_senha: {}
	}),
	methods: {
		criarSenha() {
			this.$validator.validateAll().then(valido => {
				if (!valido) {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
					return;
				}

				this.criando = true;

				const data = {
					email: this.$route.params.email,
					token: this.$route.params.token,
					nova_senha: this.nova_senha,
					repetir_nova_senha: this.repetir_nova_senha
				};
				UserPresenter.criarSenha(data)
					.then(() => {
						this.$store.dispatch('SHOW_SNACKBAR', { message: 'Senha criada com sucesso!' });
						this.$router.push({ name: 'Entrar', params: { stop: true } });
					})
					.finally(() => {
						this.carregando = false;
					});
			});
		}
	}
};
</script>
