<template>
	<Dialog titulo="Alterações" @close="close">
		<div class="loading" v-if="carregando">
			<v-progress-circular indeterminate color="primary"/>
		</div>
		<template v-else>
			<p>Antes de avançar, é necessário entrar em contato com o cliente para conferir se houve alterações na assinatura. Após o contato, utilize as ações abaixo.</p>
			<div class="q-row items-center justify-center" :class="{ 'mb-3': has_status }">
				<v-btn depressed class="primary" :disabled="!!nCHANGE_REQUESTED || !!nNO_CHANGES" :loading="loading" @click="setStatus('NO_CHANGES')">
					Não teve alteração
				</v-btn>
				<v-btn v-if="nCHANGE_REQUESTED <= nCHANGED || hasNoChanges" depressed :loading="loading"
					:class="{ 'primary': nCHANGE_REQUESTED === 0, 'success': nCHANGE_REQUESTED > 0 }"
					@click="abrirRascunho">
					Abrir rascunho para alteração de assinatura
				</v-btn>
				<v-btn v-else depressed :loading="loading" class="warning" @click="semAlteracao">
					Finalizar rascunho
				</v-btn>
			</div>
			<div class="text-center" v-if="has_status">
				<p v-if="!nCHANGE_REQUESTED && !nNO_CHANGES" class="success--text">
					A assinatura não teve alterações. Você pode continuar o processo.
				</p>
				<p v-else-if="!hasNoChanges && nCHANGE_REQUESTED > nCHANGED" class="warning--text">
					Há alterações em andamento.
				</p>
				<p v-else class="success--text">
					As alterações foram concluídas, mas você ainda pode solicitar novas.
				</p>
				<p v-if="nCHANGE_REQUESTED > 0">
					<small class="my-3 text-center gray--text">Rascunhos abertos: {{ nCHANGE_REQUESTED }} | Rascunhos concluídos: {{ nCHANGED }}</small>
				</p>
			</div>
		</template>
	</Dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import { StatusBoxPresenter } from '@Presenter/status-box-presenter';

export default {
	name: 'DialogAlteracoes',
	props: {
		box_id: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		box: null,
		carregando: true,
		loading: false
	}),
	computed: {
		hasNoChanges() {
			return this.box.status_box
				.filter(s => ['NO_CHANGES', 'CHANGE_REQUESTED', 'CHANGED'].includes(s.status))
				.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
				.find(i => i.status === 'NO_CHANGES');
		},
		has_status() {
			return this.nNO_CHANGES > 0 || this.nCHANGED > 0 || this.nCHANGE_REQUESTED > 0;
		},
		nNO_CHANGES() {
			return this.countStatus('NO_CHANGES');
		},
		nCHANGED() {
			return this.countStatus('CHANGED');
		},
		nCHANGE_REQUESTED() {
			return this.countStatus('CHANGE_REQUESTED');
		}
	},
	async created() {
		this.box = await BoxPresenter.show(this.box_id);
		this.carregando = false;
	},
	methods: {
		setStatus(status) {
			this.loading = true;
			return new Promise((revolve, reject) => {
				StatusBoxPresenter.store({
					box_id: this.box_id,
					status
				})
					.then(response => {
						this.box.status_box.push(response);
						revolve();
					// return BoxPresenter.show(this.box_id);
					})
					.catch(reject)
					.finally(() => {
						this.loading = false;
					});
			});
		},
		countStatus(status) {
			return this.box.status_box.filter(s => s.status === status).length;
		},
		semAlteracao() {
			this.setStatus('CHANGED')
				.then(() => BoxPresenter.show(this.box_id))
				.then(box => { this.box = box; });
		},
		abrirRascunho() {
			this.setStatus('CHANGE_REQUESTED').then(() => {
				this.$emit('rascunho-aberto');
				// this.close();
			});
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>

<style lang="scss" scoped>
.loading {
	min-height: 127px;
}
</style>
