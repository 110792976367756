<template>
	<Dialog :titulo="is_new ? 'Cadastrar dose máxima' : 'Editar dose máxima'" btn_cancelar="Cancelar"
		:btn_acao="is_new ? 'Cadastrar' : 'Editar'" :btn_acao_disable="!habilitarBtnAcao" @acao="submeter()"  @close="fechaModal()" :loading="salvando"
		@excluir="modal_excluir = true" :btn_excluir="messagem_dialog_excluir.btn_excluir">

		<v-container fluid grid-list-lg>
			<v-layout wrap>
				<v-flex class="pb-0" :class="'xs12'">
					<v-autocomplete
						box
						color="green"
						v-model="principio_ativo"
						:disabled="!is_new"
						:loading="carregando_principios_ativos"
						:label="'Novo Princípio ativo'"
						:item-value="'id'"
						:item-text="'descricao'"
						:items="principios_ativos"
						:return-object="true"
						:hide-details="true"/>
					<template v-if="!carregando_principios_ativos">
						<v-layout v-for="molecula in principio_ativo.moleculas" :key="molecula.id" wrap>
							<v-flex v-if="is_new" class="pb-0" :class="'xs8 md8'">
								<v-text-field box disabled :hide-details="true" v-model="molecula.descricao" :label="'Molecula'" />
							</v-flex>
							<v-flex v-if="is_new" class="pb-0" :class="'xs2 md2'">
								<v-text-field box  color="green" :hide-details="true" v-model="molecula.dosagem" :label="'Dosagem'"
									placeholder="Dose" />
							</v-flex>
							<v-flex v-if="is_new" class="pb-0" :class="'xs2 md2'">
								<v-text-field box color="green" :hide-details="true" v-model="molecula.dosagem_medida" :label="'Unidade'"
									placeholder="Medida"/>
							</v-flex>
						</v-layout>
					</template>
					<v-layout v-if="!carregando_principios_ativos">
						<v-flex class="pb-0" :class="'xs8 md8'">
							<v-text-field v-if="!is_new" box disabled :hide-details="true" v-model="item.molecula.descricao"
								:label="'Molecula'"/>
						</v-flex>
						<v-flex class="pb-0" :class="'xs2 md2'">
							<v-text-field v-if="!is_new" box color="green" :hide-details="true" v-model="item.dose_maxima"
								:label="'Dosagem'" placeholder="Dose"/>
						</v-flex>
						<v-flex class="pb-0" :class="'xs2 md2'">
							<v-text-field v-if="!is_new" box color="green" :hide-details="true" v-model="item.dose_maxima_medida"
								:label="'Unidade'" placeholder="Medida"/>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>

		<v-dialog persistent scrollable v-model="modal_excluir" transition="fade" max-width="340px">
			<transition>
				<Dialog v-if="modal_excluir" :titulo="messagem_dialog_excluir.titulo" :subtitulo="messagem_dialog_excluir.subtitulo"
					:btn_acao="messagem_dialog_excluir.btn_acao" btn_acao_class="error" btn_cancelar="Cancelar"
					@acao="excluir()" :loading="salvando" @close="modal_excluir = false" />
			</transition>
		</v-dialog>

	</Dialog>
</template>

<script>
import { CRUDMixin } from '@Mixins/crud';
import { MedicamentoPresenter } from '@Presenter/medicamento-presenter';
import { DoseMaximaPresenter } from '@Presenter/dose-maxima-presenter';

export default {
	name: 'DialogDoseMaxima',
	mixins: [CRUDMixin],
	data: () => ({
		type: 'dose-maxima',
		carregando_principios_ativos: false,
		principios_ativos: [],
		principio_ativo: {
			moleculas: []
		}
	}),
	created() {
		this.carregando_principios_ativos = true;

		this.buscaPrincipiosAtivos()
			.then(() => {
				if (!this.is_new)
					this.buscarDoseMaxima(this.item.id);
			})
			.finally(() => {
				this.carregando_principios_ativos = false;
			});
	},
	methods: {
		createData() {
			if (this.is_new) {
				return this.principio_ativo.moleculas.map(m => ({
					principio_ativo_id: m.pivot.principio_ativo_id,
					molecula_id: m.pivot.molecula_id,
					dose_maxima: m.dosagem,
					dose_maxima_medida: m.dosagem_medida
				}));
			}
			return {
				...this.item
			};
		},
		buscaPrincipiosAtivos() {
			return MedicamentoPresenter.principiosAtivos()
				.then(response => {
					this.principios_ativos = response;
				});
		},
		buscarDoseMaxima(id) {
			return DoseMaximaPresenter.show(id)
				.then(response => {
					this.principio_ativo = response.principio_ativo;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
::v-deep .v-card__actions {
  background: #f5f5f5;
  padding: 16px 32px;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;

  .v-avatar {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.06);
    transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    &:hover {
      background: rgba(0, 0, 0, 0.12);
    }

    &:active {
      background: rgba(0, 0, 0, 0.06);
    }
  }
}
</style>
