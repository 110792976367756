import { FeatureFlagPresenter } from '@Presenter/feature-flag-presenter';

export const featureFlagsMixin = {
	data: () => ({
		featureFlags: {}
	}),
	created() {
		FeatureFlagPresenter.list()
			.then(response => {
				this.featureFlags = response;
			});
	},
	methods: {
		isFeatureEnabled(featureName) {
			if (this.featureFlags[featureName])
				return this.featureFlags[featureName].enabled;
			return false;
		}
	}
};
