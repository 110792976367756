export const ProcessTypes = {
	CHARGE: 'CHARGE',
	PICKING: 'PICKING',
	BILLING: 'BILLING',
	DISPATCH: 'DISPATCH',
	SHIPPING: 'SHIPPING',
	CONCLUSION: 'CONCLUSION'
};

export const ProcessTypeDescription = {
	CHARGE: 'Cobrança',
	PICKING: 'Picking',
	BILLING: 'Faturamento',
	DISPATCH: 'Expedição',
	SHIPPING: 'Pronto para envio',
	CONCLUSION: 'Concluído'
};

export const ProcessStatus = {
	WAITING: 'WAITING',
	PROCESSING: 'PROCESSING',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR'
};

export const ProcessStatusDescription = {
	WAITING: 'Aguardando',
	PROCESSING: 'Em processamento',
	SUCCESS: 'Sucesso',
	ERROR: 'Erro'
};

export const getDescriptionProcessType = process => ProcessTypeDescription[process];
export const getDescriptionProcessStatus = process => ProcessStatusDescription[process];
