<template>
	<v-flex class="space-between items-start q-column" xs5>
		<div>
			<p class="mb-0 inter text-bold-medium titleOpacity long-string">{{medicineType}}</p>
			<p class="mb-0 text-bold subheading long-string">{{productInfo.name}}</p>
			<p class="mb-0 body-2">{{productInfo.presentation}} - {{productInfo.manufacturer.name}}</p>
		</div>
		<div :class="discountGreaterThanZero ? 'mb-4' : 'mt-4'">
			<v-layout wrap row align-center justify-start>
				<v-tooltip v-if="isGeneric" top>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small color="#212B72" class="iconStyle generic">fab fa-google</v-icon>
					</template>
					<span class="inter text-bold" style="color: #000;">Genérico</span>
				</v-tooltip>
				<v-tooltip v-if="isPbm" top>
					<template v-slot:activator="{ on }">
						<img
							v-on="on"
							src="/img/icons/percentage.svg"
							alt="Imagem de porcentagem"
							class="iconStyle percentage"
							style="width: 33px;height: 33px"/>
					</template>
					<span class="inter text-bold" style="color: #000;">PBM</span>
				</v-tooltip>
				<v-tooltip v-if="isControlled" top>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small color="#E50000" class="iconStyle receipt">fas fa-file</v-icon>
					</template>
					<span class="inter text-bold" style="color: #000;">Retenção de receita</span>
				</v-tooltip>
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<img
							v-on="on"
							src="/img/icons/recurrence.svg"
							alt="Imagem de recorrência"
							class="iconStyle recurrence"
							style="width: 30px;height: 30px"/>
					</template>
					<span class="inter text-bold" style="color: #000;">Medicamento recorrente</span>
				</v-tooltip>
			</v-layout>
		</div>
	</v-flex>
</template>

<script>
export default {
	name: 'ProductInfo',
	props: {
		productInfo: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		isMedicine() {
			return this.productInfo.drug;
		},
		medicineType() {
			return this.productInfo.drug && this.productInfo.drug.type ? this.productInfo.drug.type : '';
		},
		discountGreaterThanZero() {
			return this.productInfo.values.discount > 0;
		},
		isPbm() {
			return this.isMedicine ? this.productInfo.drug.pbm !== null : false;
		},
		isGeneric() {
			return this.isMedicine ? this.productInfo.drug.type === 'GENERICO' : false;
		},
		isControlled() {
			return this.isMedicine ? !!this.productInfo.drug.prescriptionRule : false;
		}
	}
};
</script>

<style lang="scss" scoped>
	.titleOpacity {
		opacity: 0.7;
	}

	.long-string {
		word-break: break-all;
	}

	.iconStyle {
		border-radius: 17px;
		margin: 5px
	}

	.generic {
		background-color: #E7EAFF;
		padding: 7px;
	}

	.percentage {
		background-color: rgba(45, 206, 113, 0.2);
		padding: 7px;
	}

	.receipt {
		background-color: rgba(229, 0, 0, 0.2);
		padding: 7px 10px;
	}

	.exclamation {
		background-color: rgba(255, 200, 117, 0.4);
		padding: 7px;
	}

	.recurrence {
		background-color: #FFC875;
		padding: 3px;
	}
</style>
