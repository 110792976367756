<template>
	<p class="text-center">
		<strong>Carregando</strong>
	</p>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>

</style>
