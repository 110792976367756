<template>
	<div class="px-md-5">
		<Header @baixar-planilha="baixarPlanilha" @busca="b => busca = b" />

		<v-data-table :headers="headers" :items="items" :loading="is_loading" :search="busca" item-key="id"
			:no-data-text="(is_loading) ? 'Carregando...' : (request_error) ? 'Erro ao carregar, tente novamente' : 'Nenhum estoque'"
			no-results-text="Nenhum resultado encontrado" :rows-per-page-items="[15, 30, 45, {text: 'Todos', value: -1}]"
			rows-per-page-text="Itens por página">
			<template slot="items" slot-scope="props">
				<tr>
					<td>{{ props.item.paciente }}</td>
					<td>{{ props.item.casa_repouso }}</td>
					<td>{{ props.item.medicamento }}</td>
					<td>{{ props.item.qtd_comprimidos }}</td>
					<td>{{ props.item.qtd_caixas }}</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { RelatorioPresenter } from '@Presenter/relatorio-presenter';
import Header from './header';

export default {
	name: 'RelatorioEstoquePaciente',
	components: {
		Header
	},
	data: () => ({
		is_loading: false,
		headers: [
			{ text: 'Paciente', value: 'paciente' },
			{ text: 'Casa de Repouso', value: 'casa_repouso' },
			{ text: 'Medicamento', value: 'medicamento' },
			{ text: 'Comprimidos', value: 'qtd_comprimidos' },
			{ text: 'Caixas', value: 'qtd_caixas' }
		],
		request_error: false,
		busca: '',
		items: []
	}),
	beforeMount() {
		this.index();
	},
	methods: {
		index() {
			this.is_loading = true;
			RelatorioPresenter.estoquePaciente()
				.then(response => {
					this.items = response;
				})
				.catch(() => {
					this.request_error = true;
				})
				.finally(() => {
					this.is_loading = false;
				});
		},
		baixarPlanilha() {
			import('./estoque-paciente-excel').then(Module => {
				Module.default(this.items);
			});
		}
	}
};
</script>
